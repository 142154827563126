import PropTypes from 'prop-types';
import { useState, useEffect, useCallback, useRef } from 'react';
import { Select, Form } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const { Option } = Select;
const { Item } = Form;

/**
 * `SelectSite` is a component that renders a dropdown with site names.
 * It fetches sites based on the provided `company` and displays them in the dropdown.
 *
 * @component
 * @param {Object} props - The component properties.
 * @param {string} props.dbKey - The database key for the item.
 * @returns {JSX.Element} The rendered `Select` component containing site names.
 */

export const SelectSite = ({ dbKey, isDisabled, permKey }) => {
  const isMounted = useRef(true);
  const [sites, setSites] = useState([]);
  const { dispatchAPI, company, user } = useAuthContext();
  const { message } = useErrorMessage();

  const getsites = useCallback(async () => {
    if (!isMounted.current) return;
    if (user?.role === 'users:USER') {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/sites/company/${company}/permissions/${permKey}`
        });
        if (isMounted.current) setSites(data);
      } catch (e) {
        if (isMounted.current) message(e);
      }
    } else {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/entities/company/${company}?populate=sites`
        });
        if (isMounted.current) {
          setSites(
            data.reduce(
              (acc, currEntity) => acc.concat(currEntity.sites || []),
              []
            )
          );
        }
      } catch (e) {
        if (isMounted.current) message(e);
      }
    }
  }, [company]);

  useEffect(() => {
    (async () => {
      await getsites();
    })();
  }, [getsites]);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  return (
    <Item name={dbKey}>
      <Select
        disabled={isDisabled}
        showSearch
        filterOption={(inputValue, option) =>
          (option?.title ?? '').toLowerCase().includes(inputValue.toLowerCase())
        }
      >
        {sites &&
          sites.map((s) => (
            <Option key={s._id} value={s._id} title={s.name}>
              {s.name}
            </Option>
          ))}
      </Select>
    </Item>
  );
};

SelectSite.propTypes = {
  dbKey: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  permKey: PropTypes.string
};

SelectSite.defaultProps = {
  isDisabled: false,
  permKey: ''
};
