import PropTypes from 'prop-types';
import { Select, Form } from 'antd';
import { findContractById } from './helpersFunctions';

const { Option } = Select;
const { Item } = Form;

/**
 * A custom select component for choosing values from a list of enums.
 * @component
 * @param {object} props - The component's props.
 * @param {string} props.dbKey - The key used by the form.
 * @param {object[]} props.contracts - The contracts array.
 * @param {object[]} props.entity - The entity array.
 * @param {object} props.contractId - The contract _id.
 * @param {boolean} props.isDisabled - Indicates whether the select component is disabled.
 * @returns {JSX.Element} The JSX element representing the select component.
 */
export const MultiSelectEntityByContractReducer = ({
  dbKey,
  isDisabled,
  contractId,
  contracts,
  dispatch,
  field,
  type
}) => {
  const matchingContract = findContractById(contracts, contractId);

  const entities = matchingContract ? matchingContract.entity : [];

  return (
    <Item name={dbKey}>
      <Select
        disabled={isDisabled}
        showSearch
        filterOption={(inputValue, option) =>
          (option?.title ?? '').toLowerCase().includes(inputValue.toLowerCase())
        }
        mode="multiple"
        onChange={(value) => {
          dispatch({
            type,
            field,
            payload: value
          });
        }}
      >
        {entities.map((e) => (
          <Option key={e._id} value={e._id} title={e.name}>
            {e.name}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

MultiSelectEntityByContractReducer.propTypes = {
  dbKey: PropTypes.arrayOf(PropTypes.string).isRequired,
  isDisabled: PropTypes.bool,
  contractId: PropTypes.string,
  contracts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dispatch: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

MultiSelectEntityByContractReducer.defaultProps = {
  isDisabled: false,
  contractId: ''
};
