import PropTypes from 'prop-types';
import { useFields } from '../fieldsTransportSuscribed';
import { TransportsCreateUpdate } from '../TransportCreateUpdate';

/**
 * Provides a component for creating or updating freighted goods insurance claims.
 *
 * @component
 * @param {object} props - React component props.
 * @param {string} props.purpose - The purpose of the component, either "create" or "edit".
 * @returns {React.ReactNode} The CreateUpdateContainer component with specific configurations for freighted goods insurance claims.
 */
export const FacultiesCreateUpdate = ({ purpose }) => (
  <TransportsCreateUpdate
    purpose={purpose}
    useFields={useFields}
    mandatoryDocuments={['full_statement', 'consignment_note']}
    populate="entity,site,contract,company_Id,entity"
    sub_programme="FACULTY"
  />
);

FacultiesCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
