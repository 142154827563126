import { Link } from 'react-router-dom';
import { Button, Flex, Tooltip } from 'antd';
import { EditOutlined, EyeOutlined, SyncOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { Flood } from '@carbon/icons-react';
import { MailingButton } from '../../../components/ButtonMailActionColumn';
import { FormAddInsuranceCertificate } from './AddCertificateModal/FormAddInsuranceCertificate';
import { PropertyContext } from '../PropertyContext';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { DowndoadCertificateModal } from './DownloadCertificateModal/DownloadCertificateModal';

const iconSize = 18;

/**
 * Custom hook for generating the action column configuration for a table.
 *
 * @param {Object} options - The options for configuring the action column.
 * @param {boolean} options.edit - Whether to include an edit action in the column.
 * @param {boolean} options.isArchivatedPage - Whether the page is an archived page.
 * @param {string} options.resourceName - The name of the resource.
 * @returns {Array} - The action column configuration.
 */
export const useActionColumn = ({ edit, isArchivatedPage, resourceName }) => {
  const { generateAttestation } = useContext(PropertyContext);
  const { user } = useAuthContext();
  const isUnitAdmin = user?.role === 'admins:SUPER-ADMIN';
  const { t } = useTranslation();
  return [
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record, _, idx) => {
        const isAXAContract = record?.contract?.insurance_company === 'AXA';
        return (
          <Flex gap="small" align="center" justify="end" wrap="nowrap">
            <Tooltip title={t('property.actions.show')}>
              <Link to={`/programmes/properties/show/${record?._id}`}>
                <EyeOutlined style={{ fontSize: iconSize }} />
              </Link>
            </Tooltip>
            {edit && (
              <Link to={`/programmes/properties/edit/${record?._id}`}>
                <EditOutlined style={{ fontSize: iconSize }} />
              </Link>
            )}
            {!isArchivatedPage && (
              <>
                <Tooltip title={t('property.actions.create_claim')}>
                  <Link to={`/claims/properties/create/${record?._id}`}>
                    <Flood size={20} />
                  </Link>
                </Tooltip>
                <MailingButton
                  assetId={record?._id}
                  templateName="Blank"
                  tradKey="specific_demand"
                  resource="Property"
                  baseUrl="properties"
                />
              </>
            )}
            {!isAXAContract && isUnitAdmin && (
              <FormAddInsuranceCertificate
                resourceName={resourceName}
                idWithoutParams={record?._id}
                baseUrl="property"
                rowKey={idx}
              />
            )}
            {record?.insurance_certificate &&
              record?.insurance_certificate.length > 0 &&
              !isAXAContract && (
                <Tooltip title={t('buttons.download_certificate')}>
                  <DowndoadCertificateModal
                    resourceName={resourceName}
                    idWithoutParams={record?._id}
                    baseUrl="property"
                    rowKey={idx}
                  />
                </Tooltip>
              )}
            {isAXAContract && (
              <>
                <Tooltip title={t('property.actions.generate_attestation')}>
                  <Button
                    type="link"
                    onClick={() => generateAttestation(record?._id)}
                  >
                    <SyncOutlined style={{ fontSize: iconSize }} />
                  </Button>
                </Tooltip>
                <MailingButton
                  assetId={record?._id}
                  templateName="InsuranceAttestation"
                  tradKey="sending_attestation_property"
                  resource="Property"
                  baseUrl="properties"
                />
              </>
            )}
          </Flex>
        );
      }
    }
  ];
};
