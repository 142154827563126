import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMailingFields } from '../MailingFieldsClaim';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerSendMail';
import 'react-quill/dist/quill.snow.css';

export const MailingBlankDocs = ({
  isModalMailingOpen,
  setIsModalMailingOpen,
  idWithoutParams,
  templateMail,
  resource,
  baseUrl,
  templateName,
  isModal,
  closeModal,
  file
}) => {
  const { t } = useTranslation();
  const [editorState, setEditorState] = useState();
  const [mailObject, setMailObject] = useState();
  const [templateReLoaded, setTemplateReLoaded] = useState(false);
  const { mailingFields } = useMailingFields(
    editorState,
    setEditorState,
    mailObject
  );

  const sender = 'mail.jdg@strateg.in';

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        sender_email_address: sender,
        mail_object: mailObject && mailObject,
        template_content: editorState && editorState
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        sender_email_address: sender,
        template: templateName,
        file
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        sender_email_address: sender,
        template: templateName,
        file
      })
    }
  };

  const resetEditorContent = () => {
    setTemplateReLoaded(!templateReLoaded);
  };
  useEffect(() => {
    if (templateMail[0]) {
      const processedTemplateContent = templateMail[0]?.template_content
        .replace('{contract_number}', 'sinistre')
        .replace('{unit_counter}', '');
      setMailObject(templateMail[0]?.object);
      setEditorState(processedTemplateContent);
    }
  }, [templateMail]);
  return (
    <Modal
      footer={null}
      title={t('mailing.send_an_email')}
      open={isModalMailingOpen}
      onOk={() => setIsModalMailingOpen(false)}
      onCancel={() => setIsModalMailingOpen(false)}
      className="mail-modal"
    >
      {templateMail && editorState && mailObject && (
        <>
          <CreateUpdateContainer
            fields={mailingFields}
            purpose="edit"
            resource={resource}
            baseUrl={baseUrl}
            tradKey="mailing"
            config={config}
            withFilesManager={false}
            WithPageHeaderCustom={false}
            idWithoutParams={idWithoutParams}
            templateReLoaded={templateReLoaded}
            isModal={isModal}
            closeModal={closeModal}
          />
          <Button
            onClick={() => {
              resetEditorContent();
            }}
          >
            {t('buttons.reset_to_base_editor_content')}
          </Button>
        </>
      )}
      <Button type="link" onClick={() => setIsModalMailingOpen(false)}>
        {t('buttons.cancel')}
      </Button>
    </Modal>
  );
};

MailingBlankDocs.propTypes = {
  isModalMailingOpen: PropTypes.bool.isRequired,
  setIsModalMailingOpen: PropTypes.func.isRequired,
  idWithoutParams: PropTypes.string,
  templateMail: PropTypes.arrayOf(
    PropTypes.shape({
      template_content: PropTypes.string,
      object: PropTypes.string,
      _id: PropTypes.string
    })
  ),
  resource: PropTypes.string,
  baseUrl: PropTypes.string.isRequired,
  templateName: PropTypes.string.isRequired,
  isModal: PropTypes.bool,
  closeModal: PropTypes.func,
  file: PropTypes.shape({}),
  datas: PropTypes.shape({
    unit_manager: PropTypes.shape({
      email: PropTypes.string
    })
  })
};

MailingBlankDocs.defaultProps = {
  templateMail: [],
  idWithoutParams: null,
  isModal: false,
  closeModal: () => {},
  datas: {},
  resource: '',
  file: null
};
