import dayjs from 'dayjs';

/**
 * Truncates a string to a maximum length of 30 characters.
 * If the string exceeds 30 characters, it is shortened and appended with an ellipsis ('...').
 *
 * @param {string} string - The string to be truncated.
 * @returns {string} The truncated string with an ellipsis if it exceeds 30 characters, otherwise the original string.
 */
export const truncateString = (string) => {
  if (string.length > 30) {
    return `${string.slice(0, 30)}...`;
  }
  return string;
};

/**
 * Formats a given date to a human-readable string.
 * If the date is from the current day, it returns the time in 'HH:mm' format.
 * Otherwise, it returns the date in 'DD-MM-YYYY' format.
 *
 * @param {string | Date} date - The date to be formatted.
 * @returns {string} The formatted date string.
 */
export const displayDate = (date) => {
  const formattedDate = dayjs(date);
  if (dayjs(formattedDate).startOf('day').isSame(dayjs().startOf('day'))) {
    return dayjs(date).format('HH:mm');
  }
  return dayjs(date).format('DD-MM-YYYY');
};

/**
 * Generates the title for a conversation based on the provided filter, selected conversation, user, and translation function.
 * @param {string} filter - The filter type for the conversation.
 * @param {object} selectedConversation - The selected conversation object.
 * @param {object} user - The user object.
 * @param {function} t - The translation function.
 * @returns {string} - The generated title for the conversation.
 */
export const title = (filter, selectedConversation, user, t) => {
  if (filter === 'collaborators') {
    const { users } = selectedConversation;
    if (!users) return '';
    const { first_name, last_name } =
      users.filter((userLocal) => userLocal._id !== user._id)[0] || {};
    return t('messages.titles.send_new_message_to', {
      first_name,
      last_name
    });
  }
  const { unit_counter } = selectedConversation;
  if (!unit_counter) return '';
  return t('messages.titles.send_new_message_about', {
    unit_counter
  });
};
