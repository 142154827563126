export const documentsDbKeys = [
  'entity',
  'site',
  'contract',
  'contract_number',
  'company_Id',
  'programme_types',
  'document_type',
  'last_call_date',
  'payment_date',
  'validity_start_date',
  'validity_end_date'
];
export const callForContributionDbKeys = [
  'status',
  'entity',
  'site',
  'contract',
  'contract_number',
  'company_Id',
  'contribution_type',
  'contribution_amount',
  'contribution_frequency',
  'document_type',
  'last_call_date',
  'payment_date',
  'last_sending_date',
  'is_paid'
];
