import PropTypes from 'prop-types';
import { Select, Form } from 'antd';
import { findContractById } from './helpersFunctions';

const { Option } = Select;
const { Item } = Form;

/**
 * A custom select component for choosing values from a list of enums.
 * @component
 * @param {object} props - The component's props.
 * @param {string} props.dbKey - The key used by the form.
 * @param {object[]} props.contracts - The contracts array.
 * @param {object[]} props.entity - The entity array.
 * @param {object} props.contractId - The contract _id.
 * @param {boolean} props.isDisabled - Indicates whether the select component is disabled.
 * @returns {JSX.Element} The JSX element representing the select component.
 */
export const MultiSelectSiteByEntityByContractReducer = ({
  dbKey,
  isDisabled,
  contractId,
  contracts,
  entity
}) => {
  const matchingContract = findContractById(contracts, contractId);

  const filteredEntities = matchingContract
    ? matchingContract.entity.filter((e) => entity.includes(e._id))
    : [];

  const sites = filteredEntities.reduce(
    (acc, cur) => [...acc, ...cur.sites],
    []
  );

  return (
    <Item name={dbKey}>
      <Select
        disabled={isDisabled}
        showSearch
        filterOption={(inputValue, option) =>
          (option?.title ?? '').toLowerCase().includes(inputValue.toLowerCase())
        }
        mode="multiple"
      >
        {sites.map((s) => (
          <Option key={s._id} value={s._id} title={s.name}>
            {s.name}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

MultiSelectSiteByEntityByContractReducer.propTypes = {
  dbKey: PropTypes.arrayOf(PropTypes.string).isRequired,
  isDisabled: PropTypes.bool,
  contractId: PropTypes.string,
  contracts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  entity: PropTypes.arrayOf(PropTypes.string).isRequired
};

MultiSelectSiteByEntityByContractReducer.defaultProps = {
  isDisabled: false,
  contractId: ''
};
