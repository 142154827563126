import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { DatePicker, Input, Radio } from 'antd';
import { SelectEnumsClaimsType } from '../../../components/SelectEnumsClaimsType';
import { SelectManager } from '../../../components/SelectManager';

const { TextArea } = Input;
const { Group } = Radio;

/**
 * Custom hook that returns the fields information and loading state for the LegalProtectionClaim form.
 * @hook
 * @param {Object} options - Options for the hook.
 * @param {boolean} options.isOther - Flag indicating if the claim type is "Other".
 * @param {function} options.setIsOther - Function to set the "isOther" flag.
 * @param {function} options.setIsComplaint - Function to set the "isComplaint" flag.
 * @returns {Object} - Object containing the fields information and loading state.
 */
export const useFields = ({ isOther, setIsOther, setIsComplaint }) => {
  const { t } = useTranslation();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const dateFormat = 'DD-MM-YYYY';

  /**
   * Array of field information objects.
   * Each object represents a form field and contains properties like label, name, rules, and input component.
   * @type {Array}
   */
  const fieldsInformations = [
    {
      label: ['customer_manager'],
      name: ['customer_manager'],
      rules: [{ required: true }],
      input: (
        <SelectManager
          dbKey="customer_manager"
          setIsFieldsLoading={setIsFieldsLoading}
        />
      )
    },
    {
      label: ['unit_manager'],
      name: ['unit_manager'],
      input: (
        <SelectManager
          dbKey="unit_manager"
          setIsFieldsLoading={setIsFieldsLoading}
        />
      )
    },
    {
      label: ['insurance_company_reference'],
      name: ['insurance_company_reference']
    },
    {
      label: ['client_reference'],
      name: ['client_reference']
    },
    {
      label: ['sinister_date'],
      name: ['sinister_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['claim_type'],
      name: ['claim_type'],
      rules: [{ required: true }],
      input: (
        <SelectEnumsClaimsType
          dbKey="claim_type"
          url="Legal_protection_insurance_claims"
          setIsOther={setIsOther}
          setIsComplaint={setIsComplaint}
        />
      )
    },
    {
      label: ['claim_type_if_other'],
      name: ['claim_type_if_other'],
      rules: [{ required: isOther }],
      hidden: !isOther
    },
    {
      label: ['sinister_location'],
      name: ['sinister_location'],
      rules: [{ required: true }]
    },
    {
      label: ['personal_injury'],
      name: ['personal_injury'],
      rules: [{ required: true }],
      input: (
        <Group>
          <Radio value="YES">{t('claims.form.yes')}</Radio>
          <Radio value="NO">{t('claims.form.no')}</Radio>
        </Group>
      )
    },
    {
      label: ['commentaries'],
      name: ['commentaries'],
      input: <TextArea style={{ resize: 'none' }} />
    },
    {
      label: ['pictures_damages_caused'],
      name: ['pictures_damages_caused'],
      input: 'File',
      maxFilesCount: 5,
      multipleFiles: true,
      startWithDivider: {
        title: t('claims.form.pictures')
      },
      endWithDivider: {
        title: t('claims.form.documents')
      }
    }
  ];

  return { fieldsInformations, isFieldsLoading };
};
