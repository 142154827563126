import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { renderEntities } from '../../../../utils/formatters/entities';

/**
 * A custom hook that generates a list of content for displaying subscription details.
 * @hook
 * @param {object} data - The subscription data to generate content for.
 * @returns {Array} An array of objects containing label, span, and content for each detail.
 */
export const useListContentEnvironmentInsurance = (data = {}) => {
  const { t } = useTranslation();

  const {
    desired_effective_date,
    company_registration_number,
    business_domain,
    territoriality_of_activity,
    global_yearly_turnover,
    number_of_sites_to_cover,
    entity,
    address,
    postal_code,
    city,
    is_ISO_14001_or_EMAS
  } = data;
  return [
    {
      label: 'environment_insurances.form.desired_effective_date',
      span: 1,
      content:
        (desired_effective_date &&
          dayjs(desired_effective_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'environment_insurances.form.entity',
      span: 1,
      content: renderEntities(entity)
    },
    {
      label: 'environment_insurances.form.address',
      span: 1,
      content: (address && address) || '-'
    },
    {
      label: 'environment_insurances.form.postal_code',
      span: 1,
      content: (postal_code && postal_code) || '-'
    },
    {
      label: 'environment_insurances.form.city',
      span: 1,
      content: (city && city) || '-'
    },
    {
      label: 'environment_insurances.form.company_registration_number',
      span: 1,
      content:
        (company_registration_number && company_registration_number) || '-'
    },
    {
      label: 'environment_insurances.form.business_domain',
      span: 1,
      content: (business_domain && business_domain) || '-'
    },
    {
      label: 'environment_insurances.form.territoriality_of_activity',
      span: 1,
      content: territoriality_of_activity
        ? territoriality_of_activity
            .map((territoriality) =>
              t(`environment_insurances.form.enums.${territoriality}`)
            )
            .join(', ')
        : '-'
    },
    {
      label: 'environment_insurances.form.global_yearly_turnover',
      span: 1,
      content: (global_yearly_turnover && global_yearly_turnover) || '-'
    },
    {
      label: 'environment_insurances.form.number_of_sites_to_cover',
      span: 1,
      content: (number_of_sites_to_cover && number_of_sites_to_cover) || '-'
    },
    {
      label: 'environment_insurances.form.is_ISO_14001_or_EMAS',
      span: 1,
      content: is_ISO_14001_or_EMAS
        ? t(`environment_insurances.form.${is_ISO_14001_or_EMAS}`)
        : '-'
    }
  ];
};

/**
 * A custom hook that generates a list of content for displaying subscription details.
 * @hook
 * @param {object} data - The subscription data to generate content for.
 * @returns {Array} An array of objects containing label, span, and content for each detail.
 */
export const useListContentAddress = (data = {}) => {
  const { t } = useTranslation();

  const {
    site_address,
    site_postal_code,
    site_city,
    is_site_under_authorization
  } = data;

  return [
    {
      label: 'environment_insurances.form.site_address',
      span: 1,
      content: (site_address && site_address) || '-'
    },
    {
      label: 'environment_insurances.form.site_postal_code',
      span: 1,
      content: (site_postal_code && site_postal_code) || '-'
    },
    {
      label: 'environment_insurances.form.site_city',
      span: 1,
      content: (site_city && site_city) || '-'
    },
    {
      label: 'environment_insurances.form.is_site_under_authorization',
      span: 1,
      content: is_site_under_authorization
        ? t(`environment_insurances.form.${is_site_under_authorization}`)
        : '-'
    }
  ];
};
