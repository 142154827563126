/**
 * Get the matching document
 * @function matchingDocument
 * @param {Array} datas - The array of documents
 * @param {String} documentId - The document ID
 * @returns {Object} - The matching document
 */
export const matchingDocument = (datas, documentId) => {
  const matchingDoc = datas.filter((item) => item._id === documentId);
  return matchingDoc;
};
