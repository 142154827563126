export const generalSubscribedEntries = [
  'contract',
  'date_added',
  'validity_start_date',
  'validity_end_date',
  'entity',
  'subscriber_last_name_first_name',
  'subscriber_address',
  'subscriber_postal_code',
  'subscriber_city',
  'deletion_date_effect',
  'deletion_date'
];

export const cyberInsurancesDbKeys = [
  'contract',
  'date_added',
  'entity',
  'site',
  'company_name',
  'company_registration_number',
  'activity_classification_number',
  'internet_site_or_domain_name',
  'total_workforce',
  'last_yearly_turnover',
  'is_located_in_france_andorra_monaco_eu',
  'internet_services_turnover_part',
  'global_gross_margin_percentage',
  'workstation_number',
  'server_number',
  'personal_data_server_number',
  'bank_details_server_number',
  'health_data_server_number'
];

export const hardwareInsurancesDbKeys = [
  'contract',
  'date_added',
  'entity',
  'site',
  'address',
  'postal_code',
  'city',
  'global_tax_excl_hardware_value',
  'laptops_tax_excl_value',
  'is_claim_last_two_years',
  'is_data_duplication_guarantee',
  'is_operating_cost_loss_guarantee'
];

export const professionalMultiriskInsurancesDbKeys = [
  'contract',
  'entity',
  'site',
  'risk_site_name',
  'risk_site_address',
  'postal_code',
  'city',
  'entry_date',
  'total_insured_developed_area'
];

export const assemblyTestingInsurancesDbKeys = [
  'contract',
  'entity',
  'postal_code',
  'city',
  'validity_start_date',
  'validity_end_date'
];

export const environmentInsurancesDbKeys = [
  'contract',
  'date_added',
  'entity',
  'address',
  'postal_code',
  'city',
  'company_registration_number',
  'business_domain',
  'territoriality_of_activity',
  'global_yearly_turnover',
  'number_of_sites_to_cover'
];

export const legalProtectionInsurancesDbKeys = [
  'contract',
  'entity',
  'postal_code',
  'city',
  'validity_start_date',
  'validity_end_date'
];

export const individualHousesBuildersDbKeys = [
  'contract',
  'entity',
  'postal_code',
  'city',
  'validity_start_date',
  'validity_end_date'
];
