import { useCallback } from 'react';
import dayjs from 'dayjs';
import { useHistory } from '../../../../utils/historyUtils';

/**
 * Custom hook that returns the configuration objects for getting and creating resources.
 *
 * @param {function} dispatch - The dispatch function from the Redux store.
 * @param {string} company - The company ID.
 * @param {string} program - The program type.
 * @param {string} subProgram - The sub-program type.
 * @returns {object} - The configuration objects for getting and creating resources.
 */

export const useConfig = (company, dispatch, programmeType) => {
  const { setInitialHistory, updateHistory } = useHistory();
  const onGetResource = {
    setFields: useCallback(
      (data) => {
        dispatch({
          type: 'SET_COUNT',
          field: 'count',
          payload: data?.number_of_sites_to_cover || 1
        });
        setInitialHistory(data);
        return {
          ...data,
          desired_effective_date:
            data.desired_effective_date && dayjs(data.desired_effective_date)
        };
      },
      [company, programmeType]
    )
  };

  const onUpdateResource = {
    setBody: useCallback(
      (data) => ({
        ...data,
        ...updateHistory('program')
      }),
      [company]
    )
  };
  return {
    onGetResource,
    onUpdateResource
  };
};
