import { createContext, useContext, useEffect, useState, useRef } from 'react';
import io from 'socket.io-client';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from './AuthContext';
import { useErrorMessage } from '../utils/errorMessage';

const SocketContext = createContext({});

/**
 * @description Socket context provider
 * @param {Object} props Props
 * @param {React.ReactNode} props.children Children
 * @returns {React.ReactNode} Socket context provider
 */
export const SocketContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const { token, dispatchAPI, user, company } = useAuthContext();
  const { message } = useErrorMessage();
  const [socket, setSocket] = useState();
  const [connectedUsers, setConnectedUsers] = useState([]);
  const [currentLocation, setCurrentLocation] = useState('');
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (token) {
      setSocket(io(`${process.env.REACT_APP_API_URL}?token=${token}`));
    } else if (socket && !token) {
      socket.emit('logout');
      socket.close();
    }
    return () => {
      if (socket) socket.close();
    };
  }, [token]);

  useEffect(() => {
    if (socket)
      socket.on('connectedUsers', (users) => setConnectedUsers(users));
  }, [socket]);

  const [forceRefresh, setForceRefresh] = useState();

  const [alertNotifications, setAlertsNotifications] = useState();
  const [gestionnaireNotifications, setGestionnaireNotifications] = useState();

  const [notificationsCount, setNotificationsCount] = useState();

  const [alertNotificationsCount, setAlertNotificationsCount] = useState(0);
  const [gestionnaireNotificationsCount, setGestionnaireNotificationsCount] =
    useState(0);

  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadedCount, setLoadedCount] = useState(0);
  const count = 10;

  const getNotifications = async (notifClass) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `notifications/${
          user?._id
        }/${company}?sort=-updated_at&limit=${count}${
          !notifClass ? '' : `&class=${notifClass}`
        }`
      });
      if (notifClass === 'alert') {
        setAlertsNotifications(data);
      } else if (notifClass === 'gestionnaire') {
        setGestionnaireNotifications(data);
      }
      setLoadedCount(data?.length);
    } catch (e) {
      message(e);
    }
  };

  const getNotificationsCount = async (notifClass) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `notifications/count/user/${user?._id}/company/${company}?${
          !notifClass ? '' : `&class=${notifClass}`
        }`
      });
      if (notifClass === 'alert') {
        setAlertNotificationsCount(data);
      } else if (notifClass === 'gestionnaire') {
        setGestionnaireNotificationsCount(data);
      }
      setNotificationsCount(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    let isActive = true;

    if (currentLocation === '/') {
      if (token && socket && user && user?._id && company) {
        setLoading(true);
        Promise.all([
          getNotifications('alert'),
          getNotifications('gestionnaire'),
          getNotificationsCount('alert'),
          getNotificationsCount('gestionnaire')
        ])
          .then(() => {
            if (isActive) {
              setInitLoading(false);
              setLoading(false);
            }
          })
          .catch((e) => {
            if (isActive) {
              message(e);
            }
          });
      }
    }
    return () => {
      isActive = false;
    };
  }, [socket, token, user, company, forceRefresh, currentLocation]);

  useEffect(() => {
    if (currentLocation === '/') {
      if (socket) {
        const handleNewNotification = (data) => {
          if (!isFirstRender.current && data === company) {
            Promise.all([
              getNotifications('alert'),
              getNotificationsCount()
            ]).catch((e) => {
              message(e);
            });
          }
        };

        socket.on('newNotification', handleNewNotification);
        socket.on('removeFromDashBoard', handleNewNotification);

        return () => {
          if (!isFirstRender.current) {
            socket.off('newNotification', handleNewNotification);
            socket.off('removeFromDashBoard', handleNewNotification);
          }
        };
      }
    }
    isFirstRender.current = false;
    return false;
  }, [socket, company, getNotifications, getNotificationsCount, message]);

  const onLoadMore = async (notifClass) => {
    const skipValue = loadedCount;
    setLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `notifications/${
          user?._id
        }/${company}?sort=-updated_at&limit=${count}&skip=${skipValue}${
          !notifClass ? '' : `&class=${notifClass}`
        }`
      });
      const newData = gestionnaireNotifications.concat(data);
      setGestionnaireNotifications(newData);
      setLoadedCount(loadedCount + data.length);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      message(e);
    }
  };

  const loadMore =
    !initLoading &&
    !loading &&
    gestionnaireNotifications.length !== gestionnaireNotifications ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px'
        }}
      >
        <Button type="primary" onClick={() => onLoadMore('gestionnaire')}>
          {t('buttons.load_more_notifications')}
        </Button>
      </div>
    ) : null;

  return (
    <SocketContext.Provider
      value={{
        socket,
        connectedUsers,
        loadMore,
        initLoading,
        loading,
        setLoading,
        notificationsCount,
        alertNotifications,
        gestionnaireNotifications,
        alertNotificationsCount,
        gestionnaireNotificationsCount,
        forceRefresh,
        setForceRefresh,
        setCurrentLocation
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocketContext = () => {
  const context = useContext(SocketContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
