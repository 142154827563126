import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import dayjs from 'dayjs';
import { Row, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewerWithoutForm';
import { useAuthContext } from '../../../contexts/AuthContext';

import useFields from './utils/fieldsEventCancellation';
import { EventCancellationContext } from './EventCancellationContext';
import { formatDatePickerDate } from '../../../utils/formatDatePickerDate';
import { useHistory } from '../../../utils/historyUtils';

/**
 * `EventCancellationCreateUpdate` is a React component for creating or updating documents.
 *
 * Depending on the provided `purpose` prop, this component can be used to either create or edit a document.
 * The component utilizes the `CreateUpdateContainer` for rendering the input fields and managing form actions.
 *
 * Note: This component does not support managing files.
 *
 * @component
 * @param {object} props The props for the component.
 * @param {string} props.purpose Indicates the purpose of the form. It should be either 'create' or 'edit'.
 * @returns {React.ElementType} A form for creating or updating a document.
 */

export const EventCancellationCreateUpdate = ({ purpose }) => {
  const { setCurrentEntityContext, setCurrentContract } = useContext(
    EventCancellationContext
  );

  const resource = 'event_cancellations';
  const [form] = Form.useForm();
  const { setInitialHistory, createHistory, updateHistory } = useHistory();

  const [isStageWithRoof, setIsStageWithRoof] = useState(false);
  const [isThreeSidesClosed, setIsThreeSidesClosed] = useState(false);
  const [isFireWork, setIsFireWork] = useState(false);
  const [isRanAsExpected, setIsRanAsExpected] = useState(true);
  const [isEssentialPersonUnavailability, setIsEssentialPersonUnavailability] =
    useState(false);
  const [isGuarded, setIsGuarded] = useState(false);
  const [isPermanentInstallationWithRoof, setIsPermanentInstallationWithRoof] =
    useState(false);
  const [isRanOutdoors, setIsRanOutdoors] = useState(false);
  const [isAStageUsed, setIsAStageUsed] = useState(false);
  const [isInstallation, setIsInstallation] = useState(false);
  const [currentDates, setCurrentDates] = useState({
    event_start_date: null,
    installation_start_date: null,
    holding_start_date: null,
    unInstallation_start_date: null,
    possible_postponement_start_date: null
  });

  const { fieldsEventCancellation, isFieldsLoading } = useFields(
    isStageWithRoof,
    setIsStageWithRoof,
    isThreeSidesClosed,
    setIsThreeSidesClosed,
    isFireWork,
    setIsFireWork,
    isRanAsExpected,
    setIsRanAsExpected,
    isEssentialPersonUnavailability,
    setIsEssentialPersonUnavailability,
    isGuarded,
    setIsGuarded,
    isPermanentInstallationWithRoof,
    setIsPermanentInstallationWithRoof,
    isAStageUsed,
    setIsAStageUsed,
    isRanOutdoors,
    setIsRanOutdoors,
    isInstallation,
    setIsInstallation,
    currentDates,
    setCurrentDates,
    form
  );
  const { company, companyName } = useAuthContext();
  const { t } = useTranslation();

  const mandatoryDocuments = ['event_program'];

  const config = {
    onGetResource: {
      setFields: (data) => {
        if (data?.is_a_stage_with_roof === 'YES') {
          setIsStageWithRoof(true);
        }
        setCurrentContract(data.contract);
        if (data?.is_a_stage_with_three_closed_sides === 'YES') {
          setIsThreeSidesClosed(true);
        }
        if (data?.is_special_equipment_used_including_fireworks === 'YES') {
          setIsFireWork(true);
        }
        if (data?.ran_as_expected === 'YES') {
          setIsRanAsExpected(true);
        }
        if (data?.essential_person_unavailability_specific_clauses === 'YES') {
          setIsEssentialPersonUnavailability(true);
        }
        if (data?.is_event_location_guarded === 'YES') {
          setIsGuarded(true);
        }
        if (data?.is_event_ran_in_permanent_installation_with_roof === 'YES') {
          setIsPermanentInstallationWithRoof(true);
        }
        if (data?.is_a_stage_used === 'YES') {
          setIsAStageUsed(true);
        }
        if (data?.is_event_ran_outdoors === 'YES') {
          setIsRanOutdoors(true);
        }
        if (data?.installation === 'YES') {
          setIsInstallation(true);
        }
        if (data?.event_start_date) {
          setCurrentDates({
            event_start_date: formatDatePickerDate(data?.event_start_date),
            installation_start_date: formatDatePickerDate(
              data?.installation_start_date
            ),
            holding_start_date: formatDatePickerDate(data?.holding_start_date),
            unInstallation_start_date: formatDatePickerDate(
              data?.unInstallation_start_date
            ),
            possible_postponement_start_date: formatDatePickerDate(
              data?.possible_postponement_start_date
            )
          });
        }
        setInitialHistory(data);
        return {
          ...data,
          desired_effective_date:
            data?.desired_effective_date && dayjs(data.desired_effective_date),
          date_added: data?.date_added && dayjs(data.date_added),
          event_start_date:
            data?.event_start_date && dayjs(data.event_start_date),
          event_end_date: data?.event_end_date && dayjs(data.event_end_date),
          possible_postponement_start_date:
            data?.possible_postponement_start_date &&
            dayjs(data.possible_postponement_start_date),
          possible_postponement_end_date:
            data?.possible_postponement_end_date &&
            dayjs(data.possible_postponement_end_date),
          installation_start_date:
            data?.installation_start_date &&
            dayjs(data?.installation_start_date),
          installation_end_date:
            data?.installation_end_date && dayjs(data.installation_end_date),
          holding_start_date:
            data?.holding_start_date && dayjs(data.holding_start_date),
          holding_end_date:
            data?.holding_end_date && dayjs(data.holding_end_date),
          unInstallation_start_date:
            data?.unInstallation_start_date &&
            dayjs(data.unInstallation_start_date),
          unInstallation_end_date:
            data?.unInstallation_end_date && dayjs(data.unInstallation_end_date)
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        programmes_types: 'EVENT_CANCELLATION',
        company,
        ...createHistory('program')
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        ...updateHistory('program')
      })
    }
  };

  return (
    <CreateUpdateContainer
      setCurrentEntityContext={setCurrentEntityContext}
      form={form}
      fields={fieldsEventCancellation}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="event-cancellations"
      resource={resource}
      config={config}
      extraTitle={<Row justify="center">{`${t('folder')} ${companyName}`}</Row>}
      withFilesManager
      mandatoryDocuments={mandatoryDocuments}
      populate="?populate=documents.file"
    />
  );
};

EventCancellationCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
