import { Button, Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import header from '../../assets/images/logostrategin.png';

export const MailInBrowser = () => {
  const { token } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [mail, setMail] = useState();
  const handleFinish = async (values) => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: '/mails',
        body: { ...values, token }
      });
      let html = data.content;
      html = html.replace(/cid:headerImage/, header);
      setMail({ ...data, content: html });
    } catch (e) {
      message(e);
    }
  };

  return mail ? (
    <div style={{ height: '99vh' }}>
      <iframe
        title="email-content"
        srcDoc={mail?.content}
        width="100%"
        height="100%"
      />
    </div>
  ) : (
    <Modal
      visible={!mail}
      title={t('signup.verifiyEmail')}
      closable={false}
      footer={
        <Button type="primary" onClick={form.submit}>
          OK
        </Button>
      }
    >
      <Form layout="vertical" form={form} onFinish={handleFinish}>
        <Form.Item
          name={['email']}
          rules={[{ required: true }, { type: 'email' }]}
          label={t('signup.email')}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
