import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { renderEntities } from '../../../../utils/formatters/entities';

/**
 * A custom hook that generates a list of content for displaying subscription details.
 * @hook
 * @param {object} data - The subscription data to generate content for.
 * @returns {Array} An array of objects containing label, span, and content for each detail.
 */
export const useListContentSubHardwareInsurance = (data = {}) => {
  const { t } = useTranslation();

  const {
    contract,
    customer_manager,
    unit_manager,
    desired_effective_date,
    global_tax_excl_hardware_value,
    laptops_tax_excl_value,
    is_claim_last_two_years,
    is_data_duplication_guarantee,
    is_operating_cost_loss_guarantee,
    entity,
    site,
    address,
    postal_code,
    city
  } = data;

  const customerManagerName = customer_manager
    ? `${customer_manager.first_name} ${customer_manager.last_name}`
    : '-';
  const unit_managerName = unit_manager
    ? `${unit_manager.first_name} ${unit_manager.last_name}`
    : '-';

  return [
    {
      label: 'hardware_insurances.form.contract_number',
      span: 1,
      content: (contract?.contract_number && contract?.contract_number) || '-'
    },
    {
      label: 'hardware_insurances.form.customer_manager',
      span: 1,
      content: (customerManagerName && customerManagerName) || '-'
    },
    {
      label: 'hardware_insurances.form.unit_manager',
      span: 1,
      content: (unit_managerName && unit_managerName) || '-'
    },
    {
      label: 'hardware_insurances.form.desired_effective_date',
      span: 1,
      content:
        (desired_effective_date &&
          dayjs(desired_effective_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'hardware_insurances.form.entity',
      span: 1,
      content: renderEntities(entity)
    },
    {
      label: 'hardware_insurances.form.site',
      span: 1,
      content: (site && site) || '-'
    },
    {
      label: 'hardware_insurances.form.address',
      span: 1,
      content: (address && address) || '-'
    },
    {
      label: 'hardware_insurances.form.postal_code',
      span: 1,
      content: (postal_code && postal_code) || '-'
    },
    {
      label: 'hardware_insurances.form.city',
      span: 1,
      content: (city && city) || '-'
    },
    {
      label: 'hardware_insurances.form.global_tax_excl_hardware_value',
      span: 1,
      content:
        (global_tax_excl_hardware_value && global_tax_excl_hardware_value) ||
        '-'
    },
    {
      label: 'hardware_insurances.form.laptops_tax_excl_value',
      span: 1,
      content: (laptops_tax_excl_value && laptops_tax_excl_value) || '-'
    },
    {
      label: 'hardware_insurances.form.is_claim_last_two_years',
      span: 1,
      content: is_claim_last_two_years
        ? t(`hardware_insurances.form.enums.${is_claim_last_two_years}`)
        : '-'
    },
    {
      label: 'hardware_insurances.form.is_data_duplication_guarantee',
      span: 1,
      content: is_data_duplication_guarantee
        ? t(`hardware_insurances.form.enums.${is_data_duplication_guarantee}`)
        : '-'
    },
    {
      label: 'hardware_insurances.form.is_operating_cost_loss_guarantee',
      span: 1,
      content: is_operating_cost_loss_guarantee
        ? t(
            `hardware_insurances.form.enums.${is_operating_cost_loss_guarantee}`
          )
        : '-'
    }
  ];
};
