import { useContext, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { EditOutlined, SyncOutlined } from '@ant-design/icons';
import { routes } from '../../../utils/constants/adminRoutes';
import { GolfContext } from './GolfContext';
import { MailingButton } from '../../Mailing/MailingButton';
import { ClaimButton } from '../../components/ClaimButton';
import usePopConfirm from '../utils/popConfirms';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useHistory } from '../../../utils/historyUtils';

const iconSize = 18;

/**
 * Custom hook that returns extra buttons for a golf program.
 * @hook
 * @param {boolean} isDeleted - Indicates if the golf program is deleted.
 * @param {object} golf - The golf program object.
 * @returns {object} - An object containing the extra buttons.
 */
export const useExtraButtons = (isDeleted, golf) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { setHistoryRefresh } = useAuthContext();
  const { updateHistoryCustom } = useHistory();
  const [visible, setVisible] = useState(false);
  const { generateAttestation } = useContext(GolfContext);
  const { exitProgram, unarchiveProgram } = usePopConfirm('golfs');

  const handleGenerateAttestation = () => {
    generateAttestation(golf?._id);
    updateHistoryCustom('golfs', id, t('buttons.attestation_downloaded'));
    setHistoryRefresh((prev) => !prev);
  };

  const extraButtons = (
    <>
      {!isDeleted && (
        <Link
          to={{
            pathname: `${routes.PROGRAMMES}/golfs/edit/${id}`
          }}
        >
          <Button type="primary">
            {`${t('buttons.edit')} `}
            <EditOutlined />
          </Button>
        </Link>
      )}
      {golf && !isDeleted && (
        <>
          {golf?.contract?.insurance_company === 'AXA' && (
            <Button onClick={() => handleGenerateAttestation()}>
              {`${t('buttons.generate_attestation')} `}
              <SyncOutlined style={{ fontSize: iconSize }} />
            </Button>
          )}
          <MailingButton
            asset={golf}
            templateName="InsuranceAttestation"
            tradKey="sending_attestation_golf"
            resource="Golf"
            baseUrl="golfs"
          />
          <ClaimButton id={id} url="golfs" claimType="golf" />
          <MailingButton
            asset={golf}
            templateName="Blank"
            tradKey="specific_demand"
            resource="Golf"
            baseUrl="golfs"
          />
        </>
      )}
      {golf &&
        !golf.deleted &&
        exitProgram({
          visible,
          setVisible
        })}
      {golf && golf.deleted && unarchiveProgram()}
    </>
  );

  return { extraButtons };
};
