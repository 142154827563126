import { useCallback, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Col, Flex, Skeleton, Row, Steps } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useListContentClaim } from './listContentClaim';
import { useListContentProMultiriskInsurance } from '../../programmes/ProMultiriskInsurance/utils/listContentProMultiriskInsurance';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../components';
import { Documents } from '../../components/Documents';
import {
  useListItemsStep,
  useListBeforeValidation
} from '../utils/listItemsStep';
import { handleStatusUtils } from '../utils/handleStatusUtils';
import { ModalClosure } from '../utils/ModalClosure';
import { PhotosClaims } from '../utils/PhotosClaims';
import { ShowClaimAccountingOverview } from '../ClaimAccountingOverview/ShowClaimAccountingOverview';
import { constants } from './constants';
import { useProgramDataUpdater } from '../../../utils/programDataUpdater';
import { useFetchMainInformation } from '../utils/useFetchMainInformation';
import { useFetchClaimInformation } from '../utils/useFetchClaimInformation';
import { History } from '../../components/History';
import { useExtraButtons } from '../extraButtons';
import { MailingButton } from '../../Mailing/MailingButton';

/**
 * Displays detailed information about a professional multirisk insurance claim, including claim information, professional multirisk insurance information,
 * pictures, and related actions.
 *
 * @component
 * @returns {React.ReactNode} Detailed view of the professional multirisk insurances claim.
 */

export const ProMultiriskInsuranceClaimShow = () => {
  const isMounted = useRef(false);
  const { id } = useParams();
  const {
    productAPI,
    claimAPI,
    resourceClaim,
    populateProductShow,
    populateClaimShow
  } = constants;
  const { dispatchAPI, permissions, verifPermissions } = useAuthContext();
  const { message } = useErrorMessage();
  const {
    current,
    setCurrent,
    onChange,
    toEnclosed,
    setToEnclose,
    changingStatus,
    setChangingStatus
  } = handleStatusUtils(claimAPI);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const { items } = useListItemsStep();
  const { itemsBeforeValidation } = useListBeforeValidation();
  const [edit, setEdit] = useState(true);
  const [closure, setClosure] = useState(false);
  const [productInformations, setProductInformations] = useState();
  const [claimInformation, setClaimInformation] = useState({});
  const {
    ID,
    claim,
    isClosed,
    isCompliant,
    accountingBalanced,
    feesAppealsBalanced
  } = claimInformation;

  const listContentClaim = useListContentClaim(claim);
  const listContentMainInformations =
    useListContentProMultiriskInsurance(productInformations);

  const {
    setIsCompliant,
    setAccountingBalanced,
    setFeesAppealsBalanced,
    initialData
  } = useProgramDataUpdater(claimInformation, setClaimInformation);

  const setStatusCallback = useCallback(
    (data) => {
      setCurrent(data?.step);
      setToEnclose(data?.to_enclose);
    },
    [setCurrent, setToEnclose]
  );

  const getClaimInformation = useFetchClaimInformation({
    setIsLoading,
    dispatchAPI,
    id,
    setClaimInformation,
    message,
    isMounted,
    setStatusCallback,
    claimAPI,
    populateClaimShow,
    initialData
  });

  const getProduct = useFetchMainInformation({
    setIsLoading,
    dispatchAPI,
    ID,
    populateProductShow,
    setProductInformations,
    message,
    isMounted,
    productAPI
  });

  useEffect(() => {
    if (ID) {
      getProduct();
    }
  }, [ID]);

  useEffect(() => {
    isMounted.current = true;
    getClaimInformation();
    return () => {
      isMounted.current = false;
    };
  }, [current, accountingBalanced, feesAppealsBalanced]);

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setEdit(
        !verifPermissions('/claims/edit', permissions?.notPermittedRoutes)
      );
    }
  }, [permissions]);

  const { extraButtons } = useExtraButtons({
    isCompliant,
    setIsCompliant,
    current,
    edit,
    toEnclosed,
    setClosure,
    onChange,
    productClaim: claim,
    resource: resourceClaim,
    baseUrl: claimAPI,
    product: productInformations,
    urlPathName: productAPI,
    accountingBalanced,
    feesAppealsBalanced,
    changingStatus,
    setChangingStatus
  });

  return ID && productInformations && !isLoading ? (
    <>
      <PageHeaderCustom
        title={`${claim?.unit_counter || 'xxxx'}`}
        extra={extraButtons}
      />
      <MailingButton
        asset={claim}
        templateName={changingStatus}
        tradKey={changingStatus}
        baseUrl={claimAPI}
        changingStatus={changingStatus}
        setChangingStatus={setChangingStatus}
      />
      <ContentCustom>
        <Flex vertical gap="middle">
          {current !== undefined && current !== 6 ? (
            <Steps
              onChange={(step) => {
                onChange(step, id, edit);
              }}
              items={isCompliant ? items : itemsBeforeValidation}
              current={current}
            />
          ) : null}
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={12}>
              <Row gutter={[0, 16]}>
                <Card>
                  <DescriptionList
                    data={listContentClaim}
                    translate
                    layout="vertical"
                    title={t('claims.form.claim_informations')}
                  />
                </Card>
                <PhotosClaims baseUrl={claimAPI} />
                <Documents
                  idWithoutParams={id}
                  baseUrl={claimAPI}
                  resource={resourceClaim}
                  noDelete={current === 6}
                />
              </Row>
            </Col>
            <Col xs={24} xl={12}>
              <Row gutter={[0, 16]}>
                <Card title={t('claims.form.main_informations')}>
                  <DescriptionList
                    data={listContentMainInformations}
                    translate
                    layout="vertical"
                  />
                </Card>
                <Col span={24}>
                  <Card title={t('subscriptions.form.history')} size="small">
                    <History route={claimAPI} />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={24}>
              {current >= 2 && isCompliant ? (
                <ShowClaimAccountingOverview
                  current={current}
                  buttonBelowTable={
                    current !== 6 && current !== undefined && edit === true
                  }
                  noActionColumnsButton={isClosed}
                  accountingBalanced={accountingBalanced}
                  setAccountingBalanced={setAccountingBalanced}
                  feesAppealsBalanced={feesAppealsBalanced}
                  setFeesAppealsBalanced={setFeesAppealsBalanced}
                  resourceName="professional-multirisk-insurance-claim-accounts"
                  url="professional-multirisk-insurance-claim-accounts"
                  resourceNameClaim={claimAPI}
                  urlClaim={claimAPI}
                  urlAccount="claim-accounts"
                  edit={edit}
                />
              ) : null}
            </Col>
          </Row>
        </Flex>
        <ModalClosure
          open={closure}
          onCancel={() => setClosure(false)}
          onOk={() => setClosure(false)}
          claimReferenceNumber={claim?.unit_counter}
        />
      </ContentCustom>
    </>
  ) : (
    <Skeleton active loading={isLoading} />
  );
};
