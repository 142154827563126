import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import moment from 'moment';
import { subscriptionStatus } from '../../../utils/constants/tagColors';

/**
 * Generate columns configuration for a subscriptions table.
 * @returns {Array} An array of column objects for the table.
 */
export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('subscriptions.form.program'),
      key: 'programme_types',
      dataIndex: 'programme_types',
      sorter: true,
      render: (programme_types) => t(`subscriptions.form.${programme_types}`)
    },
    {
      title: t('subscriptions.form.sub_programme'),
      key: 'sub_programme',
      dataIndex: 'sub_programme',
      sorter: true,
      render: (sub_programme) =>
        sub_programme && sub_programme.length > 0
          ? t(`subscriptions.form.${sub_programme}`)
          : '-'
    },
    {
      title: t('subscriptions.form.request_date'),
      key: 'request_date',
      dataIndex: ['request_date'],
      render: (request_date) =>
        request_date ? moment(request_date).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('subscriptions.form.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) =>
        status ? (
          <Tag color={subscriptionStatus[status]}>
            {t(`subscriptions.form.${status}`, {
              defaultValue: status
            })}
          </Tag>
        ) : (
          t('subscriptions.form.undefined')
        ),
      sorter: true
    }
  ];
};
