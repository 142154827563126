import PropTypes from 'prop-types';
import { useReducer, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { useFields } from './fieldsProMultiriskInsurance';
import { useAuthContext } from '../../../contexts/AuthContext';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewerWithoutForm';
import { useConfig } from './utils/createUpdateConfig';
import { PromultiriskInsuranceContext } from './PromultiriskInsuranceContext';

/**
 * Component for creating or updating individual houses builder insurance subscriptions.
 * @component
 * @param {Object} props - The component's properties.
 * @param {string} props.purpose - The purpose of the component, either "create" or "edit".
 * @returns {JSX.Element} The JSX element for creating or updating individual houses builder insurance subscriptions.
 */
export const ProMultiriskInsuranceCreateUpdate = ({ purpose }) => {
  const { setCurrentEntityContext, setCurrentContract } = useContext(
    PromultiriskInsuranceContext
  );
  const { company } = useAuthContext();
  const program = 'PROFESSIONAL_MULTIRISK_INSURANCE';
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const initialState = {
    isProtectedAgainstTheft: false,
    isFireProtection: false,
    isOtherTypologyActivities: false,
    isTenant: false
  };

  const formStateReducer = (state, action) => {
    switch (action.type) {
      case 'TOGGLE_STATE':
        return { ...state, [action.field]: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(formStateReducer, initialState);

  const mandatoryDocuments = state.isTenant ? ['lease_agreements'] : [];

  const { fieldsInformations } = useFields(form, state, dispatch);

  const config = useConfig(dispatch, company, program, setCurrentContract);

  return (
    <CreateUpdateContainer
      setCurrentEntityContext={setCurrentEntityContext}
      title={t(`professional_multirisk_insurances.form.${purpose}`)}
      form={form}
      fields={fieldsInformations}
      purpose={purpose}
      tradKey="professional_multirisk_insurances"
      baseUrl="professional-multirisk-insurances"
      resource="Professional-multirisk-insurances"
      populate="?populate=documents.file"
      config={config}
      mandatoryDocuments={mandatoryDocuments}
      messageOnSuccess
    />
  );
};

ProMultiriskInsuranceCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
