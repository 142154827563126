/**
 * Utility functions for agriculture-related operations.
 * @returns {Object} An object containing utility functions.
 */
export const agricultureUtils = () => {
  const flattenObject = (oTF) =>
    Object.values(oTF).flatMap((value) => {
      if (typeof value === 'object' && !Array.isArray(value)) {
        return flattenObject(value);
      }
      return value;
    });
  return { flattenObject };
};
