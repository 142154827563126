import React from 'react';
import { Button, Card, Col, Row, Select, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
  FolderOpenFilled,
  HourglassOutlined,
  CheckCircleTwoTone,
  ExclamationOutlined
} from '@ant-design/icons';
import { useDownloadDocument } from '../../../utils/downloadDoc';
import { useDraggerProps } from './utils/draggerProps';
import { handleFileTypeChange } from './utils/handleFileTypeChange';
import { handleFileDelete } from './utils/handleFileDelete';
import { useErrorMessage } from '../../../utils/errorMessage';
import { ModalEditFileKey } from './utils/ModalEditFileKey';

const { Dragger } = Upload;
const { Option } = Select;
/**
 * `FilesManager` is a React component for managing a list of files. It allows for file deletion, viewing,
 * downloading, and type changing. It uses a custom Dragger component for file uploads.
 * It also displays a list of missing files.
 *
 * @component
 * @param {Object} props - Properties passed to the component.
 * @param {Array} props.filesList - Array of file objects.
 * @param {Function} props.setFilesList - Function to set the new file list.
 * @param {Array} props.filesKeys - Array of file keys.
 * @param {Array} props.filesConfiguration - The current configuration of the files.
 * @param {Function} props.setFilesConfiguration - Function to set the new files configuration.
 * @param {string} props.purpose - The purpose of the file usage.
 * @param {Function} props.deleteFile - Function to delete a specific file.
 * @returns {JSX.Element} A JSX element representing the component.
 */

const FilesManager = ({
  filesList,
  setFilesList,
  filesKeys,
  filesConfiguration,
  setFilesConfiguration,
  purpose,
  deleteFile,
  mandatoryDocuments,
  draggerFilesKeysMandatory,
  noDelete,
  isFileManagerDisabled,
  notitle,
  noBorder,
  setRefresh,
  customTitle
}) => {
  const { t } = useTranslation();
  const { downloadDocument, viewDocument } = useDownloadDocument();
  const { message } = useErrorMessage();
  const draggerProps = useDraggerProps(filesList, setFilesList, message, t);
  const missingFileKeys = filesKeys.filter(
    (key) => !filesList.some((file) => file.fileKey === key)
  );
  const existUnsavedFiles = filesList.some((item) => !item?._id);

  return (
    <Card
      bordered={!noBorder}
      title={customTitle || (!notitle && t('files.title'))}
      className="files-manager"
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={16}>
          {filesList?.map((item) => (
            <Card size="small" key={item.name}>
              <Row justify="space-between" align="middle" gutter={[16, 16]}>
                <Col xs={24} md={12} lg={10}>
                  <span className="file-name">{item.name}</span>
                </Col>
                <Col xs={24} md={12} lg={14}>
                  <Row justify="end" align="middle" gutter={[8, 8]}>
                    <Col xs={24} md={12} lg={16}>
                      <Select
                        value={item.fileKey}
                        disabled={purpose === 'edit' && item?.file}
                        onChange={(value) =>
                          handleFileTypeChange(
                            item,
                            value,
                            filesConfiguration,
                            setFilesConfiguration
                          )
                        }
                        style={{ width: '100%' }}
                        defaultValue={item?.fileKey}
                      >
                        {filesKeys.map((key) => (
                          <Option key={key} value={key}>
                            {t(`files.keys.${key}`)}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                      <Row justify="end" gutter={[8, 8]}>
                        {purpose === 'edit' && item?.file && (
                          <>
                            <Button
                              onClick={() => viewDocument(item.file, 'show')}
                              type="link"
                              icon={<EyeOutlined />}
                            />
                            <Button
                              onClick={() => downloadDocument(item.file)}
                              type="link"
                              icon={<DownloadOutlined />}
                            />
                            {setRefresh && (
                              <ModalEditFileKey
                                filesKeys={filesKeys}
                                item={item}
                                setRefresh={setRefresh}
                                existUnsavedFiles={existUnsavedFiles}
                              />
                            )}
                          </>
                        )}
                        {!noDelete && (
                          <Button
                            type="link"
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() =>
                              handleFileDelete(
                                { name: item.name, id: item?._id },
                                deleteFile,
                                filesList,
                                setFilesList,
                                filesConfiguration,
                                setFilesConfiguration,
                                t
                              )
                            }
                          />
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          ))}
          {missingFileKeys.map((key) => (
            <Card size="small" key={key}>
              <Row justify="space-between" align="middle">
                <Col>
                  {filesConfiguration.some((item) => item.fileKey === key) ? (
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                  ) : (
                    <HourglassOutlined />
                  )}
                  &nbsp; {t(`files.keys.${key}`)}
                </Col>
                {(draggerFilesKeysMandatory.includes(key) ||
                  mandatoryDocuments.includes(key)) && (
                  <ExclamationOutlined
                    style={{ color: 'var(--warningColor)' }}
                  />
                )}
              </Row>
            </Card>
          ))}
        </Col>
        <Col xs={24} lg={8}>
          <Dragger {...draggerProps} disabled={isFileManagerDisabled}>
            <p className="ant-upload-drag-icon">
              <FolderOpenFilled />
            </p>
            <p className="ant-upload-text">{t('files.create.action')}</p>
          </Dragger>
        </Col>
      </Row>
    </Card>
  );
};

FilesManager.propTypes = {
  filesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filesKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  filesConfiguration: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setFilesList: PropTypes.func.isRequired,
  setFilesConfiguration: PropTypes.func.isRequired,
  purpose: PropTypes.string.isRequired,
  deleteFile: PropTypes.func.isRequired,
  draggerFilesKeysMandatory: PropTypes.arrayOf(PropTypes.string),
  mandatoryDocuments: PropTypes.arrayOf(PropTypes.string),
  noDelete: PropTypes.bool,
  isFileManagerDisabled: PropTypes.bool,
  notitle: PropTypes.bool,
  noBorder: PropTypes.bool,
  setRefresh: PropTypes.func,
  customTitle: PropTypes.string
};

FilesManager.defaultProps = {
  draggerFilesKeysMandatory: [],
  mandatoryDocuments: [],
  noDelete: false,
  isFileManagerDisabled: null,
  notitle: false,
  noBorder: false,
  setRefresh: null,
  customTitle: ''
};

export default FilesManager;
