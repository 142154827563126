// TODO: awaiting customer feedback before commented code can be removed
import { useEffect, useState } from 'react';

/**
 * Custom hook for handling dragger files keys selection based on the provided parameters.
 *
 * @param {string} programme - The programme value.
 * @param {string} subProgramme - The subProgramme value.
 * @param {boolean} additionalCondition - The additional condition value.
 * @returns {Object} - An object containing the draggerFilesKeysOverRide, draggerFilesKeysMandatory, and isDocument values.
 */
export const useDraggerFilesKeysSelection = (
  programme,
  subProgramme,
  additionalCondition
) => {
  const [draggerFilesKeysOverRide, setDraggerFilesKeysOverRide] = useState([]);
  const [draggerFilesKeysMandatory, setDraggerFilesKeysMandatory] = useState(
    []
  );
  const [isDocument, setIsDocument] = useState(false);
  useEffect(() => {
    switch (programme) {
      case 'CIVIL_LIABILITY':
        if (subProgramme === 'CIVIL_LIABILITY_PRO') {
          setDraggerFilesKeysOverRide([
            'company_KBIS',
            'programme_type_document',
            'certificate_of_current_contract_or_copy',
            'others'
          ]);
          setIsDocument(true);
        } else if (subProgramme === 'CIVIL_LIABILITY_EXECUTIVE') {
          setDraggerFilesKeysOverRide([
            'company_KBIS_lte_3_months',
            'organization_chart_of_the_company'
          ]);
          setIsDocument(true);
        }
        break;
      case 'CONSTRUCTION':
        if (subProgramme === 'INDIVIDUAL_HOUSES_BUILDER') {
          setDraggerFilesKeysOverRide([
            'construction_permit',
            'subContractor_list',
            'subContractor_valid_ten_years_guarantee_attestation',
            'nominative_guarantee_attestation'
          ]);
          setIsDocument(true);
        } else {
          setDraggerFilesKeysOverRide([
            'subscription_form',
            'resume',
            'company_presentation',
            'informations_report_if_insured'
          ]);
          setIsDocument(true);
        }
        break;
      case 'CYBER_INSURANCE':
        if (additionalCondition) {
          setDraggerFilesKeysOverRide(['additional_questionnaire']);
          setDraggerFilesKeysMandatory(['additional_questionnaire']);
          setIsDocument(true);
        }
        break;
      case 'ASSEMBLY_TESTING_INSURANCE':
        setDraggerFilesKeysOverRide([
          'company_KBIS_lte_3_months',
          'company_form',
          'sales_contract_copy',
          'construction_schedule',
          'plans',
          'technical_description',
          'detailed_budget_by_object'
        ]);
        setDraggerFilesKeysMandatory([
          'company_KBIS_lte_3_months',
          'company_form',
          'sales_contract_copy'
        ]);
        setIsDocument(true);
        break;
      case 'LEGAL_PROTECTION_INSURANCE':
        setDraggerFilesKeysOverRide(['multirisk_company']);
        setDraggerFilesKeysMandatory([]);
        setIsDocument(true);
        break;
      case 'ENVIRONMENT_INSURANCE':
        setDraggerFilesKeysOverRide(['subscribing_company_KBIS']);
        setDraggerFilesKeysMandatory(['subscribing_company_KBIS']);
        setIsDocument(true);
        break;
      // case 'PROFESSIONAL_MULTIRISK_INSURANCE':
      //   setDraggerFilesKeysOverRide([
      //     'lease_agreements',
      //     'Deed_of_acquisition',
      //     'plans',
      //     'sectional_plans',
      //     'external_plans_or_cadastral_parcel_designations',
      //     'plans_Documents_for_specific_installations',
      //     'condominium_status',
      //     'statutes_of_ASL_AFUL',
      //     'certificates_Q4_Q5_Q17_Q18_Q19_others',
      //     'security_surveillance_contracts',
      //     'KBIS',
      //     'presentation_model',
      //     'current_contract',
      //     'loss_history_report',
      //     'tax_return_package',
      //     'other'
      //   ]);
      //   setIsDocument(true);
      //   break;
      default:
        break;
    }
  }, [programme, subProgramme, additionalCondition]);

  return { draggerFilesKeysOverRide, draggerFilesKeysMandatory, isDocument };
};
