import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Flex } from 'antd';
import { useHandlePaidItem } from './useHandlePaidItem';
import { useHandleCallForContribution } from './useHandleCallForContribution';

export const ExtraButtons = ({
  callForContribution,
  paidItem,
  setSelectedRowKeysPaidItem,
  setSelectedRowKeysIssuedCall
}) => {
  const { t } = useTranslation();

  const { isLoadingSending, handleCallForContribution } =
    useHandleCallForContribution({
      callForContribution,
      setSelectedRowKeysIssuedCall,
      setSelectedRowKeysPaidItem
    });

  const { isLoadingPaiement, handlePaidItem } = useHandlePaidItem({
    paidItem,
    setSelectedRowKeysPaidItem,
    setSelectedRowKeysIssuedCall
  });

  return (
    <Flex gap="middle" align="center">
      <Button
        type="primary"
        loading={isLoadingSending}
        onClick={handleCallForContribution}
        disabled={!callForContribution.length || isLoadingPaiement}
      >
        {t('buttons.send_calls_for_contribution')}
      </Button>
      <Button
        type="primary"
        loading={isLoadingPaiement}
        onClick={handlePaidItem}
        disabled={!paidItem.length || isLoadingSending}
      >
        {t('buttons.confirm_payment')}
      </Button>
    </Flex>
  );
};

ExtraButtons.propTypes = {
  callForContribution: PropTypes.arrayOf(PropTypes.string),
  paidItem: PropTypes.arrayOf(PropTypes.string),
  setSelectedRowKeysPaidItem: PropTypes.func,
  setSelectedRowKeysIssuedCall: PropTypes.func
};

ExtraButtons.defaultProps = {
  callForContribution: [],
  paidItem: [],
  setSelectedRowKeysPaidItem: () => {},
  setSelectedRowKeysIssuedCall: () => {}
};
