import PropTypes from 'prop-types';
import { useState, useReducer, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Spin } from 'antd';
import { useFields } from './utils/fieldsContributions';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewerWithoutForm';
import { useConfig } from './utils/createUpdateConfig';

/**
 * Component for creating or updating calls for contribution builder insurance subscriptions.
 * @component
 * @param {Object} props - The component's properties.
 * @param {string} props.purpose - The purpose of the component, either "create" or "edit".
 * @returns {JSX.Element} The JSX element for creating or updating calls for contribution builder insurance subscriptions.
 */
export const ContributionsEdit = ({ purpose }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { company, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [idWithoutParams, setIdWithoutParams] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const documentType = 'CALL_FOR_CONTRIBUTION';

  const mandatoryDocuments = ['call_for_contribution'];

  const initialState = {
    contractId: null,
    contracts: [],
    entity: []
  };

  const formStateReducer = (state, action) => {
    switch (action.type) {
      case 'SET_ENTITY':
        return { ...state, entity: action.payload };
      case 'SET_CONTRACT_ID':
        return { ...state, contractId: action.payload };
      case 'SET_CONTRACTS':
        return { ...state, contracts: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(formStateReducer, initialState);

  const { fields } = useFields(
    isFieldsLoading,
    setIsFieldsLoading,
    state,
    dispatch,
    form,
    purpose
  );

  const config = useConfig(company, state, documentType, dispatch);

  const getDocument = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `call-for-contributions/documents/${id}?populate=entity,company_Id,documents`
      });
      setIdWithoutParams(data._id);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getDocument();
    })();
  }, [getDocument]);

  return (
    <>
      <Spin spinning={isLoading} fullsceen="true" />
      {idWithoutParams && (
        <CreateUpdateContainer
          title={t(`call_for_contributions.form.${purpose}`)}
          form={form}
          fields={fields}
          purpose={purpose}
          tradKey="call_for_contributions"
          baseUrl="call-for-contributions"
          resource="call-for-contributions"
          populate="?populate=documents.file"
          idWithoutParams={idWithoutParams}
          config={config}
          withFilesManager={false}
          mandatoryDocuments={mandatoryDocuments}
        />
      )}
    </>
  );
};

ContributionsEdit.propTypes = {
  purpose: PropTypes.string.isRequired
};
