import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { DriverDrawer } from '../components/DriverDrawer';
import useFields from './utils/fieldsHeavyVehicles';
import { HeavyVehiclesContext } from './HeavyVehiclesContext';
import { useConfig } from '../../singleVehiclesConfig';

/**
 * `HeavyVehiclesCreateUpdate` is a React component for creating or updating documents.
 *
 * Depending on the provided `purpose` prop, this component can be used to either create or edit a document.
 * The component utilizes the `CreateUpdateContainer` for rendering the input fields and managing form actions.
 *
 * @component
 * @param {object} props The props for the component.
 * @param {string} props.purpose Indicates the purpose of the form. It should be either 'create' or 'edit'.
 * @returns {React.ElementType} A form for creating or updating a document.
 */
export const HeavyVehiclesCreateUpdate = ({ purpose }) => {
  const [form] = Form.useForm();
  const { setCurrentEntityContext, setCurrentContract } =
    useContext(HeavyVehiclesContext);
  const { companyName, user } = useAuthContext();
  const isClientCondition =
    user?.role === 'users:USER' || user?.role === 'users:SUPER-USER';
  const resource = 'vehicles';
  const fleet_type = 'HEAVY_VEHICLE';
  const draggerFilesKeysMandatory = ['vehicle_registration_document'];
  const [isTrailer, setIsTrailer] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentVehicleData, setCurrentVehicleData] = useState({
    initial_traffic_release: null,
    principal_driver: null,
    secondary_drivers: []
  });
  const { config } = useConfig({
    currentVehicleData,
    setCurrentVehicleData,
    fleet_type,
    setCurrentContract
  });
  const { fieldsHeavyVehicles, isFieldsLoading } = useFields(
    {
      onAddDriver: () => setIsOpen(true)
    },
    isTrailer,
    { setIsTrailer },
    currentVehicleData,
    setCurrentVehicleData,
    form
  );
  const { t } = useTranslation();

  return (
    <>
      <CreateUpdateContainer
        setCurrentEntityContext={setCurrentEntityContext}
        parentForm={form}
        fields={fieldsHeavyVehicles}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        draggerFilesKeysMandatory={draggerFilesKeysMandatory}
        baseUrl="vehicles"
        resource={resource}
        config={config}
        extraTitle={
          <Row justify="center" className="form-company-name">{`${t(
            'folder'
          )} ${companyName}`}</Row>
        }
        withFilesManager={!isClientCondition}
        populate="?populate=documents.file"
      />
      <DriverDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

HeavyVehiclesCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
