import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback, useRef } from 'react';
import { FileExclamationOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useFiltersDataFetch } from '../../../../components/ColumnsDynamicFilters/useFiltersDatasFetch';

/**
 * `useColumns` is a custom React hook that provides a list of column configurations.
 *
 * The column titles are fetched using the `react-i18next` hook for internationalization.
 *
 * @hook
 * @returns {Array<object>} Returns an array of column configurations to be consumed by a table component.
 */

export const useColumns = (resourceName, dbExtraQueryFilter, populate) => {
  const { t } = useTranslation();
  const { dispatchAPI, company: companyId } = useAuthContext();
  const { message } = useErrorMessage();
  const { fetchFilters, filters, loading } = useFiltersDataFetch(
    resourceName,
    dbExtraQueryFilter,
    resourceName,
    populate
  );
  const [config, setConfig] = useState({});
  const isMounted = useRef(true);

  const allColumns = [
    {
      title: t('event_cancellations.form.missing_documents'),
      key: 'missing_documents',
      dataIndex: ['missing_documents'],
      sorter: true,
      width: 150,
      render: (missing_documents) =>
        missing_documents ? (
          <FileExclamationOutlined
            style={{ fontSize: 18, color: 'var(--warningColor)' }}
          />
        ) : (
          '-'
        )
    },
    {
      title: t('event_cancellations.form.company_name'),
      key: 'company_name',
      dataIndex: ['company', 'name'],
      sorter: true
    },
    {
      title: t('event_cancellations.form.contract_number'),
      key: 'contract_number',
      dataIndex: ['contract', 'contract_number'],
      sorter: true
    },
    {
      title: t('event_cancellations.form.date_added'),
      key: 'date_added',
      dataIndex: ['created_at'],
      render: (created_at) =>
        created_at ? dayjs(created_at).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('event_cancellations.form.entity'),
      key: 'entity',
      dataIndex: ['entity', 'name'],
      filters: filters?.entity?.map((entity) => ({
        text: entity.name,
        value: entity._id
      })),
      sorter: true
    },
    {
      title: t('event_cancellations.form.site'),
      key: 'site',
      dataIndex: ['site', 'name'],
      filters: filters?.site?.map((site) => ({
        text: site.name,
        value: site._id
      })),
      sorter: true
    },
    {
      title: t('event_cancellations.form.company_involved_name'),
      key: 'company_involved_name',
      dataIndex: ['company_involved_name'],
      filters: filters?.company_involved_name?.map((companyName) => ({
        text: companyName,
        value: companyName
      })),
      sorter: true
    },
    {
      title: t('event_cancellations.form.company_address'),
      key: 'company_address',
      dataIndex: ['company_address'],
      sorter: true
    },
    {
      title: t('event_cancellations.form.postal_code'),
      key: 'postal_code',
      dataIndex: ['postal_code'],
      filters: filters?.postal_code?.map((postalCode) => ({
        text: postalCode,
        value: postalCode
      })),
      sorter: true
    },
    {
      title: t('event_cancellations.form.city'),
      key: 'city',
      dataIndex: ['city'],
      filters: filters?.city?.map((city) => ({
        text: city,
        value: city
      })),
      sorter: true
    },
    {
      title: t('event_cancellations.form.event_name'),
      key: 'event_name',
      dataIndex: ['event_name'],
      filters: filters?.event_name?.map((eventName) => ({
        text: eventName,
        value: eventName
      })),
      sorter: true
    },
    {
      title: t('event_cancellations.form.event_type'),
      key: 'event_type',
      dataIndex: ['event_type'],
      filters: filters?.event_type?.map((eventType) => ({
        text: eventType,
        value: eventType
      })),
      sorter: true
    },
    {
      title: t('event_cancellations.form.event_start_date'),
      key: 'event_start_date',
      dataIndex: ['created_at'],
      filters: filters?.event_start_date?.map((date) => ({
        text: dayjs(date).format('DD/MM/YYYY'),
        value: date
      })),
      render: (event_start_date) =>
        event_start_date ? dayjs(event_start_date).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('event_cancellations.form.event_end_date'),
      key: 'event_end_date',
      dataIndex: ['created_at'],
      filters: filters?.event_end_date?.map((date) => ({
        text: dayjs(date).format('DD/MM/YYYY'),
        value: date
      })),
      render: (event_end_date) =>
        event_end_date ? dayjs(event_end_date).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('event_cancellations.form.is_a_stage_used'),
      key: 'is_a_stage_used',
      dataIndex: ['is_a_stage_used'],
      filters: filters?.is_a_stage_used?.map((isAStageUsed) => ({
        text: t(`event_cancellations.form.${isAStageUsed}`),
        value: isAStageUsed
      })),
      render: (is_a_stage_used) =>
        (is_a_stage_used && t(`event_cancellations.form.${is_a_stage_used}`)) ||
        '-',
      sorter: true
    },
    {
      title: t('event_cancellations.form.is_a_stage_with_roof'),
      key: 'is_a_stage_with_roof',
      dataIndex: ['is_a_stage_with_roof'],
      filters: filters?.is_a_stage_with_roof?.map((isAStageWithRoof) => ({
        text: t(`event_cancellations.form.${isAStageWithRoof}`),
        value: isAStageWithRoof
      })),
      render: (is_a_stage_with_roof) =>
        (is_a_stage_with_roof &&
          t(`event_cancellations.form.${is_a_stage_with_roof}`)) ||
        '-',
      sorter: true
    },
    {
      title: t('event_cancellations.form.is_a_stage_with_three_closed_sides'),
      key: 'is_a_stage_with_three_closed_sides',
      dataIndex: 'is_a_stage_with_three_closed_sides',
      filters: filters?.is_a_stage_with_three_closed_sides?.map(
        (isAStageWithThreeClosedSides) => ({
          text: t(`event_cancellations.form.${isAStageWithThreeClosedSides}`),
          value: isAStageWithThreeClosedSides
        })
      ),
      render: (is_a_stage_with_three_closed_sides) =>
        (is_a_stage_with_three_closed_sides &&
          t(
            `event_cancellations.form.${is_a_stage_with_three_closed_sides}`
          )) ||
        '-',
      sorter: true
    },
    {
      title: t('event_cancellations.form.is_a_stage_facing_dominant_winds'),
      key: 'is_a_stage_facing_dominant_winds',
      dataIndex: ['is_a_stage_facing_dominant_winds'],
      filters: filters?.is_a_stage_facing_dominant_winds?.map(
        (isAStageFacingDominantWinds) => ({
          text: t(`event_cancellations.form.${isAStageFacingDominantWinds}`),
          value: isAStageFacingDominantWinds
        })
      ),
      render: (is_a_stage_facing_dominant_winds) =>
        (is_a_stage_facing_dominant_winds &&
          t(`event_cancellations.form.${is_a_stage_facing_dominant_winds}`)) ||
        '-',
      sorter: true
    },
    {
      title: t('event_cancellations.form.amount_engaged'),
      key: 'amount_engaged',
      dataIndex: 'amount_engaged',
      filters: filters?.amount_engaged?.map((amountEngaged) => ({
        text: amountEngaged,
        value: amountEngaged
      })),
      render: (amount_engaged) =>
        (amount_engaged && `${amount_engaged} €`) || '-',
      sorter: true
    },
    {
      title: t('event_cancellations.form.public_subsidies_to_substract'),
      key: 'public_subsidies_to_substract',
      dataIndex: 'public_subsidies_to_substract',
      filters: filters?.public_subsidies_to_substract?.map(
        (publicSubsidiesToSubstract) => ({
          text: publicSubsidiesToSubstract,
          value: publicSubsidiesToSubstract
        })
      ),
      render: (public_subsidies_to_substract) =>
        (public_subsidies_to_substract &&
          `${public_subsidies_to_substract} €`) ||
        '-',
      sorter: true
    },
    {
      title: t('event_cancellations.form.total_guarantee_amount'),
      key: 'total_guarantee_amount',
      dataIndex: 'total_guarantee_amount',
      filters: filters?.total_guarantee_amount?.map((totalGuaranteeAmount) => ({
        text: totalGuaranteeAmount,
        value: totalGuaranteeAmount
      })),
      render: (total_guarantee_amount) =>
        (total_guarantee_amount && `${total_guarantee_amount} €`) || '-',
      sorter: true
    },
    {
      title: t('event_cancellations.form.estimated_income'),
      key: 'estimated_income',
      dataIndex: 'estimated_income',
      filters: filters?.estimated_income?.map((estimatedIncome) => ({
        text: estimatedIncome,
        value: estimatedIncome
      })),
      render: (estimated_income) =>
        (estimated_income && `${estimated_income} €`) || '-',
      sorter: true
    }
  ];

  const getConfig = useCallback(async () => {
    if (!isMounted.current) return;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/programme-configs/${companyId}/event-cancellation-configs`
      });
      setConfig(data[0]);
    } catch (e) {
      if (isMounted.current) message('missing_parameters');
    }
  }, [dispatchAPI, message]);

  useEffect(() => {
    getConfig();
    fetchFilters();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const filteredColumns = allColumns.filter((column) => config[column.key]);

  return [filteredColumns, loading] || [];
};
