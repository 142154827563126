import { Checkbox } from 'antd';

/**
 * A custom hook that returns predefined field configurations.
 *
 * This configuration can be used in conjunction with form libraries or frameworks
 * that need a set of field definitions to render and validate form controls.
 *
 * @hook
 * @returns {Array<Object>} returns.golfFields - Field configurations for golfs.
 */

export const useLegalProtectionFields = () => {
  const fieldNames = [
    'company_name',
    'contract_number',
    'date_added',
    'entity',
    'site',
    'postal_code',
    'city',
    'global_company_turnover',
    'employees_count'
  ];

  const legalProtectionFields = fieldNames.map((name) => ({
    name: [name],
    rules: [{ required: false }],
    valuePropName: 'checked',
    input: <Checkbox />
  }));

  return { legalProtectionFields };
};
