import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback, useRef } from 'react';
import { FileExclamationOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useFiltersDataFetch } from '../../../../components/ColumnsDynamicFilters/useFiltersDatasFetch';

/**
 * `useColumns` is a custom React hook that provides a list of column configurations.
 *
 * The column titles are fetched using the `react-i18next` hook for internationalization.
 *
 * @hook
 * @returns {Array<object>} Returns an array of column configurations to be consumed by a table component.
 */

export const useColumns = (resourceName, dbExtraQueryFilter, populate) => {
  const { t } = useTranslation();
  const { dispatchAPI, company: companyId } = useAuthContext();
  const { message } = useErrorMessage();
  const { fetchFilters, filters, loading } = useFiltersDataFetch(
    resourceName,
    dbExtraQueryFilter,
    resourceName,
    populate
  );
  const [config, setConfig] = useState({});
  const isMounted = useRef(true);

  const renderAddress = (text, record) => {
    const { number, street, additional } = record || {};
    return number || street || additional
      ? `${number || ''} ${street || ''} ${additional || ''}`
      : '-';
  };

  const allColumns = [
    {
      title: t('properties.form.missing_documents'),
      key: 'missing_documents',
      dataIndex: ['missing_documents'],
      sorter: true,
      width: 150,
      render: (missing_documents) =>
        missing_documents ? (
          <FileExclamationOutlined
            style={{ fontSize: 18, color: 'var(--warningColor)' }}
          />
        ) : (
          '-'
        )
    },
    {
      title: t('golfs.form.company_name'),
      key: 'company_name',
      dataIndex: ['company', 'name'],
      sorter: true
    },
    {
      title: t('golfs.form.contract'),
      key: 'contract',
      dataIndex: ['contract', 'contract_number'],
      filters: filters?.contract?.map((number) => ({
        text: number.contract_number,
        value: number._id
      })),
      sorter: true
    },
    {
      title: t('golfs.form.validity_start_date'),
      key: 'validity_start_date',
      dataIndex: ['contract', 'validity_start_date'],
      render: (validity_start_date) =>
        validity_start_date
          ? dayjs(validity_start_date).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t('golfs.form.validity_end_date'),
      key: 'validity_end_date',
      dataIndex: ['contract', 'validity_end_date'],
      render: (validity_end_date) =>
        validity_end_date ? dayjs(validity_end_date).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('golfs.form.entity'),
      key: 'entity',
      dataIndex: ['entity', 'name'],
      filters: filters?.entity?.map((entity) => ({
        text: entity.name,
        value: entity._id
      })),
      sorter: true
    },
    {
      title: t('golfs.form.site'),
      key: 'site',
      dataIndex: ['site', 'name'],
      filters: filters?.site?.map((site) => ({
        text: site.name,
        value: site._id
      })),
      sorter: true
    },
    {
      title: t('golfs.form.golf_name'),
      key: 'golf_name',
      dataIndex: 'golf_name',
      sorter: true,
      filters: filters?.golf_name?.map((golf_name) => ({
        text: golf_name,
        value: golf_name
      }))
    },
    {
      title: t('golfs.form.address'),
      key: 'address',
      sorter: true,
      render: renderAddress
    },
    {
      title: t('golfs.form.postal_code'),
      key: 'postal_code',
      dataIndex: ['company', 'zip_code'],
      sorter: true,
      filters: filters?.postal_code?.map((postal_code) => ({
        text: postal_code,
        value: postal_code
      }))
    },
    {
      title: t('golfs.form.city'),
      key: 'city',
      dataIndex: ['company', 'city'],
      sorter: true,
      filters: filters?.city?.map((city) => ({
        text: city,
        value: city
      }))
    },
    {
      title: t('properties.form.country'),
      key: 'country',
      dataIndex: ['country'],
      sorter: true,
      filters: filters?.country?.map((country) => ({
        text: country,
        value: country
      }))
    },
    {
      title: t('golfs.form.total_insured_developed_area'),
      key: 'total_insured_developed_area',
      dataIndex: 'total_insured_developed_area',
      sorter: true,
      filters: filters?.total_insured_developed_area?.map((area) => ({
        text: area?.toString(),
        value: area?.toString()
      }))
    },

    {
      title: t('golfs.form.workforce_full_time_equivalent'),
      key: 'workforce_full_time_equivalent',
      dataIndex: 'workforce_full_time_equivalent',
      sorter: true,
      filters: filters?.workforce_full_time_equivalent?.map((workforce) => ({
        text: workforce?.toString(),
        value: workforce?.toString()
      }))
    },
    {
      title: t('golfs.form.company_registration_number'),
      key: 'company_registration_number',
      dataIndex: 'company_registration_number',
      sorter: true,
      filters: filters?.company_registration_number?.map((number) => ({
        text: number,
        value: number
      }))
    },
    {
      title: t('golfs.form.content'),
      key: 'content',
      dataIndex: 'content',
      sorter: true,
      filters: filters?.content?.map((content) => ({
        text: content,
        value: content
      }))
    },
    {
      title: t('golfs.form.revenue'),
      key: 'previous_year_revenue',
      dataIndex: 'previous_year_revenue',
      sorter: true,
      filters: filters?.previous_year_revenue?.map((revenue) => ({
        text: revenue?.toString(),
        value: revenue?.toString()
      }))
    },
    {
      title: t('golfs.form.date_added'),
      key: 'date_added',
      dataIndex: ['created_at'],
      filters: filters?.created_at?.map((date) => ({
        text: dayjs(date).format('DD/MM/YYYY'),
        value: date
      })),
      render: (created_at) =>
        created_at ? dayjs(created_at).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('vehicles.form.deletion_date'),
      key: 'deletion_date_effect',
      dataIndex: 'deletion_date_effect',
      sorter: true,
      filters: filters?.deletion_date_effect?.map((date) => ({
        text: dayjs(date).format('DD/MM/YYYY'),
        value: date
      })),
      render: (deletion_date_effect) =>
        deletion_date_effect
          ? dayjs(deletion_date_effect).format('DD/MM/YYYY')
          : '-'
    }
  ];

  const getConfig = useCallback(async () => {
    if (!isMounted.current) return;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/programme-configs/${companyId}/golfs-configs`
      });
      setConfig(data[0]);
    } catch (e) {
      if (isMounted.current) message('missing_parameters');
    }
  }, [dispatchAPI, message]);

  useEffect(() => {
    getConfig();
    fetchFilters();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const filteredColumns = allColumns.filter((column) => config[column.key]);

  return [filteredColumns, loading] || [];
};
