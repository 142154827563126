import { useState, useEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker, Input } from 'antd';
import { SelectEnumsReducer } from '../../components/SelectEnumsReducer';
import { SelectYesNoReducer } from '../../components/SelectYesNoReducer';
import { useGetEnums } from '../../../utils/getEnums';
import { SelectEntityV2 } from '../../components/SelectEntityV2';
import { SelectSiteV2 } from '../../components/SelectSiteV2';
import { SelectContract } from '../../components/SelectContract';
import { PromultiriskInsuranceContext } from './PromultiriskInsuranceContext';

const { TextArea } = Input;

/**
 * Generate fields configuration for a form.
 *
 * @function
 * @param {Form} form - The form instance.
 * @param {Object} state - The state of the form.
 * @param {Function} dispatch - The state setter.
 * @returns {Object} An object containing the fields configuration.
 */
export const useFields = (form, state, dispatch) => {
  const { t } = useTranslation();
  const { getEnums } = useGetEnums();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [enums, setEnums] = useState({});
  const dateFormat = 'DD-MM-YYYY';
  const tradKeyEnums = 'professional_multirisk_insurances.form.enums';

  const {
    isProtectedAgainstTheft,
    isFireProtection,
    isOtherTypologyActivities
  } = state;

  const {
    currentEntityContext,
    setCurrentEntityContext,
    entitiesContext,
    setEntitiesContext,
    currentContract,
    setCurrentContract
  } = useContext(PromultiriskInsuranceContext);

  const handleEntityChange = (value) => {
    setCurrentEntityContext(value);
    form.setFieldsValue({ site: undefined });
  };

  const handleContractChange = () => {
    form.setFieldsValue({ entity: undefined, site: undefined });
  };

  const resetFieldValues = useCallback(() => {
    const fieldResetRules = [
      {
        condition: !isProtectedAgainstTheft,
        path: ['if_is_protected_against_theft_details'],
        value: ''
      },
      {
        condition: !isFireProtection,
        path: ['if_is_fire_protection_details'],
        value: ''
      },
      {
        condition: !isOtherTypologyActivities,
        path: ['typology_of_activities_if_other'],
        value: ''
      }
    ];

    fieldResetRules.forEach(({ condition, path, value }) => {
      if (condition) {
        form.setFieldsValue({
          [path[0]]: value
        });
      }
    });
  }, [
    form,
    isProtectedAgainstTheft,
    isFireProtection,
    isOtherTypologyActivities
  ]);

  useEffect(() => {
    resetFieldValues();
  }, [resetFieldValues]);

  const fieldsInformations = [
    {
      label: ['contract_number'],
      name: ['contract'],
      rules: [{ required: true }],
      input: (
        <SelectContract
          dbKey="contract"
          programme_types="PROFESSIONAL_MULTIRISK_INSURANCE"
          setCurrentContract={setCurrentContract}
          currentEntityContext={currentEntityContext}
          handleContractChange={handleContractChange}
        />
      ),
      startWithDivider: {
        title: t('professional_multirisk_insurances.form.main_informations')
      }
    },
    {
      label: ['entity'],
      name: ['entity'],
      rules: [{ required: true }],
      input: (
        <SelectEntityV2
          dbKey="entity"
          permKey="programme-PROFESSIONAL_MULTIRISK_INSURANCE"
          currentContract={currentContract}
          setEntitiesContext={setEntitiesContext}
          handleEntityChange={handleEntityChange}
          currentEntityContext={currentEntityContext}
        />
      )
    },
    {
      label: ['site'],
      name: ['site'],
      input: (
        <SelectSiteV2
          dbKey="site"
          permKey="programme-PROFESSIONAL_MULTIRISK_INSURANCE"
          currentEntityContext={currentEntityContext}
          entitiesContext={entitiesContext}
        />
      )
    },
    {
      label: ['risk_site_name'],
      name: ['risk_site_name'],
      rules: [{ required: true }]
    },
    {
      label: ['risk_site_address'],
      name: ['risk_site_address'],
      rules: [{ required: true }]
    },
    {
      label: ['postal_code'],
      name: ['postal_code'],
      rules: [{ required: true }]
    },
    {
      label: ['city'],
      name: ['city'],
      rules: [{ required: true }]
    },
    {
      label: ['entry_date'],
      name: ['entry_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['total_insured_developed_area'],
      name: ['total_insured_developed_area'],
      rules: [{ required: true }],
      input: <Input type="Number" addonAfter="m²" />
    },
    {
      label: ['legal_status'],
      name: ['legal_status'],
      rules: [{ required: true }],
      input: (
        <SelectEnumsReducer
          dbKey={['legal_status']}
          enums={enums.legalStatus}
          tradKeyEnums={tradKeyEnums}
          dispatch={dispatch}
          field="isTenant"
          type="TOGGLE_STATE"
        />
      )
    },
    {
      label: ['typology_of_activities'],
      name: ['typology_of_activities'],
      rules: [{ required: true }],
      input: (
        <SelectEnumsReducer
          dbKey={['typology_of_activities']}
          enums={enums.typologyOfActivities}
          tradKeyEnums={tradKeyEnums}
          dispatch={dispatch}
          field="isOtherTypologyActivities"
          type="TOGGLE_STATE"
        />
      )
    },
    {
      label: ['typology_of_activities_if_other'],
      name: ['typology_of_activities_if_other'],
      rules: [{ required: isOtherTypologyActivities }],
      hidden: !isOtherTypologyActivities,
      input: (
        <TextArea
          autoSize={{
            minRows: 3,
            maxRows: 5
          }}
        />
      )
    },
    {
      label: ['financial_turnover'],
      name: ['financial_turnover'],
      rules: [{ required: true }],
      input: <Input type="Number" addonAfter="€" />
    },
    {
      label: ['content'],
      name: ['content'],
      rules: [{ required: true }],
      input: (
        <TextArea
          autoSize={{
            minRows: 1,
            maxRows: 5
          }}
        />
      )
    },
    {
      label: ['is_protected_against_theft'],
      name: ['is_protected_against_theft'],
      rules: [{ required: true }],
      input: (
        <SelectYesNoReducer
          dbKey={['is_protected_against_theft']}
          tradKeyEnums={tradKeyEnums}
          dispatch={dispatch}
          field="isProtectedAgainstTheft"
          type="TOGGLE_STATE"
        />
      )
    },
    {
      label: ['if_is_protected_against_theft_details'],
      name: ['if_is_protected_against_theft_details'],
      rules: [{ required: isProtectedAgainstTheft }],
      hidden: !isProtectedAgainstTheft,
      input: (
        <TextArea
          autoSize={{
            minRows: 3,
            maxRows: 5
          }}
        />
      )
    },
    {
      label: ['is_fire_protection'],
      name: ['is_fire_protection'],
      rules: [{ required: true }],
      input: (
        <SelectYesNoReducer
          dbKey={['is_fire_protection']}
          tradKeyEnums={tradKeyEnums}
          dispatch={dispatch}
          field="isFireProtection"
          type="TOGGLE_STATE"
        />
      )
    },
    {
      label: ['if_is_fire_protection_details'],
      name: ['if_is_fire_protection_details'],
      rules: [{ required: isFireProtection }],
      hidden: !isFireProtection,
      input: (
        <TextArea
          autoSize={{
            minRows: 3,
            maxRows: 5
          }}
        />
      )
    }
  ];

  const fetchEnums = useCallback(async () => {
    setIsFieldsLoading(true);
    const data = await getEnums('professional-multirisk-insurances');
    setEnums(data);
    setIsFieldsLoading(false);
  }, [getEnums]);

  useEffect(() => {
    fetchEnums();
  }, [fetchEnums]);

  return {
    fieldsInformations,
    isFieldsLoading
  };
};
