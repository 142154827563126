export const urls = {
  PROFESSIONAL_MULTIRISK_INSURANCE: 'professional-multirisk-insurances',
  LEGAL_PROTECTION_INSURANCES: 'legal-protection-insurances',
  HARDWARE_INSURANCE: 'hardware-insurances',
  CYBER_INSURANCE: 'cyber-insurances',
  EVENT_CANCELLATION: 'event-cancellations',
  HOLE_IN_ONE: 'hole-in-ones',
  MACHINERY_BREAKAGE: 'machinery-breakages',
  PROPERTY: 'properties',
  GOLF: 'golfs',
  LIGHT_VEHICLES: 'light-vehicles',
  HEAVY_VEHICLES: 'heavy-vehicles',
  OTHER_VEHICLES: 'other-vehicles',
  AUTOMISSIONS: 'automissions',
  CIVIL_LIABILITY_PRO: 'civil-liability-pro',
  CIVIL_LIABILITY_EXECUTIVE: 'civil-liability-executive',
  FACULTIES: 'faculties',
  FREIGHTED_GOODS: 'freighted-goods',
  AD_VALOREM: 'ad-valorem',
  CONSTRUCTION_INSURANCE: 'construction-insurance',
  OTHER_CONSTRUCTION: 'other-construction',
  PROPERTY_DEVELOPER: 'other-construction',
  GENERAL_CONTRACTOR: 'other-construction',
  CONSTRUCTION_COMPANY_AND_ENGINEERING: 'other-construction',
  INDIVIDUAL_HOUSES_BUILDER: 'individual-houses-builders',
  ENVIRONMENT_INSURANCE: 'environment-insurances'
};
