import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Tooltip } from 'antd';
import { DownloadOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { routes } from '../../../../utils/constants/adminRoutes';
import { MailingButtonVehicles } from '../components/MailingButtonVehicles';
import { ClaimButton } from '../../../components/ClaimButton';
import usePopConfirm from '../../utils/popConfirms';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { GenerateGreenCard } from '../../../../utils/GenerateGreenCard';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { insuranceList } from '../insuranceList';
import { useHistory } from '../../../../utils/historyUtils';

/**
 * Custom hook that returns extra buttons for a vehicle fleet.
 * @hook
 * @param {boolean} isDeleted - Indicates if the vehicle is deleted.
 * @param {object} vehicle - The vehicle object.
 * @param {string} vehicleUrl - The URL of the vehicle.
 * @param {string} exitDocuments - Exit required Documets keys.
 * @returns {object} - An object containing the extra buttons.
 */
export const useExtraButton = (
  isDeleted,
  vehicle,
  vehicleUrl,
  exitDocuments
) => {
  const { exitProgramWithFile, unarchiveProgram } = usePopConfirm('vehicles');
  const [visible, setVisible] = useState(false);
  const { setHistoryRefresh } = useAuthContext();
  const { updateHistoryCustom } = useHistory();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { id } = useParams();
  const { message } = useErrorMessage();

  const iconSize = 18;

  const handleGenerateGreenCard = async (vehicleId) => {
    await GenerateGreenCard(vehicleId, dispatchAPI, t, message);
    updateHistoryCustom('vehicles', id, t('buttons.green_card_downloaded'));
    setHistoryRefresh((prev) => !prev);
  };

  const extraButtons = (
    <>
      {!isDeleted && (
        <Link
          to={{
            pathname: `${routes.PROGRAMMES}/${vehicleUrl}/edit/${id}`
          }}
        >
          <Button type="primary">
            {`${t('buttons.edit')} `}
            <EditOutlined />
          </Button>
        </Link>
      )}
      {!isDeleted &&
        insuranceList.includes(vehicle?.contract.insurance_company) && (
          <Tooltip title={t('light_vehicle.actions.generate_green_card')}>
            <Button onClick={() => handleGenerateGreenCard(id)}>
              <DownloadOutlined style={{ fontSize: iconSize }} />
              {t('light_vehicle.actions.generate_green_card')}
            </Button>
          </Tooltip>
        )}

      {!isDeleted && (
        <>
          <MailingButtonVehicles
            asset={vehicle}
            templateName="GreenCard"
            tradKey="sending_green_card"
          />
          <ClaimButton id={id} url={vehicleUrl} claimType="vehicle" />
          <MailingButtonVehicles
            asset={vehicle}
            templateName="Blank"
            tradKey="specific_demand"
          />
        </>
      )}
      {vehicle &&
        !vehicle.deleted &&
        exitProgramWithFile({
          visible,
          setVisible,
          exitDocuments
        })}
      {vehicle && vehicle.deleted && unarchiveProgram()}
    </>
  );
  return { extraButtons };
};
