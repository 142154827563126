import { Route, Routes } from 'react-router-dom';
import { ShowCyberInsurance } from './ShowCyberInsurance';
import { ListCyberInsurance } from './ListCyberInsurance';
import { CyberInsurancesCreateUpdate } from './CyberInsurancesCreateUpdate';
import { Exception } from '../../../components';

/**
 * A router component for handling civil-liability-related routes.
 *
 * @component
 * @returns {JSX.Element} The JSX element representing the CivilLiabilityProRouter.
 */

export const CyberInsuranceRouter = () => (
  <Routes>
    <Route
      path="edit/:id"
      element={<CyberInsurancesCreateUpdate purpose="edit" />}
    />
    <Route path="show/:id" element={<ShowCyberInsurance />} />
    <Route index element={<ListCyberInsurance />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
