// TODO: validate INDIVIDUAL_HOUSES_BUILDER when ready.
import { useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * A custom menu component for adding subscriptions.
 * @component
 * @returns {JSX.Element} The JSX element representing the menu.
 */
export const MenuAddSubscription = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const menu1 = { CIVIL_LIABILITY_PRO: 'civil-liability/pro' };
  const menu2 = { CIVIL_LIABILITY_EXECUTIVE: 'civil-liability/executive' };
  const menu3 = { OWN_ACCOUNTS: 'transport/own-accounts' };
  const menu4 = { FREIGHTED_GOODS: 'transport/freighted-goods' };
  const menu5 = { FACULTY: 'transport/faculty' };
  const menu6 = { OTHER: 'construction' };
  const menu7 = { CYBER_INSURANCE: 'cyber-insurance' };
  // const menu8 = { HARDWARE_INSURANCE: 'hardware-insurance' };
  // const menu9 = {
  //   PROFESSIONAL_MULTIRISK_INSURANCE: 'professional-multirisk-insurance'
  // };
  // const menu10 = { ASSEMBLY_TESTING_INSURANCE: 'assembly-testing-insurance' };
  // const menu11 = { LEGAL_PROTECTION_INSURANCE: 'legal-protection-insurance' };
  // const menu12 = { ENVIRONMENT_INSURANCE: 'environment-insurance' };
  // const menu13 = { INDIVIDUAL_HOUSES_BUILDER: 'individual-houses-builder' };

  const getItem = (label, key, children, type) => ({
    key,
    children,
    label,
    type
  });

  const items = [
    getItem(t('subscriptions.form.subscription_request'), 'add_subscription', [
      getItem(
        t('subscriptions.form.CIVIL_LIABILITY'),
        'g1',
        [
          getItem(
            t('subscriptions.form.CIVIL_LIABILITY_PRO'),
            `${menu1.CIVIL_LIABILITY_PRO}`
          ),
          getItem(
            t('subscriptions.form.CIVIL_LIABILITY_EXECUTIVE'),
            `${menu2.CIVIL_LIABILITY_EXECUTIVE}`
          )
        ],
        'group'
      ),
      getItem(
        t('transport.title'),
        'g2',
        [
          getItem(t('subscriptions.form.OWN_ACCOUNT'), `${menu3.OWN_ACCOUNTS}`),
          getItem(
            t('subscriptions.form.FREIGHTED_GOODS'),
            `${menu4.FREIGHTED_GOODS}`
          ),
          getItem(t('subscriptions.form.FACULTY'), `${menu5.FACULTY}`)
        ],
        'group'
      ),
      getItem(
        t('construction.title'),
        'g3',
        [
          // getItem(
          //   t('subscriptions.form.INDIVIDUAL_HOUSES_BUILDER'),
          //   `${menu13.INDIVIDUAL_HOUSES_BUILDER}`
          // ),
          getItem(t('subscriptions.form.OTHER'), `${menu6.OTHER}`)
        ],
        'group'
      ),
      getItem(
        t('cyber_insurances.title'),
        'g4',
        [
          getItem(
            t('subscriptions.form.CYBER_INSURANCE'),
            `${menu7.CYBER_INSURANCE}`
          )
        ],
        'group'
      )
      // getItem(
      //   t('hardware_insurances.title'),
      //   'g5',
      //   [
      //     getItem(
      //       t('subscriptions.form.HARDWARE_INSURANCE'),
      //       `${menu8.HARDWARE_INSURANCE}`
      //     )
      //   ],
      //   'group'
      // ),
      //   getItem(
      //     t('professional_multirisk_insurances.title'),
      //     'g6',
      //     [
      //       getItem(
      //         t('subscriptions.form.PROFESSIONAL_MULTIRISK_INSURANCE'),
      //         `${menu9.PROFESSIONAL_MULTIRISK_INSURANCE}`
      //       )
      //     ],
      //     'group'
      //   ),
      //   getItem(
      //     t('assembly_testing_insurances.title'),
      //     'g7',
      //     [
      //       getItem(
      //         t('subscriptions.form.ASSEMBLY_TESTING_INSURANCE'),
      //         `${menu10.ASSEMBLY_TESTING_INSURANCE}`
      //       )
      //     ],
      //     'group'
      //   ),
      //   getItem(
      //     t('legal_protection.title'),
      //     'g8',
      //     [
      //       getItem(
      //         t('subscriptions.form.LEGAL_PROTECTION_INSURANCE'),
      //         `${menu11.LEGAL_PROTECTION_INSURANCE}`
      //       )
      //     ],
      //     'group'
      //   ),
      //   getItem(
      //     t('environment_insurances.title'),
      //     'g9',
      //     [
      //       getItem(
      //         t('subscriptions.form.ENVIRONMENT_INSURANCE'),
      //         `${menu12.ENVIRONMENT_INSURANCE}`
      //       )
      //     ],
      //     'group'
      //   )
      //
    ])
  ];

  const handleNavigate = ({ key }) => {
    const allMenus = [
      menu1,
      menu2,
      menu3,
      menu4,
      menu5,
      menu6,
      menu7
      // menu8,
      // menu9,
      // menu10,
      // menu11,
      // menu12,
      // menu13
    ];
    // const allMenus = [menu1, menu2, menu3, menu4, menu5, menu6, menu7];
    const foundMenu = allMenus.find((menu) => Object.values(menu)[0] === key);
    const originalKey = foundMenu ? Object.keys(foundMenu)[0] : null;
    if (originalKey) {
      const navigation = key;
      navigate(`/subscriptions/${navigation}/create`);
    }
  };

  return (
    <Menu
      mode="vertical"
      items={items}
      onClick={handleNavigate}
      className="new-subscription-menu"
    />
  );
};
