import { useListContent } from './listContent';
import { useExtraButtons } from '../extraButtons';
import { VehiclesClaimShow } from '../vehiclesClaimShow';

/**
 * Renders the HeavyVehiclesShow component.
 * @returns {React.Component} The rendered HeavyVehiclesShow component.
 */
export const HeavyVehiclesShow = () =>
  VehiclesClaimShow({
    useListContent,
    pathname: 'heavy-vehicles',
    useExtraButtons,
    fleetType: 'HEAVY_VEHICLE'
  });
