import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListMails from './ListMails';
import ShowMail from './ShowMail';
import { Exception } from '../../components';

/**
 * MailRouter component.
 *
 * @returns {JSX.Element} The rendered MailRouter component.
 */
export const MailRouter = () => (
  <Routes>
    <Route index element={<ListMails />} />
    <Route path="/show/:id" element={<ShowMail />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
