import { useCallback } from 'react';
import dayjs from 'dayjs';
import { useHistory } from '../../../../utils/historyUtils';

/**
 * Custom hook that returns the configuration objects for getting and creating resources.
 *
 * @param {function} dispatch - The dispatch function from the Redux store.
 * @param {string} company - The company ID.
 * @param {string} program - The program type.
 * @param {string} subProgram - The sub-program type.
 * @returns {object} - The configuration objects for getting and creating resources.
 */

export const useConfig = (
  company,
  program,
  item,
  setSitesAddresses,
  stringifySitesAddresses
) => {
  const { setInitialHistory, createHistory, updateHistory } = useHistory();
  const onGetResource = {
    setFields: useCallback(
      (data) => {
        setInitialHistory(data);
        setSitesAddresses(
          stringifySitesAddresses(
            data?.entry_id?.sites_adress_and_if_under_authorization
          )
        );
        return {
          ...data,
          sinister_date: data?.sinister_date && dayjs(data?.sinister_date)
        };
      },
      [setInitialHistory]
    )
  };
  const onCreateResource = {
    setBody: useCallback(
      (data) => ({
        ...data,
        company,
        programme_types: program,
        entry_id: item?._id,
        opening_date: dayjs(),
        city: item?.city,
        entity: item?.entity,
        ...createHistory('claim', 'environment-insurances', item?._id)
      }),
      [company, program, item, createHistory]
    )
  };
  const onUpdateResource = {
    setBody: useCallback(
      (data) => ({
        ...data,
        ...updateHistory('claim')
      }),
      [updateHistory]
    )
  };

  return {
    onGetResource,
    onCreateResource,
    onUpdateResource
  };
};
