import moment from 'moment';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook for generating a list of claim-related labels and contents from claim data.
 * @hook
 * @param {Object} data - The data containing claim-related information.
 * @param {string} data.claim_type - The type of claim.
 * @param {Date} data.sinister_date - The date of the sinister.
 * @param {Date} data.opening_date - The opening date of the claim.
 * @param {Object} data.customer_manager - The customer manager object.
 * @param {Object} data.unit_manager - The unit manager object.
 * @param {string} data.client_reference - The client reference for the claim.
 * @returns {Array} An array of claim-related labels and contents.
 */

export const useListContentClaim = (data = {}) => {
  const { t } = useTranslation();
  const {
    claim_type,
    sinister_date,
    opening_date,
    customer_manager,
    unit_manager,
    client_reference,
    insurance_company_reference,
    entity,
    site,
    sinister_location,
    operator_name,
    personal_injury
  } = data;

  const customerManagerName = customer_manager
    ? `${customer_manager.first_name} ${customer_manager.last_name}`
    : '-';
  const unit_managerName = unit_manager
    ? `${unit_manager.first_name} ${unit_manager.last_name}`
    : '-';

  const labels = [
    {
      label: 'claims.form.claim_type',
      span: 1,
      content: claim_type ? t(`claims.form.${claim_type}`) : '-'
    },
    {
      label: 'claims.form.sinister_date',
      span: 1,
      content:
        (sinister_date && moment(sinister_date).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'claims.form.opening_date',
      span: 1,
      content:
        (opening_date && moment(opening_date).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'claims.form.customer_manager',
      span: 1,
      content: (customerManagerName && customerManagerName) || '-'
    },
    {
      label: 'claims.form.unit_manager',
      span: 1,
      content: (unit_managerName && unit_managerName) || '-'
    },
    {
      label: 'claims.form.client_reference',
      span: 1,
      content: client_reference
    },
    {
      label: 'claims.form.insurance_company_reference',
      span: 1,
      content: insurance_company_reference
    },
    {
      label: 'claims.form.entity',
      span: 1,
      content: entity && entity.name ? entity.name : '-'
    },
    {
      label: 'claims.form.site',
      span: 1,
      content: site && site.name ? site.name : '-'
    },
    {
      label: 'claims.form.sinister_location',
      span: 1,
      content: (sinister_location && sinister_location) || '-'
    },
    {
      label: 'claims.form.operator_name',
      span: 1,
      content: (operator_name && operator_name) || '-'
    },
    {
      label: 'claims.form.personal_injury',
      span: 1,
      content: personal_injury ? t(`claims.form.${personal_injury}`) : '-'
    }
  ];

  return labels;
};
