import { Route, Routes } from 'react-router-dom';
import { ListOwnAccounts } from './ListOwnAccounts';
import { OwnAccountsCreateUpdate } from './OwnAccountsCreateUpdate';
import { TransportShow } from '../TransportShow';
import { Exception } from '../../../../components';

/**
 * `OwnAccountsRouter` is a component responsible for defining the routing structure for Ad Valorem related pages.
 *
 * @component
 * @returns {JSX.Element} JSX elements representing the Ad Valorem router.
 */

export const OwnAccountsRouter = () => (
  <Routes>
    <Route
      path="edit/:id"
      element={<OwnAccountsCreateUpdate purpose="edit" />}
    />
    <Route
      path="create/:id"
      element={<OwnAccountsCreateUpdate purpose="create" />}
    />
    <Route
      path="show/:id"
      element={<TransportShow transportType="own-accounts" />}
    />
    <Route index element={<ListOwnAccounts />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
