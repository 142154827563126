import { Route, Routes } from 'react-router-dom';
import { ProMultiriskInsuranceCreateUpdateClaim } from './ProMultiriskInsuranceCreateUpdateClaim';
import { ListProMultiriskInsuranceClaim } from './ListProMultiriskInsuranceClaim';
import { ProMultiriskInsuranceClaimShow } from './ProMultiriskInsuranceClaimShow';
import { Exception } from '../../../components';

/**
 * A router component for handling hardware insurance related routes.
 *
 * @component
 * @returns {JSX.Element} The JSX element representing the ProfessionalMultiriskInsuranceClaimRouter.
 */

export const ProfessionalMultiriskInsuranceClaimRouter = () => (
  <Routes>
    <Route
      path="create/:id"
      element={<ProMultiriskInsuranceCreateUpdateClaim purpose="create" />}
    />
    <Route
      path="edit/:id"
      element={<ProMultiriskInsuranceCreateUpdateClaim purpose="edit" />}
    />
    <Route path="show/:id" element={<ProMultiriskInsuranceClaimShow />} />
    <Route index element={<ListProMultiriskInsuranceClaim />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
