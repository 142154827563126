import { Building, Departure, Arrival, TextNewLine } from '@carbon/icons-react';
import dayjs from 'dayjs';

export const refConfig = {
  Property: {
    icons: [Building, Building],
    getInfo: (matchingProgramme) => [
      matchingProgramme?.asset_name,
      matchingProgramme?.city
    ]
  },
  Golf: {
    icons: [Building, Building],
    getInfo: (matchingProgramme) => [
      matchingProgramme?.entity?.name,
      matchingProgramme?.golf_name
    ]
  },
  'Machinery-breakage': {
    icons: [Building, Building],
    getInfo: (matchingProgramme) => [
      matchingProgramme?.entity?.name,
      matchingProgramme?.site?.name
    ]
  },
  'Hole-in-one': {
    icons: [Building, Building],
    getInfo: (matchingProgramme) => [
      matchingProgramme?.entity?.name,
      matchingProgramme?.site?.name
    ]
  },
  'Event-cancellation': {
    icons: [Building, Building],
    getInfo: (matchingProgramme) => [
      matchingProgramme?.entity?.name,
      matchingProgramme?.site?.name
    ]
  },
  'Professional-multirisk-insurance': {
    icons: [TextNewLine, TextNewLine],
    getInfo: (matchingProgramme) => [
      matchingProgramme?.entity?.name,
      matchingProgramme?.site?.name
    ]
  },
  AD_VALOREM: {
    icons: [Departure, Arrival],
    getInfo: (matchingProgramme) => [
      dayjs(matchingProgramme?.departure_date).format('DD/MM/YYYY'),
      dayjs(matchingProgramme?.estimated_delivery_date).format('DD/MM/YYYY')
    ]
  },
  'Call-for-contribution': {
    icons: [TextNewLine, Building],
    getInfo: (matchingProgramme) => [
      matchingProgramme?.contract_number,
      matchingProgramme?.entity
        ? matchingProgramme?.entity.map((entity) => entity.name).join(', ')
        : '-'
    ]
  }
};
