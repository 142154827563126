import { useState } from 'react';
import { Button, Popconfirm, Tooltip, message as messageANTD } from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { FormDeleteProgrammesButton } from '../FormDeleteProgrammes';
import { useHistory } from '../../../utils/historyUtils';
import { FormDeleteProgrammesWithDocument } from '../FormDeleteProgramWithDocument';

/**
 * A hook to generate Popconfirm components for confirming actions.
 * @hook
 * @param {string} resourceName - The name of the resource.
 * @returns {object} An object containing functions to generate Popconfirm components.
 */
const usePopConfirm = (resourceName) => {
  const [visible, setVisible] = useState(false);
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { updateHistoryCustom } = useHistory();
  const iconSize = '18px';

  /**
   * Generates a exitProgram component for exiting a program.
   * @component
   * @property {boolean} visible - Whether the Popconfirm is visible.
   * @property {function} setVisible - Function to set the visibility of the Popconfirm.
   * @returns {JSX.Element} A Popconfirm component.
   */

  const exitProgramWithFile = ({ exitDocuments, idWithoutParams, rowKey }) => (
    <Popconfirm
      title={t('datatable.column.action.delete.title')}
      okText={t('datatable.column.action.delete.ok')}
      okButtonProps={{ type: 'danger' }}
      cancelText={t('datatable.column.action.delete.cancel')}
      icon={<WarningOutlined />}
    >
      <FormDeleteProgrammesWithDocument
        resourceId={id}
        resourceName={resourceName}
        visible={visible}
        setVisible={setVisible}
        exitDocuments={exitDocuments || []}
        rowKey={rowKey}
        idWithoutParams={idWithoutParams}
      />
    </Popconfirm>
  );

  const exitProgram = () => (
    <Popconfirm
      title={t('datatable.column.action.delete.title')}
      okText={t('datatable.column.action.delete.ok')}
      okButtonProps={{ type: 'danger' }}
      cancelText={t('datatable.column.action.delete.cancel')}
      icon={<WarningOutlined />}
    >
      <FormDeleteProgrammesButton
        resourceId={id}
        resourceName={resourceName}
        visible={visible}
        setVisible={setVisible}
      />
    </Popconfirm>
  );

  /**
   * Generates a Unarchive Program component for exiting a program.
   * @component
   * @param {string} programID - The ID of the program.
   * @returns {JSX.Element} A Popconfirm component.
   * @returns {JSX.Element} A Button component.
   * @returns {JSX.Element} A Tooltip component.
   */
  const unarchiveProgram = ({ idWithoutParams = '' } = {}) => {
    const reintegrate = async (idToSend) => {
      try {
        await dispatchAPI('PATCH', {
          url: `/${resourceName}/reintegrate/${idToSend}`
        });
        updateHistoryCustom(resourceName, idToSend, t('actions.reinstated'));
        messageANTD.success(t('datatable.column.action.unarchive.reintegree'));
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (e) {
        message(e);
      }
    };
    return (
      <Tooltip title={t('datatable.column.action.unarchive.ok')}>
        <Popconfirm
          title={t('datatable.column.action.unarchive.title')}
          okText={t('datatable.column.action.unarchive.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('datatable.column.action.unarchive.cancel')}
          onConfirm={() => reintegrate(id || idWithoutParams)}
          icon={<WarningOutlined />}
        >
          {!idWithoutParams ? (
            <Button>{t('datatable.column.action.unarchive.ok')}</Button>
          ) : (
            <CheckCircleOutlined
              style={{ color: 'var(--addColor)', fontSize: iconSize }}
            />
          )}
        </Popconfirm>
      </Tooltip>
    );
  };

  return { exitProgram, unarchiveProgram, exitProgramWithFile };
};

export default usePopConfirm;
