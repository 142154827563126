import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton, Row, Col, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useListContent } from './utils/listContentHeavyVehicles';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../../components';
import { Documents } from '../../../components/Documents';
import { Drivers } from '../components/Drivers';
import { History } from '../../../components/History';
import { useExtraButton } from '../utils/extraButtons';
import { vehiclesExitDocuments } from '../utils/constants';

/**
 * HeavyVehicleShow Component.
 * Displays details of a specific heavy vehicle including its main information, associated drivers, and vehicle documents.
 *
 * @component
 *
 * @returns {ReactElement} Rendered component.
 */
export const HeavyVehicleShow = () => {
  const draggerFilesKeysOverRide = [
    'vehicle_registration_document',
    'order_form',
    'sale_certificate',
    'recovery_certificate',
    'other'
  ];
  const draggerFilesKeysMandatory = [];
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [vehicle, setVehicle] = useState();
  const [principal_driver, setPrincipal_driver] = useState();
  const [secondary_drivers, setSecondary_drivers] = useState();
  const [isDeleted, setIsDeleted] = useState(false);
  const listContent = useListContent(vehicle);
  const { extraButtons } = useExtraButton(
    isDeleted,
    vehicle,
    'light-vehicles',
    vehiclesExitDocuments
  );

  const getVehicle = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/vehicles/${id}?populate=customer_manager,unit_manager,contract,entity,entity.company,site,principal_driver,secondary_drivers`
      });
      setVehicle(data);
      setPrincipal_driver(data.principal_driver);
      setSecondary_drivers(data.secondary_drivers);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getVehicle();
    })();
  }, [getVehicle]);

  useEffect(() => {
    if (vehicle) setIsDeleted(vehicle?.deleted);
  }, [vehicle]);

  return (
    <>
      <PageHeaderCustom
        title={`${t('number')} ${vehicle?.unit_counter || 'xxxx'} ${
          vehicle?.vehicle_brand || ''
        } ${vehicle?.vehicle_model || ''} ${
          vehicle?.registration_or_serial_number || ''
        }`}
        extra={extraButtons}
      />
      <ContentCustom>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={12}>
            <Skeleton loading={isLoading} title={0} paragraph={0} active />
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <Row gutter={[0, 16]}>
                <Card>
                  <DescriptionList
                    data={listContent}
                    translate
                    layout="vertical"
                    title={t('vehicles.form.main_informations')}
                  />
                </Card>
                <Drivers
                  principal_driver={principal_driver}
                  secondary_drivers={secondary_drivers}
                />
              </Row>
            </Skeleton>
          </Col>
          <Col xs={24} xl={12}>
            <Documents
              idWithoutParams={id}
              baseUrl="vehicles"
              resource="Vehicle"
              draggerFilesKeysMandatory={draggerFilesKeysMandatory}
              draggerFilesKeysOverRide={draggerFilesKeysOverRide}
            />
            <Card title={t('subscriptions.form.history')} size="small">
              <History route="vehicles" />
            </Card>
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};
