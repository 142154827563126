import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select, Form } from 'antd';

const { Option } = Select;
const { Item } = Form;

/**
 * A custom select component for choosing values from a list of enums.
 * @component
 * @param {object} props - The component's props.
 * @param {string} props.dbKey - The key used to fetch the list of enums.
 * @param {object} props.enums - The list of enums.
 * @returns {JSX.Element} The JSX element representing the select component.
 */
export const SelectEnumsReducer = ({
  dbKey,
  enums,
  isMultiple,
  tradKeyEnums,
  dispatch,
  field,
  type
}) => {
  const { t } = useTranslation();
  const conditionSerlection = (key) => {
    if (key[0] === 'legal_status') {
      return 'TENANT';
    }
    return 'OTHER';
  };
  const handleChange = (value) => {
    const condition = value === conditionSerlection(dbKey);
    dispatch({
      type,
      field,
      payload: condition
    });
  };

  if (isMultiple) {
    return (
      <Item name={dbKey}>
        {enums ? (
          <Select
            mode="multiple"
            showSearch
            filterOption={(inputValue, option) =>
              (option?.title ?? '')
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            }
            onChange={handleChange}
          >
            {enums.map((v) => {
              const title = tradKeyEnums && t(`${tradKeyEnums}.${v}`);
              return (
                <Option key={v} value={v} title={title}>
                  {title}
                </Option>
              );
            })}
          </Select>
        ) : null}
      </Item>
    );
  }
  return (
    <Item name={dbKey}>
      {enums ? (
        <Select onChange={handleChange}>
          {enums.map((v) => (
            <Option key={v} value={v}>
              {t(`${tradKeyEnums}.${v}`)}
            </Option>
          ))}
        </Select>
      ) : null}
    </Item>
  );
};

SelectEnumsReducer.propTypes = {
  dbKey: PropTypes.arrayOf(PropTypes.string).isRequired,
  enums: PropTypes.arrayOf(PropTypes.string).isRequired,
  isMultiple: PropTypes.bool,
  tradKeyEnums: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

SelectEnumsReducer.defaultProps = {
  isMultiple: false
};
