import PropTypes from 'prop-types';
import { useState, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { useFields } from './utils/fieldsContributions';
import { useAuthContext } from '../../contexts/AuthContext';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewerWithoutForm';
import { useConfig } from './utils/createUpdateConfig';

/**
 * Component for creating or updating calls for contribution builder insurance subscriptions.
 * @component
 * @param {Object} props - The component's properties.
 * @param {string} props.purpose - The purpose of the component, either "create" or "edit".
 * @returns {JSX.Element} The JSX element for creating or updating calls for contribution builder insurance subscriptions.
 */
export const ContributionsCreateUpdate = ({ purpose }) => {
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const { company } = useAuthContext();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const documentType = 'CALL_FOR_CONTRIBUTION';

  const initialState = {
    contractId: null,
    contracts: [],
    entity: []
  };

  const formStateReducer = (state, action) => {
    switch (action.type) {
      case 'SET_ENTITY':
        return { ...state, entity: action.payload };
      case 'SET_CONTRACT_ID':
        return { ...state, contractId: action.payload };
      case 'SET_CONTRACTS':
        return { ...state, contracts: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(formStateReducer, initialState);

  const { fields } = useFields(
    isFieldsLoading,
    setIsFieldsLoading,
    state,
    dispatch,
    form,
    purpose
  );

  const config = useConfig(company, state, documentType, dispatch);

  return (
    <CreateUpdateContainer
      title={t(`call_for_contributions.form.${purpose}`)}
      form={form}
      fields={fields}
      purpose={purpose}
      tradKey="call_for_contributions"
      baseUrl="call-for-contributions"
      resource="call-for-contributions"
      populate="?populate=documents.file"
      config={config}
      withFilesManager={false}
      messageOnSuccess
      successMessageKey="call_for_contributions.form.success"
    />
  );
};

ContributionsCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
