import { Route, Routes } from 'react-router-dom';
import { ShowOtherConstruction } from './ShowOtherConstruction';
import { ListOtherConstruction } from './ListOtherConstruction';
import { ConstructionOtherCreateUpdate } from './ConstructionOtherCreateUpdate';
import { Exception } from '../../../../components';

export const OtherConstructionRouter = () => (
  <Routes>
    <Route
      path="/general-contractor/edit/:id"
      element={
        <ConstructionOtherCreateUpdate
          purpose="edit"
          subProgramme="GENERAL_CONTRACTOR"
        />
      }
    />
    <Route
      path="/general-contractor/show/:id"
      element={<ShowOtherConstruction />}
    />
    <Route
      path="/property-developer/edit/:id"
      element={
        <ConstructionOtherCreateUpdate
          purpose="edit"
          subProgramme="PROPERTY_DEVELOPER"
        />
      }
    />
    <Route
      path="/property-developer/show/:id"
      element={<ShowOtherConstruction />}
    />
    <Route
      path="/construction-company-and-engineering/edit/:id"
      element={
        <ConstructionOtherCreateUpdate
          purpose="edit"
          subProgramme="CONSTRUCTION_COMPANY_AND_ENGINEERING"
        />
      }
    />
    <Route
      path="/construction-company-and-engineering/show/:id"
      element={<ShowOtherConstruction />}
    />
    <Route index element={<ListOtherConstruction />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
