import { useRef, useEffect, useState } from 'react';
import { Select, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { MultiSelectEntityByContractReducer } from './MultiSelectEntityByContractReducer';
import { MultiSelectSiteByEntityByContractReducer } from './MultiSelectSiteByEntityByContractReducer';
import { GenericSelectEnums } from '../../components/GenericSelectEnums';
import { findContractById } from './helpersFunctions';

const { Option } = Select;

/**
 * `useFields` is a custom hook that provides fields for forms and manages their associated states.
 *
 * @hook
 * @property {Array} fields - An array of field configurations for the form.
 * @property {boolean} isFieldsLoading - A flag to determine whether the form fields are being loaded.
 * @property {function} setSelectedType - A function to set the selected type.
 * @property {string} selectedType - The selected type.
 * @property {string} selectedProgramme - The selected programme.
 * @property {function} setSelectedProgramme - A function to set the selected programme.
 * @property {boolean} isNotMandatory - A flag to determine whether the field is mandatory.
 * @property {function} setIsNotMandatory - A function to set the mandatory field.
 * @property {string} contractId - The contract ID.
 * @property {function} setContractId - A function to set the contract ID.
 * @property {function} setContractNumber - A function to set the contract number.
 * @property {boolean} isFieldsLoading - A flag to determine whether the fields are being loaded.
 * @property {function} setIsFieldsLoading - A function to set the fields loading state.
 * @property {boolean} isDisabled - A flag to determine whether the fields are disabled.
 * @property {boolean} containsContractDocuments - A flag to determine whether the contract contains documents.
 * @property {boolean} isVehicleAttestation - A flag to determine whether the vehicle is an attestation.
 * @property {function} setProgrammeTypes - A function to set the programme types.
 * @returns {Object} An object containing the fields and their associated states.
 * @returns {Array} fields - An array of field configurations for the form.
 * @returns {boolean} isFieldsLoading - A flag to determine whether the form fields are being loaded.
 */

export const useFields = (
  isFieldsLoading,
  setIsFieldsLoading,
  state,
  dispatch,
  form,
  purpose
) => {
  const isMounted = useRef(false);
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI, company } = useAuthContext();
  const [enums, setEnums] = useState({});

  const { contractId, contracts, entity } = state;

  const frequency = () => {
    const selectedContract = findContractById(contracts, contractId);
    return selectedContract?.contribution_frequency
      ? t(
          `call_for_contributions.enums.${selectedContract.contribution_frequency}`
        )
      : '';
  };
  useEffect(() => {
    form.setFieldsValue({
      entity: [],
      site: [],
      contribution_frequency: frequency()
    });
  }, [contractId]);

  const fields = [
    {
      name: ['document_type'],
      input:
        purpose === 'create' ? (
          <Input disabled placeholder={t(`call_for_contributions.title`)} />
        ) : (
          <GenericSelectEnums
            dbKey={['document_type']}
            enums={enums.documentType}
            tradKeyEnums="call_for_contributions.enums"
            placeholder={t(`call_for_contributions.title`)}
            isDisabled
          />
        )
    },
    {
      label: ['contract_number'],
      name: ['contract_number'],
      rules: [{ required: true }],
      initialValue: null,
      input: (
        <Select
          showSearch
          filterOption={(input, option) =>
            (option?.title.contractNumber ?? '')
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          onChange={(value) => {
            const parsedValue = JSON.parse(value);
            dispatch({
              type: 'SET_CONTRACT_ID',
              field: 'contractId',
              payload: parsedValue.id
            });
          }}
        >
          {contracts &&
            contracts.map((c) => (
              <Option
                key={c._id}
                value={JSON.stringify({
                  id: c._id,
                  contractNumber: c.contract_number
                })}
                title={JSON.stringify({
                  contractNumber: c.contract_number
                })}
              >
                {c.contract_number}
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['entity'],
      rules: [{ required: true }],
      input: (
        <MultiSelectEntityByContractReducer
          dbKey={['entity']}
          isDisabled={!contractId}
          contractId={contractId}
          contracts={contracts}
          dispatch={dispatch}
          field="entity"
          type="SET_ENTITY"
        />
      )
    },
    {
      name: ['site'],
      input: (
        <MultiSelectSiteByEntityByContractReducer
          dbKey={['site']}
          isDisabled={!contractId}
          contractId={contractId}
          contracts={contracts}
          entity={entity}
        />
      )
    },
    {
      label: 'contribution_type',
      name: ['contribution_type'],
      rules: [{ required: true }],
      input: (
        <GenericSelectEnums
          dbKey={['contribution_type']}
          enums={enums.contributionType}
          tradKeyEnums="call_for_contributions.enums"
        />
      )
    },
    {
      label: 'contribution_amount',
      name: ['contribution_amount'],
      rules: [{ required: true }],
      input: <Input type="number" addonAfter="€" />
    },
    {
      label: 'contribution_frequency',
      name: ['contribution_frequency'],
      rules: [{ required: true }],
      input:
        purpose === 'create' ? (
          <Input disabled />
        ) : (
          <GenericSelectEnums
            dbKey={['contribution_frequency']}
            enums={enums.contributionFrequency}
            tradKeyEnums="call_for_contributions.enums"
            isDisabled
          />
        )
    },
    {
      name: ['call_for_contribution'],
      rules: [
        {
          required: purpose === 'create'
        }
      ],
      input: 'File',
      maxFilesCount: 1,
      multipleFiles: false
    }
  ];

  const fetchData = async () => {
    setIsFieldsLoading(true);
    try {
      const [contractsResponse, enumsResponse] = await Promise.all([
        dispatchAPI('POST', {
          url: `/contracts/number/companies/${company}`
        }),
        dispatchAPI('GET', { url: '/call-for-contributions/enums' })
      ]);

      if (isMounted.current) {
        dispatch({
          type: 'SET_CONTRACTS',
          field: 'contracts',
          payload: contractsResponse.data
        });
        setEnums(enumsResponse.data);
      }
    } catch (e) {
      if (e.response && isMounted.current) message(e);
    } finally {
      if (isMounted.current) setIsFieldsLoading(false);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    fetchData();
    return () => {
      isMounted.current = false;
    };
  }, []);

  return {
    fields,
    isFieldsLoading
  };
};
