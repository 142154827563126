import React from 'react';
import { ListResource } from '../../components';
import { useColumns } from './columns';

/**
 * Renders a component that displays a list of mails.
 * @component
 * @returns {JSX.Element} The rendered ListMails component.
 */
const ListMails = () => {
  const { columns } = useColumns();

  return (
    <ListResource
      resourceName="mails"
      columns={columns}
      editAction={false}
      deleteAction={false}
      withCreateButton={false}
      headless={false}
    />
  );
};

export default ListMails;
