import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Col, Flex, Skeleton, Row, Steps } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useListContentClaim } from '../utils/listContentClaim';
import { useListContentCommentaries } from '../utils/listContentCommentaries';
import { useListContent, useListContentAddresses } from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../components';
import { Documents } from '../../components/Documents';
import {
  useListItemsStep,
  useListBeforeValidation
} from '../utils/listItemsStep';
import { handleStatusUtils } from '../utils/handleStatusUtils';
import { ModalClosure } from '../utils/ModalClosure';
import { PhotosClaims } from '../utils/PhotosClaims';
import { ShowClaimAccountingOverview } from '../ClaimAccountingOverview/ShowClaimAccountingOverview';
import { useExtraButtons } from '../extraButtons';
import { History } from '../../components/History';
import { useGetProgram } from '../../../utils/getProgram';
import { useProgramDataUpdater } from '../../../utils/programDataUpdater';
import { MailingButton } from '../../Mailing/MailingButton';

/**
 * Displays detailed information about a property claim, including claim information, property information,
 * pictures, and related actions.
 *
 * @component
 * @returns {React.ReactNode} Detailed view of the property claim.
 */
export const PropertyShow = () => {
  const targetUrl = 'property_claims';
  const { id } = useParams();
  const { dispatchAPI, permissions, verifPermissions } = useAuthContext();
  const { message } = useErrorMessage();
  const {
    current,
    setCurrent,
    onChange,
    toEnclosed,
    setToEnclose,
    changingStatus,
    setChangingStatus
  } = handleStatusUtils(targetUrl);
  const { t } = useTranslation();
  const { items } = useListItemsStep();
  const { itemsBeforeValidation } = useListBeforeValidation();
  const [property, setProperty] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [edit, setEdit] = useState(true);
  const [closure, setClosure] = useState(false);
  const [propertyData, setPropertyData] = useState({});

  const { fetchProgram } = useGetProgram({
    setIsLoading,
    setProgram: setProperty,
    programId: propertyData?.ID,
    route: 'properties'
  });

  const {
    setIsCompliant,
    setAccountingBalanced,
    setFeesAppealsBalanced,
    initialData
  } = useProgramDataUpdater(propertyData, setPropertyData);

  const listContentClaim = useListContentClaim(propertyData?.claim);
  const listContent = useListContent(property);
  const listContentAddresses = useListContentAddresses(property);
  const listContentCommentaries = useListContentCommentaries(
    propertyData?.claim
  );

  const getPropertyClaim = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/property_claims/documents/${id}`
      });
      setPropertyData({
        ...initialData(data)
      });
      setCurrent(data?.step);
      setToEnclose(data?.to_enclose);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (propertyData?.ID) {
      (async () => {
        await fetchProgram();
      })();
    }
  }, [propertyData?.ID]);

  useEffect(() => {
    (async () => {
      await getPropertyClaim();
    })();
  }, [
    current,
    propertyData?.accountingBalanced,
    propertyData.feesAppealsBalanced
  ]);

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setEdit(
        !verifPermissions('/claims/edit', permissions?.notPermittedRoutes)
      );
    }
  }, [permissions]);

  const { extraButtons } = useExtraButtons({
    isCompliant: propertyData?.isCompliant,
    setIsCompliant,
    current,
    edit,
    toEnclosed,
    setClosure,
    onChange,
    productClaim: propertyData?.claim,
    resource: 'Property_claim',
    baseUrl: 'property_claims',
    product: property,
    urlPathName: 'properties',
    accountingBalanced: propertyData?.accountingBalanced,
    feesAppealsBalanced: propertyData?.feesAppealsBalanced,
    changingStatus,
    setChangingStatus
  });

  return propertyData?.ID && property && !isLoading ? (
    <>
      <PageHeaderCustom
        title={`${propertyData?.claim?.unit_counter || 'xxxx'} - ${
          property?.asset_name
        } ${property?.city}`}
        extra={extraButtons}
      />
      <MailingButton
        asset={propertyData?.claim}
        templateName={changingStatus}
        tradKey={changingStatus}
        baseUrl="property_claims"
        changingStatus={changingStatus}
        setChangingStatus={setChangingStatus}
      />
      <ContentCustom>
        <Flex vertical gap="middle">
          {current !== undefined && current !== 6 ? (
            <Steps
              onChange={(step) => {
                onChange(step, id, edit);
              }}
              items={propertyData?.isCompliant ? items : itemsBeforeValidation}
              current={current}
            />
          ) : null}
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={12}>
              <Row gutter={[0, 16]}>
                <Card>
                  <DescriptionList
                    data={listContentClaim}
                    translate
                    layout="vertical"
                    title={t('claims.form.claim_informations')}
                  />
                </Card>
                <PhotosClaims baseUrl="property_claims" />
                <Documents
                  idWithoutParams={id}
                  baseUrl="property_claims"
                  resource="Property_claim"
                  mandatoryDocuments={
                    propertyData?.isComplaint
                      ? ['filing_complaint', 'claim_statement']
                      : ['claim_statement']
                  }
                  noDelete={current === 6}
                />
              </Row>
            </Col>
            <Col xs={24} xl={12}>
              <Row gutter={[0, 16]}>
                <Card title={t('claims.form.main_informations')}>
                  <DescriptionList
                    data={listContent}
                    translate
                    layout="vertical"
                  />
                </Card>
                <Card>
                  <DescriptionList
                    data={listContentAddresses}
                    translate
                    title={t('claims.form.address')}
                  />
                </Card>
                <Row gutter={[0, 16]}>
                  <Card>
                    <DescriptionList
                      data={listContentCommentaries}
                      translate
                      layout="vertical"
                      title={t('claims.form.commentaries')}
                    />
                  </Card>
                </Row>
                <Col span={24}>
                  <Card title={t('subscriptions.form.history')} size="small">
                    <History route="property_claims" />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={24}>
              {current >= 2 && propertyData?.isCompliant ? (
                <ShowClaimAccountingOverview
                  current={current}
                  buttonBelowTable={
                    current !== 6 && current !== undefined && edit === true
                  }
                  noActionColumnsButton={propertyData?.isClosed}
                  accountingBalanced={propertyData?.accountingBalanced}
                  setAccountingBalanced={setAccountingBalanced}
                  feesAppealsBalanced={propertyData?.feesAppealsBalanced}
                  setFeesAppealsBalanced={setFeesAppealsBalanced}
                  resourceName="property-claim-accounts"
                  url="property-claim-accounts"
                  resourceNameClaim="property_claims"
                  urlClaim="property_claims"
                  urlAccount="claim-accounts"
                  edit={edit}
                />
              ) : null}
            </Col>
          </Row>
        </Flex>
        <ModalClosure
          open={closure}
          onCancel={() => setClosure(false)}
          onOk={() => setClosure(false)}
          claimReferenceNumber={propertyData?.claim?.unit_counter}
        />
      </ContentCustom>
    </>
  ) : (
    <Skeleton active loading={isLoading} />
  );
};
