import { Checkbox } from 'antd';

/**
 * A custom hook that returns predefined field configurations.
 *
 * This configuration can be used in conjunction with form libraries or frameworks
 * that need a set of field definitions to render and validate form controls.
 *
 * @hook
 * @returns {Array<Object>} returns.golfFields - Field configurations for golfs.
 */

export const useMachineryBreakageFields = () => {
  const fieldNames = [
    'missing_documents',
    'company_name',
    'contract_number',
    'date_added',
    'entity',
    'site',
    'machinery_location_address',
    'postal_code',
    'city',
    'entry_date',
    'removal_date',
    'machinery_description',
    'value_new_tax_excl',
    'machinery_serial_number',
    'machinery_brand',
    'machinery_model',
    'initial_commissioning_date',
    'status',
    'periodic_revision',
    'periodic_revision_frequency',
    'deletion_date_effect'
  ];

  const machineryBreakageFields = fieldNames.map((name) => ({
    name: [name],
    rules: [{ required: false }],
    valuePropName: 'checked',
    input: <Checkbox />
  }));

  return { machineryBreakageFields };
};
