import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { outOfNavRoutes, routes } from '../utils/constants/userRoutes';
import { Login } from '../routes/login';
import { NotificationDashboard } from '../routes/home/NotificationDashboard';
import { Exception } from '../components';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { WorkflowRouter } from '../routes/workflows/WorkflowRouter';
import { Settings } from '../routes/settings/settings';
import { UserRouter as UsrRouter } from '../routes/users/UserRouter';
import { UserTheme } from '../assets/styles/Theme/UserTheme';
import PostPwdForm from '../routes/login/PostPwdForm';
import RequireAuth from './RequireAuth';
import { MailInBrowser } from '../routes/MailinBrowser/MailInBrowser';
import { AgricultureCreateUpdate } from '../routes/agriculture/AgricultureCreateUpdate';
import InterruptionGolf from '../routes/interruption-golf/Interruption-golf';

export const UserRouter = () => {
  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'user';
    }
  }, []);

  return (
    <BrowserRouter>
      <UserTheme />
      <Routes>
        <Route
          path={`${outOfNavRoutes.MAIL_IN_BROWSER}/:token`}
          component={MailInBrowser}
        />
        <Route
          path={`${outOfNavRoutes.PASSWORD_CREATION}/:urlToken`}
          element={<PostPwdForm />}
        />
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.AGRICULTURE}/*`}
          element={<AgricultureCreateUpdate purpose="create" />}
        />
        <Route
          path={`${outOfNavRoutes.INTERRUPTION_GOLF}/*`}
          element={<InterruptionGolf purpose="create" />}
        />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route element={<RequireAuth />}>
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          <Route path={outOfNavRoutes.SETTINGS} element={<Settings />} />
          <Route path={`${routes.USERS}/*`} element={<UsrRouter />} />
          <Route path={`${routes.WORKFLOWS}/*`} element={<WorkflowRouter />} />
          <Route path={routes.HOME} element={<NotificationDashboard />} />
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
