import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMailingFields } from './MailingFields';
import { CreateUpdateContainer } from '../../../../../components/CreateUpdateContainerV3/CreateUpdateContainerSendMail';
import 'react-quill/dist/quill.snow.css';
import { useHistory } from '../../../../../utils/historyUtils';

export const Mailing = ({
  isModalMailingOpen,
  setIsModalMailingOpen,
  idWithoutParams,
  templateMail,
  datas,
  templateName,
  isModal,
  closeModal,
  targetUrl
}) => {
  if (!isModalMailingOpen) return null;
  const baseUrl = 'vehicles';
  const { t } = useTranslation();
  const { id } = useParams();
  const [editorState, setEditorState] = useState();
  const [mailObject, setMailObject] = useState();
  const { updateHistoryCustom } = useHistory();
  const [templateReLoaded, setTemplateReLoaded] = useState(false);
  const [recipientMail, setRecipientMail] = useState();
  const { mailingFields } = useMailingFields(
    editorState,
    setEditorState,
    targetUrl || baseUrl,
    setRecipientMail,
    mailObject
  );

  const handleUpdateHistory = () => {
    let messageTosend;
    let resource = 'vehicles';
    if (templateName === 'sending_green_card') {
      messageTosend = t('buttons.green_card_sent');
    }
    if (templateName === 'specific_demand') {
      messageTosend = t('buttons.specific_demand_sent');
    }
    if (templateName === 'sending_attestation') {
      messageTosend = t('buttons.attestation_sent');
    }
    if (targetUrl) {
      resource = targetUrl;
    }
    updateHistoryCustom(resource, id || idWithoutParams, messageTosend);
  };

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        sender: `${datas?.unit_manager?.first_name || ''} ${
          datas?.unit_manager?.last_name || ''
        }`,
        sender_email_address: datas?.unit_manager?.email,
        mail_object: mailObject && mailObject,
        template_content: editorState && editorState
      })
    },
    onCreateResource: {
      setBody: (data) => {
        handleUpdateHistory();
        return {
          ...data,
          template: templateName,
          datas
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => {
        handleUpdateHistory();
        return {
          ...data,
          template: templateName,
          datas
        };
      }
    }
  };

  const resetEditorContent = () => {
    setTemplateReLoaded(!templateReLoaded);
  };
  useEffect(() => {
    if (templateMail[0]) {
      const processedTemplateContent = templateMail[0]?.template_content
        .replace('{contract_number}', datas?.contract?.contract_number)
        .replace('{vehicle_brand}', datas?.vehicle_brand)
        .replace('{vehicle_model}', datas?.vehicle_model)
        .replace(
          '{registration_or_serial_number}',
          datas?.registration_or_serial_number
        )
        .replace('{unit_counter', datas?.unit_counter || 'xxxx')
        .replace(
          '{singatureUnitManager}',
          `${datas?.unit_manager?.first_name || ''} ${
            datas?.unit_manager?.last_name || ''
          }`
        );
      setMailObject(templateMail[0]?.object);
      setEditorState(processedTemplateContent);
    }
  }, [templateMail, datas]);
  return (
    <Modal
      footer={null}
      title={t('mailing.send_an_email')}
      open={isModalMailingOpen}
      onOk={() => setIsModalMailingOpen(false)}
      onCancel={() => setIsModalMailingOpen(false)}
      className="mail-modal"
    >
      {templateMail && editorState && mailObject && (
        <>
          <CreateUpdateContainer
            fields={mailingFields}
            purpose="edit"
            resource="Vehicle"
            baseUrl={baseUrl}
            tradKey="mailing"
            config={config}
            withFilesManager={false}
            WithPageHeaderCustom={false}
            idWithoutParams={idWithoutParams}
            templateReLoaded={templateReLoaded}
            isModal={isModal}
            closeModal={closeModal}
            recipientMail={recipientMail}
          />
          <Button
            onClick={() => {
              resetEditorContent();
            }}
          >
            {t('buttons.reset_to_base_editor_content')}
          </Button>
        </>
      )}
      <Button type="link" onClick={() => setIsModalMailingOpen(false)}>
        {t('buttons.cancel')}
      </Button>
    </Modal>
  );
};

Mailing.propTypes = {
  isModalMailingOpen: PropTypes.bool.isRequired,
  setIsModalMailingOpen: PropTypes.func.isRequired,
  idWithoutParams: PropTypes.string,
  templateMail: PropTypes.arrayOf(
    PropTypes.shape({
      template_content: PropTypes.string,
      object: PropTypes.string,
      _id: PropTypes.string
    })
  ),
  datas: PropTypes.shape({
    _id: PropTypes.string,
    contract: PropTypes.shape({
      contract_number: PropTypes.string
    }),
    fleet_type: PropTypes.string,
    unit_manager: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string
    }),
    vehicle_brand: PropTypes.string,
    vehicle_model: PropTypes.string,
    registration_or_serial_number: PropTypes.string,
    unit_counter: PropTypes.string,
    customer_manager: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string
    })
  }),
  templateName: PropTypes.string.isRequired,
  isModal: PropTypes.bool,
  closeModal: PropTypes.func,
  targetUrl: PropTypes.string
};

Mailing.defaultProps = {
  templateMail: [],
  idWithoutParams: null,
  datas: {},
  isModal: false,
  closeModal: null,
  targetUrl: null
};
