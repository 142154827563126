import PropTypes from 'prop-types';
import { Select, Form } from 'antd';
import { t } from 'i18next';

const { Item } = Form;
const { Option } = Select;

/**
 * Produces a yes/no select component.
 * @component
 * @param {string} dbKey - The key of the database field.
 * @param {function} setState - The state setter function.
 * @param {boolean} disabled - Whether the field is disabled.
 * @returns {JSX.Element} - A yes/no select component.
 *
 */

export const SelectYesNo = ({ dbKey, setState, disabled }) => {
  const toBoolean = (value) => {
    if (value === 'YES') {
      setState(true);
    } else {
      setState(false);
    }
  };

  const options = [
    {
      label: 'event_cancellations.form.YES',
      value: 'YES'
    },
    {
      label: 'event_cancellations.form.NO',
      value: 'NO'
    }
  ];

  return (
    <Item name={[`${dbKey}`]}>
      <Select
        placeholder={t(`event_cancellations.form.yes_or_no`)}
        disabled={disabled}
        onChange={(value) => toBoolean(value)}
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value}>
            {t(option.label)}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

SelectYesNo.propTypes = {
  dbKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  setState: PropTypes.func
};

SelectYesNo.defaultProps = {
  setState: () => {}
};
