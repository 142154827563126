import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback, useRef } from 'react';
import dayjs from 'dayjs';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useFiltersDataFetch } from '../../../../components/ColumnsDynamicFilters/useFiltersDatasFetch';

/**
 * `useColumns` is a custom React hook that provides a list of column configurations.
 *
 * The column titles are fetched using the `react-i18next` hook for internationalization.
 *
 * @hook
 * @returns {Array<object>} Returns an array of column configurations to be consumed by a table component.
 */

export const useColumns = (resourceName, dbExtraQueryFilter, populate) => {
  const { t } = useTranslation();
  const { dispatchAPI, company: companyId } = useAuthContext();
  const { message } = useErrorMessage();
  const { fetchFilters, filters, loading } = useFiltersDataFetch(
    resourceName,
    dbExtraQueryFilter,
    resourceName,
    populate
  );
  const [config, setConfig] = useState({});
  const isMounted = useRef(true);

  const allColumns = [
    {
      title: t('hole_in_ones.form.company_name'),
      key: 'company_name',
      dataIndex: ['company', 'name'],
      sorter: true
    },
    {
      title: t('hole_in_ones.form.contract_number'),
      key: 'contract_number',
      dataIndex: ['contract', 'contract_number'],
      sorter: true
    },
    {
      title: t('hole_in_ones.form.date_added'),
      key: 'date_added',
      dataIndex: ['created_at'],
      render: (created_at) =>
        created_at ? dayjs(created_at).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('hole_in_ones.form.entity'),
      key: 'entity',
      dataIndex: ['entity', 'name'],
      filters: filters?.entity?.map((entity) => ({
        text: entity.name,
        value: entity._id
      })),
      sorter: true
    },
    {
      title: t('hole_in_ones.form.site'),
      key: 'site',
      dataIndex: ['site', 'name'],
      filters: filters?.site?.map((site) => ({
        text: site.name,
        value: site._id
      })),
      sorter: true
    },
    {
      title: t('hole_in_ones.form.golf_name'),
      key: 'golf_name',
      dataIndex: ['golf_name'],
      filters: filters?.golf_name?.map((golf_name) => ({
        text: golf_name,
        value: golf_name
      })),
      sorter: true
    },
    {
      title: t('hole_in_ones.form.golf_address'),
      key: 'golf_address',
      dataIndex: ['golf_address'],
      filters: filters?.golf_address?.map((golf_address) => ({
        text: golf_address,
        value: golf_address
      })),
      sorter: true
    },
    {
      title: t('hole_in_ones.form.postal_code'),
      key: 'postal_code',
      dataIndex: ['postal_code'],
      filters: filters?.postal_code?.map((postal_code) => ({
        text: postal_code,
        value: postal_code
      })),
      sorter: true
    },
    {
      title: t('hole_in_ones.form.city'),
      key: 'city',
      dataIndex: ['city'],
      filters: filters?.city?.map((city) => ({
        text: city,
        value: city
      })),
      sorter: true
    },
    {
      title: t('hole_in_ones.form.event_start_date'),
      key: 'event_start_date',
      dataIndex: ['event_start_date'],
      filters: filters?.event_start_date?.map((date) => ({
        text: dayjs(date).format('DD/MM/YYYY'),
        value: date
      })),
      render: (event_start_date) =>
        event_start_date ? dayjs(event_start_date).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('hole_in_ones.form.event_end_date'),
      key: 'event_end_date',
      dataIndex: ['event_end_date'],
      filters: filters?.event_end_date?.map((date) => ({
        text: dayjs(date).format('DD/MM/YYYY'),
        value: date
      })),
      render: (event_end_date) =>
        event_end_date ? dayjs(event_end_date).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('hole_in_ones.form.event_name'),
      key: 'event_name',
      dataIndex: ['event_name'],
      filters: filters?.event_name?.map((eventName) => ({
        text: eventName,
        value: eventName
      })),
      sorter: true
    },
    {
      title: t('hole_in_ones.form.number_of_men_players'),
      key: 'number_of_men_players',
      dataIndex: ['number_of_men_players'],
      filters: filters?.number_of_men_players.map((number_of) => ({
        text: number_of,
        value: number_of
      })),
      sorter: true
    },
    {
      title: t('hole_in_ones.form.number_of_women_players'),
      key: 'number_of_women_players',
      dataIndex: 'number_of_women_players',
      filters: filters?.number_of_women_players.map((number_of) => ({
        text: number_of,
        value: number_of
      })),
      sorter: true
    },
    {
      title: t('hole_in_ones.form.official_length_for_men_hole_in_one'),
      key: 'official_length_for_men_hole_in_one',
      dataIndex: ['official_length_for_men_hole_in_one'],
      filters: filters?.official_length_for_men_hole_in_one.map((length) => ({
        text: length,
        value: length
      })),
      sorter: true
    },
    {
      title: t('hole_in_ones.form.official_length_for_women_hole_in_one'),
      key: 'official_length_for_women_hole_in_one',
      dataIndex: 'official_length_for_women_hole_in_one',
      filters: filters?.official_length_for_women_hole_in_one.map((length) => ({
        text: length,
        value: length
      })),
      sorter: true
    },
    {
      title: t('hole_in_ones.form.prize_description'),
      key: 'prize_description',
      dataIndex: 'prize_description',
      filters: filters?.prize_description.map((prize) => ({
        text: prize,
        value: prize
      })),
      sorter: true
    },
    {
      title: t('hole_in_ones.form.prize_value'),
      key: 'prize_value',
      dataIndex: 'prize_value',
      filters: filters?.prize_value.map((prize) => ({
        text: prize,
        value: prize
      })),
      render: (prize_value) => (prize_value && `${prize_value} €`) || '-',
      sorter: true
    }
  ];

  const getConfig = useCallback(async () => {
    if (!isMounted.current) return;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/programme-configs/${companyId}/hole-in-one-configs`
      });
      setConfig(data[0]);
    } catch (e) {
      if (isMounted.current) message('missing_parameters');
    }
  }, [dispatchAPI, message]);

  useEffect(() => {
    getConfig();
    fetchFilters();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const filteredColumns = allColumns.filter((column) => config[column.key]);

  return [filteredColumns, loading] || [];
};
