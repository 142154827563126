import { useCallback } from 'react';
import dayjs from 'dayjs';
import { useHistory } from '../../../../utils/historyUtils';

/**
 * Custom hook that returns the configuration objects for getting and creating resources.
 *
 * @param {function} dispatch - The dispatch function from the Redux store.
 * @param {string} company - The company ID.
 * @param {string} program - The program type.
 * @param {string} subProgram - The sub-program type.
 * @returns {object} - The configuration objects for getting and creating resources.
 */

export const useConfig = () => {
  const { setInitialHistory, updateHistory } = useHistory();
  const onGetResource = {
    setFields: useCallback((data) => {
      setInitialHistory(data);
      return {
        ...data,
        validity_start_date:
          data.validity_start_date && dayjs(data.validity_start_date),
        validity_end_date:
          data.validity_end_date && dayjs(data.validity_end_date)
      };
    }, [])
  };
  const onUpdateResource = {
    setBody: useCallback(
      (data) => ({
        ...data,
        ...updateHistory('program')
      }),
      []
    )
  };
  return {
    onGetResource,
    onUpdateResource
  };
};
