import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { FileAddOutlined } from '@ant-design/icons';
import { ModalAddCertificate } from './ModalAddCertificate';

/**
 * Renders a form for adding an insurance certificate.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.resourceName - The name of the resource.
 * @param {string} props.resourceId - The ID of the resource.
 * @param {string} props.rowKey - The key of the row.
 * @param {string} props.idWithoutParams - The ID without parameters.
 * @param {Array} props.mandatoryExitDocuments - The array of mandatory exit documents.
 * @returns {JSX.Element} The rendered form for adding an insurance certificate.
 */
export const FormAddInsuranceCertificate = ({
  resourceName,
  baseUrl,
  resourceId,
  rowKey,
  idWithoutParams,
  mandatoryExitDocuments
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const iconSize = '18px';

  return (
    <>
      <ModalAddCertificate
        resourceName={resourceName}
        baseUrl={baseUrl}
        resourceId={resourceId}
        rowKey={rowKey}
        idWithoutParams={idWithoutParams}
        visible={visible}
        setVisible={setVisible}
        mandatoryExitDocuments={mandatoryExitDocuments}
      />
      <Tooltip title={t('buttons.add_certificate')}>
        {!idWithoutParams ? (
          <Button
            onClick={() => {
              setVisible(resourceId ? true : rowKey);
            }}
          >
            {`${t('buttons.add_certificate')} `}
          </Button>
        ) : (
          <FileAddOutlined
            onClick={() => {
              setVisible(resourceId ? true : rowKey);
            }}
            style={{ fontSize: iconSize }}
          />
        )}
      </Tooltip>
    </>
  );
};

FormAddInsuranceCertificate.propTypes = {
  resourceName: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
  rowKey: PropTypes.number,
  idWithoutParams: PropTypes.string,
  mandatoryExitDocuments: PropTypes.shape([]).isRequired,
  baseUrl: PropTypes.string.isRequired
};

FormAddInsuranceCertificate.defaultProps = {
  rowKey: null,
  idWithoutParams: null
};
