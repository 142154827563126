import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useActionColumn } from './utils/actionIndividualHousesBuilder';
import { ListResource } from '../../../../components/ListResource/ListResource';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { ResetFilterButton } from '../../../components/ResetFiltersButton';
import { useColumns } from './utils/columns';

/**
 * Renders a list of individual houses builders.
 * @component
 * @returns {JSX.Element} The rendered ListIndividualHousesBuilder component.
 */
export const ListIndividualHousesBuilder = () => {
  const { company, permissions, verifPermissions } = useAuthContext();
  const [resetFilters, setResetFilters] = useState(false);
  const [extraFilter, setExtraFilter] = useState('');
  const [edit, setEdit] = useState(true);
  const dbExtraQueryFilter = `company_Id=${company}`;
  const populate = 'entity,contract,company_Id';
  const resourceName = 'individual-houses-builder';

  const [columns, loading] = useColumns(
    resourceName,
    dbExtraQueryFilter,
    populate
  );
  const actionColumn = useActionColumn(edit);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const currentPage = Number(params.get('p') || 1);
  const pageSize = Number(params.get('pS') || 10);

  let filterParameter = '';

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setEdit(
        !verifPermissions(
          `/programmes/individual-houses-builders/edit/`,
          permissions?.notPermittedRoutes
        )
      );
    }
  }, [permissions]);

  useEffect(() => {
    if (resetFilters) {
      filterParameter = '';
      setResetFilters(false);
    }
    setExtraFilter(filterParameter);
    navigate({
      pathname: '/programmes/individual-houses-builders',
      search: `?&p=${currentPage}&pS=${pageSize}&f=${filterParameter}`
    });
  }, [navigate, resetFilters]);

  if (loading) return <Spin fullscreen="true" />;

  return (
    <ListResource
      extraQuery={`company_Id=${company}`}
      resourceName={resourceName}
      tradKey="individual_houses_builder"
      columns={[...columns, ...actionColumn]}
      withExtraFilters={<ResetFilterButton setResetFilters={setResetFilters} />}
      noCreateButton
      populate="entity,site,contract,company_Id"
      sheetName={t('constructions.title')}
      headers={[]}
      editAction={false}
      showAction={false}
      duplicateAction={false}
      printAction={false}
      extraFilter={extraFilter}
      deleteAction={false}
      withUploadButton={false}
      customActionColumn
      scroll={{ x: 3000 }}
    />
  );
};
