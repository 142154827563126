// TODO: awaitin microservice to be able to send attestation with commented componentw
import { useCallback, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Button, Skeleton, Row, Col, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useListContent } from './utils/listContentMachineryBreakage';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../components';
import { routes } from '../../../utils/constants/adminRoutes';
import { Documents } from '../../components/Documents';
import { MailingButton } from '../../Mailing/MailingButton';
import { ClaimButton } from '../../components/ClaimButton';
import { requiredFilesConditions } from './utils/documentsConditions';
import usePopConfirm from '../utils/popConfirms';
import { History } from '../../components/History';

/**
 * MachineryBreakageShow component.
 * Renders detailed information about a specific machineryBreakages course, including its documents.
 * Provides options to edit or delete the machineryBreakages course documents.
 *
 * @component
 * @returns {JSX.Element} MachineryBreakageShow
 */
export const MachineryBreakageShow = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [machineryBreakages, setMachineryBreakage] = useState();
  const [visible, setVisible] = useState(false);
  const listContent = useListContent(machineryBreakages);
  const { exitProgram, unarchiveProgram } = usePopConfirm(
    'machinery-breakages'
  );

  const draggerFilesKeysOverRide = () => {
    if (machineryBreakages) {
      return requiredFilesConditions(machineryBreakages.status);
    }
    return [''];
  };

  const getMachineryBreakage = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/machinery-breakages/${id}?populate=customer_manager,unit_manager,entity,site,contract,company`
      });
      setMachineryBreakage(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getMachineryBreakage();
    })();
  }, [getMachineryBreakage]);

  return (
    <>
      <PageHeaderCustom
        title={`${t('number')} ${
          machineryBreakages?.unit_counter || 'xxxx'
        } - ${machineryBreakages?.golf_name || ''} ${
          machineryBreakages?.city || ''
        }`}
        extra={
          <>
            {machineryBreakages && !machineryBreakages.deleted && (
              <>
                <Link
                  to={{
                    pathname: `${routes.PROGRAMMES}/machinery-breakages/edit/${id}`
                  }}
                >
                  <Button type="primary">
                    {`${t('buttons.edit')} `}
                    <EditOutlined />
                  </Button>
                </Link>
                <ClaimButton
                  id={id}
                  url="machinery-breakages"
                  claimType="machineryBreakages"
                />
                <MailingButton
                  asset={machineryBreakages}
                  templateName="Blank"
                  tradKey="specific_demand"
                  resource="machineryBreakages"
                  baseUrl="machinery-breakages"
                />
              </>
            )}
            {machineryBreakages &&
              !machineryBreakages.deleted &&
              exitProgram({
                visible,
                setVisible
              })}
            {machineryBreakages &&
              machineryBreakages.deleted &&
              unarchiveProgram()}
          </>
        }
      />
      <ContentCustom>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={12}>
            <Skeleton loading={isLoading} title={0} paragraph={0} active />
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <Row gutter={[0, 16]}>
                <Card>
                  <DescriptionList
                    data={listContent}
                    translate
                    layout="vertical"
                    title={t('machinery_breakages.form.main_informations')}
                  />
                </Card>
              </Row>
            </Skeleton>
          </Col>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <Col xs={24} xl={12}>
              <Documents
                idWithoutParams={id}
                baseUrl="machinery-breakages"
                resource="Machinery-breakage"
                draggerFilesKeysOverRide={draggerFilesKeysOverRide()}
              />
              <Card title={t('subscriptions.form.history')} size="small">
                <History route="Machinery-breakage" />
              </Card>
            </Col>
          </Skeleton>
        </Row>
      </ContentCustom>
    </>
  );
};
