import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useFiltersDataFetch } from '../../../../components/ColumnsDynamicFilters/useFiltersDatasFetch';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useAuthContext } from '../../../../contexts/AuthContext';

/**
 * `useColumns` is a custom React hook that provides a list of column configurations.
 *
 * The column titles are fetched using the `react-i18next` hook for internationalization.
 *
 * @hook
 * @returns {Array<object>} Returns an array of column configurations to be consumed by a table component.
 */

export const useColumns = (resourceName, dbExtraQueryFilter, populate) => {
  const { dispatchAPI, company: companyId } = useAuthContext();
  const { message } = useErrorMessage();
  const isMounted = useRef(true);
  const { t } = useTranslation();
  const [config, setConfig] = useState({});

  const { fetchFilters, filters, loading } = useFiltersDataFetch(
    resourceName,
    dbExtraQueryFilter,
    resourceName,
    populate
  );

  useEffect(() => {
    fetchFilters();
  }, []);

  const allColumns = [
    {
      title: t('hardware_insurances.columns.company_name'),
      key: 'company_name',
      dataIndex: ['company', 'name'],
      sorter: true
    },
    {
      title: t('hardware_insurances.columns.contract_number'),
      key: 'contract_number',
      dataIndex: ['contract', 'contract_number'],
      sorter: true
    },
    {
      title: t('hardware_insurances.columns.date_added'),
      key: 'date_added',
      dataIndex: ['created_at'],
      render: (created_at) =>
        created_at ? dayjs(created_at).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('hardware_insurances.columns.entity'),
      key: 'entity',
      dataIndex: ['entity'],
      filters: (filters?.entity || []).map((entity) => ({
        text: entity.name,
        value: entity._id
      })),
      sorter: true,
      render: (entity) => (entity ? entity.map((e) => e.name).join(', ') : '-')
    },
    {
      title: t('hardware_insurances.columns.site'),
      key: 'site',
      dataIndex: ['site'],
      filters: (filters?.site || []).map((site) => ({
        text: site.name,
        value: site._id
      })),
      sorter: true
    },
    {
      title: t('hardware_insurances.columns.address'),
      key: 'address',
      dataIndex: ['address'],
      filters: (filters?.address || []).map((address) => ({
        text: address,
        value: address
      })),
      sorter: true
    },
    {
      title: t('hardware_insurances.columns.postal_code'),
      key: 'postal_code',
      dataIndex: ['postal_code'],
      filters: (filters?.postal_code || []).map((postal_code) => ({
        text: postal_code,
        value: postal_code
      })),
      sorter: true
    },
    {
      title: t('hardware_insurances.columns.city'),
      key: 'city',
      dataIndex: ['city'],
      filters: (filters?.city || []).map((city) => ({
        text: city,
        value: city
      })),
      sorter: true
    },
    {
      title: t('hardware_insurances.columns.global_tax_excl_hardware_value'),
      key: 'global_tax_excl_hardware_value',
      dataIndex: ['global_tax_excl_hardware_value'],
      filters: (filters?.global_tax_excl_hardware_value || []).map((value) => ({
        text: `${value} €`,
        value
      })),
      sorter: true
    },
    {
      title: t('hardware_insurances.columns.laptops_tax_excl_value'),
      key: 'laptops_tax_excl_value',
      dataIndex: ['laptops_tax_excl_value'],
      filters: (filters?.laptops_tax_excl_value || []).map((value) => ({
        text: `${value} €`,
        value
      })),
      sorter: true
    },
    {
      title: t('hardware_insurances.columns.is_claim_last_two_years'),
      key: 'is_claim_last_two_years',
      dataIndex: ['is_claim_last_two_years'],
      filters: (filters?.is_claim_last_two_years || []).map((answer) => ({
        text: t(`hardware_insurances.columns.enums.${answer}`),
        value: answer
      })),
      sorter: true,
      render: (is_claim_last_two_years) =>
        is_claim_last_two_years
          ? t(`hardware_insurances.columns.enums.${is_claim_last_two_years}`)
          : '-'
    },
    {
      title: t('hardware_insurances.columns.is_data_duplication_guarantee'),
      key: 'is_data_duplication_guarantee',
      dataIndex: 'is_data_duplication_guarantee',
      filters: (filters?.is_data_duplication_guarantee || []).map((answer) => ({
        text: t(`hardware_insurances.columns.enums.${answer}`),
        value: answer
      })),
      sorter: true,
      render: (is_data_duplication_guarantee) =>
        is_data_duplication_guarantee
          ? t(
              `hardware_insurances.columns.enums.${is_data_duplication_guarantee}`
            )
          : '-'
    },
    {
      title: t('hardware_insurances.columns.is_operating_cost_loss_guarantee'),
      key: 'is_operating_cost_loss_guarantee',
      dataIndex: ['is_operating_cost_loss_guarantee'],
      filters: (filters?.is_operating_cost_loss_guarantee || []).map(
        (answer) => ({
          text: t(`hardware_insurances.columns.enums.${answer}`),
          value: answer
        })
      ),
      sorter: true,
      render: (is_operating_cost_loss_guarantee) =>
        is_operating_cost_loss_guarantee
          ? t(
              `hardware_insurances.columns.enums.${is_operating_cost_loss_guarantee}`
            )
          : '-'
    }
  ];

  const getConfig = useCallback(async () => {
    if (!isMounted.current) return;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/programme-configs/${companyId}/hardware-insurance-configs`
      });
      setConfig(data[0]);
    } catch (e) {
      if (isMounted.current) message('missing_parameters');
    }
  }, [dispatchAPI, message]);

  useEffect(() => {
    getConfig();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const filteredColumns = allColumns.filter((column) => config[column.key]);

  return [filteredColumns, loading] || [];
};
