import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Flex, Tooltip } from 'antd';
import { EyeOutlined, EditOutlined } from '@ant-design/icons';
import { Flood } from '@carbon/icons-react';
import pluralize from 'pluralize';

const iconSize = 18;

const formatUrlPart = (str) => pluralize(str.toLowerCase().replace(/_/g, '-'));

/**
 * `extractSubProgrammes` is a hook that extracts and returns subprogrammes from a record and formats them for use in a URL.
 * @hook
 * @param {Object} record - The record containing subprogramme data.
 * @returns {string} The formatted subprogramme string.
 */

const extractSubProgrammes = (record) => {
  const {
    fleet_sub_programmes,
    civil_liability_sub_programmes,
    transport_sub_programmes,
    construction_sub_programmes
  } = record.contract || {};

  const subProgrammesParts = [
    ...(fleet_sub_programmes || []),
    ...(civil_liability_sub_programmes || []),
    ...(transport_sub_programmes || []),
    ...(construction_sub_programmes || [])
  ];

  return subProgrammesParts
    .filter((part) => typeof part === 'string')
    .map(formatUrlPart)
    .join('/');
};

/**
 * `useActionColumn` is a hook that returns a configuration array for rendering an action column.
 * The action column consists of several icons used for viewing a document, editing a document,
 * and other associated actions. Each icon has an associated action, such as navigation or
 * triggering a functionality.
 *
 * @hook
 * @returns {Array} An array containing configuration for the action column. The configuration includes
 * how to render the column based on the record provided.
 */

export const useActionColumn = (edit) => {
  const { t } = useTranslation();
  return [
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => {
        const subProgrammesString = extractSubProgrammes(record);

        return (
          <Flex gap="small" align="center" justify="end" wrap="nowrap">
            <Link to={`/programmes/${subProgrammesString}/show/${record?._id}`}>
              <Tooltip title={t('breadcrumbs.show')}>
                <EyeOutlined style={{ fontSize: iconSize }} />
              </Tooltip>
            </Link>
            {edit && (
              <Link
                to={`/programmes/${subProgrammesString}/edit/${record?._id}`}
              >
                <Tooltip title={t('breadcrumbs.edit')}>
                  <EditOutlined style={{ fontSize: iconSize }} />
                </Tooltip>
              </Link>
            )}
            <Link to={`/claims/${subProgrammesString}/create/${record?._id}`}>
              <Tooltip title={t('faculties.actions.create_claim')}>
                <Flood size={iconSize} />
              </Tooltip>
            </Link>
          </Flex>
        );
      }
    }
  ];
};
