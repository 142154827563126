import { useParams } from 'react-router-dom';
import { ExpertCall } from '../../utils/ClaimsButtons/ExpertCall';
import { useAuthContext } from '../../../../contexts/AuthContext';

/**
 * Renders the Mailing Button for Expert Call component based on a render condition.
 * @component
 * @param {boolean} renderCondition - Whether to render the Mailing Button for Vehicles.
 * @param {Object} vehicleClaim - Vehicle data.
 * @returns {?React.Component} The rendered Mailing Button for Vehicles component, or null if the render condition is false.
 */
const renderExpertCallButton = (renderCondition, vehicleClaim) => {
  const { id } = useParams();
  const { company } = useAuthContext();
  if (renderCondition) {
    return (
      <ExpertCall
        company={company}
        claimReferenceNumber={vehicleClaim?.unit_counter}
        programmeType="FLEET"
        vehicleClaimId={id}
        vehicleClaimNumber={vehicleClaim?.unit_counter}
        automission
      />
    );
  }
  return null;
};

export default renderExpertCallButton;
