import dayjs from 'dayjs';

/**
 * Produces a list content for displaying detailed golf information.
 *
 * This hook extracts specified properties from the provided data, formats them
 * and returns an array of objects suitable for rendering in a description list.
 *
 * @hook
 * @param {Object} data - Golf data to process.
 * @returns {Array<Object>} - Formatted list content for rendering.
 *
 */

export const useListContent = (data = {}) => {
  const {
    contract,
    company,
    created_at,
    entity,
    site,
    customer_manager,
    unit_manager,
    desired_effective_date,
    golf_name,
    postal_code,
    city,
    country,
    total_insured_developed_area,
    company_registration_number,
    previous_year_revenue,
    workforce_full_time_equivalent,
    content,
    deleted,
    deletion_date_effect
  } = data;
  const customerManagerName = customer_manager
    ? `${customer_manager.first_name} ${customer_manager.last_name}`
    : '-';
  const unit_managerName = unit_manager
    ? `${unit_manager.first_name} ${unit_manager.last_name}`
    : '-';

  const labels = [
    {
      label: 'golfs.form.contract_number',
      span: 1,
      content: (contract && contract.contract_number) || '-'
    },
    {
      label: 'golfs.form.company_name',
      span: 1,
      content: (company?.name && company.name) || '-'
    },
    {
      label: 'golfs.form.date_added',
      span: 1,
      content: (created_at && dayjs(created_at).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'golfs.form.entity',
      span: 1,
      content: (entity && entity.name) || '-'
    },
    {
      label: 'golfs.form.site',
      span: 1,
      content: (site && site.name) || '-'
    },
    {
      label: 'golfs.form.customer_manager',
      span: 1,
      content: (customerManagerName && customerManagerName) || '-'
    },
    {
      label: 'golfs.form.unit_manager',
      span: 1,
      content: (unit_managerName && unit_managerName) || '-'
    },
    {
      label: 'golfs.form.desired_effective_date',
      span: 1,
      content:
        (desired_effective_date &&
          dayjs(desired_effective_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'golfs.form.validity_end_date',
      span: 1,
      content:
        (contract?.validity_end_date &&
          dayjs(contract?.validity_end_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'golfs.form.golf_name',
      span: 1,
      content: (golf_name && golf_name) || '-'
    },
    {
      label: 'golfs.form.postal_code',
      span: 1,
      content: (postal_code && postal_code) || '-'
    },
    {
      label: 'golfs.form.city',
      span: 1,
      content: (city && city) || '-'
    },
    {
      label: 'golfs.form.country',
      span: 1,
      content: (country && country) || '-'
    },
    {
      label: 'golfs.form.total_insured_developed_area',
      span: 1,
      content:
        (total_insured_developed_area && total_insured_developed_area) || '-'
    },
    {
      label: 'golfs.form.company_registration_number',
      span: 1,
      content:
        (company_registration_number && company_registration_number) || '-'
    },
    {
      label: 'golfs.form.previous_year_revenue',
      span: 1,
      content: (previous_year_revenue && previous_year_revenue) || '-'
    },
    {
      label: 'golfs.form.workforce_full_time_equivalent',
      span: 2,
      content:
        (workforce_full_time_equivalent && workforce_full_time_equivalent) ||
        '-'
    },
    {
      label: 'golfs.form.content',
      span: 3,
      content: (content && content) || '-'
    }
  ];

  if (deleted) {
    labels.push({
      label: 'vehicles.form.deleted',
      span: 1,
      content:
        (deletion_date_effect &&
          dayjs(deletion_date_effect).format('DD/MM/YYYY')) ||
        '-'
    });
  }

  return labels;
};

/**
 * Produces a list content for displaying golf addresses.
 *
 * This hook extracts address-related properties from the provided data, formats them
 * and returns an array of objects suitable for rendering in a description list.
 *
 * @hook
 * @param {Object} data - Golf data containing address-related information.
 * @returns {Array<Object>} - Formatted list content for rendering addresses.
 */

export const useListContentAddresses = (data = {}) => {
  const { postal_code, city, country, number, street, additional } = data;

  const getAddressFromParts = () => {
    const addressParts = [
      number,
      street,
      additional,
      postal_code,
      city,
      country
    ];
    return [addressParts.filter((part) => part).join(' ')];
  };
  return [
    {
      label: `golfs.form.address`,
      span: 3,
      content: getAddressFromParts() || '-'
    }
  ];
};
