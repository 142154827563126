import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Form } from 'antd';
import { useFields } from './fieldsCyberInsuranceSubscription';
import { useAuthContext } from '../../../contexts/AuthContext';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewerWithoutForm';
import { toBoolean } from '../../components/utils/stateFormat';

/**
 * A component for creating or updating civil liability subscriptions.
 * @component
 * @param {object} props - The component's props.
 * @param {string} props.purpose - The purpose of the component (e.g., 'create' or 'edit').
 * @returns {JSX.Element} The JSX element representing the create/update component.
 */
export const CyberInsurancesCreateUpdate = ({ purpose }) => {
  const programme = 'CYBER_INSURANCE';
  const { company } = useAuthContext();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [companyLocationFrance, setCompanyLocationFrance] = useState(false);
  const [restartPlan, setRestartPlan] = useState(false);
  const [isOutsideBackup, setIsOutsideBackup] = useState(false);
  const [isSubsidiaries, setIsSubsidiaries] = useState(false);
  const [isSiVpnSecured, setIsSiVpnSecured] = useState(false);
  const [isInfrastructureCloudHosted, setIsInfrastructureCloudHosted] =
    useState(false);
  const [isMicrosoftActiveDirectory, setIsMicrosoftActiveDirectory] =
    useState(false);
  const [isSecurityUpdateAsap, setIsSecurityUpdateAsap] = useState(false);
  const [isSupportFromVendors, setIsSupportFromVendors] = useState(false);
  const [isSecurityEventsLogs, setIsSecurityEventsLogs] = useState(false);

  const { fieldsInformations } = useFields(
    form,
    isSubsidiaries,
    setIsSubsidiaries,
    companyLocationFrance,
    setCompanyLocationFrance,
    restartPlan,
    setRestartPlan,
    isOutsideBackup,
    setIsOutsideBackup,
    isSiVpnSecured,
    setIsSiVpnSecured,
    isInfrastructureCloudHosted,
    setIsInfrastructureCloudHosted,
    isMicrosoftActiveDirectory,
    setIsMicrosoftActiveDirectory,
    isSecurityUpdateAsap,
    setIsSecurityUpdateAsap,
    isSupportFromVendors,
    setIsSupportFromVendors,
    isSecurityEventsLogs,
    setIsSecurityEventsLogs
  );

  const config = {
    onGetResource: {
      setFields: (data) => {
        setCompanyLocationFrance(
          toBoolean(data?.is_located_in_france_andorra_monaco_eu)
        );
        setRestartPlan(toBoolean(data?.is_plan_to_restart_after_damage));
        setIsOutsideBackup(toBoolean(data?.is_outside_backup));
        setIsSiVpnSecured(toBoolean(data?.is_connection_to_si_vpn_secured));
        setIsInfrastructureCloudHosted(
          toBoolean(data?.is_infrastructure_cloud_hosted)
        );
        setIsMicrosoftActiveDirectory(
          toBoolean(data?.is_microsoft_active_directory_used)
        );
        setIsSecurityUpdateAsap(toBoolean(data?.is_security_update_asap));
        setIsSupportFromVendors(
          toBoolean(data?.is_os_and_software_updated_and_supported_by_vendor)
        );
        setIsSecurityEventsLogs(toBoolean(data?.is_security_events_logs));
        return {
          ...data,
          desired_effective_date:
            data.desired_effective_date && dayjs(data.desired_effective_date),
          financial_year_closure_date:
            data.financial_year_closure_date &&
            dayjs(data.financial_year_closure_date),
          request_date: data.request_date && dayjs(data.request_date)
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        ...{
          yearly_turnover_exceeding_50_millions_message:
            data.last_yearly_turnover > 50
        }
      })
    },

    onCreateResource: {
      setBody: (data) => ({
        ...data,
        company_id: company,
        programme_types: programme,
        ...{
          yearly_turnover_exceeding_50_millions_message:
            data.last_yearly_turnover > 50
        }
      })
    }
  };

  return (
    <CreateUpdateContainer
      form={form}
      title={t(`subscriptions.form.${purpose}`)}
      fields={fieldsInformations}
      tradKey="cyber_insurances"
      purpose={purpose}
      baseUrl="subscriptions"
      resource="subscriptions"
      populate="?populate=documents.file"
      config={config}
      withFilesManager={false}
      messageOnSuccess
    />
  );
};

CyberInsurancesCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
