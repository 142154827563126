import { Route, Routes } from 'react-router-dom';
import { ListFaculties } from './ListFaculties';
import { FacultiesCreateUpdate } from './FacultiesCreateUpdate';
import { TransportShow } from '../TransportShow';
import { Exception } from '../../../../components';

export const FacultiesRouter = () => (
  <Routes>
    <Route path="edit/:id" element={<FacultiesCreateUpdate purpose="edit" />} />
    <Route
      path="create/:id"
      element={<FacultiesCreateUpdate purpose="create" />}
    />
    <Route
      path="create/:id"
      element={<FacultiesCreateUpdate purpose="create" />}
    />
    <Route
      path="show/:id"
      element={<TransportShow transportType="faculties" />}
    />
    <Route index element={<ListFaculties />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
