import { Route, Routes } from 'react-router-dom';
import { ListCivilLiabilityExecutive } from './ListCivilLiabilityExecutive';
import { ShowCivilLiability } from '../ShowCivilLiability';
import { CivilLiabilityCreateUpdate } from '../CivilLiabilityCreateUpdate';
import { Exception } from '../../../../components';

/**
 * A router component for handling civil-liability-related routes.
 *
 * @component
 * @returns {JSX.Element} The JSX element representing the CivilLiabilityExecutiveRouter.
 */

export const CivilLiabilityExecutiveRouter = () => (
  <Routes>
    <Route
      path="edit/:id"
      element={
        <CivilLiabilityCreateUpdate
          purpose="edit"
          subProgramme="CIVIL_LIABILITY_EXECUTIVE"
        />
      }
    />
    <Route path="show/:id" element={<ShowCivilLiability />} />
    <Route index element={<ListCivilLiabilityExecutive />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
