import { useTranslation } from 'react-i18next';
import { DatePicker, Input } from 'antd';
import { SelectManager } from '../../../components/SelectManager';

/**
 * Generate fields configuration for a form.
 *
 * @function
 * @param {string} subProgramme - The sub Programme for which to generate the fields.
 * @returns {Object} An object containing the fields configuration.
 */
export const useFields = (subProgramme) => {
  const { t } = useTranslation();
  const dateFormat = 'DD-MM-YYYY';

  const fieldsInformations = [
    {
      label: ['customer_manager'],
      name: ['customer_manager'],
      rules: [{ required: true }],
      input: <SelectManager dbKey="customer_manager" />
    },
    {
      label: ['unit_manager'],
      name: ['unit_manager'],
      input: <SelectManager dbKey="unit_manager" />
    },
    {
      label: ['sub_programme'],
      input: (
        <Input placeholder={t(`transports.form.${subProgramme}`)} disabled />
      )
    },
    {
      label: ['desired_effective_date'],
      name: ['desired_effective_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    }
  ];

  return {
    fieldsInformations
  };
};
