import { Route, Routes } from 'react-router-dom';
import { HardWareInsuranceShow } from './HardWareInsuranceShow';
import { ListHardwareInsurance } from './ListHardwareInsurance';
import { HardwareInsuranceCreateUpdate } from './HardwareInsuranceCreateUpdate';
import { Exception } from '../../../components';

/**
 * A router component for handling hardware insurance related routes.
 *
 * @component
 * @returns {JSX.Element} The JSX element representing the HardwareInsuranceRouter.
 */

export const HardwareInsuranceRouter = () => (
  <Routes>
    <Route
      path="edit/:id"
      element={<HardwareInsuranceCreateUpdate purpose="edit" />}
    />
    <Route path="show/:id" element={<HardWareInsuranceShow />} />
    <Route index element={<ListHardwareInsurance />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
