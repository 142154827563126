import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import { MultipleFormFieldsGeneration } from '../../components/MultipleFormFieldsGeneration';
import { useCultureFields } from './fieldsCultureBox';
import { AgricultureDocuments } from '../AgricultureDocuments';

/**
 * Custom hook that provides fields configuration for the agriculture form.
 *
 * @param {object} form - The form object.
 * @returns {object} - An object containing the `fieldsAgriculture` function.
 */
const useFields = (form) => {
  const { t } = useTranslation();
  const { cultureFields, guaranteeFields } = useCultureFields(form);

  const fieldsAgriculture = (
    draggerFilesList,
    setDraggerFilesList,
    filesConfiguration,
    setFilesConfiguration
  ) => {
    const setFilesConfWithIndex = (filesConf, idx) => {
      setFilesConfiguration({
        ...filesConfiguration,
        [idx]: filesConf
      });
    };

    const setDraggerFilesWithIndex = (files, idx) => {
      setDraggerFilesList({
        ...draggerFilesList,
        [idx]: files
      });
    };

    const AgricultureDocs = (index) => (
      <AgricultureDocuments
        setDraggerFilesWithIndex={setDraggerFilesWithIndex}
        setFilesConfWithIndex={setFilesConfWithIndex}
        index={index}
      />
    );

    return [
      {
        label: ['last_name'],
        name: ['last_name'],
        rules: [{ required: true }],
        input: <Input />,
        startWithDivider: {
          title: t('agriculture.form.dividers.first')
        }
      },
      {
        label: ['first_name'],
        name: ['first_name'],
        rules: [{ required: true }],
        input: <Input />
      },
      {
        label: ['email'],
        rules: [{ required: true }, { type: 'email' }],
        name: ['email'],
        input: <Input />
      },
      {
        label: ['society'],
        name: ['society'],
        rules: [{ required: true }],
        input: <Input />,
        endWithDivider: {
          title: t('agriculture.form.dividers.second')
        }
      },
      {
        noLabel: true,
        name: ['cultures'],
        rules: [{ required: true }],
        input: (
          <MultipleFormFieldsGeneration
            fields={cultureFields}
            dbKey="cultures"
            tradKey="agriculture"
            agriculture
            form={form}
            guaranteeFields={guaranteeFields}
            AgricultureDocs={AgricultureDocs}
          />
        )
      }
    ];
  };
  return {
    fieldsAgriculture
  };
};
export default useFields;
