/**
 * Renders the names of entities as a comma-separated string.
 * @function
 * @param {Array} entity - The array of entities.
 * @returns {string} - The comma-separated string of entity names, or '-' if the entity is falsy.
 */
export const renderEntities = (entity) =>
  entity ? entity.map((e) => e.name).join(', ') : '-';

/**
 * Renders the names of entities as a comma-separated string.
 * @function
 * @param {Array} entity - The array of entities.
 * @returns {string} - The comma-separated string of entity names, or '-' if the entity is falsy.
 */
export const renderEntitiesShow = (entity) =>
  entity ? entity.map((e) => e).join(', ') : '-';

/**
 * Renders the names of sites as a comma-separated string.
 * @function
 * @param {Array} site - The array of sites.
 * @returns {string} - The comma-separated string of site names, or '-' if the site is falsy.
 */
export const renderSites = (site) =>
  site ? site.map((s) => s.name).join(', ') : '-';
