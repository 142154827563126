// TODO: Uncomment for customer validation
import propTypes from 'prop-types';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../../components';
import { useListContentCivilLiability } from '../listContents/listContentSubCivilLiability';
import { useListContentConstruction } from '../listContents/listContentSubConstruction';
import { useListContentCyberInsurance } from '../listContents/listContentSubCyberInsurance';
import { useListContentTransport } from '../listContents/listContentSubTransport';
import { useListContentSubHardwareInsurance } from '../listContents/listContentSubHardwareInsurance';
import { useListContentSubIndividualHousesBuilder } from '../listContents/listContentSubIndividualHousesBuilder';
import { useListContentProMultiriskInsurance } from '../listContents/listContentProMultiriskInsurance';
import { useListContentAssembyTesting } from '../listContents/listContentAssembyTesting';
import { useListContentLegalProtection } from '../listContents/listContentSubLegalProtection';
import { useListContentEnvironmentInsurance } from '../listContents/listContentSubEnvironmentInsurance';
import { AddressDisplay } from './AddressDisplay';
/**
 * Renders a card component with a description list based on the selected program.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.programme - The selected program.
 * @param {Object} props.subscription - The subscription object.
 * @param {Array<Object>} props.subscription.entity - The list of entities.
 * @param {string} props.subscription.entity[].name - The name of the entity.
 * @param {Object} props.subscription.site - The site object.
 * @param {string} props.subscription.site.name - The name of the site.
 * @returns {JSX.Element} The rendered card component.
 */
export const RenderingDescriptionListCard = ({
  programme,
  subProgramme,
  subscription
}) => {
  const { t } = useTranslation();
  let hardwareInsuranceDataset = {};
  let proMultiriskInsuranceDataset = {};
  let assemblyTestingInsuranceDataset = {};
  let legalProtectionInsuranceDataset = {};
  let environmentInsuranceDataset = {};
  const dataset = {
    ...subscription,
    entity: subscription?.entity?.map((entityItem) => entityItem?.name),
    site: subscription?.site?.name
  };

  if (programme === 'HARDWARE_INSURANCE') {
    hardwareInsuranceDataset = {
      ...subscription.hardware_insurance,
      entity: subscription?.entity,
      site: subscription?.site?.name
    };
  }

  if (programme === 'PROFESSIONAL_MULTIRISK_INSURANCE') {
    proMultiriskInsuranceDataset = {
      ...subscription.professional_multirisk_insurance,
      entity: subscription?.entity?.map((entityItem) => entityItem?.name),
      site: subscription?.site?.name
    };
  }
  if (programme === 'ASSEMBLY_TESTING_INSURANCE') {
    assemblyTestingInsuranceDataset = {
      company_id: subscription?.company_id
    };
  }
  if (programme === 'LEGAL_PROTECTION_INSURANCE') {
    legalProtectionInsuranceDataset = {
      ...subscription.legal_protection_insurance,
      entity: subscription?.entity?.map((entityItem) => entityItem?.name),
      company_name: subscription?.company_id?.name
    };
  }

  if (programme === 'ENVIRONMENT_INSURANCE') {
    environmentInsuranceDataset = {
      ...subscription.environment_insurance,
      desired_effective_date: subscription?.desired_effective_date,
      entity: subscription?.entity,
      company_id: subscription?.company_id
    };
  }

  const listContentCivilLiability = useListContentCivilLiability(dataset);
  const listContentConstruction = useListContentConstruction(dataset);
  const listContentTransport = useListContentTransport(dataset);
  const listContentCyberInsurance = useListContentCyberInsurance(dataset);
  const listContentSubHardwareInsurance = useListContentSubHardwareInsurance(
    hardwareInsuranceDataset
  );
  const listContentProMuliriskInsurance = useListContentProMultiriskInsurance(
    proMultiriskInsuranceDataset
  );
  const listContentAssemblyTestingInsurance = useListContentAssembyTesting(
    assemblyTestingInsuranceDataset
  );
  const listContentLegalProtectionInsurance = useListContentLegalProtection(
    legalProtectionInsuranceDataset
  );
  const listContentEnvironmentInsurance = useListContentEnvironmentInsurance(
    environmentInsuranceDataset
  );
  const listContentIndividualHousesBuilder =
    useListContentSubIndividualHousesBuilder(false, dataset);

  const selectListContent = (prog) => {
    switch (prog) {
      case 'CIVIL_LIABILITY':
        return listContentCivilLiability;
      case 'CONSTRUCTION':
        if (subProgramme === 'INDIVIDUAL_HOUSES_BUILDER') {
          return listContentIndividualHousesBuilder;
        }
        return listContentConstruction;
      case 'TRANSPORT':
        return listContentTransport;
      case 'CYBER_INSURANCE':
        return listContentCyberInsurance;
      case 'HARDWARE_INSURANCE':
        return listContentSubHardwareInsurance;
      case 'PROFESSIONAL_MULTIRISK_INSURANCE':
        return listContentProMuliriskInsurance;
      case 'ASSEMBLY_TESTING_INSURANCE':
        return listContentAssemblyTestingInsurance;
      case 'LEGAL_PROTECTION_INSURANCE':
        return listContentLegalProtectionInsurance;
      case 'ENVIRONMENT_INSURANCE':
        return listContentEnvironmentInsurance;
      default:
        return [];
    }
  };

  return (
    <>
      <Card title={t('subscriptions.form.informations')}>
        <DescriptionList
          data={selectListContent(programme)}
          translate
          layout="vertical"
        />
      </Card>
      {programme.includes('ENVIRONMENT_INSURANCE') && subscription && (
        <AddressDisplay
          data={
            subscription?.environment_insurance
              ?.sites_adress_and_if_under_authorization
          }
          translate
          layout="vertical"
        />
      )}
    </>
  );
};

RenderingDescriptionListCard.propTypes = {
  subProgramme: propTypes.string,
  programme: propTypes.string.isRequired,
  subscription: propTypes.shape({
    entity: propTypes.arrayOf(propTypes.shape({ name: propTypes.string })),
    site: propTypes.arrayOf(propTypes.shape({ name: propTypes.string })),
    desired_effective_date: propTypes.string,
    hardware_insurance: propTypes.shape({}),
    professional_multirisk_insurance: propTypes.shape({}),
    assembly_testing_insurance: propTypes.shape({}),
    environment_insurance: propTypes.shape({
      sites_adress_and_if_under_authorization: propTypes.arrayOf(
        propTypes.shape({})
      )
    }),
    company_id: propTypes.shape({
      name: propTypes.string
    }),
    legal_protection_insurance: propTypes.shape({})
  })
};

RenderingDescriptionListCard.defaultProps = {
  subProgramme: '',
  subscription: {
    entity: [],
    site: [],
    desired_effective_date: '',
    hardware_insurance: {},
    professional_multirisk_insurance: {},
    assembly_testing_insurance: {}
  }
};
