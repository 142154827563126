import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { useState, useEffect, useContext } from 'react';
import { useColumns } from './utils/columnsGolf';
import { useActionColumn } from './utils/actionColumn';
import { ListResource } from '../../../components/ListResource/ListResource';
import { useAuthContext } from '../../../contexts/AuthContext';
import { FilterByMissingDocuments } from '../../components/FilterByMissingDocuments';
import { GolfContext } from './GolfContext';

/**
 * A component that lists 'Golf' filtered by selected entity and site.
 *
 * `ListGolf` is responsible for fetching and listing 'Golf'.
 *
 * Once a user chooses an entity or a site, the component updates the browser URL with the
 * filtering parameters.
 *
 * @Component
 *
 * @returns {JSX.Element} The rendered `ListGolf` component with filtering capabilities.
 */
export const ListGolf = () => {
  const [isMissingDocuments, setIsMissingDocuments] = useState('');
  const [resetFilters, setResetFilters] = useState(false);
  const [showData, setShowData] = useState(true);
  const [edit, setEdit] = useState(true);
  const [reintegrate, setReintegrate] = useState(true);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [extraFilter, setExtraFilter] = useState('');
  const [isArchivatedPage, setArchivatedPage] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const currentPage = Number(params.get('p') || 1);
  const pageSize = Number(params.get('pS') || 10);

  const { generateAttestation } = useContext(GolfContext);
  const { company, permissions, verifPermissions } = useAuthContext();
  const { t } = useTranslation();

  let filterParameter = '';
  const resourceName = 'golfs';
  const dbExtraQueryFilter = `company=${company}`;
  const populate = 'entity,site,contract,company';

  const [columns, loading] = useColumns(
    resourceName,
    dbExtraQueryFilter,
    populate
  );
  const actionColumn = useActionColumn(
    edit,
    generateAttestation,
    isArchivatedPage
  );

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setShowData(
        !verifPermissions('datas/golfs', permissions?.notPermittedRoutes)
      );
      setEdit(
        !verifPermissions(
          '/programmes/golfs/edit/',
          permissions?.notPermittedRoutes
        )
      );
      setReintegrate(
        !verifPermissions(
          'programmes/golfs/reintegrate',
          permissions?.notPermittedRoutes
        )
      );
    }
  }, [permissions]);

  const onFilterMissingDocuments = (missingDocuments) => {
    setIsMissingDocuments(missingDocuments === false ? '' : missingDocuments);
  };

  useEffect(() => {
    if (resetFilters) {
      filterParameter = '';
      setResetFilters(false);
    }
    filterParameter = `missing_documents=${isMissingDocuments}__`;
    setExtraFilter(filterParameter);
    navigate({
      pathname: '/programmes/golfs',
      search: `?&p=${currentPage}&pS=${pageSize}&f=${filterParameter}`
    });
  }, [navigate, isMissingDocuments, resetFilters]);

  useEffect(() => {
    if (!forceRefresh) {
      setForceRefresh(false);
    }
  }, [forceRefresh]);

  if (loading) return <Spin fullscreen="true" />;

  return (
    <ListResource
      extraQuery={dbExtraQueryFilter}
      resourceName={resourceName}
      exportUrl={`exports/company/${company}/assets/programme-type/GOLF`}
      importUrl={`imports-golfs/golfs/company/${company}`}
      tradKey="golves"
      exportTemplateUrl="export-templates/template/golf"
      columns={[...columns, ...actionColumn]}
      withExtraFilters={
        <FilterByMissingDocuments
          onChange={onFilterMissingDocuments}
          setResetFilters={setResetFilters}
        />
      }
      populate={populate}
      editAction={false}
      showAction={false}
      sheetName={t('golves.title')}
      headers={[]}
      withUploadButton={showData}
      permissionReintegrate={reintegrate}
      customDelete
      forceRefresh={forceRefresh}
      setForceRefresh={() => setForceRefresh()}
      extraFilter={extraFilter}
      setIsArchivatedPage={setArchivatedPage}
      customActionColumn
      scroll={{ x: 4000 }}
    />
  );
};
