import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  Button,
  Skeleton,
  Row,
  Col,
  Card,
  Steps,
  Modal,
  Form,
  Input,
  Flex
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CheckOutlined,
  CheckCircleOutlined,
  EditOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { handleStatusUtils } from './utils/handleStatusUtils';
import { useListItemsStep } from './utils/show/uselistItemsStep';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { ContractCreateUpdate } from './contract/ContractCreateUpdate';
import { Documents } from './utils/Documents';
import { routes } from '../../utils/constants/adminRoutes';
import { History } from './utils/History';
import { useDraggerFilesKeysSelection } from './utils/show/useDraggerFilesKeysSelection';
import { SubscriptionContext } from './SubscriptionContext';
import { RenderingDescriptionListCard } from './utils/show/RenderingDescriptionListCard';
import { mandatoryDocumentsHandling } from './utils/show/mandatoryDocumentsHandling';
import { useHandleResize } from '../../utils/handleResize';

/**
 * Displays detailed information about a subscription.
 * @component
 * @returns {JSX.Element} The JSX element representing the subscription show page.
 */
export const SubscriptionShow = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI, permissions, verifPermissions } = useAuthContext();

  const [accept, setAccept] = useState(true);
  const [edit, setEdit] = useState(true);
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { width } = useHandleResize();
  const [subscriptionState, setSubscriptionState] = useState({
    subscription: {},
    formattedLink: '',
    subProgramme: '',
    programme: '',
    datas: [],
    contractNumber: undefined,
    contractId: undefined,
    isContractCreated: false,
    additionalCondition: false,
    isMandatoryDocumentOk: true
  });
  const {
    subscription,
    formattedLink,
    subProgramme,
    programme,
    datas,
    contractNumber,
    contractId,
    isContractCreated,
    additionalCondition,
    isMandatoryDocumentOk
  } = subscriptionState;

  const { forceRefresh } = useContext(SubscriptionContext);

  const { items } = useListItemsStep(isMandatoryDocumentOk);
  const { draggerFilesKeysOverRide, draggerFilesKeysMandatory, isDocument } =
    useDraggerFilesKeysSelection(programme, subProgramme, additionalCondition);

  const { current, setCurrent, history, setHistory, onChange } =
    handleStatusUtils(setVisible);

  const formatLink = (program) => program.toLowerCase().replace(/_/g, '-');
  const getSubscription = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/subscriptions/${id}?populate=site,entity,customer_manager,unit_manager,company_id,documents,contract,contract.documents`
      });

      setSubscriptionState({
        subscription: data,
        formattedLink: formatLink(data?.programme_types),
        subProgramme: data?.sub_programme[0],
        programme: data?.programme_types,
        datas: data,
        contractNumber: data?.contract?.contract_number,
        contractId: data?.contract?.documents?.filter(
          (doc) => doc?.document_type === 'CONTRACT'
        )[0]?._id,
        isContractCreated: data?.contract_created,
        additionalCondition:
          data?.yearly_turnover_exceeding_50_millions_message,
        documents: data?.documents,
        isMandatoryDocumentOk: mandatoryDocumentsHandling(
          data?.programme_types,
          data?.documents,
          data?.yearly_turnover_exceeding_50_millions_message
        )
      });
      setCurrent(data?.step);
      setHistory(data?.history);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [forceRefresh]);
  useEffect(() => {
    (async () => {
      await getSubscription();
    })();
  }, []);

  useEffect(() => {
    if (current === 3) {
      setAccept(false);
      setEdit(false);
    }
    if (permissions?.notPermittedRoutes?.length > 0) {
      setAccept(
        !verifPermissions(
          '/subscriptions/contract/accept',
          permissions?.notPermittedRoutes
        )
      );
      setEdit(
        !verifPermissions(
          '/subscriptions/contract/edit',
          permissions?.notPermittedRoutes
        )
      );
    }
  }, [permissions, current]);

  const handleOk = (data) => {
    const { refusal_reason } = data;

    onChange(3, id, true, refusal_reason);
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <PageHeaderCustom
        title={`${t(`subscriptions.form.${programme}`)}${
          subProgramme
            ? `- ${t(`subscriptions.form.${subProgramme}`)}- ${t(
                'subscriptions.form.subscription_request'
              )}`
            : `- ${t('subscriptions.form.subscription_request')}`
        }`}
        extra={
          <>
            {accept && current === undefined ? (
              <Button
                icon={<CheckOutlined />}
                onClick={() => {
                  onChange(0, id);
                }}
              >
                {`${t('subscriptions.form.acknowledge_receipt')} `}
              </Button>
            ) : null}
            {!isContractCreated && edit && (
              <Link
                to={{
                  pathname: `${routes.SUBSCRIPTIONS}/${formattedLink}/edit/${id}`
                }}
              >
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              </Link>
            )}
          </>
        }
      />
      <ContentCustom>
        {current !== undefined && (
          <Steps
            onChange={(step) => {
              onChange(step, id, datas);
            }}
            items={items}
            current={current}
            size="small"
            style={{ marginBottom: 16 }}
          />
        )}
        <Row gutter={[16, 16]}>
          <Col
            xs={24}
            xxl={current === 2 && datas && !isContractCreated && edit ? 17 : 24}
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} xxl={isContractCreated ? 14 : 24}>
                <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                  <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                    <RenderingDescriptionListCard
                      programme={programme}
                      subProgramme={subProgramme}
                      subscription={subscription}
                    />
                  </Skeleton>
                </Skeleton>
              </Col>
              <Col xs={24} xl={12} xxl={isContractCreated ? 6 : 12}>
                <Card title={t('subscriptions.form.history')}>
                  <History history={history} />
                </Card>
              </Col>
              {!isContractCreated &&
              formattedLink &&
              formattedLink !== 'transport' &&
              formattedLink !== 'professional-multirisk-insurance' &&
              isDocument ? (
                <Col xs={24} xl={12}>
                  <Documents
                    idWithoutParams={id}
                    baseUrl="subscriptions"
                    resource="Subscription"
                    draggerFilesKeysOverRide={draggerFilesKeysOverRide}
                    mandatoryDocuments={draggerFilesKeysMandatory}
                    setCurrent={setCurrent}
                  />
                </Col>
              ) : null}
              {isContractCreated && (
                <Col xs={24} xl={12} xxl={isContractCreated ? 4 : 12}>
                  <Card>
                    <Flex align="center" gap="middle">
                      <CheckCircleOutlined style={{ fontSize: 24 }} />
                      <Link
                        to={{
                          pathname: `${routes.DOCUMENTS}/show/${contractId}`
                        }}
                        className="simple-link"
                      >
                        {t(`subscriptions.form.created_contract`)}
                        {`${contractNumber}`}
                      </Link>
                    </Flex>
                  </Card>
                </Col>
              )}
            </Row>
          </Col>
          {current === 2 && datas && !isContractCreated && edit && (
            <Col xs={24} xxl={7}>
              <Card>
                <ContractCreateUpdate
                  purpose="create"
                  programmeType={programme}
                  subProgrammeType={subProgramme}
                  WithPageHeaderCustom={false}
                  stepper
                  setCurrent={onChange}
                  subId={id}
                  layout={width >= 1600 ? 'vertical' : 'horizontal'}
                />
              </Card>
            </Col>
          )}
        </Row>
        <Modal
          title={t('subscriptions.form.confirmation')}
          onCancel={handleCancel}
          open={visible}
          footer={null}
        >
          <Form onFinish={handleOk}>
            <Form.Item
              name="refusal_reason"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Row>
                <Button type="primary" htmlType="submit">
                  {t('buttons.validate')}
                </Button>
                <Button type="secondary" onClick={handleCancel}>
                  {t('buttons.cancel')}
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Modal>
      </ContentCustom>
    </>
  );
};
