import { useCallback, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton, Row, Col, Card } from 'antd';
import pluralize from 'pluralize';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useListContentCyberInsurance } from '../../subscriptions/utils/listContents/listContentSubCyberInsurance';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../components';
import { History } from '../../components/History';
import { useExtraButtons } from '../utils/useExtraButtons';

/**
 * Displays detailed information about a subscription.
 * @component
 * @returns {JSX.Element} The JSX element representing the subscription show page.
 */
export const ShowCyberInsurance = () => {
  const isMounted = useRef(false);
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [dataSet, setDataSet] = useState({
    datas: {},
    programme: '',
    url: undefined
  });
  const { datas, programme } = dataSet;
  const listContent = useListContentCyberInsurance(datas);
  const { extraButtons } = useExtraButtons(
    isDeleted,
    dataSet,
    'cyber-insurances',
    'Cyber-insurance'
  );

  const formatUrlPart = (str) =>
    pluralize(str.toLowerCase().replace(/_/g, '-'));

  const getCyberInsurance = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/cyber-insurances/${id}?populate=customer_manager,unit_manager,contract,company,entity,site`
      });
      if (isMounted.current) {
        setDataSet((prevState) => ({
          ...prevState,
          datas: {
            ...data,
            entity: (data?.entity || []).map((entityItem) => entityItem?.name),
            site: data?.site?.name
          },
          url: formatUrlPart(data?.programme_types),
          programme: data?.programme_types,
          isLoading: false
        }));
      }
    } catch (e) {
      if (e && isMounted.current) message(e);
    } finally {
      if (isMounted.current) setIsLoading(false);
    }
  }, [id, dispatchAPI, message]);

  useEffect(() => {
    isMounted.current = true;
    getCyberInsurance();
    return () => {
      isMounted.current = false;
    };
  }, [getCyberInsurance]);

  useEffect(() => {
    if (datas) setIsDeleted(datas?.deleted);
  }, [datas]);

  return (
    <>
      <PageHeaderCustom
        title={`${t('number')} ${datas?.unit_counter || 'xxxx'} - ${t(
          `subscriptions.form.${programme}`
        )}`}
        extra={extraButtons}
      />
      <ContentCustom>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={14}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <Row gutter={[0, 16]}>
                <Card title={t('subscriptions.form.informations')}>
                  <DescriptionList
                    data={listContent}
                    translate
                    layout="vertical"
                  />
                </Card>
              </Row>
            </Skeleton>
          </Col>
          <Col xs={24} xl={10}>
            <Card title={t('subscriptions.form.history')} size="small">
              <History route="cyber-insurances" />
            </Card>
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};
