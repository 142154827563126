import { useEffect } from 'react';
import { Tag } from 'antd';
import {
  CheckCircleFilled,
  CloseCircleOutlined,
  FileExclamationOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { claimStatus } from '../../../../utils/constants/tagColors';
import { useFiltersDataFetch } from '../../../../components/ColumnsDynamicFilters/useFiltersDatasFetch';
import { renderEntities } from '../../../../utils/formatters/entities';

/**
 * Custom hook that provides a collection of column configurations based on the provided options.
 * @hook
 * @param {Object} options - Options object.
 * @param {string} options.resourceName - The name of the resource.
 * @param {Object} options.dbExtraQueryFilter - Additional query filters for fetching data.
 * @param {boolean} options.populate - Flag indicating whether to populate data.
 * @param {string} options.targetType - Optional target type.
 * @returns {Object} Object containing column configurations for a table component.
 */
export const useColumnCollection = ({
  resourceName,
  dbExtraQueryFilter,
  populate,
  targetType
}) => {
  const { t } = useTranslation();

  const { fetchFilters, filters } = useFiltersDataFetch(
    resourceName,
    dbExtraQueryFilter,
    !targetType ? resourceName : targetType,
    populate
  );

  useEffect(() => {
    fetchFilters();
  }, []);

  const missing_documents = {
    key: 'documents',
    dataIndex: 'missing_documents',
    render: (missingdocuments) =>
      missingdocuments ? (
        <FileExclamationOutlined
          style={{ fontSize: 18, color: 'var(--warningColor)' }}
        />
      ) : null
  };
  const entity = {
    title: t('claims.form.entity'),
    key: 'entity',
    dataIndex: ['entity'],
    filters: filters?.entity?.map((ent) => ({
      text: ent.name,
      value: ent._id
    })),
    sorter: true,
    render: renderEntities
  };
  const entity_AdValorem = {
    title: t('claims.form.entity'),
    key: 'entity',
    dataIndex: ['entity', 'name'],
    filters: filters?.entity?.map((ent) => ({
      text: ent.name,
      value: ent._id
    })),
    sorter: true,
    render: (ent) => (ent ? ent.map((e) => e.name).join(', ') : '-')
  };
  const site = {
    title: t('claims.form.site'),
    key: 'site',
    dataIndex: ['site', 'name'],
    filters: filters?.site?.map((site_) => ({
      text: site_.name,
      value: site_._id
    })),
    sorter: true
  };
  const sinister_date = {
    title: t('claims.form.sinister_date'),
    key: 'sinister_date',
    dataIndex: ['sinister_date'],
    filters: filters?.sinister_date?.map((date) => ({
      text: moment(date).format('DD/MM/YYYY'),
      value: date
    })),
    render: (occurence_date) =>
      occurence_date ? moment(occurence_date).format('DD/MM/YYYY') : '-',
    sorter: true
  };
  const claim_type = {
    title: t('claims.form.claim_type'),
    key: 'claim_type',
    dataIndex: 'claim_type',
    filters: filters?.claim_type?.map((type) => ({
      text: t(`claims.form.${type}`),
      value: type
    })),
    sorter: true,
    render: (type) => t(`claims.form.${type}`)
  };
  const shipper_name = {
    title: t('claims.form.shipper_name'),
    key: 'shipper_name',
    dataIndex: ['entry_id', 'shipper_name'],
    filters: filters?.shipper_name?.map((shipper) => ({
      text: shipper,
      value: shipper
    })),
    sorter: true
  };
  const departureCity = {
    title: t('claims.form.departure_location'),
    key: 'departure_city',
    dataIndex: '',
    filters: filters?.departure_city?.map((location) => ({
      text: location,
      value: location
    })),
    render: (record) => {
      const { departure_city, departure_country } = record.entry_id;
      return `${departure_city || null}, ${departure_country || null}`;
    },
    sorter: true
  };
  const arrivalCity = {
    title: t('claims.form.arrival_location'),
    key: 'arrival_city',
    dataIndex: '',
    filters: filters?.arrival_city?.map((location) => ({
      text: location,
      value: location
    })),
    render: (record) => {
      const { arrival_city, arrival_country } = record.entry_id;
      return `${arrival_city || null}, ${arrival_country || null}`;
    },
    sorter: true
  };
  const insurance_company_reference = {
    title: t('claims.form.insurance_company_reference'),
    key: 'insurance_company_reference',
    dataIndex: 'insurance_company_reference',
    sorter: true,
    filters: filters?.insurance_company_reference?.map((ref) => ({
      text: ref,
      value: ref
    }))
  };
  const client_reference = {
    title: t('claims.form.client_reference'),
    key: 'client_reference',
    dataIndex: 'client_reference',
    sorter: true,
    filters: filters?.client_reference?.map((ref) => ({
      text: ref,
      value: ref
    }))
  };
  const insured_status = {
    title: t('claims.form.insured_status'),
    key: 'insured_status',
    dataIndex: ['insured_status'],
    filters: filters?.insured_status?.map((status) => ({
      text: t(`claims.form.${status}`),
      value: status
    })),
    sorter: true,
    render: (insuredstatus) =>
      insuredstatus ? t(`claims.form.${insuredstatus}`) : '-'
  };
  const goods_to_be_appraised = {
    title: t('claims.form.goods_to_be_appraised'),
    key: 'goods_to_be_appraised',
    dataIndex: ['goods_to_be_appraised'],
    filters: filters?.goods_to_be_appraised?.map((appraised) => ({
      text: t(`claims.form.${appraised}`),
      value: appraised
    })),
    sorter: true,
    render: (goodsToBeAppraised) =>
      goodsToBeAppraised && goodsToBeAppraised === 'YES' ? (
        <CheckCircleFilled />
      ) : (
        <CloseCircleOutlined />
      )
  };
  const goods_type = {
    title: t('claims.form.goods_type'),
    key: 'goods_type',
    dataIndex: ['goods_type'],
    filters: filters?.goods_type?.map((type) => ({
      text: t(`claims.form.${type}`),
      value: type
    })),
    sorter: true
  };
  const opening_date = {
    title: t('claims.form.opening_date'),
    key: 'opening_date',
    dataIndex: ['opening_date'],
    filters: filters?.opening_date?.map((date) => ({
      text: moment(date).format('DD/MM/YYYY'),
      value: date
    })),
    sorter: true,
    render: (openingDate) =>
      openingDate ? moment(openingDate).format('DD/MM/YYYY') : '-'
  };
  const commentaries = {
    title: t('claims.form.commentaries'),
    key: 'commentaries',
    dataIndex: ['commentaries'],
    filters: filters?.commentaries?.map((comment) => ({
      text: comment,
      value: comment
    })),
    sorter: true,
    render: (comment) => comment || '-'
  };
  const claim_status = {
    title: t('claims.form.claim_status'),
    key: 'claim_status',
    dataIndex: 'claim_status',
    filters: filters?.claim_status?.map((status) => ({
      text: t(`claims.form.${status}`),
      value: status
    })),
    render: (status) =>
      status ? (
        <Tag color={claimStatus[status]}>
          {t(`claims.form.${status}`, {
            defaultValue: status
          })}
        </Tag>
      ) : (
        <Tag color="orange">{t('claims.form.reception_pending')}</Tag>
      ),
    sorter: true
  };

  return {
    missing_documents,
    entity,
    entity_AdValorem,
    site,
    sinister_date,
    claim_type,
    shipper_name,
    departureCity,
    arrivalCity,
    insurance_company_reference,
    client_reference,
    insured_status,
    goods_to_be_appraised,
    goods_type,
    opening_date,
    commentaries,
    claim_status
  };
};
