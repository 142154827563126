import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { useColumns } from './utils/columnsProMultiriskInsurance';
import { useActionColumn } from '../../components/actionColumnProgGenerated';
import { ListResource } from '../../../components/ListResource/ListResource';
import { useAuthContext } from '../../../contexts/AuthContext';
import { ResetFilterButton } from '../../components/ResetFiltersButton';

/**
 * `ListProMultiriskInsurance` is a React component designed to list and manage light vehicles.
 * It provides filtering options by vehicle type, entity, missing documents and site.
 * The component fetches the data based on the filters applied and displays it in a tabular format.
 *
 * @component
 * @returns {ReactElement} Rendered `ListProMultiriskInsurance` component.
 */

export const ListProMultiriskInsurance = () => {
  const { company, permissions, verifPermissions } = useAuthContext();
  const { t } = useTranslation();
  const [resetFilters, setResetFilters] = useState(false);
  const [extraFilter, setExtraFilter] = useState('');
  const [showData, setShowData] = useState(true);
  const [edit, setEdit] = useState(true);
  const [reintegrate, setReintegrate] = useState(true);
  const resourceName = 'professional-multirisk-insurances';
  const [isArchivatedPage, setArchivatedPage] = useState(false);
  const actionColumn = useActionColumn(
    edit,
    isArchivatedPage,
    resourceName,
    resourceName
  );

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const currentPage = Number(params.get('p') || 1);
  const pageSize = Number(params.get('pS') || 10);

  let filterParameter = '';
  const dbExtraQueryFilter = `company=${company}`;
  const populate = 'entity,site,contract,company';

  const [columns, loading] = useColumns(
    resourceName,
    dbExtraQueryFilter,
    populate
  );

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setShowData(
        !verifPermissions(
          'datas/professional-multirisk-insurances',
          permissions?.notPermittedRoutes
        )
      );
      setEdit(
        !verifPermissions(
          '/programmes/professional-multirisk-insurances/edit',
          permissions?.notPermittedRoutes
        )
      );
      setReintegrate(
        !verifPermissions(
          'programmes/professional-multirisk-insurances/reintegrate',
          permissions?.notPermittedRoutes
        )
      );
    }
  }, [permissions]);

  useEffect(() => {
    if (resetFilters) {
      filterParameter = '';
      setResetFilters(false);
    }
    setExtraFilter(filterParameter);
    navigate({
      pathname: '/programmes/professional-multirisk-insurances',
      search: `?&p=${currentPage}&pS=${pageSize}&f=${filterParameter}`
    });
  }, [navigate, resetFilters]);

  if (loading) return <Spin fullscreen="true" />;

  return (
    <ListResource
      extraQuery={dbExtraQueryFilter}
      resourceName={resourceName}
      tradKey="professional_multirisk_insurances"
      template="professional_multirisk_insurances"
      exportUrl={`exports/company/${company}/assets/programme-type/PROFESSIONAL_MULTIRISK_INSURANCE`}
      importUrl={`imports-professional-multirisk-insurances/professional-multirisk-insurances/company/${company}`}
      exportTemplateUrl="export-templates/template/professional-multirisk-insurance"
      sheetName={t('professional_multirisk_insurances.sheetName')}
      columns={[...columns, ...actionColumn]}
      withExtraFilters={<ResetFilterButton setResetFilters={setResetFilters} />}
      populate={populate}
      headers={[]}
      showAction={false}
      editAction={false}
      duplicateAction={false}
      printAction={false}
      extraFilter={extraFilter}
      customDelete
      setIsArchivatedPage={setArchivatedPage}
      permissionReintegrate={reintegrate}
      withUploadButton={showData}
      scroll={{ x: 3000 }}
    />
  );
};
