/**
 * Formats a number by ensuring it has two decimal places and adding spaces as thousand separators.
 *
 * @param {number} number - The number to be formatted.
 * @returns {string} The formatted number as a string.
 */
export const formatNumber = (number) =>
  number
    .toFixed(2) // Ensure there are two decimal places
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' '); // Add spaces as thousand separators
