import PropTypes from 'prop-types';
import { useFields } from './fieldsAdValorem';
import { TransportsCreateUpdate } from '../TransportCreateUpdate';

/**
 * Provides a component for creating or updating ad valorem insurance claims.
 *
 * @component
 * @param {object} props - React component props.
 * @param {string} props.purpose - The purpose of the component, either "create" or "edit".
 * @returns {React.ReactNode} The CreateUpdateContainer component with specific configurations for ad valorem insurance claims.
 */
export const AdValoremCreateUpdate = ({ purpose }) => (
  <TransportsCreateUpdate
    purpose={purpose}
    useFields={useFields}
    mandatoryDocuments={['full_statement', 'consignment_note']}
    populate="entity,site,contract,company_Id"
    sub_programme="AD_VALOREM"
    fromAdValorem
  />
);

AdValoremCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
