import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Select, Divider, Button, Row } from 'antd';
import { Add } from '@carbon/icons-react';
import { useAuthContext } from '../contexts/AuthContext';
import { useErrorMessage } from '../utils/errorMessage';
import { useHandleResize } from '../utils/handleResize';

/**
 * `AdminCustomerSelector` is a React component that provides a dropdown selection
 * for companies (customers) with an additional option to navigate to the creation page for new companies.
 * The selected company's ID is stored in the application's authentication context.
 *
 * The component is designed to be placed at the bottom of a container and shows a list
 * of available companies, allowing an admin user to select one or add a new one.
 * @component
 * @returns {JSX.Element} A company dropdown selector with an additional option to create a new company.
 */

const AdminCustomerSelector = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    company,
    setCompany,
    dispatchAPI,
    setCompanyName,
    permissions,
    verifPermissions,
    token
  } = useAuthContext();
  const { message } = useErrorMessage();
  const [companies, setCompanies] = useState([]);
  const [showCreate, setShowCreate] = useState(true);
  const [showSelect, setShowSelect] = useState(true);
  const { width } = useHandleResize();
  const [isMounted, setIsMounted] = useState(false);

  const onsearch = (input, option) => {
    const result = option?.label.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const getCompany = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      setCompanies(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      if (token) {
        await getCompany();
      }
    })();
  }, [token]);

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setShowCreate(
        !verifPermissions('add/clients', permissions?.notPermittedRoutes)
      );
      setShowSelect(
        !verifPermissions('/select/client', permissions?.notPermittedRoutes)
      );
    }
  }, [permissions]);

  useEffect(() => {
    if (isMounted) {
      if (token) {
        if (company === '64e480fb7b38bc884859ad2f') {
          navigate('/');
        } else {
          navigate('/programmes');
        }
      }
    } else {
      setIsMounted(true);
    }
  }, [company]);

  const arrayData = (compa) => {
    const compCells = compa.map((comp) => ({
      value: comp._id,
      label: comp.name
    }));

    return [...compCells];
  };

  const handleCompanyChange = (value) => {
    const selectedCompany = companies.find((comp) => comp._id === value);

    if (selectedCompany) {
      setCompany(value);
      setCompanyName(selectedCompany.name);
    } else {
      setCompany('');
      setCompanyName('');
    }
  };

  return (
    <Row
      style={{
        width: '90%',
        margin: '5%',
        position: 'absolute',
        bottom: 10,
        flexDirection: 'column'
      }}
      align="middle"
    >
      <Divider
        style={{ backgroundColor: 'var(--secondaryBackground)', width: '100%' }}
      />
      {showSelect && (
        <Select
          defaultValue={company}
          style={{
            width: '100%'
          }}
          onChange={handleCompanyChange}
          options={arrayData(companies)}
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        />
      )}
      {showCreate && (
        <Button
          style={{
            marginTop: 10,
            width: width > 992 ? '100%' : undefined
          }}
          onClick={() => navigate('/companies/create')}
          shape={width > 992 ? 'default' : 'circle'}
          block={width > 992}
        >
          {width > 992 ? (
            t('basiclayout.menu.add_new_client')
          ) : (
            <Add size={22} />
          )}
        </Button>
      )}
    </Row>
  );
};

export default AdminCustomerSelector;
