import { DisplaySignature } from '../../../../claims/utils/DisplaySignature';

/**
 * Custom hook for building the list content for individual houses builders.
 *
 * @param {string} programme_id - The ID of the programme.
 * @param {Object} data - The data object containing individual houses builder information.
 * @returns {Array} - An array of objects representing the list content.
 */
export const useSignatureListContent = (programme_id, data = {}) => {
  const { individual_houses_builder = {} } = data;
  const {
    declarant_last_name,
    declarant_first_name,
    declarant_function,
    signed_on,
    signed_at
  } = individual_houses_builder;
  return [
    {
      label: 'individual_houses_builders.form.declarant_last_name',
      span: 1,
      content: (declarant_last_name && declarant_last_name) || '-'
    },
    {
      label: 'individual_houses_builders.form.declarant_first_name',
      span: 1,
      content: (declarant_first_name && declarant_first_name) || '-'
    },
    {
      label: 'individual_houses_builders.form.declarant_function',
      span: 1,
      content: (declarant_function && declarant_function) || '-'
    },
    {
      label: 'individual_houses_builders.form.signed_at',
      span: 1,
      content: (signed_at && signed_at) || '-'
    },
    {
      label: 'individual_houses_builders.form.signed_on',
      span: 1,
      content: (signed_on && signed_on) || '-'
    },
    {
      label: 'individual_houses_builders.form.signature',
      span: 1,
      content: (
        <DisplaySignature
          baseUrl="individual-houses-builder"
          programme_id={programme_id}
        />
      )
    }
  ];
};
