import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';
import { useColumns } from '../../components/columnsSubscriptionTypeContract';
import { useActionColumn } from './utils/actionColumnOtherTransport';
import { ListResource } from '../../../components/ListResource/ListResource';
import { useAuthContext } from '../../../contexts/AuthContext';

/**
 * `ListOtherTransport` is a React component designed to list and manage light vehicles.
 * It provides filtering options by vehicle type, entity, missing documents and site.
 * The component fetches the data based on the filters applied and displays it in a tabular format.
 *
 * @component
 * @returns {ReactElement} Rendered `ListOtherTransport` component.
 */

export const ListOtherTransport = ({ subProgramme }) => {
  const { company, permissions, verifPermissions } = useAuthContext();
  const [edit, setEdit] = useState(true);
  const columns = useColumns();
  const actionColumn = useActionColumn(edit);
  const { t } = useTranslation();

  const formatUrlPart = (str) =>
    pluralize(str.toLowerCase().replace(/_/g, '-'));
  const url = formatUrlPart(subProgramme);

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0 && subProgramme) {
      setEdit(
        !verifPermissions(
          `/programmes/${url}/edit/`,
          permissions?.notPermittedRoutes
        )
      );
    }
  }, [permissions, subProgramme]);

  return (
    <ListResource
      extraQuery={`company_Id=${company}&sub_programme=${subProgramme}`}
      resourceName="transports"
      exportUrl={`exports/company/${company}/sub-programme/${subProgramme}`}
      tradKey={subProgramme}
      columns={[...columns, ...actionColumn]}
      noCreateButton
      populate="contract,entity,company_Id"
      sheetName={t('transports.title')}
      headers={[]}
      editAction={false}
      showAction={false}
      duplicateAction={false}
      printAction={false}
      deleteAction={false}
      withUploadButton={false}
      customActionColumn
      scroll={{ x: 3000 }}
    />
  );
};

ListOtherTransport.propTypes = {
  subProgramme: PropTypes.string.isRequired
};
