import { Route, Routes } from 'react-router-dom';
import { ListDocuments } from './ListDocuments';
import { DocumentsCreateUpdate } from './DocumentsCreateUpdate';
import { ShowDocument } from './ShowDocument';
import { Exception } from '../../components';
import { GuaranteesSummaryEdit } from './GuaranteesSummaryEdit';
import { DocumentsContextProvider } from './DocumentsContext';
import { AmendmentCreateUpdate } from './AmendmentCreateUpdate';
import { AttestationEdit } from './AttestationsEdit';

/**
 * `DocumentRouter` is a component that defines the routing logic for the document-related features.
 *
 * Within the `DocumentsContextProvider`, the component defines several routes:
 * - `/create`: For creating a new document. Renders the `DocumentsCreateUpdate` component with a "create" purpose.
 * - `/edit/:id`: For editing an existing document, where `:id` is the document's ID. Renders the `DocumentsCreateUpdate` component with an "edit" purpose.
 * - `/show/:id`: For displaying the details of a document by its ID. Renders the `ShowDocument` component.
 * - The index route renders the `ListDocuments` component, which presumably lists all documents.
 * - For any undefined route, the `Exception` component is rendered, which likely displays an error or "not found" message.
 *
 * @component
 * @returns {React.ElementType} A set of routes wrapped within the `DocumentsContextProvider`.
 */

export const DocumentRouter = () => (
  <DocumentsContextProvider>
    <Routes>
      <Route
        path="/create"
        element={<DocumentsCreateUpdate purpose="create" />}
      />
      <Route
        path="/edit/:id"
        element={<DocumentsCreateUpdate purpose="edit" />}
      />
      <Route
        path="/edit-amendments/:id"
        element={<AmendmentCreateUpdate purpose="edit" />}
      />
      <Route path="/edit-guarantees/:id" element={<GuaranteesSummaryEdit />} />
      <Route path="/edit-attestations/:id" element={<AttestationEdit />} />
      <Route path="/show/:id" element={<ShowDocument />} />
      <Route index element={<ListDocuments />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </DocumentsContextProvider>
);
