import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback, useRef } from 'react';
import { FileExclamationOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { useFiltersDataFetch } from '../../../../../components/ColumnsDynamicFilters/useFiltersDatasFetch';

/**
 * `useColumns` is a custom React hook that provides a list of column configurations.
 *
 * The column titles are fetched using the `react-i18next` hook for internationalization.
 *
 * @hook
 * @returns {Array<object>} Returns an array of column configurations to be consumed by a table component.
 */

export const useColumns = (
  resourceName,
  dbExtraQueryFilter,
  targetType,
  populate
) => {
  const { t } = useTranslation();
  const { dispatchAPI, company: companyId } = useAuthContext();
  const { message } = useErrorMessage();
  const [config, setConfig] = useState({});
  const { fetchFilters, filters, loading } = useFiltersDataFetch(
    resourceName,
    dbExtraQueryFilter,
    targetType,
    populate
  );
  const isMounted = useRef(true);

  const allColumns = [
    {
      title: t('vehicles.form.missing_documents'),
      key: 'missing_documents',
      dataIndex: ['missing_documents'],
      sorter: true,
      width: 150,
      render: (missing_documents) =>
        missing_documents ? (
          <FileExclamationOutlined
            style={{ fontSize: 18, color: 'var(--warningColor)' }}
          />
        ) : (
          '-'
        )
    },
    {
      title: t('vehicles.form.company_name'),
      key: 'company_name',
      dataIndex: ['company', 'name'],
      sorter: true
    },
    {
      title: t('vehicles.form.contract_number'),
      key: 'contract',
      dataIndex: ['contract', 'contract_number'],
      filters: filters?.contract?.map((number) => ({
        text: number.contract_number,
        value: number._id
      })),
      sorter: true
    },
    {
      title: t('vehicles.form.date_added'),
      key: 'created_at',
      dataIndex: ['created_at'],
      filters: filters?.created_at?.map((date) => ({
        text: dayjs(date).format('DD/MM/YYYY'),
        value: date
      })),
      render: (created_at) =>
        created_at ? dayjs(created_at).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('vehicles.form.validity_start_date'),
      key: 'validity_start_date',
      dataIndex: ['contract', 'validity_start_date'],
      render: (validity_start_date) =>
        validity_start_date
          ? dayjs(validity_start_date).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t('vehicles.form.validity_end_date'),
      key: 'validity_end_date',
      dataIndex: ['contract', 'validity_end_date'],
      render: (validity_end_date) =>
        validity_end_date ? dayjs(validity_end_date).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('vehicles.form.entity'),
      key: 'entity',
      dataIndex: ['entity', 'name'],
      filters: filters?.entity?.map((entity) => ({
        text: entity.name,
        value: entity._id
      })),
      sorter: true
    },
    {
      title: t('vehicles.form.site'),
      key: 'site',
      dataIndex: ['site', 'name'],
      filters: filters?.site?.map((site) => ({
        text: site.name,
        value: site._id
      })),
      sorter: true
    },
    {
      title: t('vehicles.form.address'),
      key: 'address',
      dataIndex: ['company'],
      sorter: true,
      render: (company) =>
        company
          ? `${company.number || ''} ${company.street || ''} ${
              company.additional || ''
            }`
          : '-'
    },
    {
      title: t('vehicles.form.postal_code'),
      key: 'postal_code',
      dataIndex: ['company', 'zip_code'],
      sorter: true
    },
    {
      title: t('vehicles.form.city'),
      key: 'city',
      dataIndex: ['company', 'city'],
      sorter: true
    },
    {
      title: t('vehicles.form.products'),
      key: 'products',
      dataIndex: 'fleet_type',
      sorter: true,
      render: (fleet_type) => fleet_type && t(`vehicles.form.${fleet_type}`)
    },
    {
      title: t('vehicles.form.collaborator_first_name'),
      key: 'collaborator_first_name',
      dataIndex: 'collaborator_first_name',
      filters: filters?.collaborator_first_name?.map((name) => ({
        text: name,
        value: name
      })),
      sorter: true
    },
    {
      title: t('vehicles.form.collaborator_last_name'),
      key: 'collaborator_last_name',
      dataIndex: 'collaborator_last_name',
      filters: filters?.collaborator_last_name?.map((name) => ({
        text: name,
        value: name
      })),
      sorter: true
    },
    {
      title: t('vehicles.form.vehicle_registration_number'),
      key: 'vehicle_registration_number',
      dataIndex: 'vehicle_registration_number',
      filters: filters?.vehicle_registration_number?.map((number) => ({
        text: number,
        value: number
      })),
      sorter: true
    },
    {
      title: t('vehicles.form.departure_time'),
      key: 'departure_time',
      dataIndex: ['departure_time'],
      filters: filters?.departure_time?.map((time) => ({
        text: dayjs(time).format('HH:MM'),
        value: time
      })),
      render: (departure_time) =>
        departure_time ? dayjs(departure_time).format('HH:MM') : '-',
      sorter: true
    },
    {
      title: t('vehicles.form.start_of_the_mission'),
      key: 'start_of_the_mission',
      dataIndex: ['start_of_the_mission'],
      filters: filters?.start_of_the_mission?.map((date) => ({
        text: dayjs(date).format('DD/MM/YYYY'),
        value: date
      })),
      render: (start_of_the_mission) =>
        start_of_the_mission
          ? dayjs(start_of_the_mission).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t('vehicles.form.planned_end_of_the_mission'),
      key: 'planned_end_of_the_mission',
      dataIndex: ['planned_end_of_the_mission'],
      filters: filters?.planned_end_of_the_mission?.map((date) => ({
        text: dayjs(date).format('DD/MM/YYYY'),
        value: date
      })),
      render: (planned_end_of_the_mission) =>
        planned_end_of_the_mission
          ? dayjs(planned_end_of_the_mission).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t('vehicles.form.journey_lenght'),
      key: 'journey_lenght',
      dataIndex: 'journey_lenght',
      filters: filters?.journey_lenght?.map((lenght) => ({
        text: lenght,
        value: lenght
      })),
      sorter: true
    },
    {
      title: t('vehicles.form.departure_city'),
      key: 'departure_city',
      filters: filters?.departure_city?.map((city) => ({
        text: city,
        value: city
      })),
      dataIndex: 'departure_city',
      sorter: true
    },
    {
      title: t('vehicles.form.arrival_city'),
      key: 'arrival_city',
      dataIndex: 'arrival_city',
      filters: filters?.arrival_city?.map((city) => ({
        text: city,
        value: city
      })),
      sorter: true
    },
    {
      title: t('vehicles.form.deletion_date'),
      key: 'deletion_date_effect',
      dataIndex: 'deletion_date_effect',
      sorter: true,
      filters: filters?.deletion_date_effect?.map((date) => ({
        text: dayjs(date).format('DD/MM/YYYY'),
        value: date
      })),
      render: (deletion_date_effect) =>
        deletion_date_effect
          ? dayjs(deletion_date_effect).format('DD/MM/YYYY')
          : '-'
    }
  ];

  const getConfig = useCallback(async () => {
    if (!isMounted.current) return;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/programme-configs/${companyId}/automissions-configs`
      });
      setConfig(data[0]);
    } catch (e) {
      if (isMounted.current) message('missing_parameters');
    }
  }, [dispatchAPI, message]);

  useEffect(() => {
    getConfig();
    fetchFilters();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const filteredColumns = allColumns.filter((column) => config[column.key]);

  return [filteredColumns, loading] || [];
};
