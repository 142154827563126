// TODO: updated to customer new query. Awaiting feedback prior to deletion.
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useFiltersDataFetch } from '../../../../components/ColumnsDynamicFilters/useFiltersDatasFetch';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * `useColumns` is a custom React hook that provides a list of column configurations.
 *
 * The column titles are fetched using the `react-i18next` hook for internationalization.
 *
 * @hook
 * @returns {Array<object>} Returns an array of column configurations to be consumed by a table component.
 */

export const useColumns = (resourceName, dbExtraQueryFilter, populate) => {
  const { dispatchAPI, company: companyId } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  const { fetchFilters, filters, loading } = useFiltersDataFetch(
    resourceName,
    dbExtraQueryFilter,
    resourceName,
    populate
  );
  const [config, setConfig] = useState({});
  const isMounted = useRef(true);

  const allColumns = [
    {
      title: t('professional_multirisk_insurances.form.contract_number'),
      key: 'contract_number',
      dataIndex: ['contract', 'contract_number'],
      sorter: true
    },
    {
      title: t('professional_multirisk_insurances.form.company_name'),
      key: 'company_name',
      dataIndex: ['company', 'name'],
      sorter: true
    },
    {
      title: t('professional_multirisk_insurances.form.entity'),
      key: 'entity',
      dataIndex: ['entity'],
      filters: (filters?.entity || []).map((entity) => ({
        text: entity.name,
        value: entity._id
      })),
      sorter: true,
      render: (entity) => (entity ? entity.name : '-')
    },
    {
      title: t('professional_multirisk_insurances.form.site'),
      key: 'site',
      dataIndex: ['site'],
      filters: (filters?.site || []).map((site) => ({
        text: site.name,
        value: site._id
      })),
      sorter: true,
      render: (site) => (site ? site.name : '-')
    },
    {
      title: t('professional_multirisk_insurances.form.risk_site_name'),
      key: 'risk_site_name',
      dataIndex: ['risk_site_name'],
      filters: (filters?.risk_site_name || []).map((value) => ({
        text: value,
        value
      })),
      sorter: true
    },
    {
      title: t('professional_multirisk_insurances.form.risk_site_address'),
      key: 'risk_site_address',
      dataIndex: ['risk_site_address'],
      filters: (filters?.risk_site_address || []).map((risk_site_address) => ({
        text: risk_site_address,
        value: risk_site_address
      })),
      sorter: true
    },
    {
      title: t('professional_multirisk_insurances.form.postal_code'),
      key: 'postal_code',
      dataIndex: ['postal_code'],
      filters: (filters?.postal_code || []).map((postal_code) => ({
        text: postal_code,
        value: postal_code
      })),
      sorter: true
    },
    {
      title: t('professional_multirisk_insurances.form.city'),
      key: 'city',
      dataIndex: ['city'],
      filters: (filters?.city || []).map((city) => ({
        text: city,
        value: city
      })),
      sorter: true
    },
    {
      title: t('professional_multirisk_insurances.form.entry_date'),
      key: 'entry_date',
      dataIndex: ['entry_date'],
      filters: (filters?.entry_date || []).map((entry_date) => ({
        text: dayjs(entry_date).format('DD/MM/YYYY'),
        value: entry_date
      })),
      render: (entry_date) =>
        entry_date ? dayjs(entry_date).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t(
        'professional_multirisk_insurances.form.total_insured_developed_area'
      ),
      key: 'total_insured_developed_area',
      dataIndex: ['total_insured_developed_area'],
      filters: (filters?.total_insured_developed_area || []).map((value) => ({
        text: `${value} m²`,
        value
      })),
      sorter: true
    }
  ];

  const getConfig = useCallback(async () => {
    if (!isMounted.current) return;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/programme-configs/${companyId}/professional-multirisk-insurance-configs`
      });
      setConfig(data[0]);
    } catch (e) {
      if (isMounted.current) message('missing_parameters');
    }
  }, [dispatchAPI, message]);

  useEffect(() => {
    getConfig();
    fetchFilters();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const filteredColumns = allColumns.filter((column) => config[column.key]);

  return [filteredColumns, loading] || [];
};
