import { GarageInfosButton } from '../../utils/ClaimsButtons/GarageInfosButton';
/**
 * Renders the Mailing Button for GarageInfo component based on a render condition.
 * @component
 * @param {boolean} renderCondition - Whether to render the Mailing Button for Vehicles.
 * @param {Object} vehicleClaim - Vehicle data.
 * @param {Function} setChangingStatus - Function to set changing status.
 * @returns {?React.Component} The rendered Mailing Button for Vehicles component, or null if the render condition is false.
 */
const renderGarageInfos = (
  renderCondition,
  vehicleClaim,
  setChangingStatus
) => {
  if (renderCondition) {
    return (
      <GarageInfosButton
        claimReferenceNumber={vehicleClaim?.unit_counter}
        setChangingStatus={setChangingStatus}
      />
    );
  }
  return null;
};

export default renderGarageInfos;
