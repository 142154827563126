import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { Select, Form } from 'antd';
import { DocumentsContext } from '../documents/DocumentsContext';

const { Option } = Select;
const { Item } = Form;

/**
 * `SelectProgrammes` is a React component that presents a multi-select dropdown selector, allowing users
 * to choose multiple sub_programme types. The available sub_programme types are fetched from the `DocumentsContext`.
 *
 *
 * @component
 * @param {object} props - The properties passed to the component.
 * @param {string} props.dbKey - The database key corresponding to the selected value(s) for this dropdown.
 * @param {function} props.onSelect - A callback function that's invoked when one or more options are selected or deselected.
 *
 * @returns {JSX.Element} Returns a rendered multi-select dropdown selector for sub_programme types.
 *
 */

export const MultipleSelectSubProgrammes = ({ dbKey, isDisabled }) => {
  const { enums } = useContext(DocumentsContext);
  const { t } = useTranslation();

  return (
    <Item name={[`${dbKey}`]}>
      <Select
        mode="multiple"
        showSearch
        filterOption={(input, option) =>
          (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
        }
        disabled={isDisabled}
      >
        {enums?.[dbKey].map((sub_programme_type) => (
          <Option key={sub_programme_type} value={sub_programme_type}>
            {t(`subscriptions.form.${sub_programme_type}`)}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

MultipleSelectSubProgrammes.propTypes = {
  dbKey: PropTypes.string,
  isDisabled: PropTypes.bool
};

MultipleSelectSubProgrammes.defaultProps = {
  dbKey: undefined,
  isDisabled: false
};
