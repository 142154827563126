import moment from 'moment';
import { useTranslation } from 'react-i18next';

/**
 * `useListContent` is a custom React hook to generate a list content based on a given data.
 *
 * @hook
 * @param {object} data - The input data containing details about the document. Default is an empty object.
 * @returns {Array<object>} A list of objects containing label-content pairs for display, each object has
 * a `label` to indicate the type of data, a `span` to denote the span or size, and a `content` to denote the actual value.
 *
 * */

export const useListContent = (data = {}) => {
  const { t } = useTranslation();

  const {
    document_type,
    company_Id,
    insurance_company,
    entity,
    contract_number,
    validity_start_date,
    validity_end_date,
    subscriber_last_name_first_name,
    number,
    street,
    additional_address,
    postal_code,
    city,
    programme_types = [],
    fleet_sub_programmes = [],
    civil_liability_sub_programmes = [],
    transport_sub_programmes = [],
    construction_sub_programmes = []
  } = data;

  const subscriberAdressParts = [
    number,
    street,
    additional_address,
    postal_code,
    city
  ];
  const subscriberAddress = subscriberAdressParts
    .filter((part) => part)
    .join(' ');

  const subProgrammesParts = [
    ...(fleet_sub_programmes || []),
    ...(civil_liability_sub_programmes || []),
    ...(transport_sub_programmes || []),
    ...(construction_sub_programmes || [])
  ];
  const subProgrammes = subProgrammesParts
    .filter((part) => typeof part === 'string')
    .map((part) => t(`${part.toLowerCase()}.title`))
    .join(', ');
  return [
    {
      label: 'documents.form.document_type',
      span: 1,
      content: document_type
        ? t(`documents.status.${document_type}`)
        : t('documents.form.contract')
    },
    {
      label: 'documents.form.insurance_company',
      span: 1,
      content: insurance_company || '-'
    },
    {
      label: 'documents.form.company',
      span: 1,
      content: company_Id ? company_Id.name : '-'
    },
    {
      label: 'documents.form.entity',
      span: 1,
      content: entity ? entity.map((e) => e.name).join(',  ') : '-'
    },
    {
      label: 'documents.form.contract_number',
      span: 1,
      content: contract_number || '-'
    },
    {
      label: 'documents.form.validity_start_date',
      span: 1,
      content:
        (validity_start_date &&
          moment(validity_start_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'documents.form.validity_end_date',
      span: 2,
      content:
        (validity_end_date && moment(validity_end_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'documents.form.programmes_types',
      span: 1,
      content:
        (programme_types &&
          programme_types
            .map((part) => t(`${part.toLowerCase()}.title`))
            .join(', ')) ||
        '-'
    },
    {
      label: 'documents.form.sub_programmes',
      span: 1,
      content: subProgrammes || '-'
    },
    {
      label: 'documents.form.subscriber_last_name_first_name',
      span: 1,
      content: subscriber_last_name_first_name || '-'
    },
    {
      label: 'documents.form.subscriber_address',
      span: 1,
      content: subscriberAddress || '-'
    }
  ];
};
