import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Utility function for handling fields related to agriculture.
 *
 * @param {boolean} visible - Indicates if a modal is visible.
 * @param {function} setVisible - Function to set the visibility of a modal.
 * @returns {object} - An object containing various field options and components.
 */
export const fieldsUtils = (visible, setVisible) => {
  const { t } = useTranslation();

  const cultureTypeOptions = [
    'arboriculture',
    'viticulture',
    'field_culture',
    'priaires'
  ];

  const multirisqueInfo = [
    [
      'heatstroke',
      'cold_snap',
      'sunburn',
      'water_excess',
      'temperature_excess',
      'excessive_humidity',
      'lack_solar_radiation'
    ],
    [
      'torrential_rains',
      'violent_rains',
      'snow_or_frost_weight',
      'drought',
      'low_temperatures',
      'whirlwind_storm',
      'sandstorm'
    ]
  ];

  const desired_guarantee_options = [
    {
      label: t('agriculture.form.desired_guarantee_options.gel'),
      value: 'gel'
    },
    {
      label: t('agriculture.form.desired_guarantee_options.multirisque'),
      value: 'multirisque'
    },
    {
      label: t('agriculture.form.desired_guarantee_options.hail'),
      value: 'hail'
    },
    {
      label: t('agriculture.form.desired_guarantee_options.loss_of_yield'),
      value: 'loss_of_yield'
    }
  ];
  const existing_guarantee_options = [
    {
      label: t('agriculture.form.existing_guarantee_options.yes'),
      value: 'yes'
    },
    {
      label: t('agriculture.form.existing_guarantee_options.no'),
      value: 'no'
    }
  ];

  const salesLabel = (
    <>
      {`${t('agriculture.form.average_sales_price')}`}&nbsp;
      <InfoCircleOutlined
        onClick={() => {
          setVisible('average_sales_price');
        }}
      />
      <Modal
        open={visible === 'average_sales_price'}
        title={t('agriculture.form.average_sales_price')}
        centered
        onCancel={() => setVisible(false)}
        footer={
          <Button key="back" onClick={() => setVisible(false)}>
            Ok
          </Button>
        }
      >
        {t('agriculture.form.average_sales_price_info')}
      </Modal>
    </>
  );

  const desired_guarantee_options_Modal = (option) => (
    <Modal
      open={visible === option.value}
      title={option.label}
      centered
      onCancel={() => setVisible(false)}
      footer={
        <Button key="back" onClick={() => setVisible(false)}>
          Ok
        </Button>
      }
    >
      <div style={{ display: 'flex' }}>
        {multirisqueInfo.map((item) => (
          <div style={{ flex: 1 }}>
            <ul>
              {item.map((subItem) => (
                <li key={subItem}>
                  {t(
                    `agriculture.form.desired_guarantee_options.multirisque_modal.${subItem}`
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </Modal>
  );

  return {
    cultureTypeOptions,
    multirisqueInfo,
    desired_guarantee_options,
    existing_guarantee_options,
    salesLabel,
    desired_guarantee_options_Modal
  };
};
