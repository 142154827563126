import { useCallback } from 'react';
import { useErrorMessage } from './errorMessage';
import { useAuthContext } from '../contexts/AuthContext';

/**
 * Custom hook to fetch a program.
 * @hook
 * @param {Object} options - The options for fetching the program.
 * @param {Function} options.setIsLoading - The function to set the loading state.
 * @param {Function} options.setProgram - The function to set the program data.
 * @param {string} options.programId - The ID of the program to fetch.
 * @param {string} options.route - The route to use for fetching the program.
 * @returns {Object} - The fetchProgram function.
 */
export const useGetProgram = ({
  setIsLoading,
  setProgram,
  programId,
  route
}) => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();

  const fetchProgram = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${route}/${programId}?populate=customer_manager,unit_manager,contract,entity,site,company`
      });
      setProgram(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [programId]);

  return { fetchProgram };
};
