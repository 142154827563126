import { Checkbox } from 'antd';

/**
 * A custom hook that returns predefined field configurations.
 *
 * This configuration can be used in conjunction with form libraries or frameworks
 * that need a set of field definitions to render and validate form controls.
 *
 * @hook
 * @returns {Array<Object>} returns.golfFields - Field configurations for golfs.
 */

export const useHoleInOneFields = () => {
  const fieldNames = [
    'company_name',
    'contract_number',
    'date_added',
    'entity',
    'site',
    'golf_name',
    'golf_address',
    'postal_code',
    'city',
    'event_start_date',
    'event_end_date',
    'event_name',
    'number_of_men_players',
    'number_of_women_players',
    'official_length_for_men_hole_in_one',
    'official_length_for_women_hole_in_one',
    'prize_description',
    'prize_value'
  ];

  const holeInOneFields = fieldNames.map((name) => ({
    name: [name],
    rules: [{ required: false }],
    valuePropName: 'checked',
    input: <Checkbox />
  }));

  return { holeInOneFields };
};
