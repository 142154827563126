import { useCallback, useState, useContext } from 'react';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { CallForContributionContext } from '../CallForContributionContext';

/**
 * `useHandleCallForContribution` is a custom React hook that handles the call for contribution of a selected item.
 * @hook
 * @param {Array<string>} callForContribution - The item to be called for contribution.
 * @param {function} setForceRefresh - The function to set the force refresh state.
 * @param {boolean} forceRefresh - The force refresh state.
 * @returns {object} Returns an object with the loading state and the function to handle the call for contribution.
 */
export const useHandleCallForContribution = ({
  callForContribution,
  setSelectedRowKeysIssuedCall,
  setSelectedRowKeysPaidItem
}) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoadingSending, setIsLoadingSending] = useState(false);
  const { forceRefresh, setForceRefresh } = useContext(
    CallForContributionContext
  );

  const handleCallForContribution = useCallback(async () => {
    setIsLoadingSending(true);
    try {
      await dispatchAPI('PATCH', {
        url: `/call-for-contributions/send-mails/${callForContribution}`
      });
    } catch (e) {
      message(e);
    } finally {
      setIsLoadingSending(false);
      setSelectedRowKeysIssuedCall([]);
      setSelectedRowKeysPaidItem([]);
      setForceRefresh(!forceRefresh);
    }
  }, [dispatchAPI, callForContribution, message]);

  return { isLoadingSending, handleCallForContribution };
};
