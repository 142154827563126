import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Radio } from 'antd';

const { Group } = Radio;
const { Item } = Form;

/**
 * A custom radio group component for choosing between "YES" and "NO".
 * @component
 * @param {object} props - The component's props.
 * @param {string} props.dbKey - The key used to fetch the list of enums.
 * @param {string} props.tradKeyEnums - The key used to fetch the translations of the enums.
 * @param {function} props.setState - The function to set the state of the parent component.
 * @returns {JSX.Element} The JSX element representing the radio group component.
 */

export const RadioGroupYesNoReducer = ({
  dbKey,
  tradKeyEnums,
  dispatch,
  field,
  type
}) => {
  const { t } = useTranslation();

  const handleChange = (e) => {
    const condition = e.target.value === 'YES';

    dispatch({
      type,
      field,
      payload: condition
    });
  };

  return (
    <Item name={dbKey}>
      <Group onChange={handleChange}>
        <Radio value="YES">{t(`${tradKeyEnums}.YES`)}</Radio>
        <Radio value="NO">{t(`${tradKeyEnums}.NO`)}</Radio>
      </Group>
    </Item>
  );
};

RadioGroupYesNoReducer.propTypes = {
  dbKey: PropTypes.arrayOf(PropTypes.string).isRequired,
  tradKeyEnums: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};
