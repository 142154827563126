import { useEffect } from 'react';
import { Tag } from 'antd';
import { FileExclamationOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { claimStatus } from '../../../../utils/constants/tagColors';
import { useFiltersDataFetch } from '../../../../components/ColumnsDynamicFilters/useFiltersDatasFetch';

/**
 * `useColumns` is a custom React hook that provides a list of column configurations.
 * The column titles are fetched using the `react-i18next` hook for internationalization.
 * @hook
 * @returns {Array<object>} Returns an array of column configurations to be consumed by a table component.
 */

export const useColumns = (resourceName, dbExtraQueryFilter, populate) => {
  const { t } = useTranslation();
  const { fetchFilters, filters, loading } = useFiltersDataFetch(
    resourceName,
    dbExtraQueryFilter,
    resourceName,
    populate
  );
  useEffect(() => {
    fetchFilters();
  }, []);

  const columns = [
    {
      key: 'documents',
      width: '48px',
      dataIndex: 'missing_documents',
      render: (missing_documents) =>
        missing_documents ? (
          <FileExclamationOutlined
            style={{ fontSize: 18, color: 'var(--warningColor)' }}
          />
        ) : null
    },
    {
      title: t('claims.form.entity'),
      key: 'entity',
      dataIndex: ['entity'],
      filters: filters?.entity?.map((entity) => ({
        text: entity.name,
        value: entity._id
      })),
      sorter: true,
      render: (entity) => entity && entity.map((e) => e.name).join(', ')
    },
    {
      title: t('claims.form.sinister_date'),
      key: 'sinister_date',
      dataIndex: ['sinister_date'],
      filters: filters?.sinister_date?.map((date) => ({
        text: moment(date).format('DD/MM/YYYY'),
        value: date
      })),
      render: (occurence_date) =>
        occurence_date ? moment(occurence_date).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('claims.form.claim_type'),
      key: 'claim_type',
      dataIndex: 'claim_type',
      sorter: true,
      filters: filters?.claim_type?.map((type) => ({
        text: t(`claims.form.${type}`),
        value: type
      })),
      render: (type) => t(`claims.form.${type}`)
    },
    {
      title: t('claims.form.insurance_company_reference'),
      key: 'insurance_company_reference',
      dataIndex: 'insurance_company_reference',
      sorter: true,
      filters: filters?.insurance_company_reference?.map((ref) => ({
        text: ref,
        value: ref
      }))
    },
    {
      title: t('claims.form.client_reference'),
      key: 'client_reference',
      dataIndex: 'client_reference',
      sorter: true,
      filters: filters?.client_reference?.map((ref) => ({
        text: ref,
        value: ref
      }))
    },
    {
      title: t('claims.form.opening_date'),
      key: 'opening_date',
      dataIndex: ['opening_date'],
      render: (opening_date) =>
        opening_date ? moment(opening_date).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('claims.form.construction_sub_programmes'),
      key: 'construction_sub_programmes',
      dataIndex: ['construction_sub_programmes'],
      render: (construction_sub_programmes) =>
        construction_sub_programmes
          ? t(`claims.form.${construction_sub_programmes}`)
          : '-',
      sorter: true
    },
    {
      title: t('claims.form.lifted_reservations'),
      key: 'lifted_reservations',
      dataIndex: 'lifted_reservations',
      filters: filters?.lifted_reservations?.map((lifted_reservation) => ({
        text: t(`claims.form${lifted_reservation}`),
        value: lifted_reservation
      })),
      sorter: true,
      render: (lifted_reservations) =>
        lifted_reservations ? t(`claims.form.${lifted_reservations}`) : '-'
    },
    {
      title: t('claims.form.claim_status'),
      key: 'claim_status',
      dataIndex: 'claim_status',
      filters: filters?.claim_status?.map((status) => ({
        text: t(`claims.form.${status}`),
        value: status
      })),
      render: (status) =>
        status ? (
          <Tag color={claimStatus[status]}>
            {t(`claims.form.${status}`, {
              defaultValue: status
            })}
          </Tag>
        ) : (
          <Tag color="orange">{t('claims.form.reception_pending')}</Tag>
        ),
      sorter: true
    }
  ];

  return [columns, loading];
};
