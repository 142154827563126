import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Card, Form, Button, Flex } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useSocketContext } from '../../contexts/SocketContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useConversationContext } from '../../contexts/ConversationContext';
import { title } from './Conversations/utils';

const { TextArea } = Input;

/**
 * Custom component for writing and sending a message or a note
 *
 * @component
 * @returns {JSX.Element} - Custom component for writing and seding a message or a note
 */
export const MessageWriter = () => {
  const { t } = useTranslation();
  const { user, dispatchAPI, company } = useAuthContext();
  const { socket } = useSocketContext();
  const {
    selectedConversation,
    refreshConversation,
    setRefreshConversation,
    setIsThreadOpen,
    filter
  } = useConversationContext();
  const { message } = useErrorMessage();
  const url = '/conversations';
  const type = 'message';
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      const { _id: from } = user;
      const to = selectedConversation?.users?.find(
        (item) => item?._id !== from
      )?._id;
      const body = {
        ...values,
        from,
        company,
        ...(to && { to })
      };
      setLoading(true);
      await dispatchAPI('POST', { url, body });
      if (type === 'message') {
        if (socket) socket.emit('newMessage', { to });
        setRefreshConversation(!refreshConversation);
        setIsThreadOpen(false);
      }
      form.resetFields();
    } catch (error) {
      message(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      title={title(filter, selectedConversation, user, t)}
      bordered={false}
      className="conversation-new-message "
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item name="content" rules={[{ required: true }]}>
          <TextArea
            className="conversation-textarea"
            placeholder={t('messages.placeholder.write_a_message')}
            autoSize={{
              minRows: 3,
              maxRows: 15
            }}
          />
        </Form.Item>
        <Flex justify="flex-end">
          <Button loading={loading} type="primary" htmlType="submit">
            {t('buttons.send')}
          </Button>
        </Flex>
      </Form>
    </Card>
  );
};
