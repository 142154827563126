import { Form } from 'antd';
import { useReducer } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFields } from './utils/fieldsIndividualHousesBuilder';
import { useConfig } from './utils/config';
import { CreateUpdateContainer } from '../../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewerWithoutForm';

/**
 * A component for creating or updating Individual Houses Construction subscriptions.
 * @component
 * @param {object} props - The component's props.
 * @param {string} props.purpose - The purpose of the component (e.g., 'create' or 'update').
 * @returns {JSX.Element} The JSX element representing the create/update component.
 */
export const IndividualHousesBuilderCreateUpdate = ({ purpose }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const initialState = {
    signatureId: '',
    isAttic: false,
    isAncillaryConstruction: false,
    isCarryOutWorks: false,
    isRenewableEnergiesWorks: false,
    isPhotovoltaicPanels: false,
    isGeotechnicalStudy: false
  };

  const formStateReducer = (state, action) => {
    switch (action.type) {
      case 'SET_SIGNATURE_ID':
        return { ...state, signatureId: action.payload };
      case 'TOGGLE_STATE':
        return { ...state, [action.field]: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(formStateReducer, initialState);
  const { fieldsInformations } = useFields(form, state, dispatch);

  const config = useConfig(dispatch);

  const title = 'individual_houses_builders';
  return (
    <CreateUpdateContainer
      title={t(`${title}.form.edit`)}
      form={form}
      fields={fieldsInformations}
      purpose={purpose}
      baseUrl="individual-houses-builders"
      tradKey="individual_houses_builders"
      resource={title}
      populate="?populate=documents.file"
      config={config}
      messageOnSuccess
      mandatoryDocuments={[]}
    />
  );
};

IndividualHousesBuilderCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
