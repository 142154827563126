import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-components';
import { HomeOutlined } from '@ant-design/icons';
import { routes } from '../../utils/constants/adminRoutes';

export const PageHeaderCustom = ({
  title,
  extra,
  noDynamiqueRoute,
  noReturnButton
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitPathname = pathname.split('/').filter((p) => p !== '');
  const firstTwoPathParts = splitPathname.slice(0, 2);

  if (firstTwoPathParts.length > 0) {
    firstTwoPathParts[0] = firstTwoPathParts[0].toUpperCase();
  }

  let dynamicRoutes = [];

  if (
    firstTwoPathParts[0] === 'CLAIMS' ||
    firstTwoPathParts[0] === 'PROGRAMMES'
  ) {
    dynamicRoutes = [
      {
        path: routes.HOME,
        breadcrumbName: <HomeOutlined />
      },
      splitPathname &&
        splitPathname.length > 2 && {
          path: `${routes[firstTwoPathParts[0]]}/${firstTwoPathParts[1]}`,
          breadcrumbName: t(`breadcrumbs.back_to_view_list`)
        }
    ];
  } else {
    dynamicRoutes = [
      {
        path: routes.HOME,
        breadcrumbName: <HomeOutlined />
      },
      ...(splitPathname && splitPathname.length > 1
        ? [
            {
              path: routes[firstTwoPathParts[0]],
              breadcrumbName: t(`breadcrumbs.back_to_view_list`)
            }
          ]
        : [])
    ];
  }

  if (noDynamiqueRoute) {
    dynamicRoutes = [];
  }

  const onBack = () => {
    navigate(-1);
  };

  const itemRender = (
    // eslint-disable-next-line react/prop-types
    { path, breadcrumbName, state, search, ...route },
    params,
    routesList
  ) => {
    const last = routesList.indexOf(route) === routesList.length - 1;
    return last ? (
      <span key={path}>{breadcrumbName}</span>
    ) : (
      <Link key={path} to={{ pathname: path, state, search }}>
        {breadcrumbName}
      </Link>
    );
  };

  const paths = ['edit', 'show', 'create', '/agriculture'];

  if (paths.some((path) => pathname.includes(path))) {
    return (
      <PageHeader
        breadcrumb={{ items: dynamicRoutes, itemRender, separator: '>' }}
        title={title}
        ghost={false}
        extra={extra}
        onBack={!noReturnButton ? () => onBack() : null}
      />
    );
  }
  return (
    <PageHeader
      breadcrumb={{ items: dynamicRoutes, itemRender, separator: '>' }}
      title={title}
      ghost={false}
      extra={extra}
    />
  );
};

PageHeaderCustom.propTypes = {
  title: PropTypes.string.isRequired,
  extra: PropTypes.element,
  noDynamiqueRoute: PropTypes.bool,
  noReturnButton: PropTypes.bool
};

PageHeaderCustom.defaultProps = {
  extra: null,
  noDynamiqueRoute: false,
  noReturnButton: false
};
