import React, { useState, createContext } from 'react';
import { Col, Modal, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

export const MachineryBreakageContext = createContext();

/**
 * MachineryBreakageProvider component.
 * Provides the MachineryBreakage context to its children.
 * @component
 * @param {object} children - The children components.
 * @returns {JSX.Element} MachineryBreakageProvider
 */

export const MachineryBreakageProvider = ({ children }) => {
  const [currentContract, setCurrentContract] = useState();
  const [currentEntityContext, setCurrentEntityContext] = useState();
  const [entitiesContext, setEntitiesContext] = useState([]);

  const { t } = useTranslation();
  const [forceRefresh, setForceRefresh] = useState(false);

  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const generateAttestation = async (id) => {
    const modal = Modal.info({
      content: (
        <Row
          style={{ flexDirection: 'column' }}
          justify="center"
          align="middle"
        >
          <Col>
            <Spin />
          </Col>
          <Col>{t('files.modal.document-loading')}</Col>
        </Row>
      ),
      footer: null,
      maskClosable: false
    });
    try {
      const { data, headers } = await dispatchAPI('GET', {
        url: `machinery-breakages/attestation/${id}`,
        responseType: 'arraybuffer'
      });
      const blob = new Blob([data], {
        type: headers['content-type']
      });
      modal.destroy();
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = headers['content-disposition'];
      document.body.appendChild(a);

      a.click();

      window.URL.revokeObjectURL(url);
    } catch (e) {
      modal.destroy();
      message(e);
    }
  };

  return (
    <MachineryBreakageContext.Provider
      value={{
        forceRefresh,
        setForceRefresh,
        generateAttestation,
        currentEntityContext,
        setCurrentEntityContext,
        entitiesContext,
        setEntitiesContext,
        currentContract,
        setCurrentContract
      }}
    >
      {children}
    </MachineryBreakageContext.Provider>
  );
};
