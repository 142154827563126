import PropTypes from 'prop-types';
// import dayjs from 'dayjs';
import { Typography, Flex } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import { Building } from '@carbon/icons-react';
import { refConfig } from './refConfigBasicContent';

/**
 * Renders a component to display basic notification content.
 * @component
 * @param {Object} props - The props object.
 * @param {string} props.title - The title of the notification.
 * @param {Object} props.item - The item object containing information about the notification.
 * @param {string} props.item.ref - The reference of the notification.
 * @param {string} props.item.type - The type of the notification.
 * @param {Array} props.programme - An array of programme objects containing notification details.
 * @returns {JSX.Element} JSX element representing the basic notification content.
 */

export const CreateNotifContentBasic = ({ title, item, programme }) => {
  const matchingProgramme = programme?.find(
    (match) => match._id === item?.type
  );

  const {
    icons: [PrimaryIcon, SecondaryIcon],
    getInfo
  } = refConfig[item?.ref] || {
    icons: [Building, Building],
    getInfo: () => ['', '']
  };

  const [infoPrimary, infoSecondary] = getInfo(matchingProgramme);

  return (
    <Flex justify="space-between" align="center" wrap="wrap" gap="small">
      <Flex align="center" gap="small">
        <FileTextOutlined />
        <Typography.Text strong style={{ marginRight: 4 }}>
          {title}
        </Typography.Text>
      </Flex>
      <Flex align="center" gap="small">
        <PrimaryIcon />
        <Typography.Text>{infoPrimary}</Typography.Text>
        <SecondaryIcon />
        <Typography.Text>{infoSecondary}</Typography.Text>
      </Flex>
    </Flex>
  );
};

CreateNotifContentBasic.propTypes = {
  title: PropTypes.string.isRequired,
  item: PropTypes.shape({
    ref: PropTypes.PropTypes.string.isRequired,
    type: PropTypes.string
  }).isRequired,
  programme: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
