import { Route, Routes } from 'react-router-dom';
import { ShowOtherTransport } from '../ShowOtherTransport';
import { ListOtherTransport } from '../ListOtherTransport';
import { OtherTransportCreateUpdate } from '../OtherTransportCreateUpdate';
import { Exception } from '../../../../components';

/**
 * Router for the FreightedGoods sub-programme.
 * @component
 * @returns {JSX.Element}
 */

export const FreightedGoodsRouter = () => (
  <Routes>
    <Route
      path="/show/:id"
      element={<ShowOtherTransport subProgramme="freighted-goods" />}
    />
    <Route
      path="/edit/:id"
      element={
        <OtherTransportCreateUpdate
          purpose="edit"
          subProgramme="FREIGHTED_GOODS"
        />
      }
    />
    <Route
      index
      element={<ListOtherTransport subProgramme="FREIGHTED_GOODS" />}
    />
    <Route path="*" element={<Exception />} />
  </Routes>
);
