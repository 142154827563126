import React, { useState, createContext } from 'react';

export const CallForContributionContext = createContext();

/**
 * HoleInOneProvider component.
 * Provides the HoleInOne context to its children.
 *
 * @component
 * @param {object} children - The children components.
 * @returns {JSX.Element} HoleInOneProvider
 */

export const CallForContributionProvider = ({ children }) => {
  const [forceRefresh, setForceRefresh] = useState(false);

  return (
    <CallForContributionContext.Provider
      value={{
        forceRefresh,
        setForceRefresh
      }}
    >
      {children}
    </CallForContributionContext.Provider>
  );
};
