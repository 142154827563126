import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { DriverDrawer } from '../components/DriverDrawer';
import useFields from './utils/fieldsLightVehicles';
import { LightVehiclesContext } from './LightVehiclesContext';
import { useConfig } from '../../singleVehiclesConfig';

/**
 * The `LightVehiclesCreateUpdate` component handles the creation and updating processes for light vehicles.
 * Depending on the `purpose` prop, the component either populates the form with the existing vehicle data for updates or prepares the form for new entries.
 * This component also manages a drawer for adding drivers to the vehicle.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.purpose - Indicates the purpose of the component. Either 'create' for a new vehicle entry or 'edit' for updating an existing one.
 *
 * @returns {JSX.Element} Rendered component.
 */

export const LightVehiclesCreateUpdate = ({ purpose }) => {
  const [form] = Form.useForm();
  const { companyName, user } = useAuthContext();
  const { setCurrentEntityContext, setCurrentContract } =
    useContext(LightVehiclesContext);

  const resource = 'vehicles';
  const fleet_type = 'LIGHT_VEHICLE';
  const draggerFilesKeysMandatory = [];
  const [isOpen, setIsOpen] = useState(false);
  const isClientCondition =
    user?.role === 'users:USER' || user?.role === 'users:SUPER-USER';
  const [currentVehicleData, setCurrentVehicleData] = useState({
    initial_traffic_release: null,
    principal_driver: null,
    secondary_drivers: []
  });
  const { config } = useConfig({
    currentVehicleData,
    setCurrentVehicleData,
    fleet_type,
    setCurrentContract
  });
  const { fieldsLightVehicles, isFieldsLoading } = useFields({
    onAddDriver: () => setIsOpen(true),
    currentVehicleData,
    setCurrentVehicleData,
    form
  });
  const { t } = useTranslation();

  return (
    <>
      <CreateUpdateContainer
        setCurrentEntityContext={setCurrentEntityContext}
        parentForm={form}
        fields={fieldsLightVehicles}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        draggerFilesKeysMandatory={draggerFilesKeysMandatory}
        withFilesManager={!isClientCondition}
        mandatoryDocuments={draggerFilesKeysMandatory}
        baseUrl="vehicles"
        resource={resource}
        config={config}
        extraTitle={
          <Row justify="center" className="form-company-name">{`${t(
            'folder'
          )} ${companyName}`}</Row>
        }
        populate="?populate=documents.file"
      />
      <DriverDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

LightVehiclesCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
