// TODO: awaiting customer feedback before commented code can be removed
import { useCallback } from 'react';
import dayjs from 'dayjs';
// import { toBoolean } from '../../components/utils/stateFormat';

/**
 * Custom hook that returns the configuration objects for getting and creating resources.
 *
 * @param {function} dispatch - The dispatch function from the Redux store.
 * @param {string} company - The company ID.
 * @param {string} program - The program type.
 * @param {string} subProgram - The sub-program type.
 * @returns {object} - The configuration objects for getting and creating resources.
 */

export const useConfig = (company, program) => {
  // export const useConfig = (dispatch, company, program) => {
  const onGetResource = {
    setFields: useCallback(
      (data) => {
        const proMultiriskInsurance = data?.professional_multirisk_insurance;
        // dispatch({
        //   type: 'TOGGLE_STATE',
        //   field: 'isProtectedAgainstTheft',
        //   payload: toBoolean(proMultiriskInsurance?.is_protected_against_theft)
        // });
        // dispatch({
        //   type: 'TOGGLE_STATE',
        //   field: 'isFireProtection',
        //   payload: toBoolean(proMultiriskInsurance?.is_fire_protection)
        // });
        // dispatch({
        //   type: 'TOGGLE_STATE',
        //   field: 'isOtherTypologyActivities',
        //   payload: toBoolean(proMultiriskInsurance?.typology_of_activities)
        // });

        return {
          ...data,
          professional_multirisk_insurance: {
            ...proMultiriskInsurance,
            entry_date:
              proMultiriskInsurance.entry_date &&
              dayjs(proMultiriskInsurance.entry_date)
          }
        };
      },
      []
      // [dispatch]
    )
  };
  const onCreateResource = {
    setBody: useCallback(
      (data) => ({
        ...data,
        company_id: company,
        programme_types: program
      }),
      [company, program]
    )
  };
  return {
    onGetResource,
    onCreateResource
  };
};
