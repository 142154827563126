import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { Modal, Select, Button, Form, Input, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useSocketContext } from '../../../contexts/SocketContext';
import { useConversationContext } from '../../../contexts/ConversationContext';
import { useErrorMessage } from '../../../utils/errorMessage';

const { Option } = Select;
const { TextArea } = Input;

/**
 * @component
 * @name NewMessageModal
 * @description A component that displays a modal to start a new conversation
 * @param {boolean} open The state of the modal
 * @param {function} onCancel The function to close the modal
 * @returns {jsx} jsx - NewMessageModal component
 */
export const NewMessageModal = ({ open, onCancel }) => {
  const { t } = useTranslation();
  const { dispatchAPI, company, user } = useAuthContext();
  const { _id } = user;
  const { socket } = useSocketContext();
  const { setRefreshConversation, refreshConversation } =
    useConversationContext();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  const getUsers = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `/users?company_Id=${company}`
      });
      setUsers(data);
    } catch (error) {
      message(error);
    } finally {
      setLoading(false);
    }
  }, [company, dispatchAPI, message]);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const body = {
        ...values,
        from: _id,
        category: 'PERSONAL',
        company
      };

      await dispatchAPI('POST', {
        url: '/conversations',
        body
      });

      if (socket) socket.emit('newMessage', { to: body.to });
      setRefreshConversation(!refreshConversation);
      form.resetFields();
      onCancel();
    } catch (error) {
      message(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, [company, getUsers]);

  const usersOptions = users.map((u) => (
    <Option key={u._id} value={u._id}>
      {`${u?.first_name} ${u?.last_name}`}
    </Option>
  ));

  return (
    <Modal
      title={t('messages.titles.start_new_conversation')}
      width={640}
      open={open}
      onCancel={onCancel}
      footer={null}
    >
      <Flex vertical gap="middle">
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            name="to"
            label={t('messages.form.select_recipient')}
            rules={[
              {
                required: true,
                message: t('messages.errors.missing_recipient')
              }
            ]}
          >
            <Select
              showSearch
              loading={loading}
              placeholder={t('messages.form.research')}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {usersOptions}
            </Select>
          </Form.Item>
          <Form.Item
            name="content"
            rules={[
              {
                required: true,
                message: t('messages.errors.missing_content')
              }
            ]}
          >
            <TextArea style={{ resize: 'none' }} rows={8} />
          </Form.Item>
          <Flex justify="flex-end" gap="small">
            <Button
              onClick={() => {
                onCancel();
                form.resetFields();
              }}
            >
              {t('buttons.cancel')}
            </Button>
            <Button type="primary" htmlType="submit">
              {t('buttons.send')}
            </Button>
          </Flex>
        </Form>
      </Flex>
    </Modal>
  );
};

NewMessageModal.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func
};

NewMessageModal.defaultProps = {
  open: false,
  onCancel: () => {}
};
