import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { useColumns } from '../columns';
import { useActionColumn } from '../../actionColumn';
import { ListResource } from '../../../../components/ListResource/ListResource';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { FilterByMissingDocuments } from '../../../components/FilterByMissingDocuments';

/**
 * Displays a list of light-vehicles claims with relevant columns and additional filters.
 *
 * @component
 * @returns {React.ReactNode} List of light-vehicles claims.
 */
export const ListLightVehicles = () => {
  const [isMissingDocuments, setIsMissingDocuments] = useState('');
  const [resetFilters, setResetFilters] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [extraFilter, setExtraFilter] = useState('');
  const [isClosedButton, setIsClosedButton] = useState(false);
  const actionColumnUrl = 'light-vehicles';
  const url = 'vehicle_claims';

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const currentPage = Number(params.get('p') || 1);
  const pageSize = Number(params.get('pS') || 10);

  const { t } = useTranslation();
  const { company } = useAuthContext();

  let filterParameter = '';
  const resourceName = 'vehicle_claims';
  const dbExtraQueryFilter = `company=${company}&fleet_type=LIGHT_VEHICLE&${
    isClosedButton ? 'claim_status=CLOSED' : 'claim_status!=CLOSED'
  }`;
  const populate = 'entity,site,entry_id,company';

  const [columns, loading] = useColumns(
    resourceName,
    dbExtraQueryFilter,
    populate
  );
  const actionColumn = useActionColumn(actionColumnUrl, url);

  const onFilterMissingDocuments = (missingDocuments) => {
    setIsMissingDocuments(missingDocuments === false ? '' : missingDocuments);
  };

  useEffect(() => {
    if (resetFilters) {
      filterParameter = '';
      setResetFilters(false);
    }
    filterParameter = `missing_documents=${isMissingDocuments}__`;
    setExtraFilter(filterParameter);
    navigate({
      pathname: '/claims/light-vehicles',
      search: `?&p=${currentPage}&pS=${pageSize}&f=${filterParameter}`
    });
  }, [navigate, isMissingDocuments, resetFilters]);

  useEffect(() => {
    if (!forceRefresh) {
      setForceRefresh(false);
    }
  }, [forceRefresh]);

  if (loading) return <Spin fullscreen="true" />;

  return (
    <ListResource
      extraQuery={dbExtraQueryFilter}
      exportUrl={`exports/company/${company}/programme-type/vehicle_claim?fleet_type=LIGHT_VEHICLE_claim`}
      importUrl={`imports-claim-vehicule/company/${company}/vehicle-type/light_vehicle_type/fleet-type/LIGHT_VEHICLE`}
      exportTemplateUrl="export-templates/template/vehicle-claim"
      resourceName={resourceName}
      tradKey="claims"
      columns={[...columns, ...actionColumn]}
      withExtraFilters={
        <FilterByMissingDocuments
          onChange={onFilterMissingDocuments}
          setResetFilters={setResetFilters}
        />
      }
      populate={populate}
      editAction={false}
      showAction={false}
      deleteAction={false}
      noCreateButton
      noArchiveFilter
      sheetName={t('sheet.vehicle_claim_light_vehicle')}
      headers={[]}
      setIsClosedButton={setIsClosedButton}
      forceRefresh={forceRefresh}
      setForceRefresh={() => setForceRefresh()}
      extraFilter={extraFilter}
      customActionColumn
      scroll={{ x: 3500 }}
    />
  );
};
