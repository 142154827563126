import { Route, Routes } from 'react-router-dom';
import { ShowCivilLiability } from '../ShowCivilLiability';
import { ListCivilLiabilityPro } from './ListCivilLiabilityPro';
import { CivilLiabilityCreateUpdate } from '../CivilLiabilityCreateUpdate';
import { Exception } from '../../../../components';

/**
 * A router component for handling civil-liability-related routes.
 *
 * @component
 * @returns {JSX.Element} The JSX element representing the CivilLiabilityProRouter.
 */

export const CivilLiabilityProRouter = () => (
  <Routes>
    <Route
      path="edit/:id"
      element={
        <CivilLiabilityCreateUpdate
          purpose="edit"
          subProgramme="CIVIL_LIABILITY_PRO"
        />
      }
    />
    <Route path="show/:id" element={<ShowCivilLiability />} />
    <Route index element={<ListCivilLiabilityPro />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
