import { Route, Routes } from 'react-router-dom';
import { ListHoleInOneClaims } from './ListHoleInOneClaims';
import { HoleInOneClaimsfCreateUpdate } from './HoleInOneClaimsfCreateUpdate';
import { HoleInOneClaimsShow } from './HoleInOneClaimsShow';
import { Exception } from '../../../components';

/**
 * Provides routing for managing golf claims, including create, edit, show, and list views.
 *
 * @component
 * @returns {React.ReactNode} Routes for different views related to golf claims.
 */
export const HoleInOneClaimsRouter = () => (
  <Routes>
    <Route
      path="create/:id"
      element={<HoleInOneClaimsfCreateUpdate purpose="create" />}
    />
    <Route
      path="edit/:id"
      element={<HoleInOneClaimsfCreateUpdate purpose="edit" />}
    />
    <Route path="show/:id" element={<HoleInOneClaimsShow />} />
    <Route index element={<ListHoleInOneClaims />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
