// TODO: Wait for attachment infos
import ReactQuill from 'react-quill';

export const useMailingFields = (editorState, setEditorState) => {
  const mailingFields = [
    {
      name: ['recipient'],
      rules: [{ required: true }]
    },
    {
      name: ['recipient_email_address'],
      rules: [{ required: true }]
    },
    {
      name: ['sender'],
      rules: [{ required: true }]
    },
    {
      name: ['mail_object'],
      rules: [{ required: true }]
    },
    // {
    //   name: ['attachments'],
    //   rules: [{ required: true }]
    // },
    {
      name: ['template_content'],
      rules: [{ required: true }],
      input: (
        <ReactQuill
          style={{ width: '100%', height: '100%' }}
          value={editorState}
          onChange={(value) => setEditorState(value)}
        />
      )
    }
  ];

  return { mailingFields };
};
