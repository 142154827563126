// TODO: Refactor
import { routes } from '../../utils/constants/adminRoutes';
import { NotificationsDetails } from './NotificationsDetails';
import { CreateNotifContentBasic } from './utils/CreateNotifContentBasic';
import { CreateNotifContentVehicle } from './utils/CreateNotifContentVehicle';
import { CreateNotifTitleAmendmentSubscription } from './utils/CreateNotifTitleAmendmentSubscription';
import { CreateNotifSubscribedProgramme } from './utils/CreateNotifSubscribedProgramme';
import { CreateNotifContentClaim } from './utils/CreateNotifContentClaim';

export const handleNotifMenuItemLink = (
  notifID,
  item,
  deleteNotification,
  t,
  updatedAt,
  consulted,
  vehicule,
  golves,
  transports,
  property,
  machineryBreakage,
  holeInOne,
  eventCancellation,
  callForContribution
) => {
  const action = item?.action;
  const ref = item?.ref;
  const basicActions = ['create', 'in', 'out', 'patch'];

  const programmesValidActions = [
    'create',
    'in',
    'out',
    'patch',
    'download',
    'download_extract',
    'recall25'
  ];
  const claimValidActions = ['create', 'patch', 'download', 'download_extract'];

  const showOrListPathName = (route, programme) => {
    if (item.action === 'download_extract') {
      return `${route}/${programme}/`;
    }
    return `${route}/${programme}/show/${item?.type}`;
  };

  const displayNotificationLink = () => {
    switch (true) {
      case ref === 'generateGreenCard' && action === 'Green Card Generated':
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentVehicle
                title={t('notifications.generate_green_card')}
                item={item}
                programme={vehicule}
              />
            }
            pathname={`${routes.PROGRAMMES}`}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Call-for-contribution' &&
        programmesValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentBasic
                title={t(`notifications.${action}_call_for_contribution`)}
                item={item}
                programme={callForContribution}
              />
            }
            pathname={showOrListPathName(
              routes.PROGRAMMES,
              'Call-for-contributions'
            )}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Environment-insurance' &&
        programmesValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifSubscribedProgramme
                title={t(`notifications.${action}_environment_insurance`)}
              />
            }
            pathname={`${routes.PROGRAMMES}/environment-insurances/show/${item?.type}`}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Assembly-testing-insurance' &&
        programmesValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifSubscribedProgramme
                title={t(`notifications.${action}_assembly_testing_insurance`)}
              />
            }
            pathname={showOrListPathName(
              routes.PROGRAMMES,
              'Assembly-testing-insurances'
            )}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Professional-multirisk-insurance' &&
        programmesValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifSubscribedProgramme
                title={t(
                  `notifications.${action}_professional_multirisk_insurance`
                )}
              />
            }
            pathname={showOrListPathName(
              routes.PROGRAMMES,
              'Professional-multirisk-insurances'
            )}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Hardware-insurance' &&
        programmesValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifSubscribedProgramme
                title={t(`notifications.${action}_hardware_insurance`)}
              />
            }
            pathname={showOrListPathName(
              routes.PROGRAMMES,
              'hardware-insurances'
            )}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Cyber-insurance' &&
        (action === 'create' ||
          action === 'in' ||
          action === 'out' ||
          action === 'patch'):
        return (
          <NotificationsDetails
            content={
              <CreateNotifSubscribedProgramme
                title={t(`notifications.${action}_cyber_insurance`)}
              />
            }
            pathname={showOrListPathName(routes.PROGRAMMES, 'cyber-insurances')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Legal-protection-insurance' &&
        (action === 'create' ||
          action === 'in' ||
          action === 'out' ||
          action === 'patch'):
        return (
          <NotificationsDetails
            content={
              <CreateNotifSubscribedProgramme
                title={t(`notifications.${action}_legal_protection_insurance`)}
              />
            }
            pathname={showOrListPathName(
              routes.PROGRAMMES,
              'legal-protection-insurances'
            )}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'individual-houses-builder' &&
        (action === 'create' ||
          action === 'in' ||
          action === 'out' ||
          action === 'patch'):
        return (
          <NotificationsDetails
            content={
              <CreateNotifSubscribedProgramme
                title={t(`notifications.${action}_individual_houses_builder`)}
              />
            }
            pathname={showOrListPathName(
              routes.PROGRAMMES,
              'individual-houses-builders'
            )}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Event-cancellation' &&
        programmesValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentBasic
                title={t(`notifications.${action}_event_cancellation`)}
                item={item}
                programme={eventCancellation}
              />
            }
            pathname={showOrListPathName(
              routes.PROGRAMMES,
              'event-cancellations'
            )}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Machinery-breakage' &&
        programmesValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentBasic
                title={t(`notifications.${action}_machinery_breakage`)}
                item={item}
                programme={machineryBreakage}
              />
            }
            pathname={showOrListPathName(
              routes.PROGRAMMES,
              'machinery-breakages'
            )}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Hole-in-one' && programmesValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentBasic
                title={t(`notifications.${action}_hole_in_one`)}
                item={item}
                programme={holeInOne}
              />
            }
            pathname={showOrListPathName(routes.PROGRAMMES, 'Hole-in-ones')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'LIGHT_VEHICLE' && programmesValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentVehicle
                title={t(`notifications.${action}_light_vehicule`)}
                item={item}
                programme={vehicule}
              />
            }
            pathname={showOrListPathName(routes.PROGRAMMES, 'light-vehicles')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'OTHER_VEHICLE' && programmesValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentVehicle
                title={t(`notifications.${action}_other_vehicule`)}
                item={item}
                programme={vehicule}
              />
            }
            pathname={showOrListPathName(routes.PROGRAMMES, 'other-vehicles')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'HEAVY_VEHICLE' && programmesValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentVehicle
                title={t(`notifications.${action}_heavy_vehicule`)}
                item={item}
                programme={vehicule}
              />
            }
            pathname={showOrListPathName(routes.PROGRAMMES, 'heavy-vehicles')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'AUTOMISSION' && programmesValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentVehicle
                title={t(`notifications.${action}_automission`)}
                item={item}
                programme={vehicule}
              />
            }
            pathname={showOrListPathName(routes.PROGRAMMES, 'automissions')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Golf' && programmesValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentBasic
                title={t(`notifications.${action}_golf`)}
                item={item}
                programme={golves}
              />
            }
            pathname={showOrListPathName(routes.PROGRAMMES, 'golf')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Property' && programmesValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentBasic
                title={t(`notifications.${action}_property`)}
                item={item}
                programme={property}
              />
            }
            pathname={showOrListPathName(routes.PROGRAMMES, 'properties')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'AD_VALOREM' && programmesValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentBasic
                title={t(`notifications.${action}_ad_valorem`)}
                item={item}
                programme={transports}
              />
            }
            pathname={showOrListPathName(routes.PROGRAMMES, 'ad-valorem')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'CIVIL_LIABILITY_PRO' && basicActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifSubscribedProgramme
                title={t(`notifications.${action}_civil_liability_pro`)}
              />
            }
            pathname={showOrListPathName(
              routes.PROGRAMMES,
              'civil-liability-pro'
            )}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'CIVIL_LIABILITY_EXECUTIVE' && basicActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifSubscribedProgramme
                title={t(`notifications.${action}_civil_liability_executive`)}
              />
            }
            pathname={showOrListPathName(
              routes.PROGRAMMES,
              'civil-liability-executive'
            )}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'FACULTY' && basicActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifSubscribedProgramme
                title={t(`notifications.${action}_faculty`)}
              />
            }
            pathname={showOrListPathName(routes.PROGRAMMES, 'faculties')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'OTHER' && basicActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifSubscribedProgramme
                title={t(`notifications.${action}_other`)}
              />
            }
            pathname={showOrListPathName(
              routes.PROGRAMMES,
              'other-construction'
            )}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'FREIGHTED_GOODS' && basicActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifSubscribedProgramme
                title={t(`notifications.${action}_freightened_goods`)}
              />
            }
            pathname={showOrListPathName(routes.PROGRAMMES, 'freighted-goods')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'OWN_ACCOUNT' && basicActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifSubscribedProgramme
                title={t(`notifications.${action}_own_accounts`)}
              />
            }
            pathname={showOrListPathName(routes.PROGRAMMES, 'own-accounts')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Construction' && programmesValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifSubscribedProgramme
                title={t(`notifications.${action}_construction`)}
              />
            }
            pathname={showOrListPathName(routes.PROGRAMMES, 'construction')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Construction_claim' && basicActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentClaim
                title={t(`notifications.${action}_construction_claim`)}
                item={item}
                t={t}
              />
            }
            pathname={showOrListPathName(
              routes.CLAIMS,
              'construction-insurance'
            )}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'claim_OTHER_VEHICLE' &&
        programmesValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentClaim
                title={t(`notifications.${action}_other_vehicule_claim`)}
                item={item}
                t={t}
              />
            }
            pathname={showOrListPathName(routes.CLAIMS, 'other-vehicles')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'claim_HEAVY_VEHICLE' &&
        programmesValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentClaim
                title={t(`notifications.${action}_heavy_vehicule_claim`)}
                item={item}
                t={t}
              />
            }
            pathname={showOrListPathName(routes.CLAIMS, 'heavy-vehicles')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'claim_AUTOMISSION' &&
        programmesValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentClaim
                title={t(`notifications.${action}_automission_claim`)}
                item={item}
                t={t}
              />
            }
            pathname={showOrListPathName(routes.CLAIMS, 'automissions')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'claim_LIGHT_VEHICLE' &&
        programmesValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentClaim
                title={t(`notifications.${action}_light_vehicule_claim`)}
                item={item}
                t={t}
              />
            }
            pathname={showOrListPathName(routes.CLAIMS, 'light-vehicles')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Golf_claim' && claimValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentClaim
                title={t(`notifications.${action}_claim_golf`)}
                item={item}
                t={t}
              />
            }
            pathname={showOrListPathName(routes.CLAIMS, 'golfs')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'claim_AD_VALOREM' && claimValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentClaim
                title={t(`notifications.${action}_ad_valorem_claim`)}
                item={item}
                t={t}
              />
            }
            pathname={showOrListPathName(routes.CLAIMS, 'ad-valorem')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Property_claim' && claimValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentClaim
                title={t(`notifications.${action}_property_claim`)}
                item={item}
                t={t}
              />
            }
            pathname={showOrListPathName(routes.CLAIMS, 'properties')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'claim_CIVIL_LIABILITY_PRO' &&
        claimValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentClaim
                title={t(`notifications.${action}_civil_liability_pro_claim`)}
                item={item}
                t={t}
              />
            }
            pathname={showOrListPathName(routes.CLAIMS, 'civil-liability-pro')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'claim_CIVIL_LIABILITY_EXECUTIVE' &&
        claimValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentClaim
                title={t(
                  `notifications.${action}_civil_liability_executive_claim`
                )}
                item={item}
                t={t}
              />
            }
            pathname={showOrListPathName(
              routes.CLAIMS,
              'civil-liability-executive'
            )}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'claim_FACULTY' && claimValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentClaim
                title={t(`notifications.${action}_faculty_claim`)}
                item={item}
                t={t}
              />
            }
            pathname={showOrListPathName(routes.CLAIMS, 'faculties')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'claim_FREIGHTED_GOODS' &&
        claimValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentClaim
                title={t(`notifications.${action}_freightened_goods_claim`)}
                item={item}
                t={t}
              />
            }
            pathname={showOrListPathName(routes.CLAIMS, 'freighted-goods')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'claim_OWN_ACCOUNT' && claimValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentClaim
                title={t(`notifications.${action}_own_accounts_claim`)}
                item={item}
                t={t}
              />
            }
            pathname={showOrListPathName(routes.CLAIMS, 'own-accounts')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Hole_in_one_claim' && claimValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentClaim
                title={t(`notifications.${action}_hole_in_one_claim`)}
                item={item}
                t={t}
              />
            }
            pathname={showOrListPathName(routes.CLAIMS, 'hole-in-ones')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Machinery_breakage_claim' &&
        claimValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentClaim
                title={t(`notifications.${action}_machinery_breakage_claim`)}
                item={item}
                t={t}
              />
            }
            pathname={showOrListPathName(routes.CLAIMS, 'machinery-breakages')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Event_cancellation_claim' &&
        claimValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentClaim
                title={t(`notifications.${action}_event_cancelletion_claim`)}
                item={item}
                t={t}
              />
            }
            pathname={showOrListPathName(routes.CLAIMS, 'event-cancellations')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Cyber_insurance_claim' &&
        claimValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentClaim
                title={t(`notifications.${action}_cyber_insurance_claim`)}
                item={item}
                t={t}
              />
            }
            pathname={showOrListPathName(routes.CLAIMS, 'cyber-insurances')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Hardware_insurance_claim' &&
        claimValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentClaim
                title={t(`notifications.${action}_hardware_insurance_claim`)}
                item={item}
                t={t}
              />
            }
            pathname={showOrListPathName(routes.CLAIMS, 'hardware-insurances')}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Assembly_testing_insurance_claim' &&
        claimValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentClaim
                title={t(
                  `notifications.${action}_assembly_testing_insurance_claim`
                )}
                item={item}
                t={t}
              />
            }
            pathname={showOrListPathName(
              routes.CLAIMS,
              'assembly-testing-insurances'
            )}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Professional_multirisk_insurance_claim' &&
        claimValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentClaim
                title={t(
                  `notifications.${action}_professional_multirisk_insurance_claim`
                )}
                item={item}
                t={t}
              />
            }
            pathname={showOrListPathName(
              routes.CLAIMS,
              'professional-multirisk-insurances'
            )}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Legal_protection_insurance_claim' &&
        claimValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentClaim
                title={t(
                  `notifications.${action}_legal_protection_insurance_claim`
                )}
                item={item}
                t={t}
              />
            }
            pathname={showOrListPathName(
              routes.CLAIMS,
              'legal-protection-insurances'
            )}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Individual_houses_builder_claim' &&
        claimValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifContentClaim
                title={t(
                  `notifications.${action}_individual_houses_builder_claim`
                )}
                item={item}
                t={t}
              />
            }
            pathname={showOrListPathName(
              routes.CLAIMS,
              'individual-houses-builders'
            )}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Amendment-construction' &&
        claimValidActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifTitleAmendmentSubscription
                title={t(`notifications.${action}_amendment_construction`)}
              />
            }
            pathname={`${routes.DOCUMENTS}/show/${item?.type}`}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref &&
        ref.includes('Subscription_') &&
        basicActions.includes(action):
        return (
          <NotificationsDetails
            content={
              <CreateNotifTitleAmendmentSubscription
                title={t(`notifications.${action}_${ref.toLowerCase()}`)}
              />
            }
            pathname={`${routes.SUBSCRIPTIONS}/show/${item?.type}`}
            notifID={notifID}
            updatedAt={updatedAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      default:
        return t('notifications.form.unknown-type');
    }
  };

  return displayNotificationLink();
};
