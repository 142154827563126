import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * Custom hook for handling transport claims related to event cancellation.
 *
 * @param {Function} setIsLoading - Function to set the loading state.
 * @param {Object} eventData - Data related to the event.
 * @param {Function} setEventData - Function to set the event data.
 * @param {Function} initialData - Function to initialize the event data.
 * @param {Function} setToEnclose - Function to set the "to_enclose" data.
 * @param {Function} setCurrent - Function to set the current step.
 * @param {Function} setEvent - Function to set the event data.
 * @returns {Object} - Object containing functions for getting event cancellation claim and event cancellation.
 */
export const useTrasnportClaims = (
  setIsLoading,
  eventData,
  setEventData,
  initialData,
  setToEnclose,
  setCurrent,
  setEvent
) => {
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const getEventCancellationClaim = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/event_cancellation_claims/${id}?populate=entry_id,unit_manager,entity,site,company,customer_manager`
      });

      setEventData({
        ...initialData(data)
      });

      setToEnclose(data?.to_enclose);
      setCurrent(data?.step);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, []);

  const getEventCancellation = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/event-cancellations/${eventData?.ID}?populate=customer_manager,unit_manager,contract,entity,site,company`
      });
      setEvent(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [eventData?.ID]);

  return { getEventCancellationClaim, getEventCancellation };
};
