/**
 * Constants for the professional-multirisk insurance claim module.
 *
 * @typedef {Object} Constants
 * @property {string} productAPI - The API endpoint for professional-multirisk insurances.
 * @property {string} claimUrl - The URL for professional-multirisk insurance claims.
 * @property {string} claimAPI - The API endpoint for professional-multirisk insurance claims.
 * @property {string} resource - The resource name for professional-multirisk insurance claims.
 */

/**
 * Constants for the professional-multirisk insurance claim module.
 *
 * @type {Constants}
 */
export const constants = {
  productAPI: 'professional-multirisk-insurances',
  claimUrl: 'professional-multirisk-insurance-claim',
  claimAPI: 'professional_multirisk_insurance_claims',
  resourceClaim: 'Professional_multirisk_insurance_claim',
  populateProductShow:
    'customer_manager,unit_manager,contract,entity,site,company',
  populateClaimShow: 'entry_id,unit_manager,entity,company,customer_manager'
};
