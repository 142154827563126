import { useState } from 'react';
import { EditOutlined, FileAddOutlined, MailOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button, Flex } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { ClaimButton } from '../../../components/ClaimButton';
import { MailingButton } from '../../../Mailing/MailingButton';
import { routes } from '../../../../utils/constants/adminRoutes';
import usePopConfirm from '../../utils/popConfirms';

/**
 * Custom hook that returns extra buttons for the Construction Insurance page.
 * @hook
 * @param {Object} constructionInsurance - The construction insurance object.
 * @param {Function} setIsModalMailingOpen - Function to set the visibility of the mailing modal.
 * @returns {Object} - The extra buttons component.
 */
export const useExtraButtons = (
  constructionInsurance,
  setIsModalMailingOpen
) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const { exitProgram, unarchiveProgram } = usePopConfirm('constructions');

  const extraButtons = (
    <Flex wrap="wrap" gap="small" justify="end">
      <Link
        to={{
          pathname: `${routes.PROGRAMMES}/construction-insurance/edit/${id}`
        }}
      >
        <Button type="primary">
          {`${t('buttons.edit')} `}
          <EditOutlined />
        </Button>
      </Link>
      <ClaimButton
        id={id}
        url="construction-insurance"
        claimType="construction"
      />
      <MailingButton
        asset={constructionInsurance}
        templateName="Blank"
        tradKey="specific_demand"
        resource="Construction"
        baseUrl="constructions"
      />
      {constructionInsurance?.status !== 'AMENDMENT_REQUEST' && (
        <Link
          to={{
            pathname: `${routes.PROGRAMMES}/construction-insurance/amendment/create/${id}`
          }}
        >
          <Button>
            {`${t('buttons.amendment')} `}
            <FileAddOutlined />
          </Button>
        </Link>
      )}
      {constructionInsurance?.status === 'RUNNING_CONTRACT' && (
        <Button onClick={() => setIsModalMailingOpen(true)}>
          <MailOutlined />
          {`${t('buttons.construction_ending_docs_request')} `}
        </Button>
      )}
      {constructionInsurance &&
        !constructionInsurance.deleted &&
        exitProgram({
          visible,
          setVisible
        })}
      {constructionInsurance &&
        constructionInsurance.deleted &&
        unarchiveProgram()}
    </Flex>
  );

  return { extraButtons };
};
