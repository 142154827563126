import { Route, Routes } from 'react-router-dom';
import { ListOtherTransport } from '../ListOtherTransport';
import { ShowOtherTransport } from '../ShowOtherTransport';
import { OtherTransportCreateUpdate } from '../OtherTransportCreateUpdate';
import { Exception } from '../../../../components';

/**
 * Router for the Faculties sub-programme.
 * @component
 * @returns {JSX.Element}
 */

export const FacultiesRouter = () => (
  <Routes>
    <Route
      path="show/:id"
      element={<ShowOtherTransport subProgramme="faculties" />}
    />
    <Route
      path="edit/:id"
      element={
        <OtherTransportCreateUpdate purpose="edit" subProgramme="FACULTY" />
      }
    />
    <Route index element={<ListOtherTransport subProgramme="FACULTY" />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
