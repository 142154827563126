// TODO: Delete after customer validation
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback, useRef } from 'react';
import dayjs from 'dayjs';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useFiltersDataFetch } from '../../../../components/ColumnsDynamicFilters/useFiltersDatasFetch';
import { renderEntities } from '../../../../utils/formatters/entities';

/**
 * `useColumns` is a custom React hook that provides a list of column configurations.
 *
 * The column titles are fetched using the `react-i18next` hook for internationalization.
 *
 * @hook
 * @returns {Array<object>} Returns an array of column configurations to be consumed by a table component.
 */

export const useColumns = (resourceName, dbExtraQueryFilter, populate) => {
  const { t } = useTranslation();
  const { dispatchAPI, company: companyId } = useAuthContext();
  const { message } = useErrorMessage();
  const [config, setConfig] = useState({});
  const { fetchFilters, filters, loading } = useFiltersDataFetch(
    resourceName,
    dbExtraQueryFilter,
    resourceName,
    populate
  );
  const isMounted = useRef(true);

  // const renderAddress = (text, record) => {
  //   const { number, street, additional } = record.contract || {};
  //   return number || street || additional
  //     ? `${number || ''} ${street || ''} ${additional || ''}`
  //     : '-';
  // };

  const allColumns = [
    {
      title: t('transports.form.contract_number'),
      key: 'contract_number',
      dataIndex: ['contract', 'contract_number'],
      filters: filters?.contract?.map((number) => ({
        text: number.contract_number,
        value: number._id
      })),
      sorter: true
    },
    {
      title: t('transports.form.validity_start_date'),
      key: 'validity_start_date',
      dataIndex: ['contract', 'validity_start_date'],
      render: (validity_start_date) =>
        validity_start_date
          ? dayjs(validity_start_date).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t('transports.form.validity_end_date'),
      key: 'validity_end_date',
      dataIndex: ['contract', 'validity_end_date'],
      render: (validity_end_date) =>
        validity_end_date ? dayjs(validity_end_date).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('transports.form.company_name'),
      key: 'company_name',
      dataIndex: ['company_Id', 'name'],
      sorter: true
    },
    {
      title: t('transports.form.entity'),
      key: 'entity',
      dataIndex: ['entity'],
      filters: filters?.entity?.map((entity) => ({
        text: entity.name,
        value: entity._id
      })),
      sorter: true,
      render: renderEntities
    },
    {
      title: t('transports.form.site'),
      key: 'site',
      dataIndex: ['site', 'name'],
      filters: filters?.site?.map((site) => ({
        text: site.name,
        value: site._id
      })),
      sorter: true
    },
    {
      title: t('transports.form.unit_reference_number'),
      key: 'unit_counter',
      dataIndex: ['unit_counter'],
      filters: filters?.unit_counter?.map((unit_counter) => ({
        text: unit_counter,
        value: unit_counter
      })),
      sorter: true
    },
    {
      title: t('transports.form.shipper'),
      key: 'shipper_name',
      dataIndex: 'shipper_name',
      filters: filters?.shipper_name?.map((shipper) => ({
        text: shipper,
        value: shipper
      })),
      sorter: true
    },
    {
      title: t('transports.form.departure_location'),
      key: 'departure_city',
      dataIndex: '',
      filters: filters?.departure_city?.map((city) => ({
        text: city,
        value: city
      })),
      render: (record) => {
        const { departure_city, departure_country, departure_address } = record;
        return departure_address || `${departure_city}, ${departure_country}`;
      },
      sorter: true
    },
    {
      title: t('transports.form.parcel_quantity'),
      key: 'parcel_quantity',
      dataIndex: 'parcel_quantity',
      filters: filters?.parcel_quantity?.map((parcel_quantity) => ({
        text: parcel_quantity,
        value: parcel_quantity
      })),
      sorter: true
    },
    {
      title: t('transports.form.weight'),
      key: 'weight',
      dataIndex: 'weight',
      filters: filters?.weight?.map((weight) => ({
        text: weight,
        value: weight
      })),
      sorter: true
    },
    {
      title: t('transports.form.estimated_delivery_date'),
      key: 'estimated_delivery_date',
      dataIndex: ['estimated_delivery_date'],
      filters: filters?.estimated_delivery_date?.map((date) => ({
        text: dayjs(date).format('DD/MM/YYYY'),
        value: date
      })),
      render: (estimated_delivery_date) =>
        estimated_delivery_date
          ? dayjs(estimated_delivery_date).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t('transports.form.recipient'),
      key: 'recipient_name',
      dataIndex: 'recipient_name',
      filters: filters?.recipient_name?.map((recipient_name) => ({
        text: recipient_name,
        value: recipient_name
      })),
      sorter: true
    },
    {
      title: t('transports.form.arrival_location'),
      key: 'arrival_city',
      dataIndex: '',
      filters: filters?.arrival_city?.map((city) => ({
        text: city,
        value: city
      })),
      render: (record) => {
        const { arrival_city, arrival_country, arrival_address } = record;
        return arrival_address || `${arrival_city}, ${arrival_country}`;
      },
      sorter: true
    },
    {
      title: t('transports.form.means_of_transport'),
      key: 'means_of_transport',
      dataIndex: 'means_of_transport',
      filters: filters?.means_of_transport?.map((means_of_transport) => ({
        text: t(`transports.form.${means_of_transport}`),
        value: means_of_transport
      })),
      sorter: true,
      render: (means_of_transport) =>
        (means_of_transport && t(`transports.form.${means_of_transport}`)) ||
        '-'
    },
    {
      title: t('transports.form.nature_of_the_goods'),
      key: 'nature_of_the_goods',
      dataIndex: 'nature_of_the_goods',
      filters: filters?.nature_of_the_goods?.map((nature_of_the_goods) => ({
        text: nature_of_the_goods,
        value: nature_of_the_goods
      })),
      sorter: true
    },
    {
      title: t('transports.form.value_of_the_goods'),
      key: 'value_of_the_goods',
      dataIndex: 'value_of_the_goods',
      filters: filters?.value_of_the_goods?.map((value_of_the_goods) => ({
        text: value_of_the_goods,
        value: value_of_the_goods
      })),
      sorter: true
    },
    {
      title: t('transports.form.subscriber_last_name_first_name'),
      key: 'subscriber_last_name_first_name',
      dataIndex: ['contract', 'subscriber_last_name_first_name'],
      filters: filters?.contract?.map((subscriber) => ({
        text: subscriber.subscriber_last_name_first_name,
        value: subscriber.subscriber_last_name_first_name
      })),
      sorter: true
    },
    // {
    //   title: t('transports.form.subscriber_address'),
    //   key: 'subscriber_address',
    //   render: renderAddress
    // },
    // {
    //   title: t('transports.form.subscriber_postal_code'),
    //   key: 'subscriber_postal_code',
    //   dataIndex: ['contract', 'postal_code'],
    //   filters: filters?.contract?.postal_code?.map((postal_code) => ({
    //     text: postal_code,
    //     value: postal_code
    //   })),
    //   sorter: true
    // },
    // {
    //   title: t('transports.form.subscriber_city'),
    //   key: 'subscriber_city',
    //   dataIndex: ['contract', 'city'],
    //   filters: filters?.contract?.city?.map((city) => ({
    //     text: city,
    //     value: city
    //   })),
    //   sorter: true
    // },
    {
      title: t('transports.form.date_added'),
      key: 'created_at',
      dataIndex: ['created_at'],
      filters: filters?.created_at?.map((date) => ({
        text: dayjs(date).format('DD/MM/YYYY'),
        value: date
      })),
      render: (created_at) =>
        created_at ? dayjs(created_at).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('vehicles.form.deletion_date'),
      key: 'deletion_date_effect',
      dataIndex: 'deletion_date_effect',
      filters: filters?.deletion_date_effect?.map((date) => ({
        text: dayjs(date).format('DD/MM/YYYY'),
        value: date
      })),
      render: (deletion_date_effect) =>
        deletion_date_effect
          ? dayjs(deletion_date_effect).format('DD/MM/YYYY')
          : '-'
    }
  ];

  const getConfig = useCallback(async () => {
    if (!isMounted.current) return;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/programme-configs/${companyId}/advalorem-configs`
      });
      setConfig(data[0]);
    } catch (e) {
      if (isMounted.current) message('missing_parameters');
    }
  }, [dispatchAPI, message]);

  useEffect(() => {
    getConfig();
    fetchFilters();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const filteredColumns = allColumns.filter((column) => config[column.key]);

  return [filteredColumns, loading] || [];
};
