import { Route, Routes } from 'react-router-dom';
import { ListMachineryBreakageClaims } from './ListMachineryBreakageClaims';
import { MachineryBreakageClaimsfCreateUpdate } from './MachineryBreakageClaimsfCreateUpdate';
import { MachineryBreakageClaimsShow } from './MachineryBreakageClaimsShow';
import { Exception } from '../../../components';

/**
 * Provides routing for managing golf claims, including create, edit, show, and list views.
 *
 * @component
 * @returns {React.ReactNode} Routes for different views related to golf claims.
 */
export const MachineryBreakageClaimsRouter = () => (
  <Routes>
    <Route
      path="create/:id"
      element={<MachineryBreakageClaimsfCreateUpdate purpose="create" />}
    />
    <Route
      path="edit/:id"
      element={<MachineryBreakageClaimsfCreateUpdate purpose="edit" />}
    />
    <Route path="show/:id" element={<MachineryBreakageClaimsShow />} />
    <Route index element={<ListMachineryBreakageClaims />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
