import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { useColumns } from './columnsAdValorem';
import { useActionColumn } from './actionColumn';
import { ListResource } from '../../../../components/ListResource/ListResourceTransport';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { ResetFilterButton } from '../../../components/ResetFiltersButton';

/**
 * `ListAdValorem` is a React component designed to list and manage ad valorem transports.
 * It provides filtering options by entity, and site.
 * The component fetches the data based on the filters applied.
 *
 * @component
 * @returns {ReactElement} Rendered `ListAdValorem` component.
 */

export const ListAdValorem = () => {
  const [resetFilters, setResetFilters] = useState(false);
  const [showData, setShowData] = useState(true);
  const [edit, setEdit] = useState(true);
  const [reintegrate, setReintegrate] = useState(true);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [extraFilter, setExtraFilter] = useState('');
  const [isArchivatedPage, setArchivatedPage] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const currentPage = Number(params.get('p') || 1);
  const pageSize = Number(params.get('pS') || 10);

  const { company, verifPermissions, permissions } = useAuthContext();
  const { t } = useTranslation();

  let filterParameter = '';
  const resourceName = 'transports';
  const dbExtraQueryFilter = `company_Id=${company}&sub_programme=AD_VALOREM`;
  const populate = 'entity,site,contract,company_Id';

  const [columns, loading] = useColumns(
    resourceName,
    dbExtraQueryFilter,
    populate
  );
  const actionColumn = useActionColumn(edit, isArchivatedPage);

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setShowData(
        !verifPermissions('datas/ad-valorem', permissions?.notPermittedRoutes)
      );
      setEdit(
        !verifPermissions(
          '/programmes/ad-valorem/edit/',
          permissions?.notPermittedRoutes
        )
      );
      setReintegrate(
        !verifPermissions(
          'programmes/ad-valorem/reintegrate',
          permissions?.notPermittedRoutes
        )
      );
    }
  }, [permissions]);

  useEffect(() => {
    if (resetFilters) {
      filterParameter = '';
      setResetFilters(false);
    }
    setExtraFilter(filterParameter);
    navigate({
      pathname: '/programmes/ad-valorem',
      search: `?&p=${currentPage}&pS=${pageSize}&f=${filterParameter}`
    });
  }, [navigate, resetFilters]);

  useEffect(() => {
    if (!forceRefresh) {
      setForceRefresh(false);
    }
  }, [forceRefresh]);

  if (loading) return <Spin fullscreen="true" />;

  return (
    <ListResource
      extraQuery={dbExtraQueryFilter}
      resourceName={resourceName}
      exportUrl={`exports/company/${company}/sub-programme/AD_VALOREM`}
      importUrl={`imports-transports/company/${company}/sub-programme/AD_VALOREM`}
      tradKey="transport"
      exportTemplateUrl="export-templates/template/advalorem"
      columns={[...columns, ...actionColumn]}
      withExtraFilters={<ResetFilterButton setResetFilters={setResetFilters} />}
      populate={populate}
      editAction={false}
      showAction={false}
      sheetName={t('transport.title')}
      headers
      adValorem
      withUploadButton={showData}
      permissionReintegrate={reintegrate}
      customDelete
      forceRefresh={forceRefresh}
      setForceRefresh={() => setForceRefresh()}
      extraFilter={extraFilter}
      setIsArchivatedPage={setArchivatedPage}
      customActionColumn
      scroll={{ x: 3800 }}
    />
  );
};
