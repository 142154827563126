import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Input, DatePicker, Select } from 'antd';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { SelectManager } from '../../../components/SelectManager';
import { SelectContract } from '../../../components/SelectContract';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { SelectEntityV2 } from '../../../components/SelectEntityV2';
import { SelectSiteV2 } from '../../../components/SelectSiteV2';
import { MachineryBreakageContext } from '../MachineryBreakageContext';

const { TextArea } = Input;
const { Option } = Select;

/**
 * `useFields` is a custom hook that provides fields for forms and manages their associated states.
 *
 * @returns
 * @property {Array} fields - An array of field configurations for the form.
 * @property {boolean} isFieldsLoading - A flag to determine whether the form fields are being loaded.
 * @property {function} setMachinaryStatusType - Sets selected machinary status type.
 * @hook
 */

const useFields = ({
  currentDates,
  setCurrentDates,
  setMachinaryStatusType,
  form
}) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const {
    currentEntityContext,
    setCurrentEntityContext,
    entitiesContext,
    setEntitiesContext,
    currentContract,
    setCurrentContract
  } = useContext(MachineryBreakageContext);

  const [enums, setEnums] = useState({});
  const dateFormat = 'DD-MM-YYYY';

  const handleDateChange = (dateToDefine) => (date, dateString) => {
    setCurrentDates({
      ...currentDates,
      [dateToDefine]: dateString
    });
  };

  const handleEntityChange = (value) => {
    setCurrentEntityContext(value);
    form.setFieldsValue({ site: undefined });
  };

  const handleContractChange = () => {
    form.setFieldsValue({ entity: undefined, site: undefined });
  };

  const fieldsGolf = [
    {
      label: ['contract_number'],
      name: ['contract'],
      rules: [{ required: true }],
      input: (
        <SelectContract
          dbKey="contract"
          programme_types="MACHINERY_BREAKAGE"
          setCurrentContract={setCurrentContract}
          currentEntityContext={currentEntityContext}
          handleContractChange={handleContractChange}
        />
      ),
      startWithDivider: {
        title: t('vehicles.form.main_informations')
      }
    },
    {
      label: ['customer_manager'],
      name: ['customer_manager'],
      rules: [{ required: true }],
      input: (
        <SelectManager
          dbKey="customer_manager"
          setIsFieldsLoading={setIsFieldsLoading}
        />
      )
    },
    {
      label: ['unit_manager'],
      name: ['unit_manager'],
      input: (
        <SelectManager
          dbKey="unit_manager"
          setIsFieldsLoading={setIsFieldsLoading}
        />
      )
    },
    {
      label: ['desired_effective_date'],
      name: ['desired_effective_date'],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['entity'],
      name: ['entity'],
      rules: [{ required: true }],
      input: (
        <SelectEntityV2
          dbKey="entity"
          permKey="programme-MACHINERY_BREAKAGE"
          currentContract={currentContract}
          setEntitiesContext={setEntitiesContext}
          handleEntityChange={handleEntityChange}
          currentEntityContext={currentEntityContext}
        />
      )
    },
    {
      label: ['site'],
      name: ['site'],
      rules: [{ required: true }],
      input: (
        <SelectSiteV2
          dbKey="site"
          permKey="programme-MACHINERY_BREAKAGE"
          currentEntityContext={currentEntityContext}
          entitiesContext={entitiesContext}
        />
      )
    },
    {
      label: ['machinery_site'],
      name: ['machinery_site'],
      rules: [{ required: true }]
    },
    {
      label: ['machinery_location_address'],
      name: ['machinery_location_address'],
      rules: [{ required: true }]
    },
    {
      label: ['postal_code'],
      name: ['postal_code'],
      rules: [{ required: true }]
    },
    {
      label: ['city'],
      name: ['city'],
      rules: [{ required: true }]
    },
    {
      label: ['country'],
      name: ['country'],
      rules: [{ required: true }],
      input: (
        <Select
          placeholder={t('golfs.form.select_a_country')}
          style={{ width: '100%' }}
        >
          {(enums?.countries || []).map((c) => (
            <Option key={c} value={c}>
              {c}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: ['entry_date'],
      name: ['entry_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          format={dateFormat}
          style={{ width: '100%' }}
          onChange={handleDateChange('entry_date')}
        />
      )
    },
    {
      label: ['removal_date'],
      name: ['removal_date'],
      input: (
        <DatePicker
          format={dateFormat}
          style={{ width: '100%' }}
          minDate={dayjs(currentDates.entry_date, dateFormat)}
          disabled={!currentDates.entry_date}
        />
      )
    },
    {
      label: ['machinery_description'],
      name: ['machinery_description']
    },
    {
      label: ['value_new_tax_excl'],
      name: ['value_new_tax_excl'],
      rules: [{ required: true }],
      input: <Input type="Number" />
    },
    {
      label: ['machinery_serial_number'],
      name: ['machinery_serial_number'],
      rules: [{ required: true }]
    },
    {
      label: ['machinery_brand'],
      name: ['machinery_brand'],
      rules: [{ required: true }]
    },
    {
      label: ['machinery_model'],
      name: ['machinery_model'],
      rules: [{ required: true }]
    },
    {
      label: ['initial_commissioning_date'],
      name: ['initial_commissioning_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      name: ['status'],
      rules: [{ required: true }],
      input: (
        <Select
          placeholder={t('machinery_breakages.form.select_a_status')}
          style={{ width: '100%' }}
          onChange={(value) => setMachinaryStatusType(value)}
        >
          {(enums?.status || []).map((value) => (
            <Option key={value} value={value}>
              {t(`machinery_breakages.form.${value}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['periodic_revision'],
      input: (
        <Select
          placeholder={t('machinery_breakages.form.selection')}
          style={{ width: '100%' }}
        >
          {(enums?.periodicRevision || []).map((value) => (
            <Option key={value} value={value}>
              {t(`machinery_breakages.form.${value}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['periodic_revision_frequency'],
      input: (
        <Select
          placeholder={t('machinery_breakages.form.select_a_frequency')}
          style={{ width: '100%' }}
        >
          {(enums?.periodicRevisionFrequency || []).map((frequency) => (
            <Option key={frequency} value={frequency}>
              {t(`machinery_breakages.form.${frequency}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['comments'],
      rules: [{ required: false }],
      input: <TextArea />,
      endWithDivider: {
        title: t('machinery_breakages.form.documents')
      }
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/machinery-breakages/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(false);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fieldsGolf,
    isFieldsLoading
  };
};
export default useFields;
