import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { useColumns } from '../columns';
import { useActionColumn } from '../../actionColumn';
import { ListResource } from '../../../../components/ListResource/ListResource';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { FilterByMissingDocuments } from '../../../components/FilterByMissingDocuments';

/**
 * Renders a list of transport claims with customizable filters and actions.
 * @component
 * @param {Object} props - The props object.
 * @param {string} props.actionColumnUrl - The URL for action columns.
 * @param {string} props.populate - The populate string.
 * @param {string} props.transport_sub_programmes - The transport sub-programmes.
 * @param {string} props.sheetName - The name of the sheet.
 * @param {string} [props.targetType=null] - Optional target type.
 * @returns {JSX.Element} JSX element representing the list of transport claims.
 */
export const RenderTransportList = ({
  actionColumnUrl,
  populate,
  transport_sub_programmes,
  sheetName,
  targetType
}) => {
  const [isMissingDocuments, setIsMissingDocuments] = useState('');
  const [resetFilters, setResetFilters] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [extraFilter, setExtraFilter] = useState('');
  const [isClosedButton, setIsClosedButton] = useState(false);
  const url = 'transport_claims';

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const currentPage = Number(params.get('p') || 1);
  const pageSize = Number(params.get('pS') || 10);

  const { t } = useTranslation();
  const { company } = useAuthContext();

  let filterParameter = '';
  const resourceName = 'transport_claims';
  const dbExtraQueryFilter = `company=${company}&transport_sub_programmes=${transport_sub_programmes}&${
    isClosedButton ? 'claim_status=CLOSED' : 'claim_status!=CLOSED'
  }`;

  const [columns, loading] = useColumns({
    resourceName,
    dbExtraQueryFilter,
    populate,
    targetType
  });
  const actionColumn = useActionColumn(actionColumnUrl, url);

  const onFilterMissingDocuments = (missingDocuments) => {
    setIsMissingDocuments(missingDocuments === false ? '' : missingDocuments);
  };

  useEffect(() => {
    if (resetFilters) {
      filterParameter = '';
      setResetFilters(false);
    }
    filterParameter = `missing_documents=${isMissingDocuments}__`;
    setExtraFilter(filterParameter);
    navigate({
      pathname: `/claims/${actionColumnUrl}`,
      search: `?&p=${currentPage}&pS=${pageSize}&f=${filterParameter}`
    });
  }, [navigate, isMissingDocuments, resetFilters]);

  useEffect(() => {
    if (!forceRefresh) {
      setForceRefresh(false);
    }
  }, [forceRefresh]);

  if (loading) return <Spin fullscreen="true" />;
  return (
    <ListResource
      extraQuery={dbExtraQueryFilter}
      exportUrl={`exports/company/${company}/claim/programme-type/${transport_sub_programmes}`}
      importUrl={`imports-claim-transports/company/${company}?programme-type=${transport_sub_programmes}`}
      exportTemplateUrl={`export-claim-templates/template/transport-claim${
        transport_sub_programmes === 'AD_VALOREM' ? '-ad-valorem' : ''
      }`}
      resourceName={resourceName}
      tradKey="claims"
      columns={[...columns, ...actionColumn]}
      withExtraFilters={
        <FilterByMissingDocuments
          onChange={onFilterMissingDocuments}
          setResetFilters={setResetFilters}
        />
      }
      populate={populate}
      editAction={false}
      showAction={false}
      deleteAction={false}
      noCreateButton
      noArchiveFilter
      sheetName={t(`sheet.${sheetName}`)}
      setIsClosedButton={setIsClosedButton}
      headers={[]}
      extraFilter={extraFilter}
      customActionColumn
      scroll={{ x: 3800 }}
    />
  );
};

RenderTransportList.propTypes = {
  actionColumnUrl: PropTypes.string.isRequired,
  populate: PropTypes.string.isRequired,
  transport_sub_programmes: PropTypes.string.isRequired,
  sheetName: PropTypes.string.isRequired,
  targetType: PropTypes.string
};

RenderTransportList.defaultProps = {
  targetType: null
};
