import dayjs from 'dayjs';
import { useCallback, useContext, useEffect, useState } from 'react';
import { DatePicker, Select, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { SelectContract } from '../../../components/SelectContract';
import { SelectManager } from '../../../components/SelectManager';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { AdValoremContext } from './AdValoremContext';
import { SelectEntityV2 } from '../../../components/SelectEntityV2';
import { SelectSiteV2 } from '../../../components/SelectSiteV2';

const { Option } = Select;

/**
 * `useFields` is a custom hook that provides fields for forms and manages their associated states.
 *
 * @returns
 * @property {Array} fields - An array of field configurations for the form.
 * @property {boolean} isFieldsLoading - A flag to determine whether the form fields are being loaded.
 * @hook
 */

export const useFields = ({ currentDates, setCurrentDates, form }) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [enums, setEnums] = useState({});

  const dateFormat = 'DD-MM-YYYY';
  const {
    currentEntityContext,
    setCurrentEntityContext,
    entitiesContext,
    setEntitiesContext,
    currentContract,
    setCurrentContract
  } = useContext(AdValoremContext);

  const handleDateChange = (dateToDefine) => (date, dateString) => {
    setCurrentDates({
      ...currentDates,
      [dateToDefine]: dateString
    });
  };

  const handleEntityChange = (value) => {
    setCurrentEntityContext(value);
    form.setFieldsValue({ site: undefined });
  };

  const handleContractChange = () => {
    form.setFieldsValue({ entity: undefined, site: undefined });
  };

  const fields = [
    {
      label: ['contract_number'],
      name: ['contract'],
      rules: [{ required: true }],
      input: (
        <SelectContract
          dbKey="contract"
          sub_programme="AD_VALOREM"
          programme_types="TRANSPORT"
          adValorem
          setCurrentContract={setCurrentContract}
          currentEntityContext={currentEntityContext}
          handleContractChange={handleContractChange}
        />
      ),
      startWithDivider: {
        title: t('transports.form.main_informations')
      }
    },
    {
      label: ['customer_manager'],
      name: ['customer_manager'],
      rules: [{ required: true }],
      input: <SelectManager dbKey="customer_manager" />
    },
    {
      label: ['unit_manager'],
      name: ['unit_manager'],
      input: <SelectManager dbKey="unit_manager" />
    },
    {
      label: ['desired_effective_date'],
      name: ['desired_effective_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['entity'],
      name: ['entity'],
      rules: [{ required: true }],
      input: (
        <SelectEntityV2
          dbKey="entity"
          permKey="programme-TRANSPORT-AD_VALOREM"
          currentContract={currentContract}
          setEntitiesContext={setEntitiesContext}
          handleEntityChange={handleEntityChange}
          currentEntityContext={currentEntityContext}
        />
      )
    },
    {
      label: ['site'],
      name: ['site'],
      rules: [{ required: true }],
      input: (
        <SelectSiteV2
          dbKey="site"
          permKey="programme-TRANSPORT-AD_VALOREM"
          currentEntityContext={currentEntityContext}
          entitiesContext={entitiesContext}
        />
      )
    },
    {
      label: ['customer_reference_number'],
      name: ['customer_reference_number'],
      rules: [{ required: true }]
    },
    {
      label: ['shipper_name'],
      name: ['shipper_name'],
      rules: [{ required: true }]
    },
    {
      label: ['recipient_name'],
      name: ['recipient_name'],
      rules: [{ required: true }]
    },
    {
      label: ['departure_date'],
      name: ['departure_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          format={dateFormat}
          style={{ width: '100%' }}
          onChange={handleDateChange('departure_date')}
        />
      )
    },
    {
      label: ['estimated_delivery_date'],
      name: ['estimated_delivery_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          format={dateFormat}
          style={{ width: '100%' }}
          minDate={dayjs(currentDates.departure_date, dateFormat)}
          disabled={!currentDates.departure_date}
        />
      )
    },
    {
      label: ['parcel_quantity'],
      name: ['parcel_quantity'],
      rules: [{ required: true }]
    },
    {
      label: ['weight'],
      name: ['weight'],
      rules: [{ required: true }],
      input: <Input suffix="kg" style={{ width: '100%' }} />
    },
    {
      label: ['means_of_transport'],
      name: ['means_of_transport'],
      input: (
        <Select
          placeholder={t('documents.filter_by_type')}
          style={{ width: '100%' }}
        >
          {(enums?.means_of_transport || []).map((mean) => (
            <Option key={mean} value={mean}>
              {t(`transports.form.${mean}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: ['nature_of_the_goods'],
      name: ['nature_of_the_goods'],
      rules: [{ required: true }]
    },
    {
      label: ['value_of_the_goods'],
      name: ['value_of_the_goods'],
      rules: [{ required: true }]
    },
    {
      label: ['street_number'],
      name: ['departure_street_number'],
      rules: [{ required: true }],
      startWithDivider: {
        title: t('transports.form.departure_address')
      }
    },
    {
      label: ['street_name'],
      name: ['departure_street_name'],
      rules: [{ required: true }]
    },
    {
      label: ['additional_address'],
      name: ['departure_additional_address']
    },
    {
      label: ['postal_code'],
      name: ['departure_postal_code'],
      rules: [{ required: true }]
    },
    {
      label: ['city'],
      name: ['departure_city'],
      rules: [{ required: true }]
    },
    {
      label: ['country'],
      name: ['departure_country'],
      rules: [{ required: true }]
    },
    {
      label: ['street_number'],
      name: ['arrival_street_number'],
      rules: [{ required: true }],
      startWithDivider: {
        title: t('transports.form.arrival_address')
      }
    },
    {
      label: ['street_name'],
      name: ['arrival_street_name'],
      rules: [{ required: true }]
    },
    {
      label: ['additional_address'],
      name: ['arrival_additional_address']
    },
    {
      label: ['postal_code'],
      name: ['arrival_postal_code'],
      rules: [{ required: true }]
    },
    {
      label: ['city'],
      name: ['arrival_city'],
      rules: [{ required: true }]
    },
    {
      label: ['country'],
      name: ['arrival_country'],
      rules: [{ required: true }]
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/transports/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(false);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};
