// TODO: Refactor
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import { useEffect, useState } from 'react';
import { TileLink } from '../components/TileLink';
import { TileMenu } from '../components/TileMenu';
import { ContentCustom } from '../../components/ContentCustom/ContentCustom';
import { PageHeaderCustom } from '../../components/PageHeader/PageHeader';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { MenuAddSubscription } from './MenuAddSubscription';
import {
  CivilTile,
  ConstructionTile,
  FreightTile,
  GolfTile,
  RealestateTile,
  VehicleTile,
  MachineryBreakageTile,
  HoleInOneTile,
  EventCancellationTile,
  CyberInsuranceTile,
  HardwareInsuranceTile,
  ProfessionalMultiriskInsuranceTile
} from '../../utils/constants/customIcons';
import AssemblyTesting from '../../assets/images/tiles/AssemblyTesting.svg';
import EnvironmentInsuranceTile from '../../assets/images/tiles/Green.svg';
import ProtectionJuridiqueTile from '../../assets/images/tiles/ProtectionJuridique.svg';

/**
 * A component for rendering a page with program tiles.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.title - The title for the page.
 * @param {string} props.tilesPage - The page for the tiles.
 * @returns {ReactElement} The rendered component.
 */

export const ProgrammesTilesPage = ({ title }) => {
  const { t } = useTranslation();
  const { user, dispatchAPI, company } = useAuthContext();
  const { message } = useErrorMessage();
  const menu = MenuAddSubscription();

  const subMenuDisplay = !!['users:SUPER-USER', 'admins:SUPER-ADMIN'].includes(
    user.role
  );

  const [properties, setProperties] = useState(true);
  const [golfs, setGolfs] = useState(true);
  const [vehicles, setVehicles] = useState(true);
  const [civil, setCivil] = useState(true);
  const [transport, setTransport] = useState(true);
  const [constructionRight, setConstruction] = useState(true);
  const [machineryBreakageRight, setMachineryBreakageRight] = useState(true);
  const [holeInOneRight, setHoleInOneRight] = useState(true);
  const [eventCancellationRight, setEventCancellationRight] = useState(true);
  const [cyberInsuranceRight, setCyberInsuranceRight] = useState(true);
  const [hardwareInsuranceRight, setHardwareInsuranceRight] = useState(true);
  const [
    professionalMultiriskInsuranceRight,
    setProfessionalMultiriskInsuranceRight
  ] = useState(true);
  const [assemblyTestingInsuranceRight, setAssemblyTestingInsuranceRight] =
    useState(true);
  const [legalProtectionInsuranceRight, setLegalProtectionInsuranceRight] =
    useState(true);
  const [environmentInsuranceRight, setEnvironmentInsuranceRight] =
    useState(true);
  const [fleetDisplay, setFleetDisplay] = useState(false);
  const [civilLiabilityDisplay, setCivilLiabilityDisplay] = useState(false);
  const [transportDisplay, setTransportDisplay] = useState(false);
  const [constructionDisplay, setConstructionDisplay] = useState(false);
  const [golfDisplay, setGolfDisplay] = useState(false);
  const [propertyDisplay, setPropertyDisplay] = useState(false);
  const [machineryBreakageDisplay, setMachineryBreakageDisplay] =
    useState(false);
  const [holeInOneDisplay, setHoleInOneDisplay] = useState(false);
  const [eventCancellationDisplay, setEventCancellationDisplay] =
    useState(false);
  const [cyberInsuranceDisplay, setCyberInsuranceDisplay] = useState(false);
  const [hardwareInsuranceDisplay, setHardwareInsuranceDisplay] =
    useState(false);
  const [
    professionalMultiriskInsuranceDisplay,
    setProfessionalMultiriskInsuranceDisplay
  ] = useState(false);
  const [assemblyTestingInsuranceDisplay, setAssemblyTestingInsuranceDisplay] =
    useState(false);
  const [legalProtectionInsuranceDisplay, setLegalProtectionInsuranceDisplay] =
    useState(false);
  const [environmentInsuranceDisplay, setEnvironmentInsuranceDisplay] =
    useState(false);
  const [vehiclesFleetLinks, setVehiclesFleetLinks] = useState([]);
  const [civilLiabilityLinks, setCivilLiabilityLinks] = useState([]);
  const [freightLinks, setFreightLinks] = useState([]);
  const [constructionLinks, setConstructionLinks] = useState([]);

  const verifIfUserHasPermission = (permission) => {
    if (permission?.val === true) return true;
    return (
      permission?.children?.some((child) => verifIfUserHasPermission(child)) ||
      false
    );
  };
  const setUserPermissions = (permissions) => {
    permissions[0].children.forEach((permission) => {
      const keys = permission?.key.split('-');
      const key = keys.slice(-1);
      switch (key[0]) {
        case 'PROPERTY':
          setProperties(verifIfUserHasPermission(permission));
          break;
        case 'GOLF':
          setGolfs(verifIfUserHasPermission(permission));
          break;
        case 'FLEET':
          setVehicles(verifIfUserHasPermission(permission));
          break;
        case 'CIVIL_LIABILITY':
          setCivil(verifIfUserHasPermission(permission));
          break;
        case 'TRANSPORT':
          setTransport(verifIfUserHasPermission(permission));
          break;
        case 'CONSTRUCTION':
          setConstruction(verifIfUserHasPermission(permission));
          break;
        case 'MACHINERY_BREAKAGE':
          setMachineryBreakageRight(verifIfUserHasPermission(permission));
          break;
        case 'HOLE_IN_ONE':
          setHoleInOneRight(verifIfUserHasPermission(permission));
          break;
        case 'EVENT_CANCELLATION':
          setEventCancellationRight(verifIfUserHasPermission(permission));
          break;
        case 'CYBER_INSURANCE':
          setCyberInsuranceRight(verifIfUserHasPermission(permission));
          break;
        case 'HARDWARE_INSURANCE':
          setHardwareInsuranceRight(verifIfUserHasPermission(permission));
          break;
        case 'PROFESSIONAL_MULTIRISK_INSURANCE':
          setProfessionalMultiriskInsuranceRight(
            verifIfUserHasPermission(permission)
          );
          break;
        case 'ASSEMBLY_TESTING_INSURANCE':
          setAssemblyTestingInsuranceRight(
            verifIfUserHasPermission(permission)
          );
          break;
        case 'LEGAL_PROTECTION_INSURANCE':
          setLegalProtectionInsuranceRight(
            verifIfUserHasPermission(permission)
          );
          break;
        case 'ENVIRONMENT_INSURANCE':
          setEnvironmentInsuranceRight(verifIfUserHasPermission(permission));
          break;
        default:
          break;
      }
    });
  };
  const getUserPermissions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `permissions/${user?.permissions}`
      });
      const permissions = data?.permissions;
      setUserPermissions(permissions);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    if (user?.permissions && user.role === 'users:USER') {
      (async () => {
        await getUserPermissions();
      })();
    }
  }, [user]);

  const getProgrammesToDisplay = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `contracts/company/${company}`
      });
      const {
        programmesToDisplay,
        vehicleFleet,
        civilLiability,
        freight,
        construction
      } = data;
      const constru = construction.filter((item) => item !== null);
      setFleetDisplay(programmesToDisplay.includes('FLEET'));
      setGolfDisplay(programmesToDisplay.includes('GOLF'));
      setPropertyDisplay(programmesToDisplay.includes('PROPERTY'));
      setTransportDisplay(programmesToDisplay.includes('TRANSPORT'));
      setCivilLiabilityDisplay(programmesToDisplay.includes('CIVIL_LIABILITY'));
      setConstructionDisplay(programmesToDisplay.includes('CONSTRUCTION'));
      setMachineryBreakageDisplay(
        programmesToDisplay.includes('MACHINERY_BREAKAGE')
      );
      setHoleInOneDisplay(programmesToDisplay.includes('HOLE_IN_ONE'));
      setEventCancellationDisplay(
        programmesToDisplay.includes('EVENT_CANCELLATION')
      );
      setCyberInsuranceDisplay(programmesToDisplay.includes('CYBER_INSURANCE'));
      setHardwareInsuranceDisplay(
        programmesToDisplay.includes('HARDWARE_INSURANCE')
      );
      setProfessionalMultiriskInsuranceDisplay(
        programmesToDisplay.includes('PROFESSIONAL_MULTIRISK_INSURANCE')
      );
      setAssemblyTestingInsuranceDisplay(
        programmesToDisplay.includes('ASSEMBLY_TESTING_INSURANCE')
      );
      setLegalProtectionInsuranceDisplay(
        programmesToDisplay.includes('LEGAL_PROTECTION_INSURANCE')
      );
      setEnvironmentInsuranceDisplay(
        programmesToDisplay.includes('ENVIRONMENT_INSURANCE')
      );
      setVehiclesFleetLinks(vehicleFleet);
      setCivilLiabilityLinks(civilLiability);
      setFreightLinks(freight);
      setConstructionLinks([...constru, 'calculator']);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  useEffect(() => {
    (async () => {
      await getProgrammesToDisplay();
    })();
  }, [company]);

  return (
    <>
      <PageHeaderCustom
        title={t(`home.${title}`)}
        // extra={menu}
        extra={subMenuDisplay ? menu : null}
      />
      <ContentCustom>
        <Row gutter={[24, 24]} align="stretch">
          {properties && propertyDisplay && (
            <Col xs={24} md={12} xl={8}>
              <TileLink
                image={<RealestateTile />}
                altImage="property"
                target="programmes"
                link="properties"
              />
            </Col>
          )}
          {vehicles && fleetDisplay && (
            <Col xs={24} md={12} xl={8}>
              <TileMenu
                target="programmes"
                image={<VehicleTile />}
                altImage="vehicles_fleet"
                links={vehiclesFleetLinks}
              />
            </Col>
          )}
          {professionalMultiriskInsuranceRight &&
            professionalMultiriskInsuranceDisplay && (
              <Col xs={24} md={12} xl={8}>
                <TileLink
                  target="programmes"
                  image={<ProfessionalMultiriskInsuranceTile />}
                  altImage="professional_multirisk_insurance"
                  link="professional-multirisk-insurances"
                />
              </Col>
            )}
          {civil && civilLiabilityDisplay && (
            <Col xs={24} md={12} xl={8}>
              <TileMenu
                target="programmes"
                image={<CivilTile />}
                altImage="civil_liability"
                links={civilLiabilityLinks}
              />
            </Col>
          )}
          {constructionRight && constructionDisplay && (
            <Col xs={24} md={12} xl={8}>
              <TileMenu
                target="programmes"
                image={<ConstructionTile />}
                altImage="construction"
                links={constructionLinks}
              />
            </Col>
          )}
          {transport && transportDisplay && (
            <Col xs={24} md={12} xl={8}>
              <TileMenu
                image={<FreightTile />}
                altImage="transport"
                links={freightLinks}
                target="programmes"
              />
            </Col>
          )}
          {cyberInsuranceRight && cyberInsuranceDisplay && (
            <Col xs={24} md={12} xl={8}>
              <TileLink
                target="programmes"
                image={<CyberInsuranceTile />}
                altImage="cyber_insurance"
                link="cyber-insurances"
              />
            </Col>
          )}
          {assemblyTestingInsuranceRight && assemblyTestingInsuranceDisplay && (
            <Col xs={24} md={12} xl={8}>
              <TileLink
                target="programmes"
                image={
                  <img src={AssemblyTesting} alt="assurance_montage_essais" />
                }
                altImage="assembly_testing_insurance"
                link="assembly-testing-insurances"
              />
            </Col>
          )}
          {hardwareInsuranceRight && hardwareInsuranceDisplay && (
            <Col xs={24} md={12} xl={8}>
              <TileLink
                target="programmes"
                image={<HardwareInsuranceTile />}
                altImage="hardware_insurance"
                link="hardware-insurances"
              />
            </Col>
          )}
          {machineryBreakageRight && machineryBreakageDisplay && (
            <Col xs={24} md={12} xl={8}>
              <TileLink
                target="programmes"
                image={<MachineryBreakageTile />}
                altImage="machinery_breakage"
                link="machinery-breakages"
              />
            </Col>
          )}
          {golfs && golfDisplay && (
            <Col xs={24} md={12} xl={8}>
              <TileLink
                image={<GolfTile />}
                altImage="golf"
                link="golfs"
                target="programmes"
              />
            </Col>
          )}
          {eventCancellationRight && eventCancellationDisplay && (
            <Col xs={24} md={12} xl={8}>
              <TileLink
                target="programmes"
                image={<EventCancellationTile />}
                altImage="event_cancellation"
                link="event-cancellations"
              />
            </Col>
          )}
          {holeInOneRight && holeInOneDisplay && (
            <Col xs={24} md={12} xl={8}>
              <TileLink
                target="programmes"
                image={<HoleInOneTile />}
                altImage="hole_in_one"
                link="hole-in-ones"
              />
            </Col>
          )}
          {legalProtectionInsuranceRight && legalProtectionInsuranceDisplay && (
            <Col xs={24} md={12} xl={8}>
              <TileLink
                target="programmes"
                image={
                  <img
                    src={ProtectionJuridiqueTile}
                    alt="assurance_montage_essais"
                    style={{ height: '100%' }}
                  />
                }
                altImage="legal_protection_insurance"
                link="legal-protection-insurances"
              />
            </Col>
          )}
          {environmentInsuranceRight && environmentInsuranceDisplay && (
            <Col xs={24} md={12} xl={8}>
              <TileLink
                target="programmes"
                image={
                  <img
                    src={EnvironmentInsuranceTile}
                    alt="environment_insurance"
                    style={{ height: '100%' }}
                  />
                }
                altImage="environment_insurance"
                link="environment-insurances"
              />
            </Col>
          )}
        </Row>
      </ContentCustom>
    </>
  );
};

ProgrammesTilesPage.propTypes = {
  title: PropTypes.string.isRequired
};
