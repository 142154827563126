import { useTranslation } from 'react-i18next';
import { InputNumber, Select } from 'antd';
import { MultiSelectEntity } from '../../components/MultiSelectEntity';

/**
 * Generate fields configuration for a form.
 *
 * @function
 * @returns {Object} An object containing the fields configuration.
 */
export const useFields = () => {
  const { t } = useTranslation();

  const yesNo = ['YES', 'NO'];

  const activityOptions = [
    'AGRO_FOOD_AGRICULTURE',
    'PUBLIC_ADMINISTRATION',
    'RETAILER',
    'INSURANCE_BROKER',
    'EDUCATION',
    'ESN_EXCL_DATA_CENTER_TREATEMENT',
    'HEALTH_SERVICES',
    'FINANCE_BANK_INSURANCE',
    'CATERING',
    'HIGH_TECH',
    'INDUSTRY',
    'PHARMACEUTICAL_BIOTECH',
    'ENTERTAINMENT_FOOD_CATERING',
    'MEDIA_COMMUNICATION',
    'MEDICAL_PARAMEDICAL_EXCL_HEALTH_SERVICE',
    'TRADE_WHOLESALE_TRADE',
    'SOCIAL_ORGANIZATION',
    'WATER_GAS_ELECTRICITY',
    'LIBERAL_PROFESSION_EXCL_MEDICAL',
    'PERSONAL_SERVICE',
    'SERVICE_CONSULTING',
    'HEAD_OFFICE',
    'TELECOM_INTERNET_PROVIDER',
    'MISC_UNION_ASSOCIATIONS_ORGANISATION',
    'TOURISM',
    'DATA_CENTER_TREATEMENT',
    'AIR_SEA_TRANSPORT',
    'ROAD_INLAND_NAVIGATION_TRANSPORT',
    'OTHER'
  ];

  const createOptionObj = (optArray) =>
    optArray.map((opt) => ({
      label: t(`legal_protection_insurance.enums.${opt}`),
      value: opt
    }));

  const activityOptionsObj = createOptionObj(activityOptions);
  const yesNoOptionsObj = createOptionObj(yesNo);

  const fieldsInformations = [
    {
      label: ['entity'],
      name: ['entity'],
      rules: [{ required: true }],
      input: <MultiSelectEntity dbKey="entity" />
    },
    {
      label: ['site_name'],
      name: ['legal_protection_insurance', 'site_name']
    },
    {
      label: ['site_address'],
      name: ['legal_protection_insurance', 'site_address'],
      rules: [{ required: true }]
    },
    {
      label: ['postal_code'],
      name: ['legal_protection_insurance', 'postal_code'],
      rules: [{ required: true }]
    },
    {
      label: ['city'],
      name: ['legal_protection_insurance', 'city'],
      rules: [{ required: true }]
    },
    {
      label: ['global_company_turnover'],
      name: ['legal_protection_insurance', 'global_company_turnover'],
      rules: [{ required: true }],
      input: <InputNumber addonAfter="€" />
    },
    {
      label: ['employees_count'],
      name: ['legal_protection_insurance', 'employees_count'],
      rules: [{ required: true }],
      input: <InputNumber />
    },
    {
      label: ['activity'],
      name: ['legal_protection_insurance', 'activity'],
      rules: [{ required: true }],
      input: <Select options={activityOptionsObj} />
    },
    {
      label: ['legal_procedure_in_progress'],
      name: ['legal_protection_insurance', 'legal_procedure_in_progress'],
      rules: [{ required: true }],
      input: <Select options={yesNoOptionsObj} />
    },
    {
      label: ['desire_options_litigies'],
      name: ['legal_protection_insurance', 'desire_options_litigies'],
      rules: [{ required: true }],
      input: <Select options={yesNoOptionsObj} />
    },
    {
      label: ['double_plafonds_if_sinister'],
      name: ['legal_protection_insurance', 'double_plafonds_if_sinister'],
      rules: [{ required: true }],
      input: <Select options={yesNoOptionsObj} />
    }
  ];

  return { fieldsInformations };
};
