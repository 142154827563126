import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useFields } from './fieldsHardwareInsuanceSubscription';
import { useAuthContext } from '../../../contexts/AuthContext';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';

/**
 * Component for creating or updating hardware subscriptions.
 * @component
 * @param {Object} props - The component's properties.
 * @param {string} props.purpose - The purpose of the component, either "create" or "edit".
 * @returns {JSX.Element} The JSX element for creating or updating hardware subscriptions.
 */
export const HardwareInsuranceCreateUpdate = ({ purpose }) => {
  const { company } = useAuthContext();
  const program = 'HARDWARE_INSURANCE';
  const { t } = useTranslation();
  const { fieldsInformations } = useFields();

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        hardware_insurance: {
          ...data?.hardware_insurance,
          desired_effective_date:
            data?.hardware_insurance?.desired_effective_date &&
            dayjs(data?.hardware_insurance?.desired_effective_date)
        }
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        company_id: company,
        programme_types: program
      })
    }
  };

  return (
    <CreateUpdateContainer
      title={t(`subscriptions.form.${purpose}`)}
      fields={fieldsInformations}
      purpose={purpose}
      tradKey="hardware_insurances"
      baseUrl="subscriptions"
      resource="subscriptions"
      config={config}
      withFilesManager={false}
      messageOnSuccess
    />
  );
};

HardwareInsuranceCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
