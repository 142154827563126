import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker, InputNumber, Select } from 'antd';
import { MultiSelectEntity } from '../../../components/MultiSelectEntity';
import { useGetEnums } from '../../../../utils/getEnums';
import { GenericSelectEnums } from '../../../components/GenericSelectEnums';
import { SelectYesNo } from '../../../components/SelectYesNo';
import { MultiFormFieldsGenerationReducer } from './MultiFormFieldsGenerationReducer';

const { Option } = Select;

/**
 * Custom hook that provides fields configuration and loading state for the Environment Insurance form.
 *
 * @hook
 * @param {boolean} isFieldsLoading - The loading state of the fields.
 * @param {function} setIsFieldsLoading - The function to set the loading state of the fields.
 * @param {object} state - The state object.
 * @param {function} dispatch - The dispatch function.
 * @param {object} form - The form object.
 * @returns {object} - The fields configuration and loading state.
 */
export const useFields = (
  isFieldsLoading,
  setIsFieldsLoading,
  state,
  dispatch,
  form
) => {
  const { t } = useTranslation();
  const dateFormat = 'DD-MM-YYYY';
  const { getEnums } = useGetEnums();
  const [enums, setEnums] = useState({});

  const sitesFields = [
    {
      name: ['site_address'],
      rules: [{ required: true }]
    },
    {
      name: ['site_postal_code'],
      rules: [{ required: true }]
    },
    {
      name: ['site_city'],
      rules: [{ required: true }]
    },
    {
      name: ['is_site_under_authorization'],
      rules: [{ required: true }],
      input: (
        <Select placeholder={t('environment_insurances.form.yes_or_no')}>
          <Option value="YES">{t('environment_insurances.form.YES')}</Option>
          <Option value="NO">{t('environment_insurances.form.NO')}</Option>
        </Select>
      )
    }
  ];

  const fields = [
    {
      label: ['entity'],
      name: ['entity'],
      rules: [{ required: true }],
      input: <MultiSelectEntity dbKey="entity" />
    },
    {
      label: ['desired_effective_date'],
      name: ['desired_effective_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['address'],
      name: ['address'],
      rules: [{ required: true }]
    },
    {
      label: ['postal_code'],
      name: ['postal_code'],
      rules: [{ required: true }]
    },
    {
      label: ['city'],
      name: ['city'],
      rules: [{ required: true }]
    },
    {
      label: ['company_registration_number'],
      name: ['company_registration_number'],
      rules: [{ required: true }]
    },
    {
      label: ['business_domain'],
      name: ['business_domain'],
      rules: [{ required: true }]
    },
    {
      label: ['territoriality_of_activity'],
      name: ['territoriality_of_activity'],
      rules: [{ required: true }],
      input: (
        <GenericSelectEnums
          dbKey={['territoriality_of_activity']}
          enums={enums?.territorialityOfActivity}
          tradKeyEnums="environment_insurances.form.enums"
          isMultiple
        />
      )
    },
    {
      label: ['global_yearly_turnover'],
      name: ['global_yearly_turnover'],
      rules: [{ required: true }],
      input: <InputNumber addonAfter="€" />
    },
    {
      label: ['number_of_sites_to_cover'],
      name: ['number_of_sites_to_cover'],
      rules: [{ required: true }],
      initialValue: state.count,
      input: (
        <InputNumber
          min={1}
          onChange={(value) =>
            dispatch({ type: 'SET_COUNT', field: 'count', payload: value })
          }
        />
      )
    },
    {
      label: ['sites_adress_and_if_under_authorization'],
      name: ['sites_adress_and_if_under_authorization'],
      rules: [{ required: true }],
      input: (
        <MultiFormFieldsGenerationReducer
          dbKey={['sites_adress_and_if_under_authorization']}
          fields={sitesFields}
          tradKey="environment_insurances"
          additionalTranslation="sites_adress_and_if_under_authorization"
          form={form}
          formCount={state.count}
          enums={enums.booleanChoices}
        />
      )
    },
    {
      label: ['is_ISO_14001_or_EMAS'],
      name: ['is_ISO_14001_or_EMAS'],
      rules: [{ required: true }],
      input: <SelectYesNo dbKey={['is_ISO_14001_or_EMAS']} />
    }
  ];

  const fetchEnums = async () => {
    const data = await getEnums('environment-insurances');
    setEnums(data);
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await fetchEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};
