import PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { DocumentsContext } from '../DocumentsContext';

const { Option } = Select;

/**
 * `SelectByType` is a React component that displays a dropdown selector allowing users to select
 * a document type. It fetches the available document types from the `DocumentsContext` and presents
 * them as selectable options in a dropdown list.
 *
 * This component is useful when you want to filter or categorize documents by their type.
 *
 * @component
 * @param {object} props - The properties passed to the component.
 * @param {function} props.onChange - A callback function that's invoked when the selected value changes.
 *
 * @returns {JSX.Element} Returns a rendered dropdown selector containing document types.
 *
 * @example
 * <SelectByType onChange={(value) => console.log("Selected value:", value)} />
 */

export const SelectByType = ({ onChange, permissions }) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const { enums } = useContext(DocumentsContext);

  useEffect(() => {
    if (enums && enums.type) {
      setOptions([...enums.type]);
      if (permissions) {
        const filteredOptions = enums?.type.filter(
          (e) => e !== 'CONTRACT' && e !== 'AMENDMENT'
        );
        setOptions(filteredOptions);
      }
    }
  }, [enums]);

  return (
    <Select
      allowClear
      placeholder={t('documents.filter_by_type')}
      onChange={onChange}
    >
      {options &&
        options.map((o) => (
          <Option key={o} value={o}>
            {t(`documents.status.${o}`)}
          </Option>
        ))}
    </Select>
  );
};

SelectByType.propTypes = {
  onChange: PropTypes.func.isRequired,
  permissions: PropTypes.bool.isRequired
};
