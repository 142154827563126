import { useListContent } from './listContent';
import { useExtraButtons } from '../extraButtons';
import { VehiclesClaimShow } from '../vehiclesClaimShow';

/**
 * Renders the LightVehiclesShow component.
 * @returns {React.Component} The rendered HeavyVehiclesShow component.
 */
export const LightVehiclesShow = () =>
  VehiclesClaimShow({
    useListContent,
    pathname: 'light-vehicles',
    useExtraButtons
  });
