import { useTranslation } from 'react-i18next';
import { DatePicker, Input } from 'antd';
import { SelectManager } from '../../components/SelectManager';
import { SelectEnums } from './SelectEnums';

const { TextArea } = Input;

/**
 * Generate fields configuration for a form.
 * @param {string} subProgramme - The sub Programme value.
 * @returns {Object} An object containing the fields configuration.
 */
export const useFields = (subProgramme) => {
  const { t } = useTranslation();
  const dateFormat = 'DD-MM-YYYY';

  const fieldsInformations = [
    {
      label: ['customer_manager'],
      name: ['customer_manager'],
      rules: [{ required: true }],
      input: <SelectManager dbKey="customer_manager" />
    },
    {
      label: ['unit_manager'],
      name: ['unit_manager'],
      input: <SelectManager dbKey="unit_manager" />
    },
    {
      label: 'sub_programme',
      input: (
        <Input placeholder={t(`subscriptions.form.${subProgramme}`)} disabled />
      )
    },
    {
      label: ['desired_effective_date'],
      name: ['desired_effective_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['APE_code'],
      name: ['APE_code'],
      rules: [{ required: true }],
      input: <SelectEnums dbKey="APE_code" />
    },
    {
      label: ['previous_year_revenue'],
      name: ['previous_year_revenue'],
      rules: [{ required: true }],
      input: <Input addonAfter="€" />
    },
    {
      label: ['eighteen_months_span_statistic_number_of_employees'],
      name: ['eighteen_months_span_statistic_number_of_employees'],
      rules: [{ required: true }],
      input: (
        <SelectEnums dbKey="eighteen_months_span_statistic_number_of_employees" />
      )
    },
    {
      label: ['description_of_the_activity'],
      name: ['description_of_the_activity'],
      rules: [{ required: true }],
      input: <TextArea style={{ resize: 'none' }} />
    }
  ];

  return {
    fieldsInformations
  };
};
