import { EyeOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Badge, Flex, Tooltip, Typography } from 'antd';
import { displayDate } from './utils';
import { useAuthContext } from '../../../contexts/AuthContext';
import { urls } from '../ConversationsBoard/urlTranslations';

const { Paragraph } = Typography;

export const ConversationTitleClaim = ({ conversation }) => {
  const {
    unit_counter,
    programme_types,
    last_message_date,
    messages: itemMessages,
    claim_id,
    civil_liability_sub_programmes,
    construction_sub_programmes,
    transport_sub_programmes,
    fleet_sub_programmes
  } = conversation;
  const { user } = useAuthContext();
  const { t } = useTranslation();

  let containsUnread = false;

  containsUnread = itemMessages.some(
    (itemMessage) =>
      !!itemMessage?.unread?.length &&
      itemMessage.unread.some((unreadItem) => unreadItem === user._id)
  );

  return (
    <Flex justify="space-between" gap="small" align="center">
      <Typography>
        <Link
          style={{ margin: '0px', padding: '0px' }}
          to={`/claims/${
            urls[
              civil_liability_sub_programmes ||
                construction_sub_programmes ||
                transport_sub_programmes ||
                fleet_sub_programmes ||
                programme_types
            ]
          }/show/${claim_id}`}
        >
          <Tooltip title={t('messages.go_to_claim')}>
            <Paragraph
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                fontWeight: '600'
              }}
            >
              {`${unit_counter}`}
              <EyeOutlined style={{ fontSize: 18, paddingLeft: 10 }} />
            </Paragraph>
          </Tooltip>
        </Link>
        <Paragraph>{t(`messages.form.${programme_types}`)}</Paragraph>
      </Typography>
      <Flex gap="small" align="center">
        {containsUnread && <Badge color="red" />}
        <span className="conversation-date">
          {displayDate(last_message_date)}
        </span>
      </Flex>
    </Flex>
  );
};

ConversationTitleClaim.propTypes = {
  conversation: PropTypes.shape({
    last_message_date: PropTypes.string,
    unit_counter: PropTypes.string,
    programme_types: PropTypes.string,
    category: PropTypes.string,
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string
      })
    ),
    claim_id: PropTypes.string,
    civil_liability_sub_programmes: PropTypes.string,
    construction_sub_programmes: PropTypes.string,
    transport_sub_programmes: PropTypes.string,
    fleet_sub_programmes: PropTypes.string
  })
};

ConversationTitleClaim.defaultProps = {
  conversation: {
    last_message_date: '',
    unit_counter: '',
    programme_types: '',
    category: '',
    messages: [],
    claim_id: ''
  }
};
