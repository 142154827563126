import PropTypes from 'prop-types';
import { List, Avatar } from 'antd';
import { useConversationContext } from '../../../contexts/ConversationContext';
import { ConversationTitle } from './ConversationTitle';
import { ConversationTitleClaim } from './ConversationTitleClaim';
import { getClaimIcon } from './getClaimIcon';

/**
 * @component
 * @name ListConversationsItem
 * @description A single item of the list of conversations
 * @prop {Object} item The conversation object
 * @returns {jsx} jsx - ListConversationsItem component
 */
export const ListConversationsItem = ({ item, avatarUrl }) => {
  const {
    setSelectedConversation,
    selectedConversation,
    patchToReadConversation,
    filter,
    setIsThreadOpen
  } = useConversationContext();

  const { messages: itemMessages } = item;
  let programme_types;
  if (filter === 'claims') {
    ({ programme_types } = item);
  }

  const setClassname = ({ _id }) => {
    if (!selectedConversation) return 'conversation-item';
    if (selectedConversation && _id === selectedConversation._id)
      return 'conversation-item selected';
    return 'conversation-item';
  };

  // This function is used to display the last message in the item in the list of conversations
  const description = (messages) => {
    if (!messages.length) return '';
    const index = messages.length - 1;
    const content = messages[index].content;
    if (content.length > 100) {
      return `${content.substring(0, 50)}...`;
    }
    return content;
  };
  const handleListItemOnClick = (conversation, conversationType) => {
    setSelectedConversation(conversation);
    patchToReadConversation(conversation._id, conversationType);
    setIsThreadOpen(true);
  };

  return (
    <>
      {filter === 'collaborators' && (
        <List.Item
          onClick={() => handleListItemOnClick(item, filter)}
          className={setClassname(item)}
        >
          <List.Item.Meta
            avatar={<Avatar src={avatarUrl} />}
            title={<ConversationTitle conversation={item} />}
            description={description(itemMessages)}
          />
        </List.Item>
      )}
      {filter === 'claims' && (
        <List.Item
          onClick={() => handleListItemOnClick(item, filter)}
          className={setClassname(item)}
        >
          <List.Item.Meta
            avatar={
              <Avatar icon={getClaimIcon(programme_types)} size="large" />
            }
            title={<ConversationTitleClaim conversation={item} />}
            description={description(itemMessages)}
          />
        </List.Item>
      )}
    </>
  );
};

ListConversationsItem.propTypes = {
  item: PropTypes.shape({
    last_message_date: PropTypes.string.isRequired,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired
      })
    ).isRequired,
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired,
  avatarUrl: PropTypes.string
};

ListConversationsItem.defaultProps = {
  avatarUrl: ''
};
