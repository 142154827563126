import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Form } from 'antd';
import { DocumentsContext } from '../DocumentsContext';

const { Option } = Select;
const { Item } = Form;

/**
 * `SelectProgrammes` is a React component that presents a multi-select dropdown selector, allowing users
 * to choose multiple programme types. The available programme types are fetched from the `DocumentsContext`.
 *
 *
 * @component
 * @param {object} props - The properties passed to the component.
 * @param {string} props.dbKey - The database key corresponding to the selected value(s) for this dropdown.
 * @param {function} props.onSelect - A callback function that's invoked when one or more options are selected or deselected.
 *
 * @returns {JSX.Element} Returns a rendered multi-select dropdown selector for programme types.
 *
 * @example
 * <SelectProgrammes dbKey="programmeKey" onSelect={(selectedValues) => console.log("Selected programmes:", selectedValues)} />
 */

export const SelectProgrammes = ({ dbKey, onSelect, isDisabled }) => {
  const { enums } = useContext(DocumentsContext);
  const { t } = useTranslation();

  return (
    <Item name={[`${dbKey}`]}>
      <Select
        showSearch
        filterOption={(input, option) =>
          (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
        }
        onChange={(value) => onSelect && onSelect(value)}
        disabled={isDisabled}
      >
        {enums?.programme_types.map((programme_types) => (
          <Option key={programme_types} value={programme_types}>
            {t(`subscriptions.form.${programme_types}`)}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

SelectProgrammes.propTypes = {
  dbKey: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool
};

SelectProgrammes.defaultProps = {
  isDisabled: false
};
