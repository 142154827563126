import { Route, Routes } from 'react-router-dom';
import { ListFreightedGoods } from './ListFreightedGoods';
import { FreightedGoodsCreateUpdate } from './FreightedGoodsCreateUpdate';
import { TransportShow } from '../TransportShow';
import { Exception } from '../../../../components';

/**
 * `FreightedGoodsRouter` is a component responsible for defining the routing structure for Ad Valorem related pages.
 *
 * @component
 * @returns {JSX.Element} JSX elements representing the Ad Valorem router.
 */

export const FreightedGoodsRouter = () => (
  <Routes>
    <Route
      path="edit/:id"
      element={<FreightedGoodsCreateUpdate purpose="edit" />}
    />
    <Route
      path="create/:id"
      element={<FreightedGoodsCreateUpdate purpose="create" />}
    />
    <Route
      path="show/:id"
      element={<TransportShow transportType="freighted-goods" />}
    />
    <Route index element={<ListFreightedGoods />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
