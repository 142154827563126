import moment from 'moment';

export const useListContent = (data = {}) => {
  const {
    name,
    creation_date,
    legal_status,
    siren,
    unit_manager,
    unit_support,
    number,
    street,
    city,
    zip_code
  } = data;

  return [
    {
      label: 'companies.form.name',
      span: 1,
      content: name || '-'
    },
    {
      label: 'companies.form.creation_date',
      span: 1,
      content:
        (creation_date && moment(creation_date).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'companies.form.legal_status',
      span: 1,
      content: legal_status || '-'
    },
    {
      label: 'companies.form.siren',
      span: 1,
      content: siren || '-'
    },
    {
      label: 'companies.form.address',
      span: 2,
      content: `${number || '-'} ${street || '-'} ${city || '-'} ${
        zip_code || '-'
      }`
    },
    {
      label: 'companies.form.unit_manager',
      span: 1,
      content: unit_manager
        ? `${unit_manager.first_name} ${unit_manager.last_name}`
        : '-'
    },
    {
      label: 'companies.form.unit_support',
      span: 1,
      content: unit_support
        ? `${unit_support.first_name} ${unit_support.last_name}`
        : '-'
    }
  ];
};
