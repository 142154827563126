import React, { useState, createContext, useEffect } from 'react';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * Context to manage Other Vehicles.
 * @constant {Object} OtherVehiclesContext
 */

export const OtherVehiclesContext = createContext();

/**
 * Provider for the OtherVehiclesContext.
 * @param {Object} props - The component properties.
 * @param {JSX.Element} props.children - The children elements to be rendered.
 * @returns {JSX.Element} The rendered component.
 */

export const OtherVehiclesContextProvider = ({ children }) => {
  const [currentContract, setCurrentContract] = useState();
  const [currentEntityContext, setCurrentEntityContext] = useState();
  const [entitiesContext, setEntitiesContext] = useState([]);

  const [enums, setEnums] = useState([]);
  const [contractId, setContractId] = useState('');
  const [forceRefresh, setForceRefresh] = useState(false);
  const [contracts, setContracts] = useState([]);
  const { dispatchAPI, company } = useAuthContext();
  const { message } = useErrorMessage();

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/vehicles/enums`
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getcontractId = async () => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: `/contracts/number/companies/${company}`,
        body: {
          programme_types: 'FLEET',
          sub_programme: 'OTHER_VEHICLE'
        }
      });
      setContractId(data[0]._id);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
      await getcontractId();
    })();
  }, []);

  return (
    <OtherVehiclesContext.Provider
      value={{
        enums,
        forceRefresh,
        setForceRefresh,
        contracts,
        setContracts,
        contractId,
        currentEntityContext,
        setCurrentEntityContext,
        entitiesContext,
        setEntitiesContext,
        currentContract,
        setCurrentContract
      }}
    >
      {children}
    </OtherVehiclesContext.Provider>
  );
};
