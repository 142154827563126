import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Input, DatePicker, Select } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { SelectManager } from '../../../components/SelectManager';
import { SelectContract } from '../../../components/SelectContract';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { SelectYesNo } from './SelectYesNo';
import { EventCancellationContext } from '../EventCancellationContext';
import { SelectEntityV2 } from '../../../components/SelectEntityV2';
import { SelectSiteV2 } from '../../../components/SelectSiteV2';

const { TextArea } = Input;
const { Option } = Select;

/**
 * `useFields` is a custom hook that provides fields for forms and manages their associated states.
 *
 * @returns
 * @property {Array} fields - An array of field configurations for the form.
 * @property {boolean} isFieldsLoading - A flag to determine whether the form fields are being loaded.
 * @hook
 */

const useFields = (
  isStageWithRoof,
  setIsStageWithRoof,
  isThreeSidesClosed,
  setIsThreeSidesClosed,
  isFireWork,
  setIsFireWork,
  isRanAsExpected,
  setIsRanAsExpected,
  isEssentialPersonUnavailability,
  setIsEssentialPersonUnavailability,
  isGuarded,
  setIsGuarded,
  isPermanentInstallationWithRoof,
  setIsPermanentInstallationWithRoof,
  isAStageUsed,
  setIsAStageUsed,
  isRanOutdoors,
  setIsRanOutdoors,
  isInstallation,
  setIsInstallation,
  currentDates,
  setCurrentDates,
  form
) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [enums, setEnums] = useState({});

  const {
    event_start_date,
    installation_start_date,
    holding_start_date,
    unInstallation_start_date,
    possible_postponement_start_date
  } = currentDates;

  const {
    currentEntityContext,
    setCurrentEntityContext,
    entitiesContext,
    setEntitiesContext,
    currentContract,
    setCurrentContract
  } = useContext(EventCancellationContext);

  const dateFormat = 'DD-MM-YYYY';

  const handleDateChange = (dateToDefine) => (date, dateString) => {
    setCurrentDates({
      ...currentDates,
      [dateToDefine]: dateString
    });
  };

  const handleEntityChange = (value) => {
    setCurrentEntityContext(value);
    form.setFieldsValue({ site: undefined });
  };

  const handleContractChange = () => {
    form.setFieldsValue({ entity: undefined, site: undefined });
  };

  if (!isRanOutdoors) {
    form.setFieldsValue({
      is_event_ran_outdoors_withpermanent_installation: 'N/A',
      is_a_stage_used: 'N/A',
      is_a_stage_with_roof: 'N/A',
      is_a_stage_facing_dominant_winds: 'N/A',
      is_a_stage_with_three_closed_sides: 'N/A',
      bad_weather_specific_clauses: 'N/A'
    });
  }

  if (!isPermanentInstallationWithRoof) {
    form.setFieldsValue({
      permanent_installation_type_if_permanent_installation_with_roof: 'N/A'
    });
  } else {
    form.setFieldsValue({
      permanent_installation_type_if_permanent_installation_with_roof: ''
    });
  }

  if (!isStageWithRoof || !isThreeSidesClosed) {
    form.setFieldsValue({
      bad_weather_specific_clauses: 'N/A'
    });
  }

  if (!isFireWork) {
    form.setFieldsValue({
      operator_name: 'N/A'
    });
  }

  if (!isAStageUsed) {
    form.setFieldsValue({
      is_a_stage_with_roof: 'N/A',
      is_a_stage_with_three_closed_sides: 'N/A',
      bad_weather_specific_clauses: 'N/A'
    });
  }

  if (!isGuarded) {
    form.setFieldsValue({
      if_guarded_guarding_means: 'N/A'
    });
  }

  if (!isInstallation) {
    form.setFieldsValue({
      installation_start_date: null,
      installation_end_date: null,
      unInstallation_start_date: null,
      unInstallation_end_date: null
    });
  }

  const fieldsEventCancellation = [
    {
      label: ['contract_number'],
      name: ['contract'],
      rules: [{ required: true }],
      input: (
        <SelectContract
          dbKey="contract"
          programme_types="EVENT_CANCELLATION"
          setCurrentContract={setCurrentContract}
          currentEntityContext={currentEntityContext}
          handleContractChange={handleContractChange}
        />
      ),
      startWithDivider: {
        title: t('event_cancellations.form.main_informations')
      }
    },
    {
      label: ['customer_manager'],
      name: ['customer_manager'],
      rules: [{ required: true }],
      input: (
        <SelectManager
          dbKey="customer_manager"
          setIsFieldsLoading={setIsFieldsLoading}
        />
      )
    },
    {
      label: ['unit_manager'],
      name: ['unit_manager'],
      input: (
        <SelectManager
          dbKey="unit_manager"
          setIsFieldsLoading={setIsFieldsLoading}
        />
      )
    },
    {
      label: ['desired_effective_date'],
      name: ['desired_effective_date'],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['entity'],
      name: ['entity'],
      rules: [{ required: true }],
      input: (
        <SelectEntityV2
          dbKey="entity"
          permKey="programme-EVENT_CANCELLATION"
          currentContract={currentContract}
          setEntitiesContext={setEntitiesContext}
          handleEntityChange={handleEntityChange}
          currentEntityContext={currentEntityContext}
        />
      )
    },
    {
      label: ['site'],
      name: ['site'],
      rules: [{ required: true }],
      input: (
        <SelectSiteV2
          dbKey="site"
          permKey="programme-EVENT_CANCELLATION"
          currentEntityContext={currentEntityContext}
          entitiesContext={entitiesContext}
        />
      )
    },
    {
      label: ['company_involved_name'],
      name: ['company_involved_name'],
      rules: [{ required: true }]
    },
    {
      label: ['company_registration_number'],
      name: ['company_registration_number'],
      rules: [{ required: true }]
    },
    {
      label: ['company_address'],
      name: ['company_address'],
      rules: [{ required: true }]
    },
    {
      label: ['postal_code'],
      name: ['postal_code'],
      rules: [{ required: true }]
    },
    {
      label: ['city'],
      name: ['city'],
      rules: [{ required: true }]
    },
    {
      label: ['country'],
      name: ['country'],
      rules: [{ required: true }],
      input: (
        <Select
          placeholder={t('golfs.form.select_a_country')}
          style={{ width: '100%' }}
          showSearch
          filterOption={(input, option) =>
            (option?.title ?? '').toLowerCase().includes(input.toLowerCase())
          }
        >
          {(enums?.countries || []).map((c) => (
            <Option key={c} value={c} title={c}>
              {c}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: ['event_name'],
      name: ['event_name'],
      rules: [{ required: true }]
    },
    {
      label: ['event_type'],
      name: ['event_type'],
      rules: [{ required: true }]
    },
    {
      label: ['event_start_date'],
      name: ['event_start_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          format={dateFormat}
          style={{ width: '100%' }}
          onChange={handleDateChange('event_start_date')}
        />
      )
    },
    {
      label: ['event_end_date'],
      name: ['event_end_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          format={dateFormat}
          style={{ width: '100%' }}
          minDate={dayjs(event_start_date, dateFormat)}
          disabled={!event_start_date}
        />
      )
    },
    {
      label: ['installation'],
      name: ['installation'],
      rules: [{ required: true }],
      input: <SelectYesNo dbKey="installation" setState={setIsInstallation} />
    },
    {
      label: ['installation_start_date'],
      name: ['installation_start_date'],
      rules: [{ required: isInstallation }],
      hidden: !isInstallation,
      input: (
        <DatePicker
          format={dateFormat}
          style={{ width: '100%' }}
          onChange={handleDateChange('installation_start_date')}
        />
      )
    },
    {
      label: ['installation_end_date'],
      name: ['installation_end_date'],
      rules: [{ required: isInstallation }],
      hidden: !isInstallation,
      input: (
        <DatePicker
          format={dateFormat}
          style={{ width: '100%' }}
          minDate={dayjs(installation_start_date, dateFormat)}
          disabled={!installation_start_date}
        />
      )
    },
    {
      label: ['holding_start_date'],
      name: ['holding_start_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          format={dateFormat}
          style={{ width: '100%' }}
          onChange={handleDateChange('holding_start_date')}
        />
      )
    },
    {
      label: ['holding_end_date'],
      name: ['holding_end_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          format={dateFormat}
          style={{ width: '100%' }}
          minDate={dayjs(holding_start_date, dateFormat)}
          disabled={!holding_start_date}
        />
      )
    },
    {
      label: ['unInstallation_start_date'],
      name: ['unInstallation_start_date'],
      rules: [{ required: isInstallation }],
      hidden: !isInstallation,
      input: (
        <DatePicker
          format={dateFormat}
          style={{ width: '100%' }}
          onChange={handleDateChange('unInstallation_start_date')}
        />
      )
    },
    {
      label: ['unInstallation_end_date'],
      name: ['unInstallation_end_date'],
      rules: [{ required: isInstallation }],
      hidden: !isInstallation,
      input: (
        <DatePicker
          format={dateFormat}
          style={{ width: '100%' }}
          minDate={dayjs(unInstallation_start_date, dateFormat)}
          disabled={!unInstallation_start_date}
        />
      )
    },
    {
      label: ['is_event_ran_in_permanent_installation_with_roof'],
      name: ['is_event_ran_in_permanent_installation_with_roof'],
      rules: [{ required: true }],
      input: (
        <SelectYesNo
          dbKey="is_event_ran_in_permanent_installation_with_roof"
          setState={setIsPermanentInstallationWithRoof}
        />
      )
    },
    {
      label: [
        'permanent_installation_type_if_permanent_installation_with_roof'
      ],
      name: ['permanent_installation_type_if_permanent_installation_with_roof'],
      rules: [{ required: isPermanentInstallationWithRoof }],
      input: <Input disabled={!isPermanentInstallationWithRoof} />
    },
    {
      label: ['is_event_ran_outdoors'],
      name: ['is_event_ran_outdoors'],
      rules: [{ required: true }],
      input: (
        <SelectYesNo
          dbKey="is_event_ran_outdoors"
          setState={setIsRanOutdoors}
        />
      )
    },
    {
      label: ['is_event_ran_outdoors_withpermanent_installation'],
      name: ['is_event_ran_outdoors_withpermanent_installation'],
      rules: [{ required: isRanOutdoors }],
      input: (
        <SelectYesNo
          dbKey="is_event_ran_outdoors_withpermanent_installation"
          disabled={!isRanOutdoors}
        />
      )
    },
    {
      label: ['is_a_stage_used'],
      name: ['is_a_stage_used'],
      rules: [{ required: isRanOutdoors }],
      input: (
        <SelectYesNo
          dbKey="is_a_stage_used"
          setState={setIsAStageUsed}
          disabled={!isRanOutdoors}
          defaultValue="NO"
        />
      )
    },
    {
      label: ['is_a_stage_with_roof'],
      name: ['is_a_stage_with_roof'],
      rules: [{ required: isAStageUsed && isRanOutdoors }],
      input: (
        <SelectYesNo
          dbKey="is_a_stage_with_roof"
          setState={setIsStageWithRoof}
          disabled={!isAStageUsed || !isRanOutdoors}
        />
      )
    },
    {
      label: ['is_a_stage_with_three_closed_sides'],
      name: ['is_a_stage_with_three_closed_sides'],
      rules: [{ required: isAStageUsed && isRanOutdoors }],
      input: (
        <SelectYesNo
          dbKey="is_a_stage_with_three_closed_sides"
          setState={setIsThreeSidesClosed}
          disabled={!isAStageUsed || !isRanOutdoors}
        />
      )
    },
    {
      label: ['is_a_stage_facing_dominant_winds'],
      name: ['is_a_stage_facing_dominant_winds'],
      rules: [{ required: isAStageUsed && isRanOutdoors }],
      input: (
        <SelectYesNo
          dbKey="is_a_stage_facing_dominant_winds"
          disabled={!isAStageUsed || !isRanOutdoors}
        />
      )
    },
    {
      label: ['is_special_equipment_used_including_fireworks'],
      name: ['is_special_equipment_used_including_fireworks'],
      rules: [{ required: true }],
      input: (
        <SelectYesNo
          dbKey="is_special_equipment_used_including_fireworks"
          setState={setIsFireWork}
        />
      )
    },
    {
      label: ['operator_name'],
      name: ['operator_name'],
      rules: [{ required: isFireWork }],
      input: <Input disabled={!isFireWork} />
    },
    {
      label: ['attendance_capacity_or_expected_attendance'],
      name: ['attendance_capacity_or_expected_attendance'],
      rules: [{ required: true }],
      input: <Input type="number" />
    },
    {
      label: ['is_event_location_guarded'],
      name: ['is_event_location_guarded'],
      rules: [{ required: true }],
      input: (
        <SelectYesNo
          dbKey="is_event_location_guarded"
          setState={setIsGuarded}
        />
      )
    },
    {
      label: ['if_guarded_guarding_means'],
      name: ['if_guarded_guarding_means'],
      rules: [{ required: isGuarded }],
      input: <Input disabled={!isGuarded} />
    },
    {
      label: ['amount_engaged'],
      name: ['amount_engaged'],
      rules: [{ required: true }],
      input: (
        <Input
          type="Number"
          addonAfter="€"
          controls={false}
          onChange={(e) => {
            const amountEngaged = Number(e.target.value);
            const publicSubsidies = Number(
              form.getFieldValue('public_subsidies_to_substract') || 0
            );
            form.setFieldsValue({
              total_guarantee_amount: amountEngaged - publicSubsidies
            });
          }}
        />
      )
    },
    {
      label: ['public_subsidies_to_substract'],
      name: ['public_subsidies_to_substract'],
      input: (
        <Input
          type="Number"
          defaultValue={0}
          addonAfter="€"
          controls={false}
          onChange={(e) => {
            const publicSubsidies = Number(e.target.value);
            const amountEngaged = Number(
              form.getFieldValue('amount_engaged') || 0
            );
            form.setFieldsValue({
              total_guarantee_amount: amountEngaged - publicSubsidies
            });
          }}
        />
      )
    },
    {
      label: ['total_guarantee_amount'],
      name: ['total_guarantee_amount'],
      input: <Input type="Number" addonAfter="€" disabled />
    },
    {
      label: ['estimated_income'],
      name: ['estimated_income'],
      rules: [{ required: true }],
      input: <Input type="Number" addonAfter="€" />,
      endWithDivider: {
        title: t('event_cancellations.form.event_location')
      }
    },
    {
      label: ['event_location_city'],
      name: ['event_location_city'],
      rules: [{ required: true }]
    },
    {
      label: ['event_location_postal_code'],
      name: ['event_location_postal_code'],
      rules: [{ required: true }]
    },
    {
      label: ['event_location_address'],
      name: ['event_location_address'],
      rules: [{ required: true }]
    },
    {
      label: ['event_location_addresses_additional'],
      name: ['event_location_addresses_additional'],
      input: (
        <TextArea
          autoSize={{
            minRows: 1,
            maxRows: 50
          }}
        />
      ),
      endWithDivider: {
        title: t(
          'event_cancellations.form.if_delay_fallback_postponement_possible'
        )
      }
    },
    {
      label: ['possible_postponement_start_date'],
      name: ['possible_postponement_start_date'],
      input: (
        <DatePicker
          format={dateFormat}
          style={{ width: '100%' }}
          onChange={handleDateChange('possible_postponement_start_date')}
        />
      )
    },
    {
      label: ['possible_postponement_end_date'],
      name: ['possible_postponement_end_date'],
      input: (
        <DatePicker
          format={dateFormat}
          style={{ width: '100%' }}
          minDate={dayjs(possible_postponement_start_date, dateFormat)}
          disabled={!possible_postponement_start_date}
        />
      ),
      endWithDivider: {
        title: t(
          'event_cancellations.form.basic_guarantees_premises_equipments_other_unavailability'
        )
      }
    },
    {
      label: ['bad_weather_specific_clauses'],
      name: ['bad_weather_specific_clauses'],
      input: (
        <SelectYesNo
          dbKey="bad_weather_specific_clauses"
          disabled={
            !isStageWithRoof ||
            !isThreeSidesClosed ||
            !isAStageUsed ||
            !isRanOutdoors
          }
          defaultValue="NO"
        />
      ),
      tooltip: {
        title: t(
          'event_cancellations.form.tooltip.bad_weather_specific_clauses_requirements'
        ),
        icon: <InfoCircleOutlined />
      }
    },
    {
      label: ['essential_person_unavailability_specific_clauses'],
      name: ['essential_person_unavailability_specific_clauses'],
      input: (
        <SelectYesNo
          dbKey="essential_person_unavailability_specific_clauses"
          setState={setIsEssentialPersonUnavailability}
        />
      )
    },
    {
      label: ['if_essential_person_unavailable_details'],
      name: ['if_essential_person_unavailable_details'],
      rules: [{ required: isEssentialPersonUnavailability }],
      input: <TextArea rows={4} disabled={!isEssentialPersonUnavailability} />
    },
    {
      label: ['previous_event_organisation_dates'],
      name: ['previous_event_organisation_dates'],
      input: <TextArea rows={4} />,
      startWithDivider: {
        title: t('event_cancellations.form.if_event_ran_before')
      }
    },
    {
      label: ['ran_as_expected'],
      name: ['ran_as_expected'],
      input: (
        <SelectYesNo dbKey="ran_as_expected" setState={setIsRanAsExpected} />
      )
    },
    {
      label: ['if_not_ran_as_expected_reasons'],
      name: ['if_not_ran_as_expected_reasons'],
      rules: [{ required: !isRanAsExpected }]
    },
    {
      label: ['company_insuring_previous_events'],
      name: ['company_insuring_previous_events'],
      rules: [{ required: !isRanAsExpected }]
    },
    {
      label: ['previous_events_claim_types'],
      name: ['previous_events_claim_types'],
      rules: [{ required: !isRanAsExpected }],
      input: <TextArea rows={4} />
    },
    {
      label: ['previous_events_claim_amounts'],
      name: ['previous_events_claim_amounts'],
      rules: [{ required: !isRanAsExpected }],
      input: <Input type="Number" addonAfter="€" />,
      endWithDivider: {
        title: t('event_cancellations.form.documents')
      }
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/event-cancellations/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(false);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fieldsEventCancellation,
    isFieldsLoading
  };
};
export default useFields;
