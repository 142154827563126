// TODO: Uncomment for recette
import { useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { outOfNavRoutes, routes } from '../utils/constants/adminRoutes';
import { Login } from '../routes/login';
import { NotificationDashboard } from '../routes/home/NotificationDashboard';
import { Exception } from '../components';
import { useAuthContext } from '../contexts/AuthContext';
import RequireAuth from './RequireAuth';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { Settings } from '../routes/settings/settings';
// import { WorkflowRouter } from '../routes/workflows/WorkflowRouter';
import { DocumentRouter } from '../routes/documents/DocumentRouter';
import { SubscriptionRouter } from '../routes/subscriptions/SubscriptionRouter';
import { CompanyRouter } from '../routes/companies/CompanyRouter';
import { ContactsRouter } from '../routes/contacts/ContactsRouter';
import { ClaimsRouter } from '../routes/claims/ClaimsRouter';
import { ProgrammesRouter } from '../routes/programmes/ProgrammesRouter';
import { LegalNotice } from '../routes/legal-notice/LegalNotice';
import { AdminTheme } from '../assets/styles/Theme/AdminTheme';
import { AgricultureCreateUpdate } from '../routes/agriculture/AgricultureCreateUpdate';
import { MessagesRouter } from '../routes/messages/MessagesRouter';
import InterruptionGolf from '../routes/interruption-golf/Interruption-golf';
// import { TemplateDocRouter } from '../routes/template-docs/TemplateDocsRouter';

const PrivateRoute = ({ children }) => {
  const { isPermitted } = useAuthContext();
  const location = window.location.pathname;

  if (
    location.startsWith('/programmes/properties/edit') ||
    location.startsWith('/programmes/light-vehicles/edit') ||
    location.startsWith('/programmes/other-vehicles/edit') ||
    location.startsWith('/programmes/heavy-vehicles/edit')
  ) {
    return children;
  }

  return !isPermitted ? children : <Navigate to="/" />;
};

export const GestionnaitreUnitRouter = () => {
  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'admin';
    }
  });

  return (
    <BrowserRouter>
      <AdminTheme />
      <Routes>
        <Route
          path={`${outOfNavRoutes.AGRICULTURE}/*`}
          element={<AgricultureCreateUpdate purpose="create" />}
        />
        <Route
          path={`${outOfNavRoutes.INTERRUPTION_GOLF}/*`}
          element={<InterruptionGolf purpose="create" />}
        />
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route element={<RequireAuth />}>
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          <Route path={`${outOfNavRoutes.LEGAL}/*`} element={<LegalNotice />} />
          <Route path={outOfNavRoutes.SETTINGS} element={<Settings />} />
          <Route path={routes.HOME} element={<NotificationDashboard />} />
          <Route
            path={`${routes.PROGRAMMES}/*`}
            element={
              <PrivateRoute>
                <ProgrammesRouter />
              </PrivateRoute>
            }
          />
          <Route
            path={`${routes.DOCUMENTS}/*`}
            element={
              <PrivateRoute>
                <DocumentRouter />
              </PrivateRoute>
            }
          />
          <Route
            path={`${routes.SUBSCRIPTIONS}/*`}
            element={<SubscriptionRouter />}
          />
          <Route path={`${routes.CLAIMS}/*`} element={<ClaimsRouter />} />
          <Route
            path={`${routes.COMPANIES}/*`}
            element={
              <PrivateRoute>
                <CompanyRouter />
              </PrivateRoute>
            }
          />
          <Route path={`${routes.MESSAGES}/*`} element={<MessagesRouter />} />
          <Route path={`${routes.CONTACTS}/*`} element={<ContactsRouter />} />
          {/* <Route path={`${routes.WORKFLOWS}/*`} element={<WorkflowRouter />} />
          <Route
            path={`${routes.TEMPLATE_DOCS}/*`}
            element={<TemplateDocRouter />}
          /> */}
          <Route path={`${routes.CONFIGURATIONS}`} element={<Settings />} />
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
