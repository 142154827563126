import PropTypes from 'prop-types';
import { useFields } from '../fieldsTransportSuscribed';
import { TransportsCreateUpdate } from '../TransportCreateUpdate';

/**
 * Provides a component for creating or updating own accounts insurance claims.
 *
 * @component
 * @param {object} props - React component props.
 * @param {string} props.purpose - The purpose of the component, either "create" or "edit".
 * @returns {React.ReactNode} The CreateUpdateContainer component with specific configurations for own accounts insurance claims.
 */
export const OwnAccountsCreateUpdate = ({ purpose }) => (
  <TransportsCreateUpdate
    purpose={purpose}
    useFields={useFields}
    mandatoryDocuments={['full_statement']}
    populate="entity,site,contract,company_Id,entity"
    sub_programme="OWN_ACCOUNT"
  />
);

OwnAccountsCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
