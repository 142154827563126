import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useFields } from './fieldsMachineryBreakageClaims';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useHistory } from '../../../utils/historyUtils';

/**
 * Provides a component for creating or updating machinery breakage claims.
 *
 * @component
 * @param {object} props - React component props.
 * @param {string} props.purpose - The purpose of the component, either "create" or "update".
 * @returns {React.ReactNode} The CreateUpdateContainer component with specific configurations for golf claims.
 */
export const MachineryBreakageClaimsfCreateUpdate = ({ purpose }) => {
  const { dispatchAPI, company } = useAuthContext();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const [machine, setMachine] = useState();
  const [isOther, setIsOther] = useState(false);
  const [isComplaint, setIsComplaint] = useState(false);
  const [machineSerialNumber, setMachineSerialNumber] = useState('');
  const { setInitialHistory, createHistory, updateHistory } = useHistory();
  const { fieldsInformations } = useFields(
    isOther,
    setIsOther,
    setIsComplaint,
    machineSerialNumber
  );

  const draggerFilesKeysOverRide = [
    'full_statement',
    'estimate',
    'filing_complaint'
  ];
  const mandatoryDocuments = isComplaint
    ? ['full_statement', 'filing_complaint']
    : ['full_statement'];

  const getMachine = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `machinery-breakages/${id}?populate=entity,site,contract,company`
      });
      setMachine(data);
      setMachineSerialNumber(data?.machinery_serial_number);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getMachine();
    })();
  }, [id]);

  const config = {
    onGetResource: {
      setFields: (data) => {
        if (data.claim_type === 'OTHER') {
          setIsOther(true);
        }
        setInitialHistory(data);
        return {
          ...data,
          sinister_date: data.sinister_date && dayjs(data.sinister_date)
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        entry_id: machine?._id,
        company,
        opening_date: dayjs(),
        machinery_serial_number: machine?.machinery_serial_number,
        city: machine?.city,
        entity: machine?.entity?._id,
        site: machine?.site?._id,
        programme_types: 'MACHINERY_BREAKAGE',
        ...createHistory('claim', 'machinery-breakages', id)
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        claim_type_if_other:
          data.claim_type === 'OTHER' ? data.claim_type_if_other : null,
        ...updateHistory('claim')
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fieldsInformations}
      purpose={purpose}
      baseUrl="machinery_breakage_claims"
      resource="claims"
      config={config}
      populate="?populate=documents.file"
      draggerFilesKeysOverRide={draggerFilesKeysOverRide}
      mandatoryDocuments={mandatoryDocuments}
    />
  );
};

MachineryBreakageClaimsfCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
