import { Checkbox } from 'antd';

/**
 * A custom hook that returns predefined field configurations.
 *
 * This configuration can be used in conjunction with form libraries or frameworks
 * that need a set of field definitions to render and validate form controls.
 *
 * @hook
 * @returns {Array<Object>} returns.golfFields - Field configurations for golfs.
 */

export const useGolfsFields = () => {
  const fieldNames = [
    'missing_documents',
    'company_name',
    'contract',
    'validity_start_date',
    'validity_end_date',
    'entity',
    'site',
    'golf_name',
    'address',
    'postal_code',
    'city',
    'country',
    'total_insured_developed_area',
    'workforce_full_time_equivalent',
    'company_registration_number',
    'content',
    'previous_year_revenue',
    'date_added',
    'deletion_date_effect'
  ];

  const golfFields = fieldNames.map((name) => ({
    name: [name],
    rules: [{ required: false }],
    valuePropName: 'checked',
    input: <Checkbox />
  }));

  return { golfFields };
};
