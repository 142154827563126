import { useCallback } from 'react';
// import dayjs from 'dayjs';

/**
 * Custom hook that returns the configuration objects for getting and creating resources.
 *
 * @param {function} dispatch - The dispatch function from the Redux store.
 * @param {string} company - The company ID.
 * @param {string} program - The program type.
 * @param {string} subProgram - The sub-program type.
 * @returns {object} - The configuration objects for getting and creating resources.
 */

export const useConfig = (company, program) => {
  // const onGetResource = {
  //   setFields: useCallback((data) => {
  //     const assemblyTestingInsurance = data?.assembly_testing_insurance;
  //     return {
  //       ...data,
  //       assembly_testing_insurance: {
  //         ...assemblyTestingInsurance,
  //         desired_effective_date:
  //           assemblyTestingInsurance.desired_effective_date &&
  //           dayjs(assemblyTestingInsurance.desired_effective_date)
  //       }
  //     };
  //   }, [])
  // };
  const onCreateResource = {
    setBody: useCallback(
      (data) => ({
        ...data,
        company_id: company,
        programme_types: program
      }),
      [company, program]
    )
  };
  return {
    // onGetResource,
    onCreateResource
  };
};
