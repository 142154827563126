import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { Select, Form } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const { Option } = Select;
const { Item } = Form;

export const MultiSelectEntity = ({ dbKey, isDisabled }) => {
  const [entities, setEntities] = useState([]);
  const { company, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const id = company;

  const getEntities = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/entities/company/${id}`
      });
      setEntities(data);
    } catch (e) {
      message(e);
    }
  }, [id]);

  useEffect(() => {
    (async () => {
      await getEntities();
    })();
  }, [getEntities]);

  return (
    <Item name={[`${dbKey}`]}>
      <Select
        disabled={isDisabled}
        showSearch
        filterOption={(inputValue, option) =>
          (option?.title ?? '').toLowerCase().includes(inputValue.toLowerCase())
        }
        mode="multiple"
      >
        {entities &&
          entities.map((e) => (
            <Option key={e._id} value={e._id} title={e.name}>
              {e.name}
            </Option>
          ))}
      </Select>
    </Item>
  );
};

MultiSelectEntity.propTypes = {
  dbKey: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool
};

MultiSelectEntity.defaultProps = {
  isDisabled: false
};
