import { useState, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Row, notification } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useGenerateFormItem } from '../../utils/generateFormItem/generateFormItem';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../utils/constants/formLayout';
import { PageHeaderCustom } from '../../components/PageHeader/PageHeader';
import { ContentCustom } from '../../components/ContentCustom/ContentCustom';
import { checkMandatoryDocuments } from '../../components/CreateUpdateContainerV3/utils/checkMandatoryDocuments';
import { ErrorModal } from '../../components/ErrorModal/ErrorModal';
import { agricultureUtils } from './utils/utils';

/**
 * CreateUpdateContainerFormListWithInnerFileManager component.
 * Renders a form with file manager for creating or updating a container.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.fields - The fields to render in the form.
 * @param {string} props.purpose - The purpose of the form (create or update).
 * @param {string} props.resource - The resource name.
 * @param {Object} props.config - The configuration object.
 * @param {Object} props.form - The form instance.
 * @param {Array} props.mandatoryDocuments - The list of mandatory documents.
 * @param {Function} props.setStep - The function to set the current step.
 * @param {React.Ref} ref - The ref object.
 * @returns {JSX.Element} The rendered component.
 */
export const CreateUpdateContainerFormListWithInnerFileManager = forwardRef(
  (
    { fields, purpose, resource, config, form, mandatoryDocuments, setStep },
    ref
  ) => {
    const { t } = useTranslation();
    const { message } = useErrorMessage();
    const { dispatchAPI } = useAuthContext();
    const [isOpen, setIsOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [draggerFilesList, setDraggerFilesList] = useState([]);
    const [filesConfiguration, setFilesConfiguration] = useState([]);
    const { onCreateResource } = config;
    const navigate = useNavigate();
    const { flattenObject } = agricultureUtils();

    const createResource = async (body, files) => {
      setIsSubmitting(true);
      const formData = new FormData();

      formData.append(
        'filesConfiguration',
        JSON.stringify(flattenObject(filesConfiguration))
      );
      flattenObject(files).forEach((file) => {
        formData.append(`documents`, file);
      });

      const values =
        onCreateResource && onCreateResource.setBody
          ? onCreateResource.setBody(body)
          : body;

      formData.append(
        'values',
        JSON.stringify({
          ...values
        })
      );

      try {
        await dispatchAPI('POST', {
          url: `/agricultures`,
          body: formData
        });
        notification.success({ message: t('agriculture.messages.success') });
        setIsSubmitting(false);
        setStep(1);
      } catch (e) {
        setIsSubmitting(false);
        message(e);
      }
      setIsSubmitting(false);
    };

    const handleSubmit = async (values) => {
      const mandatoryDocumentsArePresent = checkMandatoryDocuments(
        mandatoryDocuments,
        filesConfiguration
      );

      switch (true) {
        case !mandatoryDocumentsArePresent:
          return setIsOpen(true);
        default:
          await createResource(values, draggerFilesList);
      }

      return true;
    };

    const generateFieldsMemoized = useGenerateFormItem();

    return (
      <>
        <PageHeaderCustom
          title={t(`${resource}.form.title.${purpose}`)}
          noDynamiqueRoute
          noReturnButton
        />
        <ContentCustom style={{ minHeight: '100vh' }}>
          <Form
            ref={ref}
            onFinish={handleSubmit}
            form={form}
            className="agriculture-create-update"
            {...formItemLayout}
          >
            <>
              {fields(
                draggerFilesList,
                setDraggerFilesList,
                filesConfiguration,
                setFilesConfiguration
              ).map((field) => generateFieldsMemoized(resource, field))}
            </>
            <Form.Item {...tailFormItemLayout}>
              <Row justify="end">
                <Button
                  style={{ margin: '0 8px' }}
                  onClick={() => navigate('/')}
                  danger
                >
                  {`${t('buttons.cancel')} `}
                  <CloseOutlined />
                </Button>
                <Button type="primary" htmlType="submit" loading={isSubmitting}>
                  {`${t('buttons.save')} `}
                  <CheckOutlined />
                </Button>
              </Row>
            </Form.Item>
          </Form>
          <ErrorModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title={t('errors.message.missing_mandatory_documents')}
            details={mandatoryDocuments}
            tradKey="files.keys"
          />
        </ContentCustom>
      </>
    );
  }
);

CreateUpdateContainerFormListWithInnerFileManager.propTypes = {
  fields: PropTypes.func.isRequired,
  purpose: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  config: PropTypes.shape({
    onCreateResource: PropTypes.shape({
      setBody: PropTypes.func.isRequired
    })
  }),
  form: PropTypes.shape({}).isRequired,
  mandatoryDocuments: PropTypes.shape([]),
  setStep: PropTypes.func.isRequired
};

CreateUpdateContainerFormListWithInnerFileManager.defaultProps = {
  mandatoryDocuments: [],
  config: {}
};
