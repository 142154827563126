// Main nav menuItems
// TODO: Commented routes for staging will be placed in dev router
export const routes = {
  HOME: '/',
  PROGRAMMES: '/programmes',
  CLAIMS: '/claims',
  SUBSCRIPTIONS: '/subscriptions',
  DOCUMENTS: '/documents',
  CONTACTS: '/contacts',
  CONFIGURATIONS: '/configurations',
  USERS: '/users',
  COMPANIES: '/companies',
  CONTRIBUTIONS: '/contributions',
  MAILS: '/mail'
  // MESSAGES: '/messages'
  // FLEETDATASANALYSIS: '/fleet-data-analysis'
  // WORKFLOWS: '/workflows',
  // TEMPLATE_MAILS: '/template'
  // TEMPLATE_DOCS: '/template-docs',
};

// Main nav subMenuItems
export const subRoutes = {};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  LEGAL: '/legal-notice',
  MAIL_IN_BROWSER: '/mail-in-browser',
  AGRICULTURE: '/agriculture',
  INTERRUPTION_GOLF: '/interruption-golf'
};

// url search parameters
export const pathSearches = {
  MAILS: '?s=-created_at',
  SUBSCRIPTIONS: '?s=-request_date'
};
