import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row, Form, Input } from 'antd';
import SignaturePad from 'react-signature-canvas';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';

const { Item } = Form;

/**
 * Component for creating or updating signatures.
 * @component
 * @param {Object} props - The component's properties.
 * @param {string} props.dbKey - The key of the field in the database.
 * @param {number|string} props.canvasWidth - The width of the canvas.
 * @param {number|string} props.canvasHeight - The height of the canvas.
 * @param {string} props.signatureId - The ID of the signature.
 * @param {Function} props.setSignatureId - The function to set the signature ID.
 * @returns {JSX.Element} The JSX element for creating or updating signatures.
 */

export const FieldSignatureCanvas = ({
  canvasWidth,
  canvasHeight,
  dbKey,
  signatureId,
  dispatch,
  field
}) => {
  const isMounted = useRef(false);
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const sigCanvas = useRef({});

  const getSignature = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files/signature/${signatureId}`
      });
      if (isMounted.current) {
        const imageDataUrl = data.metadata.type;
        sigCanvas.current.fromDataURL(imageDataUrl);
      }
    } catch (e) {
      if (e.response && isMounted.current) message(e.response.data.message);
    }
  };

  const handleFinish = async (image) => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: `/files/signature`,
        body: JSON.stringify({
          metadata: { type: image },
          type: 'signature',
          contentType: 'image/png',
          fileName: `signature_CMI_${new Date().toISOString()}.png`,
          uploadDate: new Date()
        })
      });
      if (isMounted.current) {
        dispatch({
          type: 'SET_SIGNATURE_ID',
          field,
          payload: data
        });
        sigCanvas.current.clear();
      }
    } catch (e) {
      if (e.response && isMounted.current) message(e.response.data.message);
    }
  };

  const handleCancel = async () => {
    try {
      if (signatureId) {
        await dispatchAPI('DELETE', {
          url: `/files/${signatureId}`
        });
      }
      if (isMounted.current) {
        dispatch({
          type: 'SET_SIGNATURE_ID',
          field,
          payload: ''
        });
        sigCanvas.current.clear();
      }
    } catch (e) {
      if (e.response && isMounted.current) message(e.response.data.message);
    }
  };

  const onCancel = () => handleCancel();

  const onFinish = () => {
    if (sigCanvas.current.isEmpty()) {
      message('empty_signature', 4.5, 'signature');
      return;
    }
    const image = sigCanvas.current.toDataURL('image/png');
    handleFinish(image);
  };

  useEffect(() => {
    if (signatureId) {
      getSignature();
    }
  }, [signatureId]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <>
      <Row
        gutter={4}
        style={{
          border: '1px solid #2b2b2b',
          width: canvasWidth,
          height: canvasHeight
        }}
      >
        <SignaturePad
          canvasProps={{
            width: canvasWidth,
            height: canvasHeight,
            className: 'signature-canvas'
          }}
          ref={sigCanvas}
        />
      </Row>
      <Row
        justify="end"
        gutter={4}
        style={{
          marginTop: 16,
          width: canvasWidth
        }}
      >
        <Col>
          <Button
            type="link"
            danger
            icon={<CloseOutlined />}
            onClick={onCancel}
          >
            {t('buttons.cancel')}
          </Button>
        </Col>
        <Col>
          <Button type="primary" onClick={onFinish} icon={<CheckOutlined />}>
            {t('buttons.validate_signature')}
          </Button>
        </Col>
      </Row>
      <Item name={dbKey}>
        <Input style={{ visibility: 'hidden' }} />
      </Item>
    </>
  );
};

FieldSignatureCanvas.propTypes = {
  dbKey: PropTypes.arrayOf(PropTypes.string).isRequired,
  canvasWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  canvasHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dispatch: PropTypes.func.isRequired,
  signatureId: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired
};

FieldSignatureCanvas.defaultProps = {
  canvasWidth: 425,
  canvasHeight: 200
};
