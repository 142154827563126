export const vehiclesClaimsDbKeys = [
  'contract',
  'created_at',
  'entity',
  'site',
  'light_vehicle_type',
  'heavy_vehicle_type',
  'other_vehicle_type',
  'vehicle_brand',
  'registration_or_serial_number',
  'initial_traffic_release',
  'vehicle_model',
  'principal_driver',
  'purchase_date_or_lease_start_date',
  'piston_displacement',
  'taxable_power',
  'mileage',
  'observations',
  'desired_effective_date',
  'deletion_date_effect',
  'deletion_date',
  'sinister_date',
  'claim_type',
  'claim_type_if_other',
  'vehicle_brand',
  'vehicle_model',
  'registration_or_serial_number',
  'commentaries',
  'claim_status',
  'client_reference',
  'insurance_company_reference'
];

export const automissionsClaimsDbKeys = [
  'entity',
  'site',
  'sinister_date',
  'claim_type',
  'claim_type_if_other',
  'collaborator_first_name',
  'collaborator_last_name',
  'opening_date',
  'commentaries',
  'claim_status',
  'client_reference',
  'insurance_company_reference'
];

export const adValoremClaimsDbKeys = [
  'entity',
  'site',
  'sinister_date',
  'claim_type',
  'claim_type_if_other',
  'shipper_name',
  'departure_city',
  'arrival_city',
  'opening_date',
  'commentaries',
  'claim_status',
  'client_reference',
  'insurance_company_reference',
  'client_reference',
  'insurance_company_reference'
];

export const otherTransportsClaimsDbKeys = [
  'entity',
  'site',
  'sinister_date',
  'claim_type',
  'claim_type_if_other',
  'client_reference',
  'insurance_company_reference',
  'insured_status',
  'goods_to_be_appraised',
  'goods_type',
  'opening_date',
  'commentaries',
  'claim_status'
];

export const constructionInsuranceClaimsDbKeys = [
  'entity',
  'site',
  'sinister_date',
  'claim_type',
  'claim_type_if_other',
  'insurance_company_reference',
  'client_reference',
  'insured_status',
  'goods_to_be_appraised',
  'goods_type',
  'opening_date',
  'commentaries',
  'claim_status'
];

export const otherConstructionClaimsDbKeys = [
  'entity',
  'sinister_date',
  'claim_type',
  'claim_type_if_other',
  'insurance_company_reference',
  'client_reference',
  'construction_sub_programmes',
  'lifted_reservations',
  'goods_type',
  'opening_date',
  'commentaries',
  'claim_status'
];

export const golfsClaimsDbKeys = [
  'entity',
  'site',
  'sinister_date',
  'claim_type',
  'claim_type_if_other',
  'insurance_company_reference',
  'client_reference',
  'golf_name',
  'opening_date',
  'commentaries',
  'claim_status'
];

export const propertiesClaimsDbKeys = [
  'entity',
  'site',
  'sinister_date',
  'claim_type',
  'claim_type_if_other',
  'insurance_company_reference',
  'client_reference',
  'insured_status',
  'goods_to_be_appraised',
  'goods_type',
  'opening_date',
  'commentaries',
  'claim_status'
];

export const eventCancellationsClaimsDbKeys = [
  'entity',
  'site',
  'sinister_date',
  'claim_type',
  'claim_type_if_other',
  'event_name',
  'place_of_occurrence',
  'unit_counter',
  'insurance_company_reference',
  'client_reference',
  'event_start_date',
  'event_end_date',
  'personal_injury',
  'claim_status'
];

export const holeInOneClaimsDbKeys = [
  'entity',
  'site',
  'sinister_date',
  'claim_type',
  'claim_type_if_other',
  'sinister_date',
  'golf_name',
  'event_name',
  'unit_counter',
  'insurance_company_reference',
  'client_reference',
  'event_start_date',
  'event_end_date',
  'claim_status'
];

export const machineryBreakagesClaimsDbKeys = [
  'entity',
  'site',
  'sinister_date',
  'claim_type',
  'claim_type_if_other',
  'machinery_serial_number',
  'sinister_location',
  'operator_name',
  'personal_injury'
];
