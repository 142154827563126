import { useNavigate } from 'react-router-dom';
import { message as messageANTD } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from '../../../../../utils/historyUtils';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { handleFormDataWithFiles } from '../../../../../components/CreateUpdateContainerV3/utils/handleFormDataWithFiles';

/**
 * Custom hook for adding an insurance certificate.
 * @hook
 * @param {Object} options - The options for adding an insurance certificate.
 * @param {Array} options.draggerFilesList - The list of files for the dragger.
 * @param {Object} options.filesConfiguration - The configuration for the files.
 * @param {Function} options.setIsSubmitting - The function to set the submitting state.
 * @param {string} options.resourceName - The name of the resource.
 * @param {string} options.resourceId - The ID of the resource.
 * @param {Function} options.setVisible - The function to set the visibility state.
 * @param {string} options.idWithoutParams - The ID without parameters.
 * @returns {Object} - The handleSubmit function.
 */
export const useAddInsuranceCertificate = ({
  draggerFilesList,
  filesConfiguration,
  setIsSubmitting,
  resourceName,
  resourceId,
  setVisible,
  idWithoutParams
}) => {
  const { t } = useTranslation();
  const { updateHistoryCustom } = useHistory();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const navigate = useNavigate();

  const config = {
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        addInsuranceCertificate: true
      })
    }
  };

  const updateResource = async (body, files) => {
    setIsSubmitting(true);
    const formData = new FormData();

    handleFormDataWithFiles(
      files,
      draggerFilesList,
      formData,
      filesConfiguration,
      'edit'
    );
    const values =
      config.onUpdateResource && config.onUpdateResource.setBody
        ? config.onUpdateResource.setBody(body)
        : body;

    const valuesToAppend = {
      ...values
    };

    formData.append(
      'values',
      JSON.stringify({
        ...valuesToAppend
      })
    );

    try {
      await dispatchAPI('PATCH', {
        url: `${resourceName}/${
          resourceId || idWithoutParams
        }?isExitProgramme=true`,
        body: formData,
        params: { exitProgramme: true }
      });
      setVisible(false);
      messageANTD.success(t('datatable.column.action.delete.sortie_ok'));
      updateHistoryCustom(
        resourceName,
        resourceId || idWithoutParams,
        t('actions.added_certificate')
      );
      navigate(-1);
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  const handleSubmit = async (values) => {
    await updateResource(values, []);
    return true;
  };
  return { handleSubmit };
};
