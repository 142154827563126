import PropTypes from 'prop-types';
import { Select, Form } from 'antd';

const { Option } = Select;
const { Item } = Form;

/**
 * A custom select component for choosing values from a list of sites.
 * @component
 * @param {object} props - The component's props.
 * @param {string} props.dbKey - The key to use in the form.
 * @param {object} props.sitesAddresses - The list of sites.
 * @returns {JSX.Element} The JSX element representing the select component.
 */
export const EnvironmentSiteSelect = ({
  dbKey,
  sitesAddresses,
  isDisabled
}) => (
  <Item name={dbKey}>
    {sitesAddresses ? (
      <Select disabled={isDisabled}>
        {sitesAddresses.map((s) => (
          <Option key={s} value={s}>
            {s}
          </Option>
        ))}
      </Select>
    ) : null}
  </Item>
);

EnvironmentSiteSelect.propTypes = {
  dbKey: PropTypes.arrayOf(PropTypes.string).isRequired,
  sitesAddresses: PropTypes.arrayOf(PropTypes.string),
  isDisabled: PropTypes.bool
};

EnvironmentSiteSelect.defaultProps = {
  sitesAddresses: [],
  isDisabled: false
};
