/**
 * Determines the required files based on the machinery status type.
 * @function
 * @param {string} machinaryStatusType - The status type of the machinery.
 * @returns {string[]} An array of required file keys based on the machinery status type.
 * If the status type is 'RENTALHOLDER', returns ['purchase_invoice'].
 * If the status type is 'OWNER' or 'PROVISION', returns ['provision_or_rental_contract'].
 * If the status type is 'OTHER', returns ['purchase_invoice', 'provision_or_rental_contract'].
 * If the status type is unknown or undefined, returns an array with an empty string [''].
 */

export const mainDocuments = [
  'piece_identite',
  'Facture_contrat_abonnement',
  'attestation_votre_club'
];

export const requiredFilesConditions = (machinaryStatusType) => {
  switch (
    machinaryStatusType.stoppage_duration ||
    machinaryStatusType.reason ||
    machinaryStatusType
  ) {
    case 'OWNER':
      return ['purchase_invoice'];
    case 'RENTALHOLDER':
    case 'PROVISION':
      return ['provision_or_rental_contract'];
    case 'OTHER':
      return ['purchase_invoice', 'provision_or_rental_contract'];
    case 'interruption_golf':
      return ['rib', ...mainDocuments];
    case 'mutation_proffesionelle':
      return ['rib', ...mainDocuments, 'contract_avenant_copy'];
    case 'more_3_months':
    case 'complication_grossesse':
      return [
        'rib',
        ...mainDocuments,
        'certificat_medical',
        'medical_treatment_order',
        'medical_exams',
        'remboursement_securite_sociale',
        'work_stop',
        'bulletin_hospital'
      ];
    case 'licenciement':
      return [
        'rib',
        ...mainDocuments,
        'convocation_letter_copy',
        'licenciement_letter_copy'
      ];
    case 'less_3_months':
      return ['rib', ...mainDocuments, 'certificat_medical'];
    case 'divorce_separation':
      return [
        'rib',
        ...mainDocuments,
        'assignation_divorce_juge',
        'livret_famille'
      ];
    case 'death':
      return [
        'rib_death',
        ...mainDocuments,
        'livret_famille',
        'death_certificate'
      ];
    default:
      return [''];
  }
};
