import { Route, Routes } from 'react-router-dom';
import { Exception } from '../../../components';
import { IndividualHousesBuilderClaimCreateUpdate } from './IndividualHousesBuilderClaimCreateUpdate';
import { ListIndividualHousesBuilderClaim } from './ListIndividualHousesBuilderClaim';
import IndividualHousesBuilderClaimShow from './IndividualHousesBuilderClaimShow';

/**
 * Provides routing for managing Individual houses builder, including create, edit, show, and list views.
 *
 * @component
 * @returns {React.ReactNode} Routes for different views related to hardware insurances.
 */
export const IndividualHousesBuilderClaimsRouter = () => (
  <Routes>
    <Route
      path="create/:id"
      element={<IndividualHousesBuilderClaimCreateUpdate purpose="create" />}
    />
    <Route
      path="edit/:id"
      element={<IndividualHousesBuilderClaimCreateUpdate purpose="edit" />}
    />
    <Route path="show/:id" element={<IndividualHousesBuilderClaimShow />} />
    <Route index element={<ListIndividualHousesBuilderClaim />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
