import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Menu, Badge } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import navMenuLogos from '../../utils/constants/navMenuLogos';
import { useAuthContext } from '../../contexts/AuthContext';
import {
  routes as customerManagerRoutes,
  subRoutes as customerManagerSubRoutes,
  pathSearches as customerManagerPathSearches
} from '../../utils/constants/customerManagerRoutes';
import { useMiscellaneousContext } from '../../contexts/MiscellaneousContext';
import {
  routes as unitManagerRoutes,
  subRoutes as unitManagerSubRoutes,
  pathSearches as unitManagerPathSearches
} from '../../utils/constants/unitManagerRoutes';

export const OtherNavMenu = ({ setCollapsed, width }) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { notReadMessagesCount } = useMiscellaneousContext();
  const { notReadMessages, notReadClaimMessages } = notReadMessagesCount;
  const location = useLocation();
  const path = location?.pathname || '/';
  const activeKeys = path
    .replace('/', '')
    .split('/')
    .map((p) => `/${p}`);
  let routes;
  let subRoutes;
  let pathSearches;

  if (user?.role === 'admins:ADMIN') {
    routes = unitManagerRoutes;
    subRoutes = unitManagerSubRoutes;
    pathSearches = unitManagerPathSearches;
  } else {
    routes = customerManagerRoutes;
    subRoutes = customerManagerSubRoutes;
    pathSearches = customerManagerPathSearches;
  }

  return (
    <Menu
      style={{
        padding: '16px 0',
        background: 'var(--primaryColor)',
        maxHeight: 'calc(100% - 191px)',
        overflowY: 'auto',
        overflowX: 'hidden'
      }}
      selectedKeys={activeKeys}
      defaultOpenKeys={activeKeys}
      mode="inline"
      onSelect={width < 992 ? () => setCollapsed(true) : () => {}}
      items={Object.entries(routes).map(([menuItem, p]) =>
        subRoutes[menuItem]
          ? {
              key: p,
              icon: navMenuLogos[menuItem],
              label: t(`basiclayout.menu.${menuItem.toLowerCase()}`),
              children: Object.entries(subRoutes[menuItem]).map(
                ([subMenuItem, subPath]) => ({
                  key: subPath,
                  disabled: subPath === '',
                  label: (
                    <NavLink
                      to={`${p}${subPath}${
                        pathSearches[subMenuItem]
                          ? pathSearches[subMenuItem]
                          : ''
                      }`}
                    >
                      <span>
                        {t(`basiclayout.submenu.${subMenuItem.toLowerCase()}`)}
                      </span>
                    </NavLink>
                  )
                })
              )
            }
          : {
              key: p,
              disabled: p === '',
              label: (
                <NavLink
                  to={`${p}${
                    pathSearches[menuItem] ? pathSearches[menuItem] : ''
                  }`}
                >
                  {navMenuLogos[menuItem]}
                  {!['MESSAGES'].includes(menuItem) ? (
                    <span>
                      {t(`basiclayout.menu.${menuItem.toLowerCase()}`)}
                    </span>
                  ) : (
                    <>
                      <span>
                        {t(`basiclayout.menu.${menuItem.toLowerCase()}`)}
                      </span>
                      <Badge
                        count={notReadClaimMessages + notReadMessages}
                        offset={[20, 7]}
                      />
                    </>
                  )}
                </NavLink>
              )
            }
      )}
    />
  );
};

OtherNavMenu.propTypes = {
  setCollapsed: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired
};
