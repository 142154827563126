import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Tooltip } from 'antd';
import { EditOutlined, EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import { Flood } from '@carbon/icons-react';
import { MailingButton } from '../../../components/ButtonMailActionColumn';

const iconSize = 18;

/**
 * Generates an array of action columns for a table in a React component for managing golf properties.
 *
 * @param {boolean} edit - A boolean indicating whether the "Edit" action is enabled.
 * @param {function} generateAttestation - A function to generate an Attestation for a golf property record.
 * @param {function} isArchivatedPage - A boolean indicating whether the "Sortie" page is enabled.
 * @returns {Object[]} An array of action column objects for rendering actions in a table.
 */
export const useActionColumn = (
  edit,
  generateAttestation,
  isArchivatedPage
) => {
  const { t } = useTranslation();
  return [
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Flex gap="small" align="center" justify="end" wrap="nowrap">
          <Link to={`/programmes/golfs/show/${record?._id}`}>
            <Tooltip title={t('golfs.actions.show')}>
              <EyeOutlined style={{ fontSize: iconSize }} />
            </Tooltip>
          </Link>
          {edit && (
            <Link to={`/programmes/golfs/edit/${record?._id}`}>
              <Tooltip title={t('golfs.actions.edit')}>
                <EditOutlined style={{ fontSize: iconSize }} />
              </Tooltip>
            </Link>
          )}
          {!isArchivatedPage && (
            <>
              <Link to={`/claims/golfs/create/${record?._id}`}>
                <Tooltip title={t('actions.create_claim')}>
                  <Flood size={20} />
                </Tooltip>
              </Link>
              <MailingButton
                assetId={record?._id}
                templateName="Blank"
                tradKey="specific_demand"
                resource="Golf"
                baseUrl="golfs"
              />
            </>
          )}
          {record?.contract?.insurance_company === 'AXA' && (
            <>
              <Tooltip title={t('actions.generate_attestation')}>
                <Button
                  type="link"
                  onClick={() => generateAttestation(record?._id)}
                  style={{ padding: 0 }}
                >
                  <DownloadOutlined style={{ fontSize: iconSize }} />
                </Button>
              </Tooltip>
              <MailingButton
                assetId={record?._id}
                templateName="InsuranceAttestation"
                tradKey="sending_attestation_golf"
                resource="Golf"
                baseUrl="golfs"
              />
            </>
          )}
        </Flex>
      )
    }
  ];
};
