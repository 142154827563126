import { useState } from 'react';
import PropTypes from 'prop-types';
import { FileExclamationOutlined } from '@ant-design/icons';
import { Flex, Tooltip, Switch, Button } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * @description Filter by missing documents and reset filters
 * @param {Object} props Props
 * @param {Function} props.onChange On change
 * @param {Function} props.setResetFilters Set reset filters
 * @returns {React.ReactNode} Filter by missing documents
 */

export const FilterByMissingDocuments = ({ onChange, setResetFilters }) => {
  const { t } = useTranslation();

  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (checked) => {
    setIsChecked(checked);
    onChange(checked);
  };

  const handleReset = () => {
    setIsChecked(false);
    onChange(false);
    setResetFilters(true);
  };

  return (
    <Flex gap="small" align="center">
      <Button onClick={handleReset}>
        {t('buttons.reset_dynamic_filters')}
      </Button>
      <Switch checked={isChecked} onChange={handleChange} />
      <Tooltip title={t('buttons.missing_documents_only')}>
        <FileExclamationOutlined
          style={{ fontSize: 18, color: 'var(--warningColor)' }}
        />
      </Tooltip>
    </Flex>
  );
};

FilterByMissingDocuments.propTypes = {
  onChange: PropTypes.func.isRequired,
  setResetFilters: PropTypes.func.isRequired
};
