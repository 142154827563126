import PropTypes from 'prop-types';
import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { useFields } from './fieldsIndividualHousesBuilderSubscription';
import { useAuthContext } from '../../../contexts/AuthContext';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewerWithoutForm';
import { useConfig } from './createUpdateConfig';

/**
 * Component for creating or updating individual houses builder insurance subscriptions.
 * @component
 * @param {Object} props - The component's properties.
 * @param {string} props.purpose - The purpose of the component, either "create" or "edit".
 * @returns {JSX.Element} The JSX element for creating or updating individual houses builder insurance subscriptions.
 */
export const IndividualHousesBuilderCreateUpdate = ({ purpose }) => {
  const { company } = useAuthContext();
  const program = 'CONSTRUCTION';
  const subProgram = 'INDIVIDUAL_HOUSES_BUILDER';
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const initialState = {
    signatureId: '',
    isAttic: false,
    isAncillaryConstruction: false,
    isCarryOutWorks: false,
    isRenewableEnergiesWorks: false,
    isPhotovoltaicPanels: false,
    isGeotechnicalStudy: false
  };

  const formStateReducer = (state, action) => {
    switch (action.type) {
      case 'SET_SIGNATURE_ID':
        return { ...state, signatureId: action.payload };
      case 'TOGGLE_STATE':
        return { ...state, [action.field]: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(formStateReducer, initialState);

  const { fieldsInformations } = useFields(form, state, dispatch);

  const config = useConfig(dispatch, company, program, subProgram);

  return (
    <CreateUpdateContainer
      title={t(`subscriptions.form.${purpose}`)}
      form={form}
      fields={fieldsInformations}
      purpose={purpose}
      tradKey="individual_houses_builders"
      baseUrl="subscriptions"
      resource="subscriptions"
      config={config}
      withFilesManager={false}
      messageOnSuccess
    />
  );
};

IndividualHousesBuilderCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
