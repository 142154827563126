import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFiltersDataFetch } from '../../../../components/ColumnsDynamicFilters/useFiltersDatasFetch';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useAuthContext } from '../../../../contexts/AuthContext';

/**
 * Generates custom table columns configuration for a claims list.
 * @hook
 * @param {string} resourceName - The name of the resource.
 * @param {string} dbExtraQueryFilter - The extra query filter to be used when fetching the data.
 * @param {string} populate - The fields to populate when fetching the data.
 * @returns {Array} An array of column configuration objects.
 */
export const useColumns = (resourceName, dbExtraQueryFilter, populate) => {
  const { dispatchAPI, company: companyId } = useAuthContext();
  const { message } = useErrorMessage();
  const isMounted = useRef(true);
  const { t } = useTranslation();
  const [config, setConfig] = useState({});
  const { fetchFilters, filters, loading } = useFiltersDataFetch(
    resourceName,
    dbExtraQueryFilter,
    resourceName,
    populate
  );

  useEffect(() => {
    fetchFilters();
  }, []);

  const columns = [
    {
      title: t('cyber_insurances.columns.contract_number'),
      key: 'contract_number',
      dataIndex: ['contract', 'contract_number'],
      sorter: true
    },
    {
      title: t('cyber_insurances.columns.company_name'),
      key: 'company_name',
      dataIndex: ['company_name'],
      filters: filters?.company?.map((company) => ({
        text: company.name,
        value: company._id
      })),
      sorter: true
    },
    {
      title: t('cyber_insurances.columns.company_registration_number'),
      key: 'company_registration_number',
      dataIndex: ['company_registration_number'],
      filters: filters?.company_registration_number?.map((regNumber) => ({
        text: regNumber,
        value: regNumber
      })),
      sorter: true
    },
    {
      title: t('cyber_insurances.columns.activity_classification_number'),
      key: 'activity_classification_number',
      dataIndex: ['activity_classification_number'],
      filters: filters?.activity_classification_number?.map((activity) => ({
        text: activity,
        value: activity
      })),
      sorter: true
    },
    {
      title: t('cyber_insurances.columns.entity'),
      key: 'entity',
      dataIndex: ['entity'],
      className: 'entity-column', // Utilisation de la classe CSS ici
      filters: filters?.entity?.map((entity) => ({
        text: entity.name,
        value: entity._id
      })),
      sorter: true,
      render: (entity) => (
        <div className="entity-column">
          {entity ? entity.map((e) => e.name).join(', ') : '-'}
        </div>
      )
    },
    {
      title: t('cyber_insurances.columns.site'),
      key: 'site',
      dataIndex: ['site', 'name'],
      filters: filters?.site?.map((site) => ({
        text: site.name,
        value: site._id
      })),
      sorter: true
    },
    {
      title: t('cyber_insurances.columns.internet_site_or_domain_name'),
      key: 'internet_site_or_domain_name',
      dataIndex: ['internet_site_or_domain_name'],
      filters: filters?.internet_site_or_domain_name?.map((site) => ({
        text: site,
        value: site
      })),
      sorter: true
    },
    {
      title: t('cyber_insurances.columns.total_workforce'),
      key: 'total_workforce',
      dataIndex: 'total_workforce',
      filters: filters?.total_workforce?.map((workforce) => ({
        text: workforce,
        value: workforce
      })),
      sorter: true
    },
    {
      title: t('cyber_insurances.columns.last_yearly_turnover'),
      key: 'last_yearly_turnover',
      dataIndex: 'last_yearly_turnover',
      filters: filters?.last_yearly_turnover?.map((turnover) => ({
        text: turnover,
        value: turnover
      })),
      sorter: true
    },
    {
      title: t(
        'cyber_insurances.columns.is_located_in_france_andorra_monaco_eu'
      ),
      key: 'is_located_in_france_andorra_monaco_eu',
      dataIndex: 'is_located_in_france_andorra_monaco_eu',
      filters: filters?.is_located_in_france_andorra_monaco_eu?.map(
        (isLocated) => ({
          text: t(`cyber_insurances.columns.enums.${isLocated}`),
          value: isLocated
        })
      ),
      render: (is_located_in_france_andorra_monaco_eu) =>
        is_located_in_france_andorra_monaco_eu
          ? t(
              `cyber_insurances.columns.enums.${is_located_in_france_andorra_monaco_eu}`
            )
          : '-',
      sorter: true
    },
    {
      title: t('cyber_insurances.columns.internet_services_turnover_part'),
      key: 'internet_services_turnover_part',
      dataIndex: 'internet_services_turnover_part',
      filters: filters?.internet_services_turnover_part?.map((turnover) => ({
        text: turnover,
        value: turnover
      })),
      render: (internet_services_turnover_part) =>
        internet_services_turnover_part
          ? t(
              `cyber_insurances.columns.enums.${internet_services_turnover_part}`
            )
          : '-',
      sorter: true
    },
    {
      title: t('cyber_insurances.columns.global_gross_margin_percentage'),
      key: 'global_gross_margin_percentage',
      dataIndex: 'global_gross_margin_percentage',
      filters: filters?.global_gross_margin_percentage?.map((margin) => ({
        text: margin,
        value: margin
      })),
      sorter: true
    },
    {
      title: t('cyber_insurances.columns.workstation_number'),
      key: 'workstation_number',
      dataIndex: 'workstation_number',
      filters: filters?.workstation_number?.map((workstation) => ({
        text: workstation,
        value: workstation
      })),
      sorter: true
    },
    {
      title: t('cyber_insurances.columns.server_number'),
      key: 'server_number',
      dataIndex: 'server_number',
      filters: filters?.server_number?.map((server) => ({
        text: server,
        value: server
      })),
      sorter: true
    },
    {
      title: t('cyber_insurances.columns.personal_data_server_number'),
      key: 'personal_data_server_number',
      dataIndex: 'personal_data_server_number',
      filters: filters?.personal_data_server_number?.map((server) => ({
        text: server,
        value: server
      })),
      sorter: true
    },
    {
      title: t('cyber_insurances.columns.bank_details_server_number'),
      key: 'bank_details_server_number',
      dataIndex: 'bank_details_server_number',
      filters: filters?.bank_details_server_number?.map((server) => ({
        text: server,
        value: server
      })),
      sorter: true
    },
    {
      title: t('cyber_insurances.columns.health_data_server_number'),
      key: 'health_data_server_number',
      dataIndex: 'health_data_server_number',
      filters: filters?.health_data_server_number?.map((server) => ({
        text: server,
        value: server
      })),
      sorter: true
    }
  ];

  const getConfig = useCallback(async () => {
    if (!isMounted.current) return;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/programme-configs/${companyId}/cyber-insurance-configs`
      });
      setConfig(data[0]);
    } catch (e) {
      if (isMounted.current) message('missing_parameters');
    }
  }, [dispatchAPI, message]);

  useEffect(() => {
    getConfig();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const filteredColumns = columns.filter((column) => config[column.key]);

  return [filteredColumns, loading] || [];
};
