import { Route, Routes } from 'react-router-dom';
import { ShowIndividualHousesBuilder } from './ShowIndividualHousesBuilder';
import { Exception } from '../../../../components';
import { IndividualHousesBuilderCreateUpdate } from './IndividualHousesBuilderCreateUpdate';
import { ListIndividualHousesBuilder } from './ListIndividualHousesBuilder';

/**
 * Renders the router for the IndividualHousesBuilder component.
 *
 * @returns {JSX.Element} The router component.
 */
export const IndividualHousesBuilderRouter = () => (
  <Routes>
    <Route
      path="/edit/:id"
      element={<IndividualHousesBuilderCreateUpdate purpose="edit" />}
    />
    <Route path="/show/:id" element={<ShowIndividualHousesBuilder />} />
    <Route index element={<ListIndividualHousesBuilder />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
