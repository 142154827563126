/**
 * Constants for the hardware insurance claim module.
 *
 * @typedef {Object} Constants
 * @property {string} productAPI - The API endpoint for hardware insurances.
 * @property {string} claimUrl - The URL for hardware insurance claims.
 * @property {string} claimAPI - The API endpoint for hardware insurance claims.
 * @property {string} resource - The resource name for hardware insurance claims.
 */

/**
 * Constants for the hardware insurance claim module.
 *
 * @type {Constants}
 */
export const constants = {
  productAPI: 'individual-houses-builders',
  claimUrl: 'individual-houses-builder-claim',
  claimAPI: 'individual_houses_builder_claims',
  resourceClaim: 'Individual_houses_builder_claim',
  populateProductShow:
    'customer_manager,unit_manager,contract,entity,company_Id',
  populateClaimShow: 'entry_id,unit_manager,entity,company,customer_manager'
};
