import PropTypes from 'prop-types';
import { Badge, Flex } from 'antd';
import { displayDate } from './utils';
import { useAuthContext } from '../../../contexts/AuthContext';

export const ConversationTitle = ({ conversation }) => {
  const {
    users,
    last_message_date,
    messages: itemMessages,
    category
  } = conversation;
  const { user } = useAuthContext();
  let firstName = '';
  let lastName = '';
  let containsUnread = false;

  if (category === 'PERSONAL') {
    const { first_name, last_name } = users.filter(
      (userLocal) => userLocal._id !== user._id
    )[0];
    firstName = first_name;
    lastName = last_name;
    containsUnread = itemMessages.some(
      (itemMessage) =>
        !!itemMessage?.unread?.length &&
        itemMessage.unread.some((unreadItem) => unreadItem === user._id)
    );
  }

  return (
    <Flex justify="space-between" gap="small" align="center">
      <span>{`${firstName} ${lastName}`}</span>
      <Flex gap="small" align="center">
        {containsUnread && <Badge color="red" />}
        <span className="conversation-date">
          {displayDate(last_message_date)}
        </span>
      </Flex>
    </Flex>
  );
};

ConversationTitle.propTypes = {
  conversation: PropTypes.shape({
    last_message_date: PropTypes.string.isRequired,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired
      })
    ).isRequired,
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string.isRequired
      })
    ).isRequired,
    category: PropTypes.string.isRequired
  }).isRequired
};
