import { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { List, Button, Skeleton } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useConversationContext } from '../../../contexts/ConversationContext';
import { NewMessageModal } from './NewMessageModal';
import { ListConversationsItem } from './ListConversationsItem';
import { useErrorMessage } from '../../../utils/errorMessage';
import { createFileFromJSON } from '../../../components/CreateUpdateContainerV3/utils/createFileFromJSON';

export const ListConversations = () => {
  const {
    user: { role, _id },
    dispatchAPI
  } = useAuthContext();
  const { getConversations, conversations, loading, filter } =
    useConversationContext();
  const { message } = useErrorMessage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [avatarUrls, setAvatarUrls] = useState({});
  const autorizedRoles = ['admins:ADMIN', 'admins:SUPER-ADMIN'];
  const buttonDisplay =
    role && autorizedRoles.includes(role) && filter !== 'claims';

  useEffect(() => {
    const fetchAvatars = async () => {
      const fetchPromises = conversations.map(async (conversation) => {
        const user = conversation.users.filter((u) => u._id !== _id)[0];
        if (user && user.documents && user.documents.length > 0) {
          try {
            const fileWithUrl = await createFileFromJSON(
              user.documents[0],
              dispatchAPI,
              message
            );
            return { id: user._id, url: fileWithUrl.url };
          } catch (e) {
            message(e);
            return { id: user._id, url: '' };
          }
        }
        return { id: user?._id, url: '' };
      });

      const avatars = await Promise.all(fetchPromises);
      const newAvatarUrls = avatars.reduce((acc, avatar) => {
        if (avatar.id && avatar.url) {
          acc[avatar.id] = avatar.url;
        }
        return acc;
      }, {});
      setAvatarUrls(newAvatarUrls);
    };

    if (filter !== 'claims') {
      fetchAvatars();
    }
  }, [conversations, dispatchAPI, message, _id, filter]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <NewMessageModal open={isModalOpen} onCancel={closeModal} />
      {!loading && (
        <div id="scrollableDiv" style={{ overflow: 'auto' }}>
          <InfiniteScroll
            dataLength={conversations.length}
            next={getConversations}
            hasMore={conversations.length < 11}
            loader={
              <Skeleton loading={loading} avatar paragraph={{ rows: 1 }} />
            }
            scrollableTarget="scrollableDiv"
          >
            <List
              style={{ padding: '0 16px' }}
              itemLayout="horizontal"
              dataSource={conversations}
              renderItem={(item) => (
                <ListConversationsItem
                  item={item}
                  avatarUrl={
                    avatarUrls[item.users.find((u) => u._id !== _id)?._id]
                  }
                  filter={filter}
                />
              )}
            />
          </InfiniteScroll>
        </div>
      )}
      {buttonDisplay && (
        <Button
          style={{ bottom: 12, right: 12, position: 'absolute' }}
          onClick={openModal}
          className="new-conversation-btn"
          shape="circle"
          type="primary"
        >
          <MessageOutlined />
        </Button>
      )}
    </>
  );
};
