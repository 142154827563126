import dayjs from 'dayjs';

/**
 * A custom hook that generates a list of content for displaying subscription details.
 * @hook
 * @param {object} data - The subscription data to generate content for.
 * @returns {Array} An array of objects containing label, span, and content for each detail.
 */
export const useListContentTransport = (data = {}) => {
  const { company_id, customer_manager, unit_manager, desired_effective_date } =
    data;
  const customerManagerName = customer_manager
    ? `${customer_manager.first_name} ${customer_manager.last_name}`
    : '-';
  const unit_managerName = unit_manager
    ? `${unit_manager.first_name} ${unit_manager.last_name}`
    : '-';

  return [
    {
      label: 'subscriptions.form.company_name',
      span: 1,
      content: company_id?.name || '-'
    },
    {
      label: 'subscriptions.form.customer_manager',
      span: 1,
      content: (customerManagerName && customerManagerName) || '-'
    },
    {
      label: 'subscriptions.form.unit_manager',
      span: 1,
      content: (unit_managerName && unit_managerName) || '-'
    },
    {
      label: 'subscriptions.form.desired_effective_date',
      span: 1,
      content:
        (desired_effective_date &&
          dayjs(desired_effective_date).format('DD/MM/YYYY')) ||
        '-'
    }
  ];
};
