import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { Spin } from 'antd';
import { useColumns } from './utils/columnsCallForContributions';
import { ListResource } from '../../components/ListResource/ListResource';
import { ResetFilterButton } from '../components/ResetFiltersButton';
import { EntitiesDisplay } from '../documents/utils/EntitiesDisplay';
import { ExtraButtons } from './utils/ExtraButtons';
import { CallForContributionContext } from './CallForContributionContext';
import { useActionColumn } from './utils/actionColumn';

/**
 * Represents the `ListCallForContribution` component that displays a list of documents.
 *
 * It provides filtering options based on entity and document type. Filter changes result in a
 * navigation update, changing the URL to reflect the applied filters.
 *
 * The list can be extended with custom action columns. Data for the documents is fetched based on the company
 * in the auth context.
 *
 * @component
 * @returns {React.ElementType} A list of documents.
 */

export const ListCallForContribution = () => {
  const [resetFilters, setResetFilters] = useState(false);
  const [extraFilter, setExtraFilter] = useState('');
  const [selectedRowKeysPaidItem, setSelectedRowKeysPaidItem] = useState([]);
  const [selectedRowKeysIssuedCall, setSelectedRowKeysIssuedCall] = useState(
    []
  );
  const { forceRefresh } = useContext(CallForContributionContext);
  const actionColumn = useActionColumn();

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const currentPage = Number(params.get('p') || 1);
  const pageSize = Number(params.get('pS') || 10);

  let filterParameter = '';
  const resourceName = 'call-for-contributions';
  const dbExtraQueryFilter = `document_type=CALL_FOR_CONTRIBUTION`;
  const populate = 'entity,company_Id,site,documents,documents.file';

  const [columns, loading] = useColumns(
    resourceName,
    dbExtraQueryFilter,
    populate,
    selectedRowKeysPaidItem,
    setSelectedRowKeysPaidItem,
    selectedRowKeysIssuedCall,
    setSelectedRowKeysIssuedCall
  );

  const renderEntitiesDisplay = (record) => <EntitiesDisplay record={record} />;

  const rowSelection = {
    selectedRowKeys: selectedRowKeysIssuedCall,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeysIssuedCall(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled:
        record.is_paid === true ||
        record.status === 'PAID' ||
        record.status === 'PENDING',
      status: record.status
    })
  };

  useEffect(() => {
    if (resetFilters) {
      filterParameter = '';
      setResetFilters(false);
    }
    setExtraFilter(filterParameter);
    navigate({
      pathname: '/contributions',
      search: `?&p=${currentPage}&pS=${pageSize}&f=${filterParameter}`
    });
  }, [navigate, resetFilters, currentPage, pageSize, extraFilter]);

  if (loading) return <Spin fullscreen="true" />;

  return (
    <ListResource
      extraQuery={dbExtraQueryFilter}
      resourceName={resourceName}
      columns={[...columns, ...actionColumn]}
      withExtraFilters={<ResetFilterButton setResetFilters={setResetFilters} />}
      tradKey="call_for_contributions"
      noCreateButton
      populate={populate}
      headers={[]}
      expandable={{
        expandedRowRender: (record) => renderEntitiesDisplay(record)
      }}
      extraButtons={
        <ExtraButtons
          callForContribution={selectedRowKeysIssuedCall}
          setSelectedRowKeysIssuedCall={setSelectedRowKeysIssuedCall}
          paidItem={selectedRowKeysPaidItem}
          setSelectedRowKeysPaidItem={setSelectedRowKeysPaidItem}
        />
      }
      forceRefresh={forceRefresh}
      noArchiveFilter
      withUploadButton={false}
      customActionColumn
      extraFilter={extraFilter}
      rowSelection={{ ...rowSelection }}
    />
  );
};
