import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Divider, Col } from 'antd';
import Logo from '../../assets/images/logoUnit.svg';
import { ContentCustom } from '../../components/ContentCustom/ContentCustom';
import { useHandleResize } from '../../utils/handleResize';

/**
 * Component for creating or updating agriculture data.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the component (create or update).
 * @returns {JSX.Element} The rendered component.
 */
export const OutOfNavLayout = ({ LogoQuest, coordenees, children }) => {
  const translation = 'OutOfNavLayout';
  const { t } = useTranslation();
  const { width } = useHandleResize();

  const secondContent = () => (
    <div
      className="sidebar"
      style={{
        backgroundColor: 'var(--primaryColor)',
        minHeight: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: width < 993 ? 'auto' : '100vh'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          color: 'white',
          justifyContent: 'space-around',
          height: '100%',
          padding: width < 993 ? '24px 32px 80px' : 40,
          gap: width < 993 ? '4vh' : '8vh'
        }}
      >
        <img src={Logo} alt="Logo" />
        <LogoQuest />
        <h1>{t(`${translation}.sider_thanks`)}</h1>
        <div style={{ width: '100%' }}>
          <h3>{t(`${translation}.coordennees`)}</h3>
          <Divider style={{ backgroundColor: 'white' }} />

          {coordenees}
        </div>
      </div>
    </div>
  );

  const desktopLayout = () => (
    <Row
      style={{
        flexWrap: 'nowrap',
        maxHeight: '100vh',
        overflow: width < 993 ? 'auto' : 'hidden',
        flexDirection: width < 993 ? 'column-reverse' : 'row'
      }}
    >
      <Col xs={24} lg={8} xl={6} xxl={5}>
        {secondContent()}
      </Col>
      <Col xs={24} lg={16} xl={18} xxl={19}>
        <ContentCustom
          style={{
            width: '100%',
            padding: width < 993 ? 16 : 24,
            minHeight: width < 993 ? 'unset' : '100vh',
            maxHeight: width < 993 ? 'unset' : '100vh',
            overflow: width < 993 ? 'hidden' : 'auto'
          }}
        >
          {children}
        </ContentCustom>
      </Col>
    </Row>
  );

  const mobileLayout = () => (
    <Col
      style={{
        flexWrap: 'nowrap',
        maxHeight: '100vh',
        overflow: 'auto',
        flexDirection: width < 993 ? 'column-reverse' : 'row'
      }}
      xs={24}
      lg={8}
    >
      <Row>
        <ContentCustom
          style={{
            width: '100%',
            padding: width < 993 ? 16 : 24,
            minHeight: width < 993 ? 'unset' : '100vh'
          }}
        >
          {children}
        </ContentCustom>
      </Row>
      <Row
        style={{
          width: '100%'
        }}
      >
        {secondContent()}
      </Row>
    </Col>
  );

  return (
    <div
      style={{
        width: '100',
        height: '100%',
        maxHeight: '100vh'
      }}
    >
      {width < 993 ? mobileLayout() : desktopLayout()}
    </div>
  );
};

OutOfNavLayout.propTypes = {
  LogoQuest: PropTypes.string,
  coordenees: PropTypes.node
};

OutOfNavLayout.defaultProps = {
  LogoQuest: '',
  coordenees: ''
};
