/**
 * Validates if a value has up to eight digits and up to two decimal places.
 * @function
 * @param {Function} t - The translation function.
 * @param {string} message - The error message key.
 * @returns {Object} - The validator object.
 */
export const eightDigitsTwoDecimals = (t, message) => ({
  validator(_, value) {
    if (!value || /^\d{1,8}(\.\d{0,2})?$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(t(`${message}`)));
  }
});

/**
 * Validates if a value contains only allowed characters.
 * @function
 * @param {Function} t - The translation function.
 * @returns {Object} - The validator object.
 */
export const forbiddenCharacters = (t) => ({
  validator(_, value) {
    if (value && typeof value.isValid === 'function' && value.isValid()) {
      return Promise.resolve();
    }
    if (
      typeof value === 'string' &&
      value.startsWith('<p>') &&
      value.endsWith('</p>')
    ) {
      return Promise.resolve();
    }
    if (
      typeof value === 'string' &&
      value.startsWith('{') &&
      value.endsWith('}') &&
      value.includes('"id"') &&
      value.includes('"contractNumber"')
    ) {
      return Promise.resolve();
    }
    if (typeof value === 'string') {
      if (!/[,#!%[\\\]]/.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error(t(`errors.message.invalid_field_characters`))
      );
    }
    return Promise.resolve();
  }
});
