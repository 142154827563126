import { Button } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { ContentCustom } from '../../components/ContentCustom/ContentCustom';
import { PageHeaderCustom } from '../../components/PageHeader/PageHeader';

/**
 * Renders the WelcomeGolf component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setStep - A function to set the step.
 * @returns {JSX.Element} The rendered WelcomeGolf component.
 */
export const WelcomeGolf = ({ setStep }) => {
  const { t } = useTranslation();
  useEffect(() => {
    // Using setTimeout to ensure the page is fully loaded before scrolling
    const timer = setTimeout(() => {
      window.scroll({ top: 0, behavior: 'smooth' });
    }, 3000); // Adjust delay if necessary

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="welcome" id="welcome">
      <PageHeaderCustom
        noDynamiqueRoute
        title={<h2>{t(`interruption_golf.form.title.create`)}</h2>}
        noReturnButton
      />
      <ContentCustom>
        <div className="welcome-body">
          <h3>{t('interruption_golf.intro.chers_amis')}</h3>
          <p>{t('interruption_golf.intro.p1')}</p>
          <p>{t('interruption_golf.intro.p2')}</p>
          <ul>
            <li>{t('interruption_golf.intro.p3l1')}</li>
            <li>{t('interruption_golf.intro.p3l2')}</li>
          </ul>
          <p>{t('interruption_golf.intro.p4')}</p>
          <p>
            {t(`interruption_golf.intro.maladie_accident`)}
            <br />
            {t(`interruption_golf.intro.mutation_professionnelle`)}
            <br />
            {t(`interruption_golf.intro.licenciement`)}
            <br />
            {t(`interruption_golf.intro.divorce_separation`)}
            <br />
            {t(`interruption_golf.intro.complication_grossesse`)}
            <br />
            {t(`interruption_golf.intro.death`)}
            <br />
          </p>
          <div className="welcome-button">
            <Button
              type="primary"
              onClick={() => {
                setStep(1);
              }}
              className="mobile-button"
            >
              {t('interruption_golf.intro.strat_form')}
            </Button>
          </div>
        </div>
      </ContentCustom>
    </div>
  );
};

WelcomeGolf.propTypes = {
  setStep: PropTypes.func
};

WelcomeGolf.defaultProps = {
  setStep: () => {}
};
