import { useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Skeleton, Row, Col, Card, Button, Popconfirm } from 'antd';
import pluralize from 'pluralize';
import { WarningOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useListContentProMultiriskInsurance } from './utils/listContentProMultiriskInsurance';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../components';
import { MailingButton } from '../../Mailing/MailingButton';
import { ClaimButton } from '../../components/ClaimButton';
import { FormDeleteProgrammesButton } from '../FormDeleteProgrammes';
import { Documents } from '../../components/Documents';
import { routes } from '../../../utils/constants/adminRoutes';
import { History } from '../../components/History';

/**
 * Displays detailed information about a subscription.
 * @component
 * @returns {JSX.Element} The JSX element representing the subscription show page.
 */
export const ProMultiriskInsuranceShow = () => {
  const isMounted = useRef(false);
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const navigate = useNavigate();
  const [dataSet, setDataSet] = useState({
    datas: {},
    programme: '',
    url: undefined
  });
  const { datas, programme, url } = dataSet;
  const listContent = useListContentProMultiriskInsurance(datas);
  const formatUrlPart = (str) =>
    pluralize(str.toLowerCase().replace(/_/g, '-'));

  const getProMultiriskInsurance = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/professional-multirisk-insurances/${id}?populate=customer_manager,unit_manager,contract,company,entity,site`
      });
      if (isMounted.current) {
        setDataSet((prevState) => ({
          ...prevState,
          datas: {
            ...data,
            entity: data?.entity?.name,
            site: data?.site?.name
          },
          url: formatUrlPart(data?.programme_types),
          programme: data?.programme_types,
          isLoading: false
        }));
      }
    } catch (e) {
      if (e && isMounted.current) message(e);
    } finally {
      if (isMounted.current) setIsLoading(false);
    }
  }, [id, message]);

  useEffect(() => {
    isMounted.current = true;
    getProMultiriskInsurance();
    return () => {
      isMounted.current = false;
    };
  }, [getProMultiriskInsurance]);

  const deleteHardwareInsurance = async () => {
    try {
      await dispatchAPI('DELETE', {
        url: `/professional-multirisk-insurances/${id}`
      });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    if (datas) setIsDeleted(datas?.deleted);
  }, [datas]);

  let editUrl;
  if (programme && programme.length > 0) {
    editUrl = `${routes.PROGRAMMES}/${url}/edit/${id}`;
  }

  return (
    <>
      <PageHeaderCustom
        title={`${t('number')} ${datas?.unit_counter || 'xxxx'} - ${t(
          `subscriptions.form.${programme}`
        )}`}
        extra={
          <>
            {editUrl && !isDeleted && (
              <Link
                to={{
                  pathname: editUrl
                }}
              >
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              </Link>
            )}
            {datas && url && (
              <>
                <ClaimButton id={id} url={url} claimType="civilLiability" />
                <MailingButton
                  asset={datas}
                  templateName="Blank"
                  tradKey="specific_demand"
                  resource="Professional-multirisk-insurance"
                  baseUrl="professional-multirisk-insurances"
                />
              </>
            )}
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteHardwareInsurance}
              icon={<WarningOutlined />}
            >
              <FormDeleteProgrammesButton
                resourceId={id}
                resourceName="professional-multirisk-insurances"
                visible={visible}
                setVisible={setVisible}
              />
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={12}>
              <Row gutter={[0, 16]}>
                <Card title={t('subscriptions.form.informations')}>
                  <DescriptionList
                    data={listContent}
                    translate
                    layout="vertical"
                  />
                </Card>
                <Col span={24}>
                  <Card title={t('subscriptions.form.history')} size="small">
                    <History route="Professional-multirisk-insurance" />
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs={24} xl={12}>
              <Documents
                idWithoutParams={id}
                baseUrl="professional-multirisk-insurances"
                resource="Professional-multirisk-insurance"
              />
            </Col>
          </Row>
        </Skeleton>
      </ContentCustom>
    </>
  );
};
