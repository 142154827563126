import PropTypes from 'prop-types';
import { Select, Form } from 'antd';
import { useTranslation } from 'react-i18next';

const { Item } = Form;
const { Option } = Select;

/**
 * Produces a yes/no select component.
 * @component
 * @param {string} dbKey - The key of the database field.
 * @param {function} dispatch - The state setter function.
 * @param {boolean} disabled - Whether the field is disabled.
 * @returns {JSX.Element} - A yes/no select component.
 */
export const SelectYesNoReducer = ({
  dbKey,
  dispatch,
  disabled,
  tradKeyEnums,
  field,
  type
}) => {
  const { t } = useTranslation();

  const handleChange = (value) => {
    const isYesSelected = value === 'YES';
    dispatch({
      type,
      field,
      payload: isYesSelected
    });
  };

  const options = [
    {
      label: t(`${tradKeyEnums}.YES`),
      value: 'YES'
    },
    {
      label: t(`${tradKeyEnums}.NO`),
      value: 'NO'
    }
  ];

  return (
    <Item name={dbKey}>
      <Select
        placeholder={t(`${tradKeyEnums}.YES_OR_NO`)}
        disabled={disabled}
        onChange={handleChange}
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value}>
            {t(option.label)}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

SelectYesNoReducer.propTypes = {
  dbKey: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool,
  dispatch: PropTypes.func,
  tradKeyEnums: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

SelectYesNoReducer.defaultProps = {
  dispatch: () => {},
  disabled: false
};
