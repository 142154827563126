import { useParams } from 'react-router-dom';
import { AcknowledgeReceiptButton } from '../ClaimsButtons/AcknowledgeReceiptButton';
import { handleStatusUtils } from '../handleStatusUtils';

/**
 * Renders the Attestation buttons conditionally based on the render condition.
 * @component
 * @param {boolean} renderCondition - Whether to render the Attestation buttons.
 * @param {boolean} edit - Indicates if is on edit mode.
 * @param {function} onChange - Custom function to manage changes.
 * @param {function} vehicleClaim - Vehicle data.
 * @param {function} setChangingStatus - Sets changing status.
 * @returns {?React.Component} The rendered Attestation buttons component, or null if the render condition is false.
 */
const renderAcknowledgeReceiptButton = (
  renderCondition,
  edit,
  onChange,
  vehicleClaim,
  setChangingStatus
) => {
  const { id } = useParams();
  const { getClaimStep } = handleStatusUtils();

  const reception = () => {
    onChange(0, id, edit);
    setChangingStatus(getClaimStep(0));
  };

  if (renderCondition) {
    return (
      <AcknowledgeReceiptButton
        reception={reception}
        claimReferenceNumber={vehicleClaim?.unit_counter}
      />
    );
  }
  return null;
};

export default renderAcknowledgeReceiptButton;
