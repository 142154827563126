import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useFields } from './utils/fieldsLegalProtectionClaim';
import { useConfig } from './utils/configLegalProtectionClaim';

export const LegalProtectionInsuranceClaimCreateUpdate = ({ purpose }) => {
  const [isComplaint, setIsComplaint] = useState(false);
  const [isOther, setIsOther] = useState(false);
  const mandatoryDocuments = ['full_statement'];
  const { fieldsInformations } = useFields({
    isOther,
    setIsOther,
    setIsComplaint,
    isComplaint
  });
  const { config } = useConfig({ setIsOther });

  return (
    <CreateUpdateContainer
      fields={fieldsInformations}
      purpose={purpose}
      baseUrl="Legal_protection_insurance_claims"
      resource="claims"
      config={config}
      populate="?populate=documents.file"
      mandatoryDocuments={mandatoryDocuments}
    />
  );
};

LegalProtectionInsuranceClaimCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
