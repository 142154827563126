import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Steps, Button, Col, Popconfirm } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';

const { Step } = Steps;

/**
 * A component for displaying a stepper for construction insurance status.
 *
 * This component allows users to navigate through different status steps for construction insurance.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.constructionInsurance - The construction insurance object with status information.
 * @param {string} props.id - The ID of the construction insurance.
 *
 * @returns {JSX.Element} The rendered `StepperAmendment` component.
 */

const stepIndexToStatus = ['REQUEST', 'UNDERSTUDY'];

export const StepperAmendment = ({
  id,
  status,
  constructionInsuranceId,
  setForceRefresh,
  forceRefresh,
  isRequest
}) => {
  const [currentStatus, setCurrentStatus] = useState(status);
  const [saveStatus, setSaveStatus] = useState(false);
  const [amendmentDecision, setAmendmentDecision] = useState(null);
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const message = useErrorMessage();

  const updateStatus = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/amendments/${id}`,
        body: JSON.stringify({
          values: JSON.stringify({ amendment_status: currentStatus })
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } catch (e) {
      message(e);
    }
  };

  const closeRequest = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/amendment-constructions/closure/${id}/construction/${constructionInsuranceId}`,
        body: { amendment_status: amendmentDecision }
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      message(e);
    }
  };

  const accept = () => {
    setAmendmentDecision('ACCEPTED');
  };
  const refuse = () => {
    setAmendmentDecision('REFUSED');
  };

  const currentStep = stepIndexToStatus.indexOf(currentStatus);

  const handleStepClick = (stepIndex) => {
    const newStatus = stepIndexToStatus[stepIndex];
    setSaveStatus(true);
    setCurrentStatus(newStatus);
  };
  useEffect(() => {
    if (saveStatus) {
      updateStatus();
      setSaveStatus(false);
    }
  }, [currentStatus]);
  useEffect(() => {
    setCurrentStatus(status);
  }, [status]);

  useEffect(() => {
    if (amendmentDecision) {
      closeRequest();
    }
  }, [amendmentDecision]);

  return (
    <Row justify="space-between" align="center">
      {status && (
        <>
          <Col span={12}>
            <Steps size="small" current={currentStep}>
              {stepIndexToStatus.map((indexStatus, index) => (
                <Step
                  key={indexStatus}
                  title={t(`amendments.form.${indexStatus}`)}
                  onClick={() => handleStepClick(index)}
                />
              ))}
            </Steps>
          </Col>
          {isRequest && (
            <Link
              to={`/programmes/construction-insurance/amendment/edit/${id}`}
            >
              <Button type="primary">{t(`buttons.edit`)}</Button>
            </Link>
          )}
          <Popconfirm
            title={t('amendments.popconfirm.accept')}
            okText={t('amendments.popconfirm.confirm')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('amendments.popconfirm.cancel')}
            onConfirm={accept}
            icon={<WarningOutlined />}
          >
            <Button>{t(`buttons.accept`)}</Button>
          </Popconfirm>
          <Popconfirm
            title={t('amendments.popconfirm.refuse')}
            okText={t('amendments.popconfirm.confirm')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('amendments.popconfirm.cancel')}
            onConfirm={refuse}
            icon={<WarningOutlined />}
          >
            <Button>{t(`buttons.refuse`)}</Button>
          </Popconfirm>
        </>
      )}
    </Row>
  );
};

StepperAmendment.propTypes = {
  status: PropTypes.string.isRequired,
  id: PropTypes.string,
  constructionInsuranceId: PropTypes.string.isRequired,
  setForceRefresh: PropTypes.func,
  forceRefresh: PropTypes.bool.isRequired,
  isRequest: PropTypes.bool
};

StepperAmendment.defaultProps = {
  id: null,
  setForceRefresh: () => {},
  isRequest: false
};
