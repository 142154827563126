import { useTranslation } from 'react-i18next';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Input, DatePicker, Select } from 'antd';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { SelectManager } from '../../../components/SelectManager';
import { SelectContract } from '../../../components/SelectContract';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { PropertyContext } from '../PropertyContext';
import { SelectEntityV2 } from '../../../components/SelectEntityV2';
import { SelectSiteV2 } from '../../../components/SelectSiteV2';

const { Option } = Select;

/**
 * A custom hook for generating fields used in property-related forms.
 *
 * @hook
 * @property {Array<Object>} fieldsProperty - Fields configurations for property-related forms.
 * @property {Array<Object>} fieldsAddresses - Fields configurations for property addresses.
 * @property {boolean} isFieldsLoading - A boolean indicating whether the fields are being loaded.
 * @returns {Object} An object containing fields configurations and loading status.
 */

const useFields = (form) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [enums, setEnums] = useState({});
  const {
    currentEntityContext,
    setCurrentEntityContext,
    entitiesContext,
    setEntitiesContext,
    currentContract,
    setCurrentContract
  } = useContext(PropertyContext);

  const dateFormat = 'DD-MM-YYYY';

  const handleEntityChange = (value) => {
    setCurrentEntityContext(value);
    form.setFieldsValue({ site: undefined });
  };

  const handleContractChange = () => {
    form.setFieldsValue({ entity: undefined, site: undefined });
  };

  const fieldsProperty = [
    {
      label: ['contract_number'],
      name: ['contract'],
      rules: [{ required: true }],
      input: (
        <SelectContract
          dbKey="contract"
          programme_types="PROPERTY"
          setCurrentContract={setCurrentContract}
          currentEntityContext={currentEntityContext}
          handleContractChange={handleContractChange}
        />
      ),
      startWithDivider: {
        title: t('properties.form.main_informations')
      }
    },
    {
      label: ['customer_manager'],
      name: ['customer_manager'],
      rules: [{ required: true }],
      input: <SelectManager dbKey="customer_manager" />
    },
    {
      label: ['unit_manager'],
      name: ['unit_manager'],
      rules: [{ required: false }],
      input: <SelectManager dbKey="unit_manager" />
    },
    {
      label: ['desired_effective_date'],
      name: ['desired_effective_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['entity'],
      name: ['entity'],
      rules: [{ required: true }],
      input: (
        <SelectEntityV2
          dbKey="entity"
          permKey="programme-PROPERTY"
          currentContract={currentContract}
          setEntitiesContext={setEntitiesContext}
          handleEntityChange={handleEntityChange}
          currentEntityContext={currentEntityContext}
        />
      )
    },
    {
      label: ['site'],
      name: ['site'],
      rules: [{ required: false }],
      input: (
        <SelectSiteV2
          dbKey="site"
          permKey="programme-PROPERTY"
          currentEntityContext={currentEntityContext}
          entitiesContext={entitiesContext}
        />
      )
    },
    {
      label: ['asset_name'],
      name: ['asset_name'],
      rules: [{ required: true }]
    },
    {
      label: ['postal_code'],
      name: ['postal_code'],
      rules: [{ required: true }]
    },
    {
      label: ['city'],
      name: ['city'],
      rules: [{ required: true }]
    },
    {
      label: ['country'],
      name: ['country'],
      rules: [{ required: true }],
      input: (
        <Select
          placeholder={t('documents.filter_by_type')}
          style={{ width: '100%' }}
          showSearch
          filterOption={(inputValue, option) =>
            (option?.title ?? '')
              .toLowerCase()
              .includes(inputValue.toLowerCase())
          }
        >
          {(enums?.countries || []).map((c) => (
            <Option key={c} value={c} title={c}>
              {t(`properties.form.${c}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: ['total_insured_developed_area'],
      name: ['total_insured_developed_area'],
      rules: [{ required: true }],
      input: <Input type="Number" />
    },
    {
      label: ['legal_status'],
      name: ['legal_status'],
      rules: [{ required: true }],
      input: (
        <Select
          placeholder={t('documents.filter_by_type')}
          style={{ width: '100%' }}
        >
          {(enums?.legal_status || []).map((l) => (
            <Option key={l} value={l}>
              {t(`properties.form.${l}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: ['typology'],
      name: ['typology'],
      rules: [{ required: true }],
      input: (
        <Select
          placeholder={t('documents.filter_by_type')}
          style={{ width: '100%' }}
        >
          {(enums?.typology || []).map((typo) => (
            <Option key={typo} value={typo}>
              {t(`properties.form.${typo}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: ['last_and_first_name_of_tenant'],
      name: ['last_and_first_name_of_tenant'],
      rules: [{ required: true }],
      input: <Input />
    },
    {
      label: ['tenant_occupation'],
      name: ['tenant_occupation'],
      rules: [{ required: true }],
      input: <Input />,
      endWithDivider: {
        title: t('properties.form.assets_addresses')
      }
    },
    {
      name: ['number'],
      rules: [{ required: true }]
    },
    {
      name: ['additional'],
      rules: [{ required: false }]
    },
    {
      name: ['street'],
      rules: [{ required: true }],
      endWithDivider: {
        title: t('properties.form.photos')
      }
    },
    {
      name: ['photos_of_the_exterior'],
      input: 'File',
      maxFilesCount: 2,
      multipleFiles: true
    },
    {
      name: ['photos_of_the_interior'],
      input: 'File',
      maxFilesCount: 2,
      multipleFiles: true,
      endWithDivider: {
        title: t('properties.form.documents')
      }
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/properties/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(false);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fieldsProperty,
    isFieldsLoading
  };
};
export default useFields;
