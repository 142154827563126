import { Route, Routes } from 'react-router-dom';
import { ProMultiriskInsuranceShow } from './ProMultiriskInsuranceShow';
import { ListProMultiriskInsurance } from './ListProMultiriskInsurance';
import { ProMultiriskInsuranceCreateUpdate } from './ProMultiriskInsuranceCreateUpdate';
import { PromultiriskInsuranceProvider } from './PromultiriskInsuranceContext';
import { Exception } from '../../../components';

/**
 * A router component for handling hardware insurance related routes.
 *
 * @component
 * @returns {JSX.Element} The JSX element representing the ProfessionalMultiriskInsuranceRouter.
 */

export const ProfessionalMultiriskInsuranceRouter = () => (
  <PromultiriskInsuranceProvider>
    <Routes>
      <Route
        path="create"
        element={<ProMultiriskInsuranceCreateUpdate purpose="create" />}
      />
      <Route
        path="edit/:id"
        element={<ProMultiriskInsuranceCreateUpdate purpose="edit" />}
      />
      <Route path="show/:id" element={<ProMultiriskInsuranceShow />} />
      <Route index element={<ListProMultiriskInsurance />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </PromultiriskInsuranceProvider>
);
