import dayjs from 'dayjs';

/**
 * Formats date to DatePicker Format.
 * @hook
 * @description When calling this function, pass string parameter you want to format
 * @returns {Object} An object containing the `getEnums` function.
 */

export const formatDatePickerDate = (date) => {
  const unformatedDate = dayjs(date);
  const formattedDate = unformatedDate.format('DD-MM-YYYY');
  return formattedDate;
};
