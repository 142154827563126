import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useHistory } from '../../../../utils/historyUtils';

/**
 * Custom hook for Individual Houses Builder Utils.
 * @hook
 * @param {Object} options - The options object.
 * @param {Object} options.construction - The construction object.
 * @param {Object} options.otherConstruction - The other construction object.
 * @returns {Object} - An object containing the `getConstructions` function and the `config` object.
 */
export const useIHBUtils = ({ construction, otherConstruction }) => {
  const { id } = useParams();
  const { dispatchAPI, company } = useAuthContext();
  const { message } = useErrorMessage();
  const { setInitialHistory, createHistory, updateHistory } = useHistory();

  const getConstructions = async (setConstruction) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `individual-houses-builder/${id}?populate=entity,site,contract,company_Id,related_contract_entities`
      });
      setConstruction(data);
    } catch (e) {
      message(e);
    }
  };

  const config = {
    onGetResource: {
      setFields: (data) => {
        if (data?.history) {
          setInitialHistory(data);
        }
        return {
          ...data,
          sinister_date: data.sinister_date && dayjs(data.sinister_date)
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        entry_id: construction?._id,
        company,
        contract: construction?.contract?._id,
        opening_date: dayjs(),
        entity: construction?.contract?.entity,
        related_contract_entities: otherConstruction
          ? construction?.contract?.related_contract_entities
          : undefined,
        site: construction?.contract?.site,
        ...createHistory('claim', 'individual-houses-builder', id)
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        ...updateHistory('claim')
      })
    }
  };

  return { getConstructions, config };
};
