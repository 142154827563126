// TODO: Refactor
// - Extraire les GET vers un fichier à part (un par modèle/route) *répétition de code*
// - Revoir le return car actuellement, les ternaires s'enchainent sans logique.
// Revoir la logique des conditions dans les props des composants. Exemple, disabled du Popconfirm clams.popconfirm.title.
// Simplifier les states (2 à 3 states maximum).
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Col, Flex, Skeleton, Row, Steps } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useListContentClaim } from './listContentClaim';
import { useListContentMainInformations } from '../../programmes/EventCancellation/utils/listContentEventCancellation';
import { useListContentEventSpecificGuarantees } from '../../programmes/EventCancellation/utils/listContentEventSpecificGuarantees';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../components';
import { Documents } from '../../components/Documents';
import {
  useListItemsStep,
  useListBeforeValidation
} from '../utils/listItemsStep';
import { handleStatusUtils } from '../utils/handleStatusUtils';
import { ModalClosure } from '../utils/ModalClosure';
import { PhotosClaims } from '../utils/PhotosClaims';
import { ShowClaimAccountingOverview } from '../ClaimAccountingOverview/ShowClaimAccountingOverview';
import { useExtraButtons } from '../extraButtons';
import { History } from '../../components/History';
import { useProgramDataUpdater } from '../../../utils/programDataUpdater';
import { useTrasnportClaims } from './utilsTransportClaims';
import { MailingButton } from '../../Mailing/MailingButton';

/**
 * Displays detailed information about a event cancellation claim, including claim information, event cancellation information,
 * pictures, and related actions.
 *
 * @component
 * @returns {React.ReactNode} Detailed view of the event claim.
 */

export const EventCancellationClaimsShow = () => {
  const targetUrl = 'event_cancellation_claims';
  const { id } = useParams();
  const { permissions, verifPermissions } = useAuthContext();
  const {
    current,
    setCurrent,
    onChange,
    toEnclosed,
    setToEnclose,
    changingStatus,
    setChangingStatus
  } = handleStatusUtils(targetUrl);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const { items } = useListItemsStep();
  const { itemsBeforeValidation } = useListBeforeValidation();
  const [event, setEvent] = useState();
  const [edit, setEdit] = useState(true);
  const [closure, setClosure] = useState(false);

  const [eventData, setEventData] = useState({});

  const listContentClaim = useListContentClaim(eventData?.claim);
  const listContentMainInformations = useListContentMainInformations(event);
  const listContentEventSpecificGuarantees =
    useListContentEventSpecificGuarantees(event);

  const {
    setIsCompliant,
    setAccountingBalanced,
    setFeesAppealsBalanced,
    initialData
  } = useProgramDataUpdater(eventData, setEventData);

  const { getEventCancellationClaim, getEventCancellation } =
    useTrasnportClaims(
      setIsLoading,
      eventData,
      setEventData,
      initialData,
      setToEnclose,
      setCurrent,
      setEvent
    );

  useEffect(() => {
    if (eventData?.ID) {
      (async () => {
        await getEventCancellation();
      })();
    }
  }, [eventData?.ID]);

  useEffect(() => {
    (async () => {
      await getEventCancellationClaim();
    })();
  }, [current, eventData?.accountingBalanced, eventData?.feesAppealsBalanced]);

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setEdit(
        !verifPermissions('/claims/edit', permissions?.notPermittedRoutes)
      );
    }
  }, [permissions]);

  const { extraButtons } = useExtraButtons({
    isCompliant: eventData?.isCompliant,
    setIsCompliant,
    current,
    edit,
    toEnclosed,
    setClosure,
    onChange,
    productClaim: eventData?.claim,
    resource: 'Event_cancellation_claim',
    baseUrl: 'event_cancellation_claims',
    product: event,
    urlPathName: 'event-cancellations',
    accountingBalanced: eventData?.accountingBalanced,
    feesAppealsBalanced: eventData?.feesAppealsBalanced,
    changingStatus,
    setChangingStatus
  });

  return eventData?.ID && event && !isLoading ? (
    <>
      <PageHeaderCustom
        title={`${eventData?.claim?.unit_counter || 'xxxx'} -  ${
          event?.event_name || '-'
        }`}
        extra={extraButtons}
      />
      <MailingButton
        asset={eventData?.claim}
        templateName={changingStatus}
        tradKey={changingStatus}
        baseUrl={targetUrl}
        changingStatus={changingStatus}
        setChangingStatus={setChangingStatus}
      />
      <ContentCustom>
        <Flex vertical gap="middle">
          {current !== undefined && current !== 6 ? (
            <Steps
              onChange={(step) => {
                onChange(step, id, edit);
              }}
              items={eventData?.isCompliant ? items : itemsBeforeValidation}
              current={current}
            />
          ) : null}
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={12}>
              <Row gutter={[0, 16]}>
                <Card>
                  <DescriptionList
                    data={listContentClaim}
                    translate
                    layout="vertical"
                    title={t('claims.form.claim_informations')}
                  />
                </Card>
                <PhotosClaims baseUrl="event_cancellation_claims" />
                <Documents
                  idWithoutParams={id}
                  baseUrl="event_cancellation_claims"
                  resource="Event_cancellation_claim"
                  noDelete={current === 6}
                />
                <Col span={24}>
                  <Card title={t('subscriptions.form.history')} size="small">
                    <History route="event_cancellation_claims" />
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs={24} xl={12}>
              <Row gutter={[0, 16]}>
                <Card title={t('claims.form.main_informations')}>
                  <DescriptionList
                    data={listContentMainInformations}
                    translate
                    layout="vertical"
                  />
                </Card>
                <Card
                  title={t(
                    'claims.form.basic_guarantees_premises_equipments_other_unavailability'
                  )}
                >
                  <DescriptionList
                    data={listContentEventSpecificGuarantees}
                    translate
                    layout="vertical"
                  />
                </Card>
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={24}>
              {current >= 2 && eventData?.isCompliant ? (
                <ShowClaimAccountingOverview
                  current={current}
                  buttonBelowTable={
                    current !== 6 && current !== undefined && edit === true
                  }
                  noActionColumnsButton={eventData?.isClosed}
                  accountingBalanced={eventData?.accountingBalanced}
                  setAccountingBalanced={setAccountingBalanced}
                  feesAppealsBalanced={eventData?.feesAppealsBalanced}
                  setFeesAppealsBalanced={setFeesAppealsBalanced}
                  resourceName="event-cancellation-claim-accounts"
                  url="event-cancellation-claim-accounts"
                  resourceNameClaim="event_cancellation_claims"
                  urlClaim="event_cancellation_claims"
                  urlAccount="claim-accounts"
                  edit={edit}
                />
              ) : null}
            </Col>
          </Row>
        </Flex>
        <ModalClosure
          open={closure}
          onCancel={() => setClosure(false)}
          onOk={() => setClosure(false)}
          claimReferenceNumber={eventData?.claim?.unit_counter}
        />
      </ContentCustom>
    </>
  ) : (
    <Skeleton active loading={isLoading} />
  );
};
