import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback, useRef } from 'react';
import { Tag } from 'antd';
import { FileExclamationOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { constructionStatus } from '../../../../utils/constants/tagColors';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useFiltersDataFetch } from '../../../../components/ColumnsDynamicFilters/useFiltersDatasFetch';
import { renderEntities } from '../../../../utils/formatters/entities';
import { formatNumber } from '../../../../utils/amountFormatter';

/**
 * `useColumns` is a custom React hook that provides a list of column configurations.
 *
 * The column titles are fetched using the `react-i18next` hook for internationalization.
 *
 * @hook
 * @returns {Array<object>} Returns an array of column configurations to be consumed by a table component.
 */

export const useColumns = (
  resourceName,
  dbExtraQueryFilter,
  targetType,
  populate
) => {
  const { t } = useTranslation();
  const { dispatchAPI, company: companyId } = useAuthContext();
  const { message } = useErrorMessage();
  const [config, setConfig] = useState({});
  const { fetchFilters, filters, loading } = useFiltersDataFetch(
    resourceName,
    dbExtraQueryFilter,
    targetType,
    populate
  );
  const isMounted = useRef(true);
  const renderAddress = (text, record) => {
    const { number, street, additional } = record || {};
    return number || street || additional
      ? `${number || ''} ${street || ''} ${additional || ''}`
      : '-';
  };
  const allColumns = [
    {
      title: t('constructions.form.missing_documents'),
      key: 'missing_documents',
      dataIndex: ['missing_documents'],
      sorter: true,
      width: 150,
      render: (missing_documents) =>
        missing_documents ? (
          <FileExclamationOutlined
            style={{ fontSize: 18, color: 'var(--warningColor)' }}
          />
        ) : (
          '-'
        )
    },
    {
      title: t('constructions.form.company_name'),
      key: 'company_name',
      dataIndex: ['company_Id', 'name'],
      sorter: true
    },
    {
      title: t('constructions.form.contract'),
      key: 'contract',
      dataIndex: ['contract', 'contract_number'],
      filters: filters?.contract?.map((number) => ({
        text: number.contract_number,
        value: number._id
      })),
      sorter: true
    },
    {
      title: t('constructions.form.entity'),
      key: 'entity',
      dataIndex: ['entity'],
      filters: filters?.entity?.map((entity) => ({
        text: entity.name,
        value: entity._id
      })),
      sorter: true,
      render: renderEntities
    },
    {
      title: t('constructions.form.site'),
      key: 'site',
      dataIndex: ['site', 'name'],
      filters: filters?.site?.map((site) => ({
        text: site.name,
        value: site._id
      })),
      sorter: true
    },
    {
      title: t('constructions.form.status'),
      key: 'status',
      dataIndex: ['status'],
      filters: filters?.status?.map((status) => ({
        text: t(`constructions.form.${status}`),
        value: status
      })),
      sorter: true,
      render: (status) =>
        status ? (
          <Tag color={constructionStatus[status]}>
            {t(`constructions.form.${status}`)}
          </Tag>
        ) : (
          '-'
        )
    },
    {
      title: t('constructions.form.construction_site_name'),
      key: 'construction_site_name',
      dataIndex: 'construction_site_name',
      filters: filters?.construction_site_name?.map((name) => ({
        text: name,
        value: name
      })),
      sorter: true
    },
    {
      title: t('constructions.form.construction_site_address'),
      key: 'construction_site_address',
      render: renderAddress
    },
    {
      title: t('constructions.form.postal_code'),
      key: 'postal_code',
      dataIndex: ['postal_code'],
      filters: filters?.postal_code?.map((postal_code) => ({
        text: postal_code,
        value: postal_code
      })),
      sorter: true
    },
    {
      title: t('constructions.form.city'),
      key: 'city',
      dataIndex: ['city'],
      filters: filters?.city?.map((city) => ({
        text: city,
        value: city
      })),
      sorter: true
    },
    {
      title: t('constructions.form.desired_guarantees'),
      key: 'desired_guarantees',
      dataIndex: 'desired_guarantees',
      filters: filters?.desired_guarantees?.map((desired_guarantees) => ({
        text: t(`constructions.form.${desired_guarantees}`),
        value: desired_guarantees
      })),
      sorter: true,
      render: (desired_guarantees) =>
        desired_guarantees ? t(`constructions.form.${desired_guarantees}`) : '-'
    },
    {
      title: t('constructions.form.subscriber'),
      key: 'subscriber',
      dataIndex: 'subscriber',
      filters: filters?.subscriber?.map((subscriber) => ({
        text: subscriber,
        value: subscriber
      })),
      sorter: true
    },
    {
      title: t(
        'constructions.form.contracting_owner_if_different_from_the_subscriber'
      ),
      key: 'contracting_owner_if_different_from_the_subscriber',
      dataIndex: 'contracting_owner_if_different_from_the_subscriber',
      filters: filters?.contracting_owner_if_different_from_the_subscriber?.map(
        (owner) => ({
          text: owner,
          value: owner
        })
      ),
      sorter: true
    },
    {
      title: t('constructions.form.construction_permit_number'),
      key: 'construction_permit_number',
      dataIndex: 'construction_permit_number',
      filters: filters?.construction_permit_number?.map((number) => ({
        text: number,
        value: number
      })),
      sorter: true
    },
    {
      title: t('constructions.form.expected_construction_cost'),
      key: 'expected_construction_cost',
      dataIndex: 'expected_construction_cost',
      filters: filters?.expected_construction_cost?.map((cost) => ({
        text: cost,
        value: cost
      })),
      render: (expected_construction_cost) =>
        expected_construction_cost
          ? `${formatNumber(expected_construction_cost)} €`
          : '-',
      sorter: true
    },
    {
      title: t('constructions.form.expected_construction_starting_date'),
      key: 'expected_construction_starting_date',
      dataIndex: 'expected_construction_starting_date',
      filters: filters?.expected_construction_starting_date?.map((date) => ({
        text: dayjs(date).format('DD/MM/YYYY'),
        value: date
      })),
      sorter: true,
      render: (expected_construction_starting_date) =>
        expected_construction_starting_date &&
        dayjs(expected_construction_starting_date).format('DD/MM/YYYY')
    },
    {
      title: t('constructions.form.construction_starting_date'),
      key: 'construction_starting_date',
      dataIndex: 'construction_starting_date',
      filters: filters?.construction_starting_date?.map((date) => ({
        text: dayjs(date).format('DD/MM/YYYY'),
        value: date
      })),
      sorter: true,
      render: (construction_starting_date) =>
        construction_starting_date &&
        dayjs(construction_starting_date).format('DD/MM/YYYY')
    },
    {
      title: t('constructions.form.expected_construction_completion_date'),
      key: 'expected_construction_completion_date',
      dataIndex: 'expected_construction_completion_date',
      filters: filters?.expected_construction_completion_date?.map((date) => ({
        text: dayjs(date).format('DD/MM/YYYY'),
        value: date
      })),
      sorter: true,
      render: (expected_construction_completion_date) =>
        expected_construction_completion_date &&
        dayjs(expected_construction_completion_date).format('DD/MM/YYYY')
    },
    {
      title: t('constructions.form.construction_completion_date'),
      key: 'construction_completion_date',
      dataIndex: 'construction_completion_date',
      filters: filters?.construction_completion_date?.map((date) => ({
        text: dayjs(date).format('DD/MM/YYYY'),
        value: date
      })),
      sorter: true,
      render: (construction_completion_date) =>
        construction_completion_date &&
        dayjs(construction_completion_date).format('DD/MM/YYYY')
    },
    {
      title: t('constructions.form.amendment_new_construction_cost'),
      key: 'amendment_new_construction_cost',
      dataIndex: ['current_amendment', 'amendment_new_construction_cost'],
      filters: filters?.current_amendment?.map((cost) => ({
        text: cost.amendment_new_construction_cost,
        value: cost._id
      })),
      sorter: true
    },
    {
      title: t('constructions.form.reason_of_amendment_demand'),
      key: 'reason_of_amendment_demand',
      dataIndex: ['current_amendment', 'reason_of_amendment_demand'],
      filters: filters?.current_amendment?.map((reason) => ({
        text: reason.reason_of_amendment_demand,
        value: reason._id
      })),
      sorter: true
    },
    {
      title: t('constructions.form.rental_loss_guarantee'),
      key: 'rental_loss_guarantee',
      dataIndex: 'rental_loss_guarantee',
      sorter: true,
      filters: filters?.rental_loss_guarantee?.map((rental_loss_guarantee) => ({
        text: t(`constructions.form.${rental_loss_guarantee}`),
        value: rental_loss_guarantee
      })),
      render: (rental_loss_guarantee) =>
        rental_loss_guarantee
          ? t(`constructions.form.${rental_loss_guarantee}`)
          : '-'
    },
    {
      title: t('constructions.form.date_added'),
      key: 'created_at',
      dataIndex: ['created_at'],
      filters: filters?.created_at?.map((date) => ({
        text: dayjs(date).format('DD/MM/YYYY'),
        value: date
      })),
      render: (created_at) =>
        created_at ? dayjs(created_at).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('vehicles.form.deletion_date'),
      key: 'deletion_date_effect',
      dataIndex: 'deletion_date_effect',
      sorter: true,
      filters: filters?.deletion_date_effect?.map((date) => ({
        text: dayjs(date).format('DD/MM/YYYY'),
        value: date
      })),
      render: (deletion_date_effect) =>
        deletion_date_effect
          ? dayjs(deletion_date_effect).format('DD/MM/YYYY')
          : '-'
    }
  ];

  const getConfig = useCallback(async () => {
    if (!isMounted.current) return;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/programme-configs/${companyId}/constructions-configs`
      });
      setConfig(data[0]);
    } catch (e) {
      if (isMounted.current) message('missing_parameters');
    }
  }, [dispatchAPI, message]);

  useEffect(() => {
    getConfig();
    fetchFilters();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const filteredColumns = allColumns.filter((column) => config[column.key]);

  return [filteredColumns, loading] || [];
};
