import React, { useState, createContext } from 'react';

export const AmendmentContext = createContext();

/**
 * ConstructionInsurance component.
 * Provides the HoleInOne context to its children.
 *
 * @component
 * @param {object} children - The children components.
 * @returns {JSX.Element} ConstructionInsurance
 */

export const AmendmentProvider = ({ children }) => {
  const [currentContract, setCurrentContract] = useState();
  const [currentEntityContext, setCurrentEntityContext] = useState();
  const [entitiesContext, setEntitiesContext] = useState([]);

  return (
    <AmendmentContext.Provider
      value={{
        currentEntityContext,
        setCurrentEntityContext,
        entitiesContext,
        setEntitiesContext,
        currentContract,
        setCurrentContract
      }}
    >
      {children}
    </AmendmentContext.Provider>
  );
};
