export const mandatoryDocumentsHandling = (programme, docs, condition) => {
  switch (programme) {
    case 'CYBER_INSURANCE':
      if (condition) {
        const isMandatoryDoc = docs.some((doc) =>
          doc.fileKey.includes('additional_questionnaire')
        );
        return isMandatoryDoc;
      }
      return true;
    default:
      return true;
  }
};
