import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker, Input, Checkbox, Typography } from 'antd';
import { GenericSelectEnums } from '../../components/GenericSelectEnums';
import { RadioGroupYesNo } from '../../components/RadioGroupYesNo';
import { RadioGroupYesNoReducer } from '../../components/RadioGroupYesNoReducer';
import { eightDigitsTwoDecimals } from '../../components/utils/fieldsValidator';
import { FieldSignatureCanvas } from '../../../components/FieldSignatureCanvas/FieldSignatureCanvas';
import { useGetEnums } from '../../../utils/getEnums';

const { Text } = Typography;

/**
 * Generate fields configuration for a form.
 *
 * @function
 * @param {Form} form - The form instance.
 * @param {Object} state - The state of the form.
 * @param {Function} dispatch - The state setter.
 * @returns {Object} An object containing the fields configuration.
 */
export const useFields = (form, state, dispatch) => {
  const { t } = useTranslation();
  const { getEnums } = useGetEnums();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [enums, setEnums] = useState({});
  const dateFormat = 'DD-MM-YYYY';
  const tradKeyEnums = 'individual_houses_builders.form.enums';
  const fieldNumberFormatErrorMessage =
    'individual_houses_builders.form.error.input_number_format';

  const {
    isAttic,
    isAncillaryConstruction,
    isCarryOutWorks,
    isRenewableEnergiesWorks,
    isPhotovoltaicPanels,
    isGeotechnicalStudy,
    signatureId
  } = state;

  const resetFieldValues = useCallback(() => {
    const fieldResetRules = [
      {
        condition: !isAttic,
        path: ['individual_houses_builder', 'if_attic'],
        value: ''
      },
      {
        condition: !isAncillaryConstruction,
        path: ['individual_houses_builder', 'if_is_ancillary_construction'],
        value: []
      },
      {
        condition: !isCarryOutWorks,
        path: [
          'individual_houses_builder',
          'if_is_project_owner_carry_out_work'
        ],
        value: []
      },
      {
        condition: !isGeotechnicalStudy,
        path: ['individual_houses_builder', 'if_is_geotechnical_study'],
        value: []
      }
    ];

    fieldResetRules.forEach(({ condition, path, value }) => {
      if (condition) {
        form.setFieldsValue({
          [path[0]]: { [path[1]]: value }
        });
      }
    });

    if (!isRenewableEnergiesWorks) {
      const renewableEnergyFields = [
        'if_is_solar_thermal_energy_amount',
        'if_is_aerothermal_energy_amount',
        'if_is_earth_air_heat_exchanger_amount',
        'if_is_photovoltaic_panels_amount',
        'if_photovoltaic_installation'
      ];
      renewableEnergyFields.forEach((field) => {
        form.setFieldsValue({
          individual_houses_builder: {
            [field]: field === 'if_photovoltaic_installation' ? [] : 0
          }
        });
      });
      dispatch({
        type: 'TOGGLE_STATE',
        field: 'isPhotovoltaicPanels',
        payload: false
      });
    }
  }, [
    form,
    isAttic,
    isAncillaryConstruction,
    isCarryOutWorks,
    isRenewableEnergiesWorks,
    isGeotechnicalStudy
  ]);

  useEffect(() => {
    resetFieldValues();
  }, [resetFieldValues]);

  const fieldsInformations = [
    {
      label: ['subscriber_name'],
      name: ['individual_houses_builder', 'subscriber_name'],
      rules: [{ required: true }],
      startWithDivider: {
        title: t('individual_houses_builders.form.general_informations')
      }
    },
    {
      label: ['subscriber_reg_number'],
      name: ['individual_houses_builder', 'subscriber_reg_number'],
      rules: [{ required: true }]
    },
    {
      label: ['contract_number_from_subscription'],
      name: ['individual_houses_builder', 'contract_number_from_subscription'],
      rules: [{ required: true }]
    },
    {
      label: ['is_1990_law_construction_contract'],
      name: ['individual_houses_builder', 'is_1990_law_construction_contract'],
      rules: [{ required: true }],
      input: (
        <RadioGroupYesNo
          dbKey={[
            'individual_houses_builder',
            'is_1990_law_construction_contract'
          ]}
          tradKeyEnums={tradKeyEnums}
        />
      ),
      startWithDivider: {
        title: t('individual_houses_builders.form.construction_contract_type')
      }
    },
    {
      label: ['is_delivered_weather_proof'],
      name: ['individual_houses_builder', 'is_delivered_weather_proof'],
      rules: [{ required: true }],
      input: (
        <RadioGroupYesNo
          dbKey={['individual_houses_builder', 'is_delivered_weather_proof']}
          tradKeyEnums={tradKeyEnums}
        />
      )
    },
    {
      label: ['is_real_estate_promotion_contract_vefa'],
      name: [
        'individual_houses_builder',
        'is_real_estate_promotion_contract_vefa'
      ],
      rules: [{ required: true }],
      input: (
        <RadioGroupYesNo
          dbKey={[
            'individual_houses_builder',
            'is_real_estate_promotion_contract_vefa'
          ]}
          tradKeyEnums={tradKeyEnums}
        />
      )
    },
    {
      label: ['is_expansion'],
      name: ['individual_houses_builder', 'is_expansion'],
      rules: [{ required: true }],
      input: (
        <RadioGroupYesNo
          dbKey={['individual_houses_builder', 'is_expansion']}
          tradKeyEnums={tradKeyEnums}
        />
      )
    },
    {
      label: ['project_owner_name'],
      name: ['individual_houses_builder', 'project_owner_name'],
      rules: [{ required: true }],
      startWithDivider: {
        title: t('individual_houses_builders.form.construction_informations')
      }
    },
    {
      label: ['current_adress'],
      name: ['individual_houses_builder', 'current_adress'],
      rules: [{ required: true }]
    },
    {
      label: ['construction_adress'],
      name: ['individual_houses_builder', 'construction_adress'],
      rules: [{ required: true }]
    },
    {
      label: ['detached_house_height'],
      name: ['individual_houses_builder', 'detached_house_height'],
      rules: [{ required: true }],
      input: (
        <GenericSelectEnums
          dbKey={['individual_houses_builder', 'detached_house_height']}
          enums={enums.detachedHouseHeight}
          tradKeyEnums={tradKeyEnums}
        />
      )
    },
    {
      label: ['floor_area'],
      name: ['individual_houses_builder', 'floor_area'],
      rules: [
        { required: true },
        eightDigitsTwoDecimals(t, fieldNumberFormatErrorMessage)
      ],
      input: <Input type="number" addonAfter="M²" />
    },
    {
      label: ['is_basement'],
      name: ['individual_houses_builder', 'is_basement'],
      rules: [{ required: true }],
      input: (
        <RadioGroupYesNo
          dbKey={['individual_houses_builder', 'is_basement']}
          tradKeyEnums={tradKeyEnums}
        />
      )
    },
    {
      label: ['is_attic'],
      name: ['individual_houses_builder', 'is_attic'],
      rules: [{ required: true }],
      input: (
        <RadioGroupYesNoReducer
          dbKey={['individual_houses_builder', 'is_attic']}
          tradKeyEnums={tradKeyEnums}
          dispatch={dispatch}
          field="isAttic"
          type="TOGGLE_STATE"
        />
      )
    },
    {
      label: ['if_attic'],
      name: ['individual_houses_builder', 'if_attic'],
      rules: [{ required: isAttic }],
      hidden: !isAttic,
      input: (
        <GenericSelectEnums
          dbKey={['individual_houses_builder', 'if_attic']}
          enums={enums.atticConfig}
          tradKeyEnums={tradKeyEnums}
        />
      )
    },
    {
      label: ['is_ancillary_construction'],
      name: ['individual_houses_builder', 'is_ancillary_construction'],
      rules: [{ required: true }],
      input: (
        <RadioGroupYesNoReducer
          dbKey={['individual_houses_builder', 'is_ancillary_construction']}
          tradKeyEnums={tradKeyEnums}
          dispatch={dispatch}
          field="isAncillaryConstruction"
          type="TOGGLE_STATE"
        />
      )
    },
    {
      label: ['if_is_ancillary_construction'],
      name: ['individual_houses_builder', 'if_is_ancillary_construction'],
      rules: [{ required: isAncillaryConstruction }],
      hidden: !isAncillaryConstruction,
      input: (
        <GenericSelectEnums
          dbKey={['individual_houses_builder', 'if_is_ancillary_construction']}
          enums={enums.ancillaryConstruction}
          tradKeyEnums={tradKeyEnums}
          isMultiple
        />
      )
    },
    {
      label: ['is_project_owner_carry_out_finishing_works'],
      name: [
        'individual_houses_builder',
        'is_project_owner_carry_out_finishing_works'
      ],
      rules: [{ required: true }],
      input: (
        <RadioGroupYesNo
          dbKey={[
            'individual_houses_builder',
            'is_project_owner_carry_out_finishing_works'
          ]}
          tradKeyEnums={tradKeyEnums}
        />
      )
    },
    {
      label: ['is_project_owner_carry_out_construction_works'],
      name: [
        'individual_houses_builder',
        'is_project_owner_carry_out_construction_works'
      ],
      rules: [{ required: true }],
      input: (
        <RadioGroupYesNoReducer
          dbKey={[
            'individual_houses_builder',
            'is_project_owner_carry_out_construction_works'
          ]}
          tradKeyEnums={tradKeyEnums}
          dispatch={dispatch}
          field="isCarryOutWorks"
          type="TOGGLE_STATE"
        />
      )
    },
    {
      label: ['if_is_project_owner_carry_out_work'],
      name: ['individual_houses_builder', 'if_is_project_owner_carry_out_work'],
      rules: [{ required: isCarryOutWorks }],
      hidden: !isCarryOutWorks,
      input: (
        <GenericSelectEnums
          dbKey={[
            'individual_houses_builder',
            'if_is_project_owner_carry_out_work'
          ]}
          enums={enums.projectOwnerCarriedOutWork}
          tradKeyEnums={tradKeyEnums}
          isMultiple
        />
      )
    },
    {
      label: ['current_adress'],
      name: ['individual_houses_builder', 'if_other_carried_out_work']
    },
    {
      label: ['global_carried_out_work_value'],
      name: ['individual_houses_builder', 'global_carried_out_work_value'],
      rules: [
        { required: true },
        eightDigitsTwoDecimals(t, fieldNumberFormatErrorMessage)
      ],
      input: <Input type="number" addonAfter="€" />
    },
    {
      label: ['is_renewable_energies_installations'],
      name: [
        'individual_houses_builder',
        'is_renewable_energies_installations'
      ],
      rules: [{ required: true }],
      input: (
        <RadioGroupYesNoReducer
          dbKey={[
            'individual_houses_builder',
            'is_renewable_energies_installations'
          ]}
          tradKeyEnums={tradKeyEnums}
          dispatch={dispatch}
          field="isRenewableEnergiesWorks"
          type="TOGGLE_STATE"
        />
      )
    },
    {
      label: ['if_is_geothermal_energy_amount'],
      name: ['individual_houses_builder', 'if_is_geothermal_energy_amount'],
      rules: [eightDigitsTwoDecimals(t, fieldNumberFormatErrorMessage)],
      hidden: !isRenewableEnergiesWorks,
      input: <Input type="number" addonAfter="€" defaultValue={0} />
    },
    {
      label: ['if_is_solar_thermal_energy_amount'],
      name: ['individual_houses_builder', 'if_is_solar_thermal_energy_amount'],
      rules: [eightDigitsTwoDecimals(t, fieldNumberFormatErrorMessage)],
      hidden: !isRenewableEnergiesWorks,
      input: <Input type="number" addonAfter="€" defaultValue={0} />
    },
    {
      label: ['if_is_aerothermal_energy_amount'],
      name: ['individual_houses_builder', 'if_is_aerothermal_energy_amount'],
      hidden: !isRenewableEnergiesWorks,
      rules: [eightDigitsTwoDecimals(t, fieldNumberFormatErrorMessage)],
      input: <Input type="number" addonAfter="€" defaultValue={0} />
    },
    {
      label: ['if_is_earth_air_heat_exchanger_amount'],
      name: [
        'individual_houses_builder',
        'if_is_earth_air_heat_exchanger_amount'
      ],
      rules: [eightDigitsTwoDecimals(t, fieldNumberFormatErrorMessage)],
      hidden: !isRenewableEnergiesWorks,
      input: <Input type="number" addonAfter="€" defaultValue={0} />
    },
    {
      label: ['if_is_photovoltaic_panels_amount'],
      name: ['individual_houses_builder', 'if_is_photovoltaic_panels_amount'],
      rules: [eightDigitsTwoDecimals(t, fieldNumberFormatErrorMessage)],
      hidden: !isRenewableEnergiesWorks,
      input: (
        <Input
          type="Number"
          addonAfter="€"
          controls={false}
          onChange={(e) => {
            const amountEngaged = Number(e.target.value);
            if (amountEngaged > 0) {
              dispatch({
                type: 'TOGGLE_STATE',
                field: 'isPhotovoltaicPanels',
                payload: true
              });
            } else {
              const fieldNamePath6 = [
                'individual_houses_builder',
                'if_photovoltaic_installation'
              ];
              form.setFieldsValue({
                [fieldNamePath6[0]]: { [fieldNamePath6[1]]: [] }
              });
              dispatch({
                type: 'TOGGLE_STATE',
                field: 'isPhotovoltaicPanels',
                payload: false
              });
            }
          }}
        />
      )
    },
    {
      label: ['if_photovoltaic_installation'],
      name: ['individual_houses_builder', 'if_photovoltaic_installation'],
      rules: [{ required: isPhotovoltaicPanels }],
      hidden: !isRenewableEnergiesWorks || !isPhotovoltaicPanels,
      input: (
        <GenericSelectEnums
          dbKey={['individual_houses_builder', 'if_photovoltaic_installation']}
          enums={enums.photovoltaicInstallation}
          tradKeyEnums={tradKeyEnums}
          isMultiple
        />
      )
    },
    {
      label: ['construction_contract_signature_date'],
      name: [
        'individual_houses_builder',
        'construction_contract_signature_date'
      ],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['construction_start_date_declaration'],
      name: [
        'individual_houses_builder',
        'construction_start_date_declaration'
      ],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['construction_start_date'],
      name: ['individual_houses_builder', 'construction_start_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['expected_construction_end_date'],
      name: ['individual_houses_builder', 'expected_construction_end_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['agreed_price_ancillary_construction_included'],
      name: [
        'individual_houses_builder',
        'agreed_price_ancillary_construction_included'
      ],
      rules: [
        { required: true },
        eightDigitsTwoDecimals(t, fieldNumberFormatErrorMessage)
      ],
      input: <Input type="number" addonAfter="€" defaultValue={0} />
    },
    {
      label: ['construction_permit_number'],
      name: ['individual_houses_builder', 'construction_permit_number'],
      rules: [{ required: true }]
    },
    {
      label: ['delivered_by'],
      name: ['individual_houses_builder', 'delivered_by'],
      rules: [{ required: true }]
    },
    {
      label: ['construction_guarantee_amount_delivered_by'],
      name: [
        'individual_houses_builder',
        'construction_guarantee_amount_delivered_by'
      ],
      rules: [{ required: true }]
    },
    {
      label: ['agreement_number'],
      name: ['individual_houses_builder', 'agreement_number'],
      rules: [{ required: true }]
    },
    {
      label: ['construction_attestation_number'],
      name: ['individual_houses_builder', 'construction_attestation_number'],
      rules: [{ required: true }]
    },
    {
      label: ['is_location_under_clay_risk_management_plan'],
      name: [
        'individual_houses_builder',
        'is_location_under_clay_risk_management_plan'
      ],
      rules: [{ required: true }],
      input: (
        <RadioGroupYesNo
          dbKey={[
            'individual_houses_builder',
            'is_location_under_clay_risk_management_plan'
          ]}
          tradKeyEnums={tradKeyEnums}
        />
      )
    },
    {
      label: ['is_location_under_seismic_regulation'],
      name: [
        'individual_houses_builder',
        'is_location_under_seismic_regulation'
      ],
      rules: [{ required: true }],
      input: (
        <RadioGroupYesNo
          dbKey={[
            'individual_houses_builder',
            'is_location_under_seismic_regulation'
          ]}
          tradKeyEnums={tradKeyEnums}
        />
      )
    },
    {
      label: ['is_geotechnical_study'],
      name: ['individual_houses_builder', 'is_geotechnical_study'],
      rules: [{ required: true }],
      input: (
        <RadioGroupYesNoReducer
          dbKey={['individual_houses_builder', 'is_geotechnical_study']}
          tradKeyEnums={tradKeyEnums}
          dispatch={dispatch}
          field="isGeotechnicalStudy"
          type="TOGGLE_STATE"
        />
      )
    },
    {
      label: ['if_is_geotechnical_study'],
      name: ['individual_houses_builder', 'if_is_geotechnical_study'],
      rules: [{ required: isGeotechnicalStudy }],
      hidden: !isGeotechnicalStudy,
      input: (
        <GenericSelectEnums
          dbKey={['individual_houses_builder', 'if_is_geotechnical_study']}
          enums={enums.geotechnicalStudy}
          tradKeyEnums={tradKeyEnums}
          isMultiple
        />
      )
    },
    {
      label: ['is_construction_principal_positionning_by_land_surveyor'],
      name: [
        'individual_houses_builder',
        'is_construction_principal_positionning_by_land_surveyor'
      ],
      rules: [{ required: true }],
      input: (
        <RadioGroupYesNo
          dbKey={[
            'individual_houses_builder',
            'is_construction_principal_positionning_by_land_surveyor'
          ]}
          tradKeyEnums={tradKeyEnums}
        />
      )
    },
    {
      label: ['declarant_last_name'],
      name: ['individual_houses_builder', 'declarant_last_name'],
      rules: [{ required: true }],
      startWithDivider: {
        title: t('individual_houses_builders.form.declarant_signature')
      }
    },
    {
      label: ['declarant_first_name'],
      name: ['individual_houses_builder', 'declarant_first_name'],
      rules: [{ required: true }]
    },
    {
      label: ['declarant_function'],
      name: ['individual_houses_builder', 'declarant_function'],
      rules: [{ required: true }]
    },
    {
      label: ['certifying'],
      name: ['individual_houses_builder', 'subcontractor_clauses'],
      rules: [{ required: true }],
      valuePropName: 'checked',
      input: (
        <Checkbox>
          {t('individual_houses_builders.form.subcontractor_clauses')}
        </Checkbox>
      )
    },
    {
      label: [''],
      name: ['individual_houses_builder', 'subcontractor_attestation_supply'],
      rules: [{ required: true }],
      valuePropName: 'checked',
      input: (
        <Checkbox>
          {t(
            'individual_houses_builders.form.subcontractor_attestation_supply'
          )}
        </Checkbox>
      )
    },
    {
      label: ['signed_at'],
      name: ['individual_houses_builder', 'signed_at'],
      rules: [{ required: true }]
    },
    {
      label: ['signed_on'],
      name: ['individual_houses_builder', 'signed_on'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['signature'],
      name: ['individual_houses_builder', 'signature'],
      rules: [{ required: true }],
      input: (
        <FieldSignatureCanvas
          dbKey={['individual_houses_builder', 'signature']}
          signatureId={signatureId}
          dispatch={dispatch}
          field="signatureId"
        />
      )
    },
    {
      label: ['warning'],
      input: (
        <Text type="danger">
          {t('individual_houses_builders.form.legal_warning')}
        </Text>
      )
    }
  ];

  const fetchEnums = useCallback(async () => {
    setIsFieldsLoading(true);
    const data = await getEnums('individual-houses-builders');
    setEnums(data);
    setIsFieldsLoading(false);
  }, [getEnums]);

  useEffect(() => {
    fetchEnums();
  }, [fetchEnums]);

  useEffect(() => {
    const fieldNamePath = ['individual_houses_builder', 'signature'];
    form.setFieldsValue({
      [fieldNamePath[0]]: { [fieldNamePath[1]]: signatureId }
    });
  }, [signatureId, form]);

  return {
    fieldsInformations,
    isFieldsLoading
  };
};
