import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from './errorMessage';
import { useAuthContext } from '../contexts/AuthContext';

export const useDownloadDocument = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  const downloadDocument = async ({
    _id,
    metadata: { originalName },
    contentType
  }) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files/${_id}`,
        responseType: 'blob'
      });
      const blob = new Blob([data], { type: contentType });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      if (originalName.includes('.pdf')) {
        a.target = '_blank';
      } else a.download = originalName;
      a.click();
      notification.success({ message: t('export.messages.export_success') });
    } catch (e) {
      message(e);
    }
  };

  const viewDocument = async (doc) => {
    try {
      let blob = {};
      if (!doc.data) {
        const response = await dispatchAPI('GET', {
          url: `/files/${doc._id}`,
          responseType: 'blob'
        });
        blob = new Blob([response.data], {
          type: response.data.type
        });
        const fileURL = URL.createObjectURL(blob);
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
      }
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  return { downloadDocument, viewDocument };
};
