import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Flex, Tooltip, message } from 'antd';
import { EditOutlined, EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import { TrafficIncident } from '@carbon/icons-react';
import { useAuthContext } from '../../../../contexts/AuthContext';
import usePopConfirm from '../../utils/popConfirms';
import { insuranceList } from '../insuranceList';
import { GenerateGreenCard } from '../../../../utils/GenerateGreenCard';
import { MailingButtonVehicles } from '../../../components/ButtonSpecificDemandVehicles';

const iconSize = 18;

/**
 * Returns an array of action columns for a table.
 *
 * @param {string} baseUrl - The base URL for the routes.
 * @param {boolean} edit - Indicates whether the edit action is enabled.
 * @param {boolean} isArchivatedPage - Indicates whether the page is an archived page.
 * @param {Array} exitDocuments - An array of documents names.
 * @returns {Array} - An array of action columns.
 */
export const useActionColumn = (
  baseUrl,
  edit,
  isArchivatedPage,
  exitDocuments
) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const translation = {
    'other-vehicles': 'other_vehicle',
    'light-vehicles': 'light_vehicle',
    'heavy-vehicles': 'heavy_vehicle'
  };

  const { unarchiveProgram, exitProgramWithFile } = usePopConfirm('vehicles');
  return [
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record, _, idx) => (
        <Flex gap="small" align="center" justify="end" wrap="nowrap">
          <Tooltip title={t(`${translation[baseUrl]}.actions.show`)}>
            <Link
              to={`/programmes/${baseUrl}/show/${record?._id}`}
              style={{ lineHeight: 1 }}
            >
              <EyeOutlined style={{ fontSize: iconSize }} />
            </Link>
          </Tooltip>
          {edit && (
            <Tooltip title={t(`${translation[baseUrl]}.actions.edit`)}>
              <Link
                to={`/programmes/${baseUrl}/edit/${record?._id}`}
                style={{ lineHeight: 1 }}
              >
                <EditOutlined style={{ fontSize: iconSize }} />
              </Link>
            </Tooltip>
          )}
          {!isArchivatedPage && (
            <>
              {insuranceList.includes(record?.contract?.insurance_company) && (
                <>
                  <Tooltip
                    title={t('light_vehicle.actions.generate_green_card')}
                  >
                    <Button
                      type="link"
                      onClick={() =>
                        GenerateGreenCard(record._id, dispatchAPI, t, message)
                      }
                      style={{ padding: 0 }}
                    >
                      <DownloadOutlined style={{ fontSize: iconSize }} />
                    </Button>
                  </Tooltip>
                  <Tooltip title={t('light_vehicle.actions.send_email')}>
                    <MailingButtonVehicles
                      assetId={record?._id}
                      templateName="GreenCard"
                      tradKey="sending_green_card"
                    />
                  </Tooltip>
                </>
              )}
              <Tooltip title={t('light_vehicle.actions.create_claim')}>
                <Link
                  to={`/claims/${baseUrl}/create/${record?._id}`}
                  style={{ lineHeight: 1 }}
                >
                  <TrafficIncident size={20} />
                </Link>
              </Tooltip>
              <MailingButtonVehicles
                assetId={record?._id}
                templateName="Blank"
                tradKey="specific_demand"
              />
            </>
          )}
          {isArchivatedPage
            ? unarchiveProgram({ idWithoutParams: record?._id })
            : exitProgramWithFile({
                exitDocuments,
                idWithoutParams: record?._id,
                rowKey: idx
              })}
        </Flex>
      )
    }
  ];
};
