import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../contexts/AuthContext';
import { useErrorMessage } from './errorMessage';

/**
 * Custom hook for managing history.
 * @hook
 * @returns {Object} An object containing functions related to history management.
 */
export const useHistory = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { user, history, setHistory, dispatchAPI, setHistoryRefresh } =
    useAuthContext();
  const timestamp = dayjs().format('DD/MM/YYYY HH:mm');

  /**
   * Generates a history message with a timestamp.
   * @param {string} messageToSend - The message to be translated and included in the history.
   * @returns {string} The history message with a timestamp.
   */
  const messageAuthor = `par ${user?.first_name} ${user?.last_name}`;
  const historyMessage = (messageToSend) =>
    `${t(messageToSend)} ${messageAuthor} ${timestamp}`;

  const getHistory = async (route, id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${route}/${id}`
      });
      setHistory(data?.history || []);
    } catch (e) {
      message(e);
    }
  };

  const updateHistory = (type) => ({
    history: [...(history || []), historyMessage(`actions.${type}_modified`)]
  });

  const setInitialHistory = (data) => {
    if (data?.history) {
      setHistory(data?.history || []);
    }
  };

  const updateHistoryCustom = async (urlRoute, id, messageToSend) => {
    const historyEntry = `${messageToSend} ${messageAuthor} ${timestamp}`;
    await dispatchAPI('PATCH', {
      url: `${urlRoute}/${id}`,
      body: JSON.stringify({
        values: JSON.stringify({
          history: [...history, historyEntry]
        })
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    setHistoryRefresh((prev) => !prev);
  };

  const createHistory = async (type, programUrl, id) => {
    if (type === 'claim' && programUrl && id) {
      const programHistory = await dispatchAPI('GET', {
        url: `${programUrl}/${id}?fields=history`
      });
      await setHistory(programHistory?.data?.history || []);
      updateHistoryCustom(programUrl, id, t('actions.claim_declared'));
    }
    return {
      history: [historyMessage(`actions.${type}_created`)]
    };
  };

  return {
    historyMessage,
    createHistory,
    updateHistory,
    setInitialHistory,
    updateHistoryCustom,
    getHistory
  };
};
