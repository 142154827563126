import { useParams } from 'react-router-dom';
import { Flex } from 'antd';
import renderAcknowledgeReceiptButton from '../utils/renderClaimButtons/AcknowledgeReceiptButton';
import renderGarageInfos from './utils/GarageInfos';
import renderAcknowledgeComplianceButton from './utils/AcknowledgeComplianceButton';
import renderExpertCallButton from './utils/ExpertCallButton';
import renderAttestationButtons from './utils/AttestationButtons';
import renderEditClaimButton from '../utils/renderClaimButtons/EditClaimButton';
import useRenderConditions from './utils/renderingConditions';
import renderCloseClaimButton from '../utils/renderClaimButtons/CloseClaimButton';
import renderMailingButtonVehicles from './utils/MailingButtonVehicles';
/**
 * Custom hook to generate extra buttons based on current state and conditions.
 * @hook
 * @param {number} current - Currrent page state.
 * @param {function} onChange - function from utils.
 * @param {function} setClosure - Set closure state.
 * @param {Object} vehicleClaim - Vehicle claim data.
 * @param {Object} vehicle - Vehicle data.
 * @param {boolean} isCompliant - Flag indicating compliance status.
 * @param {Function} setIsCompliant - Function to set compliance status.
 * @param {boolean} edit - Flag indicating edit mode.
 * @param {boolean} toEnclosed - Flag indicating closure status.
 * @param {boolean} accountingBalanced - Flag indicating accounting balance status.
 * @param {boolean} feesAppealsBalanced - Flag indicating fees appeals balance status.
 * @returns {Object} Object containing extra buttons JSX element.
 */
export const useExtraButtons = ({
  pathname,
  current,
  onChange,
  setClosure,
  vehicleClaim,
  vehicle,
  isCompliant,
  setIsCompliant,
  edit,
  toEnclosed,
  accountingBalanced,
  feesAppealsBalanced,
  targetUrl,
  setChangingStatus
}) => {
  const { id } = useParams();
  const { renderConditions } = useRenderConditions({
    current,
    isCompliant,
    edit,
    toEnclosed
  });

  const {
    conditionAcknowledgeReceiptButton,
    conditionMailingButtonVehicles,
    conditionGarageInfos,
    conditionAcknowledgeCompliance,
    conditionExpertCall,
    conditionAttestation,
    conditionEditClaim,
    conditionCloseClaim
  } = renderConditions;

  const closureValidation = () => {
    setClosure(true);
    onChange(6, id, edit);
  };

  const extraButtons = (
    <Flex gap="small" wrap="wrap" justify="end">
      {renderAcknowledgeReceiptButton(
        conditionAcknowledgeReceiptButton,
        edit,
        onChange,
        vehicleClaim,
        setChangingStatus
      )}
      {renderMailingButtonVehicles(
        conditionMailingButtonVehicles,
        vehicle,
        targetUrl
      )}
      {renderGarageInfos(conditionGarageInfos, vehicleClaim, setChangingStatus)}
      {renderAcknowledgeComplianceButton(
        conditionAcknowledgeCompliance,
        setIsCompliant,
        vehicleClaim,
        targetUrl,
        setChangingStatus
      )}
      {renderExpertCallButton(conditionExpertCall, vehicleClaim)}
      {renderAttestationButtons(
        conditionAttestation,
        vehicleClaim,
        vehicle,
        targetUrl
      )}
      {renderEditClaimButton(conditionEditClaim, pathname)}
      {renderCloseClaimButton(
        conditionCloseClaim,
        closureValidation,
        accountingBalanced,
        feesAppealsBalanced
      )}
    </Flex>
  );
  return { extraButtons };
};
