import { useCallback } from 'react';
import dayjs from 'dayjs';

/**
 * Custom hook that returns the configuration objects for getting and creating resources.
 *
 * @param {function} dispatch - The dispatch function from the Redux store.
 * @param {string} company - The company ID.
 * @param {string} program - The program type.
 * @param {string} subProgram - The sub-program type.
 * @returns {object} - The configuration objects for getting and creating resources.
 */

export const useConfig = (company, dispatch, programmeType) => {
  const onGetResource = {
    setFields: useCallback(
      (data) => {
        dispatch({
          type: 'SET_COUNT',
          field: 'count',
          payload: data?.environment_insurance?.number_of_sites_to_cover
        });
        return {
          ...data,
          desired_effective_date:
            data.desired_effective_date && dayjs(data.desired_effective_date)
        };
      },
      [company, programmeType]
    )
  };

  const onCreateResource = {
    setBody: useCallback(
      (data) => ({
        ...data,
        company_id: company,
        programme_types: programmeType
      }),
      [company]
    )
  };
  const onUpdateResource = {
    setBody: useCallback(
      (data) => ({
        ...data
      }),
      [company]
    )
  };
  return {
    onGetResource,
    onCreateResource,
    onUpdateResource
  };
};
