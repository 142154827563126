import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback
} from 'react';
import { useAuthContext } from './AuthContext';
import { useSocketContext } from './SocketContext';
import { useMiscellaneousContext } from './MiscellaneousContext';
import { useErrorMessage } from '../utils/errorMessage';

const ConversationContext = createContext();

/**
 * @description Conversation context provider
 * @param {Object} props Props
 * @param {React.ReactNode} props.children Children
 * @returns {React.ReactNode} Conversation context provider
 */
export const ConversationContextProvider = ({ children }) => {
  const { user, token, company, dispatchAPI } = useAuthContext();
  const { socket } = useSocketContext();
  const { refreshCounter, setRefreshCounter } = useMiscellaneousContext();
  const { message } = useErrorMessage();
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState({});
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('collaborators');
  const [refreshConversation, setRefreshConversation] = useState(false);
  const [isThreadOpen, setIsThreadOpen] = useState(false);
  const [refreshDrawer, setRefreshDrawer] = useState(false);

  const getConversations = useCallback(async () => {
    setLoading(true);
    try {
      const limit = conversations.length + 10;
      const personalMessageUrl = `/conversations?company=${company}&users=${user?._id}&limit=${limit}&sort=-last_message_date`;
      const claimMessageUrl = `/conversations/claims?company=${company}&limit=${limit}&sort=-last_message_date`;
      let url = personalMessageUrl;
      if (filter === 'collaborators') {
        url = personalMessageUrl;
      } else if (filter === 'claims') {
        url = claimMessageUrl;
      }
      const { data } = await dispatchAPI('GET', { url });
      setConversations(data);
    } catch (e) {
      message(e);
    } finally {
      setLoading(false);
    }
  }, [company, dispatchAPI, filter, user]);

  const patchToReadConversation = async (id, conversationType) => {
    const url =
      conversationType === 'collaborators'
        ? 'conversations/unread/'
        : 'conversations/claims/unread/';
    try {
      await dispatchAPI('PATCH', {
        url: `${url}${id}`
      });
      setRefreshConversation(!refreshConversation);
      setRefreshCounter(!refreshCounter);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    const fetchConversations = async () => {
      await getConversations();
    };

    if (socket && token) {
      socket.on('newMessage', fetchConversations);
    }
    fetchConversations();
    return () => {
      if (socket) {
        socket.off('newMessage', fetchConversations);
      }
    };
  }, [socket, filter, refreshConversation]);

  return (
    <ConversationContext.Provider
      value={{
        conversations,
        setConversations,
        selectedConversation,
        setSelectedConversation,
        loading,
        setLoading,
        getConversations,
        filter,
        setFilter,
        refreshConversation,
        setRefreshConversation,
        patchToReadConversation,
        isThreadOpen,
        setIsThreadOpen,
        refreshDrawer,
        setRefreshDrawer
      }}
    >
      {children}
    </ConversationContext.Provider>
  );
};

export const useConversationContext = () => {
  const context = useContext(ConversationContext);
  if (!context) {
    throw new Error(
      'useConversationContext must be used within a ConversationProvider'
    );
  }
  return context;
};
