import { PropTypes } from 'prop-types';
import React from 'react';
import { Tooltip } from 'antd';

/**
 * A React component for creating a ToolTip with a container.
 * @component
 * @returns {JSX.Element} The JSX element representing the status filter.
 */

export const TooltipContainer = ({ children, title }) => (
  <Tooltip title={title}>
    <div>{children}</div>
  </Tooltip>
);

TooltipContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};

TooltipContainer.defaultProps = {
  title: null,
  children: null
};
