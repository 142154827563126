import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFields } from './utils/fieldsAssemblyTestingInsurance';
import { useAuthContext } from '../../../contexts/AuthContext';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useConfig } from './utils/createUpdateConfig';

/**
 * Component for creating or updating individual houses builder insurance subscriptions.
 * @component
 * @param {Object} props - The component's properties.
 * @param {string} props.purpose - The purpose of the component, either "create" or "edit".
 * @returns {JSX.Element} The JSX element for creating or updating individual houses builder insurance subscriptions.
 */
export const AssemblyTestingInsuranceCreateUpdate = ({ purpose }) => {
  const { company } = useAuthContext();
  const program = 'ASSEMBLY_TESTING_INSURANCE';
  const { t } = useTranslation();

  const mandatoryDocuments = [
    'company_KBIS_lte_3_months',
    'company_form',
    'sales_contract_copy'
  ];

  const { fieldsInformations } = useFields();

  const config = useConfig(company, program);

  return (
    <CreateUpdateContainer
      title={t(`assembly_testing_insurances.form.${purpose}`)}
      fields={fieldsInformations}
      purpose={purpose}
      tradKey="assembly_testing_insurances"
      baseUrl="assembly-testing-insurances"
      resource="assembly-testing-insurances"
      populate="?populate=documents.file"
      config={config}
      mandatoryDocuments={mandatoryDocuments}
      messageOnSuccess
    />
  );
};

AssemblyTestingInsuranceCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
