import dayjs from 'dayjs';

/**
 * Produces a list content for displaying detailed hole in one information.
 *
 * This hook extracts specified properties from the provided data, formats them
 * and returns an array of objects suitable for rendering in a description list.
 *
 * @hook
 * @param {Object} data - Hole in one data to process.
 * @returns {Array<Object>} - Formatted list content for rendering.
 *
 */

export const useListContent = (data = {}) => {
  const {
    contract,
    company,
    created_at,
    entity,
    site,
    customer_manager,
    unit_manager,
    desired_effective_date,
    golf_name,
    golf_address,
    postal_code,
    city,
    event_start_date,
    event_end_date,
    event_name,
    number_of_men_players,
    number_of_women_players,
    official_length_for_men_hole_in_one,
    official_length_for_women_hole_in_one,
    prize_description,
    prize_value,
    deleted,
    deletion_date_effect
  } = data;
  const customerManagerName = customer_manager
    ? `${customer_manager.first_name} ${customer_manager.last_name}`
    : '-';
  const unit_managerName = unit_manager
    ? `${unit_manager.first_name} ${unit_manager.last_name}`
    : '-';

  const labels = [
    {
      label: 'hole_in_ones.form.contract_number',
      span: 1,
      content: (contract && contract.contract_number) || '-'
    },
    {
      label: 'hole_in_ones.form.company_name',
      span: 1,
      content: (company?.name && company.name) || '-'
    },
    {
      label: 'hole_in_ones.form.date_added',
      span: 1,
      content: (created_at && dayjs(created_at).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'hole_in_ones.form.entity',
      span: 1,
      content: (entity && entity.name) || '-'
    },
    {
      label: 'hole_in_ones.form.site',
      span: 1,
      content: (site && site.name) || '-'
    },
    {
      label: 'hole_in_ones.form.customer_manager',
      span: 1,
      content: (customerManagerName && customerManagerName) || '-'
    },
    {
      label: 'hole_in_ones.form.unit_manager',
      span: 1,
      content: (unit_managerName && unit_managerName) || '-'
    },
    {
      label: 'hole_in_ones.form.desired_effective_date',
      span: 1,
      content:
        (desired_effective_date &&
          dayjs(desired_effective_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'hole_in_ones.form.validity_end_date',
      span: 1,
      content:
        (contract?.validity_end_date &&
          dayjs(contract?.validity_end_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'hole_in_ones.form.golf_name',
      span: 1,
      content: (golf_name && golf_name) || '-'
    },
    {
      label: 'hole_in_ones.form.golf_address',
      span: 1,
      content: (golf_address && golf_address) || '-'
    },
    {
      label: 'hole_in_ones.form.postal_code',
      span: 1,
      content: (postal_code && postal_code) || '-'
    },
    {
      label: 'hole_in_ones.form.city',
      span: 1,
      content: (city && city) || '-'
    },
    {
      label: 'hole_in_ones.form.event_start_date',
      span: 1,
      content:
        (event_start_date && dayjs(event_start_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'hole_in_ones.form.event_end_date',
      span: 1,
      content:
        (event_end_date && dayjs(event_end_date).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'hole_in_ones.form.event_name',
      span: 1,
      content: (event_name && event_name) || '-'
    },
    {
      label: 'hole_in_ones.form.number_of_men_players',
      span: 1,
      content: (number_of_men_players && number_of_men_players) || '-'
    },
    {
      label: 'hole_in_ones.form.number_of_women_players',
      span: 1,
      content: (number_of_women_players && number_of_women_players) || '-'
    },
    {
      label: 'hole_in_ones.form.official_length_for_men_hole_in_one',
      span: 1,
      content:
        (official_length_for_men_hole_in_one &&
          `${official_length_for_men_hole_in_one} m`) ||
        '-'
    },
    {
      label: 'hole_in_ones.form.official_length_for_women_hole_in_one',
      span: 1,
      content:
        (official_length_for_women_hole_in_one &&
          `${official_length_for_women_hole_in_one} m`) ||
        '-'
    },
    {
      label: 'hole_in_ones.form.prize_description',
      span: 1,
      content: (prize_description && prize_description) || '-'
    },
    {
      label: 'hole_in_ones.form.prize_value',
      span: 1,
      content: (prize_value && `${prize_value} €`) || '-'
    }
  ];

  if (deleted) {
    labels.push({
      label: 'hole_in_ones.form.deleted',
      span: 1,
      content:
        (deletion_date_effect &&
          dayjs(deletion_date_effect).format('DD/MM/YYYY')) ||
        '-'
    });
  }

  return labels;
};
