import PropTypes from 'prop-types';
import { Typography, Flex } from 'antd';
import { FileTextOutlined, CarOutlined } from '@ant-design/icons';

/**
 * Renders a component to display notification content for a vehicle.
 * @component
 * @param {Object} props - The props object.
 * @param {string} props.title - The title of the notification.
 * @param {Object} props.item - The item object containing information about the vehicle.
 * @param {string} props.item.ref - The reference of the vehicle.
 * @param {string} props.item.type - The type of the vehicle.
 * @param {Array} props.programme - An array of programme objects containing vehicle details.
 * @returns {JSX.Element} JSX element representing the notification content for the vehicle.
 */

export const CreateNotifContentVehicle = ({ title, item, programme }) => {
  const matchingProgramme = programme?.find(
    (match) => match._id === item?.type
  );

  const vehiculeBrand = matchingProgramme?.vehicle_brand;
  const vehiculeNumber = matchingProgramme?.registration_or_serial_number;
  const vehiculeModel = matchingProgramme?.vehicle_model;

  return (
    <Flex justify="space-between" align="center" wrap="wrap" gap="small">
      <Flex align="center" gap="small">
        <FileTextOutlined />
        <Typography.Text strong style={{ marginRight: 4 }}>
          {title}
        </Typography.Text>
      </Flex>
      <Flex align="center" gap="small">
        <CarOutlined />
        <Typography.Text>
          {vehiculeBrand}
          {vehiculeNumber}
          {vehiculeModel}
        </Typography.Text>
      </Flex>
    </Flex>
  );
};

CreateNotifContentVehicle.propTypes = {
  title: PropTypes.string.isRequired,
  item: PropTypes.shape({
    ref: PropTypes.PropTypes.string.isRequired,
    type: PropTypes.string
  }).isRequired,
  programme: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
