import { useEffect } from 'react';
import { Tag } from 'antd';
import { FileExclamationOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { claimStatus } from '../../../utils/constants/tagColors';
import { useFiltersDataFetch } from '../../../components/ColumnsDynamicFilters/useFiltersDatasFetch';
import { renderEntities } from '../../../utils/formatters/entities';

/**
 * Generates custom table columns configuration for a claims list.
 * @hook
 * @param {string} resourceName - The name of the resource.
 * @param {string} dbExtraQueryFilter - The extra query filter to be used when fetching the data.
 * @param {string} populate - The fields to populate when fetching the data.
 * @returns {Array} An array of column configuration objects.
 */
export const useColumns = (resourceName, dbExtraQueryFilter, populate) => {
  const { t } = useTranslation();
  const { fetchFilters, filters, loading } = useFiltersDataFetch(
    resourceName,
    dbExtraQueryFilter,
    resourceName,
    populate
  );

  useEffect(() => {
    fetchFilters();
  }, []);

  const columns = [
    {
      key: 'documents',
      dataIndex: 'missing_documents',
      render: (missing_documents) =>
        missing_documents ? (
          <FileExclamationOutlined
            style={{ fontSize: 18, color: 'var(--warningColor)' }}
          />
        ) : null
    },
    {
      title: t('claims.form.entity'),
      key: 'entity',
      dataIndex: ['entity'],
      filters: (filters?.entity || []).map((entity) => ({
        text: entity.name,
        value: entity._id
      })),
      sorter: true,
      render: (entity) => renderEntities(entity)
    },
    {
      title: t('claims.form.site'),
      key: 'site',
      dataIndex: ['site', 'name'],
      filters: filters?.site?.map((site) => ({
        text: site.name,
        value: site._id
      })),
      sorter: true
    },
    {
      title: t('claims.form.client_reference'),
      key: 'client_reference',
      dataIndex: 'client_reference',
      filters: (filters?.client_reference || []).map((client_reference) => ({
        text: client_reference,
        value: client_reference
      })),
      render: (client_reference) => client_reference || '-',
      sorter: true
    },
    {
      title: t('claims.form.claim_status'),
      key: 'claim_status',
      dataIndex: 'claim_status',
      render: (status) =>
        status ? (
          <Tag color={claimStatus[status]}>
            {t(`claims.form.${status}`, {
              defaultValue: status
            })}
          </Tag>
        ) : (
          <Tag color="orange">{t('claims.form.reception_pending')}</Tag>
        ),
      sorter: true,
      filters: (filters?.claimStatus || []).map((status) => ({
        text: t(`claims.form.${status}`),
        value: status
      }))
    },
    {
      title: t('claims.form.unit_counter'),
      key: 'unit_counter',
      dataIndex: 'unit_counter',
      filters: (filters?.unit_counter || []).map((unit_counter) => ({
        text: unit_counter,
        value: unit_counter
      })),
      render: (unit_counter) => unit_counter || '-',
      sorter: true
    },
    {
      title: t('claims.form.claim_type'),
      key: 'claim_type',
      dataIndex: 'claim_type',
      filters: (filters?.claim_type || []).map((claim_type) => ({
        text: t(`claims.form.${claim_type}`),
        value: claim_type
      })),
      sorter: true,
      render: (claim_type) =>
        claim_type ? t(`claims.form.${claim_type}`) : '-'
    },
    {
      title: t('claims.form.insurance_company_reference'),
      key: 'insurance_company_reference',
      dataIndex: 'insurance_company_reference',
      filters: (filters?.insurance_company_reference || []).map(
        (insurance_company_reference) => ({
          text: insurance_company_reference,
          value: insurance_company_reference
        })
      ),
      render: (insurance_company_reference) =>
        insurance_company_reference || '-',
      sorter: true
    },
    {
      title: t('claims.form.sinister_date'),
      key: 'sinister_date',
      dataIndex: 'sinister_date',
      filters: (filters?.sinister_date || []).map((sinister_date) => ({
        text: dayjs(sinister_date).format('DD/MM/YYYY'),
        value: sinister_date
      })),
      render: (sinister_date) =>
        sinister_date ? dayjs(sinister_date).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('claims.form.sinister_location'),
      key: 'sinister_location',
      dataIndex: 'sinister_location',
      filters: (filters?.sinister_location || []).map((value) => ({
        text: value,
        value
      })),
      render: (sinister_location) => sinister_location || '-',
      sorter: true
    },
    {
      title: t('claims.form.commentaries'),
      key: 'commentaries',
      dataIndex: 'commentaries',
      filters: (filters?.commentaries || []).map((commentary) => ({
        text: commentary,
        value: commentary
      })),
      render: (commentary) => commentary || '-'
    }
  ];
  return [columns, loading];
};
