import dayjs from 'dayjs';

/**
 * Produces a list content for displaying detailed event cancellation information.
 *
 * This hook extracts specified properties from the provided data, formats them
 * and returns an array of objects suitable for rendering in a description list.
 *
 * @hook
 * @param {Object} data - event cancellation data to process.
 * @returns {Array<Object>} - Formatted list content for rendering.
 *
 */

export const useListContentEventAddress = (data = {}) => {
  const {
    event_location_city,
    event_location_postal_code,
    event_location_address,
    event_location_addresses_additional
  } = data;

  const labels = [
    {
      label: 'event_cancellations.form.event_location_city',
      span: 1,
      content: (event_location_city && event_location_city) || '-'
    },
    {
      label: 'event_cancellations.form.event_location_postal_code',
      span: 1,
      content: (event_location_postal_code && event_location_postal_code) || '-'
    },
    {
      label: 'event_cancellations.form.event_location_address',
      span: 1,
      content: (event_location_address && event_location_address) || '-'
    },
    {
      label: 'event_cancellations.form.event_location_addresses_additional',
      span: 1,
      content:
        (event_location_addresses_additional &&
          event_location_addresses_additional) ||
        '-'
    }
  ];
  return labels;
};

/**
 * Produces a list content for displaying detailed event cancellation information.
 *
 * This hook extracts specified properties from the provided data, formats them
 * and returns an array of objects suitable for rendering in a description list.
 *
 * @hook
 * @param {Object} data - event cancellation data to process.
 * @returns {Array<Object>} - Formatted list content for rendering.
 *
 */

export const useListContentPostponeDates = (data = {}) => {
  const { possible_postponement_start_date, possible_postponement_end_date } =
    data;
  const labels = [
    {
      label: 'event_cancellations.form.possible_postponement_start_date',
      span: 1,
      content:
        (possible_postponement_start_date &&
          dayjs(possible_postponement_start_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'event_cancellations.form.possible_postponement_end_date',
      span: 1,
      content:
        (possible_postponement_end_date &&
          dayjs(possible_postponement_end_date).format('DD/MM/YYYY')) ||
        '-'
    }
  ];
  return labels;
};
