import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { message as messageANTD } from 'antd';
import { useHistory } from '../../../../utils/historyUtils';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { handleFormDataWithFiles } from '../../../../components/CreateUpdateContainerV3/utils/handleFormDataWithFiles';
import { checkMandatoryDocuments } from '../../../../components/CreateUpdateContainerV3/utils/checkMandatoryDocuments';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { checkDraggerTypes } from '../../../../components/CreateUpdateContainerV3/utils/checkDraggerTypes';

/**
 * Custom hook for deleting a program.
 * @hook
 * @param {Array} draggerFilesList - The list of files in the dragger.
 * @param {Object} filesConfiguration - The configuration for the files.
 * @param {Function} setIsSubmitting - A function to set the submitting state.
 * @param {Array} mandatoryDocuments - The list of mandatory documents.
 * @param {string} resourceName - The name of the resource.
 * @param {string} resourceId - The ID of the resource.
 * @param {Function} setVisible - A function to set the visibility state.
 * @param {Function} setIsOpen - A function to set the open state.
 * @returns {Object} - An object containing the handleSubmit function.
 */
export const useDeleteProgram = (
  draggerFilesList,
  filesConfiguration,
  setIsSubmitting,
  mandatoryDocuments,
  resourceName,
  resourceId,
  setVisible,
  setIsOpen
) => {
  const { t } = useTranslation();
  const { updateHistoryCustom } = useHistory();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const navigate = useNavigate();

  const config = {
    onUpdateResource: {
      setBody: (data) => ({
        ...data
      })
    }
  };

  const updateResource = async (body, files) => {
    setIsSubmitting(true);
    const formData = new FormData();

    handleFormDataWithFiles(
      files,
      draggerFilesList,
      formData,
      filesConfiguration,
      'edit'
    );
    const values =
      config.onUpdateResource && config.onUpdateResource.setBody
        ? config.onUpdateResource.setBody(body)
        : body;

    const mandatoryDocumentsArePresent = checkMandatoryDocuments(
      mandatoryDocuments,
      filesConfiguration
    );

    const valuesToAppend = {
      ...values,
      missing_documents: mandatoryDocumentsArePresent
    };

    formData.append(
      'values',
      JSON.stringify({
        ...valuesToAppend
      })
    );

    try {
      await dispatchAPI('PATCH', {
        url: `${resourceName}/${resourceId}?isExitProgramme=true`,
        body: formData,
        params: { exitProgramme: true }
      });
      setVisible(false);
      messageANTD.success(t('datatable.column.action.delete.sortie_ok'));
      updateHistoryCustom(resourceName, resourceId, t('actions.exited'));
      navigate(-1);
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  const handleSubmit = async (values) => {
    const mandatoryDocumentsArePresent = checkMandatoryDocuments(
      mandatoryDocuments,
      filesConfiguration
    );
    const boolean = checkDraggerTypes(draggerFilesList, filesConfiguration);
    switch (true) {
      case !mandatoryDocumentsArePresent:
        return setIsOpen(true);
      case !boolean:
        return message(t('missing_types'));
      default:
        await updateResource(values, []);
        return true;
    }
  };
  return { handleSubmit };
};
