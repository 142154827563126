import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useColumns } from '../utils/columnsCivilLiability';
import { useActionColumn } from '../../../components/actionColumnSubProgGenerated';
import { ListResource } from '../../../../components/ListResource/ListResource';
import { useAuthContext } from '../../../../contexts/AuthContext';

/**
 * `ListCivilLiabilityPro` is a React component designed to list and manage light vehicles.
 * It provides filtering options by vehicle type, entity, missing documents and site.
 * The component fetches the data based on the filters applied and displays it in a tabular format.
 *
 * @component
 * @returns {ReactElement} Rendered `ListCivilLiabilityPro` component.
 */

export const ListCivilLiabilityPro = () => {
  const { company, permissions, verifPermissions } = useAuthContext();
  const [edit, setEdit] = useState(true);
  const columns = useColumns();
  const [isArchivatedPage, setArchivatedPage] = useState(false);
  const actionColumn = useActionColumn(edit, isArchivatedPage);
  const { t } = useTranslation();

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setEdit(
        !verifPermissions(
          `/programmes/civil-liability-pro/edit/`,
          permissions?.notPermittedRoutes
        )
      );
    }
  }, [permissions]);

  return (
    <ListResource
      extraQuery={`company_id=${company}&civil_liability_type=CIVIL_LIABILITY_PRO`}
      resourceName="civil-liability"
      exportUrl={`exports/company/${company}/sub-programme/CIVIL_LIABILITY_PRO`}
      tradKey="civil_liability_pro"
      columns={[...columns, ...actionColumn]}
      noCreateButton
      populate="contract,company_id,entity"
      sheetName={t('civil-liability-executive.title')}
      headers={[]}
      editAction={false}
      showAction={false}
      duplicateAction={false}
      printAction={false}
      deleteAction={false}
      withUploadButton={false}
      setIsArchivatedPage={setArchivatedPage}
      customActionColumn
      scroll={{ x: 3600 }}
    />
  );
};
