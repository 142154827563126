import PropTypes from 'prop-types';
import { useState, useCallback, useRef, useEffect } from 'react';
import { Tooltip } from 'antd';
import { FormOutlined, MailOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { Mailing } from '../programmes/VehiclesFleets/components/Modals/Mailing';
import { useErrorMessage } from '../../utils/errorMessage';

const iconSize = 18;

export const MailingButtonVehicles = ({ assetId, templateName, tradKey }) => {
  const isMounted = useRef(false);
  const [isModalMailingOpen, setIsModalMailingOpen] = useState(false);
  const [templateMail, setTemplateMail] = useState();
  const [asset, setAsset] = useState();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();

  const getTemplateMail = useCallback(async () => {
    if (!isMounted.current) return;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/templates-mails/name/${templateName}?populate=documents,documents.file`
      });
      if (isMounted.current) {
        setTemplateMail(data);
      }
    } catch (e) {
      if (isMounted.current) {
        message(e);
      }
    }
  }, [templateName]);

  const getVehicle = useCallback(async () => {
    if (!isMounted.current) return;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/vehicles/${assetId}?populate=customer_manager,unit_manager,contract,entity,entity.company,site,principal_driver,secondary_drivers`
      });
      if (isMounted.current) {
        setAsset(data);
      }
    } catch (e) {
      if (isMounted.current) {
        message(e);
      }
    }
  }, [assetId]);

  const closeModal = () => {
    setIsModalMailingOpen(false);
  };

  const onClick = async () => {
    await Promise.all([getTemplateMail(), getVehicle()]);
    setIsModalMailingOpen(true);
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <>
      {tradKey &&
        (templateName === 'GreenCard' ? (
          <Tooltip title={t('light_vehicle.actions.send_email')}>
            <MailOutlined
              style={{ fontSize: iconSize }}
              onClick={() => {
                onClick();
              }}
            />
          </Tooltip>
        ) : (
          <Tooltip title={t('light_vehicle.actions.specific_demand')}>
            <FormOutlined
              style={{ fontSize: iconSize }}
              onClick={() => {
                onClick();
              }}
            />
          </Tooltip>
        ))}

      <Mailing
        isModalMailingOpen={isModalMailingOpen}
        setIsModalMailingOpen={setIsModalMailingOpen}
        idWithoutParams={assetId}
        templateMail={templateMail}
        datas={asset}
        templateName={tradKey}
        isModal
        closeModal={closeModal}
      />
    </>
  );
};

MailingButtonVehicles.propTypes = {
  assetId: PropTypes.string.isRequired,
  templateName: PropTypes.string.isRequired,
  tradKey: PropTypes.string.isRequired
};
