import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Input, Form, Button, Row } from 'antd';
import {
  CloseOutlined,
  DeleteOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import { formItemLayout } from '../../utils/constants/formLayout';

/**
 * Renders a form with multiple fields generation based on the provided configuration.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.fields - An array of field configurations.
 * @param {string} props.dbKey - The database key for the form list.
 * @param {string} props.tradKey - The translation key for the form.
 * @param {string} props.additionalTranslation - Additional translation key for the form.
 * @param {boolean} props.initialiceOpen - Flag to indicate if the form should be initially open.
 * @param {boolean} props.agriculture - Flag to indicate if the form is for agriculture.
 * @param {Object} props.form - The form object.
 * @param {Array} props.guaranteeFields - An array of guarantee field configurations.
 * @param {Function} props.AgricultureDocs - A function to render additional agriculture documents.
 * @returns {JSX.Element} The rendered form component.
 */
export const MultipleFormFieldsGeneration = ({
  fields,
  dbKey,
  tradKey,
  additionalTranslation,
  initialiceOpen,
  agriculture,
  form,
  guaranteeFields,
  AgricultureDocs
}) => {
  const { t } = useTranslation();

  // useDraggerProps(draggerFilesList, setDraggerFilesList);

  const calculationsConditions = (name) => {
    if (
      name.includes('surface') ||
      name.includes('average_yield') ||
      name.includes('average_sales_price')
    ) {
      return true;
    }
    return false;
  };

  const handleChange = (e, idx, name) => {
    const { id, value } = e.target;
    if (calculationsConditions(id)) {
      const surface = form.getFieldsValue().cultures[idx].surface || 0;
      const average_yield =
        form.getFieldsValue().cultures[idx].average_yield || 0;
      const average_sales_price =
        form.getFieldsValue().cultures[idx].average_sales_price || 0;

      form.setFieldValue(
        ['cultures', idx, 'capital_to_cover'],
        surface * average_sales_price * average_yield
      );
    }
    if (name === 'existing_guarantee' && value === 'no') {
      form.setFieldValue(['cultures', idx, 'garantie_from_whom'], null);
      form.setFieldValue(['cultures', idx, 'precise_garantie_existant'], null);
    }
  };

  const culturesExistCondition = (cond, idx) => {
    if (
      cond.cultures &&
      idx < cond.cultures.length &&
      cond.cultures[idx]?.existing_guarantee === 'yes'
    ) {
      return true;
    }
    return false;
  };

  return (
    <Form.List
      name={dbKey}
      initialValue={(initialiceOpen || agriculture) && [{}]}
    >
      {(fieldsData, { add, remove }) => (
        <>
          {fieldsData.map((field, index) => (
            <Form.Item
              key={field.key}
              className={agriculture ? 'agriculture-form' : 'license-form'}
            >
              <Row justify="space-between">
                {!agriculture && (
                  <span>
                    {t(`${tradKey}.form.${additionalTranslation || dbKey}`)}
                    &nbsp;
                    {index + 1}
                  </span>
                )}
                {!agriculture && (
                  <CloseOutlined onClick={() => remove(index)} />
                )}
              </Row>
              {fields.map(({ name, ...restField }) => (
                <>
                  <Form.Item
                    name={[field.name, ...name]}
                    label={t(`${tradKey}.form.${name[0]}`)}
                    {...restField}
                    onChange={(e) => handleChange(e, index, name[0])}
                    {...formItemLayout}
                  >
                    {restField.input ? restField.input : <Input />}
                  </Form.Item>
                  {guaranteeFields &&
                    name[0] === 'existing_guarantee' &&
                    culturesExistCondition(form.getFieldsValue(), index) &&
                    guaranteeFields.map(
                      ({ name: extraName, ...subRestField }) => (
                        <Row key={extraName}>
                          <Form.Item
                            name={[field.name, extraName]}
                            label={t(`${tradKey}.form.${extraName}`)}
                            {...subRestField}
                            onChange={(e) => handleChange(e, index, extraName)}
                            {...formItemLayout}
                          >
                            {subRestField.input ? (
                              subRestField.input
                            ) : (
                              <Input />
                            )}
                          </Form.Item>
                        </Row>
                      )
                    )}
                </>
              ))}
              {AgricultureDocs && AgricultureDocs(index)}
              {agriculture && index !== fieldsData.length - 1 && (
                <Form.Item>
                  <Button onClick={() => remove(index)} block>
                    <DeleteOutlined /> {t(`${tradKey}.form.remove`)}
                  </Button>
                </Form.Item>
              )}
            </Form.Item>
          ))}
          <Form.Item>
            <Button
              type={agriculture ? 'primary' : 'dashed'}
              onClick={() => add()}
              block
            >
              <PlusCircleOutlined /> {t(`${tradKey}.form.add`)}
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

MultipleFormFieldsGeneration.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.arrayOf(PropTypes.string).isRequired
    })
  ).isRequired,
  dbKey: PropTypes.string.isRequired,
  tradKey: PropTypes.string.isRequired,
  additionalTranslation: PropTypes.string,
  form: PropTypes.objectOf({}).isRequired,
  initialiceOpen: PropTypes.bool,
  agriculture: PropTypes.bool,
  guaranteeFields: PropTypes.arrayOf(PropTypes.shape({})),
  AgricultureDocs: PropTypes.func
};

MultipleFormFieldsGeneration.defaultProps = {
  additionalTranslation: undefined,
  initialiceOpen: false,
  agriculture: false,
  guaranteeFields: [],
  AgricultureDocs: null
};
