// TODO: DO NOT delete the commented code before customer testing
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainerV3/CreateUpdateContainerContract';
import { useAuthContext } from '../../contexts/AuthContext';

import { useFields } from './fieldsDocuments';
import { formatDatePickerDate } from '../../utils/formatDatePickerDate';
/**
 * `DocumentsCreateUpdate` is a React component for creating or updating documents.
 *
 * Depending on the provided `purpose` prop, this component can be used to either create or edit a document.
 * The component utilizes the `CreateUpdateContainer` for rendering the input fields and managing form actions.
 *
 * Note: This component does not support managing files.
 *
 * @component
 * @param {object} props The props for the component.
 * @param {string} props.purpose Indicates the purpose of the form. It should be either 'create' or 'edit'.
 * @returns {React.ElementType} A form for creating or updating a document.
 */

export const DocumentsCreateUpdate = ({ purpose }) => {
  const [selectedType, setSelectedType] = useState('');
  const [selectedProgramme, setSelectedProgramme] = useState([]);
  const [isNotMandatory, setIsNotMandatory] = useState(true);
  const [contractId, setContractId] = useState('');
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [idWithoutParams, setIdWithoutParams] = useState(null);
  const [resetField, setResetField] = useState(false);
  const [newPurpose, setNewPurpose] = useState('');
  const [baseUrl, setBaseUrl] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [contractNumber, setContractNumber] = useState('');
  const [containsContractDocuments, setContainsContractDocuments] =
    useState(false);
  const [isVehicleAttestation, setIsVehicleAttestation] = useState(false);
  const [programmeTypes, setProgrammeTypes] = useState(false);
  const [currentDates, setCurrentDates] = useState({
    validity_start_date: null
  });

  const { fields } = useFields(
    setSelectedType,
    selectedType,
    selectedProgramme,
    setSelectedProgramme,
    isNotMandatory,
    setIsNotMandatory,
    contractId,
    setContractId,
    setContractNumber,
    isFieldsLoading,
    setIsFieldsLoading,
    isDisabled,
    containsContractDocuments,
    isVehicleAttestation,
    setProgrammeTypes,
    currentDates,
    setCurrentDates
  );
  const { company, companyName } = useAuthContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const config = {
    onGetResource: {
      setFields: (data) => {
        setSelectedType(data.document_type);
        setSelectedProgramme(data.programme_types);
        setIsNotMandatory(data.is_pending);
        if (purpose === 'edit' && data.document_type === undefined) {
          setSelectedType('CONTRACT');
          setIsDisabled(true);
        }
        if (data.documents && data.documents.length > 0) {
          const isContractDocuments = data.documents.some(
            (item) =>
              item.document_type.includes('CONTRACT') &&
              item.is_pending === false
          );
          setContainsContractDocuments(isContractDocuments);
        }
        if (data?.validity_start_date) {
          setCurrentDates({
            validity_start_date: formatDatePickerDate(data.validity_start_date)
          });
        }

        return {
          ...data,
          validity_start_date:
            data.validity_start_date && dayjs(data.validity_start_date),
          validity_end_date:
            data.validity_end_date && dayjs(data.validity_end_date),
          is_pending: Boolean(data.is_pending),
          ...(purpose === 'edit' &&
            data.document_type === undefined && { document_type: 'CONTRACT' }),
          contract_number: data.contract_number
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        is_pending: Boolean(data.is_pending),
        company_Id: company
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        is_pending: Boolean(data.is_pending),
        company_Id: company,
        ...(contractNumber !== '' && { contract_number: contractNumber })
      })
    }
  };

  useEffect(() => {
    if (purpose !== 'edit') {
      if (selectedType === '') {
        setNewPurpose('create');
        setIdWithoutParams(null);
        setContractId('');
        setBaseUrl('contracts');
        setIsNotMandatory(true);
      } else if (selectedType === 'CONTRACT') {
        setNewPurpose('create');
        setIdWithoutParams(null);
        setContractId('');
        setIsDisabled(true);
        setBaseUrl('contracts');
        setIsNotMandatory(false);
      } else if (selectedType === 'ATTESTATION') {
        setNewPurpose('edit');
        setResetField(false);
        setBaseUrl('contracts/documents');
        setIsDisabled(true);
        setIsNotMandatory(false);
        setIsVehicleAttestation(programmeTypes);
      } else if (selectedType === 'GUARANTEES') {
        setNewPurpose('edit');
        setResetField(false);
        setBaseUrl('contracts/documents');
        setIsDisabled(true);
        setIsNotMandatory(false);
      } else if (selectedType === 'CALL_FOR_CONTRIBUTION') {
        navigate({
          pathname: '/contributions/create'
        });
      }
      if (contractId !== '') {
        setIdWithoutParams(contractId);
      }
    }
    if (purpose === 'edit') {
      setBaseUrl('contracts/documents');
    }
  }, [selectedType, contractId, programmeTypes]);

  return (
    <Skeleton loading={isFieldsLoading}>
      {baseUrl !== '' ? (
        <CreateUpdateContainer
          fields={fields}
          purpose={newPurpose || purpose}
          urlFileKeys="contracts"
          baseUrl={baseUrl}
          resource="documents"
          config={config}
          extraTitle={
            <Row justify="center">{`${t('folder')} ${companyName}`}</Row>
          }
          withFilesManager={false}
          idWithoutParams={idWithoutParams}
          resetField={resetField}
        />
      ) : null}
    </Skeleton>
  );
};

DocumentsCreateUpdate.propTypes = {
  purpose: PropTypes.string
};

DocumentsCreateUpdate.defaultProps = {
  purpose: null
};
