import { useCallback } from 'react';

/**
 * Custom hook for fetching main information.
 *
 * @param {Object} options - The options for the hook.
 * @param {Function} options.setIsLoading - The function to set the loading state.
 * @param {Function} options.dispatchAPI - The function to dispatch API requests.
 * @param {string} options.entryId - The ID of the entry.
 * @param {Function} options.setProductInformations - The function to set the product information.
 * @param {Function} options.message - The function to display error messages.
 * @param {Object} options.isMounted - The object to check if the component is mounted.
 * @param {string} options.productAPI - The API endpoint for the product.
 * @returns {Function} - The callback function for fetching main information.
 */
export const useFetchMainInformation = ({
  setIsLoading,
  dispatchAPI,
  ID,
  populateProductShow,
  setProductInformations,
  message,
  isMounted,
  productAPI
}) =>
  useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${productAPI}/${ID}?populate=${populateProductShow}`
      });
      if (isMounted.current) {
        setProductInformations({
          ...data,
          entity: Array.isArray(data?.entity)
            ? data?.entity
            : // ? data?.entity?.map((entityItem) => entityItem?.name)
              data?.entity?.name,
          site: data?.site?.name
        });
      }
    } catch (e) {
      if (isMounted.current) message(e);
    } finally {
      if (isMounted.current) setIsLoading(false);
    }
  }, [
    setIsLoading,
    dispatchAPI,
    ID,
    populateProductShow,
    setProductInformations,
    message,
    isMounted
  ]);
