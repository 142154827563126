import PropTypes from 'prop-types';
import { Typography, Flex } from 'antd';
import { CertificateCheck } from '@carbon/icons-react';

/**
 * Renders a component to display notification content for a title amendment subscription.
 * @component
 * @param {Object} props - The props object.
 * @param {string} props.title - The title of the notification.
 * @returns {JSX.Element} JSX element representing the notification content for the title amendment subscription.
 */

export const CreateNotifTitleAmendmentSubscription = ({ title }) => (
  <Flex justify="space-between" align="center" wrap="wrap" gap="small">
    <Flex align="center" gap="small">
      <CertificateCheck />
      <Typography.Text strong style={{ marginRight: 4 }}>
        {title}
      </Typography.Text>
    </Flex>
  </Flex>
);

CreateNotifTitleAmendmentSubscription.propTypes = {
  title: PropTypes.string.isRequired
};
