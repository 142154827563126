import { useState, useRef, useEffect } from 'react';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * @hook useHandleUpdatePermissions
 * @description Custom hook to update all permissions
 * @returns {Object} Returns object with handleUpdatePermissions function and loading state
 */

export const useHandleUpdatePermissions = () => {
  const { dispatchAPI, company } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(false);

  const handleUpdatePermissions = async () => {
    if (!isMounted.current) return;
    setLoading(true);
    try {
      await dispatchAPI('PATCH', {
        url: `permissions/patchAllPermissions/company/${company}`
      });
      if (isMounted.current) {
        notification.success({
          message: t('export.messages.permissions.success')
        });
      }
    } catch (e) {
      if (e.response) {
        message(e.response.status);
      } else {
        message(t('message.500'));
      }
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return { handleUpdatePermissions, loading };
};
