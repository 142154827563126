import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';

const { Option } = Select;

/**
 * `SelectTypeAcountancy` is a React component that displays a dropdown selector allowing users to select
 * a document type.
 *
 * This component is useful when you want to filter or categorize documents by their type.
 *
 * @component
 * @param {object} props - The properties passed to the component.
 * @param {function} props.onChange - A callback function that's invoked when the selected value changes.
 *
 * @returns {JSX.Element} Returns a rendered dropdown selector containing document types.
 *
 * @example
 * <SelectTypeAcountancy onChange={(value) => console.log("Selected value:", value)} />
 */

export const SelectTypeAcountancy = ({ onChange }) => {
  const { t } = useTranslation();
  const options = ['ATTESTATION', 'CALL_FOR_CONTRIBUTION'];

  return (
    <Select
      allowClear
      placeholder={t('documents.filter_by_type')}
      onChange={onChange}
    >
      {options &&
        options.map((o) => (
          <Option key={o} value={o}>
            {t(`documents.status.${o}`)}
          </Option>
        ))}
    </Select>
  );
};

SelectTypeAcountancy.propTypes = {
  onChange: PropTypes.func.isRequired
};
