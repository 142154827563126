import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useHistory } from '../../../utils/historyUtils';

/**
 * Component for creating and updating transport claims.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the transport claim.
 * @param {Function} props.useFields - The function to use for retrieving fields information.
 * @param {Array<string>} props.mandatoryDocuments - The mandatory documents for the transport claim.
 * @param {string} props.populate - The populate parameter for retrieving related data.
 * @param {string} props.sub_programme - The sub-programme for the transport claim.
 * @returns {JSX.Element} The JSX element for creating and updating transport claims.
 */
export const TransportsCreateUpdate = ({
  purpose,
  useFields,
  mandatoryDocuments,
  populate,
  sub_programme,
  fromAdValorem
}) => {
  const { dispatchAPI, company } = useAuthContext();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const [isOther, setIsOther] = useState(false);
  const [transportGoods, setTransportGoods] = useState();
  const { fieldsInformations } = useFields(isOther, setIsOther);
  const { setInitialHistory, createHistory, updateHistory } = useHistory();

  const getTransports = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `transports/${id}?populate=${populate}`
      });
      setTransportGoods(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getTransports();
    })();
  }, [id]);

  const config = {
    onGetResource: {
      setFields: (data) => {
        if (data.claim_type === 'OTHER') {
          setIsOther(true);
        }
        setInitialHistory(data);
        return {
          ...data,
          sinister_date: data.sinister_date && dayjs(data.sinister_date)
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        entry_id: transportGoods?._id,
        company,
        contract: transportGoods?.contract?._id,
        opening_date: dayjs(),
        city: transportGoods?.city,
        entity: transportGoods?.contract?.entity,
        related_contract_entities: !fromAdValorem
          ? transportGoods?.contract?.related_contract_entities
          : undefined,
        site: transportGoods?.contract?.site,
        transport_sub_programmes: sub_programme,
        ...createHistory('claim', 'transports', id)
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        ...updateHistory('claim')
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fieldsInformations}
      purpose={purpose}
      baseUrl="transport_claims"
      resource="claims"
      config={config}
      populate="?populate=documents.file"
      mandatoryDocuments={mandatoryDocuments}
    />
  );
};

TransportsCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired,
  useFields: PropTypes.func.isRequired,
  mandatoryDocuments: PropTypes.shape([
    PropTypes.string,
    PropTypes.string,
    PropTypes.string
  ]).isRequired,
  populate: PropTypes.string.isRequired,
  sub_programme: PropTypes.string.isRequired,
  fromAdValorem: PropTypes.bool
};

TransportsCreateUpdate.defaultProps = {
  fromAdValorem: false
};
