import PropTypes from 'prop-types';
import { Select, Form } from 'antd';

const { Option } = Select;
const { Item } = Form;

/**
 * `SelectSiteV2` is a component that renders a dropdown with site names depending on the current context.
 *
 * @component
 * @param {Object} props - The component properties.
 * @param {string} props.dbKey - The database key for the item.
 * @param {boolean} [props.isDisabled=false] - Flag indicating if the Select should be disabled.
 * @param {string} [props.currentEntityContext=null] - The current entity context.
 * @param {Array<Object>} [props.entitiesContext=[]] - An array of entity contexts.
 * @returns {JSX.Element} The rendered `Select` component containing site names.
 */
export const SelectSiteV2 = ({
  dbKey,
  isDisabled,
  currentEntityContext,
  entitiesContext
}) => {
  const options = () => {
    if (!entitiesContext) return null;

    return entitiesContext.map((entity) => {
      if (
        (Array.isArray(currentEntityContext) &&
          entity?._id !== currentEntityContext[0]) ||
        (!Array.isArray(currentEntityContext) &&
          entity?._id !== currentEntityContext)
      )
        return null;
      return (entity?.sites || []).map((site) => (
        <Option key={site?._id} value={site?._id} title={site?.name}>
          {site?.name}
        </Option>
      ));
    });
  };

  return (
    <Item name={dbKey}>
      <Select
        disabled={isDisabled}
        showSearch
        filterOption={(inputValue, option) =>
          (option?.title ?? '').toLowerCase().includes(inputValue.toLowerCase())
        }
      >
        {options()}
      </Select>
    </Item>
  );
};

SelectSiteV2.propTypes = {
  dbKey: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  currentEntityContext: PropTypes.arrayOf(PropTypes.string),
  entitiesContext: PropTypes.arrayOf(PropTypes.shape({}))
};

SelectSiteV2.defaultProps = {
  isDisabled: false,
  currentEntityContext: PropTypes.string,
  entitiesContext: []
};
