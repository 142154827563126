import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useColumns } from '../columns';
import { useActionColumn } from '../../actionColumn';
import { ListResource } from '../../../../components/ListResource/ListResource';
import { SelectByEntity } from '../../../components/SelectByEntity';
import { SelectBySite } from '../../../components/SelectBySite';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { FilterByMissingDocuments } from '../../../components/FilterByMissingDocuments';
import { StatusFilter } from '../../../components/StatusFilter';
import { claimStatus } from '../../../../utils/constants/tagColors';

/**
 * Displays a list of Other construction claims with relevant columns and additional filters.
 *
 * @component
 * @returns {React.ReactNode} List of Other construction claims.
 */
export const ListCivilLiabilityPro = () => {
  const [entity, setEntity] = useState('');
  const [site, setSite] = useState('');
  const [isMissingDocuments, setIsMissingDocuments] = useState('');
  const [statusTag, setStatusTag] = useState('');
  const actionColumnUrl = 'civil-liability-pro';
  const [isClosedButton, setIsClosedButton] = useState(false);
  const url = 'civil_liability_claims';
  const enums = 'claimStatus';

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const currentPage = Number(params.get('p') || 1);
  const pageSize = Number(params.get('pS') || 10);

  const columns = useColumns();
  const actionColumn = useActionColumn(actionColumnUrl, url);
  const { company } = useAuthContext();
  const { t } = useTranslation();

  let filterParameter = `entity=${entity}__claim_status=${statusTag}__missing_documents=${isMissingDocuments}`;
  const onFilterEntity = (entityId) => {
    setEntity(entityId || '');
  };
  const dbExtraQueryFilter = `company_id=${company}&civil_liability_sub_programmes=CIVIL_LIABILITY_PRO&${
    isClosedButton ? 'claim_status=CLOSED' : 'claim_status!=CLOSED'
  }`;

  const onFilterSite = (siteId) => {
    setSite(siteId || '');
  };

  const onFilterMissingDocuments = (missingDocuments) => {
    setIsMissingDocuments(missingDocuments === false ? '' : missingDocuments);
  };

  const onFilterStatus = (status) => {
    setStatusTag(status || '');
  };

  useEffect(() => {
    if (site !== '') {
      filterParameter += `__site=${site}__&p=${currentPage}&pS=${pageSize}`;
    } else {
      filterParameter += `__&p=${currentPage}&pS=${pageSize}`;
    }
    navigate({
      pathname: '/claims/civil-liability-pro',
      search: `?f=${filterParameter}`
    });
  }, [entity, site, isMissingDocuments, statusTag]);
  return (
    <ListResource
      extraQuery={dbExtraQueryFilter}
      exportUrl={`exports/company/${company}/civil/programme-type/civil_liability_claim`}
      importUrl={`imports-claim-civils/company/${company}`}
      exportTemplateUrl="export-claim-templates/template/civil-claim"
      resourceName="civil_liability_claims"
      tradKey="claims"
      columns={[...columns, ...actionColumn]}
      withExtraFilters={
        <>
          <SelectByEntity onChange={onFilterEntity} />
          <SelectBySite onChange={onFilterSite} />
          <StatusFilter
            onChange={onFilterStatus}
            url={url}
            enums={enums}
            tagMapping={claimStatus}
            translationKey="claims"
          />
          <FilterByMissingDocuments onChange={onFilterMissingDocuments} />
        </>
      }
      populate="entity,site,entry_id,company_id,related_contract_entities"
      editAction={false}
      showAction={false}
      deleteAction={false}
      noCreateButton
      noArchiveFilter
      setIsClosedButton={setIsClosedButton}
      sheetName={t('sheet.civillaims')}
      headers={[]}
      onlyExport
      customActionColumn
      scroll={{ x: 1800 }}
    />
  );
};
