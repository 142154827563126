import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Row, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewerWithoutForm';
import { useAuthContext } from '../../../contexts/AuthContext';

import useFields from './utils/fieldsHoleInOne';
import { HoleInOneContext } from './HoleInOneContext';
import { useHistory } from '../../../utils/historyUtils';

/**
 * `HoleInOneCreateUpdate` is a React component for creating or updating documents.
 *
 * Depending on the provided `purpose` prop, this component can be used to either create or edit a document.
 * The component utilizes the `CreateUpdateContainer` for rendering the input fields and managing form actions.
 *
 * Note: This component does not support managing files.
 *
 * @component
 * @param {object} props The props for the component.
 * @param {string} props.purpose Indicates the purpose of the form. It should be either 'create' or 'edit'.
 * @returns {React.ElementType} A form for creating or updating a document.
 */

export const HoleInOneCreateUpdate = ({ purpose }) => {
  const resource = 'hole_in_ones';
  const [form] = Form.useForm();
  const { fieldsGolf } = useFields(form);
  const { setInitialHistory, createHistory, updateHistory } = useHistory();
  const { company, companyName } = useAuthContext();
  const { t } = useTranslation();
  const { setCurrentEntityContext } = useContext(HoleInOneContext);

  const config = {
    onGetResource: {
      setFields: (data) => {
        setInitialHistory(data);
        return {
          ...data,
          desired_effective_date:
            data.desired_effective_date && dayjs(data.desired_effective_date),
          date_added: data.date_added && dayjs(data.date_added),
          event_start_date:
            data.event_start_date && dayjs(data.event_start_date),
          event_end_date: data.event_end_date && dayjs(data.event_end_date),
          entry_date: data.entry_date && dayjs(data.entry_date)
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        programmes_types: 'HOLE_IN_ONE',
        company,
        ...createHistory('program')
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        ...updateHistory('program')
      })
    }
  };
  return (
    <CreateUpdateContainer
      form={form}
      fields={fieldsGolf}
      purpose={purpose}
      baseUrl="hole-in-ones"
      resource={resource}
      config={config}
      setCurrentEntityContext={setCurrentEntityContext}
      extraTitle={<Row justify="center">{`${t('folder')} ${companyName}`}</Row>}
      withFilesManager={false}
    />
  );
};

HoleInOneCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
