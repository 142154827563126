import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row, Col, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  WarningOutlined,
  EditOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../components';
import { routes } from '../../../utils/constants/adminRoutes';
import { useListContent } from './utils/ListContentTransports';
import { MailingButton } from '../../Mailing/MailingButton';
import { ClaimButton } from '../../components/ClaimButton';
import { TooltipContainer } from '../../components/TooltipContainer';
import { useDeleteTransports } from '../../../utils/deleteTransports';
import { History } from '../../components/History';

/**
 * Represents the `ShowOtherTransport` component that displays details of a datas.
 *
 * Fetches and displays a datas's details based on its ID, which is derived from the current route parameters.
 * The component also provides action buttons for editing, regenerating, downloading, and sending the datas.
 *
 * @component
 * @example
 * ```jsx
 * <ShowOtherTransport />
 * ```
 */

export const ShowOtherTransport = ({ subProgramme }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [datas, setDatas] = useState();
  const listContent = useListContent(datas);
  const { deleteTransportsAPI } = useDeleteTransports();

  const getDocument = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/transports/${id}?populate=contract,entity,company_Id,unit_manager,customer_manager,contract.documents`
      });
      setDatas(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  const deleteFreightedGoods = async () => {
    await deleteTransportsAPI(id);
  };

  useEffect(() => {
    (async () => {
      await getDocument();
    })();
  }, [getDocument]);

  useEffect(() => {
    if (datas) setIsDeleted(datas?.deleted);
  }, [datas]);

  const formatUrlPart = (str) => str.toLowerCase().replace(/_/g, '-');
  let editUrl;
  if (subProgramme && subProgramme.length > 0) {
    editUrl = `${routes.PROGRAMMES}/${formatUrlPart(subProgramme)}/edit/${id}`;
  }

  return (
    <>
      <PageHeaderCustom
        title={`${t('number')} ${datas?.unit_counter || 'xxxx'} - ${t(
          `${subProgramme}.title`
        )}`}
        extra={
          <>
            {!isDeleted && editUrl && (
              <Link
                to={{
                  pathname: editUrl
                }}
              >
                <Button>
                  <EditOutlined />
                  {`${t('buttons.edit')} `}
                </Button>
              </Link>
            )}
            <ClaimButton id={id} url={subProgramme} claimType="transport" />
            <MailingButton
              asset={datas}
              templateName="Blank"
              tradKey="specific_demand"
              resource="Transport"
              baseUrl="transports"
            />
            <TooltipContainer title={t('buttons.remove')}>
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={deleteFreightedGoods}
                icon={<WarningOutlined />}
              >
                <Button type="link" danger>
                  {`${t('buttons.delete')} `}
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </TooltipContainer>
          </>
        }
      />
      <ContentCustom>
        <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
          <Row gutter={[16, 16]}>
            <Col Col xs={24} xl={16}>
              <Card>
                <DescriptionList
                  data={listContent}
                  translate
                  layout="vertical"
                  column={4}
                  title={t('transports.form.main_informations')}
                />
              </Card>
            </Col>
            <Col xs={24} xl={8}>
              <Row gutter={[0, 16]}>
                <Col xs={24}>
                  <Card title={t('subscriptions.form.history')} size="small">
                    <History route="Transport" />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Skeleton>
      </ContentCustom>
    </>
  );
};

ShowOtherTransport.propTypes = {
  subProgramme: PropTypes.string.isRequired
};
