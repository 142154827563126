import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Col, Modal, Row, Spin, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useColumns } from './columnsDocuments';
import { useActionColumn } from './actionColumn';
import { ListResource } from '../../components/ListResource/ListResource';
import { SelectByEntity } from '../components/SelectByEntity';
import { SelectByType } from './utils/SelectByType';
import { useAuthContext } from '../../contexts/AuthContext';
import { EntitiesDisplay } from './utils/EntitiesDisplay';

/**
 * Represents the `ListDocuments` component that displays a list of documents.
 *
 * It provides filtering options based on entity and document type. Filter changes result in a
 * navigation update, changing the URL to reflect the applied filters.
 *
 * The list can be extended with custom action columns. Data for the documents is fetched based on the company
 * in the auth context.
 *
 * @component
 * @returns {React.ElementType} A list of documents.
 */

export const ListDocuments = () => {
  const { t } = useTranslation();
  const { company, permissions, verifPermissions, dispatchAPI, user } =
    useAuthContext();
  const navigate = useNavigate();
  const columns = useColumns();

  const [loaded, setLoaded] = useState(false);
  const [isCustomerManager, setIsCustomerManager] = useState(false);
  const [entity, setEntity] = useState('');
  const [extraFilter, setExtraFilter] = useState('');
  const [show, setShow] = useState(true);
  const [edit, setEdit] = useState(true);
  const [extraQuery, setExtraQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const accountancyExtraQuery = `company_Id=${company}&document_type=ATTESTATION,CALL_FOR_CONTRIBUTION`;
  const managerExtraQuery = `company_Id=${company}&document_type=ATTESTATION,GUARANTEES`;
  const adminExtraQuery = `company_Id=${company}&document_type=CONTRACT,AMENDMENT,GUARANTEES,ATTESTATION,CALL_FOR_CONTRIBUTION`;
  let filterParameter = `entity=${entity}__`;

  const generateAttestation = async (id) => {
    const modal = Modal.info({
      content: (
        <Row
          style={{ flexDirection: 'column' }}
          justify="center"
          align="middle"
        >
          <Col>
            <Spin />
          </Col>
          <Col>{t('files.modal.document-loading')}</Col>
        </Row>
      ),
      footer: null,
      maskClosable: false
    });
    try {
      const { data, headers } = await dispatchAPI('GET', {
        url: `documents/attestation/${id}`,
        responseType: 'arraybuffer'
      });
      const blob = new Blob([data], {
        type: headers['content-type']
      });
      modal.destroy();
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = headers['content-disposition'];
      document.body.appendChild(a);

      a.click();

      window.URL.revokeObjectURL(url);
    } catch (e) {
      modal.destroy();
      message.error(e);
    }
  };
  const actionColumn = useActionColumn(edit, show, generateAttestation);

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setShow(
        !verifPermissions(
          'documents/contract/show',
          permissions?.notPermittedRoutes
        )
      );
      setEdit(
        !verifPermissions(
          'documents/contract/edit',
          permissions?.notPermittedRoutes
        )
      );
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [permissions]);

  const onFilterEntity = (entityId) => {
    setEntity(entityId || '');
  };

  useEffect(() => {
    const usersRoles = ['users:USER', 'admins:ADMIN', 'users:SUPER-USER'];
    if (usersRoles.includes(user.role)) {
      setExtraQuery(
        `company_Id=${company}&document_type=ATTESTATION,GUARANTEES`
      );
      setIsCustomerManager(true);
      setIsLoading(false);
    } else if (user.role === 'users:ACCOUNTANCY-USER') {
      setExtraQuery(accountancyExtraQuery);
      setIsLoading(false);
    } else {
      setExtraQuery(adminExtraQuery);
      setIsLoading(false);
    }
  }, [permissions]);

  const onFilterType = (typeId) => {
    if (isCustomerManager) {
      setExtraQuery(
        typeId
          ? `company_Id=${company}&document_type=${typeId}`
          : managerExtraQuery
      );
    } else {
      setExtraQuery(
        typeId
          ? `company_Id=${company}&document_type=${typeId}`
          : adminExtraQuery
      );
    }
  };

  useEffect(() => {
    filterParameter = `entity=${entity}__`;
    setExtraFilter(filterParameter);
    navigate({
      pathname: '/documents',
      search: `?f=${filterParameter}`
    });
  }, [entity]);

  const renderEntitiesDisplay = (record) => <EntitiesDisplay record={record} />;

  return (
    <>
      <Spin spinning={isLoading} fullscreen="true" />
      {extraQuery && (
        <ListResource
          extraQuery={extraQuery}
          dataToFetch={`documents/user/${user._id}`}
          resourceName="documents"
          customActionColumn
          columns={[...columns, ...actionColumn]}
          withExtraFilters={
            <>
              <SelectByEntity onChange={onFilterEntity} isDocumentFilter />
              <SelectByType
                onChange={onFilterType}
                permissions={isCustomerManager}
              />
            </>
          }
          setLoaded={() => setLoaded()}
          loaded={loaded}
          populate="entity,file,previous_amendment"
          withCreateButton={edit}
          noExitSwitch
          withUploadButton={edit}
          onDoubleClickAction={false}
          expandable={{
            expandedRowRender: (record) => renderEntitiesDisplay(record)
          }}
          extraFilter={extraFilter}
          scroll={{ x: 3500 }}
        />
      )}
    </>
  );
};
