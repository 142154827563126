import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';
import ULogo from '../../assets/images/logo-outline-green.svg';
import { ContentCustom } from '../../components/ContentCustom/ContentCustom';

/**
 * Renders the ThanksGolf component.
 * This component displays a thank you message and a button to return to the website.
 * @component
 * @returns {JSX.Element} The rendered ThanksGolf component.
 */
const ThanksGolf = () => {
  const { t } = useTranslation();
  return (
    <ContentCustom
      style={{
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '2rem'
      }}
    >
      <div
        style={{
          width: '40%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          gap: '2rem'
        }}
      >
        <img src={ULogo} alt="Unit" />
        <h2>{t('agriculture.form.unit_remercie')}</h2>
        <p style={{ fontSize: '18px' }}>
          {t('interruption_golf.final_thanks')}
        </p>
        <Button
          type="primary"
          onClick={() => {
            window.location.href = 'https://www.jdg-assurances.fr/';
          }}
        >
          {`${t('agriculture.form.retourne_site')} >`}
        </Button>
      </div>
    </ContentCustom>
  );
};

export default ThanksGolf;
