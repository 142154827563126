import { Route, Routes } from 'react-router-dom';
import { ListCallForContribution } from './ListCallForContribution';
import { ContributionsCreateUpdate } from './ContributionsCreateUpdate';
import { ContributionsEdit } from './ContributionsEdit';
import { Exception } from '../../components';
import { CallForContributionProvider } from './CallForContributionContext';

/**
 * A router component for handling civil-liability-related routes.
 *
 * @component
 * @returns {JSX.Element} The JSX element representing the CivilLiabilityProRouter.
 */

export const ContributionsRouter = () => (
  <CallForContributionProvider>
    <Routes>
      <Route
        path="create"
        element={<ContributionsCreateUpdate purpose="create" />}
      />
      <Route path="edit/:id" element={<ContributionsEdit purpose="edit" />} />
      <Route index element={<ListCallForContribution />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </CallForContributionProvider>
);
