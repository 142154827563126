import { Link } from 'react-router-dom';
import { Flex, Tooltip } from 'antd';
import {
  CheckOutlined,
  CloseCircleOutlined,
  EditOutlined,
  EyeOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { handleStatusUtils } from './handleStatusUtils';
import { useAuthContext } from '../../../contexts/AuthContext';

const iconSize = 18;

/**
 * Generates the action column configuration for a table.
 *
 * @returns {Array} An array containing the action column configuration.
 * @param {boolean} edit - Whether to show the edit icon or not.
 * @param {boolean} accept - Whether to show the accept icon or not.
 */
export const useActionColumn = (edit, accept) => {
  const { onChange } = handleStatusUtils();
  const { user } = useAuthContext();
  const { t } = useTranslation();

  const getLinkPath = (record) => {
    let linkPath;

    switch (record?.programme_types) {
      case 'CIVIL_LIABILITY':
        linkPath = `/subscriptions/civil-liability/edit/${record?._id}`;
        break;
      case 'TRANSPORT':
        linkPath = `/subscriptions/transport/edit/${record?._id}`;
        break;
      case 'CONSTRUCTION':
        if (record?.sub_programme[0] === 'INDIVIDUAL_HOUSES_BUILDER') {
          linkPath = `/subscriptions/individual-houses-builder/edit/${record?._id}`;
        } else {
          linkPath = `/subscriptions/construction/edit/${record?._id}`;
        }
        break;
      case 'CYBER_INSURANCE':
        linkPath = `/subscriptions/cyber-insurance/edit/${record?._id}`;
        break;
      case 'HARDWARE_INSURANCE':
        linkPath = `/subscriptions/hardware-insurance/edit/${record?._id}`;
        break;
      case 'PROFESSIONAL_MULTIRISK_INSURANCE':
        linkPath = `/subscriptions/professional-multirisk-insurance/edit/${record?._id}`;
        break;
      case 'ASSEMBLY_TESTING_INSURANCE':
        linkPath = `/subscriptions/assembly-testing-insurance/edit/${record?._id}`;
        break;
      case 'LEGAL_PROTECTION_INSURANCE':
        linkPath = `/subscriptions/legal-protection-insurance/edit/${record?._id}`;
        break;
      case 'ENVIRONMENT_INSURANCE':
        linkPath = `/subscriptions/environment-insurance/edit/${record?._id}`;
        break;
      default:
        break;
    }

    return linkPath;
  };

  return [
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <Flex gap="small" align="center" justify="end" wrap="nowrap">
          <Link
            to={`/subscriptions/show/${record?._id}`}
            style={{ lineHeight: 1 }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          {user.role === 'admins:SUPER-ADMIN' ||
          user.role === 'admins:ADMIN' ? (
            <>
              {edit && (
                <Link to={getLinkPath(record)} style={{ lineHeight: 1 }}>
                  <EditOutlined style={{ fontSize: iconSize }} />
                </Link>
              )}
              {record.status !== 'accepted' && record.status !== 'rejected'
                ? accept && (
                    <>
                      <Tooltip
                        title={t('subscriptions.form.accept_request')}
                        placement="topLeft"
                      >
                        <CheckOutlined
                          style={{ fontSize: iconSize }}
                          onClick={() => {
                            onChange(4, record._id);
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        title={t('subscriptions.form.reject_request')}
                        placement="topLeft"
                      >
                        <CloseCircleOutlined
                          style={{ fontSize: iconSize, color: 'red' }}
                          onClick={() => {
                            onChange(3, record._id);
                          }}
                        />
                      </Tooltip>
                    </>
                  )
                : null}
            </>
          ) : null}
        </Flex>
      )
    }
  ];
};
