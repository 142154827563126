import { useCallback, useState, useContext } from 'react';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { CallForContributionContext } from '../CallForContributionContext';

/**
 * `useHandlePaidItem` is a custom React hook that handles the payment of a selected item.
 * @hook
 * @param {Array<string>} paidItem - The item to be paid.
 * @param {function} setForceRefresh - The function to set the force refresh state.
 * @param {boolean} forceRefresh - The force refresh state.
 * @returns {object} Returns an object with the loading state and the function to handle the payment.
 */
export const useHandlePaidItem = ({
  paidItem,
  setSelectedRowKeysPaidItem,
  setSelectedRowKeysIssuedCall
}) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { forceRefresh, setForceRefresh } = useContext(
    CallForContributionContext
  );
  const [isLoadingPaiement, setIsLoadingSending] = useState(false);

  const handlePaidItem = useCallback(async () => {
    setIsLoadingSending(true);
    try {
      await dispatchAPI('PATCH', {
        url: `/call-for-contributions/paid-status/${paidItem}`
      });
    } catch (e) {
      message(e);
    } finally {
      setIsLoadingSending(false);
      setSelectedRowKeysPaidItem([]);
      setSelectedRowKeysIssuedCall([]);
      setForceRefresh(!forceRefresh);
    }
  }, [dispatchAPI, paidItem, message]);

  return { isLoadingPaiement, handlePaidItem };
};
