import { Route, Routes } from 'react-router-dom';
import { ListCyberInsuranceClaims } from './ListCyberInsuranceClaims';
import { CyberInsuranceClaimsCreateUpdate } from './CyberInsuranceClaimsCreateUpdate';
import { CyberInsuranceClaimsShow } from './CyberInsuranceClaimsShow';
import { Exception } from '../../../components';

/**
 * Provides routing for managing event cancellations, including create, edit, show, and list views.
 *
 * @component
 * @returns {React.ReactNode} Routes for different views related to event cancellations.
 */
export const CyberInsuranceClaimsRouter = () => (
  <Routes>
    <Route
      path="create/:id"
      element={<CyberInsuranceClaimsCreateUpdate purpose="create" />}
    />
    <Route
      path="edit/:id"
      element={<CyberInsuranceClaimsCreateUpdate purpose="edit" />}
    />
    <Route path="show/:id" element={<CyberInsuranceClaimsShow />} />
    <Route index element={<ListCyberInsuranceClaims />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
