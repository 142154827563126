/**
 * Constants for light vehicles.
 * @constant constants for diffenent types of vehicles
 * @namespace lightVehiclesConstants
 */
export const lightVehiclesConstants = {
  draggerFilesKeysOverRide: [
    'vehicle_registration_document',
    'order_form',
    'reason_of_exit',
    'other'
  ]
};

export const otherVehiclesConstants = {
  draggerFilesKeysOverRide: [
    'vehicle_registration_document',
    'order_form',
    'other',
    'purchase_invoice',
    'reason_of_exit'
  ]
};

export const heavyVehicleConstants = {
  draggerFilesKeysOverRide: [
    'vehicle_registration_document',
    'order_form',
    'reason_of_exit',
    'other'
  ]
};

export const vehiclesExitDocuments = [
  'sale_certificate',
  'recovery_certificate'
];
