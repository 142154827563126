import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Divider, Row } from 'antd';
import { DescriptionList } from '../../../../components';
import { useListContentAddress } from '../listContents/listContentSubEnvironmentInsurance';

/**
 * Renders a card component that displays addresses.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.data - The array of addresses to display.
 * @returns {JSX.Element} The rendered AddressDisplay component.
 */
export const AddressDisplay = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Card>
      {data &&
        data.map((address, index) => (
          <Row key={`${t(`environment_insurances.form.site`)} ${index + 1}`}>
            <DescriptionList
              data={useListContentAddress(address)}
              translate
              layout="vertical"
              title={`${t(`environment_insurances.form.site`)} ${index + 1}`}
              column={4}
            />
            {index < data.length - 1 && <Divider />}
          </Row>
        ))}
    </Card>
  );
};

AddressDisplay.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
