import { Input, Select, Spin } from 'antd';
import { useEffect } from 'react';
import { useContactUtils } from '../../utils/contactUtils';

/**
 * Custom hook for managing recipient inputs.
 * @hook
 * @param {string} baseUrl - The base URL for API requests.
 * @returns {Object} - An object containing the SelectReceipientMail and ReceptorInput components.
 */
export const useRecipientInputs = (baseUrl) => {
  const {
    getCategoryContact,
    currentContact,
    isContactLoading,
    getClientManagers,
    allManagers,
    setIsContactLoading,
    handleManagerSelect
  } = useContactUtils();

  useEffect(() => {
    setIsContactLoading(true);
    (async () => {
      await getClientManagers();
      await getCategoryContact(baseUrl);
      setIsContactLoading(false);
    })();
  }, []);

  const SelectReceipientMail = () =>
    !isContactLoading ? (
      <Select
        style={{ width: '100%' }}
        mode="tags"
        maxCount={1}
        onChange={handleManagerSelect}
      >
        {allManagers?.allManagers?.map((manager) => (
          <Select.Option
            key={manager.id}
            value={`${manager.first_name} ${manager.last_name}`}
          >
            {manager.first_name} {manager.last_name}
          </Select.Option>
        ))}
      </Select>
    ) : (
      <Spin />
    );

  const ReceptorInput = () =>
    !isContactLoading ? (
      <Input defaultValue={currentContact} style={{ width: '100%' }} />
    ) : (
      <Spin />
    );

  return { SelectReceipientMail, ReceptorInput, currentContact };
};
