import React from 'react';
import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ContentCustom, PageHeaderCustom } from '../../components';

const { Title, Paragraph } = Typography;

export const LegalNotice = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeaderCustom title={t(`legal.title`)} />
      <ContentCustom>
        <Row gutter={[24, 24]}>
          <Col xs={24} lg={16} xl={12} xxl={10}>
            <Title level={5}>{t('legal_notice.title1')}</Title>
            <Paragraph>{t('legal_notice.paragraph1')}</Paragraph>
            <Paragraph>{t('legal_notice.paragraph2')}</Paragraph>

            <Title level={5}>{t('legal_notice.title2')}</Title>
            <Paragraph>
              {t('legal_notice.paragraph3')}
              {t('legal_notice.paragraph3bis')}
            </Paragraph>

            <Title level={5}>{t('legal_notice.title3')}</Title>
            <Paragraph>{t('legal_notice.paragraph4')}</Paragraph>

            <Title level={5}>{t('legal_notice.title4')}</Title>
            <Paragraph>{t('legal_notice.paragraph5')}</Paragraph>

            <Title level={5}>{t('legal_notice.title5')}</Title>
            <Paragraph>{t('legal_notice.paragraph6')}</Paragraph>

            <Title level={5}>{t('legal_notice.title6')}</Title>
            <Paragraph>{t('legal_notice.paragraph7')}</Paragraph>

            <Title level={5}>{t('legal_notice.title7')}</Title>
            <Paragraph>{t('legal_notice.paragraph8')}</Paragraph>

            <Title level={5}>{t('legal_notice.title8')}</Title>
            <Paragraph>{t('legal_notice.paragraph9')}</Paragraph>

            <Title level={5}>{t('legal_notice.title9')}</Title>
            <Paragraph>{t('legal_notice.paragraph10')}</Paragraph>

            <Title level={5}>{t('legal_notice.title10')}</Title>
            <Paragraph>{t('legal_notice.paragraph11')}</Paragraph>

            <Title level={5}>{t('legal_notice.title11')}</Title>
            <Paragraph>{t('legal_notice.paragraph12')}</Paragraph>

            <Title level={5}>{t('legal_notice.title12')}</Title>
            <Paragraph>{t('legal_notice.paragraph13')}</Paragraph>
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};
