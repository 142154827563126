import { useListContent } from './listContent';
import { useExtraButtons } from '../extraButtons';
import { VehiclesClaimShow } from '../vehiclesClaimShow';

/**
 * Renders the OtherVehiclesShow component.
 * @returns {React.Component} The rendered HeavyVehiclesShow component.
 */
export const OtherVehiclesShow = () =>
  VehiclesClaimShow({
    useListContent,
    pathname: 'other-vehicles',
    useExtraButtons
  });
