import { useState } from 'react';
import { DatePicker, Radio, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { SelectManager } from '../../components/SelectManager';

const { Group } = Radio;
const { Option } = Select;
const { TextArea } = Input;

/**
 * A hook for generating form fields configuration based on the provided parameters.
 *
 * @hook
 * @param {boolean} isOther - Indicates whether the "claim_description_if_other" field is visible.
 * @param {Function} setIsComplaint - A function to set the visibility fields in dragger.
 * @returns {Object} An object containing an array of form fields configuration.
 */

export const useFields = (
  golfName,
  eventName,
  eventStartDate,
  eventEndDate,
  purpose
) => {
  const { t } = useTranslation();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const dateFormat = 'DD-MM-YYYY';

  const fieldsInformations = [
    {
      label: ['customer_manager'],
      name: ['customer_manager'],
      rules: [{ required: true }],
      input: (
        <SelectManager
          dbKey="customer_manager"
          setIsFieldsLoading={setIsFieldsLoading}
        />
      )
    },
    {
      label: ['unit_manager'],
      name: ['unit_manager'],
      input: (
        <SelectManager
          dbKey="unit_manager"
          setIsFieldsLoading={setIsFieldsLoading}
        />
      )
    },
    {
      label: ['insurance_company_reference'],
      name: ['insurance_company_reference']
    },
    {
      label: ['client_reference'],
      name: ['client_reference']
    },
    {
      label: ['golf_name'],
      name: ['golf_name'],
      input: <Input placeholder={golfName} disabled />
    },
    {
      label: ['event_name'],
      name: ['event_name'],
      input: <Input placeholder={eventName} disabled />
    },
    {
      label: ['event_start_date'],
      name: ['event_start_date'],
      input:
        purpose === 'create' ? (
          <Input placeholder={eventStartDate} disabled />
        ) : (
          <DatePicker format={dateFormat} style={{ width: '100%' }} disabled />
        )
    },
    {
      label: ['event_end_date'],
      name: ['event_end_date'],
      input:
        purpose === 'create' ? (
          <Input placeholder={eventEndDate} disabled />
        ) : (
          <DatePicker format={dateFormat} style={{ width: '100%' }} disabled />
        )
    },
    {
      label: ['sinister_date'],
      name: ['sinister_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['sinister_location'],
      name: ['sinister_location'],
      rules: [{ required: true }]
    },
    {
      label: ['personal_injury'],
      name: ['personal_injury'],
      rules: [{ required: true }],
      input: (
        <Group>
          <Radio value="YES">{t('claims.form.yes')}</Radio>
          <Radio value="NO">{t('claims.form.no')}</Radio>
        </Group>
      )
    },
    {
      label: ['claim_type'],
      name: ['claim_type'],
      input: (
        <Select placeholder={t(`claims.form.FINANCIAL_LOSS`)} disabled>
          <Option key="FINANCIAL_LOSS" value="FINANCIAL_LOSS">
            {t(`claims.form.FINANCIAL_LOSS`)}
          </Option>
        </Select>
      )
    },
    {
      label: ['commentaries'],
      name: ['commentaries'],
      input: <TextArea style={{ resize: 'none' }} />
    },
    {
      label: ['pictures_damages_caused'],
      name: ['pictures_damages_caused'],
      input: 'File',
      maxFilesCount: 5,
      multipleFiles: true,
      startWithDivider: {
        title: t('claims.form.pictures')
      },
      endWithDivider: {
        title: t('claims.form.documents')
      }
    }
  ];

  return {
    fieldsInformations,
    isFieldsLoading
  };
};
