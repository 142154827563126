import { MultiSelectEntityByContract } from '../../components/MultiSelectEntityByContract';

export const useFields = (idWithoutParams) => {
  const fields = [
    {
      name: ['entity'],
      rules: [{ required: true }],
      input: (
        <MultiSelectEntityByContract
          dbKey="entity"
          contractId={idWithoutParams}
        />
      )
    },
    {
      name: ['attestation'],
      input: 'File',
      maxFilesCount: 1,
      multipleFiles: false
    }
  ];

  return {
    fields
  };
};
