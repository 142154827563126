import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { ClaimButton } from '../../components/ClaimButton';
import { MailingButton } from '../../Mailing/MailingButton';
import { routes } from '../../../utils/constants/adminRoutes';
import usePopConfirm from './popConfirms';

/**
 * Custom hook that returns extra buttons for a program.
 * @hook
 * @param {boolean} isDeleted - Indicates if the program is deleted.
 * @param {object} dataSet - The data set containing program information.
 * @param {string} apiURL - The API URL for the program.
 * @param {string} resource - The resource for the program.
 * @returns {object} - An object containing the extra buttons JSX.
 */
export const useExtraButtons = (isDeleted, dataSet, apiURL, resource) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [visible, setVisible] = useState(false);
  const { exitProgram, unarchiveProgram } = usePopConfirm(
    'legal-protection-insurances'
  );

  const { datas, programme, url } = dataSet;

  let editUrl;
  if (programme && programme.length > 0) {
    editUrl = `${routes.PROGRAMMES}/${url}/edit/${id}`;
  }

  const extraButtons = (
    <>
      {editUrl && !isDeleted && (
        <Link
          to={{
            pathname: editUrl
          }}
        >
          <Button type="primary">
            {`${t('buttons.edit')} `}
            <EditOutlined />
          </Button>
        </Link>
      )}
      {datas && url && (
        <>
          <ClaimButton id={id} url={url} claimType="civilLiability" />
          <MailingButton
            asset={datas}
            templateName="Blank"
            tradKey="specific_demand"
            resource={resource}
            baseUrl={apiURL}
          />
        </>
      )}
      {datas &&
        !datas.deleted &&
        exitProgram({
          visible,
          setVisible
        })}
      {datas && datas.deleted && unarchiveProgram()}
    </>
  );
  return { extraButtons };
};
