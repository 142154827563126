import { useParams } from 'react-router-dom';
import { AcknowledgeComplianceButton } from '../../utils/ClaimsButtons/AcknowledgeComplianceButton';

/**
 * Renders the Acknowledge Compliance button component conditionally based on current state.
 * @component
 * @param {boolean} renderCondition - Flag indicating if renders or not.
 * @param {Function} setIsCompliant - Function to set compliance status.
 * @param {Object} vehicleClaim - Vehicle claim data.
 * @param {string} targetUrl - Vehicle claim data.
 * @param {Function} setChangingStatus - Function to set changing status.
 * @returns {?React.Component} The rendered Acknowledge Compliance button component, or null if conditions are not met.
 */
const renderAcknowledgeComplianceButton = (
  renderCondition,
  setIsCompliant,
  vehicleClaim,
  targetUrl,
  setChangingStatus
) => {
  const { id } = useParams();

  if (renderCondition) {
    return (
      <AcknowledgeComplianceButton
        setIsCompliant={setIsCompliant}
        claimReferenceNumber={vehicleClaim?.unit_counter}
        id={id}
        url={`/${targetUrl}`}
        setChangingStatus={setChangingStatus}
      />
    );
  }
  return null;
};
export default renderAcknowledgeComplianceButton;
