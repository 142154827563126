import { Checkbox } from 'antd';

/**
 * A custom hook that returns predefined field configurations.
 *
 * This configuration can be used in conjunction with form libraries or frameworks
 * that need a set of field definitions to render and validate form controls.
 *
 * @hook
 * @returns {Array<Object>} returns.civilLiabilityFields - Field configurations for civil liability.
 */
export const useCivilLiabilityFields = () => {
  const fieldNames = [
    'missing_documents',
    'contract_number',
    'company_name',
    'date_added',
    'validity_start_date',
    'validity_end_date',
    'entity',
    'products',
    'subscriber_last_name_first_name',
    'address',
    'postal_code',
    'city',
    'site',
    'APE_code',
    'eighteen_months_span_statistic_number_of_employees',
    'description_of_the_activity'
  ];

  const civilLiabilityFields = fieldNames.map((name) => ({
    name: [name],
    rules: [{ required: false }],
    valuePropName: 'checked',
    input: <Checkbox />
  }));

  return { civilLiabilityFields };
};
