import { useCallback, useState } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { useErrorMessage } from './errorMessage';

/**
 * Custom hook for managing contacts.
 * @hook
 * @returns {Object} An object containing various contact utility functions and state variables.
 */
export const useContactUtils = () => {
  const { dispatchAPI, company } = useAuthContext();
  const { message } = useErrorMessage();
  const [currentContact, setCurrentContact] = useState('');
  const [allManagers, setAllManagers] = useState({
    allManagers: [],
    selectedManager: []
  });
  const [isContactLoading, setIsContactLoading] = useState(true);

  /**
   * Retrieves the client managers from the server.
   * @returns {Array} An array of client managers.
   */
  const getClientManagers = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: `/users/companies/${company}`,
        body: {
          role: 'users:USER',
          projection: ['first_name', 'last_name', 'email']
        }
      });
      setAllManagers({ ...allManagers, allManagers: data });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    return [];
  }, []);

  /**
   * Generates the URL selector based on the base URL.
   * @param {string} baseUrl - The base URL.
   * @returns {string} The generated URL selector.
   */
  const urlSelector = (baseUrl) => {
    const categoryMap = {
      properties: 'real_estate',
      golfs: 'golf',
      vehicles: 'vehicle',
      'civil-liabilities': 'civil_liability',
      transports: 'transport',
      constructions: 'construction',
      'machinery-breakages': 'machinery_breakage',
      'hole-in-ones': 'hole_in_one',
      'event-cancellation': 'event_cancellation',
      'cyber-insurances': 'cyber_insurance',
      'hardware-insurances': 'hardware_insurance',
      'legal-protection-insurances': 'legal_protection_insurance',
      property_claims: 'real_estate',
      golf_claims: 'golf',
      vehicle_claims: 'vehicle',
      civil_liability_claims: 'civil_liability',
      transport_claims: 'transport',
      construction_claims: 'construction',
      machinery_breakage_claims: 'machinery_breakage',
      hole_in_one_claims: 'hole_in_one',
      event_cancellation_claims: 'event_cancellation',
      cyber_insurance_claims: 'cyber_insurance',
      hardware_insurance_claims: 'hardware_insurance',
      legal_protection_insurance_claims: 'legal_protection_insurance'
    };

    const typeMap = {
      property_claims: 'claim',
      vehicle_claims: 'claim',
      golf_claims: 'claim',
      construction_claims: 'claim',
      machinery_breakage_claims: 'claim',
      hole_in_one_claims: 'claim',
      event_cancellation_claims: 'claim',
      cyber_insurance_claims: 'claim',
      civil_liability_claims: 'claim',
      hardware_insurance_claims: 'claim',
      transport_claims: 'claim'
    };

    const category = categoryMap[baseUrl] || 'real_estate';
    const type = typeMap[baseUrl] || 'customer';

    return `${category}_contacts_mail_${type}`;
  };

  const getCompanyContacts = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contacts/${company}`
      });
      return data[0] || [];
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    return [];
  };

  const getCategoryContact = async (category) => {
    const currentContacts = await getCompanyContacts();
    setCurrentContact(currentContacts[urlSelector(category)] || '');
  };

  const handleManagerSelect = async (value) => {
    allManagers?.allManagers.forEach((manager) => {
      if (`${manager.first_name} ${manager.last_name}` === value[0]) {
        setAllManagers({ ...allManagers, selectedManager: [manager] });
        setCurrentContact(manager.email);
      }
    });
    await setIsContactLoading(true);
    setIsContactLoading(false);
  };

  return {
    urlSelector,
    getCompanyContacts,
    getCategoryContact,
    currentContact,
    isContactLoading,
    setIsContactLoading,
    setCurrentContact,
    getClientManagers,
    allManagers,
    setAllManagers,
    handleManagerSelect
  };
};
