import { Tooltip } from 'antd';

/**
 * Renders the entity.
 * @component
 * @param {Array} entity - The entity array.
 * @param {Object} t - The translation object.
 * @returns {JSX.Element} - The rendered entity.
 */

export const RenderEntity = (entity, t) => {
  const [firstEntity, secondEntity] = entity || [];
  const entityArray = entity || [];
  let cellText = '';
  if (entityArray && entityArray.length >= 2 && entityArray.length < 3) {
    cellText = `${firstEntity.name}, ${secondEntity.name}`;
  } else if (entityArray && entityArray.length > 2) {
    cellText = `${firstEntity.name}, ${secondEntity.name} ...`;
  } else if (entityArray && entityArray.length === 1) {
    cellText = firstEntity.name;
  } else {
    return '-';
  }

  return (
    <Tooltip title={t(`documents.tooltip.display_entities`)}>
      {cellText}
    </Tooltip>
  );
};
