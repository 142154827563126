import { Route, Routes } from 'react-router-dom';
import { ListConstructionInsurance } from './ListConstructionInsurance';
import { ConstructionInsuranceCreateUpdate } from './ConstructionInsuranceCreateUpdate';
import { AmendmentCreateUpdate } from './Amendment/AmendmentCreateUpdate';
import { ConstructionInsuranceShow } from './ConstructionInsuranceShow';
import { Exception } from '../../../../components';
import { ConstructionInsuranceProvider } from './ConstructionInsuranceContext';
import { AmendmentProvider } from './Amendment/AmendmentContext';

/**
 * Component that defines the router for ConstructionInsurance.
 * @returns {JSX.Element} The rendered component.
 */

export const ConstructionInsuranceRouter = () => (
  <ConstructionInsuranceProvider>
    <AmendmentProvider>
      <Routes>
        <Route
          path="amendment/create/:id"
          element={<AmendmentCreateUpdate purpose="create" />}
        />
        <Route
          path="amendment/edit/:id"
          element={<AmendmentCreateUpdate purpose="edit" />}
        />
        <Route
          path="create"
          element={<ConstructionInsuranceCreateUpdate purpose="create" />}
        />
        <Route
          path="edit/:id"
          element={<ConstructionInsuranceCreateUpdate purpose="edit" />}
        />
        <Route path="show/:id" element={<ConstructionInsuranceShow />} />
        <Route index element={<ListConstructionInsurance />} />
        <Route path="*" element={<Exception />} />
      </Routes>
    </AmendmentProvider>
  </ConstructionInsuranceProvider>
);
