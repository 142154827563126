import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useColumns } from './utils/columnsHardwareInsurance';
import { useActionColumn } from '../../components/actionColumnProgGenerated';
import { ListResource } from '../../../components/ListResource/ListResource';
import { useAuthContext } from '../../../contexts/AuthContext';
import { ResetFilterButton } from '../../components/ResetFiltersButton';

/**
 * `ListHardwareInsurance` is a React component designed to list and manage light vehicles.
 * It provides filtering options by vehicle type, entity, missing documents and site.
 * The component fetches the data based on the filters applied and displays it in a tabular format.
 *
 * @component
 * @returns {ReactElement} Rendered `ListHardwareInsurance` component.
 */

export const ListHardwareInsurance = () => {
  const { company, permissions, verifPermissions } = useAuthContext();
  const [resetFilters, setResetFilters] = useState(false);
  const [extraFilter, setExtraFilter] = useState('');
  const [edit, setEdit] = useState(true);
  const resourceName = 'hardware-insurances';
  const [isArchivatedPage, setArchivatedPage] = useState(false);
  const actionColumn = useActionColumn(
    edit,
    isArchivatedPage,
    resourceName,
    resourceName
  );

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const currentPage = Number(params.get('p') || 1);
  const pageSize = Number(params.get('pS') || 10);

  let filterParameter = '';
  const dbExtraQueryFilter = `company=${company}`;
  const populate = 'entity,site,contract,company';

  const [columns, loading] = useColumns(
    resourceName,
    dbExtraQueryFilter,
    populate
  );

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setEdit(
        !verifPermissions(
          `/programmes/hardware-insurances/edit`,
          permissions?.notPermittedRoutes
        )
      );
    }
  }, [permissions]);

  useEffect(() => {
    if (resetFilters) {
      filterParameter = '';
      setResetFilters(false);
    }
    setExtraFilter(filterParameter);
    navigate({
      pathname: '/programmes/hardware-insurances',
      search: `?&p=${currentPage}&pS=${pageSize}&f=${filterParameter}`
    });
  }, [navigate, resetFilters]);

  if (loading) return <Spin fullscreen="true" />;

  return (
    <ListResource
      extraQuery={dbExtraQueryFilter}
      resourceName={resourceName}
      tradKey="hardware_insurances"
      columns={[...columns, ...actionColumn]}
      withExtraFilters={<ResetFilterButton setResetFilters={setResetFilters} />}
      setIsArchivatedPage={setArchivatedPage}
      noCreateButton
      populate={populate}
      headers={[]}
      editAction={false}
      showAction={false}
      duplicateAction={false}
      printAction={false}
      deleteAction={false}
      withUploadButton={false}
      extraFilter={extraFilter}
      customActionColumn
      scroll={{ x: 3200 }}
    />
  );
};
