import PropTypes from 'prop-types';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Row, Col, Typography, Descriptions } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListClaimAccountingOverview } from './ListClaimAccountingOverview';

import { useFieldsAppeal } from './utils/fields/fieldsAppeal';
import { useFieldsInvoicesPayment } from './utils/fields/fieldsInvoicesPayment';
import { useFieldsFees } from './utils/fields/fieldsFees';
import { useFieldsExcess } from './utils/fields/fieldsExcess';
import { useFieldsAccountingProvision } from './utils/fields/fieldsAccountingProvision';

import { GlobalPaymentAmount } from './utils/amountsDisplay/GlobalPaymentAmount';
import { AmountDue } from './utils/amountsDisplay/AmountDue';
import { SumDisplay } from './utils/amountsDisplay/SumDisplay';
import { FeesAppealsBalance } from './utils/amountsDisplay/FeesAppealsBalance';
import { CustomerExcess } from './utils/amountsDisplay/CustomerExcess';

const { Item } = Descriptions;

/**
 * Displays a list of accounting overview claims with relevant columns and additional filters.
 * @component
 * @param {number} current - Current tab.
 * @param {boolean} buttonBelowTable - Display button below table.
 * @param {boolean} noActionColumnsButton - Display action columns button.
 * @param {boolean} accountingBalanced - Sets button display.
 * @param {function} setAccountingBalanced - Set accounting balanced.
 * @param {string} urlClaim - Url claim.
 * @param {string} resourceNameClaim - Resource name claim.
 * @param {string} url - Url.
 * @param {string} resourceName - Resource name.
 * @returns {React.ReactNode} List of accounting overview claims.
 */

export const ShowClaimAccountingOverview = ({
  current,
  buttonBelowTable,
  noActionColumnsButton,
  accountingBalanced,
  setAccountingBalanced,
  urlClaim,
  urlAccount,
  resourceNameClaim,
  url,
  resourceName,
  feesAppealsBalanced,
  setFeesAppealsBalanced,
  edit
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { fieldsInvoicesPayment } = useFieldsInvoicesPayment();
  const { fieldsAppeal } = useFieldsAppeal();
  const { fieldsFees } = useFieldsFees();
  const { fieldsExcess } = useFieldsExcess();
  const { fieldsAccountingProvision } = useFieldsAccountingProvision();
  const [reload, setReload] = useState(false);

  return (
    <Card>
      {current && urlClaim && resourceNameClaim && url && resourceName && (
        <Row gutter={[16, 16]}>
          {edit && (
            <Col xs={24} xxl={12}>
              <Descriptions bordered title={t(`claims.form.global_cost_case`)}>
                <Item label={t(`claims.form.global_cost_case`)} span={3}>
                  <SumDisplay
                    reload={reload}
                    baseUrl={urlAccount}
                    resourceNameClaim={resourceNameClaim}
                    sumTypeUrl="global-cost"
                  />
                </Item>
                <Item label={t(`claims.form.amount_type`)} span={3}>
                  <Typography>{t(`claims.form.amount`)}</Typography>
                </Item>
                <Item label={t(`claims.form.ACCOUNTING_PROVISION`)} span={3}>
                  <GlobalPaymentAmount
                    reload={reload}
                    baseUrl={urlAccount}
                    resourceNameClaim={resourceNameClaim}
                  />
                </Item>
                <Item label={t(`claims.form.customer_regulation`)} span={3}>
                  <CustomerExcess
                    reload={reload}
                    baseUrl={urlAccount}
                    resourceNameClaim={resourceNameClaim}
                    sumTypeUrl="total-excess"
                  />
                </Item>
                <Item label={t(`claims.form.total_against_appeal`)} span={3}>
                  <SumDisplay
                    reload={reload}
                    baseUrl={urlAccount}
                    resourceNameClaim={resourceNameClaim}
                    sumTypeUrl="total-against-appeal"
                  />
                </Item>
                <Item label={t(`claims.form.total_lodged_appeal`)} span={3}>
                  <SumDisplay
                    reload={reload}
                    baseUrl={urlAccount}
                    resourceNameClaim={resourceNameClaim}
                    sumTypeUrl="total-lodged-appeal"
                  />
                </Item>
                <Item label={t(`claims.form.total_due_fees`)} span={3}>
                  <SumDisplay
                    reload={reload}
                    baseUrl={urlAccount}
                    resourceNameClaim={resourceNameClaim}
                    sumTypeUrl="total-fees"
                  />
                </Item>
                <Item label={t(`claims.form.total_excess`)} span={3}>
                  <SumDisplay
                    reload={reload}
                    baseUrl={urlAccount}
                    resourceNameClaim={resourceNameClaim}
                    sumTypeUrl="total-excess"
                  />
                </Item>
                <Item label={t(`claims.form.balance_appeals_fees`)} span={3}>
                  <FeesAppealsBalance
                    reload={reload}
                    baseUrl={urlAccount}
                    resourceNameClaim={resourceNameClaim}
                    sumTypeUrl="balance-appeals-fees"
                    feesAppealsBalanced={feesAppealsBalanced}
                    setFeesAppealsBalanced={setFeesAppealsBalanced}
                  />
                </Item>
              </Descriptions>
            </Col>
          )}
          <Col xs={24} xxl={12}>
            <Descriptions
              bordered
              title={t(`claims.form.customer_accounting_management`)}
            >
              <Item label={t(`claims.form.amount_type`)} span={3}>
                <Typography>{t(`claims.form.amount`)}</Typography>
              </Item>
              <Item label={t(`claims.form.ACCOUNTING_PROVISION`)} span={3}>
                <GlobalPaymentAmount
                  reload={reload}
                  baseUrl={urlAccount}
                  resourceNameClaim={resourceNameClaim}
                />
              </Item>
              <Item label={t(`claims.form.total_excess`)} span={3}>
                <SumDisplay
                  reload={reload}
                  baseUrl={urlAccount}
                  resourceNameClaim={resourceNameClaim}
                  sumTypeUrl="total-excess"
                />
              </Item>
              <Item label={t(`claims.form.immediate_compensation`)} span={3}>
                <SumDisplay
                  reload={reload}
                  baseUrl={urlAccount}
                  resourceNameClaim={resourceNameClaim}
                  sumTypeUrl="immediate-compensation"
                />
              </Item>
              <Item label={t(`claims.form.immediate_paiement`)} span={3}>
                <SumDisplay
                  reload={reload}
                  baseUrl={urlAccount}
                  resourceNameClaim={resourceNameClaim}
                  sumTypeUrl="immediate-paiement"
                />
              </Item>
              <Item label={t(`claims.form.deferred_paiement`)} span={3}>
                <SumDisplay
                  reload={reload}
                  baseUrl={urlAccount}
                  resourceNameClaim={resourceNameClaim}
                  sumTypeUrl="deferred-paiement"
                />
              </Item>
              <Item label={t(`claims.form.amount_due`)} span={3}>
                <AmountDue
                  reload={reload}
                  baseUrl={urlAccount}
                  resourceNameClaim={resourceNameClaim}
                  accountingBalanced={accountingBalanced}
                  setAccountingBalanced={setAccountingBalanced}
                />
              </Item>
            </Descriptions>
          </Col>

          {edit && (
            <>
              <Col xs={24} xxl={12}>
                <Card
                  className="table-card"
                  title={t('claims.form.ACCOUNTING_PROVISION')}
                >
                  <ListClaimAccountingOverview
                    claimId={id}
                    resourceName={resourceNameClaim}
                    url={urlClaim}
                    amountType="ACCOUNTING_PROVISION"
                    fields={fieldsAccountingProvision}
                    setReload={setReload}
                    reload={reload}
                    noPagination
                    noActionColumnsButton={noActionColumnsButton}
                    urlAccount={urlAccount}
                    resourceNameClaim={resourceNameClaim}
                  />
                </Card>
              </Col>
              <Col xs={24} xxl={12}>
                <Card className="table-card" title={t('claims.form.FEES')}>
                  <ListClaimAccountingOverview
                    claimId={id}
                    resourceName={resourceName}
                    url={url}
                    amountType="FEES"
                    fields={fieldsFees}
                    setReload={setReload}
                    reload={reload}
                    buttonBelowTable={buttonBelowTable}
                    noPagination
                    noActionColumnsButton={noActionColumnsButton}
                    urlAccount={urlAccount}
                    resourceNameClaim={resourceNameClaim}
                  />
                </Card>
              </Col>
              <Col xs={24} xxl={12}>
                <Card className="table-card" title={t('claims.form.EXCESS')}>
                  <ListClaimAccountingOverview
                    claimId={id}
                    resourceName={resourceName}
                    url={url}
                    amountType="EXCESS"
                    fields={fieldsExcess}
                    setReload={setReload}
                    reload={reload}
                    buttonBelowTable={buttonBelowTable}
                    noPagination
                    noActionColumnsButton={noActionColumnsButton}
                    urlAccount={urlAccount}
                    resourceNameClaim={resourceNameClaim}
                  />
                </Card>
              </Col>
              <Col xs={24} xxl={12}>
                <Card className="table-card" title={t('claims.form.appeal')}>
                  <ListClaimAccountingOverview
                    claimId={id}
                    resourceName={resourceName}
                    url={url}
                    amountType="APPEAL"
                    fields={fieldsAppeal}
                    setReload={setReload}
                    reload={reload}
                    buttonBelowTable={buttonBelowTable}
                    noActionColumnsButton={noActionColumnsButton}
                    noPagination
                    urlAccount={urlAccount}
                    resourceNameClaim={resourceNameClaim}
                  />
                </Card>
              </Col>
              <Col xs={24} xxl={12}>
                <Card
                  className="table-card"
                  title={t('claims.form.INVOICES_PAYMENT')}
                >
                  <ListClaimAccountingOverview
                    claimId={id}
                    resourceName={resourceName}
                    url={url}
                    amountType="INVOICES_PAYMENT"
                    fields={fieldsInvoicesPayment}
                    setReload={setReload}
                    reload={reload}
                    buttonBelowTable={buttonBelowTable}
                    noPagination
                    noActionColumnsButton={noActionColumnsButton}
                    urlAccount={urlAccount}
                    resourceNameClaim={resourceNameClaim}
                  />
                </Card>
              </Col>
            </>
          )}
        </Row>
      )}
    </Card>
  );
};

ShowClaimAccountingOverview.propTypes = {
  current: PropTypes.number.isRequired,
  buttonBelowTable: PropTypes.bool,
  noActionColumnsButton: PropTypes.bool,
  accountingBalanced: PropTypes.bool.isRequired,
  setAccountingBalanced: PropTypes.func.isRequired,
  urlClaim: PropTypes.string.isRequired,
  resourceNameClaim: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  feesAppealsBalanced: PropTypes.bool.isRequired,
  setFeesAppealsBalanced: PropTypes.func.isRequired,
  urlAccount: PropTypes.string.isRequired,
  edit: PropTypes.bool.isRequired
};

ShowClaimAccountingOverview.defaultProps = {
  buttonBelowTable: false,
  noActionColumnsButton: false
};
