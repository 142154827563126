import React, { useState, createContext, useEffect } from 'react';
import { Col, Modal, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * `PropertyContextProvider` wraps its children with a context provider that
 * manages state related to properties. This includes enums, contracts, forms,
 * loading status, and a force-refresh mechanism.
 *
 * It fetches property enums on mount and provides various states and actions
 * to be used by children components through context.
 *
 * @component
 * @param {Object} props - Props for the component.
 * @param {ReactNode} props.children - React components or elements wrapped by the context.
 *
 */

export const PropertyContext = createContext();

export const PropertyContextProvider = ({ children }) => {
  const [currentContract, setCurrentContract] = useState();
  const [currentEntityContext, setCurrentEntityContext] = useState();
  const [entitiesContext, setEntitiesContext] = useState([]);
  const { t } = useTranslation();
  const [enums, setEnums] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [form, setForm] = useState(null);

  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/properties/enums`
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const generateAttestation = async (id) => {
    const modal = Modal.info({
      content: (
        <Row
          style={{ flexDirection: 'column' }}
          justify="center"
          align="middle"
        >
          <Col>
            <Spin />
          </Col>
          <Col>{t('files.modal.document-loading')}</Col>
        </Row>
      ),
      footer: null,
      maskClosable: false
    });
    try {
      const { data, headers } = await dispatchAPI('GET', {
        url: `properties/attestation/${id}`,
        responseType: 'arraybuffer'
      });
      const blob = new Blob([data], {
        type: headers['content-type']
      });
      modal.destroy();
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = headers['content-disposition'];
      document.body.appendChild(a);

      a.click();

      window.URL.revokeObjectURL(url);
    } catch (e) {
      modal.destroy();
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return (
    <PropertyContext.Provider
      value={{
        enums,
        forceRefresh,
        setForceRefresh,
        contracts,
        setContracts,
        form,
        setForm,
        isLoading,
        setIsLoading,
        generateAttestation,
        currentEntityContext,
        setCurrentEntityContext,
        entitiesContext,
        setEntitiesContext,
        currentContract,
        setCurrentContract
      }}
    >
      {children}
    </PropertyContext.Provider>
  );
};
