import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const { Option } = Select;

export const SelectByEntity = ({ onChange, permKey, isDocumentFilter }) => {
  const { t } = useTranslation();
  const [entities, setEntities] = useState([]);
  const { company, dispatchAPI, user, userEntitySite } = useAuthContext();
  const { message } = useErrorMessage();
  const id = company;

  const getEntities = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/entities/company/${id}`
      });

      const filterEntitiesForUser = (entitiesArray, condition) =>
        entitiesArray.filter((entity) =>
          userEntitySite.some((siteEntity) => condition(siteEntity, entity))
        );

      const filteredEntities =
        user?.role === 'users:USER'
          ? filterEntitiesForUser(
              data,
              (siteEntity, entity) =>
                (isDocumentFilter || siteEntity.typeP === permKey) &&
                siteEntity.id === entity._id
            )
          : data;

      setEntities(filteredEntities);
    } catch (e) {
      message(e);
    }
  }, [id, user, userEntitySite, isDocumentFilter, permKey]);

  useEffect(() => {
    (async () => {
      await getEntities();
    })();
  }, [getEntities]);

  return (
    <Select
      allowClear
      placeholder={t('vehicles.filter.filter_by_entity')}
      showSearch
      onChange={onChange}
      filterOption={(input, option) =>
        (option?.title ?? '').toLowerCase().includes(input.toLowerCase())
      }
    >
      {entities &&
        entities.map((e) => (
          <Option key={e._id} value={e._id} title={e.name}>
            {e.name}
          </Option>
        ))}
    </Select>
  );
};

SelectByEntity.propTypes = {
  onChange: PropTypes.func.isRequired,
  permKey: PropTypes.string,
  isDocumentFilter: PropTypes.bool
};

SelectByEntity.defaultProps = {
  permKey: '',
  isDocumentFilter: false
};
