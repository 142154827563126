import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Flex, Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import FilesManager from '../../../../../components/CreateUpdateContainerV3/FilesManager/FilesManagerWithDisplay';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { useAddInsuranceCertificate } from './addInsuranceCertificate';
import { useDraggerProps } from '../../../../../components/CreateUpdateContainerV3/FilesManager/utils/draggerProps';
import { ErrorModal } from '../../../../../components/ErrorModal/ErrorModal';
import { useGetCertificates } from './getCetificates';

/**
 * ModalAddCertificate component.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.resourceName - The name of the resource.
 * @param {string} props.baseUrl - The base URL.
 * @param {string} props.resourceId - The resource ID.
 * @param {string} props.rowKey - The row key.
 * @param {string} props.idWithoutParams - The ID without parameters.
 * @param {boolean} props.visible - The visibility of the modal.
 * @param {function} props.setVisible - The function to set the visibility of the modal.
 * @param {Array} props.mandatoryExitDocuments - The array of mandatory exit documents.
 * @returns {JSX.Element} The ModalAddCertificate component.
 */
export const ModalAddCertificate = ({
  resourceName,
  baseUrl,
  resourceId,
  rowKey,
  idWithoutParams,
  visible,
  setVisible,
  mandatoryExitDocuments
}) => {
  const openCondition = resourceId ? visible === true : visible === rowKey;
  if (!openCondition) return null;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isOpen, setIsOpen] = useState(false);
  const [filesConfiguration, setFilesConfiguration] = useState([]);
  const [draggerFilesList, setDraggerFilesList] = useState([]);
  const [mandatoryDocuments, setMandatoryDocuments] = useState(
    mandatoryExitDocuments
  );

  const possibleDocuments = ['insurance_certificate'];
  const [isLoading, setIsLoading] = useState(false);

  const { getResource } = useGetCertificates({
    resourceId,
    resourceName,
    idWithoutParams,
    setDraggerFilesList,
    setFilesConfiguration,
    setIsLoading
  });

  useDraggerProps(draggerFilesList, setDraggerFilesList);

  useEffect(() => {
    if (filesConfiguration.length > 0) {
      setMandatoryDocuments(
        filesConfiguration
          .filter((file) => file.mandatory)
          .map((file) => file.key)
      );
    } else {
      setMandatoryDocuments(possibleDocuments);
    }
  }, [filesConfiguration]);

  useEffect(() => {
    if (visible) getResource();
  }, [visible]);

  const { handleSubmit } = useAddInsuranceCertificate({
    draggerFilesList,
    filesConfiguration,
    setIsSubmitting,
    resourceName,
    resourceId,
    setVisible,
    idWithoutParams
  });

  const deleteFile = async (fileID) => {
    try {
      await dispatchAPI('PATCH', {
        url: `${baseUrl}/${
          resourceId || idWithoutParams
        }/${fileID}?isInsuranceCertificate=true`
      });
    } catch (e) {
      message(e);
    }
  };
  return (
    <Modal
      width={800}
      footer={false}
      loading={isLoading}
      open={openCondition}
      onCancel={() => {
        setVisible(false);
        form.resetFields();
      }}
    >
      <Form form={form} onFinish={handleSubmit}>
        <FilesManager
          customTitle={t('files.certificate_title')}
          filesList={draggerFilesList}
          draggerFilesKeysMandatory={mandatoryDocuments}
          mandatoryDocuments={mandatoryDocuments}
          setFilesList={setDraggerFilesList}
          filesKeys={possibleDocuments}
          filesConfiguration={filesConfiguration}
          setFilesConfiguration={setFilesConfiguration}
          purpose="edit"
          deleteFile={deleteFile}
        />
        <Form.Item>
          <Flex>
            <Button
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
              style={{ marginRight: 10 }}
            >
              {t('datatable.column.action.delete.ok')}
            </Button>
            <Button
              type="danger"
              onClick={() => {
                setVisible(false);
                form.resetFields();
              }}
              style={{ marginLeft: 10 }}
            >
              {t('datatable.column.action.delete.cancel')}
            </Button>
          </Flex>
        </Form.Item>
      </Form>
      <ErrorModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={t('errors.message.missing_mandatory_documents_exit_programme')}
        details={mandatoryDocuments}
        tradKey="files.keys"
      />
    </Modal>
  );
};

ModalAddCertificate.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
  rowKey: PropTypes.number,
  idWithoutParams: PropTypes.string,
  mandatoryExitDocuments: PropTypes.shape([]).isRequired,
  resourceName: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  setVisible: PropTypes.func
};

ModalAddCertificate.defaultProps = {
  rowKey: null,
  idWithoutParams: null,
  visible: false,
  setVisible: () => {}
};
