/**
 * @param {string} t - i18n function
 * @param {string} tradKey - The key to translate
 * @param {string} value - The value to translate
 * @returns {string} The translated value
 */
export const translate = (t, tradKey, value) => t(tradKey + value) || '-';

/**
 * Renders the names of items as a comma-separated string.
 * @function
 * @param {string} t - i18n function
 * @param {string} tradKey - The key to translate
 * @param {Array} array - The array of items.
 * @returns {string} - The comma-separated string of item names, or '-' if the item is falsy.
 */
export const translateArray = (t, tradKey, array) => {
  if (Array.isArray(array) && array.length > 0) {
    return array.map((a) => t(tradKey + a)).join(', ');
  }
  return '-';
};

/**
 * @param {object} data - The data object containing the first_name and last_name properties.
 * @returns {string} The full name of the person, or '-' if the data object is falsy.
 */
export const getFullName = (data) =>
  data ? `${data.first_name} ${data.last_name}` : '-';
