import { useTranslation } from 'react-i18next';

export const useColumns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('claims.form.amounts'),
      dataIndex: 'amount',
      key: 'amounts',
      render: (amount) => amount?.toFixed(2) || '-'
    },
    {
      title: t('claims.form.incl_or_excl_tax'),
      dataIndex: 'incl_or_excl_tax',
      key: 'incl_or_excl_tax',
      render: (incl_or_excl_tax) =>
        incl_or_excl_tax ? t(`claims.form.${incl_or_excl_tax}`) : '-'
    },
    {
      title: t('claims.form.immediate_or_deferred_payment'),
      dataIndex: 'immediate_or_deferred_payment',
      key: 'immediate_or_deferred_payment',
      render: (immediate_or_deferred_payment) =>
        immediate_or_deferred_payment
          ? t(`claims.form.${immediate_or_deferred_payment}`)
          : '-'
    }
  ];
};
