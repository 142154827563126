import dayjs from 'dayjs';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, DatePicker, Button, InputNumber } from 'antd';
import { SelectEnums } from '../../components/SelectEnums';
import { SelectManager } from '../../../../components/SelectManager';
import { SelectContract } from '../../../../components/SelectContract';
import { SelectEntityV2 } from '../../../../components/SelectEntityV2';
import { LightVehiclesContext } from '../LightVehiclesContext';
import { SelectSiteV2 } from '../../../../components/SelectSiteV2';
import { SelectSecondaryDrivers } from '../../../../components/SelectSecondaryDrivers';
import { SelectPrincipalDriver } from '../../../../components/SelectPrincipalDriver';

const { TextArea } = Input;

/**
 * Custom hook that returns an array of fields for the Light Vehicles form.
 *
 * @param {Object} options - The options for the hook.
 * @param {Function} options.onAddDriver - The function to handle adding a driver.
 * @param {Object} options.currentVehicleData - The current vehicle data.
 * @param {Function} options.setCurrentVehicleData - The function to set the current vehicle data.
 * @returns {Object} - An object containing the fields for the Light Vehicles form.
 */
const useFields = ({
  onAddDriver,
  currentVehicleData,
  setCurrentVehicleData,
  form
}) => {
  const { t } = useTranslation();
  const {
    currentEntityContext,
    setCurrentEntityContext,
    entitiesContext,
    setEntitiesContext,
    currentContract,
    setCurrentContract
  } = useContext(LightVehiclesContext);

  const dateFormat = 'DD-MM-YYYY';

  const handleDateChange = (dateToDefine) => (date, dateString) => {
    setCurrentVehicleData({
      ...currentVehicleData,
      [dateToDefine]: dateString
    });
  };
  const vehicle_type = currentVehicleData?.light_vehicle_type;

  const setVehicleType = (value) => {
    setCurrentVehicleData({
      ...currentVehicleData,
      light_vehicle_type: value
    });
  };

  const handleEntityChange = (value) => {
    setCurrentEntityContext(value);
    form.setFieldsValue({ site: undefined });
  };

  const handleContractChange = () => {
    form.setFieldsValue({ entity: undefined, site: undefined });
  };

  const fieldsLightVehicles = [
    {
      noLabel: true,
      name: ['fleet_type'],
      input: <Input hidden />
    },
    {
      label: ['contract_number'],
      name: ['contract'],
      rules: [{ required: true }],
      input: (
        <SelectContract
          dbKey="contract"
          sub_programme="LIGHT_VEHICLE"
          programme_types="FLEET"
          setCurrentContract={setCurrentContract}
          currentEntityContext={currentEntityContext}
          handleContractChange={handleContractChange}
        />
      )
    },
    {
      label: ['customer_manager'],
      name: ['customer_manager'],
      input: <SelectManager dbKey="customer_manager" />
    },
    {
      label: ['unit_manager'],
      name: ['unit_manager'],
      input: <SelectManager dbKey="unit_manager" />
    },
    {
      label: ['desired_effective_date'],
      name: ['desired_effective_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['entity'],
      name: ['entity'],
      rules: [{ required: true }],
      input: (
        <SelectEntityV2
          dbKey="entity"
          permKey="programme-FLEET-LIGHT_VEHICLE"
          currentContract={currentContract}
          setEntitiesContext={setEntitiesContext}
          handleEntityChange={handleEntityChange}
          currentEntityContext={currentEntityContext}
        />
      )
    },
    {
      label: ['site'],
      name: ['site'],
      input: (
        <SelectSiteV2
          dbKey="site"
          permKey="programme-FLEET-LIGHT_VEHICLE"
          currentEntityContext={currentEntityContext}
          entitiesContext={entitiesContext}
        />
      )
    },
    {
      label: ['vehicle_type'],
      name: ['light_vehicle_type'],
      rules: [{ required: true }],
      input: (
        <SelectEnums dbKey="light_vehicle_type" onChange={setVehicleType} />
      )
    },
    {
      label: ['registration_card_category'],
      name: ['registration_card_category'],
      rules: [{ required: true }]
    },
    {
      label: ['vehicle_brand'],
      name: ['vehicle_brand'],
      rules: [{ required: true }]
    },
    {
      label: ['vehicle_model'],
      name: ['vehicle_model'],
      rules: [{ required: true }]
    },
    {
      label: ['registration_or_serial_number'],
      name: ['registration_or_serial_number'],
      rules: [{ required: true }]
    },
    {
      label: ['initial_traffic_release'],
      name: ['initial_traffic_release'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          format={dateFormat}
          style={{ width: '100%' }}
          onChange={handleDateChange('initial_traffic_release')}
        />
      )
    },
    {
      label: ['purchase_date_or_lease_start_date'],
      name: ['purchase_date_or_lease_start_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          format={dateFormat}
          style={{ width: '100%' }}
          minDate={dayjs(
            currentVehicleData.initial_traffic_release,
            dateFormat
          )}
          disabled={!currentVehicleData.initial_traffic_release}
        />
      )
    },
    {
      label: ['mileage'],
      name: ['mileage'],
      input: <InputNumber addonAfter="km" style={{ width: '100%' }} />
    },
    vehicle_type === 'MOTORCYCLE'
      ? {
          label: ['piston_displacement'],
          name: ['piston_displacement'],
          input: (
            <InputNumber
              addonAfter="cm³"
              disabled={vehicle_type !== 'MOTORCYCLE'}
              style={{ width: '100%' }}
            />
          )
        }
      : {
          label: ['taxable_power'],
          name: ['taxable_power'],
          customTooltip:
            !vehicle_type && `${t('vehicles.form.require_vehicle_type')}`,
          input: (
            <InputNumber
              disabled={!vehicle_type}
              addonAfter={t('vehicles.form.tHp')}
              style={{ width: '100%' }}
            />
          )
        },
    {
      label: ['observations'],
      name: ['observations'],
      rules: [{ required: false }],
      input: <TextArea style={{ resize: 'none' }} />
    },
    {
      name: [' '],
      input: (
        <Button block onClick={onAddDriver}>
          {t('drivers.form.create_or_modify_driver')}
        </Button>
      ),
      startWithDivider: {
        title: t('drivers.form.drivers')
      }
    },
    {
      label: ['principal_driver'],
      name: ['principal_driver'],
      rules: [{ required: false }],
      input: (
        <SelectPrincipalDriver
          dbKey="principal_driver"
          style={{ resize: 'none' }}
          currentSecundaryDrivers={currentVehicleData?.secondary_drivers}
          setPrincipalDriver={(driver) =>
            setCurrentVehicleData({
              ...currentVehicleData,
              principal_driver: driver
            })
          }
        />
      )
    },
    {
      label: ['secondary_drivers'],
      name: ['secondary_drivers'],
      rules: [{ required: false }],
      input: (
        <SelectSecondaryDrivers
          dbKey="secondary_drivers"
          style={{ resize: 'none' }}
          currentPrincipalDriver={currentVehicleData?.principal_driver}
          setSecondaryDrivers={(driver) =>
            setCurrentVehicleData({
              ...currentVehicleData,
              secondary_drivers: driver
            })
          }
        />
      ),
      endWithDivider: {
        title: t('vehicles.form.documents')
      }
    }
  ];
  return {
    fieldsLightVehicles
  };
};
export default useFields;
