import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { useColumns } from './utils/columnsProperty';
import { useActionColumn } from './utils/actionColumn';
import { ListResource } from '../../../components/ListResource/ListResource';
import { useAuthContext } from '../../../contexts/AuthContext';
import { FilterByMissingDocuments } from '../../components/FilterByMissingDocuments';

/**
 * A component that lists 'Properties' filtered by selected entity and site.
 *
 * `ListProperties` is responsible for fetching and listing 'Properties'.
 *
 * Once a user chooses an entity or a site, the component updates the browser URL with the
 * filtering parameters.
 *
 * @Component
 *
 * @returns {JSX.Element} The rendered `ListProperties` component with filtering capabilities.
 */

export const ListProperty = () => {
  const [isMissingDocuments, setIsMissingDocuments] = useState('');
  const [resetFilters, setResetFilters] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [showData, setShowData] = useState(true);
  const [edit, setEdit] = useState(true);
  const [reintegrate, setReintegrate] = useState(true);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [extraFilter, setExtraFilter] = useState('');
  const [isArchivatedPage, setArchivatedPage] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const currentPage = Number(params.get('p') || 1);
  const pageSize = Number(params.get('pS') || 10);

  const { company, verifPermissions, permissions } = useAuthContext();
  const { t } = useTranslation();

  let filterParameter = '';
  const resourceName = 'properties';
  const dbExtraQueryFilter = `company=${company}`;
  const populate = 'entity,site,contract,company,insurance_certificate.file';

  const [columns, loading] = useColumns(
    resourceName,
    dbExtraQueryFilter,
    populate
  );
  const actionColumn = useActionColumn({
    edit,
    isArchivatedPage,
    resourceName
  });

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setShowData(
        !verifPermissions('datas/properties', permissions?.notPermittedRoutes)
      );
      setEdit(
        !verifPermissions(
          '/programmes/properties/edit',
          permissions?.notPermittedRoutes
        )
      );
      setReintegrate(
        !verifPermissions(
          'programmes/properties/reintegrate',
          permissions?.notPermittedRoutes
        )
      );
    }
  }, [permissions]);

  const onFilterMissingDocuments = (missingDocuments) => {
    setIsMissingDocuments(missingDocuments === false ? '' : missingDocuments);
  };

  useEffect(() => {
    if (resetFilters) {
      filterParameter = '';
      setResetFilters(false);
    }
    filterParameter = `missing_documents=${isMissingDocuments}__`;
    setExtraFilter(filterParameter);
    navigate({
      pathname: '/programmes/properties',
      search: `?&p=${currentPage}&pS=${pageSize}&f=${filterParameter}`
    });
  }, [navigate, isMissingDocuments, resetFilters]);

  useEffect(() => {
    if (!forceRefresh) {
      setForceRefresh(false);
    }
  }, [forceRefresh]);

  if (loading) return <Spin fullscreen="true" />;

  return (
    <ListResource
      extraQuery={dbExtraQueryFilter}
      resourceName={resourceName}
      exportUrl={`exports/company/${company}/assets/programme-type/PROPERTY`}
      importUrl={`imports-properties/property/company/${company}`}
      tradKey="properties"
      exportTemplateUrl="export-templates/template/property"
      columns={[...columns, ...actionColumn]}
      withExtraFilters={
        <FilterByMissingDocuments
          onChange={onFilterMissingDocuments}
          setResetFilters={setResetFilters}
        />
      }
      setLoaded={() => setLoaded()}
      loaded={loaded}
      populate={populate}
      editAction={false}
      showAction={false}
      sheetName={t('property.title')}
      headers={[]}
      withUploadButton={showData}
      permissionReintegrate={reintegrate}
      customDelete
      forceRefresh={forceRefresh}
      setForceRefresh={() => setForceRefresh()}
      extraFilter={extraFilter}
      setIsArchivatedPage={setArchivatedPage}
      customActionColumn
      scroll={{ x: 3800 }}
    />
  );
};
