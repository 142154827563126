import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Skeleton, Row, Col, Popconfirm, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  WarningOutlined,
  EditOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../../components';
import { routes } from '../../../../utils/constants/adminRoutes';
import { useListContent } from './utils/ListContentOtherConstruction';
import { MailingButton } from '../../../Mailing/MailingButton';
import { ClaimButton } from '../../../components/ClaimButton';
import { TooltipContainer } from '../../../components/TooltipContainer';
import { History } from '../../../components/History';

/**
 * Represents the `ShowOtherConstruction` component that displays details of a datas.
 * @component
 *
 * @returns {JSX.Element} The rendered `ShowOtherConstruction` component which displays the automission's details, documents, and associated drivers.
 */

export const ShowOtherConstruction = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [datas, setDatas] = useState([]);
  const [subProgramme, setSubProgramme] = useState([]);
  const listContent = useListContent(datas);
  const navigate = useNavigate();

  const getDocument = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/constructions/${id}?populate=customer_manager,unit_manager,entity,site,contract,company_Id`
      });
      setDatas(data);
      setSubProgramme(data.sub_programme);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  const deleteConstruction = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/constructions/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getDocument();
    })();
  }, [getDocument]);

  useEffect(() => {
    if (datas) setIsDeleted(!datas?.deleted);
  }, [datas]);

  const formatUrlPart = (str) => str.toLowerCase().replace(/_/g, '-');
  let editUrl;
  if (subProgramme && subProgramme.length > 0) {
    editUrl = `${routes.PROGRAMMES}/other-construction/${formatUrlPart(
      subProgramme
    )}/edit/${id}`;
  }

  return (
    <>
      <PageHeaderCustom
        title={`${t('number')} ${datas?.unit_counter || 'xxxx'} - ${t(
          `subscriptions.form.${subProgramme}`
        )}`}
        extra={
          <>
            {isDeleted && editUrl && (
              <Link
                to={{
                  pathname: editUrl
                }}
              >
                <Button>
                  <EditOutlined />
                  {`${t('buttons.edit')} `}
                </Button>
              </Link>
            )}
            <ClaimButton
              id={id}
              url="construction-insurance"
              claimType="construction"
            />
            <MailingButton
              asset={datas}
              templateName="Blank"
              tradKey="specific_demand"
              resource="Construction"
              baseUrl="constructions"
            />
            <TooltipContainer title={t('buttons.remove')}>
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={deleteConstruction}
                icon={<WarningOutlined />}
              >
                <Button type="link" danger>
                  {`${t('buttons.delete')} `}
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </TooltipContainer>
          </>
        }
      />
      <ContentCustom>
        <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
          <Row gutter={[16, 16]}>
            <Col Col xs={24} xl={16}>
              <Card>
                <DescriptionList
                  data={listContent}
                  translate
                  layout="vertical"
                  column={4}
                />
              </Card>
            </Col>
            <Col xs={24} xl={8}>
              <Row gutter={[0, 16]}>
                <Col xs={24}>
                  <Card title={t('subscriptions.form.history')} size="small">
                    <History route="Construction" />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Skeleton>
      </ContentCustom>
    </>
  );
};
