import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../contexts/AuthContext';

export const categories = [
  'vehicle',
  'real_estate',
  'golf',
  'construction',
  'civil_liability',
  'transport',
  'cyber_insurance',
  'hardware_insurance',
  'event_cancellation',
  'hole_in_one',
  'machinery_breakage',
  'professional_multirisk_insurance',
  'legal_protection_insurance',
  'assembly_testing_insurance',
  'environment_insurance'
];

/**
 * Custom hook that retrieves the existing categories from the programs list.
 * @hookl
 * @returns {Object} An object containing the existing categories.
 */
export const useCategories = () => {
  const [programsList, setProgramsList] = useState([]);
  const { company, dispatchAPI } = useAuthContext();

  const getProgramsList = async () => {
    const { data } = await dispatchAPI('GET', {
      url: `contracts/company/${company}`
    });
    setProgramsList(data?.programmesToDisplay || []);
  };

  useEffect(() => {
    getProgramsList();
  }, []);

  const mapProgramsToCategories = () => {
    const programCategoryMap = {
      FLEET: 'vehicle',
      GOLF: 'golf',
      CONSTRUCTION: 'construction',
      PROPERTY: 'real_estate',
      TRANSPORT: 'transport',
      CIVIL_LIABILITY: 'civil_liability',
      MACHINERY_BREAKAGE: 'machinery_breakage',
      HOLE_IN_ONE: 'hole_in_one',
      EVENT_CANCELLATION: 'event_cancellation',
      CYBER_INSURANCE: 'cyber_insurance',
      HARDWARE_INSURANCE: 'hardware_insurance',
      PROFESSIONAL_MULTIRISK_INSURANCE: 'professional_multirisk_insurance',
      LEGAL_PROTECTION_INSURANCE: 'legal_protection_insurance',
      ASSEMBLY_TESTING_INSURANCE: 'assembly_testing_insurance',
      ENVIRONMENT_INSURANCE: 'environment_insurance'
    };

    return programsList
      .map((program) => programCategoryMap[program])
      .filter(Boolean);
  };

  const existingCategories = mapProgramsToCategories();

  return { existingCategories };
};
