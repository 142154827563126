import { useCallback } from 'react';
import dayjs from 'dayjs';
import { toBoolean } from '../../../components/utils/stateFormat';
import { useHistory } from '../../../../utils/historyUtils';

/**
 * Custom hook that returns the configuration objects for getting and creating resources.
 *
 * @param {function} dispatch - The dispatch function from the Redux store.
 * @param {string} company - The company ID.
 * @param {string} program - The program type.
 * @param {string} subProgram - The sub-program type.
 * @returns {object} - The configuration objects for getting and creating resources.
 */

export const useConfig = (dispatch, company, program, setCurrentContract) => {
  const { setInitialHistory, createHistory, updateHistory } = useHistory();
  const onGetResource = {
    setFields: useCallback(
      (data) => {
        setCurrentContract(data.contract);
        setInitialHistory(data);
        dispatch({
          type: 'TOGGLE_STATE',
          field: 'isProtectedAgainstTheft',
          payload: toBoolean(data?.is_protected_against_theft)
        });
        dispatch({
          type: 'TOGGLE_STATE',
          field: 'isFireProtection',
          payload: toBoolean(data?.is_fire_protection)
        });
        dispatch({
          type: 'TOGGLE_STATE',
          field: 'isOtherTypologyActivities',
          payload: toBoolean(data?.typology_of_activities)
        });
        dispatch({
          type: 'TOGGLE_STATE',
          field: 'isTenant',
          payload: toBoolean(data?.legal_status === 'TENANT')
        });

        return {
          ...data,
          entry_date: data.entry_date && dayjs(data.entry_date)
        };
      },
      [dispatch, setInitialHistory]
    )
  };
  const onCreateResource = {
    setBody: useCallback(
      (data) => ({
        ...data,
        company,
        programme_types: program,
        ...createHistory('program')
      }),
      [company, program]
    )
  };
  const onUpdateResource = {
    setBody: useCallback(
      (data) => ({
        ...data,
        ...updateHistory('program')
      }),
      []
    )
  };
  return {
    onGetResource,
    onCreateResource,
    onUpdateResource
  };
};
