import { Flex } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useDownloadDocument } from '../../../utils/downloadDoc';

const iconSize = 18;

/**
 * Creates an action column configuration for a table, including various icons and links.
 *
 * @function
 * @returns {Array} An array representing the action column configuration.
 */

export const useActionColumn = () => {
  const { viewDocument } = useDownloadDocument();

  return [
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Flex align="center" wrap="nowrap" justify="end" gap="small">
          <EyeOutlined
            style={{ fontSize: iconSize }}
            onClick={() => viewDocument(record?.documents[0]?.file)}
          />
        </Flex>
      )
    }
  ];
};
