import { Checkbox } from 'antd';

/**
 * A custom hook that returns predefined field configurations.
 *
 * This configuration can be used in conjunction with form libraries or frameworks
 * that need a set of field definitions to render and validate form controls.
 *
 * @hook
 * @returns {Array<Object>} returns.golfFields - Field configurations for golfs.
 */

export const useHardwareFields = () => {
  const fieldNames = [
    'company_name',
    'contract_number',
    'date_added',
    'entity',
    'site',
    'address',
    'postal_code',
    'city',
    'global_tax_excl_hardware_value',
    'laptops_tax_excl_value',
    'is_claim_last_two_years',
    'is_data_duplication_guarantee',
    'is_operating_cost_loss_guarantee'
  ];

  const hardwareFields = fieldNames.map((name) => ({
    name: [name],
    rules: [{ required: false }],
    valuePropName: 'checked',
    input: <Checkbox />
  }));

  return { hardwareFields };
};
