import { Row, Col, Modal, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useConversationContext } from '../../../contexts/ConversationContext';
import { ListConversations } from './ListConversations';
import { Thread } from '../Thread';
import { MessageWriter } from '../MessageWriter';
import { MessageWriterClaim } from '../MessageWriterClaim';

/**
 * @component
 * @name ConversationDisplay
 * @description A component that displays the list of conversations and the conversation thread
 * @param {string} filterKey The key to filter the conversations
 * @returns {jsx} jsx - ConversationDisplay component
 */
export const ConversationDisplay = () => {
  const { isThreadOpen, filter, loading } = useConversationContext();
  const { t } = useTranslation();

  if (loading)
    return (
      <Modal
        visible
        centered
        footer={false}
        closable={false}
        bodyStyle={{ textAlign: 'center' }}
      >
        <Row justify="center" align="middle" style={{ height: '100%' }}>
          <Col>
            {t('messages.loading')}
            <br />
            <Spin size="large" />
          </Col>
        </Row>
      </Modal>
    );

  return (
    <Row>
      <Col xs={24} xl={6} className="conversations-list">
        <ListConversations />
      </Col>
      <Col xs={24} xl={18} className="conversation-col">
        <Row>
          <Col xs={24} xl={12} className="messages-col">
            {isThreadOpen && <Thread loading={false} />}
          </Col>
          <Col xs={24} xl={12} className="new-message-col">
            {isThreadOpen && filter === 'collaborators' ? (
              <MessageWriter url="/conversations" type="message" />
            ) : null}
            {isThreadOpen && filter === 'claims' ? (
              <MessageWriterClaim
                url="/conversations"
                type="message"
                selectRefresh="messagePage"
              />
            ) : null}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
