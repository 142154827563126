import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { renderEntities } from '../../../../utils/formatters/entities';

/**
 * `useListContent` is a custom React hook to generate a list content based on a given data.
 *
 * @hook
 * @param {object} data - The input data containing details about the document. Default is an empty object.
 * @returns {Array<object>} A list of objects containing label-content pairs for display, each object has
 * a `label` to indicate the type of data, a `span` to denote the span or size, and a `content` to denote the actual value.
 *
 * */

export const useListContent = (data = {}) => {
  const { t } = useTranslation();

  const {
    company_Id,
    contract,
    customer_manager,
    unit_manager,
    desired_effective_date,
    entity,
    sub_programme,
    programmes_types,
    deleted,
    deletion_date
  } = data;

  const customerManagerName = customer_manager
    ? `${customer_manager.first_name} ${customer_manager.last_name}`
    : '-';
  const unit_managerName = unit_manager
    ? `${unit_manager.first_name} ${unit_manager.last_name}`
    : '-';

  const subscriberAdressParts = [
    contract?.number,
    contract?.street,
    contract?.additional_address,
    contract?.postal_code,
    contract?.city
  ];
  const subscriberAddress = subscriberAdressParts
    .filter((part) => part)
    .join(' ');

  const labels = [
    {
      label: 'subscriptions.form.company',
      span: 1,
      content: company_Id ? company_Id.name : '-'
    },
    {
      label: 'subscriptions.form.contract_number',
      span: 1,
      content: contract?.contract_number || '-'
    },
    {
      label: 'subscriptions.form.entity',
      span: 2,
      content: renderEntities(entity)
    },
    {
      label: 'subscriptions.form.validity_start_date',
      span: 1,
      content:
        (contract?.validity_start_date &&
          dayjs(contract?.validity_start_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'subscriptions.form.validity_end_date',
      span: 1,
      content:
        (contract?.validity_end_date &&
          dayjs(contract?.validity_end_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'subscriptions.form.customer_manager',
      span: 1,
      content: customerManagerName
    },
    {
      label: 'subscriptions.form.unit_manager',
      span: 1,
      content: unit_managerName
    },
    {
      label: 'subscriptions.form.desired_effective_date',
      span: 1,
      content:
        (desired_effective_date &&
          dayjs(desired_effective_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'subscriptions.form.program',
      span: 1,
      content:
        (programmes_types && t(`subscriptions.form.${programmes_types}`)) || '-'
    },
    {
      label: 'subscriptions.form.sub_programme',
      span: 2,
      content:
        (sub_programme && t(`subscriptions.form.${sub_programme}`)) || '-'
    },
    {
      label: 'subscriptions.form.subscriber_last_name_first_name',
      span: 1,
      content: contract?.subscriber_last_name_first_name || '-'
    },
    {
      label: 'subscriptions.form.subscriber_address',
      span: 1,
      content: subscriberAddress || '-'
    }
  ];
  if (deleted) {
    labels.push({
      label: 'subscription.form.deleted',
      span: 1,
      content:
        (deletion_date && dayjs(deletion_date).format('DD/MM/YYYY')) || '-'
    });
  }

  return labels;
};
