import { useCallback } from 'react';
import dayjs from 'dayjs';
import { toBoolean } from '../../../components/utils/stateFormat';
import { useHistory } from '../../../../utils/historyUtils';

/**
 * Custom hook that returns the configuration objects for getting and creating resources.
 *
 * @param {function} dispatch - The dispatch function from the Redux store.
 * @param {string} company - The company ID.
 * @param {string} program - The program type.
 * @param {string} subProgram - The sub-program type.
 * @returns {object} - The configuration objects for getting and creating resources.
 */

export const useConfig = (dispatch, company, program, item) => {
  const { setInitialHistory, createHistory, updateHistory } = useHistory();
  const onGetResource = {
    setFields: useCallback(
      (data) => {
        dispatch({
          type: 'TOGGLE_STATE',
          field: 'isOther',
          payload: toBoolean(data?.claim_Type === 'OTHER')
        });
        setInitialHistory(data);
        return {
          ...data,
          sinister_date: data?.sinister_date && dayjs(data?.sinister_date)
        };
      },
      [dispatch, setInitialHistory]
    )
  };
  const onCreateResource = {
    setBody: useCallback(
      (data) => ({
        ...data,
        company,
        programme_types: program,
        entry_id: item?._id,
        opening_date: dayjs(),
        city: item?.city,
        entity: item?.entity,
        ...createHistory('claim', 'assembly-testing-insurances', item?._id)
      }),
      [company, program, item, createHistory]
    )
  };
  const onUpdateResource = {
    setBody: useCallback(
      (data) => ({
        ...data,
        claim_type_if_other:
          data?.claim_type === 'OTHER' ? data?.claim_type_if_other : null,
        ...updateHistory('claim')
      }),
      [updateHistory]
    )
  };

  return {
    onGetResource,
    onCreateResource,
    onUpdateResource
  };
};
