import { useTranslation } from 'react-i18next';

/**
 * Produces a list content for displaying detailed event cancellation information.
 *
 * This hook extracts specified properties from the provided data, formats them
 * and returns an array of objects suitable for rendering in a description list.
 *
 * @hook
 * @param {Object} data - event cancellation data to process.
 * @returns {Array<Object>} - Formatted list content for rendering.
 *
 */

export const useListContentEventPreviousTenue = (data = {}) => {
  const { t } = useTranslation();

  const {
    previous_event_organisation_dates,
    ran_as_expected,
    if_not_ran_as_expected_reasons,
    company_insuring_previous_events,
    previous_events_claim_types,
    previous_events_claim_amounts
  } = data;

  const labels = [
    {
      label: 'event_cancellations.form.previous_event_organisation_dates',
      span: 1,
      content:
        (previous_event_organisation_dates &&
          previous_event_organisation_dates) ||
        '-'
    },
    {
      label: 'event_cancellations.form.ran_as_expected',
      span: 1,
      content:
        (ran_as_expected && t(`event_cancellations.form.${ran_as_expected}`)) ||
        '-'
    },
    {
      label: 'event_cancellations.form.if_not_ran_as_expected_reasons',
      span: 1,
      content:
        (if_not_ran_as_expected_reasons && if_not_ran_as_expected_reasons) ||
        '-'
    },
    {
      label: 'event_cancellations.form.company_insuring_previous_events',
      span: 1,
      content:
        (company_insuring_previous_events &&
          company_insuring_previous_events) ||
        '-'
    },
    {
      label: 'event_cancellations.form.previous_events_claim_types',
      span: 1,
      content:
        (previous_events_claim_types && previous_events_claim_types) || '-'
    },
    {
      label: 'event_cancellations.form.previous_events_claim_amounts',
      span: 1,
      content:
        (previous_events_claim_amounts &&
          `${previous_events_claim_amounts} €`) ||
        '-'
    }
  ];
  return labels;
};
