import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker, Select } from 'antd';
import { SelectManager } from '../../../../components/SelectManager';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';

const { Option } = Select;

/**
 * Generate fields configuration for a form.
 * @param {string} subProgramme - The sub Programme value.
 * @returns {Object} An object containing the fields configuration.
 */
export const useFields = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [enums, setEnums] = useState({});
  const dateFormat = 'DD-MM-YYYY';

  const fieldsInformations = [
    {
      label: ['customer_manager'],
      name: ['customer_manager'],
      rules: [{ required: true }],
      input: <SelectManager dbKey="customer_manager" />
    },
    {
      label: ['unit_manager'],
      name: ['unit_manager'],
      input: <SelectManager dbKey="unit_manager" />
    },
    {
      label: ['sub_programme'],
      name: ['sub_programme'],
      rules: [{ required: true }],
      input: (
        <Select
          placeholder={t('subscriptions.filter_by_type')}
          style={{ width: '100%' }}
          disabled
        >
          {(enums?.construction_sub_programmes || []).map((sub_programme) => (
            <Option key={sub_programme} value={sub_programme}>
              {t(`subscriptions.form.${sub_programme}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: ['desired_effective_date'],
      name: ['desired_effective_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/contracts/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fieldsInformations,
    isFieldsLoading
  };
};
