import { Route, Routes } from 'react-router-dom';
import { ListLegalProtectionInsuranceClaim } from './ListLegalProtectionInsuranceClaim';
import { Exception } from '../../../components';
import { LegalProtectionInsuranceClaimCreateUpdate } from './LegalProtectionInsuranceClaimCreateUpdate';
import LegalProtectionInsuranceClaimShow from './LegalProtectionInsuranceClaimShow';

/**
 * Provides routing for managing hardware insurances, including create, edit, show, and list views.
 *
 * @component
 * @returns {React.ReactNode} Routes for different views related to hardware insurances.
 */
export const LegalProtectionInsuranceClaimsRouter = () => (
  <Routes>
    <Route
      path="create/:id"
      element={<LegalProtectionInsuranceClaimCreateUpdate purpose="create" />}
    />
    <Route
      path="edit/:id"
      element={<LegalProtectionInsuranceClaimCreateUpdate purpose="edit" />}
    />
    <Route path="show/:id" element={<LegalProtectionInsuranceClaimShow />} />
    <Route index element={<ListLegalProtectionInsuranceClaim />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
