//  TODO: uncomment when ready
import {
  ListChecked,
  UserMultiple,
  Policy,
  License,
  Flood,
  ContainerServices,
  Catalog,
  DocumentAdd,
  Settings,
  RequestQuote,
  Concept,
  TreeFallRisk,
  Need
} from '@carbon/icons-react';
import { CommentOutlined, MailOutlined } from '@ant-design/icons';

const navMenuLogos = {
  HOME: <ListChecked />,
  USERS: <UserMultiple />,
  DOCUMENTS: <License />,
  COMPANIES: <ContainerServices />,
  CONTACTS: <Catalog />,
  WORKFLOWS: <Concept />,
  CONFIGURATIONS: <Settings />,
  CLAIMS: <Flood />,
  TEMPLATE_DOCS: <DocumentAdd />,
  PROGRAMMES: <Policy />,
  TEMPLATE_MAILS: <TreeFallRisk />,
  SUBSCRIPTIONS: <RequestQuote />,
  CONTRIBUTIONS: <Need />,
  MESSAGES: <CommentOutlined />,
  MAILS: <MailOutlined />
  // FLEETDATASANALYSIS: <LineChartOutlined />
};

export default navMenuLogos;
