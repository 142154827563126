import { Route, Routes } from 'react-router-dom';
import { ListEnvironmentInsurance } from './ListEnvironmentInsurance';
import { EnvironmentInsuranceCreateUpdate } from './EnvironmentInsuranceCreateUpdate';
import { EnvironmentInsuranceShow } from './EnvironmentInsuranceShow';
import { Exception } from '../../../components';

/**
 * Provides routing for managing hardware insurances, including create, edit, show, and list views.
 *
 * @component
 * @returns {React.ReactNode} Routes for different views related to hardware insurances.
 */
export const EnvironmentInsuranceRouter = () => (
  <Routes>
    <Route
      path="create/:id"
      element={<EnvironmentInsuranceCreateUpdate purpose="create" />}
    />
    <Route
      path="edit/:id"
      element={<EnvironmentInsuranceCreateUpdate purpose="edit" />}
    />
    <Route path="show/:id" element={<EnvironmentInsuranceShow />} />
    <Route index element={<ListEnvironmentInsurance />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
