import { Input } from 'antd';

export const fieldsCalculator = () => {
  const civil_liability_insurance_rmco = [
    {
      label: ['civil_liability_rmco'],
      name: ['civil_liability_rmco'],
      rules: [{ required: true }],
      input: <Input addonAfter="%" style={{ textAlign: 'right' }} />
    }
  ];

  const damages_insurance_construction = [
    {
      label: ['risks'],
      name: ['risks'],
      rules: [{ required: true }],
      input: <Input addonAfter="%" style={{ textAlign: 'right' }} />
    },
    {
      label: ['property_damage'],
      name: ['work_damage'],
      rules: [{ required: true }],
      input: <Input addonAfter="%" style={{ textAlign: 'right' }} />
    },
    {
      label: ['maintenance_visit'],
      name: ['maintenance_visit'],
      rules: [{ required: true }],
      input: <Input addonAfter="%" style={{ textAlign: 'right' }} />
    }
  ];

  const work_damages_guarantee = [
    {
      label: ['mandatory_damage'],
      name: ['mandatory_damage'],
      rules: [{ required: true }],
      input: <Input addonAfter="%" style={{ textAlign: 'right' }} />
    },
    {
      label: ['equipment_work'],
      name: ['equipment_work'],
      rules: [{ required: true }],
      input: <Input addonAfter="%" style={{ textAlign: 'right' }} />
    },
    {
      label: ['non_material_damages'],
      name: ['non_material_damages'],
      rules: [{ required: true }],
      input: <Input addonAfter="%" style={{ textAlign: 'right' }} />
    },
    {
      label: ['existing_damages'],
      name: ['existing_damages'],
      rules: [{ required: true }],
      input: <Input addonAfter="%" style={{ textAlign: 'right' }} />
    }
  ];

  const after_work_damages = [
    {
      label: ['mandatory_guarantee'],
      name: ['mandatory_guarantee'],
      rules: [{ required: true }],
      input: <Input addonAfter="%" style={{ textAlign: 'right' }} />
    },
    {
      label: ['proper_functioning'],
      name: ['proper_functioning'],
      rules: [{ required: true }],
      input: <Input addonAfter="%" style={{ textAlign: 'right' }} />
    },
    {
      label: ['after_non_material_damages'],
      name: ['after_non_material_damages'],
      rules: [{ required: true }],
      input: <Input addonAfter="%" style={{ textAlign: 'right' }} />
    },
    {
      label: ['after_existing_damages'],
      name: ['after_existing_damages'],
      rules: [{ required: true }],
      input: <Input addonAfter="%" style={{ textAlign: 'right' }} />
    }
  ];

  return {
    civil_liability_insurance_rmco,
    damages_insurance_construction,
    work_damages_guarantee,
    after_work_damages
  };
};
