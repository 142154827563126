/**
 * Custom hook for updating program data.
 *@hook
 * @param {Object} programData - The current program data.
 * @param {Function} setProgramData - The function to update the program data.
 * @returns {Object} - An object containing functions to update specific program data fields.
 */
export const useProgramDataUpdater = (programData, setProgramData) => {
  const initialData = (data) => ({
    ID: data?.entry_id?._id,
    claim: data,
    isCompliant: data?.is_compliant,
    isComplaint: !!(
      data?.claim_type === 'THEFT' || data?.claim_type === 'VANDALISM'
    ),
    isClosed: data?.is_closed,
    accountingBalanced: data?.accounting_balanced,
    feesAppealsBalanced: data?.is_appeals_fees_balanced
  });

  const setIsCompliant = (value) =>
    setProgramData({ ...programData, isCompliant: value });
  const setAccountingBalanced = (value) =>
    setProgramData({ ...programData, accountingBalanced: value });
  const setFeesAppealsBalanced = (value) =>
    setProgramData({ ...programData, feesAppealsBalanced: value });

  return {
    setIsCompliant,
    setAccountingBalanced,
    setFeesAppealsBalanced,
    setProgramData,
    initialData
  };
};
