import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { message } from 'antd';
import { useParams } from 'react-router-dom';
import { useHistory } from '../../../../utils/historyUtils';
import { useAuthContext } from '../../../../contexts/AuthContext';

export const useConfig = ({ setIsOther }) => {
  const { dispatchAPI, company } = useAuthContext();
  const { id } = useParams();
  const { setInitialHistory, createHistory, updateHistory } = useHistory();
  const [legalProtectionInsurance, setLegalProtectionInsurance] = useState({
    entity: null,
    entry_id: null
  });

  const getLegalProtectionInsurance = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `legal-protection-insurances/${id}?populate=entity,contract,company`
      });
      setLegalProtectionInsurance({
        entry_id: data?._id,
        entity: data?.entity
      });
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getLegalProtectionInsurance();
    })();
  }, [id]);

  const config = {
    onGetResource: {
      setFields: (data) => {
        if (data.claim_type === 'OTHER') {
          setIsOther(true);
        }
        setInitialHistory(data);
        return {
          ...data,
          sinister_date: data.sinister_date && dayjs(data.sinister_date)
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        entry_id: legalProtectionInsurance?.entry_id,
        company,
        opening_date: dayjs(),
        entity: legalProtectionInsurance?.entity,
        programme_types: 'LEGAL_PROTECTION_INSURANCE',
        ...createHistory('claim', 'Legal-protection-insurances', id)
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        claim_type_if_other:
          data.claim_type === 'OTHER' ? data.claim_type_if_other : null,
        ...updateHistory('claim')
      })
    }
  };

  return { config };
};
