// TODO: awaiting customer feedback before commented code can be removed
// import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'antd';
// import { DatePicker, Input } from 'antd';
// import { SelectEnumsReducer } from '../../components/SelectEnumsReducer';
// import { SelectYesNoReducer } from '../../components/SelectYesNoReducer';
// import { GenericSelectEnums } from '../../components/GenericSelectEnums';
// import { useGetEnums } from '../../../utils/getEnums';
import { MultiSelectEntity } from '../../components/MultiSelectEntity';
// import { SelectSite } from '../../components/SelectSite';

// const { TextArea } = Input;

/**
 * Generate fields configuration for a form.
 *
 * @function
 * @param {Form} form - The form instance.
 * @param {Object} state - The state of the form.
 * @param {Function} dispatch - The state setter.
 * @returns {Object} An object containing the fields configuration.
 */
export const useFields = () => {
  // export const useFields = (form, state, dispatch) => {
  const { t } = useTranslation();
  // const { getEnums } = useGetEnums();
  // const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  // const [enums, setEnums] = useState({});
  const dateFormat = 'DD-MM-YYYY';
  // const tradKeyEnums = 'professional_multirisk_insurances.form.enums';

  // const {
  //   isProtectedAgainstTheft,
  //   isFireProtection,
  //   isOtherTypologyActivities
  // } = state;

  // const resetFieldValues = useCallback(() => {
  //   const fieldResetRules = [
  //     {
  //       condition: !isProtectedAgainstTheft,
  //       path: [
  //         'professional_multirisk_insurance',
  //         'if_is_protected_against_theft_details'
  //       ],
  //       value: ''
  //     },
  //     {
  //       condition: !isFireProtection,
  //       path: [
  //         'professional_multirisk_insurance',
  //         'if_is_fire_protection_details'
  //       ],
  //       value: ''
  //     },
  //     {
  //       condition: !isOtherTypologyActivities,
  //       path: [
  //         'professional_multirisk_insurance',
  //         'typology_of_activities_if_other'
  //       ],
  //       value: ''
  //     }
  //   ];

  //   fieldResetRules.forEach(({ condition, path, value }) => {
  //     if (condition) {
  //       form.setFieldsValue({
  //         [path[0]]: { [path[1]]: value }
  //       });
  //     }
  //   });
  // }, [
  //   form,
  //   isProtectedAgainstTheft,
  //   isFireProtection,
  //   isOtherTypologyActivities
  // ]);

  // useEffect(() => {
  //   resetFieldValues();
  // }, [resetFieldValues]);

  const fieldsInformations = [
    {
      label: ['entity'],
      name: ['entity'],
      rules: [{ required: true }],
      input: <MultiSelectEntity dbKey="entity" />,
      startWithDivider: {
        title: t('professional_multirisk_insurances.form.main_informations')
      }
    },
    // {
    //   label: ['site'],
    //   name: ['site'],
    //   input: <SelectSite dbKey="site" />
    // },
    // {
    //   label: ['site_name'],
    //   name: ['professional_multirisk_insurance', 'site_name']
    // },
    // {
    //   label: ['site_address'],
    //   name: ['professional_multirisk_insurance', 'site_address']
    // },
    // {
    //   label: ['postal_code'],
    //   name: ['professional_multirisk_insurance', 'postal_code'],
    //   rules: [{ required: true }]
    // },
    // {
    //   label: ['city'],
    //   name: ['professional_multirisk_insurance', 'city'],
    //   rules: [{ required: true }]
    // },
    {
      label: ['entry_date'],
      name: ['professional_multirisk_insurance', 'entry_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    }
    // {
    //   label: ['total_insured_developed_area'],
    //   name: [
    //     'professional_multirisk_insurance',
    //     'total_insured_developed_area'
    //   ],
    //   rules: [{ required: true }],
    //   input: <Input type="Number" addonAfter="m²" />
    // },
    // {
    //   label: ['legal_status'],
    //   name: ['professional_multirisk_insurance', 'legal_status'],
    //   rules: [{ required: true }],
    //   input: (
    //     <GenericSelectEnums
    //       dbKey={['professional_multirisk_insurance', 'legal_status']}
    //       enums={enums.legalStatus}
    //       tradKeyEnums={tradKeyEnums}
    //     />
    //   )
    // },
    // {
    //   label: ['typology_of_activities'],
    //   name: ['professional_multirisk_insurance', 'typology_of_activities'],
    //   rules: [{ required: true }],
    //   input: (
    //     <SelectEnumsReducer
    //       dbKey={['professional_multirisk_insurance', 'typology_of_activities']}
    //       enums={enums.typologyOfActivities}
    //       tradKeyEnums={tradKeyEnums}
    //       dispatch={dispatch}
    //       field="isOtherTypologyActivities"
    //       type="TOGGLE_STATE"
    //     />
    //   )
    // },
    // {
    //   label: ['typology_of_activities_if_other'],
    //   name: [
    //     'professional_multirisk_insurance',
    //     'typology_of_activities_if_other'
    //   ],
    //   rules: [{ required: isOtherTypologyActivities }],
    //   hidden: !isOtherTypologyActivities,
    //   input: (
    //     <TextArea
    //       autoSize={{
    //         minRows: 3,
    //         maxRows: 5
    //       }}
    //     />
    //   )
    // },
    // {
    //   label: ['premicies_turnover'],
    //   name: ['professional_multirisk_insurance', 'premicies_turnover'],
    //   rules: [{ required: true }],
    //   input: <Input type="Number" addonAfter="€" />
    // },
    // {
    //   label: ['content'],
    //   name: ['professional_multirisk_insurance', 'content'],
    //   rules: [{ required: true }],
    //   input: (
    //     <TextArea
    //       autoSize={{
    //         minRows: 1,
    //         maxRows: 5
    //       }}
    //     />
    //   )
    // },
    // {
    //   label: ['is_protected_against_theft'],
    //   name: ['professional_multirisk_insurance', 'is_protected_against_theft'],
    //   rules: [{ required: true }],
    //   input: (
    //     <SelectYesNoReducer
    //       dbKey={[
    //         'professional_multirisk_insurance',
    //         'is_protected_against_theft'
    //       ]}
    //       tradKeyEnums={tradKeyEnums}
    //       dispatch={dispatch}
    //       field="isProtectedAgainstTheft"
    //       type="TOGGLE_STATE"
    //     />
    //   )
    // },
    // {
    //   label: ['if_is_protected_against_theft_details'],
    //   name: [
    //     'professional_multirisk_insurance',
    //     'if_is_protected_against_theft_details'
    //   ],
    //   rules: [{ required: isProtectedAgainstTheft }],
    //   hidden: !isProtectedAgainstTheft,
    //   input: (
    //     <TextArea
    //       autoSize={{
    //         minRows: 3,
    //         maxRows: 5
    //       }}
    //     />
    //   )
    // },
    // {
    //   label: ['is_fire_protection'],
    //   name: ['professional_multirisk_insurance', 'is_fire_protection'],
    //   rules: [{ required: true }],
    //   input: (
    //     <SelectYesNoReducer
    //       dbKey={['professional_multirisk_insurance', 'is_fire_protection']}
    //       tradKeyEnums={tradKeyEnums}
    //       dispatch={dispatch}
    //       field="isFireProtection"
    //       type="TOGGLE_STATE"
    //     />
    //   )
    // },
    // {
    //   label: ['if_is_fire_protection_details'],
    //   name: [
    //     'professional_multirisk_insurance',
    //     'if_is_fire_protection_details'
    //   ],
    //   rules: [{ required: isFireProtection }],
    //   hidden: !isFireProtection,
    //   input: (
    //     <TextArea
    //       autoSize={{
    //         minRows: 3,
    //         maxRows: 5
    //       }}
    //     />
    //   )
    // }
  ];

  // const fetchEnums = useCallback(async () => {
  //   setIsFieldsLoading(true);
  //   const data = await getEnums('professional-multirisk-insurances');
  //   setEnums(data);
  //   setIsFieldsLoading(false);
  // }, [getEnums]);

  // useEffect(() => {
  //   fetchEnums();
  // }, [fetchEnums]);

  return {
    fieldsInformations
    // isFieldsLoading
  };
};
