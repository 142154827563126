import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker, Input } from 'antd';
import { SelectManager } from '../../../components/SelectManager';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { SelectEnums } from './SelectEnums';
import { SelectYesNo } from '../../../components/SelectYesNo';
import { MultiSelectEntity } from '../../../components/MultiSelectEntity';
import { SelectSite } from '../../../components/SelectSite';

const { TextArea } = Input;

/**
 * Custom hook for managing form fields in the Cyber Insurance programme.
 * @hook
 * @param {object} form - The form instance from Ant Design.
 * @param {boolean} isSubsidiaries - Flag indicating if the company is a subsidiary.
 * @param {function} setIsSubsidiaries - Setter function for the isSubsidiaries flag.
 * @param {boolean} companyLocationFrance - Flag indicating if the company is located in France.
 * @param {function} setCompanyLocationFrance - Setter function for the companyLocationFrance flag.
 * @param {boolean} restartPlan - Flag indicating if there is a plan to restart after damage.
 * @param {function} setRestartPlan - Setter function for the restartPlan flag.
 * @param {boolean} isOutsideBackup - Flag indicating if there is an outside backup.
 * @param {function} setIsOutsideBackup - Setter function for the isOutsideBackup flag.
 * @param {boolean} isSiVpnSecured - Flag indicating if the SI/VPN is secured.
 * @param {function} setIsSiVpnSecured - Setter function for the isSiVpnSecured flag.
 * @param {boolean} isInfrastructureCloudHosted - Flag indicating if the infrastructure is cloud-hosted.
 * @param {function} setIsInfrastructureCloudHosted - Setter function for the isInfrastructureCloudHosted flag.
 * @param {boolean} isMicrosoftActiveDirectory - Flag indicating if Microsoft Active Directory is used.
 * @param {function} setIsMicrosoftActiveDirectory - Setter function for the isMicrosoftActiveDirectory flag.
 * @param {boolean} isSecurityUpdateAsap - Flag indicating if security updates are applied ASAP.
 * @param {function} setIsSecurityUpdateAsap - Setter function for the isSecurityUpdateAsap flag.
 * @param {boolean} isSupportFromVendors - Flag indicating if there is support from vendors.
 * @param {function} setIsSupportFromVendors - Setter function for the isSupportFromVendors flag.
 * @param {boolean} isSecurityEventsLogs - Flag indicating if security events logs are maintained.
 * @param {function} setIsSecurityEventsLogs - Setter function for the isSecurityEventsLogs flag.
 * @param {boolean} setIsTurnOverAboveFifty - Flag indicating if the turnover is above fifty.
 * @returns {object} - An object containing the necessary fields and their configurations.
 */
export const useFields = (
  form,
  isSubsidiaries,
  setIsSubsidiaries,
  companyLocationFrance,
  setCompanyLocationFrance,
  restartPlan,
  setRestartPlan,
  isOutsideBackup,
  setIsOutsideBackup,
  isSiVpnSecured,
  setIsSiVpnSecured,
  isInfrastructureCloudHosted,
  setIsInfrastructureCloudHosted,
  isMicrosoftActiveDirectory,
  setIsMicrosoftActiveDirectory,
  isSecurityUpdateAsap,
  setIsSecurityUpdateAsap,
  isSupportFromVendors,
  setIsSupportFromVendors,
  isSecurityEventsLogs,
  setIsSecurityEventsLogs,
  setIsTurnOverAboveFifty
) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [enums, setEnums] = useState({});
  const dateFormat = 'DD-MM-YYYY';

  useEffect(() => {
    if (!isSubsidiaries) {
      form.setFieldsValue({
        is_located_in_france_andorra_monaco_eu: '',
        if_not_subsidiary_number_france_andorra_monaco_eu_world: '',
        if_not_turnover_breakdown: ''
      });
    }
    if (!companyLocationFrance) {
      form.setFieldsValue({
        if_not_subsidiary_number_france_andorra_monaco_eu_world: '',
        if_not_turnover_breakdown: ''
      });
    }
  }, [isSubsidiaries, companyLocationFrance, form]);

  const fieldsInformations = [
    {
      label: ['customer_manager'],
      name: ['customer_manager'],
      rules: [{ required: true }],
      input: <SelectManager dbKey="customer_manager" />
    },
    {
      label: ['unit_manager'],
      name: ['unit_manager'],
      input: <SelectManager dbKey="unit_manager" />
    },
    {
      label: ['desired_effective_date'],
      name: ['desired_effective_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['company_name'],
      name: ['company_name'],
      rules: [{ required: true }]
    },
    {
      label: ['company_registration_number'],
      name: ['company_registration_number'],
      rules: [{ required: true }]
    },
    {
      label: ['activity_classification_number'],
      name: ['activity_classification_number'],
      rules: [{ required: true }]
    },
    {
      label: ['entity'],
      name: ['entity'],
      rules: [{ required: true }],
      input: <MultiSelectEntity dbKey="entity" />
    },
    {
      label: ['site'],
      name: ['site'],
      input: <SelectSite dbKey="site" />
    },
    {
      label: ['subscriber_adress'],
      name: ['subscriber_adress'],
      rules: [{ required: true }]
    },
    {
      label: ['internet_site_or_domain_name'],
      name: ['internet_site_or_domain_name'],
      rules: [{ required: true }]
    },
    {
      label: ['business_domain'],
      name: ['business_domain'],
      rules: [{ required: true }],
      input: (
        <SelectEnums dbKey="business_domain" enums={enums.businessDomain} />
      )
    },
    {
      label: ['total_workforce'],
      name: ['total_workforce'],
      rules: [{ required: true }],
      input: <Input type="Number" />
    },
    {
      label: ['last_yearly_turnover'],
      name: ['last_yearly_turnover'],
      rules: [{ required: true }],
      input: (
        <Input
          type="Number"
          addonAfter="Millions €"
          onChange={(e) => {
            const value = parseFloat(e.target.value);
            if (value > 50) {
              setIsTurnOverAboveFifty(true);
            } else {
              setIsTurnOverAboveFifty(false);
            }
          }}
        />
      )
    },
    {
      label: ['financial_year_closure_date'],
      name: ['financial_year_closure_date'],
      rules: [{ required: true }],
      input: <DatePicker format="DD/MM" />
    },
    {
      label: ['is_subsidiary'],
      name: ['is_subsidiary'],
      rules: [{ required: true }],
      input: (
        <SelectYesNo dbKey={['is_subsidiary']} setState={setIsSubsidiaries} />
      )
    },
    {
      label: ['is_located_in_france_andorra_monaco_eu'],
      name: ['is_located_in_france_andorra_monaco_eu'],
      rules: [{ required: isSubsidiaries }],
      hidden: !isSubsidiaries,
      input: (
        <SelectYesNo
          dbKey={['is_located_in_france_andorra_monaco_eu']}
          setState={setCompanyLocationFrance}
        />
      )
    },
    {
      label: ['if_not_subsidiary_number_france_andorra_monaco_eu_world'],
      name: ['if_not_subsidiary_number_france_andorra_monaco_eu_world'],
      rules: [{ required: !companyLocationFrance && isSubsidiaries }],
      hidden: companyLocationFrance || !isSubsidiaries,
      input: (
        <TextArea
          autoSize={{
            minRows: 3,
            maxRows: 5
          }}
        />
      )
    },
    {
      label: ['if_not_turnover_breakdown'],
      name: ['if_not_turnover_breakdown'],
      rules: [{ required: !companyLocationFrance && isSubsidiaries }],
      hidden: companyLocationFrance || !isSubsidiaries,
      input: (
        <TextArea
          autoSize={{
            minRows: 3,
            maxRows: 20
          }}
        />
      )
    },
    {
      label: ['main_customers_description'],
      name: ['main_customers_description'],
      rules: [{ required: true }],
      input: (
        <TextArea
          autoSize={{
            minRows: 3,
            maxRows: 20
          }}
        />
      )
    },
    {
      label: ['internet_services_turnover_part'],
      name: ['internet_services_turnover_part'],
      rules: [{ required: true }],
      input: (
        <SelectEnums
          dbKey="internet_services_turnover_part"
          enums={enums.interNetServicePart}
        />
      )
    },
    {
      label: ['french_turnover_percentage'],
      name: ['french_turnover_percentage'],
      rules: [{ required: true }],
      input: <Input type="Number" addonAfter="%" />
    },
    {
      label: ['european_turnover_percentage'],
      name: ['european_turnover_percentage'],
      rules: [{ required: true }],
      input: <Input type="Number" addonAfter="%" />
    },
    {
      label: ['canada_us_turnover_percentage'],
      name: ['canada_us_turnover_percentage'],
      rules: [{ required: true }],
      input: <Input type="Number" addonAfter="%" />
    },
    {
      label: ['global_gross_margin_percentage'],
      name: ['global_gross_margin_percentage'],
      rules: [{ required: true }],
      input: <Input type="Number" addonAfter="%" />
    },
    {
      label: ['workstation_number'],
      name: ['workstation_number'],
      rules: [{ required: true }],
      input: <Input type="Number" />
    },
    {
      label: ['server_number'],
      name: ['server_number'],
      rules: [{ required: true }],
      input: <Input type="Number" />
    },
    {
      label: ['personal_data_server_number'],
      name: ['personal_data_server_number'],
      rules: [{ required: true }],
      input: (
        <Input
          type="Number"
          addonAfter={t(`cyber_insurances.form.personal_datas_quantity`)}
        />
      )
    },
    {
      label: ['bank_details_server_number'],
      name: ['bank_details_server_number'],
      rules: [{ required: true }],
      input: (
        <Input
          type="Number"
          addonAfter={t(`cyber_insurances.form.personal_datas_quantity`)}
        />
      )
    },
    {
      label: ['health_data_server_number'],
      name: ['health_data_server_number'],
      rules: [{ required: true }],
      input: (
        <Input
          type="Number"
          addonAfter={t(`cyber_insurances.form.personal_datas_quantity`)}
        />
      )
    },
    {
      label: ['is_company_yearly_sensitize_cyber_risks'],
      name: ['is_company_yearly_sensitize_cyber_risks'],
      rules: [{ required: true }],
      input: (
        <SelectEnums
          dbKey="is_company_yearly_sensitize_cyber_risks"
          enums={enums.booleanChoices}
        />
      )
    },
    {
      label: ['is_plan_to_restart_after_damage'],
      name: ['is_plan_to_restart_after_damage'],
      rules: [{ required: true }],
      input: (
        <SelectYesNo
          dbKey={['is_plan_to_restart_after_damage']}
          setState={setRestartPlan}
        />
      )
    },
    {
      label: ['if_yes_is_tested_yearly'],
      name: ['if_yes_is_tested_yearly'],
      rules: [{ required: restartPlan }],
      hidden: !restartPlan,
      input: (
        <SelectEnums
          dbKey="if_yes_is_tested_yearly"
          enums={enums.booleanChoices}
        />
      )
    },
    {
      label: ['if_yes_activity_restart_delay'],
      name: ['if_yes_activity_restart_delay'],
      rules: [{ required: restartPlan }],
      hidden: !restartPlan,
      input: (
        <Input
          type="Number"
          addonAfter={t(`cyber_insurances.form.restart_delay_days`)}
        />
      )
    },
    {
      label: ['is_security_policy_applied_evenly_across_subsidiaries'],
      name: ['is_security_policy_applied_evenly_across_subsidiaries'],
      rules: [{ required: true }],
      input: (
        <SelectEnums
          dbKey="is_security_policy_applied_evenly_across_subsidiaries"
          enums={enums.booleanChoices}
        />
      )
    },
    {
      label: ['is_outside_backup'],
      name: ['is_outside_backup'],
      rules: [{ required: true }],
      input: (
        <SelectYesNo
          dbKey={['is_outside_backup']}
          setState={setIsOutsideBackup}
        />
      )
    },
    {
      label: ['if_yes_frequency'],
      name: ['if_yes_frequency'],
      rules: [{ required: isOutsideBackup }],
      hidden: !isOutsideBackup
    },
    {
      label: ['is_activity_restart_delay_tested_twice_a_year'],
      name: ['is_activity_restart_delay_tested_twice_a_year'],
      rules: [{ required: true }],
      input: (
        <SelectEnums
          dbKey={['is_activity_restart_delay_tested_twice_a_year']}
          enums={enums.booleanChoices}
        />
      )
    },
    {
      label: ['is_connection_to_si_vpn_secured'],
      name: ['is_connection_to_si_vpn_secured'],
      rules: [{ required: true }],
      input: (
        <SelectYesNo
          dbKey={['is_connection_to_si_vpn_secured']}
          setState={setIsSiVpnSecured}
        />
      )
    },
    {
      label: ['if_not_protocols_description'],
      name: ['if_not_protocols_description'],
      rules: [{ required: !isSiVpnSecured }],
      hidden: isSiVpnSecured,
      input: (
        <TextArea
          autoSize={{
            minRows: 1,
            maxRows: 5
          }}
        />
      )
    },
    {
      label: ['is_network_access_control_enabled'],
      name: ['is_network_access_control_enabled'],
      rules: [{ required: true }],
      input: (
        <SelectEnums
          dbKey="is_network_access_control_enabled"
          enums={enums.booleanChoices}
        />
      )
    },
    {
      label: ['is_double_authentication_applied_for'],
      name: ['is_double_authentication_applied_for'],
      rules: [{ required: true }],
      input: (
        <SelectEnums
          dbKey="is_double_authentication_applied_for"
          enums={enums.authenticationAppliedFor}
          isMultiple
        />
      )
    },
    {
      label: ['is_password_manager_required'],
      name: ['is_password_manager_required'],
      rules: [{ required: true }],
      input: (
        <SelectEnums
          dbKey="is_password_manager_required"
          enums={enums.booleanChoices}
        />
      )
    },
    {
      label: ['is_infrastructure_cloud_hosted'],
      name: ['is_infrastructure_cloud_hosted'],
      rules: [{ required: true }],
      input: (
        <SelectYesNo
          dbKey={['is_infrastructure_cloud_hosted']}
          setState={setIsInfrastructureCloudHosted}
        />
      )
    },
    {
      label: ['if_cloud_hosted_is_totally_hosted'],
      name: ['if_cloud_hosted_is_totally_hosted'],
      rules: [{ required: isInfrastructureCloudHosted }],
      hidden: !isInfrastructureCloudHosted,
      input: (
        <SelectEnums
          dbKey="if_cloud_hosted_is_totally_hosted"
          enums={enums.booleanChoices}
        />
      )
    },
    {
      label: ['if_cloud_hosted_cloud_providers'],
      name: ['if_cloud_hosted_cloud_providers'],
      rules: [{ required: isInfrastructureCloudHosted }],
      hidden: !isInfrastructureCloudHosted,
      input: (
        <SelectEnums
          dbKey="if_cloud_hosted_cloud_providers"
          enums={enums.cloudProviders}
          isMultiple
        />
      )
    },
    {
      label: ['if_cloud_hosted_cloud_providers_other'],
      name: ['if_cloud_hosted_cloud_providers_other'],
      hidden: !isInfrastructureCloudHosted,
      input: <Input defaultValue="-" />
    },
    {
      label: ['is_microsoft_active_directory_used'],
      name: ['is_microsoft_active_directory_used'],
      rules: [{ required: true }],
      input: (
        <SelectYesNo
          dbKey={['is_microsoft_active_directory_used']}
          setState={setIsMicrosoftActiveDirectory}
        />
      )
    },
    {
      label: ['if_microsoft_active_directory_user_accounts_quantity'],
      name: ['if_microsoft_active_directory_user_accounts_quantity'],
      rules: [{ required: isMicrosoftActiveDirectory }],
      hidden: !isMicrosoftActiveDirectory,
      input: <Input type="Number" />
    },
    {
      label: ['if_microsoft_active_directory_service_accounts_quantity'],
      name: ['if_microsoft_active_directory_service_accounts_quantity'],
      rules: [{ required: isMicrosoftActiveDirectory }],
      hidden: !isMicrosoftActiveDirectory,
      input: <Input type="Number" />
    },
    {
      label: ['is_admin_right_limited_and_admin_session_segregated'],
      name: ['is_admin_right_limited_and_admin_session_segregated'],
      rules: [{ required: true }],
      input: (
        <SelectEnums
          dbKey="is_admin_right_limited_and_admin_session_segregated"
          enums={enums.booleanChoices}
        />
      )
    },
    {
      label: ['is_access_permissions_enabled'],
      name: ['is_access_permissions_enabled'],
      rules: [{ required: true }],
      input: (
        <SelectEnums
          dbKey="is_access_permissions_enabled"
          enums={enums.booleanChoices}
        />
      )
    },
    {
      label: ['is_collaborator_admin_of_own_workspace'],
      name: ['is_collaborator_admin_of_own_workspace'],
      rules: [{ required: true }],
      input: (
        <SelectEnums
          dbKey="is_collaborator_admin_of_own_workspace"
          enums={enums.booleanChoices}
        />
      )
    },
    {
      label: ['is_mobile_workspace_hashed'],
      name: ['is_mobile_workspace_hashed'],
      rules: [{ required: true }],
      input: (
        <SelectEnums
          dbKey="is_mobile_workspace_hashed"
          enums={enums.booleanChoices}
        />
      )
    },
    {
      label: ['is_boyd_prohibited'],
      name: ['is_boyd_prohibited'],
      rules: [{ required: true }],
      input: (
        <SelectEnums dbKey="is_boyd_prohibited" enums={enums.booleanChoices} />
      )
    },
    {
      label: ['is_network_segmentation_enabled'],
      name: ['is_network_segmentation_enabled'],
      rules: [{ required: true }],
      input: (
        <SelectEnums
          dbKey="is_network_segmentation_enabled"
          enums={enums.booleanChoices}
        />
      )
    },
    {
      label: ['is_security_update_apply_as_soon_as_released'],
      name: ['is_security_update_apply_as_soon_as_released'],
      rules: [{ required: true }],
      input: (
        <SelectYesNo
          dbKey={['is_security_update_apply_as_soon_as_released']}
          setState={setIsSecurityUpdateAsap}
        />
      )
    },
    {
      label: ['if_applied_average_delay_for_high_criticality'],
      name: ['if_applied_average_delay_for_high_criticality'],
      rules: [{ required: isSecurityUpdateAsap }],
      hidden: !isSecurityUpdateAsap,
      input: (
        <TextArea
          autoSize={{
            minRows: 1,
            maxRows: 5
          }}
        />
      )
    },
    {
      label: ['is_os_and_software_updated_and_supported_by_vendor'],
      name: ['is_os_and_software_updated_and_supported_by_vendor'],
      rules: [{ required: true }],
      input: (
        <SelectYesNo
          dbKey={['is_os_and_software_updated_and_supported_by_vendor']}
          setState={setIsSupportFromVendors}
        />
      )
    },
    {
      label: ['if_not_specify_safety_measures'],
      name: ['if_not_specify_safety_measures'],
      rules: [{ required: !isSupportFromVendors }],
      hidden: isSupportFromVendors,
      input: (
        <TextArea
          autoSize={{
            minRows: 1,
            maxRows: 5
          }}
        />
      )
    },
    {
      label: ['is_pentest_audit_conducted_yearly'],
      name: ['is_pentest_audit_conducted_yearly'],
      rules: [{ required: true }],
      input: (
        <SelectEnums
          dbKey="is_pentest_audit_conducted_yearly"
          enums={enums.booleanChoices}
        />
      )
    },
    {
      label: ['is_antivirus_installed_and_updated_on_each_workspace'],
      name: ['is_antivirus_installed_and_updated_on_each_workspace'],
      rules: [{ required: true }],
      input: (
        <SelectEnums
          dbKey="is_antivirus_installed_and_updated_on_each_workspace"
          enums={enums.antivirusAnswers}
        />
      )
    },
    {
      label: ['is_antivirus_installed_and_updated_on_each_server'],
      name: ['is_antivirus_installed_and_updated_on_each_server'],
      rules: [{ required: true }],
      input: (
        <SelectEnums
          dbKey="is_antivirus_installed_and_updated_on_each_server"
          enums={enums.antivirusAnswers}
        />
      )
    },
    {
      label: ['is_email_filtered'],
      name: ['is_email_filtered'],
      rules: [{ required: true }],
      input: (
        <SelectEnums dbKey="is_email_filtered" enums={enums.booleanChoices} />
      )
    },
    {
      label: ['is_security_events_logs_collection_and_handling_tool'],
      name: ['is_security_events_logs_collection_and_handling_tool'],
      rules: [{ required: true }],
      input: (
        <SelectYesNo
          dbKey={['is_security_events_logs_collection_and_handling_tool']}
          setState={setIsSecurityEventsLogs}
        />
      )
    },
    {
      label: ['if_security_events_logs_collection_and_handling_tool_specify'],
      name: ['if_security_events_logs_collection_and_handling_tool_specify'],
      rules: [{ required: isSecurityEventsLogs }],
      hidden: !isSecurityEventsLogs,
      input: (
        <TextArea
          autoSize={{
            minRows: 1,
            maxRows: 5
          }}
        />
      )
    },
    {
      label: ['is_soc'],
      name: ['is_soc'],
      rules: [{ required: true }],
      input: <SelectEnums dbKey="is_soc" enums={enums.booleanChoices} />
    },
    {
      label: ['is_double_signature_validation_required_for_payments'],
      name: ['is_double_signature_validation_required_for_payments'],
      rules: [{ required: true }],
      input: (
        <SelectEnums
          dbKey="is_double_signature_validation_required_for_payments"
          enums={enums.booleanChoices}
        />
      )
    },
    {
      label: ['no_claims_during_last_three_years'],
      name: ['no_claims_during_last_three_years'],
      rules: [{ required: true }],
      input: (
        <SelectEnums
          dbKey="no_claims_during_last_three_years"
          enums={enums.booleanChoices}
        />
      )
    },
    {
      label: ['other_security_posture_informations'],
      name: ['other_security_posture_informations'],
      rules: [{ required: true }],
      input: (
        <TextArea
          autoSize={{
            minRows: 1,
            maxRows: 10
          }}
        />
      )
    },
    {
      label: ['other_infrastructure_informations'],
      name: ['other_infrastructure_informations'],
      rules: [{ required: true }],
      input: (
        <TextArea
          autoSize={{
            minRows: 1,
            maxRows: 10
          }}
        />
      )
    },
    {
      label: ['signee'],
      name: ['signee'],
      rules: [{ required: true }]
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/cyber-insurances/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fieldsInformations,
    isFieldsLoading
  };
};
