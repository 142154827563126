// TODO: Do not remove commented code, temporary disabled !!!!
//  the commented code is mainly related to the select all and select read of checkboxes
import { useContext, useState, useCallback, useEffect } from 'react';
import {
  Select,
  Col,
  Row,
  Input,
  // Checkbox,
  Button,
  Tree,
  notification,
  Flex,
  Spin
} from 'antd';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { ContentCustom } from '../../../../components/ContentCustom/ContentCustom';
import { handleSearch, handleSearchUserTwo } from '../utils/searchUtils';
import { titlesToTranslate } from './utils';
import { useCompanyContext } from '../../CompanyContext';
import { useHandleResize } from '../../../../utils/handleResize';

const { Search } = Input;
const { Option } = Select;

/**
 * Component for managing permissions.
 *
 * @component
 * @returns {JSX.Element} PermissionsManagement component.
 */
export const PermissionsManagement = () => {
  const { dispatchAPI, company } = useContext(AuthContext);
  const { forceRefresh } = useCompanyContext();
  const { message } = useErrorMessage();
  const [users, setUsers] = useState([]);
  const [userTwo, setUserTwo] = useState([]);
  const [show, setShow] = useState(false);
  const [secondShow, setSecondShow] = useState(false);
  const { t } = useTranslation();
  // const [selectAllCheckBox, setSelectAllCheckBox] = useState(false);
  // const [selectRead, setSelectRead] = useState(false);
  const [, setKeySearched] = useState('');
  const [nodeChecked, setNodeChecked] = useState([]);
  const [permissionId, setPermissionId] = useState('');
  const [defaultChecked, setDefaultChecked] = useState([]);
  const [treeDataUserOne, setTreeDataUserOne] = useState([]);
  const [treeDataUserTwo, setTreeDataUserTwo] = useState([]);
  const [defaultCheckedTwo, setDefaultCheckedTwo] = useState([]);
  const [isSpinOpen, setIsSpinOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectValue, setSelectValue] = useState('');
  const { width } = useHandleResize();

  const getParentsNodes = (key, treeData, parents = []) => {
    let parentFound = false;

    treeData.some((node) => {
      if (node.key === key) {
        parentFound = true;
        return true;
      }
      if (node.children) {
        const parentKeys = getParentsNodes(key, node.children, [
          ...parents,
          node.key
        ]);
        if (parentKeys) {
          parents.push(...parentKeys);
          parentFound = true;
          return true;
        }
      }
      return false;
    });
    return parentFound ? parents : null;
  };

  const getUser = useCallback(async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/${id}/companies/${company}?populate=permissions`
      });
      return data[0];
    } catch (e) {
      message(e.response.status);
      return [];
    }
  }, []);

  const getUsers = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users?role=users:USER,users:ACCOUNTANCY-USER&company_Id=${company}`
      });
      setUsers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  }, []);

  useEffect(() => {
    getUsers(company);
  }, [company]);

  // const getAllSelect = (treeData) => {
  //   const allSelect = [];
  //   treeData?.forEach((permission) => {
  //     if (permission?.children) {
  //       allSelect.push(...getAllSelect(permission?.children));
  //     }
  //     allSelect.push(permission?.key);
  //   });
  //   setNodeChecked(allSelect);
  //   setDefaultChecked(allSelect);
  //   return allSelect;
  // };

  // const getVisualisationSelect = (treeData) => {
  //   setIsSpinOpen(true);
  //   const allSelect = new Set();

  //   const addNodeAndParents = (nodeKey) => {
  //     allSelect.add(nodeKey);
  //     const parentKeys = getParentsNodes(nodeKey, treeData);
  //     if (parentKeys) {
  //       parentKeys.forEach((parentKey) => allSelect.add(parentKey));
  //     }
  //   };

  //   const traverseTree = (nodes) => {
  //     nodes.forEach((node) => {
  //       if (node.children) {
  //         traverseTree(node.children);
  //       }
  //       if (node.title === 'Visualisation') {
  //         addNodeAndParents(node.key);
  //       }
  //     });
  //   };
  //   traverseTree(treeData);

  //   const allSelectArray = Array.from(allSelect);
  //   setNodeChecked(allSelectArray);
  //   setDefaultChecked(allSelectArray);
  //   setIsSpinOpen(false);
  //   return allSelectArray;
  // };

  const parsePermissionsChecked = (permissions) => {
    const permissionsChecked = [];

    permissions?.forEach((permission) => {
      if (permission?.val === true) {
        permissionsChecked.push(permission?.key);
      }
      if (permission?.children) {
        permissionsChecked.push(
          ...parsePermissionsChecked(permission?.children)
        );
      }
    });

    return permissionsChecked;
  };

  const translateEnums = (permissions) => {
    const translateObject = (obj) => {
      if (!obj || typeof obj !== 'object') {
        return obj;
      }

      const newObj = Array.isArray(obj) ? [] : {};

      Object.keys(obj).forEach((key) => {
        if (
          key === 'title' &&
          Object.prototype.hasOwnProperty.call(titlesToTranslate, obj[key])
        ) {
          newObj[key] = titlesToTranslate[obj[key]];
        } else if (key === 'children' && Array.isArray(obj[key])) {
          newObj[key] = obj[key].map((child) => translateObject(child));
        } else {
          newObj[key] = translateObject(obj[key]);
        }
      });

      return newObj;
    };

    return translateObject(permissions);
  };

  const showRights = async (value) => {
    const gettedUser = await getUser(value);

    setPermissionId(gettedUser?.permissions._id);

    const userExpectedFiltered = users.filter(
      (user) => user?._id !== gettedUser?._id
    );

    setUserTwo(userExpectedFiltered);

    setDefaultChecked(
      parsePermissionsChecked(gettedUser?.permissions?.permissions)
    );

    setSelectValue(`${gettedUser.first_name} ${gettedUser.last_name}`);

    setTreeDataUserOne(translateEnums(gettedUser?.permissions?.permissions));

    setShow(true);
  };

  const showRightsSecondUser = async (value) => {
    const gettedUser = await getUser(value);

    setDefaultCheckedTwo(
      parsePermissionsChecked(gettedUser?.permissions?.permissions)
    );
    setTreeDataUserTwo(translateEnums(gettedUser?.permissions?.permissions));
    setSecondShow(true);
  };

  const handleCancel = () => {
    setShow(false);
    setNodeChecked([]);
    setDefaultChecked([]);
    setTreeDataUserOne([]);
    setSelectValue('');
    // setSelectAllCheckBox(false);
  };

  // const handleSelectRead = () => {
  //   getVisualisationSelect(treeDataUserOne);
  //   setSelectRead(!selectRead);
  // };

  // const handleSelectAll = () => {
  //   getAllSelect(treeDataUserOne);
  //   setSelectAllCheckBox(!selectAllCheckBox);
  // };

  const handleDefaultChecked = () => defaultChecked;

  const handleDefaultCheckedTwo = () => defaultCheckedTwo;

  const handleOnCheck = (checkedKeys) => {
    const allCheckedKeys = new Set(checkedKeys.checked);

    // For each checked key, find and add its parent keys
    checkedKeys.checked.forEach((key) => {
      const parentKeys = getParentsNodes(key, treeDataUserOne);
      if (parentKeys) {
        parentKeys.forEach((parentKey) => allCheckedKeys.add(parentKey));
      }
    });

    const updatedCheckedKeys = Array.from(allCheckedKeys);
    setNodeChecked(updatedCheckedKeys);
    setDefaultChecked(updatedCheckedKeys);
  };
  const handleSave = async () => {
    setIsSpinOpen(true);
    try {
      await dispatchAPI('PATCH', {
        url: `permissions/${permissionId}`,
        body: {
          newPermissions: nodeChecked.length > 0 ? nodeChecked : defaultChecked
        }
      });
      setShow(false);
      setNodeChecked([]);
      setDefaultChecked([]);
      setTreeDataUserOne([]);
      // setSelectAllCheckBox(false);
      setSelectValue('');
      notification.success({
        message: t('export.messages.permissions.success')
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    } finally {
      setIsSpinOpen(false);
    }
  };

  const handleUpdatePermissions = async () => {
    try {
      setLoading(true);
      setIsSpinOpen(true);
      await dispatchAPI('PATCH', {
        url: `permissions/patchAllPermissions/company/${company}`
      });
      setShow(false);
      setNodeChecked([]);
      setDefaultChecked([]);
      setTreeDataUserOne([]);
      // setSelectAllCheckBox(false);
      setSelectValue('');
      setLoading(false);
      notification.success({
        message: t('export.messages.permissions.success')
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    } finally {
      setIsSpinOpen(false);
    }
  };

  useEffect(() => {
    setShow(false);
    setNodeChecked([]);
    setDefaultChecked([]);
    setTreeDataUserOne([]);
    setSelectValue('');
    setTreeDataUserTwo([]);
    setDefaultCheckedTwo([]);
    setSecondShow(false);
  }, [forceRefresh]);

  return (
    <>
      <Spin spinning={isSpinOpen} fullscreen="true" />
      <ContentCustom>
        <p style={{ marginBottom: 2 }}>{t('companies.form.user_select')}</p>
        <Flex gap="middle" align="center">
          <Select
            style={{ width: 320 }}
            onChange={showRights}
            value={selectValue}
          >
            {users
              ? users.map((user) => (
                  <Option key={user._id} value={user?._id}>
                    {user.first_name}&nbsp;{user.last_name}
                  </Option>
                ))
              : null}
          </Select>
          <Button loading={loading} onClick={() => handleUpdatePermissions()}>
            {t('companies.form.updatePermissions')}
          </Button>
        </Flex>
      </ContentCustom>
      {show ? (
        <ContentCustom
          style={{
            borderTop: '1px solid var(--borderColor)'
          }}
        >
          <Row gutter={[40, 24]}>
            <Col
              xs={24}
              xl={14}
              style={{
                borderRight:
                  width >= 1200 ? '1px solid var(--borderColor)' : 'none',
                borderBottom:
                  width < 1200 ? '1px solid var(--borderColor)' : 'none'
              }}
            >
              <Row justify="space-between">
                <Search
                  style={{ width: 320 }}
                  allowClear
                  placeholder={t('placeholder.search')}
                  onSearch={(value) => handleSearch(value, setKeySearched)}
                />
                {/* <Checkbox
                  checked={selectAllCheckBox}
                  onChange={handleSelectAll}
                >
                  {t('companies.form.check_visualize_modify')}
                </Checkbox> */}
                {/* <Checkbox checked={selectRead} onChange={handleSelectRead}>
                  {t('companies.form.check_all_visualization')}
                </Checkbox> */}
                <Flex gap="small">
                  <Button
                    type="primary"
                    onClick={() => {
                      handleSave();
                    }}
                  >
                    {t('buttons.save')}
                  </Button>
                  <Button
                    onClick={() => {
                      handleCancel();
                    }}
                  >
                    {t('buttons.cancel')}
                  </Button>
                </Flex>
              </Row>
              <Tree
                checkedKeys={handleDefaultChecked()}
                checkable
                autoExpandParent
                loadingTree
                treeData={treeDataUserOne}
                checkStrictly
                onCheck={(checkedKeys) => {
                  handleOnCheck(checkedKeys);
                }}
                style={{ margin: '16px 0' }}
              />
            </Col>
            <Col xs={24} xl={10}>
              <Col>
                <p style={{ marginBottom: 2 }}>
                  {t('companies.form.other_user_select')}
                </p>
                <Select style={{ width: 320 }} onChange={showRightsSecondUser}>
                  {users
                    ? userTwo.map((user) => (
                        <Option key={user._id} value={user?.permissions?._id}>
                          {user.first_name}&nbsp;{user.last_name}
                        </Option>
                      ))
                    : null}
                </Select>
              </Col>
              {secondShow ? (
                <Col style={{ marginTop: 16 }}>
                  <Search
                    style={{ width: 320 }}
                    allowClear
                    placeholder={t('placeholder.search')}
                    onSearch={(value) => handleSearchUserTwo(value)}
                  />
                  <Tree
                    checkedKeys={handleDefaultCheckedTwo()}
                    checkable
                    autoExpandParent
                    isLoading
                    checkStrictly
                    treeData={treeDataUserTwo}
                    style={{ margin: '16px 0' }}
                  />
                </Col>
              ) : null}
            </Col>
          </Row>
        </ContentCustom>
      ) : null}
    </>
  );
};
