import { Route, Routes } from 'react-router-dom';
import { CreateUpdateCompany } from './CreateUpdateCompany';
import { ShowCompany } from './ShowCompany/ShowCompany';
import { Exception } from '../../components';
import { CompanyContextProvider } from './CompanyContext';

export const CompanyRouter = () => (
  <Routes>
    <Route path="/create" element={<CreateUpdateCompany purpose="create" />} />
    <Route path="/edit/:id" element={<CreateUpdateCompany purpose="edit" />} />
    <Route
      index
      element={
        <CompanyContextProvider>
          <ShowCompany />
        </CompanyContextProvider>
      }
    />
    <Route path="*" element={<Exception />} />
  </Routes>
);
