/**
 * Retrieves and formats sub-programmes based on the provided record and translation function.
 * @param {Object} record - The record object containing sub-programme types.
 * @param {Function} t - The translation function used to translate sub-programme names.
 * @returns {string} - The formatted sub-programmes or '-' if no sub-programmes are found.
 */
export const getAndFormatSubProgrammes = (record, t) => {
  const subProgrammeTypes = [
    'fleet_sub_programmes',
    'civil_liability_sub_programmes',
    'transport_sub_programmes',
    'construction_sub_programmes'
  ];

  const foundSubProgrammes = subProgrammeTypes
    .map((type) => record[type])
    .find((subProgrammes) => subProgrammes && subProgrammes.length > 0);

  return foundSubProgrammes
    ? foundSubProgrammes
        .map((subProgramme) => t(`documents.form.${subProgramme}`))
        .join(', ')
    : '-';
};
