// TODO: Wait for attachment infos
import { useEffect } from 'react';
import ReactQuill from 'react-quill';
import { useRecipientInputs } from './recipientSelector';

export const useMailingFields = (
  editorState,
  setEditorState,
  baseUrl,
  setRecipientMail
) => {
  const { SelectReceipientMail, ReceptorInput, currentContact } =
    useRecipientInputs(baseUrl);
  useEffect(() => {
    setRecipientMail(currentContact);
  }, [currentContact]);

  const mailingFields = [
    {
      name: ['recipient'],
      rules: [{ required: true }],
      input: SelectReceipientMail()
    },
    {
      name: ['recipient_email_address'],
      rules: [{ required: true }, { type: 'email' }],
      input: ReceptorInput()
    },
    {
      name: ['sender'],
      rules: [{ required: true }]
    },
    {
      name: ['mail_object'],
      rules: [{ required: true }]
    },
    // {
    //   name: ['attachments'],
    //   rules: [{ required: true }]
    // },
    {
      name: ['template_content'],
      rules: [{ required: true }],
      input: (
        <ReactQuill
          style={{ width: '100%', height: '100%' }}
          value={editorState}
          onChange={(value) => setEditorState(value)}
        />
      )
    }
  ];

  return { mailingFields };
};
