import { RenderTransportList } from '../utils/renderListResource';

/**
 * Displays a list of Other construction claims with relevant columns and additional filters.
 *
 * @component
 * @returns {React.ReactNode} List of Other construction claims.
 */
export const ListOwnAccounts = () =>
  RenderTransportList({
    actionColumnUrl: 'own-accounts',
    populate: 'entity,site,entry_id,company,related_contract_entities',
    transport_sub_programmes: 'OWN_ACCOUNT',
    sheetName: 'ownAccounts'
  });
