import {
  Button,
  Divider,
  Tree,
  Popconfirm,
  Input,
  Space,
  message,
  Flex
} from 'antd';
import { CheckOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useCompanyContext } from '../../CompanyContext';
import { useHandleUpdatePermissions } from '../Hooks/useHandleUpdatePermissions';

const { TreeNode } = Tree;

/**
 * Component for displaying and managing tree nodes.
 *
 * @component
 * @returns {object} The renderTitle and renderTreeNodes functions.
 */
export const DisplayTreeNodes = () => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const { message: errorMessage } = useErrorMessage();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [edit, setEdit] = useState(true);
  const [Del, setDelete] = useState(true);

  const { dispatchAPI, permissions, verifPermissions } = useAuthContext();
  const { handleUpdatePermissions } = useHandleUpdatePermissions();
  const [inputValue, setInputValue] = useState({});
  const [editNodeKey, setEditNodeKey] = useState(null);
  const { forceRefresh, setForceRefresh } = useCompanyContext();

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setDelete(
        !verifPermissions('/sites/delete', permissions?.notPermittedRoutes)
      );
      setEdit(
        !verifPermissions('/sites/edit', permissions?.notPermittedRoutes)
      );
    }
  }, [permissions]);

  const renderTitle = (node) => {
    let nodeType = '';
    const setKey = (key) => {
      const id = key;
      setEditNodeKey(id);
    };

    if (node.id && node.children && node.children.length > 0) {
      nodeType = 'entity';
    }
    if (node.id && node.children && node.children.length === 0) {
      nodeType = 'site';
    }

    const getItem = async (id, type) => {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/${type}/${id}`
        });
        setInputValue(data.name);
      } catch (e) {
        errorMessage(e);
      }
    };

    const patchItem = async (id, value, type) => {
      setIsSubmitting(true);
      try {
        await dispatchAPI('PATCH', {
          url: `/${type}/${id}`,
          body: JSON.stringify({
            values: JSON.stringify({ name: value })
          })
        });
        await handleUpdatePermissions();
        setInputValue(value);
        setForceRefresh(!forceRefresh);

        setEditMode(false);
        setIsSubmitting(false);
      } catch (e) {
        errorMessage(e);
      } finally {
        setIsSubmitting(false);
      }
    };

    const deleteEntity = async (id) => {
      setIsSubmitting(true);
      try {
        setIsSubmitting(true);
        await dispatchAPI('DELETE', {
          url: `/entities/${id}`
        });
        await handleUpdatePermissions();
        message.success(
          t(`workflows.messages.success.entity_successfully_deleted`)
        );
        setForceRefresh(!forceRefresh);
      } catch (e) {
        errorMessage(e);
      } finally {
        setIsSubmitting(false);
      }
    };

    const deleteSite = async (id) => {
      setIsSubmitting(true);
      try {
        await dispatchAPI('DELETE', {
          url: `/entities/sites/${id}`
        });
        await handleUpdatePermissions();
        message.success(
          t(`workflows.messages.success.entity_successfully_deleted`)
        );
        setForceRefresh(!forceRefresh);
      } catch (e) {
        errorMessage(e);
      } finally {
        setIsSubmitting(false);
      }
    };

    const handleDeleteItem = async (id, type) => {
      if (type === 'entities') {
        await deleteEntity(id);
      } else {
        await deleteSite(id);
      }
      setForceRefresh(!forceRefresh);
    };

    if (!node) return null;
    if (node.id) {
      if (editMode && editNodeKey === node.id) {
        return (
          <Space style={{ width: '100%' }}>
            <Input
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
            />
            <Button
              type="primary"
              loading={isSubmitting}
              onClick={() => {
                patchItem(node.id, inputValue, nodeType);
                setEditMode(false);
              }}
            >
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
          </Space>
        );
      }
      return (
        <Flex gap="large" align="center">
          {node.title}
          <Flex align="center" gap="small">
            {edit && (
              <>
                <EditOutlined
                  onClick={() => {
                    setEditMode(true);
                    getItem(node.id, nodeType);
                    setKey(node.id);
                  }}
                />
                <Divider type="vertical" />
              </>
            )}
            {Del && (
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={() => handleDeleteItem(node.id, nodeType)}
              >
                <Button type="link" style={{ padding: 0 }}>
                  <DeleteOutlined
                    style={{ color: 'var(--errorColor' }}
                    type="delete"
                  />
                </Button>
              </Popconfirm>
            )}
          </Flex>
        </Flex>
      );
    }
    return node.title;
  };

  const renderTitleNode = (node) => {
    if (typeof node === 'string') return t(`tiles.${node}`);
    return node;
  };

  const renderTreeNodes = (nodes) => {
    if (!nodes) return null;
    if (Array.isArray(nodes)) {
      return nodes.map((node) => {
        const title = renderTitle(node);
        if (node.children) {
          return (
            <TreeNode
              key={node.key}
              title={renderTitleNode(title)}
              autoExpandParent
            >
              {renderTreeNodes(node.children)}
            </TreeNode>
          );
        }
        return <TreeNode key={node.key} title={title} isLeaf={node.isLeaf} />;
      });
    }
    const title = renderTitle(nodes);
    return <TreeNode key={nodes.key} title={title} isLeaf={nodes.isLeaf} />;
  };

  return {
    renderTitle,
    renderTreeNodes
  };
};
