import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useAuthContext } from '../../../contexts/AuthContext';

import useFields from './utils/fieldsMachineryBreakage';
import { MachineryBreakageContext } from './MachineryBreakageContext';
import { formatDatePickerDate } from '../../../utils/formatDatePickerDate';
import { useHistory } from '../../../utils/historyUtils';

/**
 * `MachineryBreakageCreateUpdate` is a React component for creating or updating documents.
 *
 * Depending on the provided `purpose` prop, this component can be used to either create or edit a document.
 * The component utilizes the `CreateUpdateContainer` for rendering the input fields and managing form actions.
 *
 * Note: This component does not support managing files.
 *
 * @component
 * @param {object} props The props for the component.
 * @param {string} props.purpose Indicates the purpose of the form. It should be either 'create' or 'edit'.
 * @returns {React.ElementType} A form for creating or updating a document.
 */

export const MachineryBreakageCreateUpdate = ({ purpose }) => {
  const { setCurrentEntityContext, setCurrentContract } = useContext(
    MachineryBreakageContext
  );

  const [form] = Form.useForm();
  const resource = 'machinery_breakages';
  const [currentDates, setCurrentDates] = useState({ entry_date: null });
  const { setInitialHistory, createHistory, updateHistory } = useHistory();
  const [machinaryStatusType, setMachinaryStatusType] = useState(' ');
  const { fieldsGolf, isFieldsLoading } = useFields({
    currentDates,
    setCurrentDates,
    setMachinaryStatusType,
    form
  });
  const { company, companyName } = useAuthContext();
  const { t } = useTranslation();
  const config = {
    onGetResource: {
      setFields: (data) => {
        setCurrentContract(data.contract);
        if (data?.entry_date) {
          setCurrentDates({
            entry_date: formatDatePickerDate(data.entry_date)
          });
          if (data?.status) {
            setMachinaryStatusType(data.status);
          }
        }
        setInitialHistory(data);
        return {
          ...data,
          desired_effective_date:
            data.desired_effective_date && dayjs(data.desired_effective_date),
          date_added: data.date_added && dayjs(data.date_added),
          initial_commissioning_date:
            data.initial_commissioning_date &&
            dayjs(data.initial_commissioning_date),
          removal_date: data.removal_date && dayjs(data.removal_date),
          entry_date: data.entry_date && dayjs(data.entry_date)
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        programmes_types: 'MACHINERY_BREAKAGE',
        company,
        ...createHistory('program')
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        ...updateHistory('program')
      })
    }
  };
  return (
    <CreateUpdateContainer
      setCurrentEntityContext={setCurrentEntityContext}
      fields={fieldsGolf}
      parentForm={form}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="machinery-breakages"
      resource={resource}
      config={config}
      machinaryStatusType={machinaryStatusType}
      extraTitle={<Row justify="center">{`${t('folder')} ${companyName}`}</Row>}
      withFilesManager
      populate="?populate=documents.file"
    />
  );
};

MachineryBreakageCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
