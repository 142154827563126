import { Route, Routes } from 'react-router-dom';
import { ListMachineryBreakage } from './ListMachineryBreakage';
import { MachineryBreakageCreateUpdate } from './MachineryBreakageCreateUpdate';
import { MachineryBreakageShow } from './MachineryBreakageShow';
import { Exception } from '../../../components';
import { MachineryBreakageProvider } from './MachineryBreakageContext';

export const MachineryBreakageRouter = () => (
  <MachineryBreakageProvider>
    <Routes>
      <Route
        path="create"
        element={<MachineryBreakageCreateUpdate purpose="create" />}
      />
      <Route
        path="edit/:id"
        element={<MachineryBreakageCreateUpdate purpose="edit" />}
      />
      <Route path="show/:id" element={<MachineryBreakageShow />} />
      <Route index element={<ListMachineryBreakage />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </MachineryBreakageProvider>
);
