// TODO: awaiting customer feedback before commented code can be removed
import PropTypes from 'prop-types';
// import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
// import { Form } from 'antd';
import { useFields } from './fieldsProMultiriskInsuanceSubscription';
import { useAuthContext } from '../../../contexts/AuthContext';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useConfig } from './createUpdateConfig';

/**
 * Component for creating or updating individual houses builder insurance subscriptions.
 * @component
 * @param {Object} props - The component's properties.
 * @param {string} props.purpose - The purpose of the component, either "create" or "edit".
 * @returns {JSX.Element} The JSX element for creating or updating individual houses builder insurance subscriptions.
 */
export const ProMultiriskInsuranceSubCreateUpdate = ({ purpose }) => {
  const { company } = useAuthContext();
  const program = 'PROFESSIONAL_MULTIRISK_INSURANCE';
  const { t } = useTranslation();
  // const [form] = Form.useForm();

  // const mandatoryDocuments = ['lease_agreements'];

  // const draggerFilesKeysOverRide = [
  //   'lease_agreements',
  //   'Deed_of_acquisition',
  //   'plans',
  //   'sectional_plans',
  //   'external_plans_or_cadastral_parcel_designations',
  //   'plans_Documents_for_specific_installations',
  //   'condominium_status',
  //   'statutes_of_ASL_AFUL',
  //   'certificates_Q4_Q5_Q17_Q18_Q19_others',
  //   'security_surveillance_contracts',
  //   'KBIS',
  //   'presentation_model',
  //   'current_contract',
  //   'loss_history_report',
  //   'tax_return_package',
  //   'other'
  // ];

  // const initialState = {
  //   isProtectedAgainstTheft: false,
  //   isFireProtection: false,
  //   isOtherTypologyActivities: false
  // };

  // const formStateReducer = (state, action) => {
  //   switch (action.type) {
  //     case 'TOGGLE_STATE':
  //       return { ...state, [action.field]: action.payload };
  //     default:
  //       return state;
  //   }
  // };

  // const [state, dispatch] = useReducer(formStateReducer, initialState);

  const { fieldsInformations } = useFields();
  // const { fieldsInformations } = useFields(form, state, dispatch);

  const config = useConfig(company, program);

  return (
    <CreateUpdateContainer
      title={`${t(`subscriptions.form.${purpose}`)} - ${t(
        `subscriptions.form.${program}`
      )}`}
      // form={form}
      fields={fieldsInformations}
      purpose={purpose}
      tradKey="professional_multirisk_insurances"
      baseUrl="subscriptions"
      resource="subscriptions"
      config={config}
      // populate="?populate=documents.file"
      withFilesManager={false}
      // draggerFilesKeysOverRide={draggerFilesKeysOverRide}
      // mandatoryDocuments={mandatoryDocuments}
      messageOnSuccess
    />
  );
};

ProMultiriskInsuranceSubCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
