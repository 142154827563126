import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useFields } from './utils/fieldsConstruction';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { CreateUpdateContainer } from '../../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useHistory } from '../../../../utils/historyUtils';

/**
 * A component for creating or updating construction subscriptions.
 * @component
 * @param {object} props - The component's props.
 * @param {string} props.purpose - The purpose of the component (e.g., 'create' or 'update').
 * @returns {JSX.Element} The JSX element representing the create/update component.
 */
export const ConstructionOtherCreateUpdate = ({ purpose, subProgramme }) => {
  const { company } = useAuthContext();
  const { setInitialHistory, createHistory, updateHistory } = useHistory();
  const program = 'CONSTRUCTION';
  const { t } = useTranslation();
  const { fieldsInformations } = useFields();

  const draggerFilesKeysOverRide = [
    'subscription_form',
    'resume',
    'company_presentation',
    'informations_report_if_insured'
  ];

  const config = {
    onGetResource: {
      setFields: (data) => {
        setInitialHistory(data);
        return {
          ...data,
          desired_effective_date:
            data.desired_effective_date && dayjs(data.desired_effective_date)
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        company_id: company,
        programme_types: program,
        ...createHistory('program')
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        ...updateHistory('program')
      })
    }
  };
  return (
    <CreateUpdateContainer
      title={t(`constructions.form.edit.${subProgramme}`)}
      fields={fieldsInformations}
      purpose={purpose}
      baseUrl="constructions"
      resource="constructions"
      config={config}
      draggerFilesKeysOverRide={draggerFilesKeysOverRide}
    />
  );
};

ConstructionOtherCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired,
  subProgramme: PropTypes.string.isRequired
};
