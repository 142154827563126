import { useCallback } from 'react';

/**
 * Custom hook for fetching claim information.
 *
 * @param {Object} options - The options for fetching claim information.
 * @param {Function} options.setIsLoading - The function to set the loading state.
 * @param {Function} options.dispatchAPI - The function to dispatch API requests.
 * @param {string} options.id - The ID of the claim.
 * @param {Function} options.setClaimInformation - The function to set the claim information.
 * @param {Function} options.message - The function to display error messages.
 * @param {Object} options.isMounted - The object to check if the component is mounted.
 * @param {Function} options.setStatusCallback - The function to set the status callback.
 * @param {string} options.claimAPI - The API endpoint for claims.
 * @param {Function} options.setIsCompliant - The function to set the compliance status.
 * @param {Function} options.setAccountingBalanced - The function to set the accounting balance status.
 * @param {Function} options.setFeesAppealsBalanced - The function to set the fees and appeals balance status.
 * @returns {Function} - The memoized callback function for fetching claim information.
 */
export const useFetchClaimInformation = ({
  setIsLoading,
  dispatchAPI,
  id,
  setClaimInformation,
  message,
  isMounted,
  setStatusCallback,
  claimAPI,
  populateClaimShow,
  initialData
}) =>
  useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${claimAPI}/${id}?populate=${populateClaimShow}`
      });
      if (isMounted.current) {
        setClaimInformation({
          ...initialData(data)
        });
        setStatusCallback(data);
      }
    } catch (e) {
      if (isMounted.current) message(e);
    } finally {
      if (isMounted.current) setIsLoading(false);
    }
  }, [
    dispatchAPI,
    id,
    isMounted,
    message,
    setClaimInformation,
    setIsLoading,
    setStatusCallback
  ]);
