import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Form } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const { Option } = Select;
const { Item } = Form;

const requiresComplaintDocument = [
  'THEFT',
  'VANDALISM',
  'CYBER_FUND_DIVERSION',
  'RANSOMWARE',
  'DENIAL_OF_SERVICE',
  'PERSONAL_DATA_THEFT',
  'CYBER_ESPIONAGE',
  'IDENTITY_THEFT',
  'E_REPUTATION_DAMAGE'
];

/**
 * A custom select component for choosing values from a list of enums.
 * @component
 * @param {object} props - The component's props.
 * @param {string} props.dbKey - The key used to fetch the list of enums.
 * @param {object} props.enums - The list of enums.
 * @returns {JSX.Element} The JSX element representing the select component.
 */
export const SelectEnumsReducerClaimType = ({
  dbKey,
  isMultiple,
  tradKeyEnums,
  dispatch,
  type,
  url
}) => {
  const isMounted = useRef(false);

  const { t } = useTranslation();

  const [enums, setEnums] = useState([]);
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${url}/enums`
      });
      if (isMounted.current) setEnums(data.claimType);
    } catch (e) {
      if (isMounted.current) message(e.response.status);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    (async () => {
      await getEnums();
    })();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleChange = (value) => {
    let isComplaint = false;
    let isOther = false;
    if (requiresComplaintDocument.includes(value)) {
      isComplaint = true;
    }
    if (value === 'OTHER') {
      isOther = true;
    }
    if (!value === 'OTHER' && !requiresComplaintDocument.includes(value)) {
      isComplaint = false;
      isOther = false;
    }
    dispatch({
      type,
      field: 'isComplaint',
      payload: isComplaint
    });

    dispatch({
      type,
      field: 'isOther',
      payload: isOther
    });
  };

  if (isMultiple) {
    return (
      <Item name={dbKey}>
        {enums ? (
          <Select
            mode="multiple"
            showSearch
            filterOption={(inputValue, option) =>
              (option?.title ?? '')
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            }
            onChange={handleChange}
          >
            {enums.map((v) => {
              const title = tradKeyEnums && t(`${tradKeyEnums}.${v}`);
              return (
                <Option key={v} value={v} title={title}>
                  {title}
                </Option>
              );
            })}
          </Select>
        ) : null}
      </Item>
    );
  }
  return (
    <Item name={dbKey}>
      {enums ? (
        <Select onChange={handleChange}>
          {enums.map((v) => (
            <Option key={v} value={v}>
              {t(`${tradKeyEnums}.${v}`)}
            </Option>
          ))}
        </Select>
      ) : null}
    </Item>
  );
};

SelectEnumsReducerClaimType.propTypes = {
  dbKey: PropTypes.arrayOf(PropTypes.string).isRequired,
  isMultiple: PropTypes.bool,
  tradKeyEnums: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

SelectEnumsReducerClaimType.defaultProps = {
  isMultiple: false
};
