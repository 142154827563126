import PropTypes from 'prop-types';
import { useState, useEffect, useCallback, useRef } from 'react';
import { Select, Form } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const { Option } = Select;
const { Item } = Form;

export const MultiSelectEntityByContract = ({
  dbKey,
  isDisabled,
  contractId
}) => {
  const isMounted = useRef(false);
  const [entities, setEntities] = useState([]);
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const getEntities = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/entities/contract/${contractId}`
      });
      if (isMounted.current) {
        setEntities(data);
      }
    } catch (e) {
      if (e.response && isMounted.current) message(e);
    }
  }, [contractId]);

  useEffect(() => {
    isMounted.current = true;
    if (contractId) {
      getEntities();
    }
    return () => {
      isMounted.current = false;
    };
  }, [getEntities, contractId]);

  return (
    <Item name={[`${dbKey}`]}>
      <Select
        disabled={isDisabled}
        showSearch
        filterOption={(inputValue, option) =>
          (option?.title ?? '').toLowerCase().includes(inputValue.toLowerCase())
        }
        mode="multiple"
      >
        {entities &&
          entities.map((e) => (
            <Option key={e._id} value={e._id} title={e.name}>
              {e.name}
            </Option>
          ))}
      </Select>
    </Item>
  );
};

MultiSelectEntityByContract.propTypes = {
  dbKey: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  contractId: PropTypes.string
};

MultiSelectEntityByContract.defaultProps = {
  isDisabled: false,
  contractId: ''
};
