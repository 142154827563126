import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { routes } from '../../../../utils/constants/adminRoutes';

/**
 * Renders the Mailing Button for Expert Call component based on a render condition.
 * @component
 * @param {boolean} renderCondition - Whether to render the Mailing Button for Vehicles.
 * @param {string} pathname - Pathname for request.
 * @returns {?React.Component} The rendered Mailing Button for Vehicles component, or null if the render condition is false.
 */
const renderEditClaimButton = (renderCondition, pathname) => {
  const { t } = useTranslation();
  const { id } = useParams();

  if (renderCondition) {
    return (
      <Link
        to={{
          pathname: `${routes.CLAIMS}/${pathname}/edit/${id}`
        }}
      >
        <Button icon={<EditOutlined />}>{`${t('claims.form.edit')} `}</Button>
      </Link>
    );
  }
  return null;
};

export default renderEditClaimButton;
