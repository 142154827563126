// TODO: Waiting for feedback before deleting comments
import { DatePicker, Input, Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { SelectManager } from '../../../components/SelectManager';
import { SelectEnumsClaimsType } from '../../../components/SelectEnumsClaimsType';
/**
 * A hook for generating form fields configuration based on the provided parameters.
 *
 * @hook
 * @param {boolean} isOther - Indicates whether the "claim_description_if_other" field is visible.
 * @param {Function} setIsOther - A function to set the visibility of the "claim_description_if_other" field.
 * @returns {Object} An object containing an array of form fields configuration.
 */

const { Option } = Select;

export const useFields = (isOther, setIsOther) => {
  const { t } = useTranslation();
  const dateFormat = 'DD-MM-YYYY';

  const fieldsInformations = [
    {
      label: ['customer_manager'],
      name: ['customer_manager'],
      rules: [{ required: true }],
      input: <SelectManager dbKey="customer_manager" />
    },
    {
      label: ['unit_manager'],
      name: ['unit_manager'],
      input: <SelectManager dbKey="unit_manager" />
    },
    {
      label: ['insurance_company_reference'],
      name: ['insurance_company_reference']
    },
    {
      label: ['client_reference'],
      name: ['client_reference']
    },
    {
      label: ['sinister_date'],
      name: ['sinister_date'],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['claim_type'],
      name: ['claim_type'],
      rules: [{ required: true }],
      input: (
        <SelectEnumsClaimsType
          dbKey="claim_type"
          url="transport_claims"
          setIsOther={setIsOther}
        />
      )
    },
    {
      label: ['claim_type_if_other'],
      name: ['claim_type_if_other'],
      hidden: !isOther
    },
    // {
    //   label: ['goods_to_be_appraised'],
    //   name: ['goods_to_be_appraised'],
    //   rules: [{ required: true }],
    //   input: (
    //     <Group>
    //       {(enums?.goodToBeAppraised || []).map((option) => (
    //         <Radio key={option} value={option}>
    //           {t(`constructions.form.${option}`)}
    //         </Radio>
    //       ))}
    //     </Group>
    //   )
    // },
    {
      label: ['customer_email'],
      name: ['customer_email'],
      rules: [{ required: true, type: 'email' }]
    },
    {
      name: ['customer_phone_number'],
      label: 'customer_phone_number',
      rules: [{ required: true }],
      input: (
        <Input.Group>
          <Form.Item
            noStyle
            name={['customer_phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: 70 }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['customer_phone_number', 'number']}>
            <Input style={{ width: 'calc(100% - 70px)' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      label: ['pictures_damages_caused'],
      name: ['pictures_damages_caused'],
      input: 'File',
      maxFilesCount: 5,
      multipleFiles: true,
      startWithDivider: {
        title: t('claims.form.pictures')
      },
      endWithDivider: {
        title: t('claims.form.documents')
      }
    }
  ];

  return {
    fieldsInformations
  };
};
