import { Col, Modal, Row, Spin } from 'antd';

export const generateAttestationGarage = async (
  vehicleId,
  dispatchAPI,
  t,
  message
) => {
  const modal = Modal.info({
    content: (
      <Row style={{ flexDirection: 'column' }} justify="center" align="middle">
        <Col>
          <Spin />
        </Col>
        <Col>{t('files.modal.document-loading')}</Col>
      </Row>
    ),
    footer: null,
    maskClosable: false
  });
  try {
    const { data, headers } = await dispatchAPI('GET', {
      url: `/vehicle_claims/generateAttestation/${vehicleId}`,
      responseType: 'arraybuffer'
    });
    const blob = new Blob([data], {
      type: headers['content-type']
    });
    modal.destroy();
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = headers['content-disposition'];
    document.body.appendChild(a);

    a.click();

    window.URL.revokeObjectURL(url);
  } catch (e) {
    modal.destroy();
    message(e);
  }
};
