import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { useColumns } from './utils/columnsOtherVehicles';
import { ListResource } from '../../../../components/ListResource/ListResource';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { FilterByMissingDocuments } from '../../../components/FilterByMissingDocuments';
import { useActionColumn } from '../utils/actionButtons';
import { vehiclesExitDocuments } from '../utils/constants';

/**
 * `ListOtherVehicles` is a React component designed to list and manage light vehicles.
 * It provides filtering options by vehicle type, entity, missing documents and site.
 * The component fetches the data based on the filters applied and displays it in a tabular format.
 *
 * @component
 * @returns {ReactElement} Rendered `ListOtherVehicles` component.
 */
export const ListOtherVehicles = () => {
  const [isMissingDocuments, setIsMissingDocuments] = useState('');
  const [resetFilters, setResetFilters] = useState(false);
  const [showData, setShowData] = useState(true);
  const [edit, setEdit] = useState(true);
  const [reintegrate, setReintegrate] = useState(true);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [extraFilter, setExtraFilter] = useState('');
  const [isArchivatedPage, setArchivatedPage] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const currentPage = Number(params.get('p') || 1);
  const pageSize = Number(params.get('pS') || 10);

  const { company, verifPermissions, permissions } = useAuthContext();
  const { t } = useTranslation();

  let filterParameter = '';
  const resourceName = 'vehicles';
  const dbExtraQueryFilter = `company=${company}&fleet_type=OTHER_VEHICLE`;
  const populate = 'entity,site,principal_driver,contract,company';
  const baseUrl = 'other-vehicles';

  const [columns, loading] = useColumns(
    resourceName,
    dbExtraQueryFilter,
    populate
  );
  const actionColumn = useActionColumn(
    baseUrl,
    edit,
    isArchivatedPage,
    vehiclesExitDocuments
  );

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setShowData(
        !verifPermissions(
          'datas/other_vehicles',
          permissions?.notPermittedRoutes
        )
      );
      setEdit(
        !verifPermissions(
          '/programmes/other-vehicles/edit',
          permissions?.notPermittedRoutes
        )
      );
      setReintegrate(
        !verifPermissions(
          '/programmes/other-vehicles/reintegrate',
          permissions?.notPermittedRoutes
        )
      );
    }
  }, [permissions]);

  const onFilterMissingDocuments = (missingDocuments) => {
    setIsMissingDocuments(missingDocuments === false ? '' : missingDocuments);
  };

  useEffect(() => {
    if (resetFilters) {
      filterParameter = '';
      setResetFilters(false);
    }
    filterParameter = `missing_documents=${isMissingDocuments}__`;
    setExtraFilter(filterParameter);
    navigate({
      pathname: '/programmes/other-vehicles',
      search: `?&p=${currentPage}&pS=${pageSize}&f=${filterParameter}`
    });
  }, [navigate, isMissingDocuments, resetFilters]);

  useEffect(() => {
    if (!forceRefresh) {
      setForceRefresh(false);
    }
  }, [forceRefresh]);

  if (loading) return <Spin fullscreen="true" />;

  return (
    <ListResource
      extraQuery={dbExtraQueryFilter}
      resourceName={resourceName}
      exportUrl={`exports/company/${company}/fleet-type/OTHER_VEHICLE`}
      importUrl={`imports-vehicles/company/${company}/vehicle-type/other_vehicle_type/fleet-type/OTHER_VEHICLE`}
      tradKey="other_vehicle"
      exportTemplateUrl="export-templates/template/other-vehicle"
      columns={[...columns, ...actionColumn]}
      withExtraFilters={
        <FilterByMissingDocuments
          onChange={onFilterMissingDocuments}
          setResetFilters={setResetFilters}
        />
      }
      populate={populate}
      editAction={false}
      showAction={false}
      sheetName={t('other_vehicle.title')}
      headers={[]}
      withUploadButton={showData}
      permissionReintegrate={reintegrate}
      customDelete
      forceRefresh={forceRefresh}
      setForceRefresh={() => setForceRefresh()}
      extraFilter={extraFilter}
      setIsArchivatedPage={setArchivatedPage}
      customActionColumn
      scroll={{ x: 4800 }}
    />
  );
};
