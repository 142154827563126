import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useListContent } from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainerV3/CreateUpdateContainerContract';
import { useFields } from './utils/fieldsAttestations';
import { matchingDocument } from './utils/helperFunctions';

/**
 * `DocumentsCreateUpdate` is a React component for creating or updating documents.
 * The component utilizes the `CreateUpdateContainer` for rendering the input fields and managing form actions.
 *
 * @component
 * @returns {React.ElementType} A form for creating or updating a document.
 */
export const AttestationEdit = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI, company } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [document, setDocument] = useState();
  const [idWithoutParams, setIdWithoutParams] = useState(null);
  const listContent = useListContent(document);

  const mandatoryDocuments = ['attestation'];

  const { fields } = useFields(idWithoutParams);

  const config = {
    onGetResource: {
      setFields: (data) => {
        const matchingDoc = matchingDocument(data.documents, id);
        return {
          ...data,
          entity: matchingDoc[0].entity
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        company_Id: company,
        document_type: 'ATTESTATION'
      })
    }
  };

  const getDocument = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `contracts/documents/${id}?populate=entity,company_Id,documents`
      });
      setIdWithoutParams(data._id);
      setDocument(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getDocument();
    })();
  }, [getDocument]);

  return (
    <>
      <PageHeaderCustom title={t('documents.form.title.edit_attestation')} />
      <ContentCustom>
        <Row gutter={[0, 24]}>
          <Col xs={24} md={8} lg={6} xl={4} xxl={2}>
            <Skeleton loading={isLoading} title={0} paragraph={0} active />
          </Col>
          <Col xs={24} md={16} lg={18} xl={20} xxl={22}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <DescriptionList
                title={t('documents.form.reference_contract')}
                data={listContent}
                translate
                layout="vertical"
                column={4}
              />
            </Skeleton>
          </Col>
        </Row>
        <Row gutter={[0, 24]}>
          {idWithoutParams && (
            <CreateUpdateContainer
              fields={fields}
              resource="documents"
              urlFileKeys="contracts"
              baseUrl="contracts/documents"
              withFilesManager={false}
              purpose="edit"
              populate="?populate=documents.file"
              idWithoutParams={idWithoutParams}
              withPageHeaderCustom={false}
              config={config}
              mandatoryDocuments={mandatoryDocuments}
            />
          )}
        </Row>
      </ContentCustom>
    </>
  );
};
