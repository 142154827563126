import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  CheckCircleOutlined,
  FileExclamationOutlined
} from '@ant-design/icons';
import { getAndFormatSubProgrammes } from './utils/getAndFormatSubProgrammes';
import { RenderEntity } from './utils/RenderEntity';

/**
 * `useColumns` is a hook that returns an array of column configurations for rendering a table.
 * Each configuration specifies how a specific column of the table should be displayed.
 * It utilizes the i18next internationalization framework to translate table headers and certain
 * values. Dates in the table are formatted using the `dayjs` library.
 *
 * @hook
 * @returns {Array} An array containing configuration for each column of the table. The configuration includes
 * details such as the title of the column, key, dataIndex to fetch the data, rendering logic, and whether it is sortable.
 */

export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('documents.form.pending'),
      key: 'is_pending',
      dataIndex: 'is_pending',
      render: (is_pending) =>
        is_pending ? (
          <FileExclamationOutlined twoToneColor="#var(--warningColor)" />
        ) : null,
      sorter: true
    },
    {
      title: t('documents.form.type'),
      key: 'document_type',
      dataIndex: 'document_type',
      render: (document_type) => t(`documents.status.${document_type}`),
      sorter: true
    },
    {
      title: t('documents.form.contract_number'),
      key: 'contract_number',
      dataIndex: ['contract_number'],
      sorter: true
    },
    {
      title: t('documents.form.programmes_types'),
      key: 'programme_types',
      dataIndex: 'programme_types',
      render: (programme_types) => t(`documents.form.${programme_types}`),
      sorter: true
    },
    {
      title: t('documents.form.sub_programmes'),
      key: 'programme_types',
      render: (text, record) => getAndFormatSubProgrammes(record, t),
      sorter: true
    },
    {
      title: t('documents.form.entity'),
      key: 'entity',
      dataIndex: ['entity'],
      sorter: true,
      render: (entity) => RenderEntity(entity, t)
    },
    {
      title: t('documents.form.has_amendment'),
      key: 'has_amendment',
      dataIndex: ['has_amendment'],
      sorter: true,
      render: (has_amendment) => (has_amendment ? <CheckCircleOutlined /> : '-')
    },
    {
      title: t('documents.form.validity_start_date'),
      key: 'validity_start_date',
      dataIndex: 'validity_start_date',
      render: (validity_start_date) =>
        dayjs(validity_start_date).format('DD/MM/YYYY'),
      sorter: true
    },
    {
      title: t('documents.form.validity_end_date'),
      key: 'validity_end_date',
      dataIndex: 'validity_end_date',
      render: (validity_end_date) =>
        dayjs(validity_end_date).format('DD/MM/YYYY'),
      sorter: true
    },
    {
      title: t('documents.form.if_is_amendment_number'),
      key: 'reference_number_amendment',
      dataIndex: ['reference_number_amendment'],
      sorter: true,
      render: (reference_number_amendment) => reference_number_amendment || '-'
    },
    {
      title: t('documents.form.amendment_validity_start_date'),
      key: 'amendment_validity_start_date',
      dataIndex: 'amendment_validity_start_date',
      render: (amendment_validity_start_date) =>
        amendment_validity_start_date
          ? dayjs(amendment_validity_start_date).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t('documents.form.amended_reference'),
      key: 'previous_amendment',
      dataIndex: 'previous_amendment',
      render: (previous_amendment) =>
        previous_amendment?.reference_number_amendment ||
        previous_amendment?.contract_number ||
        '-',
      sorter: true
    }
  ];
};
