/**
 * Constants for the hardware insurance claim module.
 *
 * @typedef {Object} Constants
 * @property {string} productAPI - The API endpoint for hardware insurances.
 * @property {string} claimUrl - The URL for hardware insurance claims.
 * @property {string} claimAPI - The API endpoint for hardware insurance claims.
 * @property {string} resource - The resource name for hardware insurance claims.
 */

/**
 * Constants for the hardware insurance claim module.
 *
 * @type {Constants}
 */
export const constants = {
  productAPI: 'hardware-insurances',
  claimUrl: 'hardware-insurance-claim',
  claimAPI: 'hardware_insurance_claims',
  resourceClaim: 'Hardware_insurance_claim',
  populateProductShow:
    'customer_manager,unit_manager,contract,entity,site,company',
  populateClaimShow: 'entry_id,unit_manager,entity,company,customer_manager'
};
