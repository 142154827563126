import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, InputNumber } from 'antd';
import { useGetEnums } from '../../../../../utils/getEnums';

const { Option } = Select;

/**
 * Generates fields configuration for the claims page.
 *
 * @returns {Object} An object containing the fields configuration.
 */
export const useFieldsExcess = () => {
  const { t } = useTranslation();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [enums, setEnums] = useState({});
  const { getEnums } = useGetEnums();

  const fieldsExcess = [
    {
      label: ['amount'],
      name: ['amount'],
      rules: [{ required: true }],
      input: <InputNumber controls={false} />
    },
    {
      label: ['amount_or_percentage'],
      name: ['amount_or_percentage'],
      rules: [{ required: true }],
      input: (
        <Select>
          {(enums?.amountOrPercentage || []).map((option) => (
            <Option key={option} value={option}>
              {t(`claims.form.${option}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: ['wording'],
      name: ['wording'],
      rules: [{ required: false }]
    }
  ];
  const fetchEnums = async () => {
    const data = await getEnums('claim-accounts');
    setEnums(data);
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(false);
    await fetchEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fieldsExcess,
    isFieldsLoading
  };
};
