// TODO: Commented routes for staging will be placed in dev router
import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { outOfNavRoutes, routes } from '../utils/constants/adminRoutes';
import { Login } from '../routes/login';
import { NotificationDashboard } from '../routes/home/NotificationDashboard';
import { Exception } from '../components';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { Settings } from '../routes/settings/settings';
import { DocumentRouter } from '../routes/documents/DocumentRouter';
import { SubscriptionRouter } from '../routes/subscriptions/SubscriptionRouter';
import { UserRouter } from '../routes/users/UserRouter';
import { CompanyRouter } from '../routes/companies/CompanyRouter';
import { ContactsRouter } from '../routes/contacts/ContactsRouter';
import { ClaimsRouter } from '../routes/claims/ClaimsRouter';
import { ProgrammesRouter } from '../routes/programmes/ProgrammesRouter';
import { LegalNotice } from '../routes/legal-notice/LegalNotice';
import { AdminTheme } from '../assets/styles/Theme/AdminTheme';
import RequireAuth from './RequireAuth';
import { MailInBrowser } from '../routes/MailinBrowser/MailInBrowser';
import { ContributionsRouter } from '../routes/contributions/ContributionsRouter';
import { MessagesRouter } from '../routes/messages/MessagesRouter';
import { AgricultureCreateUpdate } from '../routes/agriculture/AgricultureCreateUpdate';
import InterruptionGolf from '../routes/interruption-golf/Interruption-golf';
import { ScrollToTop } from '../utils/scrollToTop/scrollToTop';
import { MailRouter } from '../routes/MailRouter/MailRouter';
// import { FleetDatasAnalysisRouter } from '../routes/FleetDatasAnalysis/FleetDatasAnalysisRouter';
// import { WorkflowRouter } from '../routes/workflows/WorkflowRouter';
// import { TemplateMailRouter } from '../routes/template-mails/TemplateMailRouter';
// import { TemplateDocRouter } from '../routes/template-docs/TemplateDocsRouter';

export const AdminRouter = () => {
  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'admin';
    }
  });

  return (
    <BrowserRouter>
      <ScrollToTop />
      <AdminTheme />
      <Routes>
        <Route
          path={`${outOfNavRoutes.AGRICULTURE}/*`}
          element={<AgricultureCreateUpdate purpose="create" />}
        />
        <Route
          path={`${outOfNavRoutes.INTERRUPTION_GOLF}/*`}
          element={<InterruptionGolf purpose="create" />}
        />
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.MAIL_IN_BROWSER}/:token`}
          component={MailInBrowser}
        />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route element={<RequireAuth />}>
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          <Route path={`${outOfNavRoutes.LEGAL}/*`} element={<LegalNotice />} />
          <Route path={outOfNavRoutes.SETTINGS} element={<Settings />} />
          <Route path={routes.HOME} element={<NotificationDashboard />} />
          <Route path={`${routes.USERS}/*`} element={<UserRouter />} />
          <Route
            path={`${routes.PROGRAMMES}/*`}
            element={<ProgrammesRouter />}
          />
          <Route path={`${routes.DOCUMENTS}/*`} element={<DocumentRouter />} />
          <Route
            path={`${routes.SUBSCRIPTIONS}/*`}
            element={<SubscriptionRouter />}
          />
          <Route path={`${routes.CLAIMS}/*`} element={<ClaimsRouter />} />
          <Route path={`${routes.COMPANIES}/*`} element={<CompanyRouter />} />
          <Route path={`${routes.CONTACTS}/*`} element={<ContactsRouter />} />
          <Route path={`${routes.MAILS}/*`} element={<MailRouter />} />
          <Route
            path={`${routes.CONTRIBUTIONS}/*`}
            element={<ContributionsRouter />}
          />
          <Route path={`${routes.MESSAGES}/*`} element={<MessagesRouter />} />
          {/* <Route
            path={`${routes.FLEETDATASANALYSIS}/*`}
            element={<FleetDatasAnalysisRouter />}
          /> */}
          {/* <Route path={`${routes.WORKFLOWS}/*`} element={<WorkflowRouter />} /> */}
          {/* <Route
            path={`${routes.TEMPLATE_MAILS}/*`}
            element={<TemplateMailRouter />}
          /> */}
          {/* <Route
            path={`${routes.TEMPLATE_DOCS}/*`}
            element={<TemplateDocRouter />}
          /> */}
          <Route path={`${routes.CONFIGURATIONS}`} element={<Settings />} />
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
