import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import { Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useAuthContext } from '../../../../contexts/AuthContext';

import { useFields } from './fieldsAdValorem';
import { AdValoremContext } from './AdValoremContext';
import { formatDatePickerDate } from '../../../../utils/formatDatePickerDate';
import { useHistory } from '../../../../utils/historyUtils';

/**
 * `AdValoremCreateUpdate` is a React component for creating or updating documents.
 *
 * Depending on the provided `purpose` prop, this component can be used to either create or edit a document.
 * The component utilizes the `CreateUpdateContainer` for rendering the input fields and managing form actions.
 *
 * Note: This component does not support managing files.
 *
 * @component
 * @param {object} props The props for the component.
 * @param {string} props.purpose Indicates the purpose of the form. It should be either 'create' or 'edit'.
 * @returns {React.ElementType} A form for creating or updating a document.
 */

export const AdValoremCreateUpdate = ({ purpose }) => {
  const { setCurrentEntityContext, setCurrentContract } =
    useContext(AdValoremContext);
  const [selectedType, setSelectedType] = useState();
  const [currentDates, setCurrentDates] = useState({ departure_date: null });
  const [form] = Form.useForm();
  const { setInitialHistory, createHistory, updateHistory } = useHistory();
  const { fields, isFieldsLoading, base64 } = useFields({
    setSelectedType,
    selectedType,
    currentDates,
    setCurrentDates,
    form
  });
  const { company, companyName } = useAuthContext();
  const { t } = useTranslation();
  const config = {
    onGetResource: {
      setFields: (data) => {
        if (data?.departure_date) {
          setCurrentDates({
            departure_date: formatDatePickerDate(data.departure_date)
          });
        }
        setCurrentContract(data.contract);
        setInitialHistory(data);
        return {
          ...data,
          date_added: data.created_at && dayjs(data.created_at),
          desired_effective_date:
            data.desired_effective_date && dayjs(data.desired_effective_date),
          departure_date: data.departure_date && dayjs(data.departure_date),
          estimated_delivery_date:
            data.estimated_delivery_date && dayjs(data.estimated_delivery_date)
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        company_Id: company,
        programmes_types: 'TRANSPORT',
        sub_programme: 'AD_VALOREM',
        ...createHistory('program')
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        ...(base64 ? { photo: base64 } : {}),
        ...updateHistory('program')
      })
    }
  };
  return (
    <CreateUpdateContainer
      setCurrentEntityContext={setCurrentEntityContext}
      fields={fields}
      parentForm={form}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      title={t('ad_valorem.title')}
      baseUrl="transports"
      resource="transports"
      config={config}
      extraTitle={<Row justify="center">{`${t('folder')} ${companyName}`}</Row>}
      withFilesManager={false}
    />
  );
};

AdValoremCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
