import { useCallback } from 'react';
import dayjs from 'dayjs';
import { toBoolean } from '../../components/utils/stateFormat';

/**
 * Custom hook that returns the configuration objects for getting and creating resources.
 *
 * @param {function} dispatch - The dispatch function from the Redux store.
 * @param {string} company - The company ID.
 * @param {string} program - The program type.
 * @param {string} subProgram - The sub-program type.
 * @returns {object} - The configuration objects for getting and creating resources.
 */

export const useConfig = (dispatch, company, program, subProgram) => {
  const onGetResource = {
    setFields: useCallback(
      (data) => {
        const builder = data?.individual_houses_builder;
        dispatch({
          type: 'SET_SIGNATURE_ID',
          field: 'signatureId',
          payload: builder?.signature
        });
        dispatch({
          type: 'TOGGLE_STATE',
          field: 'isAttic',
          payload: toBoolean(builder?.is_attic)
        });
        dispatch({
          type: 'TOGGLE_STATE',
          field: 'isAncillaryConstruction',
          payload: toBoolean(builder?.is_ancillary_construction)
        });
        dispatch({
          type: 'TOGGLE_STATE',
          field: 'isCarryOutWorks',
          payload: toBoolean(
            builder?.is_project_owner_carry_out_construction_works
          )
        });
        dispatch({
          type: 'TOGGLE_STATE',
          field: 'isRenewableEnergiesWorks',
          payload: toBoolean(builder?.is_renewable_energies_installations)
        });
        dispatch({
          type: 'TOGGLE_STATE',
          field: 'isPhotovoltaicPanels',
          payload: builder?.if_is_photovoltaic_panels_amount > 0
        });
        dispatch({
          type: 'TOGGLE_STATE',
          field: 'isGeotechnicalStudy',
          payload: toBoolean(builder?.is_geotechnical_study)
        });

        return {
          ...data,
          individual_houses_builder: {
            ...builder,
            construction_contract_signature_date:
              builder.construction_contract_signature_date &&
              dayjs(builder.construction_contract_signature_date),
            construction_start_date_declaration:
              builder.construction_start_date_declaration &&
              dayjs(builder.construction_start_date_declaration),
            construction_start_date:
              builder.construction_start_date &&
              dayjs(builder.construction_start_date),
            expected_construction_end_date:
              builder.expected_construction_end_date &&
              dayjs(builder.expected_construction_end_date),
            signed_on: builder.signed_on && dayjs(builder.signed_on)
          }
        };
      },
      [dispatch]
    )
  };
  const onCreateResource = {
    setBody: useCallback(
      (data) => ({
        ...data,
        company_id: company,
        programme_types: program,
        sub_programme: subProgram
      }),
      [company, program, subProgram]
    )
  };
  return {
    onGetResource,
    onCreateResource
  };
};
