import { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Dropdown, Flex, Input, Modal, Space, Switch } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ExportXlsxButton } from '../ExportXlsxButton/ExportXlsxButton';
import { ImportXlsxButton } from '../ImportXlsxButton/ImportXlsxButton';
import { ExportXlsxTemplateButton } from '../ExportXlsxTemplateButton/ExportXlsxTemplateButton';
import { Datatable } from '../DataTable/Datatable';
import { PageHeaderCustom } from '../PageHeader/PageHeader';
import { ContentCustom } from '../ContentCustom/ContentCustom';
import { AddIcon } from '../../utils/constants/customIcons';

const { Search } = Input;

/**
 * `ListResource` is a custom component for listing and managing resources in a table format.
 *
 * @component
 * @param {string} resourceName - The name of the resource being listed.
 * @param {string} [tradKey] - The translation key for the resource name.
 * @param {string} [dataToFetch] - The data to fetch for the resource.
 * @param {Array} columns - The columns configuration for the table.
 * @param {boolean} [customActionColumn] - Indicates if custom actions are included in the table.
 * @param {Array} [headers] - The headers configuration for exporting data.
 * @param {string} [extraQuery] - Additional query parameters for data fetching.
 * @param {ReactNode} [extraHeader] - Extra header elements.
 * @param {ReactNode} [extraButtons] - Extra buttons to be displayed.
 * @param {string} [exportUrl] - The URL for exporting data.
 * @param {boolean|Object} [withCreateButton] - Indicates if a create button is displayed. Can be an object to customize the button.
 * @param {boolean} [withUploadButton] - Indicates if an upload button is displayed.
 * @param {boolean} [withPageHeader] - Indicates if a page header is displayed.
 * @param {boolean} [withSearchBar] - Indicates if a search bar is displayed.
 * @param {boolean} [forceRefresh] - Indicates if the data should be forcefully refreshed.
 * @param {boolean|Object} [editAction] - Indicates if an edit action is available. Can be an object to customize the action.
 * @param {boolean|Object} [showAction] - Indicates if a show action is available. Can be an object to customize the action.
 * @param {boolean|Object} [duplicateAction] - Indicates if a duplicate action is available. Can be an object to customize the action.
 * @param {boolean|Object} [printAction] - Indicates if a print action is available. Can be an object to customize the action.
 * @param {boolean} [deleteAction] - Indicates if a delete action is available.
 * @param {boolean|Object} [onDoubleClickAction] - Indicates if a double-click action is available. Can be an object to customize the action.
 * @param {Object} [scroll] - The scroll configuration for the table.
 * @param {Object} [expandable] - The expandable configuration for the table.
 * @param {string} [path] - The path for the resource.
 * @param {string} [rowKey] - The key to use as a unique identifier for table rows.
 * @param {Function} [formatter] - A function to format the data.
 * @param {ReactNode} [withExtraPageHeader] - Extra page header elements.
 * @param {ReactNode} [withExtraFilters] - Extra filters to be displayed.
 * @param {Function} [setLoaded] - A function to set the loading state.
 * @param {boolean} [loaded] - Indicates if the data is loaded.
 * @param {string} [sheetName] - The name of the sheet for exporting data.
 * @param {string} [importUrl] - The URL for importing data.
 * @param {Function} [setIsArchivatedPage] - A function to set the archivated page state.
 * @returns {JSX.Element} The JSX element for listing and managing resources.
 */
export const ListResource = ({
  resourceName,
  tradKey,
  dataToFetch,
  columns,
  customActionColumn,
  headers,
  children,
  populate,
  extraQuery,
  extraHeader,
  extraButtons,
  exportUrl,
  withCreateButton,
  withUploadButton,
  withPageHeader,
  withSearchBar,
  forceRefresh,
  setForceRefresh,
  editAction,
  showAction,
  duplicateAction,
  printAction,
  deleteAction,
  onDoubleClickAction,
  scroll,
  expandable,
  path,
  rowKey,
  formatter,
  withExtraPageHeader,
  withExtraFilters,
  setLoaded,
  loaded,
  sheetName,
  importUrl,
  exportTemplateUrl,
  template,
  permissionReintegrate,
  noArchiveFilter,
  customDelete,
  setIsArchivatedPage,
  adValorem
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');
  const pageSize = params.get('pS');
  const currentFilters = params.get('f');
  const currentSorter = params.get('s');
  const [searchValue, setSearchValue] = useState(keyword);
  const [isArchived, setIsArchived] = useState(false);
  const [unarchive, setUnarchive] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const searchResource = (value) => {
    if (value) {
      navigate({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}&k=${value}`
      });
    } else {
      navigate({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}`
      });
    }
  };

  useEffect(() => {
    setSearchValue(null);
  }, [pathname]);

  useEffect(() => {
    if (keyword) setSearchValue(keyword);
    else setSearchValue(null);
  }, [keyword]);

  const menu = {
    items: [
      ...(headers
        ? [
            {
              key: 'export',
              label: (
                <ExportXlsxButton
                  dataName={template || resourceName}
                  headers={headers}
                  url={`/${exportUrl || resourceName}`}
                  fileName={`${resourceName}.csv`}
                  resourceName={resourceName}
                  exportUrl={exportUrl}
                  populate={populate}
                  extraQuery={extraQuery}
                  formatter={formatter}
                  sheetName={sheetName}
                  permissionReintegrate={permissionReintegrate}
                />
              )
            }
          ]
        : []),
      {
        key: 'import',
        label: (
          <ImportXlsxButton
            resourceName={resourceName}
            importUrl={importUrl}
            setForceRefresh={setForceRefresh}
            forceRefresh={forceRefresh}
          />
        )
      },
      {
        key: 'exportTemplate',
        label: (
          <ExportXlsxTemplateButton
            dataName={resourceName}
            url={`/${exportTemplateUrl || resourceName}`}
            sheetName={sheetName}
          />
        )
      }
    ]
  };
  return (
    <>
      {withPageHeader && (
        <PageHeaderCustom
          title={t(`${tradKey || resourceName}.title`)}
          extra={extraHeader}
        />
      )}
      <ContentCustom>
        {withExtraPageHeader}
        <Flex
          justify={withSearchBar ? 'space-between' : 'end'}
          gap="small"
          align="center"
          wrap="wrap"
        >
          {withSearchBar && (
            <Flex align="center" gap="small">
              <Search
                allowClear
                placeholder={t('placeholder.search')}
                defaultValue={searchValue}
                onSearch={(value) => searchResource(value)}
              />
              {withExtraFilters}
            </Flex>
          )}
          {!withCreateButton && headers && (
            <Dropdown menu={menu}>
              <Button type="link">
                <MenuOutlined
                  style={{ fontSize: 16, color: 'var(--textColor)' }}
                />
              </Button>
            </Dropdown>
          )}

          <Flex gap="small" align="center" wrap="wrap">
            {extraButtons}
            {withCreateButton && !noArchiveFilter && !adValorem && (
              <Flex gap="middle" align="center">
                <Switch
                  checkedChildren={t('buttons.over')}
                  unCheckedChildren={t('buttons.ongoing')}
                  onChange={() => {
                    if (setIsArchivatedPage) setIsArchivatedPage(!isArchived);
                    setIsArchived(!isArchived);
                    setUnarchive(!unarchive);
                  }}
                />
                <Link to={withCreateButton?.path || `${pathname}/create`}>
                  <Button type="primary">
                    {withCreateButton?.buttonText || `${t('buttons.create')}`}
                    &nbsp;
                    {withCreateButton?.buttonIcon || <AddIcon />}
                  </Button>
                </Link>
              </Flex>
            )}
            {adValorem && (
              <>
                <Button
                  type="primary"
                  onClick={() => {
                    setIsVisible(true);
                  }}
                >
                  {withCreateButton?.buttonText || `${t('buttons.create')}`}
                  &nbsp;
                  {withCreateButton?.buttonIcon || <AddIcon />}
                </Button>
                <Modal
                  footer={false}
                  open={isVisible}
                  onCancel={() => {
                    setIsVisible(false);
                  }}
                >
                  <Space direction="vertical">
                    {t('ad_valorem.actions.create_modal')}
                    <Space direction="horizontal">
                      <Link to={t('ad_valorem.links.AXA')}>
                        <Button value={1}>{t(`claims.form.yes`)}</Button>
                      </Link>
                      <Link to={withCreateButton?.path || `${pathname}/create`}>
                        <Button value={2}>{t(`claims.form.no`)}</Button>
                      </Link>
                    </Space>
                  </Space>
                </Modal>
              </>
            )}
            {withUploadButton && (
              <Dropdown menu={menu}>
                <Button type="link">
                  <MenuOutlined
                    style={{ fontSize: 16, color: 'var(--textColor)' }}
                  />
                </Button>
              </Dropdown>
            )}
          </Flex>
        </Flex>
        <Flex align="center" gap="small">
          {children}
        </Flex>
        <Datatable
          style={{ marginTop: 24 }}
          resourceName={resourceName}
          dataToFetch={dataToFetch}
          columns={columns}
          customActionColumn={customActionColumn}
          extraQuery={extraQuery}
          populate={populate}
          forceRefresh={forceRefresh}
          editAction={editAction}
          showAction={showAction}
          duplicateAction={duplicateAction}
          printAction={printAction}
          deleteAction={deleteAction}
          onDoubleClickAction={onDoubleClickAction}
          scroll={scroll || { x: 1200 }}
          expandable={expandable}
          path={path}
          rowKey={rowKey}
          setLoaded={setLoaded}
          loaded={loaded}
          isArchived={isArchived}
          unarchive={unarchive}
          customDelete={customDelete}
        />
      </ContentCustom>
    </>
  );
};

ListResource.propTypes = {
  resourceName: PropTypes.string.isRequired,
  tradKey: PropTypes.string,
  dataToFetch: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customActionColumn: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.shape({})),
  extraQuery: PropTypes.string,
  extraHeader: PropTypes.element,
  extraButtons: PropTypes.element,
  exportUrl: PropTypes.string,
  populate: PropTypes.string,
  withCreateButton: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      path: PropTypes.string,
      buttonText: PropTypes.string,
      buttonIcon: PropTypes.element
    })
  ]),
  withUploadButton: PropTypes.bool,
  withPageHeader: PropTypes.bool,
  withSearchBar: PropTypes.bool,
  forceRefresh: PropTypes.bool,
  editAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  duplicateAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  printAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  showAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  deleteAction: PropTypes.bool,
  onDoubleClickAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func
    })
  ]),
  scroll: PropTypes.shape({}),
  expandable: PropTypes.shape({}),
  path: PropTypes.string,
  rowKey: PropTypes.string,
  formatter: PropTypes.func,
  withExtraPageHeader: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.oneOf([null])
  ]),
  withExtraFilters: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.oneOf([null])
  ]),
  setLoaded: PropTypes.func,
  loaded: PropTypes.bool,
  sheetName: PropTypes.string,
  importUrl: PropTypes.string,
  exportTemplateUrl: PropTypes.string,
  template: PropTypes.string,
  permissionReintegrate: PropTypes.bool,
  noArchiveFilter: PropTypes.bool,
  customDelete: PropTypes.bool,
  setForceRefresh: PropTypes.func.isRequired,
  setIsArchivatedPage: PropTypes.func,
  adValorem: PropTypes.bool
};

ListResource.defaultProps = {
  tradKey: null,
  headers: null,
  extraQuery: null,
  extraHeader: null,
  extraButtons: null,
  exportUrl: null,
  populate: null,
  customActionColumn: false,
  withCreateButton: true,
  withUploadButton: true,
  withSearchBar: true,
  withPageHeader: true,
  dataToFetch: null,
  forceRefresh: null,
  editAction: true,
  showAction: true,
  duplicateAction: false,
  printAction: false,
  deleteAction: true,
  onDoubleClickAction: true,
  scroll: null,
  expandable: null,
  path: null,
  rowKey: '_id',
  formatter: null,
  withExtraFilters: null,
  withExtraPageHeader: null,
  setLoaded: null,
  loaded: null,
  sheetName: null,
  importUrl: null,
  exportTemplateUrl: null,
  template: null,
  permissionReintegrate: true,
  noArchiveFilter: false,
  customDelete: false,
  setIsArchivatedPage: null,
  adValorem: false
};
