// TODO: testing with usecallback to memoized the function and stop re-rendering so it can be declared as dependency
// import { useTranslation } from 'react-i18next';
// import { notification } from 'antd';

// export const useErrorMessage = () => {
//   const { t } = useTranslation();
//   return {
//     message: (
//       status = '404',
//       duration = 4.5,
//       key = undefined,
//       notificationType = 'error'
//     ) => {
//       const s = status?.response?.status || status;
//       notification[notificationType]({
//         message: t(`errors.message.${s}`),
//         key: key || 'network_feedback',
//         duration
//       });
//     }
//   };
// };
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';

export const useErrorMessage = () => {
  const { t } = useTranslation();

  const message = useCallback(
    (
      status = '404',
      duration = 4.5,
      key = undefined,
      notificationType = 'error'
    ) => {
      const s = status?.response?.status || status;
      notification[notificationType]({
        message: t(`errors.message.${s}`),
        key: key || 'network_feedback',
        duration
      });
    },
    [t]
  );

  return { message };
};
