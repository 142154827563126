import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useFields } from './utils/fieldsOtherTransport';
import { useAuthContext } from '../../../contexts/AuthContext';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useHistory } from '../../../utils/historyUtils';

/**
 * A component for creating or updating other transport types subscriptions.
 * @component
 * @param {object} props - The component's props.
 * @param {string} props.purpose - The purpose of the component (e.g., 'create' or 'update').
 * @returns {JSX.Element} The JSX element representing the create/update component.
 */
export const OtherTransportCreateUpdate = ({ purpose, subProgramme }) => {
  const { company } = useAuthContext();
  const program = 'CIVIL_LIABILITY';
  const { setInitialHistory, createHistory, updateHistory } = useHistory();
  const { t } = useTranslation();
  const { fieldsInformations } = useFields(subProgramme);

  const config = {
    onGetResource: {
      setFields: (data) => {
        setInitialHistory(data);
        return {
          ...data,
          desired_effective_date:
            data.desired_effective_date && dayjs(data.desired_effective_date)
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        company_id: company,
        programme_types: program,
        sub_programme: subProgramme,
        ...createHistory('program')
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        ...updateHistory('program')
      })
    }
  };
  return (
    <CreateUpdateContainer
      title={t(`transports.form.edit.${subProgramme}`)}
      fields={fieldsInformations}
      purpose={purpose}
      baseUrl="transports"
      resource="transports"
      config={config}
      messageOnSuccess
      withFilesManager={false}
    />
  );
};

OtherTransportCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired,
  subProgramme: PropTypes.string
};

OtherTransportCreateUpdate.defaultProps = {
  subProgramme: null
};
