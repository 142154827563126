/* eslint-disable consistent-return */
/* eslint-disable radix */
/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import { Button, Row, Col, Select, Form } from 'antd';
// import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import EmailConfirmation from './EmailConfirmation';
import EmailResetPwd from './EmailResetPwd';
import EmailFromTemplate from './EmailFromTemplate';
import { generateInitialFinalInputs } from '../../../../../utils/generateFinalInputs';
import { mapInputs } from '../../../utils';

const { Item } = Form;
const { Option } = Select;

const emailTypesEnum = [
  // { value: 'empty', text: 'Email par défaut' },
  { value: 'confirmation', text: 'Email de confirmation de compte' },
  { value: 'resetPwd', text: 'Email de réinitialisation de mot de passe' },
  { value: 'fromTemplate', text: 'Depuis un template SendInBlue' },
  {
    value: 'fromMailerMicroserviceTemplate',
    text: 'Depuis un template Mailer Microservice Strategin'
  }
];

const EmailGeneration = ({
  stepInputs,
  finalInputs,
  setFinalInputs,
  emailType,
  setEmailType,
  nextStep
}) => {
  const [confirmEmailFields, setConfirmEmailFields] = useState(
    (JSON.stringify(finalInputs) !== '{}' && finalInputs) ||
      generateInitialFinalInputs('emailConfirmation')
  );
  const [resetPwdEmailFields, setResetPwdEmailFields] = useState(
    (JSON.stringify(finalInputs) !== '{}' && finalInputs) ||
      generateInitialFinalInputs('emailResetPwd')
  );
  const [fromTemplateEmailFields, setFromTemplateEmailFields] = useState(
    (JSON.stringify(finalInputs) !== '{}' && finalInputs) ||
      generateInitialFinalInputs('emailFromTemplate')
  );

  const selectFormToShow = () => {
    switch (emailType) {
      case 'confirmation':
        return (
          <EmailConfirmation
            stepInputs={stepInputs}
            confirmEmailFields={confirmEmailFields}
            setConfirmEmailFields={setConfirmEmailFields}
          />
        );
      case 'resetPwd':
        return (
          <EmailResetPwd
            stepInputs={stepInputs}
            resetPwdEmailFields={resetPwdEmailFields}
            setResetPwdEmailFields={setResetPwdEmailFields}
          />
        );
      case 'fromTemplate':
      case 'fromMailerMicroserviceTemplate':
        return (
          <EmailFromTemplate
            stepInputs={stepInputs}
            fromTemplateEmailFields={fromTemplateEmailFields}
            setFromTemplateEmailFields={setFromTemplateEmailFields}
          />
        );
      default:
        break;
    }
  };

  const tagDrag = (event, ElmLabel, field) => {
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.dropEffect = 'move';
    const data = `@{${ElmLabel}|${field}}`;
    event.dataTransfer.setData('text', data);
  };

  const onEmailTypeChange = (value) => {
    switch (value) {
      case 'confirmation':
        setConfirmEmailFields(generateInitialFinalInputs('emailConfirmation'));
        break;
      case 'resetPwd':
        setResetPwdEmailFields(generateInitialFinalInputs('emailResetPwd'));
        break;
      case 'fromMailerMicroserviceTemplate':
      case 'fromTemplate':
        setFromTemplateEmailFields(
          generateInitialFinalInputs('emailFromTemplate')
        );
        break;
      default:
        break;
    }
    setEmailType(value);
  };

  const finishTransform = () => {
    switch (emailType) {
      case 'confirmation':
        setFinalInputs(confirmEmailFields);
        break;
      case 'resetPwd':
        setFinalInputs(resetPwdEmailFields);
        break;
      case 'fromTemplate':
      case 'fromMailerMicroserviceTemplate':
        setFinalInputs(fromTemplateEmailFields);
        break;
      default:
        break;
    }
    nextStep();
  };

  return (
    <Row>
      <Col span={24}>
        <Row style={{ marginBottom: 15 }}>
          {mapInputs(stepInputs, true, tagDrag)}
        </Row>
        <Row style={{ marginBottom: 15 }}>
          <Col span={12} offset={6}>
            <Item label="Type d'email">
              <Select
                showSearch
                value={emailType}
                onChange={onEmailTypeChange}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {emailTypesEnum.map((emailTypeEnum) => (
                  <Option value={emailTypeEnum.value} key={emailTypeEnum.value}>
                    {emailTypeEnum.text}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
        </Row>
        <Row style={{ marginBottom: 15 }}>{selectFormToShow()}</Row>
        <Button type="primary" block onClick={finishTransform}>
          Valider
        </Button>
      </Col>
    </Row>
  );
};

EmailGeneration.propTypes = {
  stepInputs: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  finalInputs: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  setFinalInputs: PropTypes.func.isRequired,
  emailType: PropTypes.string.isRequired,
  setEmailType: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired
};

export default EmailGeneration;
