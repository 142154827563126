/**
 * Renders the attestation buttons based on the provided conditions.
 *
 * @param {boolean} renderCondition - The condition to render the buttons.
 * @param {object} vehicleClaim - The vehicle claim object.
 * @param {object} vehicle - The vehicle object.
 * @param {string} targetUrl - The target URL.
 * @returns {JSX.Element|null} The rendered attestation buttons or null.
 */
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { MailingButtonVehicles } from '../../../programmes/VehiclesFleets/components/MailingButtonVehicles';
import { generateAttestationGarage } from '../GenerateAttestationGarage';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useHistory } from '../../../../utils/historyUtils';

const renderAttestationButtons = (
  renderCondition,
  vehicleClaim,
  vehicle,
  targetUrl
) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const { setHistoryRefresh } = useAuthContext();
  const { updateHistoryCustom } = useHistory();

  const handleGenerateAttestationGarage = async (idClaim) => {
    await generateAttestationGarage(idClaim, dispatchAPI, t, message);
    updateHistoryCustom(targetUrl, id, t('buttons.attestation_downloaded'));
    setHistoryRefresh((prev) => !prev);
  };

  if (renderCondition) {
    return (
      <>
        <Button
          icon={<SyncOutlined />}
          onClick={() => handleGenerateAttestationGarage(vehicleClaim?._id)}
        >
          {`${t('claims.form.generate_take_charge_attestation')} `}
        </Button>
        <MailingButtonVehicles
          asset={vehicle}
          templateName="send_attestation_vehicules"
          tradKey="sending_attestation"
          targetUrl={targetUrl}
        />
      </>
    );
  }
  return null;
};
export default renderAttestationButtons;
