// TODO: awaitin microservice to be able to send attestation with commented componentw
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton, Row, Col, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import {
  useListContent,
  useListContentAddresses
} from './utils/listContentGolfs';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../components';
import { Photos } from '../../components/Photos';
import { Documents } from '../../components/Documents';
import { History } from '../../components/History';
import { useExtraButtons } from './extraButtons';

/**
 * GolfShow component.
 * Renders detailed information about a specific golf course, including its documents.
 * Provides options to edit or delete the golf course documents.
 *
 * @component
 * @returns {JSX.Element} GolfShow
 */
export const GolfShow = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [golf, setgolf] = useState();
  const listContent = useListContent(golf);
  const [isDeleted, setIsDeleted] = useState(false);
  const listContentAddresses = useListContentAddresses(golf);
  const { extraButtons } = useExtraButtons(isDeleted, golf);
  const draggerFilesKeysOverRide = [
    'lease_agreements',
    'Deed_of_acquisition',
    'plans',
    'sectional_plans',
    'external_plans_or_cadastral_parcel_designations',
    'plans_Documents_for_specific_installations',
    'certificates_Q4_Q5_Q17_Q18_Q19',
    'security_surveillance_contracts',
    'KBIS',
    'presentation_model',
    'current_contract',
    'loss_history_report',
    'tax_return_package',
    'other'
  ];

  const getgolf = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/golfs/${id}?populate=customer_manager,unit_manager,entity,site,contract,company`
      });
      setgolf(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getgolf();
    })();
  }, [getgolf]);

  useEffect(() => {
    if (golf) setIsDeleted(golf?.deleted);
  }, [golf]);

  // const deletegolf = async () => {
  //   try {
  //     await dispatchAPI('DELETE', { url: `/golfs/${id}` });
  //     navigate(-1);
  //   } catch (e) {
  //     if (e.response) message(e.response.status);
  //   }
  // };

  return (
    <>
      <PageHeaderCustom
        title={`${t('number')} ${golf?.unit_counter || 'xxxx'} - ${
          golf?.golf_name || ''
        } ${golf?.city || ''}`}
        extra={extraButtons}
      />
      <ContentCustom>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={12}>
            <Skeleton loading={isLoading} title={0} paragraph={0} active />
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <Row gutter={[0, 16]}>
                <Card>
                  <DescriptionList
                    data={listContent}
                    translate
                    layout="vertical"
                    title={t('golfs.form.main_informations')}
                  />
                </Card>
                <Card>
                  <DescriptionList
                    data={listContentAddresses}
                    translate
                    title={t('golfs.form.addresses')}
                  />
                </Card>
                <Card className="photo-card">
                  <Photos baseUrl="golfs" />
                </Card>
              </Row>
            </Skeleton>
          </Col>
          <Col xs={24} xl={12}>
            <Documents
              idWithoutParams={id}
              baseUrl="golfs"
              resource="Golf"
              draggerFilesKeysOverRide={draggerFilesKeysOverRide}
            />
            <Card title={t('subscriptions.form.history')} size="small">
              <History route="golfs" />
            </Card>
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};
