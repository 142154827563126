import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useFields } from '../Construction/ConstructionInsurance/fieldsConstructionInsurance';
import { useIHBUtils } from './utils/individualHousesBuilderUtils';

/**
 * IndividualHousesBuilderClaimCreateUpdate component.
 * Renders a form for creating or updating individual houses builder claims.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the form (e.g., 'create' or 'update').
 * @returns {JSX.Element} The rendered component.
 */
export const IndividualHousesBuilderClaimCreateUpdate = ({ purpose }) => {
  const { id } = useParams();
  const [constructionInsurance, setConstructionInsurance] = useState();
  const [isComplaint, setIsComplaint] = useState(false);
  const { fieldsInformations } = useFields(
    setIsComplaint,
    'individual_houses_builder_claims'
  );

  const { getConstructions, config } = useIHBUtils({
    construction: constructionInsurance
  });

  const mandatoryDocuments = isComplaint ? ['filing_complaint'] : [];

  useEffect(() => {
    (async () => {
      await getConstructions(setConstructionInsurance);
    })();
  }, [id]);

  return (
    <CreateUpdateContainer
      fields={fieldsInformations}
      purpose={purpose}
      baseUrl="individual_houses_builder_claims"
      resource="claims"
      config={config}
      populate="?populate=documents.file"
      mandatoryDocuments={mandatoryDocuments}
    />
  );
};

IndividualHousesBuilderClaimCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
