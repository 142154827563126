import { Route, Routes } from 'react-router-dom';
import { ListEventCancellationClaims } from './ListEventCancellationClaims';
import { EventCancellationClaimsCreateUpdate } from './EventCancellationClaimsCreateUpdate';
import { EventCancellationClaimsShow } from './EventCancellationClaimsShow';
import { Exception } from '../../../components';

/**
 * Provides routing for managing event cancellations, including create, edit, show, and list views.
 *
 * @component
 * @returns {React.ReactNode} Routes for different views related to event cancellations.
 */
export const EventCancellationClaimsRouter = () => (
  <Routes>
    <Route
      path="create/:id"
      element={<EventCancellationClaimsCreateUpdate purpose="create" />}
    />
    <Route
      path="edit/:id"
      element={<EventCancellationClaimsCreateUpdate purpose="edit" />}
    />
    <Route path="show/:id" element={<EventCancellationClaimsShow />} />
    <Route index element={<ListEventCancellationClaims />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
