import { useEffect, useState } from 'react';
import { Select, message, Spin, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import { MultiSelectEntity } from '../../../components/MultiSelectEntity';
import { useAuthContext } from '../../../../contexts/AuthContext';

/**
 * @hook
 * Custom hook for managing fields information related to Legal Protection Insurance.
 * @returns {Object} An object containing the fields information.
 */
export const useFields = () => {
  const [enums, setEnums] = useState({
    businessDomain: [],
    booleanChoices: []
  });
  const [isloading, setIsLoading] = useState(true);
  const { dispatchAPI } = useAuthContext();

  const { t } = useTranslation();

  const getEnums = async () => {
    try {
      setIsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: '/legal-protection-insurances/enums'
      });
      setEnums(data);
      setIsLoading(false);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    getEnums();
  }, []);

  const createOptionObj = (optArray) =>
    optArray.map((opt) => ({
      label: t(`legal_protection_insurance.enums.${opt}`),
      value: opt
    }));

  const activityOptionsObj = createOptionObj(enums?.businessDomain);
  const yesNoOptionsObj = createOptionObj(enums?.booleanChoices);

  const fieldsInformations = [
    {
      label: ['entity'],
      name: ['entity'],
      rules: [{ required: true }],
      input: <MultiSelectEntity dbKey="entity" />
    },
    {
      label: ['site_name'],
      name: ['site_name']
    },
    {
      label: ['site_address'],
      name: ['site_address'],
      rules: [{ required: true }]
    },
    {
      label: ['postal_code'],
      name: ['postal_code'],
      rules: [{ required: true }]
    },
    {
      label: ['city'],
      name: ['city'],
      rules: [{ required: true }]
    },
    {
      label: ['global_company_turnover'],
      name: ['global_company_turnover'],
      rules: [{ required: true }],
      input: <InputNumber addonAfter="€" />
    },
    {
      label: ['employees_count'],
      name: ['employees_count'],
      rules: [{ required: true }],
      input: <InputNumber />
    },
    {
      label: ['activity'],
      name: ['activity'],
      rules: [{ required: true }],
      input: isloading ? <Spin /> : <Select options={activityOptionsObj} />
    },
    {
      label: ['legal_procedure_in_progress'],
      name: ['legal_procedure_in_progress'],
      rules: [{ required: true }],
      input: isloading ? <Spin /> : <Select options={yesNoOptionsObj} />
    },
    {
      label: ['desire_options_litigies'],
      name: ['desire_options_litigies'],
      rules: [{ required: true }],
      input: isloading ? <Spin /> : <Select options={yesNoOptionsObj} />
    },
    {
      label: ['double_plafonds_if_sinister'],
      name: ['double_plafonds_if_sinister'],
      rules: [{ required: true }],
      input: isloading ? <Spin /> : <Select options={yesNoOptionsObj} />
    }
  ];

  return { fieldsInformations };
};
