import { Checkbox } from 'antd';

/**
 * @hook
 * Custom hook that returns environment fields for insurance settings.
 * @returns {Object} An object containing environment fields.
 */
export const useEnvironmentFields = () => {
  const fieldNames = [
    'company_name',
    'contract_number',
    'date_added',
    'entity',
    'address',
    'postal_code',
    'city',
    'company_registration_number',
    'business_domain',
    'territoriality_of_activity',
    'global_yearly_turnover',
    'number_of_sites_to_cover'
  ];

  const environmentFields = fieldNames.map((name) => ({
    name: [name],
    rules: [{ required: false }],
    valuePropName: 'checked',
    input: <Checkbox />
  }));

  return { environmentFields };
};
