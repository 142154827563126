import { useTranslation } from 'react-i18next';

export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t(`claims.form.amounts`),
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => amount?.toFixed(2) || '-'
    },
    {
      title: t(`claims.form.incl_or_excl_tax`),
      dataIndex: 'incl_or_excl_tax',
      key: 'incl_or_excl_tax',
      render: (incl_or_excl_tax) =>
        incl_or_excl_tax ? t(`claims.form.${incl_or_excl_tax}`) : '-'
    },
    {
      title: t(`claims.form.lodged_or_against_appeal`),
      dataIndex: 'lodged_or_against_appeal',
      key: 'lodged_or_against_appeal',
      render: (lodged_or_against_appeal) =>
        lodged_or_against_appeal
          ? t(`claims.form.${lodged_or_against_appeal}`)
          : '-'
    },
    {
      title: t(`claims.form.wording`),
      dataIndex: 'wording',
      key: 'wording'
    }
  ];
};
