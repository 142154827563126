import { Radio, Button, Col, Row } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

/**
 * Custom hook for handling fields related to interruption golf form.
 * @hook
 * @param {Object} selectedOptions - The selected options for the form.
 * @param {Function} setSelectedOptions - The function to set the selected options.
 * @param {Object} form - The form object.
 * @returns {Object} - An object containing the fields and ifDeathFields.
 */
const useIntGolfFields = (selectedOptions, setSelectedOptions, form) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const reasonOptions = [
    'mutation_proffesionelle',
    'complication_grossesse',
    'licenciement',
    'maladie_accident',
    'divorce_separation',
    'death'
  ];

  const stoppage_duration_options = ['more_3_months', 'less_3_months'];

  const handleReasonChange = (e) => {
    setSelectedOptions({ reason: e.target.value });
    if (e.target.value !== 'maladie_accident') {
      form.setFieldValue('stoppage_duration', null);
    }
  };

  const handleDurationChange = (e) => {
    setSelectedOptions({
      ...selectedOptions,
      stoppage_duration: e.target.value
    });
  };

  const requestAttestation = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/interruption-golfs`,
        responseType: 'arraybuffer'
      });

      const blob = new Blob([data], {
        type: 'application/pdf'
      });

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = 'ATTESTATION_GOLF_A_REMPLIR.pdf';
      document.body.appendChild(a);

      a.click();

      window.URL.revokeObjectURL(url);
    } catch (e) {
      message(e);
    }
  };

  const fields = [
    {
      label: 'document_to_fill',
      input: (
        <Button
          className="mobile-button"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          type="primary"
          onClick={requestAttestation}
        >
          <DownloadOutlined />
          {t(`interruption_golf.form.attestation_golf_download`)}
        </Button>
      ),
      startWithDivider: {
        title: t('interruption_golf.form.divider_1')
      },
      endWithDivider: {
        style: { opacity: 0 }
      }
    },
    { name: 'last_name', label: 'last_name', rules: [{ required: true }] },
    { name: 'first_name', label: 'first_name', rules: [{ required: true }] },
    {
      name: 'email',
      label: 'email',
      rules: [{ required: true }, { type: 'email' }]
    },
    {
      name: 'phone_number',
      label: 'phone_number',
      rules: [{ required: true }]
    },
    { name: 'address', label: 'address', rules: [{ required: true }] },
    { name: 'code_postal', label: 'code_postal', rules: [{ required: true }] },
    { name: 'city', label: 'city', rules: [{ required: true }] },
    { name: 'country', label: 'country', rules: [{ required: true }] },
    { name: 'golf_name', label: 'golf_name', rules: [{ required: true }] },
    {
      name: 'interruption_reason',
      label: 'interruption_reason',
      rules: [{ required: true }],
      input: (
        <Radio.Group onChange={handleReasonChange}>
          <Row gutter={[16, 16]}>
            {reasonOptions.map((option) => (
              <Col key={option} span={12}>
                <Row align="middle">
                  <Col span={20}>
                    <Radio value={option}>
                      {t(
                        `interruption_golf.form.interruption_reasons.${option}`
                      )}
                    </Radio>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </Radio.Group>
      )
    }
  ];

  const ifDeathFields = [
    {
      name: 'last_name_death',
      label: 'last_name',
      rules: [{ required: true }],
      startWithDivider: {
        title: t('interruption_golf.form.divider_death')
      }
    },
    {
      name: 'first_name_death',
      rules: [{ required: true }],
      label: 'first_name'
    },
    {
      name: 'postal_code_death',
      rules: [{ required: true }],
      label: 'code_postal'
    },
    { name: 'city_death', rules: [{ required: true }], label: 'city' },
    { name: 'country_death', rules: [{ required: true }], label: 'country' },
    {
      name: 'phone_number_death',
      rules: [{ required: true }],
      label: 'phone_number'
    },
    {
      name: 'lien_de_parente_death',
      rules: [{ required: true }],
      label: 'lien_de_parente'
    },
    {
      name: 'address_notaire_death',
      rules: [{ required: true }],
      label: 'address_notaire'
    }
  ];

  const ifMaladieOrAccidentFields = [
    {
      name: 'stoppage_duration',
      label: 'stoppage_duration',
      rules: [{ required: true }],
      input: (
        <Radio.Group onChange={handleDurationChange}>
          <Row gutter={[16, 16]}>
            {stoppage_duration_options.map((option) => (
              <Col key={option} span={12}>
                <Row align="middle">
                  <Col span={20}>
                    <Radio value={option}>
                      {t(
                        `interruption_golf.form.stoppage_duration_options.${option}`
                      )}
                    </Radio>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </Radio.Group>
      )
    }
  ];

  if (selectedOptions?.reason === 'death') {
    fields.push(...ifDeathFields);
  }

  if (selectedOptions?.reason === 'maladie_accident') {
    fields.push(...ifMaladieOrAccidentFields);
  }

  return { fields, ifDeathFields };
};

export default useIntGolfFields;
