import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFields } from './fieldsConstructionInsurance';
import { CreateUpdateContainer } from '../../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useConstructionUtils } from '../ConstructionUtils';

/**
 * Provides a component for creating or updating Construction insurance claims.
 *
 * @component
 * @param {object} props - React component props.
 * @param {string} props.purpose - The purpose of the component, either "create" or "edit".
 * @returns {React.ReactNode} The CreateUpdateContainer component with specific configurations for Construction insurance claims.
 */
export const ConstructionInsuranceCreateUpdate = ({ purpose }) => {
  const { id } = useParams();
  const [constructionInsurance, setConstructionInsurance] = useState();
  const [subProgramme, setSubProgramme] = useState('CONSTRUCTION_INSURANCE');
  const [isComplaint, setIsComplaint] = useState(false);
  const { fieldsInformations } = useFields(
    setIsComplaint,
    'construction_claims'
  );

  const { draggerFilesKeysOverRide, getConstructions, config } =
    useConstructionUtils({
      construction: constructionInsurance,
      subProgramme
    });

  const mandatoryDocuments = isComplaint ? ['filing_complaint'] : [];

  useEffect(() => {
    (async () => {
      await getConstructions(setConstructionInsurance, setSubProgramme);
    })();
  }, [id]);

  return (
    <CreateUpdateContainer
      fields={fieldsInformations}
      purpose={purpose}
      baseUrl="construction_claims"
      resource="claims"
      config={config}
      populate="?populate=documents.file"
      draggerFilesKeysOverRide={draggerFilesKeysOverRide}
      mandatoryDocuments={mandatoryDocuments}
    />
  );
};

ConstructionInsuranceCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
