import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Input, InputNumber, Radio, Row, Select } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fieldsUtils } from './fieldUtils';

/**
 * Custom hook that provides culture fields and guarantee fields for agriculture form.
 *
 * @returns {Object} An object containing cultureFields and guaranteeFields.
 */
export const useCultureFields = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const {
    cultureTypeOptions,
    desired_guarantee_options,
    existing_guarantee_options,
    salesLabel,
    desired_guarantee_options_Modal
  } = fieldsUtils(visible, setVisible);

  const cultureFields = [
    {
      name: ['culture_type'],
      rules: [{ required: true }],
      input: (
        <Select>
          {cultureTypeOptions.map((option) => (
            <Select.Option key={option} value={option}>
              {t(`agriculture.form.culture_type_options.${option}`)}
            </Select.Option>
          ))}
        </Select>
      )
    },
    {
      name: ['culture'],
      rules: [{ required: true }],
      input: <Input />
    },
    {
      name: ['surface'],
      rules: [{ required: true }],
      input: <InputNumber step={0} controls={false} addonAfter="Ha" />
    },
    {
      name: ['average_yield'],
      rules: [{ required: true }],
      input: <InputNumber step={0} controls={false} addonAfter="t/Ha" />
    },
    {
      name: ['average_sales_price'],
      rules: [{ required: true }],
      label: salesLabel,
      input: <InputNumber step={0} controls={false} addonAfter="€/t" />
    },
    {
      name: ['capital_to_cover'],
      rules: [{ required: true }],
      input: <InputNumber width="100%" disabled defaultValue={0} />
    },
    {
      name: ['desired_guarantee'],
      rules: [{ required: true }],
      input: (
        <Radio.Group>
          <Row gutter={[16, 16]}>
            {desired_guarantee_options.map((option) => (
              <Col key={option.value} span={12}>
                <Row align="middle">
                  <Col span={20}>
                    <Radio value={option.value}>{option.label}</Radio>
                  </Col>
                  <Col span={4}>
                    {option.value === 'multirisque' && (
                      <>
                        <Button
                          onClick={() => setVisible(option.value)}
                          type="none"
                          onCancel
                        >
                          <InfoCircleOutlined />
                        </Button>
                        {desired_guarantee_options_Modal(option)}
                      </>
                    )}
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </Radio.Group>
      )
    },
    {
      name: ['existing_guarantee'],
      rules: [{ required: true }],
      input: (
        <Radio.Group>
          {existing_guarantee_options.map((option) => (
            <Radio key={option.value} value={option.value}>
              {option.label}
            </Radio>
          ))}
        </Radio.Group>
      )
    }
  ];

  const guaranteeFields = [
    {
      name: ['garantie_from_whom'],
      rules: [{ required: true }]
    },
    {
      name: ['precise_garantie_existant'],
      rules: [{ required: true }]
    }
  ];

  return { cultureFields, guaranteeFields };
};
