import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

/**
 * `useListContent` is a custom React hook designed to format and prepare data for listing within a component.
 *
 * @hook
 * @param {Object} data - The data object containing details of the civil liability instance.
 * @property {string} data.contract_number - The contract number.
 * @property {Object} data.contract - The contract object.
 * @property {Date} data.created_at - The creation date of the contract.
 * @property {Object} data.customer_manager - The manager responsible for the customer.
 * @property {Object} data.unit_manager - The manager responsible for the unit.
 * @property {Date} data.desired_effective_date - The desired effective date of the contract.
 * @property {Date} data.validity_end_date - The validity end date of the contract.
 * @property {string} data.APE_code - The APE code associated with the contract.
 * @property {number} data.previous_year_revenue - The revenue of the previous year.
 * @property {string} data.eighteen_months_span_statistic_number_of_employees - Number of employees statistic spanning eighteen months.
 * @property {string} data.description_of_the_activity - Description of the related activity.
 *
 * @returns {Array} Returns an array of objects, where each object corresponds to a label and its associated content. This makes it easier to render lists within a component.
 *
 */

export const useListContentCyberInsurance = (data = {}) => {
  const {
    contract,
    customer_manager,
    unit_manager,
    desired_effective_date,
    entity,
    site,
    company_name,
    company_registration_number,
    activity_classification_number,
    internet_site_or_domain_name,
    subscriber_adress,
    business_domain,
    total_workforce,
    last_yearly_turnover,
    financial_year_closure_date,
    is_subsidiary,
    is_located_in_france_andorra_monaco_eu,
    if_not_subsidiary_number_france_andorra_monaco_eu_world,
    if_not_turnover_breakdown,
    main_customers_description,
    internet_services_turnover_part,
    french_turnover_percentage,
    european_turnover_percentage,
    canada_us_turnover_percentage,
    global_gross_margin_percentage,
    workstation_number,
    server_number,
    personal_data_server_number,
    bank_details_server_number,
    health_data_server_number,
    deleted,
    deletion_date,
    is_company_yearly_sensitize_cyber_risks,
    is_plan_to_restart_after_damage,
    if_yes_is_tested_yearly,
    if_yes_activity_restart_delay,
    is_security_policy_applied_evenly_across_subsidiaries,
    is_outside_backup,
    if_yes_frequency,
    is_activity_restart_delay_tested_twice_a_year,
    is_connection_to_si_vpn_secured,
    if_not_protocols_description,
    is_network_access_control_enabled,
    is_double_authentication_applied_for,
    is_password_manager_required,
    is_infrastructure_cloud_hosted,
    if_cloud_hosted_is_totally_hosted,
    if_cloud_hosted_cloud_providers,
    if_cloud_hosted_cloud_providers_other,
    is_microsoft_active_directory_used,
    if_microsoft_active_directory_user_accounts_quantity,
    if_microsoft_active_directory_service_accounts_quantity,
    is_admin_right_limited_and_admin_session_segregated,
    is_access_permissions_enabled,
    is_collaborator_admin_of_own_workspace,
    is_mobile_workspace_hashed,
    is_boyd_prohibited,
    is_network_segmentation_enabled,
    is_security_update_apply_as_soon_as_released,
    if_applied_average_delay_for_high_criticality,
    is_os_and_software_updated_and_supported_by_vendor,
    if_not_specify_safety_measures,
    is_pentest_audit_conducted_yearly,
    is_antivirus_installed_and_updated_on_each_workspace,
    is_antivirus_installed_and_updated_on_each_server,
    is_email_filtered,
    is_security_events_logs_collection_and_handling_tool,
    if_security_events_logs_collection_and_handling_tool_specify,
    is_soc,
    is_double_signature_validation_required_for_payments,
    no_claims_during_last_three_years,
    other_security_posture_informations,
    other_infrastructure_informations,
    signee
  } = data;

  const { t } = useTranslation();

  const customerManagerName = customer_manager
    ? `${customer_manager.first_name} ${customer_manager.last_name}`
    : '-';
  const unit_managerName = unit_manager
    ? `${unit_manager.first_name} ${unit_manager.last_name}`
    : '-';

  const labels = [
    {
      label: 'cyber_insurances.form.contract_number',
      span: 1,
      content: (contract?.contract_number && contract?.contract_number) || '-'
    },
    {
      label: 'cyber_insurances.form.customer_manager',
      span: 1,
      content: customerManagerName
    },
    {
      label: 'cyber_insurances.form.unit_manager',
      span: 1,
      content: unit_managerName
    },
    {
      label: 'cyber_insurances.form.desired_effective_date',
      span: 1,
      content:
        (desired_effective_date &&
          dayjs(desired_effective_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.entity',
      span: 1,
      content: (entity && entity.map((e) => e).join(', ')) || '-'
    },
    {
      label: 'cyber_insurances.form.site',
      span: 1,
      content: (site && site) || '-'
    },
    {
      label: 'cyber_insurances.form.company_name',
      span: 1,
      content: (company_name && company_name) || '-'
    },
    {
      label: 'cyber_insurances.form.company_registration_number',
      span: 1,
      content:
        (company_registration_number && company_registration_number) || '-'
    },
    {
      label: 'cyber_insurances.form.activity_classification_number',
      span: 1,
      content:
        (activity_classification_number && activity_classification_number) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.subscriber_adress',
      span: 1,
      content: (subscriber_adress && subscriber_adress) || '-'
    },
    {
      label: 'cyber_insurances.form.internet_site_or_domain_name',
      span: 1,
      content:
        (internet_site_or_domain_name && internet_site_or_domain_name) || '-'
    },
    {
      label: 'cyber_insurances.form.business_domain',
      span: 1,
      content:
        (business_domain &&
          t(`cyber_insurances.form.enums.${business_domain}`)) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.total_workforce',
      span: 1,
      content: (total_workforce && total_workforce) || '-'
    },
    {
      label: 'cyber_insurances.form.last_yearly_turnover',
      span: 1,
      content: (last_yearly_turnover && last_yearly_turnover) || '-'
    },
    {
      label: 'cyber_insurances.form.financial_year_closure_date',
      span: 1,
      content:
        (financial_year_closure_date &&
          dayjs(financial_year_closure_date).format('DD/MM')) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.is_subsidiary',
      span: 3,
      content:
        (is_subsidiary && t(`cyber_insurances.form.enums.${is_subsidiary}`)) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.is_located_in_france_andorra_monaco_eu',
      span: 3,
      content:
        (is_located_in_france_andorra_monaco_eu &&
          t(
            `cyber_insurances.form.enums.${is_located_in_france_andorra_monaco_eu}`
          )) ||
        '-'
    },
    {
      label:
        'cyber_insurances.form.if_not_subsidiary_number_france_andorra_monaco_eu_world',
      span: 3,
      content:
        (if_not_subsidiary_number_france_andorra_monaco_eu_world &&
          if_not_subsidiary_number_france_andorra_monaco_eu_world) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.if_not_turnover_breakdown',
      span: 3,
      content: (if_not_turnover_breakdown && if_not_turnover_breakdown) || '-'
    },
    {
      label: 'cyber_insurances.form.main_customers_description',
      span: 3,
      content: (main_customers_description && main_customers_description) || '-'
    },
    {
      label: 'cyber_insurances.form.internet_services_turnover_part',
      span: 3,
      content:
        (internet_services_turnover_part &&
          t(
            `cyber_insurances.form.enums.${internet_services_turnover_part}`
          )) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.french_turnover_percentage',
      span: 3,
      content: (french_turnover_percentage && french_turnover_percentage) || '-'
    },
    {
      label: 'cyber_insurances.form.european_turnover_percentage',
      span: 3,
      content:
        (european_turnover_percentage && european_turnover_percentage) || '-'
    },
    {
      label: 'cyber_insurances.form.canada_us_turnover_percentage',
      span: 3,
      content:
        (canada_us_turnover_percentage && canada_us_turnover_percentage) || '-'
    },
    {
      label: 'cyber_insurances.form.global_gross_margin_percentage',
      span: 1,
      content:
        (global_gross_margin_percentage && global_gross_margin_percentage) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.workstation_number',
      span: 1,
      content: (workstation_number && workstation_number) || '-'
    },
    {
      label: 'cyber_insurances.form.server_number',
      span: 1,
      content: (server_number && server_number) || '-'
    },
    {
      label: 'cyber_insurances.form.personal_data_server_number',
      span: 1,
      content:
        (personal_data_server_number && personal_data_server_number) || '-'
    },
    {
      label: 'cyber_insurances.form.bank_details_server_number',
      span: 1,
      content: (bank_details_server_number && bank_details_server_number) || '-'
    },
    {
      label: 'cyber_insurances.form.health_data_server_number',
      span: 1,
      content: (health_data_server_number && health_data_server_number) || '-'
    },
    {
      label: 'cyber_insurances.form.is_company_yearly_sensitize_cyber_risks',
      span: 3,
      content:
        (is_company_yearly_sensitize_cyber_risks &&
          t(
            `cyber_insurances.form.enums.${is_company_yearly_sensitize_cyber_risks}`
          )) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.is_plan_to_restart_after_damage',
      span: 3,
      content:
        (is_plan_to_restart_after_damage &&
          t(
            `cyber_insurances.form.enums.${is_plan_to_restart_after_damage}`
          )) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.if_yes_is_tested_yearly',
      span: 3,
      content:
        (if_yes_is_tested_yearly &&
          t(`cyber_insurances.form.enums.${if_yes_is_tested_yearly}`)) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.if_yes_activity_restart_delay',
      span: 3,
      content:
        (if_yes_activity_restart_delay && if_yes_activity_restart_delay) || '-'
    },
    {
      label:
        'cyber_insurances.form.is_security_policy_applied_evenly_across_subsidiaries',
      span: 3,
      content:
        (is_security_policy_applied_evenly_across_subsidiaries &&
          t(
            `cyber_insurances.form.enums.${is_security_policy_applied_evenly_across_subsidiaries}`
          )) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.is_outside_backup',
      span: 3,
      content:
        (is_outside_backup &&
          t(`cyber_insurances.form.enums.${is_outside_backup}`)) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.if_yes_frequency',
      span: 3,
      content: (if_yes_frequency && if_yes_frequency) || '-'
    },
    {
      label:
        'cyber_insurances.form.is_activity_restart_delay_tested_twice_a_year',
      span: 3,
      content:
        (is_activity_restart_delay_tested_twice_a_year &&
          t(
            `cyber_insurances.form.enums.${is_activity_restart_delay_tested_twice_a_year}`
          )) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.is_connection_to_si_vpn_secured',
      span: 3,
      content:
        (is_connection_to_si_vpn_secured &&
          t(
            `cyber_insurances.form.enums.${is_connection_to_si_vpn_secured}`
          )) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.if_not_protocols_description',
      span: 3,
      content:
        (if_not_protocols_description && if_not_protocols_description) || '-'
    },
    {
      label: 'cyber_insurances.form.is_network_access_control_enabled',
      span: 3,
      content:
        (is_network_access_control_enabled &&
          t(
            `cyber_insurances.form.enums.${is_network_access_control_enabled}`
          )) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.is_double_authentication_applied_for',
      span: 3,
      content:
        (is_double_authentication_applied_for &&
          is_double_authentication_applied_for
            .map((auth) => t(`cyber_insurances.form.enums.${auth}`))
            .join(', ')) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.is_password_manager_required',
      span: 3,
      content:
        (is_password_manager_required &&
          t(`cyber_insurances.form.enums.${is_password_manager_required}`)) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.is_infrastructure_cloud_hosted',
      span: 3,
      content:
        (is_infrastructure_cloud_hosted &&
          t(`cyber_insurances.form.enums.${is_infrastructure_cloud_hosted}`)) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.if_cloud_hosted_is_totally_hosted',
      span: 3,
      content:
        (if_cloud_hosted_is_totally_hosted &&
          t(
            `cyber_insurances.form.enums.${if_cloud_hosted_is_totally_hosted}`
          )) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.if_cloud_hosted_cloud_providers',
      span: 1,
      content:
        (if_cloud_hosted_cloud_providers &&
          if_cloud_hosted_cloud_providers.map((auth) => auth).join(', ')) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.if_cloud_hosted_cloud_providers_other',
      span: 1,
      content:
        (if_cloud_hosted_cloud_providers_other &&
          if_cloud_hosted_cloud_providers_other) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.is_microsoft_active_directory_used',
      span: 1,
      content:
        (is_microsoft_active_directory_used &&
          t(
            `cyber_insurances.form.enums.${is_microsoft_active_directory_used}`
          )) ||
        '-'
    },
    {
      label:
        'cyber_insurances.form.if_microsoft_active_directory_user_accounts_quantity',
      span: 3,
      content:
        (if_microsoft_active_directory_user_accounts_quantity &&
          if_microsoft_active_directory_user_accounts_quantity) ||
        '-'
    },
    {
      label:
        'cyber_insurances.form.if_microsoft_active_directory_service_accounts_quantity',
      span: 3,
      content:
        (if_microsoft_active_directory_service_accounts_quantity &&
          if_microsoft_active_directory_service_accounts_quantity) ||
        '-'
    },
    {
      label:
        'cyber_insurances.form.is_admin_right_limited_and_admin_session_segregated',
      span: 3,
      content:
        (is_admin_right_limited_and_admin_session_segregated &&
          t(
            `cyber_insurances.form.enums.${is_admin_right_limited_and_admin_session_segregated}`
          )) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.is_access_permissions_enabled',
      span: 3,
      content:
        (is_access_permissions_enabled &&
          t(`cyber_insurances.form.enums.${is_access_permissions_enabled}`)) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.is_collaborator_admin_of_own_workspace',
      span: 3,
      content:
        (is_collaborator_admin_of_own_workspace &&
          t(
            `cyber_insurances.form.enums.${is_collaborator_admin_of_own_workspace}`
          )) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.is_mobile_workspace_hashed',
      span: 3,
      content:
        (is_mobile_workspace_hashed &&
          t(`cyber_insurances.form.enums.${is_mobile_workspace_hashed}`)) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.is_boyd_prohibited',
      span: 3,
      content:
        (is_boyd_prohibited &&
          t(`cyber_insurances.form.enums.${is_boyd_prohibited}`)) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.is_network_segmentation_enabled',
      span: 3,
      content:
        (is_network_segmentation_enabled &&
          t(
            `cyber_insurances.form.enums.${is_network_segmentation_enabled}`
          )) ||
        '-'
    },
    {
      label:
        'cyber_insurances.form.is_security_update_apply_as_soon_as_released',
      span: 3,
      content:
        (is_security_update_apply_as_soon_as_released &&
          t(
            `cyber_insurances.form.enums.${is_security_update_apply_as_soon_as_released}`
          )) ||
        '-'
    },
    {
      label:
        'cyber_insurances.form.if_applied_average_delay_for_high_criticality',
      span: 3,
      content:
        (if_applied_average_delay_for_high_criticality &&
          if_applied_average_delay_for_high_criticality) ||
        '-'
    },
    {
      label:
        'cyber_insurances.form.is_os_and_software_updated_and_supported_by_vendor',
      span: 3,
      content:
        (is_os_and_software_updated_and_supported_by_vendor &&
          t(
            `cyber_insurances.form.enums.${is_os_and_software_updated_and_supported_by_vendor}`
          )) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.if_not_specify_safety_measures',
      span: 3,
      content:
        (if_not_specify_safety_measures && if_not_specify_safety_measures) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.is_pentest_audit_conducted_yearly',
      span: 3,
      content:
        (is_pentest_audit_conducted_yearly &&
          t(
            `cyber_insurances.form.enums.${is_pentest_audit_conducted_yearly}`
          )) ||
        '-'
    },
    {
      label:
        'cyber_insurances.form.is_antivirus_installed_and_updated_on_each_workspace',
      span: 3,
      content:
        (is_antivirus_installed_and_updated_on_each_workspace &&
          t(
            `cyber_insurances.form.enums.${is_antivirus_installed_and_updated_on_each_workspace}`
          )) ||
        '-'
    },
    {
      label:
        'cyber_insurances.form.is_antivirus_installed_and_updated_on_each_server',
      span: 3,
      content:
        (is_antivirus_installed_and_updated_on_each_server &&
          t(
            `cyber_insurances.form.enums.${is_antivirus_installed_and_updated_on_each_server}`
          )) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.is_email_filtered',
      span: 3,
      content:
        (is_email_filtered &&
          t(`cyber_insurances.form.enums.${is_email_filtered}`)) ||
        '-'
    },
    {
      label:
        'cyber_insurances.form.is_security_events_logs_collection_and_handling_tool',
      span: 3,
      content:
        (is_security_events_logs_collection_and_handling_tool &&
          t(
            `cyber_insurances.form.enums.${is_security_events_logs_collection_and_handling_tool}`
          )) ||
        '-'
    },
    {
      label:
        'cyber_insurances.form.if_security_events_logs_collection_and_handling_tool_specify',
      span: 3,
      content:
        (if_security_events_logs_collection_and_handling_tool_specify &&
          if_security_events_logs_collection_and_handling_tool_specify) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.is_soc',
      span: 3,
      content: (is_soc && t(`cyber_insurances.form.enums.${is_soc}`)) || '-'
    },
    {
      label:
        'cyber_insurances.form.is_double_signature_validation_required_for_payments',
      span: 3,
      content:
        (is_double_signature_validation_required_for_payments &&
          t(
            `cyber_insurances.form.enums.${is_double_signature_validation_required_for_payments}`
          )) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.no_claims_during_last_three_years',
      span: 3,
      content:
        (no_claims_during_last_three_years &&
          t(
            `cyber_insurances.form.enums.${no_claims_during_last_three_years}`
          )) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.other_security_posture_informations',
      span: 3,
      content:
        (other_security_posture_informations &&
          other_security_posture_informations) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.other_infrastructure_informations',
      span: 3,
      content:
        (other_infrastructure_informations &&
          other_infrastructure_informations) ||
        '-'
    },
    {
      label: 'cyber_insurances.form.signee',
      span: 3,
      content: (signee && signee) || '-'
    }
  ];

  if (deleted) {
    labels.push({
      label: 'cyber_insurances.form.deleted',
      span: 3,
      content:
        (deletion_date && dayjs(deletion_date).format('DD/MM/YYYY')) || '-'
    });
  }

  return labels;
};
