import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback, useRef } from 'react';
import { FileExclamationOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { useFiltersDataFetch } from '../../../../../components/ColumnsDynamicFilters/useFiltersDatasFetch';

/**
 * `useColumns` is a custom React hook that provides a list of column configurations.
 *
 * The column titles are fetched using the `react-i18next` hook for internationalization.
 *
 * @hook
 * @returns {Array<object>} Returns an array of column configurations to be consumed by a table component.
 */

export const useColumns = (resourceName, dbExtraQueryFilter, populate) => {
  const { t } = useTranslation();
  const { dispatchAPI, company: companyId } = useAuthContext();
  const { message } = useErrorMessage();
  const [config, setConfig] = useState({});
  const { fetchFilters, filters, loading } = useFiltersDataFetch(
    resourceName,
    dbExtraQueryFilter,
    resourceName,
    populate
  );
  const isMounted = useRef(true);

  const allColumns = [
    {
      title: t('properties.form.missing_documents'),
      key: 'missing_documents',
      dataIndex: ['missing_documents'],
      sorter: true,
      width: 150,
      render: (missing_documents) =>
        missing_documents ? (
          <FileExclamationOutlined
            style={{ fontSize: 18, color: 'var(--warningColor)' }}
          />
        ) : (
          '-'
        )
    },
    {
      title: t('vehicles.form.company_name'),
      key: 'company_name',
      dataIndex: ['company', 'name'],
      sorter: true
    },
    {
      title: t('vehicles.form.contract_number'),
      key: 'contract',
      dataIndex: ['contract', 'contract_number'],
      filters: filters?.contract?.map((number) => ({
        text: number.contract_number,
        value: number._id
      })),
      sorter: true
    },
    {
      title: t('vehicles.form.date_added'),
      key: 'date_added',
      dataIndex: ['created_at'],
      filters: filters?.created_at?.map((date) => ({
        text: dayjs(date).format('DD/MM/YYYY'),
        value: date
      })),
      render: (created_at) =>
        created_at ? dayjs(created_at).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('vehicles.form.validity_start_date'),
      key: 'validity_start_date',
      dataIndex: ['contract', 'validity_start_date'],
      render: (validity_start_date) =>
        validity_start_date
          ? dayjs(validity_start_date).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t('vehicles.form.validity_end_date'),
      key: 'validity_end_date',
      dataIndex: ['contract', 'validity_end_date'],
      render: (validity_end_date) =>
        validity_end_date ? dayjs(validity_end_date).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('vehicles.form.entity'),
      key: 'entity',
      dataIndex: ['entity', 'name'],
      filters: filters?.entity?.map((entity) => ({
        text: entity.name,
        value: entity._id
      })),
      sorter: true
    },
    {
      title: t('vehicles.form.site'),
      key: 'site',
      dataIndex: ['site', 'name'],
      filters: filters?.site?.map((site) => ({
        text: site.name,
        value: site._id
      })),
      sorter: true
    },
    {
      title: t('vehicles.form.address'),
      key: 'address',
      dataIndex: ['company'],
      sorter: true,
      render: (company) =>
        company
          ? `${company.number || ''} ${company.street || ''} ${
              company.additional || ''
            }`
          : '-'
    },
    {
      title: t('vehicles.form.postal_code'),
      key: 'postal_code',
      dataIndex: ['company', 'zip_code'],
      sorter: true
    },
    {
      title: t('vehicles.form.city'),
      key: 'city',
      dataIndex: ['company', 'city'],
      sorter: true
    },
    {
      title: t('vehicles.form.products'),
      key: 'heavy_vehicle_type',
      dataIndex: 'heavy_vehicle_type',
      sorter: true,
      filters: filters?.heavy_vehicle_type?.map((type) => ({
        text: t(`vehicles.form.enums.heavy_vehicle_type.${type}`),
        value: type
      })),
      render: (heavy_vehicle_type) =>
        heavy_vehicle_type &&
        t(`vehicles.form.enums.heavy_vehicle_type.${heavy_vehicle_type}`)
    },
    {
      title: t('vehicles.form.vehicle_brand'),
      key: 'vehicle_brand',
      dataIndex: 'vehicle_brand',
      sorter: true,
      filters: filters?.vehicle_brand?.map((brand) => ({
        text: brand,
        value: brand
      }))
    },
    {
      title: t('vehicles.form.registration_or_serial_number'),
      key: 'registration_or_serial_number',
      dataIndex: 'registration_or_serial_number',
      sorter: true,
      filters: filters?.registration_or_serial_number?.map((number) => ({
        text: number,
        value: number
      }))
    },
    {
      title: t('vehicles.form.initial_traffic_release'),
      key: 'initial_traffic_release',
      dataIndex: 'initial_traffic_release',
      sorter: true,
      filters: filters?.initial_traffic_release?.map((date) => ({
        text: dayjs(date).format('DD/MM/YYYY'),
        value: date.toString()
      })),
      render: (initial_traffic_release) =>
        initial_traffic_release
          ? dayjs(initial_traffic_release).format('DD/MM/YYYY')
          : '-'
    },
    {
      title: t('vehicles.form.vehicle_model'),
      key: 'vehicle_model',
      dataIndex: 'vehicle_model',
      sorter: true,
      filters: filters?.vehicle_model?.map((model) => ({
        text: model,
        value: model
      }))
    },
    {
      title: t('vehicles.form.principal_driver'),
      key: 'principal_driver',
      dataIndex: 'principal_driver',
      sorter: true,
      filters: filters?.principal_driver?.map((driver) => ({
        text: `${driver.first_name} ${driver.last_name}`,
        value: driver._id
      })),
      render: (principal_driver) =>
        principal_driver
          ? `${principal_driver.first_name} ${principal_driver.last_name}`
          : '-'
    },
    {
      title: t('vehicles.form.purchase_date_or_lease_start_date'),
      key: 'purchase_date_or_lease_start_date',
      dataIndex: 'purchase_date_or_lease_start_date',
      sorter: true,
      filters: filters?.purchase_date_or_lease_start_date?.map((date) => ({
        text: dayjs(date).format('DD/MM/YYYY'),
        value: date
      })),
      render: (purchase_date_or_lease_start_date) =>
        purchase_date_or_lease_start_date
          ? dayjs(purchase_date_or_lease_start_date).format('DD/MM/YYYY')
          : '-'
    },
    {
      title: t('vehicles.form.piston_displacement'),
      key: 'piston_displacement',
      dataIndex: 'piston_displacement',
      sorter: true,
      filters: filters?.piston_displacement?.map((displacement) => ({
        text: displacement?.toString(),
        value: displacement?.toString()
      }))
    },
    {
      title: t('vehicles.form.taxable_power'),
      key: 'taxable_power',
      dataIndex: 'taxable_power',
      sorter: true,
      filters: filters?.taxable_power?.map((power) => ({
        text: power?.toString(),
        value: power?.toString()
      }))
    },
    {
      title: t('vehicles.form.mileage'),
      key: 'mileage',
      dataIndex: 'mileage',
      sorter: true,
      filters: filters?.mileage?.map((mileage) => ({
        text: mileage?.toString(),
        value: mileage?.toString()
      }))
    },
    {
      title: t('vehicles.form.observations'),
      key: 'observations',
      dataIndex: 'observations',
      sorter: true,
      filters: filters?.observations?.map((observation) => ({
        text: observation,
        value: observation
      }))
    },
    {
      title: t('vehicles.form.desired_effective_date'),
      key: 'desired_effective_date',
      dataIndex: 'desired_effective_date',
      filters: filters?.desired_effective_date?.map((date) => ({
        text: dayjs(date).format('DD/MM/YYYY'),
        value: date
      })),
      render: (desired_effective_date) =>
        desired_effective_date
          ? dayjs(desired_effective_date).format('DD/MM/YYYY')
          : '-'
    },
    {
      title: t('vehicles.form.deletion_date'),
      key: 'deletion_date_effect',
      dataIndex: 'deletion_date_effect',
      sorter: true,
      filters: filters?.deletion_date_effect?.map((date) => ({
        text: dayjs(date).format('DD/MM/YYYY'),
        value: date
      })),
      render: (deletion_date_effect) =>
        deletion_date_effect
          ? dayjs(deletion_date_effect).format('DD/MM/YYYY')
          : '-'
    }
  ];
  const getConfig = useCallback(async () => {
    if (!isMounted.current) return;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/programme-configs/${companyId}/vehicles-heavy-configs`
      });
      setConfig(data[0]);
    } catch (e) {
      if (isMounted.current) message('missing_parameters');
    }
  }, [dispatchAPI, message]);

  useEffect(() => {
    getConfig();
    fetchFilters();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const filteredColumns = allColumns.filter((column) => config[column.key]);

  return [filteredColumns, loading] || [];
};
