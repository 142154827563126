import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Col, Flex, Skeleton, Row, Steps } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useListContentClaim } from '../utils/listContentClaim';
import { useListContentCommentaries } from '../utils/listContentCommentaries';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../components';
import { Documents } from '../../components/Documents';
import {
  useListItemsStep,
  useListBeforeValidation
} from '../utils/listItemsStep';
import { handleStatusUtils } from '../utils/handleStatusUtils';
import { ModalClosure } from '../utils/ModalClosure';
import { PhotosClaims } from '../utils/PhotosClaims';
import { ShowClaimAccountingOverview } from '../ClaimAccountingOverview/ShowClaimAccountingOverview';
import { useGetProgram } from '../../../utils/getProgram';
import { History } from '../../components/History';
import { useProgramDataUpdater } from '../../../utils/programDataUpdater';
import { MailingButton } from '../../Mailing/MailingButton';

/**
 * Renders the details of a vehicles claim.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.useListContent - A function to retrieve the list content.
 * @param {string} props.pathname - The current pathname.
 * @param {Function} props.useExtraButtons - A function to retrieve extra buttons.
 * @returns {JSX.Element} The VehiclesClaimShow component.
 */
export const VehiclesClaimShow = ({
  useListContent,
  pathname,
  useExtraButtons
}) => {
  const targetUrl = 'vehicle_claims';
  const { id } = useParams();
  const { dispatchAPI, permissions, verifPermissions } = useAuthContext();
  const { message } = useErrorMessage();
  const {
    current,
    setCurrent,
    onChange,
    toEnclosed,
    setToEnclose,
    changingStatus,
    setChangingStatus
  } = handleStatusUtils(targetUrl);
  const { t } = useTranslation();
  const { items } = useListItemsStep();
  const { itemsBeforeValidation } = useListBeforeValidation();
  const [vehicle, setVehicle] = useState();
  const listContent = useListContent(vehicle);

  const [pageStatus, setPageStatus] = useState({
    isLoading: true,
    edit: true,
    closure: false
  });
  const { isLoading, edit, closure } = pageStatus;

  const [VehicleClaimInfo, setVehicleClaimInfo] = useState({});
  const {
    claim,
    ID,
    isCompliant,
    isClosed,
    accountingBalanced,
    feesAppealsBalanced
  } = VehicleClaimInfo;

  const listContentClaim = useListContentClaim(claim);
  const listContentCommentaries = useListContentCommentaries(claim);

  const {
    setIsCompliant,
    setAccountingBalanced,
    setFeesAppealsBalanced,
    initialData
  } = useProgramDataUpdater(VehicleClaimInfo, setVehicleClaimInfo);

  const setIsLoading = (value) => {
    setPageStatus({ ...pageStatus, isLoading: value });
  };
  const setEdit = (value) => {
    setPageStatus({ ...pageStatus, edit: value });
  };
  const setClosure = (value) => {
    setPageStatus({ ...pageStatus, closure: value });
  };

  const { fetchProgram } = useGetProgram({
    setIsLoading,
    setProgram: setVehicle,
    programId: ID,
    route: 'vehicles'
  });

  const { extraButtons } = useExtraButtons({
    pathname,
    current,
    onChange,
    setClosure,
    vehicleClaim: claim,
    vehicle,
    isCompliant,
    setIsCompliant,
    edit,
    toEnclosed,
    accountingBalanced,
    feesAppealsBalanced,
    targetUrl,
    changingStatus,
    setChangingStatus
  });

  const getVehiclesClaim = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/vehicle_claims/documents/${id}?populate=driver_time_accident`
      });
      setVehicleClaimInfo({
        ...initialData(data)
      });
      setCurrent(data?.step);
      setToEnclose(data?.to_enclose);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (ID) {
      (async () => {
        await fetchProgram();
      })();
    }
  }, [ID]);

  useEffect(() => {
    (async () => {
      await getVehiclesClaim();
    })();
  }, [current, accountingBalanced, feesAppealsBalanced]);

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setEdit(
        !verifPermissions('/claims/edit', permissions?.notPermittedRoutes)
      );
    }
  }, [permissions]);

  return ID && vehicle && !isLoading ? (
    <>
      <PageHeaderCustom
        title={
          pathname === 'automissions'
            ? `${claim?.unit_counter || 'xxxx'} - ${
                vehicle?.collaborator_first_name || ''
              } ${vehicle?.collaborator_last_name || ''} ${
                vehicle?.vehicle_registration_number || ''
              }`
            : `${claim?.unit_counter || 'xxxx'} - ${
                vehicle?.vehicle_brand || ''
              } ${vehicle?.vehicle_model || ''} ${
                vehicle?.registration_or_serial_number || ''
              }`
        }
        extra={extraButtons}
      />
      <MailingButton
        asset={claim}
        templateName={changingStatus}
        tradKey={changingStatus}
        baseUrl={targetUrl}
        changingStatus={changingStatus}
        setChangingStatus={setChangingStatus}
      />
      <ContentCustom>
        <Flex vertical gap="middle">
          {current !== undefined && current !== 6 ? (
            <Steps
              onChange={(step) => {
                onChange(step, id, edit);
              }}
              items={isCompliant ? items : itemsBeforeValidation}
              current={current}
            />
          ) : null}
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={12}>
              <Row gutter={[0, 16]}>
                <Card>
                  <DescriptionList
                    data={listContentClaim}
                    translate
                    layout="vertical"
                    title={t('claims.form.claim_informations')}
                  />
                </Card>
                <PhotosClaims baseUrl="vehicle_claims" />
                <Documents
                  idWithoutParams={id}
                  baseUrl="vehicle_claims"
                  resource="Vehicle_claim"
                  noDelete={current === 6}
                />
              </Row>
            </Col>
            <Col xs={24} xl={12}>
              <Row gutter={[0, 16]}>
                <Card title={t('claims.form.main_informations')}>
                  <DescriptionList
                    data={listContent}
                    translate
                    layout="vertical"
                  />
                </Card>
                <Card>
                  <DescriptionList
                    data={listContentCommentaries}
                    translate
                    layout="vertical"
                    title={t('claims.form.commentaries')}
                  />
                </Card>
                <Col span={24}>
                  <Card title={t('subscriptions.form.history')} size="small">
                    <History route="vehicle_claims" />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={24}>
              {current >= 2 && isCompliant ? (
                <ShowClaimAccountingOverview
                  current={current}
                  buttonBelowTable={
                    current !== 6 && current !== undefined && edit === true
                  }
                  noActionColumnsButton={isClosed}
                  accountingBalanced={accountingBalanced}
                  setAccountingBalanced={setAccountingBalanced}
                  feesAppealsBalanced={feesAppealsBalanced}
                  setFeesAppealsBalanced={setFeesAppealsBalanced}
                  resourceName="vehicle-claim-accounts"
                  url="vehicle-claim-accounts"
                  resourceNameClaim="vehicle_claims"
                  urlClaim="vehicle_claims"
                  urlAccount="claim-accounts"
                  edit={edit}
                />
              ) : null}
            </Col>
          </Row>
        </Flex>
        <ModalClosure
          open={closure}
          onCancel={() => setClosure(false)}
          onOk={() => setClosure(false)}
          claimReferenceNumber={claim?.unit_counter}
        />
      </ContentCustom>
    </>
  ) : (
    <Skeleton active loading={isLoading} />
  );
};

VehiclesClaimShow.propTypes = {
  useListContent: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  useExtraButtons: PropTypes.func.isRequired
};
