import { createContext, useContext, useState, useEffect } from 'react';
import { useAuthContext } from './AuthContext';
import { useSocketContext } from './SocketContext';
import { useErrorMessage } from '../utils/errorMessage';

const MiscellaneousContext = createContext();

/**
 * @description Miscellaneous context provider, a context peovider for dispatching non themed informations such as counters or whatever
 * @param {Object} props Props
 * @param {React.ReactNode} props.children Children
 * @returns {React.ReactNode} Miscellaneous context provider
 */
export const MiscellaneousContextProvider = ({ children }) => {
  const { user, token, company, dispatchAPI } = useAuthContext();
  const { socket } = useSocketContext();
  const { message } = useErrorMessage();
  const [notReadMessagesCount, setNotReadMessagesCount] = useState({
    notReadMessages: 0,
    notReadClaimMessages: 0
  });
  const [refreshCounter, setRefreshCounter] = useState(false);

  const getConversationCount = async () => {
    if (!company || !user?._id || !token) return;
    try {
      const conversationCountUrl = `/conversations/messages/count/${company}/${user?._id}`;
      const { data } = await dispatchAPI('GET', { url: conversationCountUrl });
      setNotReadMessagesCount(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    const fetchConversations = async () => {
      await getConversationCount();
    };

    if (socket && token && company && user) {
      socket.on('newMessage', fetchConversations);
    }
    fetchConversations();
    return () => {
      if (socket) {
        socket.off('newMessage', fetchConversations);
      }
    };
  }, [socket, company, user?._id, refreshCounter]);

  return (
    <MiscellaneousContext.Provider
      value={{
        notReadMessagesCount,
        refreshCounter,
        setRefreshCounter
      }}
    >
      {children}
    </MiscellaneousContext.Provider>
  );
};

export const useMiscellaneousContext = () => {
  const context = useContext(MiscellaneousContext);
  if (context === undefined) {
    throw new Error(
      'useMiscellaneousContext must be used within a ConversationProvider'
    );
  }
  return context;
};
