import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, DatePicker } from 'antd';
import { SelectManager } from '../../../components/SelectManager';
import { SelectContract } from '../../../components/SelectContract';
import { HoleInOneContext } from '../HoleInOneContext';
import { SelectSiteV2 } from '../../../components/SelectSiteV2';
import { SelectEntityV2 } from '../../../components/SelectEntityV2';

const { TextArea } = Input;

/**
 * `useFields` is a custom hook that provides fields for forms and manages their associated states.
 *
 * @returns
 * @property {Array} fields - An array of field configurations for the form.
 * @hook
 */

const useFields = (form) => {
  const { t } = useTranslation();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [selectedEntity, setSelectedEntity] = useState();
  const [entityHasSite, setEntityHasSite] = useState(false);
  const {
    setCurrentEntityContext,
    currentEntityContext,
    entitiesContext,
    setEntitiesContext,
    currentContract,
    setCurrentContract
  } = useContext(HoleInOneContext);
  const dateFormat = 'DD-MM-YYYY';

  const handleEntityChange = (value) => {
    setCurrentEntityContext(value);
    form.setFieldsValue({ site: undefined });
  };

  const handleContractChange = () => {
    form.setFieldsValue({ entity: undefined, site: undefined });
  };

  useEffect(() => {
    if (currentEntityContext) {
      form.setFieldsValue({
        site: null
      });
      const entity = entitiesContext?.find(
        (e) => e._id === currentEntityContext
      );
      setEntityHasSite(entity?.sites?.length > 0);
    }
  }, [currentEntityContext]);

  const fieldsGolf = [
    {
      label: ['contract_number'],
      name: ['contract'],
      rules: [{ required: true }],
      input: (
        <SelectContract
          dbKey="contract"
          programme_types="HOLE_IN_ONE"
          setCurrentContract={setCurrentContract}
          handleContractChange={handleContractChange}
        />
      ),
      startWithDivider: {
        title: t('hole_in_ones.form.main_informations')
      }
    },
    {
      label: ['customer_manager'],
      name: ['customer_manager'],
      rules: [{ required: true }],
      input: (
        <SelectManager
          dbKey="customer_manager"
          setIsFieldsLoading={setIsFieldsLoading}
        />
      )
    },
    {
      label: ['unit_manager'],
      name: ['unit_manager'],
      input: (
        <SelectManager
          dbKey="unit_manager"
          setIsFieldsLoading={setIsFieldsLoading}
        />
      )
    },
    {
      label: ['desired_effective_date'],
      name: ['desired_effective_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['entity'],
      name: ['entity'],
      rules: [{ required: true }],
      input: (
        <SelectEntityV2
          dbKey="entity"
          setSelectedEntity={setSelectedEntity}
          permKey="programme-HOLE_IN_ONE"
          currentContract={currentContract}
          setEntitiesContext={setEntitiesContext}
          handleEntityChange={handleEntityChange}
          currentEntityContext={currentEntityContext}
        />
      )
    },
    {
      label: ['site'],
      name: ['site'],
      rules: [{ required: entityHasSite }],
      input: (
        <SelectSiteV2
          dbKey="site"
          selectedEntity={selectedEntity}
          currentEntityContext={currentEntityContext}
          entitiesContext={entitiesContext}
        />
      )
    },
    {
      label: ['golf_name'],
      name: ['golf_name'],
      rules: [{ required: true }]
    },
    {
      label: ['golf_address'],
      name: ['golf_address'],
      rules: [{ required: true }]
    },
    {
      label: ['postal_code'],
      name: ['postal_code'],
      rules: [{ required: true }]
    },
    {
      label: ['city'],
      name: ['city'],
      rules: [{ required: true }]
    },
    {
      label: ['event_start_date'],
      name: ['event_start_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['event_end_date'],
      name: ['event_end_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['event_name'],
      name: ['event_name'],
      rules: [{ required: true }]
    },
    {
      label: ['number_of_men_players'],
      name: ['number_of_men_players'],
      rules: [{ required: true }],
      input: <Input type="Number" />
    },
    {
      label: ['number_of_women_players'],
      name: ['number_of_women_players'],
      rules: [{ required: true }],
      input: <Input type="Number" />
    },
    {
      label: ['number_of_the_concerned_hole'],
      name: ['number_of_the_concerned_hole'],
      rules: [{ required: true }],
      input: <Input type="Number" />
    },
    {
      label: ['official_length_for_men_hole_in_one'],
      name: ['official_length_for_men_hole_in_one'],
      rules: [{ required: true }],
      input: <Input type="Number" addonAfter="m" />
    },
    {
      label: ['official_length_for_women_hole_in_one'],
      name: ['official_length_for_women_hole_in_one'],
      rules: [{ required: true }],
      input: <Input type="Number" addonAfter="m" />
    },
    {
      name: ['prize_description'],
      rules: [{ required: true }],
      input: <TextArea />
    },
    {
      label: ['prize_value'],
      name: ['prize_value'],
      rules: [{ required: true }],
      input: <Input type="Number" addonAfter="€" />
    }
  ];
  return {
    fieldsGolf,
    isFieldsLoading
  };
};
export default useFields;
