import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useFields } from './fieldsAutomissions';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { CreateUpdateContainer } from '../../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useHistory } from '../../../../utils/historyUtils';

/**
 * Provides a component for creating or updating automissions vehicles claims.
 *
 * @component
 * @param {object} props - React component props.
 * @param {string} props.purpose - The purpose of the component, either "create" or "update".
 * @returns {React.ReactNode} The CreateUpdateContainer component with specific configurations for automissions vehicles claims.
 */
export const AutomissionsCreateUpdate = ({ purpose }) => {
  const { dispatchAPI, company } = useAuthContext();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const [vehicle, setVehicle] = useState();
  const [isOther, setIsOther] = useState(false);
  const [isComplaint, setIsComplaint] = useState(false);
  const { fieldsInformations } = useFields(isOther, setIsOther, setIsComplaint);
  const { setInitialHistory, createHistory, updateHistory } = useHistory();

  const draggerFilesKeysOverRide = [
    'car_registration_document',
    'driver_license',
    'auto_report',
    'filing_complaint'
  ];
  const mandatoryDocuments = isComplaint
    ? ['driver_license', 'filing_complaint']
    : ['driver_license'];
  const draggerFilesKeysMandatory = isComplaint
    ? ['driver_license', 'filing_complaint']
    : ['driver_license'];

  const getVehicle = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `vehicle/${id}?populate=entity,site,contract,company`
      });
      setVehicle(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getVehicle();
    })();
  }, []);

  const config = {
    onGetResource: {
      setFields: (data) => {
        if (data.claim_type === 'OTHER') {
          setIsOther(true);
        }
        setInitialHistory(data);
        return {
          ...data,
          sinister_date: data.sinister_date && dayjs(data.sinister_date)
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        entry_id: id,
        company,
        opening_date: dayjs(),
        entity: vehicle?.entity?._id,
        site: vehicle?.site?._id,
        fleet_type: 'AUTOMISSION',
        ...createHistory('claim', 'vehicles', id)
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        ...updateHistory('claim')
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fieldsInformations}
      purpose={purpose}
      baseUrl="vehicle_claims"
      resource="claims"
      config={config}
      populate="?populate=documents.file"
      mandatoryDocuments={mandatoryDocuments}
      draggerFilesKeysOverRide={draggerFilesKeysOverRide}
      draggerFilesKeysMandatory={draggerFilesKeysMandatory}
    />
  );
};

AutomissionsCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
