import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFields } from './fieldsOthersConstruction';
import { CreateUpdateContainer } from '../../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useConstructionUtils } from '../ConstructionUtils';

/**
 * Provides a component for creating or updating other construction insurance claims.
 *
 * @component
 * @param {object} props - React component props.
 * @param {string} props.purpose - The purpose of the component, either "create" or "edit".
 * @returns {React.ReactNode} The CreateUpdateContainer component with specific configurations for other construction insurance claims.
 */
export const OthersConstructionCreateUpdate = ({ purpose }) => {
  const { id } = useParams();
  const [otherConstruction, setOthersConstruction] = useState();
  const [subProgramme, setSubProgramme] = useState();
  const { fieldsInformations } = useFields();

  const { draggerFilesKeysOverRide, getConstructions, config } =
    useConstructionUtils({
      construction: otherConstruction,
      subProgramme,
      otherConstruction
    });

  useEffect(() => {
    (async () => {
      await getConstructions(setOthersConstruction, setSubProgramme);
    })();
  }, [id]);

  return (
    <CreateUpdateContainer
      fields={fieldsInformations}
      purpose={purpose}
      baseUrl="construction_claims"
      resource="claims"
      config={config}
      populate="?populate=documents.file"
      draggerFilesKeysOverRide={draggerFilesKeysOverRide}
    />
  );
};

OthersConstructionCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
