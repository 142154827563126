import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'antd';
import { EditOutlined, SyncOutlined } from '@ant-design/icons';
import { routes } from '../../../../utils/constants/adminRoutes';
import { ClaimButton } from '../../../components/ClaimButton';
import { MailingButton } from '../../../Mailing/MailingButton';
import usePopConfirm from '../../utils/popConfirms';

/**
 * Custom hook that returns extra buttons for a vehicle fleet automission.
 * @hook
 * @param {boolean} isDeleted - Indicates if the vehicle is deleted.
 * @param {object} vehicle - The vehicle object.
 * @returns {JSX.Element} - The extra buttons JSX element.
 */
export const useExtraButtons = (isDeleted, vehicle) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const { exitProgram, unarchiveProgram } = usePopConfirm('vehicles');

  const iconSize = 18;

  return (
    <>
      {isDeleted && (
        <Link
          to={{
            pathname: `${routes.PROGRAMMES}/automissions/edit/${id}`
          }}
        >
          <Button type="primary">
            {`${t('buttons.edit')} `}
            <EditOutlined />
          </Button>
        </Link>
      )}
      {isDeleted && (
        <>
          <Button>
            {`${t('buttons.generate_attestation')} `}
            <SyncOutlined style={{ fontSize: iconSize }} />
          </Button>
          <MailingButton
            asset={vehicle}
            templateName="InsuranceAttestation"
            tradKey="sending_attestation"
            resource="Vehicle"
            baseUrl="vehicles"
          />
          <ClaimButton id={id} url="automissions" claimType="vehicle" />
          <MailingButton
            asset={vehicle}
            templateName="Blank"
            tradKey="specific_demand"
            resource="Vehicle"
            baseUrl="vehicles"
          />
        </>
      )}
      {vehicle &&
        !vehicle.deleted &&
        exitProgram({
          visible,
          setVisible
        })}
      {vehicle && vehicle.deleted && unarchiveProgram()}
    </>
  );
};
