import { useCallback } from 'react';
import { findContractById } from './helpersFunctions';

/**
 * Custom hook that returns the configuration objects for getting and creating resources.
 *
 * @param {function} dispatch - The dispatch function from the Redux store.
 * @param {string} company - The company ID.
 * @param {string} program - The program type.
 * @param {string} subProgram - The sub-program type.
 * @returns {object} - The configuration objects for getting and creating resources.
 */

export const useConfig = (company, state, documentType, dispatch) => {
  const { contractId, contracts } = state;
  const selectedContract = findContractById(contracts, contractId);

  const onGetResource = {
    setFields: useCallback(
      (data) => {
        dispatch({
          type: 'SET_CONTRACT_ID',
          field: 'contractId',
          payload: data.contract
        });
        dispatch({
          type: 'SET_ENTITY',
          field: 'entity',
          payload: data.entity
        });
        return {
          ...data
        };
      },
      [contractId]
    )
  };

  const onCreateResource = {
    setBody: useCallback(
      (data) => ({
        ...data,
        company_Id: company,
        contract: contractId,
        document_type: documentType,
        contract_number: selectedContract
          ? selectedContract.contract_number
          : null,
        contribution_frequency: selectedContract
          ? selectedContract.contribution_frequency
          : null
      }),
      [company, contractId, selectedContract]
    )
  };
  const onUpdateResource = {
    setBody: useCallback(
      (data) => ({
        ...data,
        company_Id: company,
        contract: contractId,
        contract_number: selectedContract
          ? selectedContract.contract_number
          : null,
        document_type: documentType
      }),
      [company, contractId, selectedContract]
    )
  };
  return {
    onGetResource,
    onCreateResource,
    onUpdateResource
  };
};
