/**
 * Defines render conditions based on current state and flags.
 * @hook
 * @param {number} current - The current page state.
 * @param {boolean} edit - Flag indicating edit mode.
 * @param {boolean} toEnclosed - Flag indicating closure status.
 * @returns {Object} An object containing render conditions for various components.
 */
const useRenderConditions = ({ isCompliant, current, edit, toEnclosed }) => {
  const renderConditions = {
    conditionAcknowledgeReceiptButton: current === undefined,
    conditionAcknowledgeCompliance:
      (current === 0 || current === 1) && !isCompliant,
    conditionSpecifiDemand: current !== 6 && current !== undefined,
    conditionEdit: edit && current !== 6 && current !== undefined,
    conditionCloseClaim: toEnclosed
  };

  return { renderConditions };
};

export default useRenderConditions;
