export const vehiclesDbKeys = [
  'contract',
  'created_at',
  'entity',
  'site',
  'light_vehicle_type',
  'heavy_vehicle_type',
  'other_vehicle_type',
  'vehicle_brand',
  'registration_or_serial_number',
  'initial_traffic_release',
  'vehicle_model',
  'principal_driver',
  'purchase_date_or_lease_start_date',
  'piston_displacement',
  'taxable_power',
  'mileage',
  'observations',
  'desired_effective_date',
  'deletion_date_effect',
  'deletion_date'
];

export const automissionsDbKeys = [
  'contract',
  'created_at',
  'entity',
  'site',
  'collaborator_first_name',
  'collaborator_last_name',
  'vehicle_registration_number',
  'departure_time',
  'start_of_the_mission',
  'planned_end_of_the_mission',
  'journey_lenght',
  'departure_city',
  'arrival_city',
  'deletion_date_effect',
  'deletion_date'
];

export const golfsDbKeys = [
  'contract',
  'created_at',
  'entity',
  'site',
  'postal_code',
  'city',
  'country',
  'total_insured_developed_area',
  'golf_name',
  'workforce_full_time_equivalent',
  'company_registration_number',
  'content',
  'previous_year_revenue',
  'deletion_date_effect',
  'deletion_date'
];

export const constructionInsurancesDbKeys = [
  'missing_documents',
  'company_name',
  'contract',
  'created_at',
  'entity',
  'site',
  'status',
  'postal_code',
  'city',
  'desired_guarantees',
  'subscriber',
  'contracting_owner_if_different_from_the_subscriber',
  'construction_site_name',
  'construction_permit_number',
  'construction_site_address',
  'expected_construction_cost',
  'expected_construction_starting_date',
  'construction_starting_date',
  'expected_construction_completion_date',
  'construction_completion_date',
  'amendment_new_construction_cost',
  'reason_of_amendment_demand',
  'rental_loss_guarantee',
  'comments',
  'deletion_date_effect',
  'deletion_date',
  'current_amendment',
  'current_amendment_reason_of_amendment_demand'
];

export const propertiesDbKeys = [
  'missing_documents',
  'contract',
  'date_added',
  'entity',
  'site',
  'asset_name',
  'assets_addresses',
  'postal_code',
  'city',
  'country',
  'total_insured_developed_area',
  'legal_status',
  'typology',
  'tenant_occupation',
  'last_and_first_name_of_tenant',
  'deletion_date_effect',
  'deletion_date'
];

export const adValoremDbKeys = [
  'contract',
  'date_added',
  'entity',
  'site',
  'unit_reference_number',
  'shipper_name',
  'departure_city',
  'parcel_quantity',
  'weight',
  'estimated_delivery_date',
  'recipient_name',
  'arrival_city',
  'means_of_transport',
  'nature_of_the_goods',
  'value_of_the_goods',
  'subscriber_last_name_first_name',
  'subscriber_address',
  'subscriber_postal_code',
  'subscriber_city',
  'deletion_date_effect',
  'deletion_date'
];

export const eventCancellationsDbKeys = [
  'contract',
  'date_added',
  'entity',
  'site',
  'unit_reference_number',
  'company_name',
  'contract_number',
  'company_involved_name',
  'company_address',
  'postal_code',
  'city',
  'event_name',
  'event_type',
  'event_start_date',
  'event_end_date',
  'is_a_stage_used',
  'is_a_stage_with_roof',
  'is_a_stage_with_three_closed_sides',
  'is_a_stage_facing_dominant_winds',
  'amount_engaged',
  'total_guarantee_amount',
  'estimated_income',
  'deletion_date_effect',
  'deletion_date'
];

export const holeInOnesDbKeys = [
  'contract',
  'date_added',
  'entity',
  'site',
  'unit_reference_number',
  'golf_name',
  'golf_address',
  'postal_code',
  'city',
  'event_start_date',
  'event_end_date',
  'event_name',
  'number_of_men_players',
  'number_of_women_players',
  'official_length_for_men_hole_in_one',
  'official_length_for_women_hole_in_one',
  'prize_description',
  'prize_value'
];

export const machineryBreakagesDbKeys = [
  'contract',
  'date_added',
  'entity',
  'site',
  'machinery_location_address',
  'postal_code',
  'city',
  'entry_date',
  'removal_date',
  'machinery_description',
  'value_new_tax_excl',
  'machinery_serial_number',
  'machinery_brand',
  'machinery_model',
  'initial_commissioning_date',
  'status',
  'periodic_revision',
  'periodic_revision_frequency'
];
