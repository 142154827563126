import { Route, Routes } from 'react-router-dom';
import { ListHoleInOne } from './ListHoleInOne';
import { HoleInOneCreateUpdate } from './HoleInOneCreateUpdate';
import { HoleInOneShow } from './HoleInOneShow';
import { Exception } from '../../../components';
import { HoleInOneProvider } from './HoleInOneContext';

export const HoleInOneRouter = () => (
  <HoleInOneProvider>
    <Routes>
      <Route
        path="create"
        element={<HoleInOneCreateUpdate purpose="create" />}
      />
      <Route
        path="edit/:id"
        element={<HoleInOneCreateUpdate purpose="edit" />}
      />
      <Route path="show/:id" element={<HoleInOneShow />} />
      <Route index element={<ListHoleInOne />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </HoleInOneProvider>
);
