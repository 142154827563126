import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

/**
 * Produces a list content for displaying detailed golf information.
 *
 * This hook extracts specified properties from the provided data, formats them
 * and returns an array of objects suitable for rendering in a description list.
 *
 * @hook
 * @param {Object} data - Golf data to process.
 * @returns {Array<Object>} - Formatted list content for rendering.
 *
 */

export const useListContent = (data = {}) => {
  const { t } = useTranslation();
  const {
    contract,
    company,
    created_at,
    entity,
    site,
    customer_manager,
    unit_manager,
    desired_effective_date,
    machinery_site,
    machinery_location_address,
    postal_code,
    city,
    country,
    entry_date,
    removal_date,
    machinery_description,
    value_new_tax_excl,
    machinery_serial_number,
    machinery_brand,
    machinery_model,
    initial_commissioning_date,
    status,
    periodic_revision,
    periodic_revision_frequency,
    comments,
    deleted,
    deletion_date_effect
  } = data;
  const customerManagerName = customer_manager
    ? `${customer_manager.first_name} ${customer_manager.last_name}`
    : '-';
  const unit_managerName = unit_manager
    ? `${unit_manager.first_name} ${unit_manager.last_name}`
    : '-';

  const labels = [
    {
      label: 'machinery_breakages.form.contract_number',
      span: 1,
      content: (contract && contract.contract_number) || '-'
    },
    {
      label: 'machinery_breakages.form.company_name',
      span: 1,
      content: (company?.name && company.name) || '-'
    },
    {
      label: 'machinery_breakages.form.date_added',
      span: 1,
      content: (created_at && dayjs(created_at).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'machinery_breakages.form.entity',
      span: 1,
      content: (entity && entity.name) || '-'
    },
    {
      label: 'machinery_breakages.form.site',
      span: 1,
      content: (site && site.name) || '-'
    },
    {
      label: 'machinery_breakages.form.customer_manager',
      span: 1,
      content: (customerManagerName && customerManagerName) || '-'
    },
    {
      label: 'machinery_breakages.form.unit_manager',
      span: 1,
      content: (unit_managerName && unit_managerName) || '-'
    },
    {
      label: 'machinery_breakages.form.desired_effective_date',
      span: 1,
      content:
        (desired_effective_date &&
          dayjs(desired_effective_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'machinery_breakages.form.validity_end_date',
      span: 1,
      content:
        (contract?.validity_end_date &&
          dayjs(contract?.validity_end_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'machinery_breakages.form.machinery_site',
      span: 1,
      content: (machinery_site && machinery_site) || '-'
    },
    {
      label: 'machinery_breakages.form.machinery_location_address',
      span: 1,
      content: (machinery_location_address && machinery_location_address) || '-'
    },
    {
      label: 'machinery_breakages.form.postal_code',
      span: 1,
      content: (postal_code && postal_code) || '-'
    },
    {
      label: 'machinery_breakages.form.city',
      span: 1,
      content: (city && city) || '-'
    },
    {
      label: 'machinery_breakages.form.country',
      span: 1,
      content: (country && country) || '-'
    },
    {
      label: 'machinery_breakages.form.entry_date',
      span: 1,
      content: (entry_date && dayjs(entry_date).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'machinery_breakages.form.removal_date',
      span: 1,
      content: (removal_date && dayjs(removal_date).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'machinery_breakages.form.machinery_description',
      span: 1,
      content: (machinery_description && machinery_description) || '-'
    },
    {
      label: 'machinery_breakages.form.value_new_tax_excl',
      span: 1,
      content: (value_new_tax_excl && value_new_tax_excl) || '-'
    },
    {
      label: 'machinery_breakages.form.machinery_serial_number',
      span: 1,
      content: (machinery_serial_number && machinery_serial_number) || '-'
    },
    {
      label: 'machinery_breakages.form.machinery_brand',
      span: 1,
      content: (machinery_brand && machinery_brand) || '-'
    },
    {
      label: 'machinery_breakages.form.machinery_model',
      span: 1,
      content: (machinery_model && machinery_model) || '-'
    },
    {
      label: 'machinery_breakages.form.initial_commissioning_date',
      span: 1,
      content:
        (initial_commissioning_date &&
          dayjs(initial_commissioning_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'machinery_breakages.form.status',
      span: 1,
      content: (status && t(`machinery_breakages.form.${status}`)) || '-'
    },
    {
      label: 'machinery_breakages.form.periodic_revision',
      span: 1,
      content:
        (periodic_revision &&
          t(`machinery_breakages.form.${periodic_revision}`)) ||
        '-'
    },
    {
      label: 'machinery_breakages.form.periodic_revision_frequency',
      span: 1,
      content:
        (periodic_revision_frequency &&
          t(`machinery_breakages.form.${periodic_revision_frequency}`)) ||
        '-'
    },
    {
      label: 'machinery_breakages.form.comments',
      span: 1,
      content: (comments && comments) || '-'
    }
  ];

  if (deleted) {
    labels.push({
      label: 'machinery_breakages.form.deleted',
      span: 1,
      content:
        (deletion_date_effect &&
          dayjs(deletion_date_effect).format('DD/MM/YYYY')) ||
        '-'
    });
  }

  return labels;
};
