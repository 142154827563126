export const titlesToTranslate = {
  PROGRAMME: 'Programme',
  SINISTRES: 'Sinistres',
  FLEET: 'Automobiles',
  CIVIL_LIABILITY: 'Responsabilité Civile',
  GOLF: 'Immobilier Golf',
  TRANSPORT: 'Transport',
  PROPERTY: 'Immobilier',
  CONSTRUCTION: 'Construction',
  LIGHT_VEHICLE: 'Véhicule Léger',
  OTHER_VEHICLE: 'Autre Véhicule',
  AUTOMISSION: 'Auto Mission',
  HEAVY_VEHICLE: 'Véhicule Lourd',
  CIVIL_LIABILITY_PRO: 'Responsabilité Civile Pro',
  CIVIL_LIABILITY_EXECUTIVE: 'Responsabilité Civile Dirigeant',
  FREIGHTED_GOODS: 'Marchandises Transportées',
  AD_VALOREM: 'Ad Valorem',
  OWN_ACCOUNT: 'Compte Propre',
  FACULTY: 'Facultés',
  CONSTRUCTION_INSURANCE: 'Assurance Construction',
  PROPERTY_DEVELOPER: 'Promoteur Immobilier',
  GENERAL_CONTRACTOR: 'Entrepreneur Général',
  CONSTRUCTION_COMPANY_AND_ENGINEERING:
    "Entreprise de Construction et d'Ingénierie",
  HOLE_IN_ONE: 'Trou en un',
  CYBER_INSURANCE: 'Cyber',
  EVENT_CANCELLATION: "Annulation d'événement",
  MACHINERY_BREAKAGE: 'Bris machine',
  HARDWARE_INSURANCE: 'Assurance "Multiclic"',
  PROFESSIONAL_MULTIRISK_INSURANCE:
    'Assurance "Multirisque Professionnelle / Entreprise"',
  ASSEMBLY_TESTING_INSURANCE: 'Montage essais',
  LEGAL_PROTECTION_INSURANCE: 'Protection juridique',
  ENVIRONMENT_INSURANCE: 'Assurance Environnement',
  INDIVIDUAL_HOUSES_BUILDER: 'Assurance CMI'
};
