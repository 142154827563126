import { Checkbox } from 'antd';

/**
 * A custom hook that returns predefined field configurations.
 *
 * This configuration can be used in conjunction with form libraries or frameworks
 * that need a set of field definitions to render and validate form controls.
 *
 * @hook
 * @returns {Array<Object>} returns.automissionsFields - Field configurations for automissions.
 */

export const useAutomissionsFields = () => {
  const fieldNames = [
    'missing_documents',
    'contract',
    'date_added',
    'entity',
    'site',
    'address',
    'postal_code',
    'city',
    'products',
    'collaborator_first_name',
    'collaborator_last_name',
    'vehicle_registration_number',
    'departure_time',
    'start_of_the_mission',
    'planned_end_of_the_mission',
    'journey_lenght',
    'departure_city',
    'arrival_city',
    'deletion_date_effect'
  ];

  const automissionsFields = fieldNames.map((name) => ({
    name: [name],
    rules: [{ required: false }],
    valuePropName: 'checked',
    input: <Checkbox />
  }));

  return { automissionsFields };
};
