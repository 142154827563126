import { useEffect, useState } from 'react';
import { Tag } from 'antd';
import { FileExclamationOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { claimStatus } from '../../../utils/constants/tagColors';

/**
 * Generates custom table columns configuration for a claims list.
 *
 * @returns {Array} An array of column configuration objects.
 */
export const useColumns = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState();

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/civil_liability_claims/enums'
      });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return [
    {
      key: 'documents',
      dataIndex: 'missing_documents',
      width: '48px',
      render: (missing_documents) =>
        missing_documents ? (
          <FileExclamationOutlined
            style={{ fontSize: 18, color: 'var(--warningColor)' }}
          />
        ) : null
    },
    {
      title: t('claims.form.entity'),
      key: 'entity',
      dataIndex: ['entity'],
      sorter: true,
      render: (entity) => (entity ? entity.map((e) => e.name).join(', ') : '-')
    },
    {
      title: t('claims.form.site'),
      key: 'site',
      dataIndex: ['site', 'name'],
      sorter: true
    },
    {
      title: t('claims.form.client_reference'),
      key: 'client_reference',
      dataIndex: 'client_reference',
      render: (client_reference) => client_reference || '-',
      sorter: true
    },
    {
      title: t('claims.form.claim_status'),
      key: 'claim_status',
      dataIndex: 'claim_status',
      render: (status) =>
        status ? (
          <Tag color={claimStatus[status]}>
            {t(`claims.form.${status}`, {
              defaultValue: status
            })}
          </Tag>
        ) : (
          <Tag color="orange">{t('claims.form.reception_pending')}</Tag>
        ),
      sorter: true,
      filters: enums?.claimStatus?.map((status) => ({
        text: t(`claims.form.${status}`),
        value: status
      }))
    },
    {
      title: t('claims.form.sinister_date'),
      key: 'sinister_date',
      dataIndex: 'sinister_date',
      render: (sinister_date) =>
        sinister_date ? dayjs(sinister_date).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('claims.form.place_of_occurrence'),
      key: 'place_of_occurrence',
      dataIndex: 'place_of_occurrence',
      sorter: true
    },
    {
      title: t('claims.form.opening_date'),
      key: 'opening_date',
      dataIndex: ['opening_date'],
      render: (opening_date) =>
        opening_date ? dayjs(opening_date).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('claims.form.commentaries'),
      key: 'commentaries',
      dataIndex: ['commentaries'],
      render: (commentaries) => commentaries || '-'
    }
  ];
};
