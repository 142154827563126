import dayjs from 'dayjs';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, DatePicker, Button, InputNumber } from 'antd';
import { SelectEnums } from '../../components/SelectEnums';
import { SelectManager } from '../../../../components/SelectManager';
import { SelectContract } from '../../../../components/SelectContract';
import { SelectEntityV2 } from '../../../../components/SelectEntityV2';
import { SelectSiteV2 } from '../../../../components/SelectSiteV2';
import { HeavyVehiclesContext } from '../HeavyVehiclesContext';
import { SelectSecondaryDrivers } from '../../../../components/SelectSecondaryDrivers';
import { SelectPrincipalDriver } from '../../../../components/SelectPrincipalDriver';

const { TextArea } = Input;

/**
 * Custom hook that provides a configuration for fields specific to a fleet type form.
 *
 * @param {Object} form - AntD form instance.
 * @param {string} fleetType - Type of the fleet (e.g., 'LIGHT_VEHICLE').
 * @returns {Object} An object containing arrays with configurations for rendering fleet-specific form fields.
 */

const useFields = (
  { onAddDriver },
  isTrailer,
  { setIsTrailer },
  currentVehicleData,
  setCurrentVehicleData,
  form
) => {
  const { t } = useTranslation();
  const dateFormat = 'DD-MM-YYYY';
  const {
    currentEntityContext,
    setCurrentEntityContext,
    entitiesContext,
    setEntitiesContext,
    currentContract,
    setCurrentContract
  } = useContext(HeavyVehiclesContext);

  const handleDateChange = (dateToDefine) => (date, dateString) => {
    setCurrentVehicleData({
      ...currentVehicleData,
      [dateToDefine]: dateString
    });
  };

  const handleEntityChange = (value) => {
    setCurrentEntityContext(value);
    form.setFieldsValue({ site: undefined });
  };

  const handleContractChange = () => {
    form.setFieldsValue({ entity: undefined, site: undefined });
  };

  const fieldsHeavyVehicles = [
    {
      noLabel: true,
      name: ['fleet_type'],
      input: <Input hidden />
    },
    {
      label: ['contract_number'],
      name: ['contract'],
      rules: [{ required: true }],
      input: (
        <SelectContract
          dbKey="contract"
          sub_programme="HEAVY_VEHICLE"
          programme_types="FLEET"
          setCurrentContract={setCurrentContract}
          currentEntityContext={currentEntityContext}
          handleContractChange={handleContractChange}
        />
      ),
      startWithDivider: {
        title: t('vehicles.form.main_informations')
      }
    },
    {
      label: ['customer_manager'],
      name: ['customer_manager'],
      rules: [{ required: true }],
      input: <SelectManager dbKey="customer_manager" />
    },
    {
      label: ['unit_manager'],
      name: ['unit_manager'],
      rules: [{ required: false }],
      input: <SelectManager dbKey="unit_manager" />
    },
    {
      label: ['desired_effective_date'],
      name: ['desired_effective_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['entity'],
      name: ['entity'],
      rules: [{ required: true }],
      input: (
        <SelectEntityV2
          dbKey="entity"
          permKey="programme-FLEET-HEAVY_VEHICLE"
          currentContract={currentContract}
          setEntitiesContext={setEntitiesContext}
          handleEntityChange={handleEntityChange}
          currentEntityContext={currentEntityContext}
        />
      )
    },
    {
      label: ['site'],
      name: ['site'],
      rules: [{ required: false }],
      input: (
        <SelectSiteV2
          dbKey="site"
          permKey="programme-FLEET-HEAVY_VEHICLE"
          currentEntityContext={currentEntityContext}
          entitiesContext={entitiesContext}
        />
      )
    },
    {
      label: ['vehicle_type'],
      name: ['heavy_vehicle_type'],
      rules: [{ required: true }],
      input: (
        <SelectEnums
          onChange={(value) =>
            value === 'TRAILER' ? setIsTrailer(true) : setIsTrailer(false)
          }
          dbKey="heavy_vehicle_type"
        />
      )
    },
    {
      label: ['registration_card_category'],
      name: ['registration_card_category'],
      rules: [{ required: true }]
    },
    {
      label: ['vehicle_brand'],
      name: ['vehicle_brand'],
      rules: [{ required: true }]
    },
    {
      label: ['vehicle_model'],
      name: ['vehicle_model'],
      rules: [{ required: true }]
    },
    {
      label: ['registration_or_serial_number'],
      name: ['registration_or_serial_number'],
      rules: [{ required: true }]
    },
    {
      label: ['initial_traffic_release'],
      name: ['initial_traffic_release'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          format={dateFormat}
          style={{ width: '100%' }}
          onChange={handleDateChange('initial_traffic_release')}
        />
      )
    },
    {
      label: ['purchase_date_or_lease_start_date'],
      name: ['purchase_date_or_lease_start_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          format={dateFormat}
          style={{ width: '100%' }}
          minDate={dayjs(
            currentVehicleData.initial_traffic_release,
            dateFormat
          )}
          disabled={!currentVehicleData.initial_traffic_release}
        />
      )
    },
    {
      label: ['mileage'],
      name: ['mileage'],
      input: <InputNumber addonAfter="km" style={{ width: '100%' }} />
    },
    {
      label: ['taxable_power'],
      name: ['taxable_power'],
      input: (
        <InputNumber
          addonAfter={t('vehicles.form.tHp')}
          style={{ width: '100%' }}
        />
      )
    },
    {
      label: ['piston_displacement'],
      name: ['piston_displacement'],
      input: <InputNumber addonAfter="cm³" style={{ width: '100%' }} />
    },
    {
      label: ['observations'],
      name: ['observations'],
      rules: [{ required: false }],
      input: <TextArea />
    },
    {
      name: [' '],
      rules: [{ required: false }],
      input: (
        <Button block onClick={onAddDriver}>
          {t('drivers.form.create_or_modify_driver')}
        </Button>
      ),
      startWithDivider: {
        title: t('drivers.form.drivers')
      }
    },
    {
      label: ['principal_driver'],
      name: ['principal_driver'],
      rules: [{ required: false }],
      input: (
        <SelectPrincipalDriver
          dbKey="principal_driver"
          style={{ resize: 'none' }}
          currentSecundaryDrivers={currentVehicleData?.secondary_drivers}
          setPrincipalDriver={(driver) =>
            setCurrentVehicleData({
              ...currentVehicleData,
              principal_driver: driver
            })
          }
        />
      )
    },
    {
      label: ['secondary_drivers'],
      name: ['secondary_drivers'],
      rules: [{ required: false }],
      input: (
        <SelectSecondaryDrivers
          dbKey="secondary_drivers"
          style={{ resize: 'none' }}
          currentPrincipalDriver={currentVehicleData?.principal_driver}
          setSecondaryDrivers={(driver) =>
            setCurrentVehicleData({
              ...currentVehicleData,
              secondary_drivers: driver
            })
          }
        />
      ),
      endWithDivider: {
        title: t('vehicles.form.documents')
      }
    }
  ];

  return {
    fieldsHeavyVehicles
  };
};
export default useFields;
