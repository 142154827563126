// TODO: testing implementing usecallback so it can be declared in dependencies without triggering infinite loop. Testing ok so far. Remove comment if no issues arise.
import { useCallback } from 'react';
import { useErrorMessage } from './errorMessage';
import { useAuthContext } from '../contexts/AuthContext';

/**
 * Retrieves the enums for a given resource.
 * @hook
 * @description You have to call this function with the name of the route to get the enums. (ex: 'users')
 * @returns {Object} An object containing the `getEnums` function.
 */
export const useGetEnums = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const getEnums = useCallback(
    async (resource) => {
      if (!resource) return null;
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/${resource}/enums`
        });

        if (data) {
          return data;
        }

        return null;
      } catch (error) {
        if (error.response) {
          return message(error.response.status);
        }
        return message(error);
      }
    },
    [dispatchAPI, message]
  );

  return { getEnums };
};

// export const useGetEnums = () => {
//   const { dispatchAPI } = useAuthContext();
//   const { message } = useErrorMessage();

//   const getEnums = async (resource) => {
//     if (!resource) return null;
//     try {
//       const { data } = await dispatchAPI('GET', {
//         url: `/${resource}/enums`
//       });

//       if (data) {
//         return data;
//       }

//       return null;
//     } catch (error) {
//       if (error.response) {
//         return message(error.response.status);
//       }
//       return message(error);
//     }
//   };

//   return { getEnums };
// };
