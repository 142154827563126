import { Checkbox } from 'antd';

/**
 * A custom hook that returns predefined field configurations.
 *
 * This configuration can be used in conjunction with form libraries or frameworks
 * that need a set of field definitions to render and validate form controls.
 *
 * @hook
 * @returns {Array<Object>} returns.constructionsFields - Field configurations for constructions.
 */

export const useConstructionsFields = () => {
  const fieldNames = [
    'missing_documents',
    'company_name',
    'contract',
    'date_added',
    'entity',
    'status',
    'postal_code',
    'city',
    'desired_guarantees',
    'subscriber',
    'contracting_owner_if_different_from_the_subscriber',
    'construction_site_name',
    'construction_permit_number',
    'construction_site_address',
    'expected_construction_cost',
    'expected_construction_starting_date',
    'construction_starting_date',
    'expected_construction_completion_date',
    'construction_completion_date',
    'amendment_new_construction_cost',
    'reason_of_amendment_demand',
    'rental_loss_guarantee',
    'comments',
    'deletion_date_effect'
  ];

  const constructionsFields = fieldNames.map((name) => ({
    name: [name],
    rules: [{ required: false }],
    valuePropName: 'checked',
    input: <Checkbox />
  }));

  return { constructionsFields };
};
