import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton, Row, Col, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useListContent } from './utils/listContentAutomissions';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../../components';
import { Documents } from '../../../components/Documents';
import { History } from '../../../components/History';
import { useExtraButtons } from './extraButtons';

/**
 * The `AutomissionShow` component displays details about a specific automission.
 *
 * The component fetches and displays comprehensive details of an automission, identified by its ID.
 * It provides capabilities to edit or delete the current automission and to view associated documents.
 *
 * It uses the `useParams` hook to get the ID of the automission from the current route.
 * The `useNavigate` hook is used for navigation upon certain actions (e.g., after deleting the automission).
 * It also incorporates translation capabilities with the `useTranslation` hook.
 *
 * @component
 *
 *
 * @returns {JSX.Element} The rendered `AutomissionShow` component which displays the automission's details, documents, and associated drivers.
 */

export const AutomissionShow = () => {
  const draggerFilesKeysOverRide = [
    'collaborator_vehicle_registration_document',
    'collaborator_driving_licence'
  ];
  const draggerFilesKeysMandatory = ['collaborator_driving_licence'];
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [vehicle, setVehicle] = useState();
  const [isDeleted, setIsDeleted] = useState(false);
  const listContent = useListContent(vehicle);
  const { extraButtons } = useExtraButtons(isDeleted, vehicle);

  const getVehicle = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/vehicles/${id}?populate=customer_manager,unit_manager,contract,entity,entity.company,site`
      });
      setVehicle(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getVehicle();
    })();
  }, [getVehicle]);

  useEffect(() => {
    if (vehicle) setIsDeleted(vehicle?.deleted);
  }, [vehicle]);

  return (
    <>
      <PageHeaderCustom
        title={`${t('number')} ${vehicle?.unit_counter || 'xxxx'} - ${t(
          'automissions.title'
        )}
        ${vehicle?.collaborator_first_name || ''} ${
          vehicle?.collaborator_last_name || ''
        }`}
        extra={extraButtons}
      />
      <ContentCustom>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={12}>
            <Skeleton loading={isLoading} title={0} paragraph={0} active />
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <Card>
                <DescriptionList
                  data={listContent}
                  translate
                  layout="vertical"
                  title={t('vehicles.form.main_informations')}
                />
              </Card>
            </Skeleton>
          </Col>
          <Col xs={24} xl={12}>
            <Documents
              idWithoutParams={id}
              baseUrl="vehicles"
              resource="Vehicle"
              draggerFilesKeysOverRide={draggerFilesKeysOverRide}
              draggerFilesKeysMandatory={draggerFilesKeysMandatory}
            />
            <Card title={t('subscriptions.form.history')} size="small">
              <History route="Vehicle" />
            </Card>
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};
