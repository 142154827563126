import React, { useEffect, useState, useRef } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useSocketContext } from '../../contexts/SocketContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { Alerts } from './AlertCard';

/**
 * @component
 * @description Notification dashboard
 * @returns {React.ReactNode} Notification dashboard
 */
export const NotificationDashboard = () => {
  const isMounted = useRef(false);
  const { t } = useTranslation();
  const { dispatchAPI, company, companyName } = useAuthContext();
  const { notifications, loading, setLoading } = useSocketContext();
  const { message } = useErrorMessage();
  const [datas, setDatas] = useState({
    vehicule: [],
    golfs: [],
    property: [],
    transportData: [],
    eventCancellation: [],
    holeInOne: [],
    machineryBreakage: [],
    callForContribution: []
  });

  const getEventCancellation = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `event-cancellations?company=${company}&populate=entity,site`
      });
      return data;
    } catch (e) {
      message(e);
      return [];
    }
  };

  const getHoleInOne = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `hole-in-ones?company=${company}&populate=entity,site`
      });
      return data;
    } catch (e) {
      message(e);
      return [];
    }
  };

  const getMachineryBreakage = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `machinery-breakages?company=${company}&populate=entity,site`
      });
      return data;
    } catch (e) {
      message(e);
      return [];
    }
  };

  const getVehicule = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `vehicle?company=${company}&populate=entity,entity.sites`
      });
      return data;
    } catch (e) {
      message(e);
      return [];
    }
  };

  const getProperty = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `property?company=${company}`
      });
      return data;
    } catch (e) {
      message(e);
      return [];
    }
  };

  const getGolfs = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `golfs?company=${company}&populate=entity,entity.sites`
      });
      return data;
    } catch (e) {
      message(e);
      return [];
    }
  };

  const getTransports = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/transports?company=${company}`
      });
      return data;
    } catch (e) {
      message(e);
      return [];
    }
  };

  const getCallForContribution = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `call-for-contributions?company_Id=${company}&populate=entity,site`
      });
      return data;
    } catch (e) {
      message(e);
      return [];
    }
  };

  useEffect(() => {
    isMounted.current = true;
    setLoading(true);

    Promise.all([
      getVehicule(),
      getGolfs(),
      getTransports(),
      getProperty(),
      getEventCancellation(),
      getHoleInOne(),
      getMachineryBreakage(),
      getCallForContribution()
    ])
      .then(
        ([
          vehiculeData,
          golfsData,
          transportData,
          propertyData,
          eventCancellationData,
          holeInOneData,
          machineryBreakageData,
          callForContributionData
        ]) => {
          if (isMounted.current) {
            setDatas({
              vehicule: vehiculeData,
              golfs: golfsData,
              transports: transportData,
              property: propertyData,
              eventCancellation: eventCancellationData,
              holeInOne: holeInOneData,
              machineryBreakage: machineryBreakageData,
              callForContribution: callForContributionData
            });
          }
        }
      )
      .catch((e) => {
        if (isMounted.current) {
          message(e);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setLoading(false);
        }
      });
    return () => {
      isMounted.current = false;
    };
  }, [notifications]);

  return (
    <>
      <PageHeaderCustom title={`${t('home.title')} ${companyName}`} />
      <ContentCustom>
        <Row gutter={[24, 24]}>
          <Col xs={24} lg={12}>
            <Alerts loading={loading} datas={datas} notifclass="alert" />
          </Col>
          <Col xs={24} lg={12}>
            <Alerts loading={loading} datas={datas} notifclass="gestionnaire" />
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};
