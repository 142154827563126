import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { Select, Form } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const { Option } = Select;
const { Item } = Form;

/**
 * A component for selecting secondary drivers.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.dbKey - The key for the database.
 * @param {function} props.setSecondaryDrivers - The function to set the selected secondary drivers.
 * @param {string} props.currentPrincipalDriver - The ID of the current principal driver.
 * @returns {JSX.Element} - The JSX element representing the select secondary drivers component.
 */
export const SelectSecondaryDrivers = ({
  dbKey,
  setSecondaryDrivers,
  currentPrincipalDriver
}) => {
  const { t } = useTranslation();
  const [drivers, setDrivers] = useState([]);
  const { company, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const getDrivers = async () => {
    try {
      const filters = company ? `?company_Id=${company}` : '';
      const { data } = await dispatchAPI('GET', { url: `/drivers${filters}` });
      const filteredData = data.filter(
        (driver) => driver._id !== currentPrincipalDriver
      );
      setDrivers(filteredData);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getDrivers();
    })();
  }, [currentPrincipalDriver]);

  return (
    <Item name={[`${dbKey}`]}>
      <Select
        placeholder={t('drivers.form.select_driver')}
        mode="multiple"
        showSearch
        allowClear
        onDropdownVisibleChange={getDrivers}
        onChange={(value) => {
          setSecondaryDrivers(value);
        }}
        filterOption={(input, option) =>
          (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
        }
      >
        {drivers &&
          drivers.map((d) => (
            <Option key={d._id} value={d._id}>
              {`${d.first_name} ${d.last_name}`}
            </Option>
          ))}
      </Select>
    </Item>
  );
};

SelectSecondaryDrivers.propTypes = {
  dbKey: PropTypes.string.isRequired,
  setSecondaryDrivers: PropTypes.func.isRequired,
  currentPrincipalDriver: PropTypes.string.isRequired
};
