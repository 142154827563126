import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCategories } from './utils/categories';

/**
 * A hook that generates an array of field configurations. These configurations
 * are used for form fields and include details like field names, labels, and validation rules.
 * The fields are meant for contacts, categorized under 'customer_managers' and 'claims_managers'.
 *
 * @hook
 * @returns {Object} Contains an array of field configurations.
 * @property {Array<Object>} fields - An array of field configurations.
 */

export const useFields = () => {
  const { t } = useTranslation();
  const { existingCategories } = useCategories();

  const fields1 = existingCategories.map((category) => [
    {
      name: [`${category}_contacts_mail_customer`],
      label: [`${category}_contacts_mail`],
      rules: [{ type: 'email' }]
    },
    {
      name: [`${category}_contacts_phone_customer`],
      label: [`${category}_contacts_phone`]
    }
  ]);

  const fields2 = existingCategories.map((category) => [
    {
      name: [`${category}_contacts_mail_claim`],
      label: [`${category}_contacts_mail`],
      rules: [{ type: 'email' }]
    },
    {
      name: [`${category}_contacts_phone_claim`],
      label: [`${category}_contacts_phone`]
    }
  ]);

  const fields = [
    {
      name: ['div1'],
      label: ['divider_1'],
      input: <Divider>{t('contacts.customer_managers')}</Divider>
    },
    ...fields1.flat(),
    {
      name: ['div2'],
      label: ['divider_2'],
      input: <Divider>{t('contacts.claims_managers')}</Divider>
    },
    ...fields2.flat()
  ];

  return {
    fields
  };
};
