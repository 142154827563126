import { Route, Routes } from 'react-router-dom';
import { ListEventCancellation } from './ListEventCancellation';
import { EventCancellationCreateUpdate } from './EventCancellationCreateUpdate';
import { EventCancellationShow } from './EventCancellationShow';
import { Exception } from '../../../components';
import { EventCancellationProvider } from './EventCancellationContext';

export const EventCancellationRouter = () => (
  <EventCancellationProvider>
    <Routes>
      <Route
        path="create"
        element={<EventCancellationCreateUpdate purpose="create" />}
      />
      <Route
        path="edit/:id"
        element={<EventCancellationCreateUpdate purpose="edit" />}
      />
      <Route path="show/:id" element={<EventCancellationShow />} />
      <Route index element={<ListEventCancellation />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </EventCancellationProvider>
);
