import PropTypes from 'prop-types';
import * as XLSX from 'xlsx';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Upload, Button, Spin, Alert, message, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * `ImportXlsxButton` is a component for uploading and importing data from an XLSX file.
 *
 * @component
 * @param {string} importUrl - The URL where the imported data will be sent.
 * @returns {JSX.Element} A button component that allows users to upload and import data from an XLSX file.
 */

export const ImportXlsxButton = ({ importUrl, setForceRefresh }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [errorFromBackend, setErrorFromBackend] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const sendData = async (value, onSuccess, onError) => {
    try {
      const response = await dispatchAPI('POST', {
        url: importUrl,
        body: value
      });
      if (response) {
        onSuccess();
      }
    } catch (e) {
      onError(e.response.data);
      setErrorFromBackend(e.response.data.message || e.response.data);
      setIsUploading(false);
      setIsOpen(true);
    }
  };

  const handleFileChange = (info) => {
    if (info.file.status === 'uploading') {
      setIsUploading(true);
    } else if (info.file.status === 'done') {
      setIsUploading(false);
      message.success(`${info.file.name} file uploaded successfully`, 5);
      setIsSuccess(true);
    }
  };

  const customFileUpload = ({ onSuccess, onError, file }) => {
    const reader = new FileReader();

    reader.onload = (evt) => {
      const data = new Uint8Array(evt.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      sendData(jsonData, onSuccess, onError);
    };
    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    if (isSuccess) {
      setForceRefresh(true);
      setIsSuccess(false);
    }
  }, [isSuccess]);

  return (
    <>
      {isUploading ? (
        <Modal>
          <Spin size="large">
            <Alert message="Alert message title" type="info" />
          </Spin>
        </Modal>
      ) : null}
      <Upload
        customRequest={customFileUpload}
        showUploadList={false}
        onChange={handleFileChange}
      >
        <Button type="link">
          <UploadOutlined style={{ fontSize: '14px', marginRight: 8 }} />
          {t('buttons.upload')}
        </Button>
      </Upload>
      <Modal
        title="Error"
        open={isOpen}
        onOk={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
      >
        <p>{errorFromBackend}</p>
      </Modal>
    </>
  );
};

ImportXlsxButton.propTypes = {
  importUrl: PropTypes.string,
  setForceRefresh: PropTypes.func.isRequired
};

ImportXlsxButton.defaultProps = {
  importUrl: null
};
