import { Route, Routes } from 'react-router-dom';
import { Exception } from '../../../components';
import { LegalProtectionList } from './LegalProtectionList';
import { LegalProtectionShow } from './LegalProtectionShow';
import { LegalProtectionCreateUpdate } from './LegalPortectionCreateUpdate';

/**
 * A router component for handling hardware insurance related routes.
 *
 * @component
 * @returns {JSX.Element} The JSX element representing the HardwareInsuranceRouter.
 */

export const LegalProtectionRouter = () => (
  <Routes>
    <Route
      path="edit/:id"
      element={<LegalProtectionCreateUpdate purpose="edit" />}
    />
    <Route path="show/:id" element={<LegalProtectionShow />} />
    <Route index element={<LegalProtectionList />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
