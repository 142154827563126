import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Col, Modal, Row, Spin, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useColumns } from '../../documents/columnsDocuments';
import { useActionColumn } from '../../documents/actionColumn';
import { ListResource } from '../../../components/ListResource/ListResource';
import { SelectByEntity } from '../../components/SelectByEntity';
import { SelectTypeAcountancy } from './SelectTypeAcountancy';
import { useAuthContext } from '../../../contexts/AuthContext';
import { EntitiesDisplay } from '../../documents/utils/EntitiesDisplay';

/**
 * Represents the `ListDocumentsHome` component that displays a list of documents.
 *
 * It provides filtering options based on entity and document type. Filter changes result in a
 * navigation update, changing the URL to reflect the applied filters.
 *
 * The list can be extended with custom action columns. Data for the documents is fetched based on the company
 * in the auth context.
 *
 * @component
 * @returns {React.ElementType} A list of documents.
 */

export const ListDocumentsHome = () => {
  const { t } = useTranslation();
  const { company, permissions, verifPermissions, dispatchAPI, user } =
    useAuthContext();
  const accountancyExtraQuery = `company_Id=${company}&document_type=ATTESTATION,CALL_FOR_CONTRIBUTION`;
  const navigate = useNavigate();
  const columns = useColumns();

  const [loaded, setLoaded] = useState(false);
  const [entity, setEntity] = useState('');
  const [extraFilter, setExtraFilter] = useState('');
  const [show, setShow] = useState(true);
  const [edit, setEdit] = useState(true);
  const [extraQuery, setExtraQuery] = useState(accountancyExtraQuery);
  const [isLoading, setIsLoading] = useState(true);

  let filterParameter = `entity=${entity}__`;

  const generateAttestation = async (id) => {
    const modal = Modal.info({
      content: (
        <Row
          style={{ flexDirection: 'column' }}
          justify="center"
          align="middle"
        >
          <Col>
            <Spin />
          </Col>
          <Col>{t('files.modal.document-loading')}</Col>
        </Row>
      ),
      footer: null,
      maskClosable: false
    });
    try {
      const { data, headers } = await dispatchAPI('GET', {
        url: `documents/attestation/${id}`,
        responseType: 'arraybuffer'
      });
      const blob = new Blob([data], {
        type: headers['content-type']
      });
      modal.destroy();
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = headers['content-disposition'];
      document.body.appendChild(a);

      a.click();

      window.URL.revokeObjectURL(url);
    } catch (e) {
      modal.destroy();
      message.error(e);
    }
  };
  const actionColumn = useActionColumn(edit, show, generateAttestation);

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setShow(
        !verifPermissions(
          'documents/contract/show',
          permissions?.notPermittedRoutes
        )
      );
      setEdit(
        !verifPermissions(
          'documents/contract/edit',
          permissions?.notPermittedRoutes
        )
      );
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [permissions]);

  const onFilterEntity = (entityId) => {
    setEntity(entityId || '');
  };

  const onFilterType = (typeId) => {
    setExtraQuery(
      typeId
        ? `company_Id=${company}&document_type=${typeId}`
        : accountancyExtraQuery
    );
  };

  useEffect(() => {
    filterParameter = `entity=${entity}__`;
    setExtraFilter(filterParameter);
    navigate({
      pathname: '/',
      search: `?f=${filterParameter}`
    });
  }, [entity]);

  const renderEntitiesDisplay = (record) => <EntitiesDisplay record={record} />;

  return (
    <>
      <Spin spinning={isLoading} fullscreen="true" />
      <ListResource
        extraQuery={extraQuery}
        dataToFetch={`documents/user/${user._id}`}
        resourceName="documents"
        customActionColumn
        columns={[...columns, ...actionColumn]}
        withExtraFilters={
          <>
            <SelectByEntity onChange={onFilterEntity} isDocumentFilter />
            <SelectTypeAcountancy onChange={onFilterType} />
          </>
        }
        setLoaded={() => setLoaded()}
        loaded={loaded}
        populate="entity,file,previous_amendment"
        withCreateButton={edit}
        withUploadButton={edit}
        onDoubleClickAction={false}
        expandable={{
          expandedRowRender: (record) => renderEntitiesDisplay(record)
        }}
        extraFilter={extraFilter}
      />
    </>
  );
};
