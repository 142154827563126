import { Checkbox } from 'antd';

/**
 * A custom hook that returns predefined field configurations.
 *
 * This configuration can be used in conjunction with form libraries or frameworks
 * that need a set of field definitions to render and validate form controls.
 *
 * @hook
 * @returns {Array<Object>} returns.otherVehiclesFields - Field configurations for other vehicles types.
 */

export const useOtherVehiclesFields = () => {
  const fieldNames = [
    'missing_documents',
    'contract',
    'created_at',
    'entity',
    'site',
    'address',
    'postal_code',
    'city',
    'other_vehicle_type',
    'vehicle_brand',
    'registration_or_serial_number',
    'initial_traffic_release',
    'vehicle_model',
    'principal_driver',
    'purchase_date_or_lease_start_date',
    'mileage',
    'observations',
    'desired_effective_date',
    'deletion_date_effect'
  ];

  const otherVehiclesFields = fieldNames.map((name) => ({
    name: [name],
    rules: [{ required: false }],
    valuePropName: 'checked',
    input: <Checkbox />
  }));

  return { otherVehiclesFields };
};
