import { useCallback, useEffect, useState, forwardRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Row, Spin } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ContentCustom } from '../ContentCustom/ContentCustom';
import { PageHeaderCustom } from '../PageHeader/PageHeader';
import { useGenerateFormItem } from '../../utils/generateFormItem';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../utils/constants/formLayout';

/**
 * A component for creating or updating resources.
 *
 * @component
 * @param {string} purpose - The purpose of the container ('create' or 'edit').
 * @param {Array} fields - An array of field configurations for the form.
 * @param {string} baseUrl - The base URL for the resource.
 * @param {string} resource - The name of the resource.
 * @param {boolean} loadingFields - Indicates whether fields are being loaded.
 * @param {Object} config - Configuration options for resource operations.
 * @param {Function} formExtra - Additional content to render within the form.
 * @param {string} tradKey - The translation key for the resource.
 * @param {string} submitLabel - The label for the submit button.
 * @param {Function} customSubmit - A custom submit function.
 * @param {boolean} isParentLoading - Indicates if the parent component is loading.
 * @param {React.Element} extraTitle - Additional title content.
 * @param {Function} setDriverId - Callback function to set the driver ID.
 * @param {string} idWithoutParams - The ID without URL parameters.
 * @param {Function} setIsPrincipal - Callback function to set if it's the principal.
 * @param {Function} setIsOpen - Callback function to set the open state.
 * @returns {React.Element} The CreateUpdateContainer component.
 */

export const CreateUpdateContainer = forwardRef(
  (
    {
      purpose,
      fields,
      loadingFields,
      resource,
      baseUrl,
      config,
      formExtra,
      tradKey,
      submitLabel,
      customSubmit,
      isParentLoading,
      extraTitle,
      idWithoutParams,
      setIsOpen
    },
    ref
  ) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { message } = useErrorMessage();
    const { dispatchAPI } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const generateFields = useGenerateFormItem();
    const [form] = Form.useForm();
    const { onGetResource, onCreateResource, onUpdateResource } = config;

    const updateResource = async (body) => {
      setIsSubmitting(true);
      try {
        await dispatchAPI('PATCH', {
          url: `${baseUrl}/${idWithoutParams || id}`,
          body:
            onUpdateResource && onUpdateResource.setBody
              ? onUpdateResource.setBody(body)
              : body
        });
        if (baseUrl !== 'drivers') {
          navigate(-1);
        } else {
          setIsOpen(false);
        }
      } catch (e) {
        setIsSubmitting(false);
        message(e);
      }
    };

    const createResource = async (body) => {
      setIsSubmitting(true);
      try {
        await dispatchAPI('POST', {
          url: `${baseUrl}`,
          body:
            onCreateResource && onCreateResource.setBody
              ? onCreateResource.setBody(body)
              : body
        });
        if (baseUrl !== 'drivers') {
          navigate(-1);
        } else {
          setIsOpen(false);
        }
      } catch (e) {
        setIsSubmitting(false);
        message(e);
      }
    };

    const getResource = useCallback(async () => {
      setIsLoading(true);
      try {
        const { data } = await dispatchAPI('GET', {
          url: `${baseUrl}/${idWithoutParams || id}`
        });
        form.setFieldsValue(
          onGetResource && onGetResource.setFields
            ? onGetResource.setFields(data)
            : data
        );
      } catch (e) {
        message(e);
      }
      setIsLoading(false);
    }, [purpose, id, loadingFields, baseUrl]);

    useEffect(() => {
      if (purpose === 'edit' && (id || idWithoutParams)) {
        setIsLoading(true);
        if (!loadingFields)
          (async () => {
            await getResource();
          })();
      }
    }, [getResource, idWithoutParams]);

    const handleSubmit = async (values) => {
      if (customSubmit) customSubmit(values);
      else {
        if (purpose === 'edit') await updateResource(values);
        if (purpose === 'create') await createResource(values);
      }
    };

    return (
      <>
        <PageHeaderCustom title={t(`${resource}.form.title.${purpose}`)} />
        {extraTitle}
        <ContentCustom>
          <Spin spinning={isLoading || isParentLoading}>
            <Form
              ref={ref}
              {...formItemLayout}
              onFinish={handleSubmit}
              form={form}
            >
              {fields.map((field) =>
                generateFields(tradKey || resource, field)
              )}
              {formExtra}
              <Form.Item {...tailFormItemLayout}>
                <Row justify="end">
                  <Button
                    style={{ margin: '0 10px' }}
                    type="link"
                    danger
                    onClick={() => navigate(-1)}
                  >
                    {`${t('buttons.cancel')} `}
                    <CloseOutlined />
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isSubmitting}
                  >
                    {`${t(submitLabel || 'buttons.save')} `}
                    <CheckOutlined />
                  </Button>
                </Row>
              </Form.Item>
            </Form>
          </Spin>
        </ContentCustom>
      </>
    );
  }
);

CreateUpdateContainer.propTypes = {
  purpose: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  baseUrl: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  loadingFields: PropTypes.bool,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    }),
    onCreateResource: PropTypes.shape({
      setBody: PropTypes.func
    }),
    onUpdateResource: PropTypes.shape({
      setBody: PropTypes.func
    })
  }),
  formExtra: PropTypes.element,
  tradKey: PropTypes.string,
  submitLabel: PropTypes.string,
  customSubmit: PropTypes.func,
  isParentLoading: PropTypes.bool,
  extraTitle: PropTypes.element,
  setDriverId: PropTypes.func,
  idWithoutParams: PropTypes.string,
  setIsPrincipal: PropTypes.func,
  setIsOpen: PropTypes.func
};

CreateUpdateContainer.defaultProps = {
  config: {},
  loadingFields: false,
  formExtra: null,
  tradKey: null,
  submitLabel: null,
  customSubmit: null,
  isParentLoading: false,
  extraTitle: null,
  setDriverId: null,
  idWithoutParams: null,
  setIsPrincipal: null,
  setIsOpen: null
};
