import { useState } from 'react';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

/**
 * Provides utility functions for managing claim status changes.
 *
 * @param {Function} message - The function to display error messages.
 * @returns {Object} An object containing current status, setCurrent function, and onChange function.
 */
export const handleStatusUtils = () => {
  const { dispatchAPI } = useAuthContext();
  const [current, setCurrent] = useState(0);
  const { message } = useErrorMessage();

  const claimStep = {
    0: 'RECEIVED_DECLARATION',
    1: 'INFORMATIONS_WAITING',
    2: 'EXPERTISE_IN_PROGRESS',
    3: 'UNDERSTUDY_COMPENSATION',
    4: 'APPEAL',
    5: 'CLOSED'
  };

  const onChange = async (value, id) => {
    setCurrent(value);
    try {
      await dispatchAPI('PATCH', {
        url: `golf_claims/${id}`,
        body: JSON.stringify({
          values: JSON.stringify({
            step: value,
            claim_status: claimStep[value]
          })
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } catch (e) {
      message(e);
    }
  };
  return {
    current,
    setCurrent,
    onChange
  };
};
