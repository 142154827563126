import { Link } from 'react-router-dom';
import { Flex, Tooltip } from 'antd';
import { EyeOutlined, EditOutlined } from '@ant-design/icons';
import { Flood } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';

const iconSize = 18;

/**
 * Custom hook that returns an array of action column configuration for a table.
 * @hook
 * @param {boolean} edit - Flag indicating whether edit actions should be included.
 * @returns {Array} - Array of action column configuration objects.
 */
export const useActionColumn = (edit) => {
  const { t } = useTranslation();
  const programString = 'individual-houses-builders';

  return [
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Flex gap="small" align="center" justify="end" wrap="nowrap">
          <Link to={`/programmes/${programString}/show/${record?._id}`}>
            <Tooltip title={t('construction.actions.show')}>
              <EyeOutlined style={{ fontSize: iconSize }} />
            </Tooltip>
          </Link>
          {edit && (
            <Link to={`/programmes/${programString}/edit/${record?._id}`}>
              <Tooltip title={t('construction.actions.edit')}>
                <EditOutlined style={{ fontSize: iconSize }} />
              </Tooltip>
            </Link>
          )}
          <Link to={`/claims/${programString}/create/${record?._id}`}>
            <Tooltip title={t('construction.actions.create_claim')}>
              <Flood size={iconSize} />
            </Tooltip>
          </Link>
        </Flex>
      )
    }
  ];
};
