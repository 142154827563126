import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { outOfNavRoutes, routes } from '../utils/constants/accountancyRoutes';
import { Login } from '../routes/login';
import { Exception } from '../components';
import RequireAuth from './RequireAuth';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { LegalNotice } from '../routes/legal-notice/LegalNotice';
import { AdminTheme } from '../assets/styles/Theme/AdminTheme';
import { AgricultureCreateUpdate } from '../routes/agriculture/AgricultureCreateUpdate';
import { MessagesRouter } from '../routes/messages/MessagesRouter';
import InterruptionGolf from '../routes/interruption-golf/Interruption-golf';
import { ListDocumentsHome } from '../routes/home/accountancyHome/ListDocumentsHome';

/**
 * Renders the Accountancy Router component.
 * This component handles the routing for the accountancy section of the application.
 * @component
 * @returns {JSX.Element} The Accountancy Router component.
 */
export const AccountancyRouter = () => {
  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'admin';
    }
  });
  return (
    <BrowserRouter>
      <AdminTheme />
      <Routes>
        <Route
          path={`${outOfNavRoutes.AGRICULTURE}/*`}
          element={<AgricultureCreateUpdate purpose="create" />}
        />
        <Route
          path={`${outOfNavRoutes.INTERRUPTION_GOLF}/*`}
          element={<InterruptionGolf purpose="create" />}
        />
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route element={<RequireAuth />}>
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          <Route path={`${routes.MESSAGES}/*`} element={<MessagesRouter />} />
          <Route path={`${outOfNavRoutes.LEGAL}/*`} element={<LegalNotice />} />
          <Route path={routes.DOCUMENTS} element={<ListDocumentsHome />} />
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
