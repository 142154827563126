import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

/**
 * Produces a list content for displaying detailed event cancellation information.
 *
 * This hook extracts specified properties from the provided data, formats them
 * and returns an array of objects suitable for rendering in a description list.
 *
 * @hook
 * @param {Object} data - event cancellation data to process.
 * @returns {Array<Object>} - Formatted list content for rendering.
 *
 */

export const useListContentMainInformations = (data = {}) => {
  const { t } = useTranslation();
  const {
    contract,
    company,
    created_at,
    entity,
    site,
    customer_manager,
    unit_manager,
    desired_effective_date,
    company_involved_name,
    company_registration_number,
    company_address,
    postal_code,
    city,
    country,
    event_name,
    event_type,
    event_start_date,
    event_end_date,
    is_event_ran_in_permanent_installation_with_roof,
    permanent_installation_type_if_permanent_installation_with_roof,
    is_event_ran_outdoors,
    is_event_ran_outdoors_withpermanent_installation,
    is_a_stage_used,
    is_a_stage_with_roof,
    is_a_stage_with_three_closed_sides,
    is_a_stage_facing_dominant_winds,
    is_special_equipment_used_including_fireworks,
    operator_name,
    attendance_capacity_or_expected_attendance,
    is_event_location_guarded,
    if_guarded_guarding_means,
    amount_engaged,
    public_subsidies_to_substract,
    total_guarantee_amount,
    estimated_income,
    deleted,
    deletion_date_effect
  } = data;
  const customerManagerName = customer_manager
    ? `${customer_manager.first_name} ${customer_manager.last_name}`
    : '-';
  const unit_managerName = unit_manager
    ? `${unit_manager.first_name} ${unit_manager.last_name}`
    : '-';

  const labels = [
    {
      label: 'event_cancellations.form.contract_number',
      span: 1,
      content: (contract && contract.contract_number) || '-'
    },
    {
      label: 'event_cancellations.form.company_name',
      span: 1,
      content: (company?.name && company.name) || '-'
    },
    {
      label: 'event_cancellations.form.date_added',
      span: 1,
      content: (created_at && dayjs(created_at).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'event_cancellations.form.entity',
      span: 1,
      content: (entity && entity.name) || '-'
    },
    {
      label: 'event_cancellations.form.site',
      span: 1,
      content: (site && site.name) || '-'
    },
    {
      label: 'event_cancellations.form.customer_manager',
      span: 1,
      content: (customerManagerName && customerManagerName) || '-'
    },
    {
      label: 'event_cancellations.form.unit_manager',
      span: 1,
      content: (unit_managerName && unit_managerName) || '-'
    },
    {
      label: 'event_cancellations.form.desired_effective_date',
      span: 1,
      content:
        (desired_effective_date &&
          dayjs(desired_effective_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'event_cancellations.form.validity_end_date',
      span: 1,
      content:
        (contract?.validity_end_date &&
          dayjs(contract?.validity_end_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'event_cancellations.form.company_involved_name',
      span: 1,
      content: (company_involved_name && company_involved_name) || '-'
    },
    {
      label: 'event_cancellations.form.company_registration_number',
      span: 1,
      content:
        (company_registration_number && company_registration_number) || '-'
    },
    {
      label: 'event_cancellations.form.company_address',
      span: 1,
      content: (company_address && company_address) || '-'
    },
    {
      label: 'event_cancellations.form.postal_code',
      span: 1,
      content: (postal_code && postal_code) || '-'
    },
    {
      label: 'event_cancellations.form.city',
      span: 1,
      content: (city && city) || '-'
    },
    {
      label: 'event_cancellations.form.country',
      span: 1,
      content: (country && country) || '-'
    },
    {
      label: 'event_cancellations.form.event_name',
      span: 1,
      content: (event_name && event_name) || '-'
    },
    {
      label: 'event_cancellations.form.event_type',
      span: 1,
      content: (event_type && event_type) || '-'
    },
    {
      label: 'event_cancellations.form.event_start_date',
      span: 1,
      content:
        (event_start_date && dayjs(event_start_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'event_cancellations.form.event_end_date',
      span: 1,
      content:
        (event_end_date && dayjs(event_end_date).format('DD/MM/YYYY')) || '-'
    },
    {
      label:
        'event_cancellations.form.is_event_ran_in_permanent_installation_with_roof',
      span: 1,
      content:
        (is_event_ran_in_permanent_installation_with_roof &&
          t(
            `event_cancellations.form.${is_event_ran_in_permanent_installation_with_roof}`
          )) ||
        '-'
    },
    {
      label:
        'event_cancellations.form.permanent_installation_type_if_permanent_installation_with_roof',
      span: 1,
      content:
        (permanent_installation_type_if_permanent_installation_with_roof &&
          permanent_installation_type_if_permanent_installation_with_roof) ||
        '-'
    },
    {
      label: 'event_cancellations.form.is_event_ran_outdoors',
      span: 1,
      content:
        (is_event_ran_outdoors &&
          t(`event_cancellations.form.${is_event_ran_outdoors}`)) ||
        '-'
    },
    {
      label:
        'event_cancellations.form.is_event_ran_outdoors_withpermanent_installation',
      span: 1,
      content:
        (is_event_ran_outdoors_withpermanent_installation &&
          t(
            `event_cancellations.form.${is_event_ran_outdoors_withpermanent_installation}`
          )) ||
        '-'
    },
    {
      label: 'event_cancellations.form.is_a_stage_used',
      span: 1,
      content:
        (is_a_stage_used && t(`event_cancellations.form.${is_a_stage_used}`)) ||
        '-'
    },
    {
      label: 'event_cancellations.form.is_a_stage_with_roof',
      span: 1,
      content:
        (is_a_stage_with_roof &&
          t(`event_cancellations.form.${is_a_stage_with_roof}`)) ||
        '-'
    },
    {
      label: 'event_cancellations.form.is_a_stage_with_three_closed_sides',
      span: 1,
      content:
        (is_a_stage_with_three_closed_sides &&
          t(
            `event_cancellations.form.${is_a_stage_with_three_closed_sides}`
          )) ||
        '-'
    },
    {
      label: 'event_cancellations.form.is_a_stage_facing_dominant_winds',
      span: 1,
      content:
        (is_a_stage_facing_dominant_winds &&
          t(`event_cancellations.form.${is_a_stage_facing_dominant_winds}`)) ||
        '-'
    },
    {
      label:
        'event_cancellations.form.is_special_equipment_used_including_fireworks',
      span: 1,
      content:
        (is_special_equipment_used_including_fireworks &&
          t(
            `event_cancellations.form.${is_special_equipment_used_including_fireworks}`
          )) ||
        '-'
    },
    {
      label: 'event_cancellations.form.operator_name',
      span: 1,
      content: (operator_name && operator_name) || '-'
    },
    {
      label:
        'event_cancellations.form.attendance_capacity_or_expected_attendance',
      span: 1,
      content:
        (attendance_capacity_or_expected_attendance &&
          attendance_capacity_or_expected_attendance) ||
        '-'
    },
    {
      label: 'event_cancellations.form.is_event_location_guarded',
      span: 1,
      content:
        (is_event_location_guarded &&
          t(`event_cancellations.form.${is_event_location_guarded}`)) ||
        '-'
    },
    {
      label: 'event_cancellations.form.if_guarded_guarding_means',
      span: 1,
      content: (if_guarded_guarding_means && if_guarded_guarding_means) || '-'
    },
    {
      label: 'event_cancellations.form.amount_engaged',
      span: 1,
      content: (amount_engaged && `${amount_engaged} €`) || '-'
    },
    {
      label: 'event_cancellations.form.public_subsidies_to_substract',
      span: 1,
      content:
        (public_subsidies_to_substract &&
          `${public_subsidies_to_substract} €`) ||
        '-'
    },
    {
      label: 'event_cancellations.form.total_guarantee_amount',
      span: 1,
      content: (total_guarantee_amount && `${total_guarantee_amount} €`) || '-'
    },
    {
      label: 'event_cancellations.form.estimated_income',
      span: 1,
      content: (estimated_income && `${estimated_income} €`) || '-'
    }
  ];

  if (deleted) {
    labels.push({
      label: 'event_cancellations.form.deleted',
      span: 1,
      content:
        (deletion_date_effect &&
          dayjs(deletion_date_effect).format('DD/MM/YYYY')) ||
        '-'
    });
  }

  return labels;
};
