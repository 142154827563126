import { useListContent } from './listContent';
import { useExtraButtons } from '../extraButtons';
import { VehiclesClaimShow } from '../vehiclesClaimShow';

/**
 * Renders the AutomissionsShow component.
 * @returns {React.Component} The rendered AutomissionsShow component.
 */
export const AutomissionsShow = () =>
  VehiclesClaimShow({
    useListContent,
    pathname: 'automissions',
    useExtraButtons
  });
