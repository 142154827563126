import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useFields } from '../../subscriptions/civilLiability/fieldsCivilLiability';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useHistory } from '../../../utils/historyUtils';

/**
 * A component for creating or updating civil liability subscriptions.
 * @component
 * @param {object} props - The component's props.
 * @param {string} props.purpose - The purpose of the component (e.g., 'create' or 'update').
 * @returns {JSX.Element} The JSX element representing the create/update component.
 */
export const CivilLiabilityCreateUpdate = ({ purpose, subProgramme }) => {
  const { t } = useTranslation();
  const { fieldsInformations } = useFields(subProgramme);
  const { setInitialHistory, createHistory, updateHistory } = useHistory();
  const mandatoryDocuments =
    subProgramme === 'CIVIL_LIABILITY_PRO'
      ? ['company_KBIS', 'programme_type_document', 'others']
      : ['company_KBIS_lte_3_months', 'organization_chart_of_the_company'];
  const draggerFilesKeysOverRide =
    subProgramme === 'CIVIL_LIABILITY_PRO'
      ? [
          'company_KBIS',
          'programme_type_document',
          'certificate_of_current_contract_or_copy',
          'previous_year_revenue',
          'company_workforce',
          'others'
        ]
      : [
          'company_KBIS_lte_3_months',
          'organization_chart_of_the_company',
          'others'
        ];

  const config = {
    onGetResource: {
      setFields: (data) => {
        setInitialHistory(data);
        return {
          ...data,
          desired_effective_date:
            data.desired_effective_date && dayjs(data.desired_effective_date)
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        ...createHistory('program')
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        ...updateHistory('program')
      })
    }
  };
  const title = subProgramme && subProgramme.toLowerCase();
  return (
    <CreateUpdateContainer
      title={t(`${title}.form.edit`)}
      fields={fieldsInformations}
      purpose={purpose}
      baseUrl="civil-liabilities"
      resource="civil_liability"
      populate="?populate=documents.file"
      config={config}
      draggerFilesKeysOverRide={draggerFilesKeysOverRide}
      messageOnSuccess
      mandatoryDocuments={mandatoryDocuments}
    />
  );
};

CivilLiabilityCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired,
  subProgramme: PropTypes.string
};

CivilLiabilityCreateUpdate.defaultProps = {
  subProgramme: null
};
