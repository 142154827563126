import { useCallback } from 'react';
import dayjs from 'dayjs';
import { toBoolean } from '../../../../components/utils/stateFormat';
import { useHistory } from '../../../../../utils/historyUtils';

/**
 * Custom hook for configuring the use of resources in the IndividualHousesBuilder component.
 * @hook
 * @param {function} dispatch - The dispatch function from the Redux store.
 * @returns {object} An object containing the onGetResource and onUpdateResource functions.
 */
export const useConfig = (dispatch) => {
  const { updateHistory } = useHistory();

  const onGetResource = {
    setFields: useCallback(
      (data) => {
        dispatch({
          type: 'SET_SIGNATURE_ID',
          field: 'signatureId',
          payload: data?.signature
        });
        dispatch({
          type: 'TOGGLE_STATE',
          field: 'isAttic',
          payload: toBoolean(data?.is_attic)
        });
        dispatch({
          type: 'TOGGLE_STATE',
          field: 'isAncillaryConstruction',
          payload: toBoolean(data?.is_ancillary_construction)
        });
        dispatch({
          type: 'TOGGLE_STATE',
          field: 'isCarryOutWorks',
          payload: toBoolean(
            data?.is_project_owner_carry_out_construction_works
          )
        });
        dispatch({
          type: 'TOGGLE_STATE',
          field: 'isRenewableEnergiesWorks',
          payload: toBoolean(data?.is_renewable_energies_installations)
        });
        dispatch({
          type: 'TOGGLE_STATE',
          field: 'isPhotovoltaicPanels',
          payload: data?.if_is_photovoltaic_panels_amount > 0
        });
        dispatch({
          type: 'TOGGLE_STATE',
          field: 'isGeotechnicalStudy',
          payload: toBoolean(data?.is_geotechnical_study)
        });

        return {
          ...data,
          construction_contract_signature_date:
            data?.construction_contract_signature_date &&
            dayjs(data?.construction_contract_signature_date),
          construction_start_date_declaration:
            data.construction_start_date_declaration &&
            dayjs(data.construction_start_date_declaration),
          construction_start_date:
            data.construction_start_date && dayjs(data.construction_start_date),
          expected_construction_end_date:
            data.expected_construction_end_date &&
            dayjs(data.expected_construction_end_date),
          signed_on: data.signed_on && dayjs(data.signed_on)
        };
      },
      [dispatch]
    )
  };

  const onUpdateResource = {
    setBody: (data) => ({
      ...data,
      ...updateHistory('program')
    })
  };
  return {
    onGetResource,
    onUpdateResource
  };
};
