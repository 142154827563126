import dayjs from 'dayjs';

/**
 * `useListContent` is a custom React hook to generate a list content based on a given data.
 *
 * @hook
 * @param {object} data - The input data containing details about the document. Default is an empty object.
 * @returns {Array<object>} A list of objects containing label-content pairs for display, each object has
 * a `label` to indicate the type of data, a `span` to denote the span or size, and a `content` to denote the actual value.
 *
 * */

export const useListContent = (data = {}) => {
  const {
    company,
    insurance_company,
    entity,
    contract,
    validity_start_date,
    validity_end_date,
    subscriber_last_name_first_name,
    number,
    street,
    additional_address,
    postal_code,
    city
  } = data;

  const subscriberAdressParts = [
    number,
    street,
    additional_address,
    postal_code,
    city
  ];
  const subscriberAddress = subscriberAdressParts
    .filter((part) => part)
    .join(' ');

  return [
    {
      label: 'documents.form.company',
      span: 1,
      content: company ? company.name : '-'
    },
    {
      label: 'documents.form.entity',
      span: 1,
      content: entity ? entity.map((e) => e.name).join(',  ') : '-'
    },
    {
      label: 'documents.form.contract_number',
      span: 1,
      content: (contract?.contract_number && contract?.contract_number) || '-'
    },
    {
      label: 'documents.form.insurance_company',
      span: 1,
      content: insurance_company || '-'
    },
    {
      label: 'documents.form.validity_start_date',
      span: 1,
      content:
        (validity_start_date &&
          dayjs(validity_start_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'documents.form.validity_end_date',
      span: 2,
      content:
        (validity_end_date && dayjs(validity_end_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'documents.form.subscriber_last_name_first_name',
      span: 1,
      content: subscriber_last_name_first_name || '-'
    },
    {
      label: 'documents.form.subscriber_address',
      span: 1,
      content: subscriberAddress || '-'
    }
  ];
};
