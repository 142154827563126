import { useState, useRef, useEffect } from 'react';
import { dbKeysSelection } from './dbKeysSelection';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

/**
 * @function useFiltersDataFetch
 * @description fetches filters data from the backend
 * @param {string} resourceName
 * @param {object} dbExtraQueryFilter
 * @param {string} targetType
 * @returns {object} { fetchFilters, filters, loading }
 */

export const useFiltersDataFetch = (
  resourceName,
  dbExtraQueryFilter,
  targetType,
  populate
) => {
  const isMounted = useRef(false);
  const { dispatchAPI, company } = useAuthContext();
  const { message } = useErrorMessage();
  const [filters, setFilters] = useState(null);
  const [loading, setLoading] = useState(false);
  const dbKeys = dbKeysSelection(targetType);
  const fetchFilters = async () => {
    if (!isMounted.current) return;
    setLoading(true);
    try {
      const { data } = await dispatchAPI('POST', {
        url: `/columns-filters`,
        body: { resourceName, company, dbExtraQueryFilter, dbKeys, populate }
      });

      if (isMounted.current) {
        setFilters(data);
      }
    } catch (e) {
      if (isMounted.current) {
        message(e);
      }
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return { fetchFilters, filters, loading };
};
