import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useFields } from './utils/fieldsEnvironmentInsuranceClaims';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useConfig } from './utils/createUpdateConfig';
import { constants } from './utils/constants';
import { stringifySitesAddresses } from './utils/stringifySitesAddresses';

/**
 * Component for creating or updating individual houses builder insurance subscriptions.
 * @component
 * @param {Object} props - The component's properties.
 * @param {string} props.purpose - The purpose of the component, either "create" or "edit".
 * @returns {JSX.Element} The JSX element for creating or updating individual houses builder insurance subscriptions.
 */
export const EnvironmentInsuranceCreateUpdate = ({ purpose }) => {
  const isMounted = useRef(false);
  const { claimAPI, productAPI } = constants;
  const { company, dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const [item, setItem] = useState();
  const [sitesAddresses, setSitesAddresses] = useState();
  const program = 'ENVIRONMENT_INSURANCE';

  const mandatoryDocuments = ['full_statement'];

  const getItem = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `${productAPI}/${id}?populate=contract,company`
      });
      setItem(data);
      setSitesAddresses(
        stringifySitesAddresses(data.sites_adress_and_if_under_authorization)
      );
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    if (purpose === 'create') {
      (async () => {
        await getItem();
      })();
    }
    return () => {
      isMounted.current = false;
    };
  }, [id]);

  const { fieldsInformations } = useFields(claimAPI, sitesAddresses);

  const config = useConfig(
    company,
    program,
    item,
    setSitesAddresses,
    stringifySitesAddresses
  );

  return (
    <CreateUpdateContainer
      fields={fieldsInformations}
      purpose={purpose}
      baseUrl={claimAPI}
      resource="claims"
      config={config}
      mandatoryDocuments={mandatoryDocuments}
      populate="?populate=documents.file,entry_id"
    />
  );
};

EnvironmentInsuranceCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
