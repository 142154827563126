// import dayjs from 'dayjs';
// import { renderEntitiesShow } from '../../../../utils/formatters/entities';

/**
 * A custom hook that generates a list of content for displaying subscription details.
 * @hook
 * @param {object} data - The subscription data to generate content for.
 * @returns {Array} An array of objects containing label, span, and content for each detail.
 */
export const useListContentAssembyTesting = (data = {}) => {
  const {
    company_id
    // entity,
    // work_site_name,
    // address,
    // postal_code,
    // city,
    // desired_effective_date
  } = data;

  return [
    {
      label: 'subscriptions.form.company_name',
      span: 1,
      content: company_id?.name || '-'
    }
    // {
    //   label: 'professional_multirisk_insurances.form.entity',
    //   span: 1,
    //   content: renderEntitiesShow(entity)
    // },
    // {
    //   label: 'subscriptions.form.work_site_name',
    //   span: 1,
    //   content: (work_site_name && work_site_name) || '-'
    // },
    // {
    //   label: 'subscriptions.form.address',
    //   span: 1,
    //   content: (address && address) || '-'
    // },
    // {
    //   label: 'subscriptions.form.postal_code',
    //   span: 1,
    //   content: (postal_code && postal_code) || '-'
    // },
    // {
    //   label: 'subscriptions.form.city',
    //   span: 1,
    //   content: (city && city) || '-'
    // },
    // {
    //   label: 'subscriptions.form.desired_effective_date',
    //   span: 1,
    //   content:
    //     (desired_effective_date &&
    //       dayjs(desired_effective_date).format('DD/MM/YYYY')) ||
    //     '-'
    // }
  ];
};
