import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, Divider } from 'antd';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { CreateEditSelect } from './CreateEditSelect';
import { useHeavyVehicleFields } from './heavy-vehicle-settingsFields';
import { useLightVehicleFields } from './light-vehicle-settingsFields';
import { usePropertiesFields } from './properties-settingsFields';
import { useGolfsFields } from './golfs-settingsFields';
import { useOtherVehiclesFields } from './other-vehicles-settingsFields';
import { useAutomissionsFields } from './automissions-settingsFields';
import { useTransportsFields } from './transports-settingsFields';
import { useConstructionsFields } from './constructions-settingsFields';
import { useMachineryBreakageFields } from './machinery-breakage-settingsFields';
import { useHoleInOneFields } from './hole-in-one-settingsFields';
import { useEventCancellationFields } from './event-cancellation-settingsFields';
import { useProMultiriskFields } from './proffesional-multirisk-settingsFields';
import { useCivilLiabilityFields } from './civil-liability-settingsFields';
import { useCyberFields } from './cyber-settingsFields';
import { useAssemblyTestingInsurance } from './assembly-testing-settingsFields';
import { useHardwareFields } from './hardware-insurance-settingsFields';
import { useLegalProtectionFields } from './legal-protection-settingsFields';
import { useEnvironmentFields } from './environment-insurance-settingsFields';
import { useIndividualHousesBuilderFields } from './individual-houses-builder-settingsFields';

/**
 * `Settings` is a React component that renders a settings interface allowing users
 * to view and edit configurations related to various resources like properties, golf, vehicles, etc.
 * It leverages the `useTranslation` hook to support internationalization and the `CreateEditSelect`
 * component to handle creation and editing functionalities.
 *
 * The component structure includes:
 * - A page header displaying the settings title.
 * - A divider component with a settings description.
 * - A collapse/accordion component containing each of the setting categories.
 *
 * Each setting category (like properties, golf, vehicles) is represented by a collapsible panel,
 * and the actual editing interface is handled by the `CreateEditSelect` component.
 *
 * @component
 * @returns {JSX.Element} - A rendered `Settings` component.
 */

export const Settings = () => {
  const isMounted = useRef(true);
  const { t } = useTranslation();
  const { dispatchAPI, company } = useAuthContext();
  const { message } = useErrorMessage();

  const { otherVehiclesFields } = useOtherVehiclesFields();
  const { lightVehiclesFields } = useLightVehicleFields();
  const { heavyVehiclesFields } = useHeavyVehicleFields();
  const { propertyFields } = usePropertiesFields();
  const { golfFields } = useGolfsFields();
  const { automissionsFields } = useAutomissionsFields();
  const { transportsFields } = useTransportsFields();
  const { constructionsFields } = useConstructionsFields();
  const { machineryBreakageFields } = useMachineryBreakageFields();
  const { holeInOneFields } = useHoleInOneFields();
  const { eventCancellationFields } = useEventCancellationFields();
  const { proMultiriskFields } = useProMultiriskFields();
  const { civilLiabilityFields } = useCivilLiabilityFields();
  const { cyberFields } = useCyberFields();
  const { assemblyTestingFields } = useAssemblyTestingInsurance();
  const { hardwareFields } = useHardwareFields();
  const { legalProtectionFields } = useLegalProtectionFields();
  const { environmentFields } = useEnvironmentFields();
  const { individualHousesBuilderFields } = useIndividualHousesBuilderFields();

  const [displayState, setDisplayState] = useState({
    fleetDisplay: false,
    transportDisplay: false,
    constructionDisplay: false,
    golfDisplay: false,
    propertyDisplay: false,
    machineryBreakageDisplay: false,
    holeInOneDisplay: false,
    eventCancellationDisplay: false
  });

  const {
    fleetDisplay,
    transportDisplay,
    constructionDisplay,
    golfDisplay,
    propertyDisplay,
    machineryBreakageDisplay,
    holeInOneDisplay,
    eventCancellationDisplay,
    civilLiabilityDisplay,
    cyberDisplay,
    proMultiriskDisplay,
    assemblyTestingDisplay,
    legalProtectionDisplay,
    environmentDisplay,
    individualHousesBuilderDisplay,
    hardwareDisplay
  } = displayState;

  const items = [
    propertyDisplay && {
      key: '1',
      label: t('settings.property'),
      children: (
        <CreateEditSelect
          fields={propertyFields}
          configType="properties-configs"
          resource="properties"
        />
      )
    },
    fleetDisplay && {
      key: '2',
      label: t('settings.light_vehicles_fleet'),
      children: (
        <CreateEditSelect
          fields={lightVehiclesFields}
          configType="vehicles-light-configs"
          resource="vehicles"
        />
      )
    },
    fleetDisplay && {
      key: '3',
      label: t('settings.heavy_vehicles_fleet'),
      children: (
        <CreateEditSelect
          fields={heavyVehiclesFields}
          configType="vehicles-heavy-configs"
          resource="vehicles"
        />
      )
    },
    fleetDisplay && {
      key: '4',
      label: t('settings.other_vehicles_fleet'),
      children: (
        <CreateEditSelect
          fields={otherVehiclesFields}
          configType="vehicles-other-configs"
          resource="vehicles"
        />
      )
    },
    transportDisplay && {
      key: '5',
      label: t('settings.automissions'),
      children: (
        <CreateEditSelect
          fields={automissionsFields}
          configType="automissions-configs"
          resource="vehicles"
        />
      )
    },
    golfDisplay && {
      key: '6',
      label: t('settings.golf'),
      children: (
        <CreateEditSelect
          fields={golfFields}
          configType="golfs-configs"
          resource="golfs"
        />
      )
    },
    transportDisplay && {
      key: '7',
      label: t('settings.transport'),
      children: (
        <CreateEditSelect
          fields={transportsFields}
          configType="advalorem-configs"
          resource="transports"
        />
      )
    },
    constructionDisplay && {
      key: '8',
      label: t('settings.construction'),
      children: (
        <CreateEditSelect
          fields={constructionsFields}
          configType="constructions-configs"
          resource="constructions"
        />
      )
    },
    machineryBreakageDisplay && {
      key: '9',
      label: t('settings.machinery_breakage'),
      children: (
        <CreateEditSelect
          fields={machineryBreakageFields}
          configType="machinery-breakage-configs"
          resource="machinery_breakages"
        />
      )
    },
    holeInOneDisplay && {
      key: '10',
      label: t('settings.hole_in_one'),
      children: (
        <CreateEditSelect
          fields={holeInOneFields}
          configType="hole-in-one-configs"
          resource="hole_in_ones"
        />
      )
    },
    eventCancellationDisplay && {
      key: '11',
      label: t('settings.event_cancellation'),
      children: (
        <CreateEditSelect
          fields={eventCancellationFields}
          configType="event-cancellation-configs"
          resource="event_cancellations"
        />
      )
    },
    proMultiriskDisplay && {
      key: '12',
      label: t('settings.professional_multirisk'),
      children: (
        <CreateEditSelect
          fields={proMultiriskFields}
          configType="professional-multirisk-insurance-configs"
          resource="professional_multirisk_insurances"
        />
      )
    },
    civilLiabilityDisplay && {
      key: '13',
      label: t('settings.civil_liability'),
      children: (
        <CreateEditSelect
          fields={civilLiabilityFields}
          configType="civil-liability-configs"
          resource="civil_liability"
        />
      )
    },
    cyberDisplay && {
      key: '14',
      label: t('settings.cyber_insurance'),
      children: (
        <CreateEditSelect
          fields={cyberFields}
          configType="cyber-insurance-configs"
          resource="cyber_insurances"
        />
      )
    },
    assemblyTestingDisplay && {
      key: '15',
      label: t('settings.assembly_testing_insurance'),
      children: (
        <CreateEditSelect
          fields={assemblyTestingFields}
          configType="assembly-testing-insurance-configs"
          resource="assembly_testing_insurances"
        />
      )
    },
    hardwareDisplay && {
      key: '16',
      label: t('settings.hardware_insurance'),
      children: (
        <CreateEditSelect
          fields={hardwareFields}
          configType="hardware-insurance-configs"
          resource="hardware_insurances"
        />
      )
    },
    legalProtectionDisplay && {
      key: '17',
      label: t('settings.legal_protection_insurance'),
      children: (
        <CreateEditSelect
          fields={legalProtectionFields}
          configType="legal-protection-insurance-configs"
          resource="legal_protection"
        />
      )
    },
    environmentDisplay && {
      key: '18',
      label: t('settings.environment_insurances'),
      children: (
        <CreateEditSelect
          fields={environmentFields}
          configType="environment-insurance-configs"
          resource="environment_insurances"
        />
      )
    },
    individualHousesBuilderDisplay && {
      key: '19',
      label: t('settings.individual_houses_builders'),
      children: (
        <CreateEditSelect
          fields={individualHousesBuilderFields}
          configType="individual-houses-builder-configs"
          resource="individual_houses_builders"
        />
      )
    }
  ].filter(Boolean);

  const getProgrammesToDisplay = async () => {
    if (!isMounted.current) {
      return;
    }
    try {
      const { data } = await dispatchAPI('GET', {
        url: `contracts/company/${company}`
      });
      if (isMounted.current) {
        const { programmesToDisplay } = data;
        setDisplayState({
          fleetDisplay: programmesToDisplay.includes('FLEET'),
          transportDisplay: programmesToDisplay.includes('TRANSPORT'),
          constructionDisplay: programmesToDisplay.includes('CONSTRUCTION'),
          golfDisplay: programmesToDisplay.includes('GOLF'),
          propertyDisplay: programmesToDisplay.includes('PROPERTY'),
          machineryBreakageDisplay:
            programmesToDisplay.includes('MACHINERY_BREAKAGE'),
          holeInOneDisplay: programmesToDisplay.includes('HOLE_IN_ONE'),
          civilLiabilityDisplay:
            programmesToDisplay.includes('CIVIL_LIABILITY'),
          eventCancellationDisplay:
            programmesToDisplay.includes('EVENT_CANCELLATION'),
          cyberDisplay: programmesToDisplay.includes('CYBER_INSURANCE'),
          proMultiriskDisplay: programmesToDisplay.includes(
            'PROFESSIONAL_MULTIRISK_INSURANCE'
          ),
          assemblyTestingDisplay: programmesToDisplay.includes(
            'ASSEMBLY_TESTING_INSURANCE'
          ),
          legalProtectionDisplay: programmesToDisplay.includes(
            'LEGAL_PROTECTION_INSURANCE'
          ),
          environmentDisplay: programmesToDisplay.includes(
            'ENVIRONMENT_INSURANCE'
          ),
          individualHousesBuilderDisplay: data?.construction?.includes(
            'individual-houses-builders'
          ),
          hardwareDisplay: programmesToDisplay.includes('HARDWARE_INSURANCE')
        });
      }
    } catch (e) {
      if (isMounted.current) {
        message(e.response.status);
      }
    }
  };
  useEffect(() => {
    (async () => {
      await getProgrammesToDisplay();
    })();
  }, [company]);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  return (
    <>
      <PageHeaderCustom title={t('settings.title')} />
      <ContentCustom>
        <Divider orientation="left">{t('settings.description')}</Divider>
        <Collapse accordion items={items} />
      </ContentCustom>
    </>
  );
};
