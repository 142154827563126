// TODO: Commented Out Sent By mail option. Not available yet
// TODO: awaitin microservice to be able to send attestation with commented componentw
import { useCallback, useEffect, useState } from 'react';
// import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Button, Skeleton, Row, Col, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
// import { EditOutlined, SyncOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useListContent } from './utils/listContentHoleInOne';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../components';
import { routes } from '../../../utils/constants/adminRoutes';
// import { HoleInOneContext } from './HoleInOneContext';
import { MailingButton } from '../../Mailing/MailingButton';
import { ClaimButton } from '../../components/ClaimButton';
import usePopConfirm from '../utils/popConfirms';
import { History } from '../../components/History';
// import { SendAttestationButton } from '../../Mailing/SendAttestationButton';

// const iconSize = 18;

/**
 * HoleInOneShow component.
 * Renders detailed information about a specific HoleInOnes course, including its documents.
 * Provides options to edit or delete the HoleInOnes course documents.
 *
 * @component
 * @returns {JSX.Element} HoleInOneShow
 */
export const HoleInOneShow = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [HoleInOnes, setHoleInOne] = useState();
  const [visible, setVisible] = useState(false);
  const listContent = useListContent(HoleInOnes);
  // const { generateAttestation } = useContext(HoleInOneContext);
  const { exitProgram, unarchiveProgram } = usePopConfirm('hole-in-ones');

  const getHoleInOne = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/hole-in-ones/${id}?populate=customer_manager,unit_manager,entity,site,contract,company`
      });
      setHoleInOne(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getHoleInOne();
    })();
  }, [getHoleInOne]);

  return (
    <>
      <PageHeaderCustom
        title={`${t('number')} ${HoleInOnes?.unit_counter || 'xxxx'} - ${
          HoleInOnes?.golf_name || ''
        } - ${HoleInOnes?.city || ''}`}
        extra={
          <>
            {HoleInOnes && !HoleInOnes.deleted && (
              <>
                <Link
                  to={{
                    pathname: `${routes.PROGRAMMES}/hole-in-ones/edit/${id}`
                  }}
                >
                  <Button type="primary">
                    {`${t('buttons.edit')} `}
                    <EditOutlined />
                  </Button>
                </Link>
                {/* {HoleInOnes?.contract?.insurance_company === 'AXA' && (
                  <Button onClick={() => generateAttestation(HoleInOnes?._id)}>
                    {`${t('buttons.generate_attestation')} `}
                    <SyncOutlined style={{ fontSize: iconSize }} />
                  </Button>
                )} */}
                {/* <MailingButton
                  asset={HoleInOnes}
                  templateName="InsuranceAttestation"
                  tradKey="sending_attestation_golf"
                  resource="HoleInOnes"
                  baseUrl="hole-in-ones"
                /> */}
                <ClaimButton
                  id={id}
                  url="hole-in-ones"
                  claimType="HoleInOnes"
                />
                <MailingButton
                  asset={HoleInOnes}
                  templateName="Blank"
                  tradKey="specific_demand"
                  resource="HoleInOnes"
                  baseUrl="hole-in-ones"
                />
              </>
            )}
            {HoleInOnes &&
              !HoleInOnes.deleted &&
              exitProgram({
                visible,
                setVisible
              })}
            {HoleInOnes && HoleInOnes.deleted && unarchiveProgram()}
          </>
        }
      />
      <ContentCustom>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={12}>
            <Skeleton loading={isLoading} title={0} paragraph={0} active />
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <Row gutter={[0, 16]}>
                <Card>
                  <DescriptionList
                    data={listContent}
                    translate
                    layout="vertical"
                    title={t('hole_in_ones.form.main_informations')}
                  />
                </Card>
                <Col span={24}>
                  <Card title={t('subscriptions.form.history')} size="small">
                    <History route="hole-in-ones" />
                  </Card>
                </Col>
              </Row>
            </Skeleton>
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};
