import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Checkbox, Col, Modal, Row, Spin, Table } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * Returns an array of column configurations for a ConversationsBoard component.
 *  @hook
 *
 * @param {Array} companies - The array of company objects.
 * @returns {Array} - An array of column configurations.
 */
const useColumns = (companies) => [
  {
    title: 'Client',
    dataIndex: 'name',
    showSorterTooltip: {
      target: 'full-header'
    },
    sorter: (a, b) => a.name.length - b.name.length,
    sortDirections: ['descend'],
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
      const uniqueNames = new Set((companies || []).map((c) => c.name));
      const names = Array.from(uniqueNames).sort();

      return (
        <div
          style={{
            padding: 15,
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            height: '20rem',
            overflowY: 'auto'
          }}
        >
          {names.map((name) => (
            <Checkbox
              key={name}
              onChange={() => {
                const newSelectedKeys = selectedKeys.includes(name)
                  ? selectedKeys.filter((sk) => sk !== name)
                  : [...selectedKeys, name];
                setSelectedKeys(newSelectedKeys);
                confirm({ closeDropdown: false });
              }}
              checked={selectedKeys.includes(name)}
            >
              {name}
            </Checkbox>
          ))}
        </div>
      );
    },
    onFilter: (value, record) =>
      record.name.toLowerCase().includes(value.toLowerCase())
  },
  {
    title: 'Messages de personnes non lus',
    dataIndex: 'notReadMessages',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.notReadMessages - b.notReadMessages
  },
  {
    title: 'Messages de sinistres non lus',
    dataIndex: 'notReadClaimMessages',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.notReadClaimMessages - b.notReadClaimMessages
  }
];

const ConversationsBoard = () => {
  const { user, token, company, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const getCompany = async () => {
    if (!company || !user?._id || !token) return;
    setIsLoading(true);
    try {
      const { data: companiesIds } = await dispatchAPI('GET', {
        url: '/companies?fields=_id'
      });
      const companyIds = companiesIds.map((c) => c._id);
      const { data: companiesData } = await dispatchAPI('POST', {
        url: `conversations/allMessages/count`,
        body: {
          companyIds,
          userId: user?._id
        }
      });
      setCompanies(companiesData);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getCompany();
  }, []);

  if (isLoading)
    return (
      <Modal
        visible
        centered
        footer={false}
        closable={false}
        bodyStyle={{ textAlign: 'center' }}
      >
        <Row justify="center" align="middle" style={{ height: '100%' }}>
          <Col>
            {t('messages.loading')}
            <br />
            <Spin size="large" />
          </Col>
        </Row>
      </Modal>
    );

  return (
    <Table
      columns={useColumns(companies)}
      dataSource={companies}
      showSorterTooltip={{
        target: 'sorter-icon'
      }}
    />
  );
};
export default ConversationsBoard;
