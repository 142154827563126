import { Link } from 'react-router-dom';
import { Flex, Tooltip } from 'antd';
import { EditOutlined, EyeOutlined, FileAddOutlined } from '@ant-design/icons';
import { Flood } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';

const iconSize = 18;

/**
 * `useActionColumn` is a hook that returns a configuration array for rendering an action column.
 * The action column consists of several icons used for viewing a document, editing a document,
 * and other associated actions. Each icon has an associated action, such as navigation or
 * triggering a functionality.
 *
 * @hook
 * @returns {Array} An array containing configuration for the action column. The configuration includes
 * how to render the column based on the record provided.
 */

export const useActionColumn = (_, isArchivatedPage) => {
  const { t } = useTranslation();

  return [
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Flex gap="small" align="center" justify="end" wrap="nowrap">
          <Link to={`/programmes/construction-insurance/show/${record?._id}`}>
            <Tooltip title={t('construction.actions.show')}>
              <EyeOutlined style={{ fontSize: iconSize }} />
            </Tooltip>
          </Link>
          <Link to={`/programmes/construction-insurance/edit/${record?._id}`}>
            <Tooltip title={t('construction.actions.edit')}>
              <EditOutlined style={{ fontSize: iconSize }} />
            </Tooltip>
          </Link>
          {!isArchivatedPage && (
            <>
              {record?.status !== 'AMENDMENT_REQUEST' && (
                <Link
                  to={{
                    pathname: `/programmes/construction-insurance/amendment/create/${record?._id}`
                  }}
                >
                  <Tooltip title={t('construction.actions.create_amendement')}>
                    <FileAddOutlined style={{ fontSize: iconSize }} />
                  </Tooltip>
                </Link>
              )}
              <Link to={`/claims/construction-insurance/create/${record?._id}`}>
                <Tooltip title={t('construction.actions.create_claim')}>
                  <Flood size={iconSize} />
                </Tooltip>
              </Link>
            </>
          )}
        </Flex>
      )
    }
  ];
};
