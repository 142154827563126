import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Flex, Tooltip } from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { TrafficIncident } from '@carbon/icons-react';
import { MailingButtonVehicles } from '../../../../components/ButtonSpecificDemandVehicles';

const iconSize = 18;

export const useActionColumn = (_, isArchivatedPage) => {
  const { t } = useTranslation();
  return [
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Flex gap="small" align="center" justify="end" wrap="nowrap">
          <Link to={`/programmes/automissions/show/${record?._id}`}>
            <Tooltip title={t('automissions.actions.show')}>
              <EyeOutlined style={{ fontSize: iconSize }} />
            </Tooltip>
          </Link>
          <Link to={`/programmes/automissions/edit/${record?._id}`}>
            <Tooltip title={t('automissions.actions.edit')}>
              <EditOutlined style={{ fontSize: iconSize }} />
            </Tooltip>
          </Link>
          {!isArchivatedPage && (
            <>
              <Link to={`/claims/automissions/create/${record?._id}`}>
                <Tooltip title={t('actions.create_claim')}>
                  <TrafficIncident size={20} />
                </Tooltip>
              </Link>
              <MailingButtonVehicles
                assetId={record?._id}
                templateName="Blank"
                tradKey="specific_demand"
              />
            </>
          )}
        </Flex>
      )
    }
  ];
};
