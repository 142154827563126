import { useTranslation } from 'react-i18next';
import { ConversationDisplay } from './ConversationDisplay';
import { useAuthContext } from '../../../contexts/AuthContext';

/**
 * @hook
 * @name useTabContent
 * @description A custom hook for generating the tab content for the conversations page
 * @returns {Object} The tab content
 */
export const useTabContent = () => {
  const { user } = useAuthContext();
  const { t } = useTranslation();
  let items = [];
  const hasClaimsRights = ['users:SUPER-USER', 'admins:SUPER-ADMIN'].includes(
    user.role
  );

  const generateTab = (filterKey) => ({
    label: t(`messages.titles.${filterKey}`),
    key: filterKey,
    children: <ConversationDisplay />
  });

  if (hasClaimsRights) {
    items = [generateTab('collaborators'), generateTab('claims')];
  } else {
    items = [generateTab('collaborators')];
  }

  return { items };
};
