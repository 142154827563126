import { createGlobalStyle } from 'styled-components';

const DarkTheme = createGlobalStyle`
  body {
    color-scheme: dark;
    --clientColor: #030407;
    --primaryColor: #1B3837;
    --addColor: #314B49;
    --componentBackground: #0a0c14;
    --secondaryColor: #314B49;
    --disabledColor: #636363;
    --bodyBackground: var(--clientColor);
    --primaryHover: #181b30;
    --secondaryHover: #fff;
    --textColor: rgba(255, 255, 255, 0.8);
    --textColorSecondary: #bfbfbf;
    --textColorActive: var(--secondaryColor);
    --textColorInvert: #181b30;
    --itemHoverBackground: var(--primaryHover);
    --itemActiveBackground: var(--primaryHover);
    --borderColor: #38414a;
    --borderColorSecondary: #1d2126;
    --contrastBackground: #111322;
    --errorColor: #e31919;
    --boxShadowColor: rgba(0, 0, 0, 0.8);
    --boxShadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
    --borderRadius: 32px;
    --borderRadiusXS: 2px;
    --borderRadiusLG: 8px;
    --borderRadiusXL: 12px;
    --titleFont: 'Hammersmith One', sans-serif;
  }
  `;

export default DarkTheme;
