import { useTranslation } from 'react-i18next';

/**
 * Custom hook that generates an array of legal protection information based on the provided data.
 * @hook
 * @param {Object} data - The data object containing the legal protection information.
 * @returns {Array} An array of legal protection information.
 */
export const useListContentLegalProtection = (data = {}) => {
  const { t } = useTranslation();

  const keys = [
    'company_name',
    'site_name',
    'entity',
    'site_address',
    'postal_code',
    'city',
    'global_company_turnover',
    'employees_count'
  ];

  const keyWithEnums = [
    'activity',
    'legal_procedure_in_progress',
    'desire_options_litigies',
    'double_plafonds_if_sinister'
  ];

  const createArray = (keysArray) =>
    keysArray.map((key) => ({
      label: `subscriptions.form.${key}`,
      span: 1,
      content: data[key] || '-'
    }));

  const legalProtectionInfo = createArray(keys);

  const addKeysWithEnums = (keysArray, originalArr) => {
    keysArray.forEach((key) => {
      originalArr.push({
        label: `subscriptions.form.${key}`,
        span: 1,
        content: t(`legal_protection_insurance.enums.${data[key]}`) || '-'
      });
    });
  };

  addKeysWithEnums(keyWithEnums, legalProtectionInfo);

  return legalProtectionInfo;
};
