import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import FilesManager from '../../components/CreateUpdateContainerV3/FilesManager/FilesManagerWithDisplay';
import { useDraggerProps } from '../../components/CreateUpdateContainerV3/FilesManager/utils/draggerProps';

/**
 * Renders the AgricultureDocuments component.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.setDraggerFilesWithIndex - The function to set the dragger files with index.
 * @param {Function} props.setFilesConfWithIndex - The function to set the files configuration with index.
 * @param {number} props.index - The index of the component.
 * @returns {JSX.Element} The rendered component.
 */
export const AgricultureDocuments = ({
  setDraggerFilesWithIndex,
  setFilesConfWithIndex,
  index
}) => {
  const [filesConfiguration, setFilesConfiguration] = useState([]);
  const { t } = useTranslation();
  const [draggerFilesList, setDraggerFilesList] = useState([]);
  const possibleDocuments = [
    'parcel_surveys',
    'yield_declarations',
    'customs_declarations'
  ];

  useDraggerProps(draggerFilesList, setDraggerFilesList);

  useEffect(() => {
    if (draggerFilesList.length > 0 && filesConfiguration !== null) {
      let fileName = '8484';
      const updatedFilesConfigurations = filesConfiguration.map(
        (fileConfig) => {
          fileName = t(`files.keys.${fileConfig.fileKey}`);

          return {
            ...fileConfig,
            name: `C${index + 1} ${fileName} - ${fileConfig.name}`,
            culture: index
          };
        }
      );
      setFilesConfWithIndex(updatedFilesConfigurations, index);

      const updatedFilesNames = draggerFilesList.map((file) => {
        const modifiedFile = new File(
          [file],
          `C${index + 1}${fileName}_ ${file.name}`,
          {
            type: file.type,
            ...file
          }
        );
        return modifiedFile;
      });
      setDraggerFilesWithIndex(updatedFilesNames, index);
    }
  }, [draggerFilesList, filesConfiguration, index]);

  return (
    <FilesManager
      filesList={draggerFilesList}
      setFilesList={setDraggerFilesList}
      filesKeys={possibleDocuments}
      filesConfiguration={filesConfiguration}
      setFilesConfiguration={setFilesConfiguration}
      purpose="create"
      noBorder
    />
  );
};

AgricultureDocuments.propTypes = {
  setDraggerFilesWithIndex: PropTypes.func.isRequired,
  setFilesConfWithIndex: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired
};
