import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { useColumns } from './columns';
import { useActionColumn } from '../actionColumn';
import { ListResource } from '../../../components/ListResource/ListResource';
import { useAuthContext } from '../../../contexts/AuthContext';
import { FilterByMissingDocuments } from '../../components/FilterByMissingDocuments';

/**
 * A component that lists 'Hole in one' filtered by selected entity and site.
 *
 * `ListHoleInOneClaims` is responsible for fetching and listing 'Hole in one' for a
 * specific entity and site. It provides filter dropdowns (`SelectByEntity` and `SelectBySite`)
 * to allow users to select specific entity or site to filter the results.
 *
 * Once a user chooses an entity or a site, the component updates the browser URL with the
 * filtering parameters.
 *
 * @Component
 *
 * @returns {JSX.Element} The rendered `ListHoleInOneClaims` component with filtering capabilities.
 */

export const ListHoleInOneClaims = () => {
  const [isMissingDocuments, setIsMissingDocuments] = useState('');
  const [resetFilters, setResetFilters] = useState(false);
  const [extraFilter, setExtraFilter] = useState('');
  const [isClosedButton, setIsClosedButton] = useState(false);
  const actionColumnUrl = 'hole-in-ones';
  const url = 'hole_in_one_claims';

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const currentPage = Number(params.get('p') || 1);
  const pageSize = Number(params.get('pS') || 10);

  const { t } = useTranslation();
  const { company } = useAuthContext();

  let filterParameter = '';
  const resourceName = 'hole_in_one_claims';
  const dbExtraQueryFilter = `company=${company}&${
    isClosedButton ? 'claim_status=CLOSED' : 'claim_status!=CLOSED'
  }`;
  const populate = 'entity,site,entry_id,company';

  const [columns, loading] = useColumns(
    resourceName,
    dbExtraQueryFilter,
    populate
  );
  const actionColumn = useActionColumn(actionColumnUrl, url);

  const onFilterMissingDocuments = (missingDocuments) => {
    setIsMissingDocuments(missingDocuments === false ? '' : missingDocuments);
  };

  useEffect(() => {
    if (resetFilters) {
      filterParameter = '';
      setResetFilters(false);
    }
    filterParameter = `missing_documents=${isMissingDocuments}__`;
    setExtraFilter(filterParameter);
    navigate({
      pathname: '/claims/hole-in-ones',
      search: `?&p=${currentPage}&pS=${pageSize}&f=${filterParameter}`
    });
  }, [navigate, isMissingDocuments, resetFilters]);

  if (loading) return <Spin fullscreen="true" />;
  return (
    <ListResource
      extraQuery={dbExtraQueryFilter}
      resourceName={resourceName}
      exportUrl={`exports/company/${company}/claim/programme-type/HOLE_IN_ONE`}
      importUrl={`imports-claim-hole-in-ones/company/${company}`}
      exportTemplateUrl="export-claim-templates/template/hole-in-one-claim"
      tradKey="claims"
      columns={[...columns, ...actionColumn]}
      withExtraFilters={
        <FilterByMissingDocuments
          onChange={onFilterMissingDocuments}
          setResetFilters={setResetFilters}
        />
      }
      populate={populate}
      editAction={false}
      showAction={false}
      deleteAction={false}
      sheetName={t('sheet.hole_in_one_claims')}
      headers={[]}
      setIsClosedButton={setIsClosedButton}
      noCreateButton
      noArchiveFilter
      extraFilter={extraFilter}
      customActionColumn
      scroll={{ x: 3800 }}
    />
  );
};
