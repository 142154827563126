import { Checkbox } from 'antd';

/**
 * Custom hook that returns an array of fields for professional multirisk settings.
 * @returns {Object} An object containing the array of fields.
 */
export const useProMultiriskFields = () => {
  const fieldNames = [
    'missing_documents',
    'contract_number',
    'company_name',
    'entity',
    'site',
    'risk_site_name',
    'risk_site_address',
    'postal_code',
    'city',
    'entry_date',
    'total_insured_developed_area'
  ];

  const proMultiriskFields = fieldNames.map((name) => ({
    name: [name],
    rules: [{ required: false }],
    valuePropName: 'checked',
    input: <Checkbox />
  }));

  return { proMultiriskFields };
};
