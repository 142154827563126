import { Route, Routes } from 'react-router-dom';
import { EnvironmentInsuranceShow } from './EnvironmentInsuranceShow';
import { ListEnvironmentInsurance } from './ListEnvironmentInsurance';
import { EnvironmentCreateUpdate } from './EnvironmentCreateUpdate';
import { Exception } from '../../../components';

/**
 * A router component for handling hardware insurance related routes.
 *
 * @component
 * @returns {JSX.Element} The JSX element representing the HardwareInsuranceRouter.
 */

export const EnvironmentInsuranceRouter = () => (
  <Routes>
    <Route
      path="edit/:id"
      element={<EnvironmentCreateUpdate purpose="edit" />}
    />
    <Route path="show/:id" element={<EnvironmentInsuranceShow />} />
    <Route index element={<ListEnvironmentInsurance />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
