import { Checkbox } from 'antd';

/**
 * Custom hook for assembly testing insurance fields.
 * @hook
 * @returns {Object} An object containing the assembly testing fields.
 */
export const useAssemblyTestingInsurance = () => {
  const fieldNames = [
    'company_name',
    'contract_number',
    'entity',
    'validity_start_date',
    'validity_end_date',
    'address',
    'postal_code',
    'city'
  ];

  const assemblyTestingFields = fieldNames.map((name) => ({
    name: [name],
    rules: [{ required: false }],
    valuePropName: 'checked',
    input: <Checkbox />
  }));

  return { assemblyTestingFields };
};
