import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback, useRef } from 'react';
import { FileExclamationOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useFiltersDataFetch } from '../../../../components/ColumnsDynamicFilters/useFiltersDatasFetch';

/**
 * `useColumns` is a custom React hook that provides a list of column configurations.
 *
 * The column titles are fetched using the `react-i18next` hook for internationalization.
 *
 * @hook
 * @returns {Array<object>} Returns an array of column configurations to be consumed by a table component.
 */

export const useColumns = (resourceName, dbExtraQueryFilter, populate) => {
  const { t } = useTranslation();
  const { dispatchAPI, company: companyId } = useAuthContext();
  const { message } = useErrorMessage();
  const [config, setConfig] = useState({});
  const { fetchFilters, filters, loading } = useFiltersDataFetch(
    resourceName,
    dbExtraQueryFilter,
    resourceName,
    populate
  );
  const isMounted = useRef(true);

  const renderAddress = (text, record) => {
    const { number, street, additional } = record || {};
    return number || street || additional
      ? `${number || ''} ${street || ''} ${additional || ''}`
      : '-';
  };

  const allColumns = [
    {
      title: t('properties.form.missing_documents'),
      key: 'missing_documents',
      dataIndex: ['missing_documents'],
      sorter: true,
      width: 150,
      render: (missing_documents) =>
        missing_documents ? (
          <FileExclamationOutlined
            style={{ fontSize: 18, color: 'var(--warningColor)' }}
          />
        ) : (
          '-'
        )
    },
    {
      title: t('properties.form.company_name'),
      key: 'company_name',
      dataIndex: ['company', 'name'],
      sorter: true
    },
    {
      title: t('properties.form.contract_number'),
      key: 'contract',
      dataIndex: ['contract', 'contract_number'],
      sorter: true,
      filters: filters?.contract?.map((number) => ({
        text: number.contract_number,
        value: number._id
      }))
    },
    {
      title: t('properties.form.date_added'),
      key: 'created_at',
      dataIndex: ['created_at'],
      filters: filters?.created_at?.map((date) => ({
        text: dayjs(date).format('DD/MM/YYYY'),
        value: date
      })),
      render: (created_at) =>
        created_at ? dayjs(created_at).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('properties.form.validity_start_date'),
      key: 'validity_start_date',
      dataIndex: ['contract', 'validity_start_date'],
      render: (validity_start_date) =>
        validity_start_date
          ? dayjs(validity_start_date).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t('properties.form.validity_end_date'),
      key: 'validity_end_date',
      dataIndex: ['contract', 'validity_end_date'],
      render: (validity_end_date) =>
        validity_end_date ? dayjs(validity_end_date).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('properties.form.entity'),
      key: 'entity',
      dataIndex: ['entity', 'name'],
      filters: filters?.entity?.map((entity) => ({
        text: entity.name,
        value: entity._id
      })),
      sorter: true
    },
    {
      title: t('properties.form.site'),
      key: 'site',
      dataIndex: ['site', 'name'],
      filters: filters?.site?.map((site) => ({
        text: site.name,
        value: site._id
      })),
      sorter: true
    },
    {
      title: t('properties.form.asset'),
      key: 'asset_name',
      dataIndex: 'asset_name',
      filters: filters?.asset_name?.map((asset) => ({
        text: asset,
        value: asset
      })),
      sorter: true
    },
    {
      title: t('properties.form.assets_addresses'),
      key: 'assets_addresses',
      sorter: true,
      render: renderAddress
    },
    {
      title: t('properties.form.postal_code'),
      key: 'postal_code',
      dataIndex: ['postal_code'],
      filters: filters?.postal_code?.map((postal_code) => ({
        text: postal_code,
        value: postal_code
      })),
      sorter: true
    },
    {
      title: t('properties.form.city'),
      key: 'city',
      dataIndex: ['city'],
      filters: filters?.city?.map((city) => ({
        text: city,
        value: city
      })),
      sorter: true
    },
    {
      title: t('properties.form.country'),
      key: 'country',
      dataIndex: ['country'],
      filters: filters?.country?.map((country) => ({
        text: country,
        value: country
      })),
      sorter: true
    },
    {
      title: t('properties.form.total_insured_developed_area'),
      key: 'total_insured_developed_area',
      dataIndex: ['total_insured_developed_area'],
      filters: filters?.total_insured_developed_area?.map((area) => ({
        text: area,
        value: area
      })),
      sorter: true
    },
    {
      title: t('properties.form.legal_status'),
      key: 'legal_status',
      dataIndex: ['legal_status'],
      filters: filters?.legal_status?.map((status) => ({
        text: t(`properties.form.${status}`),
        value: status
      })),
      sorter: true,
      render: (legal_status) =>
        (legal_status && t(`properties.form.${legal_status}`)) || '-'
    },
    {
      title: t('properties.form.typology'),
      key: 'typology',
      dataIndex: 'typology',
      filters: filters?.typology?.map((typology) => ({
        text: t(`properties.form.${typology}`),
        value: typology
      })),
      sorter: true,
      render: (typology) =>
        (typology && t(`properties.form.${typology}`)) || '-'
    },
    {
      title: t('properties.form.tenant_occupation'),
      key: 'tenant_occupation',
      dataIndex: 'tenant_occupation',
      filters: filters?.tenant_occupation?.map((occupation) => ({
        text: occupation,
        value: occupation
      })),
      sorter: true
    },
    {
      title: t('properties.form.last_and_first_name_of_tenant'),
      key: 'last_and_first_name_of_tenant',
      dataIndex: 'last_and_first_name_of_tenant',
      filters: filters?.last_and_first_name_of_tenant?.map((tenant) => ({
        text: tenant,
        value: tenant
      })),
      sorter: true
    },
    {
      title: t('vehicles.form.deletion_date'),
      key: 'deletion_date_effect',
      dataIndex: 'deletion_date_effect',
      filters: filters?.deletion_date_effect?.map((date) => ({
        text: dayjs(date).format('DD/MM/YYYY'),
        value: date
      })),
      sorter: true,
      render: (deletion_date_effect) =>
        deletion_date_effect
          ? dayjs(deletion_date_effect).format('DD/MM/YYYY')
          : '-'
    }
  ];

  const getConfig = useCallback(async () => {
    if (!isMounted.current) return;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/programme-configs/${companyId}/properties-configs`
      });
      setConfig(data[0]);
    } catch (e) {
      if (isMounted.current) message('missing_parameters');
    }
  }, [dispatchAPI, message]);

  useEffect(() => {
    getConfig();
    fetchFilters();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const filteredColumns = allColumns.filter((column) => config[column.key]);

  return [filteredColumns, loading] || [];
};
