import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerContract';
import { useAuthContext } from '../../../contexts/AuthContext';

import { useFields } from './fieldsDocumentsSubscription';
import { formatDatePickerDate } from '../../../utils/formatDatePickerDate';
/**
 * `DocumentsCreateUpdate` is a React component for creating or updating documents.
 *
 * Depending on the provided `purpose` prop, this component can be used to either create or edit a document.
 * The component utilizes the `CreateUpdateContainer` for rendering the input fields and managing form actions.
 *
 * Note: This component does not support managing files.
 *
 * @component
 * @param {object} props The props for the component.
 * @param {string} props.purpose Indicates the purpose of the form. It should be either 'create' or 'edit'.
 * @returns {React.ElementType} A form for creating or updating a document.
 */

export const ContractCreateUpdate = ({
  purpose,
  programmeType,
  subProgrammeType,
  WithPageHeaderCustom,
  setCurrent,
  stepper,
  subId,
  layout
}) => {
  const [isMandatory, setIsMandatory] = useState(true);
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [currentDates, setCurrentDates] = useState({
    validity_start_date: null
  });
  const selectedType = 'CONTRACT';
  const { fields } = useFields(
    selectedType,
    isMandatory,
    setIsMandatory,
    isFieldsLoading,
    setIsFieldsLoading,
    programmeType,
    subProgrammeType,
    currentDates,
    setCurrentDates
  );
  const { id } = useParams();
  const { company, companyName } = useAuthContext();
  const { t } = useTranslation();
  const config = {
    onCreateResource: {
      setBody: (data) => {
        if (data?.validity_start_date) {
          setCurrentDates({
            validity_start_date: formatDatePickerDate(data?.validity_start_date)
          });
        }
        return {
          ...data,
          is_pending: Boolean(data.is_pending),
          company_Id: company,
          programme_types: programmeType,
          document_type: selectedType,
          construction_sub_programmes:
            programmeType === 'CONSTRUCTION' ? subProgrammeType : null,
          transport_sub_programmes:
            programmeType === 'TRANSPORT' ? subProgrammeType : null,
          civil_liability_sub_programmes:
            programmeType === 'CIVIL_LIABILITY' ? subProgrammeType : null
        };
      }
    }
  };

  useEffect(() => {
    setLoaded(true);
  }, [programmeType, subProgrammeType]);

  return (
    <Skeleton loading={isFieldsLoading}>
      {!isFieldsLoading && loaded && (
        <CreateUpdateContainer
          fields={fields}
          purpose={purpose}
          urlFileKeys="contracts"
          baseUrl={`contracts/subscription/${id}`}
          resource="documents"
          config={config}
          extraTitle={
            <Row justify="center">{`${t('folder')} ${companyName}`}</Row>
          }
          withFilesManager={false}
          WithPageHeaderCustom={WithPageHeaderCustom}
          setCurrent={setCurrent}
          stepper={stepper}
          subId={subId}
          layout={layout}
        />
      )}
    </Skeleton>
  );
};

ContractCreateUpdate.propTypes = {
  purpose: PropTypes.string,
  programmeType: PropTypes.string,
  subProgrammeType: PropTypes.arrayOf(PropTypes.string),
  WithPageHeaderCustom: PropTypes.bool,
  setCurrent: PropTypes.func,
  stepper: PropTypes.bool,
  subId: PropTypes.string,
  layout: PropTypes.string
};

ContractCreateUpdate.defaultProps = {
  purpose: null,
  programmeType: null,
  subProgrammeType: null,
  WithPageHeaderCustom: false,
  setCurrent: null,
  stepper: false,
  subId: null,
  layout: null
};
