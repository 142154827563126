import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Card, Form, Button, Flex } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useConversationContext } from '../../contexts/ConversationContext';
import { title } from './Conversations/utils';

const { TextArea } = Input;

/**
 * Custom component for writing and sending a message
 * @component
 * @returns {JSX.Element} - Custom component for writing and seding a message or a note
 */
export const MessageWriterClaim = ({ selectRefresh }) => {
  const { t } = useTranslation();
  const { user, dispatchAPI, company } = useAuthContext();
  const {
    selectedConversation,
    refreshConversation,
    setRefreshConversation,
    filter,
    setIsThreadOpen,
    refreshDrawer,
    setRefreshDrawer
  } = useConversationContext();

  const {
    unit_counter,
    programme_types,
    civil_liability_sub_programmes,
    fleet_sub_programmes,
    construction_sub_programmes,
    transport_sub_programmes,
    entity,
    site
  } = selectedConversation;

  const { message } = useErrorMessage();
  const url = '/conversations/claims';
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      const { _id: from } = user;
      const body = {
        ...values,
        unit_counter,
        programme_types,
        fleet_sub_programmes,
        civil_liability_sub_programmes,
        construction_sub_programmes,
        transport_sub_programmes,
        entity,
        site,
        from,
        company
      };
      setLoading(true);
      await dispatchAPI('POST', { url, body });
      if (selectRefresh === 'messagePage') {
        setRefreshConversation(!refreshConversation);
      }
      if (selectRefresh === 'drawer') {
        setRefreshDrawer(!refreshDrawer);
      }
      setIsThreadOpen(false);
      form.resetFields();
    } catch (error) {
      message(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      title={title(filter, selectedConversation, user, t)}
      bordered={false}
      className="conversation-new-message"
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item name="content" rules={[{ required: true }]}>
          <TextArea
            style={{ resize: 'none' }}
            placeholder={t('messages.placeholder.write_a_message')}
            autoSize={{
              minRows: 3,
              maxRows: 15
            }}
            className="conversation-textarea"
          />
        </Form.Item>
        <Flex justify="flex-end">
          <Button loading={loading} type="primary" htmlType="submit">
            {t('buttons.send')}
            <SendOutlined />
          </Button>
        </Flex>
      </Form>
    </Card>
  );
};

MessageWriterClaim.propTypes = {
  selectRefresh: PropTypes.string
};

MessageWriterClaim.defaultProps = {
  selectRefresh: ''
};
