// TODO: Add dynamic filters
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { FileExclamationOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * `useColumns` is a custom React hook that provides a list of column configurations.
 *
 * The column titles are fetched using the `react-i18next` hook for internationalization.
 *
 * @hook
 * @returns {Array<object>} Returns an array of column configurations to be consumed by a table component.
 */

export const useColumns = () => {
  const { dispatchAPI, company: companyId } = useAuthContext();
  const { message } = useErrorMessage();
  const isMounted = useRef(true);
  const { t } = useTranslation();
  const [config, setConfig] = useState({});

  const renderProducts = (text, record) => {
    const {
      fleet_sub_programmes,
      civil_liability_sub_programmes,
      transport_sub_programmes,
      construction_sub_programmes
    } = record.contract || {};
    const subProgrammesParts = [
      ...(fleet_sub_programmes || []),
      ...(civil_liability_sub_programmes || []),
      ...(transport_sub_programmes || []),
      ...(construction_sub_programmes || [])
    ];
    const subProgrammes = subProgrammesParts
      .filter((part) => typeof part === 'string')
      .map((part) => t(`${part.toLowerCase()}.title`))
      .join(', ');
    return subProgrammes || '-';
  };

  const renderAddress = (text, record) => {
    const { number, street, additional } = record.company_id || {};
    return number || street || additional
      ? `${number || ''} ${street || ''} ${additional || ''}`
      : '-';
  };

  const allColumns = [
    {
      title: t('properties.form.missing_documents'),
      key: 'missing_documents',
      dataIndex: ['missing_documents'],
      sorter: true,
      render: (missing_documents) =>
        missing_documents ? (
          <FileExclamationOutlined
            style={{ fontSize: 18, color: 'var(--warningColor)' }}
          />
        ) : (
          '-'
        )
    },
    {
      title: t('civil_liability.form.contract_number'),
      key: 'contract_number',
      dataIndex: ['contract', 'contract_number'],
      sorter: true
    },
    {
      title: t('civil_liability.form.company_name'),
      key: 'company_name',
      dataIndex: ['company_id', 'name'],
      sorter: true
    },
    {
      title: t('civil_liability.form.date_added'),
      key: 'created_at',
      dataIndex: ['created_at'],
      render: (created_at) =>
        created_at ? dayjs(created_at).format('DD/MM/YYYY') : '_',
      sorter: true
    },
    {
      title: t('civil_liability.form.validity_start_date'),
      key: 'validity_start_date',
      dataIndex: ['contract', 'validity_start_date'],
      render: (validity_start_date) =>
        validity_start_date
          ? dayjs(validity_start_date).format('DD/MM/YYYY')
          : '_',
      sorter: true
    },
    {
      title: t('civil_liability.form.validity_end_date'),
      key: 'validity_end_date',
      dataIndex: ['contract', 'validity_end_date'],
      render: (validity_end_date) =>
        validity_end_date ? dayjs(validity_end_date).format('DD/MM/YYYY') : '_',
      sorter: true
    },
    {
      title: t('civil_liability.form.entity'),
      key: 'entity',
      dataIndex: ['entity'],
      sorter: true,
      render: (entity) => (entity ? entity.map((e) => e.name).join(', ') : '-')
    },
    {
      title: t('civil_liability.form.products'),
      key: 'products',
      render: renderProducts
    },
    {
      title: t('civil_liability.form.subscriber_last_name_first_name'),
      key: 'subscriber_last_name_first_name',
      dataIndex: ['contract', 'subscriber_last_name_first_name'],
      sorter: true
    },
    {
      title: t('civil_liability.form.address'),
      key: 'address',
      render: renderAddress
    },
    {
      title: t('civil_liability.form.postal_code'),
      key: 'postal_code',
      dataIndex: ['contract', 'postal_code'],
      sorter: true
    },
    {
      title: t('civil_liability.form.city'),
      key: 'city',
      dataIndex: ['contract', 'city'],
      sorter: true
    },
    {
      title: t('civil_liability.form.site'),
      key: 'site',
      dataIndex: ['site', 'name'],
      sorter: true
    },
    {
      title: t('civil_liability.form.APE_code'),
      key: 'APE_code',
      dataIndex: ['APE_code'],
      render: (APE_code) => APE_code || '-',
      sorter: true
    },
    {
      title: t(
        'civil_liability.form.eighteen_months_span_statistic_number_of_employees'
      ),
      key: 'eighteen_months_span_statistic_number_of_employees',
      dataIndex: 'eighteen_months_span_statistic_number_of_employees',
      sorter: true,
      render: (eighteen_months_span_statistic_number_of_employees) =>
        (eighteen_months_span_statistic_number_of_employees &&
          t(
            `civil_liability.form.enums.${eighteen_months_span_statistic_number_of_employees}`
          )) ||
        '-'
    },
    {
      title: t('civil_liability.form.description_of_the_activity'),
      key: 'description_of_the_activity',
      dataIndex: 'description_of_the_activity',
      render: (description_of_the_activity) =>
        description_of_the_activity || '-',
      sorter: true
    }
  ];

  const getConfig = useCallback(async () => {
    if (!isMounted.current) return;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/programme-configs/${companyId}/civil-liability-configs`
      });
      setConfig(data[0]);
    } catch (e) {
      if (isMounted.current) message('missing_parameters');
    }
  }, [dispatchAPI, message]);

  useEffect(() => {
    getConfig();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const filteredColumns = allColumns.filter((column) => config[column.key]);

  return filteredColumns || [];
};
