import { useCallback, useEffect, useState, forwardRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Row, Spin, notification } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ContentCustom } from '../ContentCustom/ContentCustom';
import { PageHeaderCustom } from '../PageHeader/PageHeader';
import { useGenerateFormItem } from '../../utils/generateFormItem/generateFormItem';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../utils/constants/formLayout';
import FilesManager from './FilesManager/FilesManagerWithDisplay';
import { handleFormDataWithFiles } from './utils/handleFormDataWithFiles';
import { handleFileActionsOnFetch } from './utils/handleFileActionsOnFetch';
import { checkDraggerTypes } from './utils/checkDraggerTypes';
import { useDownloadDocument } from '../../utils/downloadDoc';
import { checkMandatoryDocuments } from './utils/checkMandatoryDocuments';
import { ErrorModal } from '../ErrorModal/ErrorModal';

/**
 * `CreateUpdateContainer` is a React component designed to create or update resources.
 * It provides a form with fields specified in the `fields` prop and has the capability to manage file uploads.
 * The component's main function is to create or update data on a given API endpoint specified by `baseUrl`.
 *
 * @component
 * @param {Object} props - Properties passed to the component.
 * @param {string} props.purpose - Purpose of the form, e.g., 'edit' or 'create'.
 * @param {Array} props.fields - Fields configuration for the form.
 * @param {string} props.baseUrl - Base API endpoint for CRUD operations.
 * @param {string} props.resource - Name of the resource for translations and other functionalities.
 * @param {boolean} [props.loadingFields=false] - Boolean indicating if the fields are still loading.
 * @param {Object} [props.config={}] - Configuration for API operations.
 * @param {JSX.Element} [props.formExtra=null] - Additional JSX components or elements to render within the form.
 * @param {string} [props.tradKey=null] - Translation key for the resource.
 * @param {string} [props.submitLabel=null] - Label for the submit button.
 * @param {Function} [props.customSubmit=null] - Custom function for submit action.
 * @param {boolean} [props.isParentLoading=false] - Boolean indicating if the parent component is loading.
 * @param {boolean} [props.withFilesManager=true] - Boolean indicating if file manager functionality is included.
 * @param {string} [props.idWithoutParams=null] - ID associated with the resource without parameters.
 * @param {boolean} [props.withPageHeaderCustom=true] - Boolean indicating if custom page header is included.
 * @param {JSX.Element} [props.extraTitle=null] - Additional title JSX component or element.
 * @param {Array} [props.mandatoryDocuments=null] - Array of string to define submit blocking setUp.
 * @param {Array} [props.draggerFilesKeysOverRide=null] - Allows to manually set draggerFileKeys.
 *
 * @returns {JSX.Element} The `CreateUpdateContainer` component.
 */

export const CreateUpdateContainer = forwardRef(
  (
    {
      purpose,
      fields,
      loadingFields,
      resource,
      baseUrl,
      config,
      formExtra,
      tradKey,
      submitLabel,
      customSubmit,
      isParentLoading,
      withFilesManager,
      extraTitle,
      idWithoutParams,
      withPageHeaderCustom,
      title,
      draggerFilesKeysMandatory,
      populate,
      urlFileKeys,
      draggerFilesKeysOverRide,
      mandatoryDocuments,
      noDelete,
      messageOnSuccess,
      createUrl,
      editUrl,
      setCurrentEntityContext
    },
    ref
  ) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { message } = useErrorMessage();
    const { dispatchAPI } = useAuthContext();
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [draggerFilesList, setDraggerFilesList] = useState([]);
    const [fieldsFilesList, setFieldsFileList] = useState([]);
    const [filesConfiguration, setFilesConfiguration] = useState([]);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [draggerFilesKeys, setDraggerFilesKeys] = useState([]);
    const [form] = Form.useForm();
    const { onGetResource, onCreateResource, onUpdateResource } = config;
    const { downloadDocument, viewDocument } = useDownloadDocument();

    const getResourceFilesKeys = async () => {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `${urlFileKeys || editUrl}/enums`
        });
        if (data.fileKeys && data.fileKeys.length !== 0) {
          setDraggerFilesKeys(
            data.fileKeys
              .filter((enumItem) => enumItem.source === 'dragger')
              .map((enumItem) => enumItem.key)
          );
          const transformedObject = {};

          data.fileKeys.forEach((item) => {
            if (item.source === 'field') {
              transformedObject[item.key] = [];
            }
          });
          if (draggerFilesKeysOverRide) {
            setDraggerFilesKeys(draggerFilesKeysOverRide);
          }
          setFieldsFileList(transformedObject);
        }
        return setIsLoading(false);
      } catch (e) {
        return message(e);
      }
    };

    const updateResource = async (body, files) => {
      setIsSubmitting(true);
      const formData = new FormData();

      handleFormDataWithFiles(
        files,
        draggerFilesList,
        formData,
        filesConfiguration,
        purpose
      );
      const values =
        onUpdateResource && onUpdateResource.setBody
          ? onUpdateResource.setBody(body)
          : body;

      formData.append(
        'values',
        JSON.stringify({
          ...values
        })
      );

      try {
        await dispatchAPI('PATCH', {
          url: `${editUrl}/${idWithoutParams || id}`,
          body: formData
        });
        navigate(-1);
      } catch (e) {
        setIsSubmitting(false);
        message(e);
      }
    };

    const createResource = async (body, files) => {
      setIsSubmitting(true);
      const formData = new FormData();
      handleFormDataWithFiles(
        files,
        draggerFilesList,
        formData,
        filesConfiguration,
        purpose
      );
      const values =
        onCreateResource && onCreateResource.setBody
          ? onCreateResource.setBody(body)
          : body;

      formData.append(
        'values',
        JSON.stringify({
          ...values
        })
      );

      try {
        await dispatchAPI('POST', {
          url: `${editUrl}/original-entry/${idWithoutParams || id}`,
          body: formData
        });
        if (messageOnSuccess) {
          notification.success({ message: t('export.messages.success') });
        }
        navigate(-1);
      } catch (e) {
        setIsSubmitting(false);
        message(e);
      }
    };

    const deleteFile = async (fileID) => {
      try {
        await dispatchAPI('PATCH', {
          url: `${editUrl}/${id || idWithoutParams}/${fileID}`
        });
      } catch (e) {
        message(e);
      }
    };

    const getResource = useCallback(async () => {
      setIsLoading(true);
      let url = '';
      if (purpose === 'create') {
        url = createUrl;
      } else {
        url = editUrl;
      }
      try {
        const { data } = await dispatchAPI('GET', {
          url: `${url}/${id}${populate}`
        });
        if (setCurrentEntityContext && data.entity) {
          setCurrentEntityContext(data.entity);
        }
        if (data.documents) {
          handleFileActionsOnFetch(
            data,
            setFieldsFileList,
            setDraggerFilesList,
            setFilesConfiguration,
            dispatchAPI,
            message
          );
          form.setFieldsValue(
            onGetResource && onGetResource.setFields
              ? onGetResource.setFields(data)
              : data
          );
        } else {
          form.setFieldsValue(
            onGetResource && onGetResource.setFields
              ? onGetResource.setFields(data)
              : data
          );
        }
      } catch (e) {
        message(e);
      }
      setIsLoading(false);
    }, [purpose, id, loadingFields, baseUrl, idWithoutParams]);

    useEffect(() => {
      if (!loadingFields) {
        setIsLoading(true);
        (async () => {
          if (
            (purpose === 'edit' || purpose === 'create') &&
            (id || idWithoutParams)
          ) {
            await getResource();
          }
          await getResourceFilesKeys();
        })();
      }
    }, [getResource, id, idWithoutParams]);

    const handleSubmit = async (values) => {
      const extractedFileKeys = filesToUpload.map(
        (fileObject) => fileObject.file
      );
      const mandatoryDocumentsArePresent = checkMandatoryDocuments(
        mandatoryDocuments,
        filesConfiguration
      );
      const boolean = checkDraggerTypes(draggerFilesList, filesConfiguration);
      switch (true) {
        case !mandatoryDocumentsArePresent:
          return setIsOpen(true);
        case !boolean:
          return message(t('missing_types'));
        case customSubmit:
          return customSubmit(values, extractedFileKeys);
        case purpose === 'edit':
          await updateResource(values, extractedFileKeys);
          return true;
        default:
          await createResource(values, extractedFileKeys);
      }

      return true;
    };
    const generateFieldsMemoized = useCallback(
      useGenerateFormItem(
        fieldsFilesList,
        setFieldsFileList,
        filesConfiguration,
        setFilesConfiguration,
        purpose,
        deleteFile,
        setFilesToUpload,
        downloadDocument,
        viewDocument
      ),
      [fieldsFilesList, filesConfiguration]
    );
    return (
      <>
        {withPageHeaderCustom && (
          <PageHeaderCustom
            title={title || t(`${resource}.form.title.${purpose}`)}
          />
        )}
        {extraTitle}
        <ContentCustom>
          <Spin spinning={isLoading || isParentLoading}>
            <Form
              ref={ref}
              {...formItemLayout}
              onFinish={handleSubmit}
              form={form}
            >
              <>
                {fields.map((field) =>
                  generateFieldsMemoized(tradKey || resource, field)
                )}
                {formExtra}
              </>
              {withFilesManager && (
                <FilesManager
                  filesList={draggerFilesList}
                  draggerFilesKeysMandatory={draggerFilesKeysMandatory}
                  setFilesList={setDraggerFilesList}
                  filesKeys={draggerFilesKeys}
                  filesConfiguration={filesConfiguration}
                  setFilesConfiguration={setFilesConfiguration}
                  purpose={purpose}
                  deleteFile={deleteFile}
                  noDelete={noDelete}
                />
              )}

              <Form.Item {...tailFormItemLayout}>
                <Row justify="end">
                  <Button
                    style={{ margin: '0 8px' }}
                    type="link"
                    danger
                    onClick={() => navigate(-1)}
                  >
                    {`${t('buttons.cancel')} `}
                    <CloseOutlined />
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isSubmitting}
                  >
                    {`${t(submitLabel || 'buttons.save')} `}
                    <CheckOutlined />
                  </Button>
                </Row>
              </Form.Item>
            </Form>
            <ErrorModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              title={t('errors.message.missing_mandatory_documents')}
              details={mandatoryDocuments}
              tradKey="files.keys"
            />
          </Spin>
        </ContentCustom>
      </>
    );
  }
);

CreateUpdateContainer.propTypes = {
  purpose: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  baseUrl: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  loadingFields: PropTypes.bool,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    }),
    onCreateResource: PropTypes.shape({
      setBody: PropTypes.func
    }),
    onUpdateResource: PropTypes.shape({
      setBody: PropTypes.func
    })
  }),
  formExtra: PropTypes.element,
  tradKey: PropTypes.string,
  submitLabel: PropTypes.string,
  customSubmit: PropTypes.func,
  isParentLoading: PropTypes.bool,
  withFilesManager: PropTypes.bool,
  idWithoutParams: PropTypes.string,
  withPageHeaderCustom: PropTypes.bool,
  extraTitle: PropTypes.element,
  title: PropTypes.string,
  draggerFilesKeys: PropTypes.arrayOf(PropTypes.string),
  draggerFilesKeysMandatory: PropTypes.arrayOf(PropTypes.string),
  populate: PropTypes.string,
  urlFileKeys: PropTypes.string,
  mandatoryDocuments: PropTypes.arrayOf(PropTypes.string),
  draggerFilesKeysOverRide: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string,
  noDelete: PropTypes.bool,
  messageOnSuccess: PropTypes.bool,
  createUrl: PropTypes.string,
  editUrl: PropTypes.string,
  setCurrentEntityContext: PropTypes.func
};

CreateUpdateContainer.defaultProps = {
  config: {},
  loadingFields: false,
  formExtra: null,
  tradKey: null,
  submitLabel: null,
  customSubmit: null,
  isParentLoading: false,
  withFilesManager: true,
  idWithoutParams: null,
  withPageHeaderCustom: true,
  extraTitle: null,
  title: null,
  draggerFilesKeys: [],
  draggerFilesKeysMandatory: [],
  populate: '',
  urlFileKeys: null,
  mandatoryDocuments: [],
  draggerFilesKeysOverRide: null,
  type: null,
  noDelete: false,
  messageOnSuccess: false,
  createUrl: null,
  editUrl: null,
  setCurrentEntityContext: null
};
