/**
 * Constants for the assembly testing insurance claim module.
 *
 * @typedef {Object} Constants
 * @property {string} productAPI - The API endpoint for assembly testing insurances.
 * @property {string} claimUrl - The URL for assembly testing insurance claims.
 * @property {string} claimAPI - The API endpoint for assembly testing insurance claims.
 * @property {string} resource - The resource name for assembly testing insurance claims.
 */

/**
 * Constants for the assembly testing insurance claim module.
 *
 * @type {Constants}
 */
export const constants = {
  productTradKey: 'environment_insurances',
  productAPI: 'environment-insurances',
  claimUrl: 'environment-insurance-claim',
  claimAPI: 'environment_insurance_claims',
  resourceClaim: 'Environment_insurance_claim',
  populateProductShow: 'customer_manager,unit_manager,contract,entity,company',
  populateClaimShow: 'entry_id,unit_manager,entity,company,customer_manager'
};
