import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Form, Row } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

/**
 * Reducer function for generating multiple form fields dynamically.
 *
 * @component
 * @param {Object} props - The props object.
 * @param {Array} props.fields - The array of fields to be rendered in the form.
 * @param {string} props.dbKey - The key used to identify the form field in the database.
 * @param {string} props.tradKey - The key used for translation.
 * @param {string} props.additionalTranslation - Additional translation key (optional).
 * @param {number} props.formCount - The current count of form fields.
 * @param {Object} props.form - The antd Form instance.
 * @returns {JSX.Element} - The JSX element representing the form fields.
 */
export const MultiFormFieldsGenerationReducer = ({
  fields,
  dbKey,
  tradKey,
  additionalTranslation,
  formCount,
  form
}) => {
  const { t } = useTranslation();
  const currentCount = useRef(formCount);

  useEffect(() => {
    if (formCount !== currentCount.current) {
      if (formCount < currentCount.current) {
        const allFieldsValues = form.getFieldsValue();

        const newFieldsValues = { ...allFieldsValues };
        if (newFieldsValues.sites_adress_and_if_under_authorization) {
          const startIndex = formCount;
          const deleteCount = currentCount.current - formCount;
          newFieldsValues.sites_adress_and_if_under_authorization.splice(
            startIndex,
            deleteCount
          );
        }

        form.setFieldsValue(newFieldsValues);
      }
      currentCount.current = formCount;
    }
  }, [formCount]);

  const formList = ({ remove }, count) => (
    <>
      {[...Array(count).keys()].map((index) => (
        <Form.Item key={index} className="agriculture">
          <Row justify="space-between">
            <span>
              {t(`${tradKey}.form.${additionalTranslation || dbKey}`)}&nbsp;
              {index + 1}
            </span>
            <CloseOutlined onClick={() => remove(index)} />
            {fields.map(({ name, ...restField }) => (
              <Row key={`${name[0]}-${index}`}>
                <Form.Item
                  name={[index, ...name]}
                  label={t(`${tradKey}.form.${name[0]}`)}
                  {...restField}
                >
                  {restField.input ? restField.input : <Input />}
                </Form.Item>
              </Row>
            ))}
          </Row>
        </Form.Item>
      ))}
    </>
  );
  return (
    <Form.List name={dbKey}>
      {(fieldsData, { add, remove }) => (
        <>{formList({ add, remove }, formCount)}</>
      )}
    </Form.List>
  );
};

MultiFormFieldsGenerationReducer.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.arrayOf(PropTypes.string).isRequired
    })
  ).isRequired,
  dbKey: PropTypes.arrayOf(PropTypes.string).isRequired,
  tradKey: PropTypes.string.isRequired,
  additionalTranslation: PropTypes.string,
  formCount: PropTypes.number,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func,
    getFieldsValue: PropTypes.func
  }).isRequired
};

MultiFormFieldsGenerationReducer.defaultProps = {
  additionalTranslation: undefined,
  formCount: 0
};
