import PropTypes from 'prop-types';
import { Card } from 'antd';
import { useState, useEffect } from 'react';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';

import useNoFields from '../../components/noFieldsForDragger';

/**
 * `Documents` is a React component used for displaying and managing documents.
 * It employs the `CreateUpdateContainer` to achieve its main functionality and is utilized primarily
 * in the context of editing vehicle-related documents.
 *
 * @component
 * @param {Object} props - Properties passed to the component.
 * @param {string} [props.idWithoutParams=null] - The ID associated with the displayed topic document without any parameters.
 * @param {string} props.baseUrl - The base URL of the displayed topic document.
 * @param {string} props.resource - The resource of the displayed topic document.
 * @param {string[]} props.draggerFilesKeysOverRide - The keys of the files to be displayed in the `Dragger` component.
 * @param {string[]} props.draggerFilesKeysMandatory - The keys of the mandatory files to be displayed in the `Dragger` component.
 *
 * @returns {JSX.Element} The `Documents` component.
 */

export const Documents = ({
  idWithoutParams,
  baseUrl,
  resource,
  draggerFilesKeysOverRide,
  mandatoryDocuments
}) => {
  const { noFields } = useNoFields();

  const [loaded, setLoaded] = useState(false);

  const config = {
    onUpdateResource: {
      setBody: (data) => ({
        ...data
      })
    }
  };
  useEffect(() => {
    setLoaded(true);
  }, [draggerFilesKeysOverRide]);
  return (
    <Card className="show-doc-card">
      {idWithoutParams &&
        baseUrl &&
        resource &&
        loaded &&
        draggerFilesKeysOverRide.length > 0 && (
          <CreateUpdateContainer
            fields={noFields}
            purpose="edit"
            baseUrl={baseUrl}
            resource={resource}
            config={config}
            withFilesManager
            withPageHeaderCustom={false}
            idWithoutParams={idWithoutParams}
            draggerFilesKeysOverRide={draggerFilesKeysOverRide}
            mandatoryDocuments={mandatoryDocuments}
            populate="?populate=documents.file"
          />
        )}
    </Card>
  );
};

Documents.propTypes = {
  idWithoutParams: PropTypes.string,
  baseUrl: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  draggerFilesKeysOverRide: PropTypes.arrayOf(PropTypes.string),
  mandatoryDocuments: PropTypes.arrayOf(PropTypes.string)
};

Documents.defaultProps = {
  idWithoutParams: null,
  draggerFilesKeysOverRide: null,
  mandatoryDocuments: []
};
