import { useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { Row, Divider, List, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useConversationContext } from '../../contexts/ConversationContext';

/**
 * Composant personnalisé pour afficher un fil de messages.
 *
 * @component
 * @returns {JSX.Element} - Composant personnalisé pour afficher un fil de messages.
 */
export const Thread = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { selectedConversation, loading } = useConversationContext();

  const backgroundColor = ({ from: { _id } }) =>
    _id === user._id ? '#F0F0F0' : '#314B49';

  const textColor = ({ from: { _id } }) =>
    _id === user._id ? 'black' : '#FFFFFF';

  const currentDate = dayjs().startOf('day');

  const messageTitle = ({ from, date }) => {
    const isUserMessage = from._id === user._id;
    const color = isUserMessage ? 'black' : '#FFFFFF';
    return (
      <Row justify="space-between" style={{ color }}>
        <span>{`${from.first_name} ${from.last_name}`}</span>
        <span>{dayjs(date).format('HH:mm')}</span>
      </Row>
    );
  };

  const justify = ({ from: { _id } }) => (_id === user._id ? 'end' : 'start');

  /**
   * Display a divider if the current message is the first of the day
   * @param {object} item - Current message
   * @param {number} index - Current index
   * @returns {boolean} - True if the current message is the first of the day
   */
  const displayDivider = (item, index) => {
    if (index === 0) return true;
    const previousMessage = selectedConversation?.messages[index - 1];
    const itemDate = dayjs(item.date).startOf('day');
    const previousMessageDate = dayjs(previousMessage.date).startOf('day');
    return (
      itemDate.isSame(currentDate, 'day') &&
      !previousMessageDate.isSame(currentDate, 'day')
    );
  };

  /**
   * Display a formatted date
   * @param {object} date - Current date of the message
   * @returns {string} - Date to display
   */
  const dateFormat = (date) => {
    const itemDate = dayjs(date).startOf('day');
    const dayDiff = currentDate.diff(itemDate, 'days');

    switch (dayDiff) {
      case 0:
        return t('messages.form.today');
      case 1:
        return t('messages.form.yesterday');
      case 2:
        return t('messages.form.before_yesterday');
      default:
        return itemDate.format('DD/MM/YYYY');
    }
  };

  const scrollableDivRef = useRef(null);
  useEffect(() => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop =
        scrollableDivRef.current.scrollHeight;
    }
  }, [selectedConversation.messages]);

  return (
    <div id="scrollableDiv" ref={scrollableDivRef} className="message-thread">
      <InfiniteScroll
        dataLength={selectedConversation?.messages?.length}
        hasMore={selectedConversation?.messages?.length > 10}
        inverse
        loader={<Skeleton loading={loading} avatar paragraph={{ rows: 1 }} />}
        scrollableTarget="scrollableDiv"
      >
        <List
          loading={loading}
          itemLayout="horizontal"
          dataSource={selectedConversation?.messages || []}
          className="messages-list"
          renderItem={(item, index) => (
            <>
              {displayDivider(item, index) && (
                <Divider className="divider-thread " orientationMargin={0}>
                  <span
                    style={{
                      backgroundColor: '#F0F0F0 ',
                      borderRadius: '26px',
                      padding: '4px 8px',
                      width: '100%'
                    }}
                  >
                    {dateFormat(item.date)}
                  </span>
                </Divider>
              )}
              <Row
                justify={justify(item)}
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: justify(item)
                }}
              >
                <List.Item
                  className={
                    item.from._id === user._id
                      ? 'message-list-item right'
                      : 'message-list-item left'
                  }
                  style={{
                    width: '50%',
                    overflowWrap: 'break-word',
                    backgroundColor: backgroundColor(item),
                    color: textColor(item),
                    padding: '8px',
                    marginBottom: '8px',
                    borderTopRightRadius:
                      item.from._id === user._id ? '0' : '8px',
                    borderTopLeftRadius:
                      item.from._id === user._id ? '8px' : '0px',
                    borderBottomRightRadius:
                      item.from._id === user._id ? '8px' : '8px',
                    borderBottomLeftRadius:
                      item.from._id === user._id ? '8px' : '8px'
                  }}
                >
                  <List.Item.Meta
                    title={
                      <div style={{ color: textColor(item) }}>
                        {messageTitle(item)}
                      </div>
                    }
                    description={
                      <span style={{ color: textColor(item) }}>
                        {item?.content}
                      </span>
                    }
                  />
                </List.Item>
              </Row>
            </>
          )}
        />
      </InfiniteScroll>
    </div>
  );
};
