import { Form, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { OutOfNavLayout } from './OutOfNavLayout';
import { ReactComponent as GolfLogo } from '../../assets/images/tiles/golf.svg';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import useIntGolfFields from './IntGolfFields';
import { WelcomeGolf } from './WelcomeGolf';
import ThanksGolf from './ThanksGolf';
import { useHandleResize } from '../../utils/handleResize';

/**
 * Represents the InterruptionGolf component.
 * This component is responsible for rendering the Interruption Golf form and its steps.
 *
 * @component
 * @returns {JSX.Element} The InterruptionGolf component
 */
const InterruptionGolf = () => {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState('interruption_golf');
  const [step, setStep] = useState(0);
  const [form] = Form.useForm();
  const { width } = useHandleResize();
  const confirmation_information = t(
    'interruption_golf.form.confirmation_information'
  );

  const { fields } = useIntGolfFields(
    selectedOptions,
    setSelectedOptions,
    form
  );

  const afterValidSubmit = () => {
    notification.success({
      message: t('interruption_golf.messages.success')
    });
    setStep(2);
  };

  const coordenees = (
    <>
      <p>
        <strong>{t('interruption_golf.postal_address_title')}</strong>
      </p>
      <p>{t(`interruption_golf.postal_address`)}</p>
      <p>
        <strong>{t('interruption_golf.email_title')}:</strong>
      </p>
      <p>{t('interruption_golf.email')}</p>
    </>
  );

  const allFilesAreMandatoryConditions = (
    draggerFilesKeys,
    setAllFilesAreMandatoryArray
  ) => {
    const filteredFilesKeys = draggerFilesKeys.filter((key) => {
      if (
        selectedOptions.stoppage_duration === 'more_3_months' &&
        key === 'work_stop'
      ) {
        return false;
      }
      return !['bulletin_hospital'].includes(key);
    });
    return setAllFilesAreMandatoryArray(filteredFilesKeys);
  };

  return (
    <div
      style={{
        width: '100',
        height: '100%',
        maxHeight: '100vh'
      }}
    >
      <OutOfNavLayout LogoQuest={GolfLogo} coordenees={coordenees}>
        {step === 0 && <WelcomeGolf setStep={setStep} />}
        {step === 1 && (
          <CreateUpdateContainer
            purpose="create"
            baseUrl="interruption-golfs"
            resource="interruption_golf"
            fields={fields}
            machinaryStatusType={selectedOptions}
            withFilesManager
            customCancel={() => setStep(0)}
            allFilesAreMandatory
            allFilesAreMandatoryConditions={allFilesAreMandatoryConditions}
            noDynamiqueRoute
            noReturnButton
            parentForm={form}
            confirmationCheckboxMessage={confirmation_information}
            customPostCreate={afterValidSubmit}
            layout={width < 993 ? 'vertical' : 'horizontal'}
          />
        )}

        {step === 2 && <ThanksGolf />}
      </OutOfNavLayout>
    </div>
  );
};

export default InterruptionGolf;
