import PropTypes from 'prop-types';
import { useState, useCallback, useEffect } from 'react';
import { Button } from 'antd';
import { ImportOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { MailingBlank } from './Modals/MailingBlank';
import { useErrorMessage } from '../../utils/errorMessage';

/**
 * A button component used for mailing functionality.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.asset - The asset object.
 * @param {string} props.templateName - The name of the template.
 * @param {string} props.tradKey - The translation key.
 * @param {string} props.resource - The resource string.
 * @param {string} props.baseUrl - The base URL.
 * @returns {JSX.Element} The rendered component.
 */
export const MailingButton = ({
  asset,
  templateName,
  tradKey,
  resource,
  baseUrl,
  changingStatus,
  setChangingStatus
}) => {
  const [isModalMailingOpen, setIsModalMailingOpen] = useState(false);
  const [templateMail, setTemplateMail] = useState();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();

  const getTemplateMail = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/templates-mails/name/${templateName}?populate=documents,documents.file`
      });
      setTemplateMail(data);
    } catch (e) {
      message(e);
    }
  }, [dispatchAPI, templateName, message]);

  const onClick = async () => {
    await getTemplateMail();
    setIsModalMailingOpen(true);
  };

  useEffect(() => {
    if (changingStatus) {
      getTemplateMail();
    }
    if (!changingStatus) {
      setTemplateMail(null);
    }
  }, [changingStatus]);

  return (
    <>
      {!changingStatus && tradKey && (
        <Button onClick={() => onClick()}>
          {`${t(`buttons.${tradKey}`)} `}
          <ImportOutlined />
        </Button>
      )}
      <MailingBlank
        isModalMailingOpen={changingStatus || isModalMailingOpen}
        setIsModalMailingOpen={() => {
          setIsModalMailingOpen(false);
          if (setChangingStatus) setChangingStatus(false);
        }}
        idWithoutParams={asset?._id}
        templateMail={templateMail}
        datas={asset}
        resource={resource}
        baseUrl={baseUrl}
        templateName={tradKey}
        isModal
        closeModal={() => {
          setIsModalMailingOpen(false);
          if (setChangingStatus) setChangingStatus(false);
        }}
      />
    </>
  );
};

MailingButton.propTypes = {
  asset: PropTypes.shape({
    _id: PropTypes.string
  }),
  templateName: PropTypes.string.isRequired,
  tradKey: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
  changingStatus: PropTypes.bool,
  setChangingStatus: PropTypes.func
};

MailingButton.defaultProps = {
  asset: {},
  changingStatus: false,
  setChangingStatus: () => {}
};
