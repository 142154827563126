import PropTypes from 'prop-types';
import { useEffect, useState, useCallback } from 'react';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainerV3/CreateUpdateContainerModified';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

/**
 * A component that facilitates creating or editing resources.
 * Depending on the existing configuration, this component can either be used
 * to create a new resource or edit an existing one.
 *
 * Internally, it uses the `CreateUpdateContainer` component, passing necessary
 * configurations for resource handling.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {Array<Object>} props.fields - Fields configurations for the resource.
 * @param {string} props.configType - The configuration type for the resource API.
 * @param {string} props.resource - The resource's name.
 * @param {Array<string>} props.arr - An array of string values for creating resources.
 *
 * @returns {ReactElement} The rendered `CreateEditSelect` component.
 */

export const CreateEditSelect = ({ fields, configType, resource }) => {
  const [isConfig, setIsConfig] = useState('');
  const [purpose, setPurpose] = useState('');
  const { company, companyName, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const getConfig = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/programme-configs/${company}/${configType}`
      });
      if (data) {
        setIsConfig(data[0]._id);
        setPurpose('edit');
      } else {
        setPurpose('create');
      }
    } catch (e) {
      message(e);
    }
  }, []);

  useEffect(() => {
    if (configType) {
      (async () => {
        await getConfig();
      })();
    }
  }, []);

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data[0]
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        company_Id: company,
        companyName
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data
      })
    }
  };

  return (
    <>
      {purpose && !isConfig ? (
        <CreateUpdateContainer
          fields={fields}
          purpose="create"
          baseUrl={configType}
          resource={resource}
          config={config}
          withFilesManager={false}
          WithPageHeaderCustom={false}
        />
      ) : null}
      {purpose && isConfig ? (
        <CreateUpdateContainer
          fields={fields}
          purpose="edit"
          baseUrl="programme-configs"
          resource={resource}
          config={config}
          withFilesManager={false}
          WithPageHeaderCustom={false}
          idWithoutParams={company && company}
          extraParams={`/${configType}`}
        />
      ) : null}
    </>
  );
};

CreateEditSelect.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  configType: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired
};
