import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select, Form } from 'antd';

const { Option } = Select;
const { Item } = Form;

/**
 * A custom select component for choosing values from a list of enums.
 * @component
 * @param {object} props - The component's props.
 * @param {string} props.dbKey - The key to use in the form.
 * @param {object} props.enums - The list of enums.
 * @returns {JSX.Element} The JSX element representing the select component.
 */
export const GenericSelectEnums = ({
  dbKey,
  enums,
  isMultiple,
  isDisabled,
  tradKeyEnums
}) => {
  const { t } = useTranslation();

  if (isMultiple) {
    return (
      <Item name={dbKey}>
        {enums ? (
          <Select
            mode="multiple"
            showSearch
            disabled={isDisabled}
            filterOption={(inputValue, option) =>
              (option?.title ?? '')
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            }
          >
            {enums.map((v) => {
              const title = tradKeyEnums && t(`${tradKeyEnums}.${v}`);
              return (
                <Option key={v} value={v} title={title}>
                  {title}
                </Option>
              );
            })}
          </Select>
        ) : null}
      </Item>
    );
  }
  return (
    <Item name={dbKey}>
      {enums ? (
        <Select disabled={isDisabled}>
          {enums.map((v) => (
            <Option key={v} value={v}>
              {t(`${tradKeyEnums}.${v}`)}
            </Option>
          ))}
        </Select>
      ) : null}
    </Item>
  );
};

GenericSelectEnums.propTypes = {
  dbKey: PropTypes.arrayOf(PropTypes.string).isRequired,
  enums: PropTypes.arrayOf(PropTypes.string),
  isMultiple: PropTypes.bool,
  isDisabled: PropTypes.bool,
  tradKeyEnums: PropTypes.string.isRequired
};

GenericSelectEnums.defaultProps = {
  enums: [],
  isMultiple: false,
  isDisabled: false
};
