import PropTypes from 'prop-types';
import { Flex, Button } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * @description Filter by missing documents and reset filters
 * @param {Object} props Props
 * @param {Function} props.onChange On change
 * @param {Function} props.setResetFilters Set reset filters
 * @returns {React.ReactNode} Filter by missing documents
 */

export const ResetFilterButton = ({ setResetFilters }) => {
  const { t } = useTranslation();

  const handleReset = () => {
    setResetFilters(true);
  };

  return (
    <Flex gap="small" align="center">
      <Button onClick={handleReset}>
        {t('buttons.reset_dynamic_filters')}
      </Button>
    </Flex>
  );
};

ResetFilterButton.propTypes = {
  setResetFilters: PropTypes.func.isRequired
};
