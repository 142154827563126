import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Modal, Row, Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDownloadDocument } from '../../../../../utils/downloadDoc';
import { useGetCertificates } from '../AddCertificateModal/getCetificates';

const iconSize = '18px';

/**
 * DownloadCertificateModal component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.resourceName - The name of the resource.
 * @param {string} props.resourceId - The ID of the resource.
 * @param {string} props.rowKey - The key of the row.
 * @param {string} props.idWithoutParams - The ID without parameters.
 * @returns {JSX.Element} The DownloadCertificateModal component.
 */
export const DowndoadCertificateModal = ({
  resourceName,
  resourceId,
  rowKey,
  idWithoutParams
}) => {
  const [visible, setVisible] = useState(false);
  const openCondition = resourceId ? visible === true : visible === rowKey;
  const [draggerFilesList, setDraggerFilesList] = useState([]);
  const { downloadDocument } = useDownloadDocument();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { getResource } = useGetCertificates({
    resourceId,
    resourceName,
    idWithoutParams,
    setDraggerFilesList,
    setFilesConfiguration: () => {},
    setIsLoading
  });

  useEffect(() => {
    if (visible) getResource();
  }, [visible]);

  return (
    <>
      <Modal
        width={800}
        footer={false}
        loading={isLoading}
        open={openCondition}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <Card
          title={t('files.download_certificate_title')}
          className="files-manager"
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={16}>
              {draggerFilesList?.map((item) => (
                <Card size="small" key={item.name}>
                  <Row justify="space-between" align="middle" gutter={[16, 16]}>
                    <Col xs={24} md={12} lg={10}>
                      <span className="file-name">{item.name}</span>
                    </Col>
                    <Col>
                      <Row justify="end" align="middle" gutter={[8, 8]}>
                        <Col xs={24} md={12} lg={8}>
                          <Row justify="end" gutter={[8, 8]}>
                            <Button
                              onClick={() => downloadDocument(item.file)}
                              type="link"
                              icon={<DownloadOutlined />}
                            />
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              ))}
            </Col>
          </Row>
        </Card>
      </Modal>
      <Tooltip title={t('buttons.add_certificate')}>
        {!idWithoutParams ? (
          <Button
            onClick={() => {
              setVisible(resourceId ? true : rowKey);
            }}
          >
            {`${t('buttons.add_certificate')}`}
          </Button>
        ) : (
          <DownloadOutlined
            onClick={() => {
              setVisible(resourceId ? true : rowKey);
            }}
            style={{ fontSize: iconSize }}
          />
        )}
      </Tooltip>
    </>
  );
};

DowndoadCertificateModal.propTypes = {
  resourceName: PropTypes.string,
  resourceId: PropTypes.string,
  rowKey: PropTypes.string,
  idWithoutParams: PropTypes.bool
};

DowndoadCertificateModal.defaultProps = {
  resourceName: '',
  resourceId: '',
  rowKey: '',
  idWithoutParams: false
};
