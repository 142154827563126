import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Menu, Badge } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import navMenuLogos from '../../utils/constants/navMenuLogos';
import { useMiscellaneousContext } from '../../contexts/MiscellaneousContext';
import {
  routes,
  subRoutes,
  pathSearches
} from '../../utils/constants/accountancyRoutes';

/**
 * Renders the accountancy navigation menu.
 * @param {Object} props - The component props.
 * @param {Function} props.setCollapsed - The function to set the collapsed state.
 * @param {number} props.width - The width of the component.
 * @returns {JSX.Element} - The rendered component.
 */
export const AccountancyNavMenu = ({ setCollapsed, width }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { notReadMessagesCount } = useMiscellaneousContext();
  const { notReadMessages, notReadClaimMessages } = notReadMessagesCount;
  const path = location?.pathname || '/';
  const activeKeys = path
    .replace('/', '')
    .split('/')
    .map((p) => `/${p}`);

  return (
    <Menu
      style={{
        padding: '16px 0',
        background: 'var(--primaryColor)',
        maxHeight: 'calc(100% - 191px)',
        overflowY: 'auto',
        overflowX: 'hidden'
      }}
      selectedKeys={activeKeys}
      defaultOpenKeys={activeKeys}
      mode="inline"
      onSelect={width < 992 ? () => setCollapsed(true) : () => {}}
      items={Object.entries(routes).map(([menuItem, p]) =>
        subRoutes[menuItem]
          ? {
              key: p,
              icon: navMenuLogos[menuItem],
              label: t(`basiclayout.menu.${menuItem.toLowerCase()}`),
              children: Object.entries(subRoutes[menuItem]).map(
                ([subMenuItem, subPath]) => ({
                  key: subPath,
                  disabled: subPath === '',
                  label: (
                    <NavLink
                      to={`${p}${subPath}${
                        pathSearches[subMenuItem]
                          ? pathSearches[subMenuItem]
                          : ''
                      }`}
                    >
                      <span>
                        {t(`basiclayout.submenu.${subMenuItem.toLowerCase()}`)}
                      </span>
                    </NavLink>
                  )
                })
              )
            }
          : {
              key: p,
              disabled: p === '',
              label: (
                <NavLink
                  to={`${p}${
                    pathSearches[menuItem] ? pathSearches[menuItem] : ''
                  }`}
                >
                  {navMenuLogos[menuItem]}
                  {!['MESSAGES'].includes(menuItem) ? (
                    <span>
                      {t(`basiclayout.menu.${menuItem.toLowerCase()}`)}
                    </span>
                  ) : (
                    <>
                      <span>
                        {t(`basiclayout.menu.${menuItem.toLowerCase()}`)}
                      </span>
                      <Badge
                        count={notReadClaimMessages + notReadMessages}
                        offset={[20, 7]}
                      />
                    </>
                  )}
                </NavLink>
              )
            }
      )}
    />
  );
};

AccountancyNavMenu.propTypes = {
  setCollapsed: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired
};
