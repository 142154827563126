import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useAuthContext } from '../../../../contexts/AuthContext';

import useFields from './utils/fieldsAutomissions';
import { AutomissionsContext } from './AutomissionsContext';
import { formatDatePickerDate } from '../../../../utils/formatDatePickerDate';
import { useHistory } from '../../../../utils/historyUtils';

/**
 * `AutomissionsCreateUpdate` is a React component for creating or updating documents.
 *
 * Depending on the provided `purpose` prop, this component can be used to either create or edit a document.
 * The component utilizes the `CreateUpdateContainer` for rendering the input fields and managing form actions.
 *
 * @component
 * @param {object} props The props for the component.
 * @param {string} props.purpose Indicates the purpose of the form. It should be either 'create' or 'edit'.
 * @returns {React.ElementType} A form for creating or updating a document.
 */

export const AutomissionsCreateUpdate = ({ purpose }) => {
  const [form] = Form.useForm();
  const { setCurrentEntityContext, setCurrentContract } =
    useContext(AutomissionsContext);
  const { company, companyName, user } = useAuthContext();
  const isClientCondition =
    user?.role === 'users:USER' || user?.role === 'users:SUPER-USER';
  const { setInitialHistory, createHistory, updateHistory } = useHistory();
  const resource = 'vehicles';
  const fleet_type = 'AUTOMISSION';
  const mandatoryDocuments = ['collaborator_driving_licence'];
  const draggerFilesKeysOverRide = [
    'collaborator_vehicle_registration_document',
    'collaborator_driving_licence'
  ];
  const draggerFilesKeysMandatory = ['collaborator_driving_licence'];
  const [currentDates, setCurrentDates] = useState({
    start_of_the_mission: null
  });
  const { fieldsAutomissions, isFieldsLoading } = useFields({
    currentDates,
    setCurrentDates,
    form
  });
  const { t } = useTranslation();
  const config = {
    onGetResource: {
      setFields: (data) => {
        setInitialHistory(data);
        setCurrentContract(data.contract);
        if (data?.start_of_the_mission) {
          setCurrentDates({
            start_of_the_mission: formatDatePickerDate(
              data?.start_of_the_mission
            )
          });
        }
        return {
          ...data,
          start_of_the_mission:
            data.start_of_the_mission && dayjs(data.start_of_the_mission),
          planned_end_of_the_mission:
            data.planned_end_of_the_mission &&
            dayjs(data.planned_end_of_the_mission),
          created_at: data.created_at && dayjs(data.created_at),
          desired_effective_date:
            data.desired_effective_date && dayjs(data.desired_effective_date),
          departure_time: data.departure_time && dayjs(data.departure_time)
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        company,
        programmes_types: 'FLEET',
        fleet_type,
        ...createHistory('program')
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        ...updateHistory('program')
      })
    }
  };
  return (
    <CreateUpdateContainer
      setCurrentEntityContext={setCurrentEntityContext}
      parentForm={form}
      fields={fieldsAutomissions}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      mandatoryDocuments={mandatoryDocuments}
      draggerFilesKeysOverRide={draggerFilesKeysOverRide}
      draggerFilesKeysMandatory={draggerFilesKeysMandatory}
      title={t('vehicles.form.title.automissions')}
      baseUrl="vehicles"
      resource={resource}
      config={config}
      extraTitle={
        <Row justify="center" className="form-company-name">{`${t(
          'folder'
        )} ${companyName}`}</Row>
      }
      withFilesManager={!isClientCondition}
      populate="?populate=documents.file"
    />
  );
};

AutomissionsCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
