import { useCallback } from 'react';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { handleFileActionsOnFetch } from '../../../../../components/CreateUpdateContainerV3/utils/handleFileActionsOnFetch';
import { useErrorMessage } from '../../../../../utils/errorMessage';

/**
 * Custom hook for fetching resources.
 * @hook
 * @param {Object} options - The options for fetching resources.
 * @param {string} options.resourceId - The ID of the resource.
 * @param {string} options.resourceName - The name of the resource.
 * @param {string} options.idWithoutParams - The ID without parameters.
 * @param {function} options.setDraggerFilesList - The function to set the dragger files list.
 * @param {function} options.setFilesConfiguration - The function to set the files configuration.
 * @param {function} options.setIsLoading - The function to set the loading state.
 * @returns {Object} - The getResource function.
 */
export const useGetCertificates = ({
  resourceId,
  resourceName,
  idWithoutParams,
  setDraggerFilesList,
  setFilesConfiguration,
  setIsLoading
}) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const getResource = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `${resourceName}/${
          resourceId || idWithoutParams
        }?populate=insurance_certificate.file`
      });
      const resourceObject = {
        documents: data.insurance_certificate || []
      };
      handleFileActionsOnFetch(
        resourceObject,
        () => {},
        setDraggerFilesList,
        setFilesConfiguration,
        dispatchAPI,
        message
      );
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [resourceName, resourceId, idWithoutParams]);

  return { getResource };
};
