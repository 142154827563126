import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Timeline, Button } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { handleStatusUtils } from './handleStatusUtils';
import { useAuthContext } from '../../contexts/AuthContext';
import { useHistory } from '../../utils/historyUtils';

export const History = ({ route }) => {
  const { id } = useParams();
  const { historyRefresh, history } = useAuthContext();
  const { current } = handleStatusUtils();
  const [historyTimeline, setHistoryTimeline] = useState([]);
  const [displayAll, setDisplayAll] = useState(false);
  const { getHistory } = useHistory();

  useEffect(() => {
    (async () => {
      await getHistory(route, id);
    })();
  }, [current, historyRefresh]);

  useEffect(() => {
    const slicedHistory = displayAll ? history : history.slice(-10);
    const dataTimeline = slicedHistory.map((entry) => ({ children: entry }));
    setHistoryTimeline(dataTimeline);
  }, [history, displayAll]);

  const handleDisplayAll = () => {
    setDisplayAll(true);
  };

  return history ? (
    <>
      <Timeline reverse mode="alternate" items={historyTimeline} />
      {!displayAll && history.length > 10 && (
        <Button onClick={handleDisplayAll} type="primary">
          {t('buttons.display_all')}
        </Button>
      )}
    </>
  ) : null;
};

History.propTypes = {
  route: PropTypes.string.isRequired
};
