// Main nav menuItems
export const routes = {
  HOME: '/',
  PROGRAMMES: '/programmes',
  CLAIMS: '/claims',
  DOCUMENTS: '/documents',
  CONTACTS: '/contacts',
  CONFIGURATIONS: '/configurations'
};

// Main nav subMenuItems
export const subRoutes = {};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  LEGAL: '/legal-notice',
  MAIL_IN_BROWSER: '/mail-in-browser'
};

// url search parameters
export const pathSearches = {};
