import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useFields } from './fieldsHoleInOneClaims';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useHistory } from '../../../utils/historyUtils';

/**
 * Provides a component for creating or updating machinery breakage claims.
 *
 * @component
 * @param {object} props - React component props.
 * @param {string} props.purpose - The purpose of the component, either "create" or "update".
 * @returns {React.ReactNode} The CreateUpdateContainer component with specific configurations for golf claims.
 */
export const HoleInOneClaimsfCreateUpdate = ({ purpose }) => {
  const { dispatchAPI, company } = useAuthContext();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const { setInitialHistory, createHistory, updateHistory } = useHistory();
  const [holeInOne, setHoleInOne] = useState({
    entity: null,
    site: null,
    contract: null,
    company: null
  });
  const [isComplaint, setIsComplaint] = useState(false);
  const { fieldsInformations } = useFields(
    setIsComplaint,
    holeInOne.golfName,
    holeInOne.eventName,
    holeInOne.eventStartDate
      ? dayjs(holeInOne.eventStartDate).format('DD/MM/YYYY')
      : null,
    holeInOne.eventEndDate
      ? dayjs(holeInOne.eventEndDate).format('DD/MM/YYYY')
      : null,
    purpose
  );

  const mandatoryDocuments = isComplaint ? ['filing_complaint'] : [];

  const getHoleInOne = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `hole-in-ones/${id}?populate=entity,site,contract,company`
      });
      setHoleInOne({
        entry_id: data?._id,
        entity: data?.entity?._id,
        site: data?.site?._id,
        golfName: data?.golf_name,
        eventName: data?.event_name,
        eventStartDate: data?.event_start_date,
        eventEndDate: data?.event_end_date
      });
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getHoleInOne();
    })();
  }, [id]);

  const config = {
    onGetResource: {
      setFields: (data) => {
        setInitialHistory(data);
        return {
          ...data,
          sinister_date: data.sinister_date && dayjs(data.sinister_date),
          event_start_date:
            data.event_start_date && dayjs(data.event_start_date),
          event_end_date: data.event_end_date && dayjs(data.event_end_date)
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        entry_id: holeInOne?.entry_id,
        company,
        opening_date: dayjs(),
        city: holeInOne?.city,
        entity: holeInOne?.entity,
        site: holeInOne?.site,
        event_start_date: holeInOne?.eventStartDate,
        event_end_date: holeInOne?.eventEndDate,
        event_name: holeInOne?.eventName,
        golf_name: holeInOne?.golfName,
        claimType: 'FINANCIAL_LOSS',
        programme_types: 'HOLE_IN_ONE',
        ...createHistory('claim', 'hole-in-ones', id)
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        ...updateHistory('claim')
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fieldsInformations}
      purpose={purpose}
      baseUrl="hole_in_one_claims"
      resource="claims"
      config={config}
      populate="?populate=documents.file"
      mandatoryDocuments={mandatoryDocuments}
    />
  );
};

HoleInOneClaimsfCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
