import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select, Form } from 'antd';

const { Option } = Select;
const { Item } = Form;

/**
 * A custom select component for choosing values from a list of enums.
 * @component
 * @param {object} props - The component's props.
 * @param {string} props.dbKey - The key used to fetch the list of enums.
 * @param {object} props.enums - The list of enums.
 * @returns {JSX.Element} The JSX element representing the select component.
 */
export const SelectEnums = ({ dbKey, enums, isMultiple }) => {
  const { t } = useTranslation();

  if (isMultiple) {
    return (
      <Item name={[`${dbKey}`]}>
        {enums ? (
          <Select
            mode="multiple"
            showSearch
            filterOption={(inputValue, option) =>
              (option?.title ?? '')
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            }
          >
            {enums.map((v) => {
              const title =
                dbKey === 'if_cloud_hosted_cloud_providers'
                  ? v
                  : t(`cyber_insurances.form.enums.${v}`);
              return (
                <Option key={v} value={v} title={title}>
                  {title}
                </Option>
              );
            })}
          </Select>
        ) : null}
      </Item>
    );
  }
  return (
    <Item name={[`${dbKey}`]}>
      {enums ? (
        <Select>
          {enums.map((v) => (
            <Option key={v} value={v}>
              {t(`cyber_insurances.form.enums.${v}`)}
            </Option>
          ))}
        </Select>
      ) : null}
    </Item>
  );
};

SelectEnums.propTypes = {
  dbKey: PropTypes.string.isRequired,
  enums: PropTypes.arrayOf(PropTypes.string),
  isMultiple: PropTypes.bool
};

SelectEnums.defaultProps = {
  enums: [],
  isMultiple: false
};
