import {
  vehiclesDbKeys,
  automissionsDbKeys,
  golfsDbKeys,
  constructionInsurancesDbKeys,
  propertiesDbKeys,
  adValoremDbKeys,
  eventCancellationsDbKeys,
  holeInOnesDbKeys,
  machineryBreakagesDbKeys
} from './directEntries';
import {
  vehiclesClaimsDbKeys,
  automissionsClaimsDbKeys,
  adValoremClaimsDbKeys,
  otherTransportsClaimsDbKeys,
  constructionInsuranceClaimsDbKeys,
  otherConstructionClaimsDbKeys,
  golfsClaimsDbKeys,
  propertiesClaimsDbKeys,
  eventCancellationsClaimsDbKeys,
  holeInOneClaimsDbKeys,
  machineryBreakagesClaimsDbKeys
} from './directEntriesClaimDbKeys';
import {
  generalSubscribedEntries,
  cyberInsurancesDbKeys,
  hardwareInsurancesDbKeys,
  professionalMultiriskInsurancesDbKeys,
  assemblyTestingInsurancesDbKeys,
  environmentInsurancesDbKeys,
  legalProtectionInsurancesDbKeys,
  individualHousesBuildersDbKeys
} from './subscribedEntries';
import {
  cyberInsuranceClaimsDbKeys,
  hardwareInsurancesClaimsDbKeys,
  professionalMultiriskInsurancesClaimsDbKeys,
  assemblyTestingInsurancesClaimsDbKeys,
  environmentInsurancesClaimsDbKeys,
  legalProtectionInsurancesClaimsDbKeys,
  individualHousesBuildersClaimsDbKeys
} from './subscribedEntriesClaimDbKeys';
import { callForContributionDbKeys, documentsDbKeys } from './documentsDbKeys';

/**
 * Returns the database keys based on the specified resource.
 * @function
 * @param {string} resource - The resource name.
 * @returns {Array} - The array of database keys.
 */
export const dbKeysSelection = (resource) => {
  switch (resource) {
    case 'vehicles':
      return vehiclesDbKeys;
    case 'vehicle_claims':
      return vehiclesClaimsDbKeys;
    case 'automissions':
      return automissionsDbKeys;
    case 'automission_claims':
      return automissionsClaimsDbKeys;
    case 'golfs':
      return golfsDbKeys;
    case 'golf_claims':
      return golfsClaimsDbKeys;
    case 'construction-insurance':
      return constructionInsurancesDbKeys;
    case 'construction_insurance_claims':
      return constructionInsuranceClaimsDbKeys;
    case 'construction_claims':
      return otherConstructionClaimsDbKeys;
    case 'properties':
      return propertiesDbKeys;
    case 'property_claims':
      return propertiesClaimsDbKeys;
    case 'transports':
      return adValoremDbKeys;
    case 'adValorem_claims':
      return adValoremClaimsDbKeys;
    case 'transport_claims':
      return otherTransportsClaimsDbKeys;
    case 'generalSubscribedEntries':
      return generalSubscribedEntries;
    case 'event-cancellations':
      return eventCancellationsDbKeys;
    case 'event_cancellation_claims':
      return eventCancellationsClaimsDbKeys;
    case 'hole-in-ones':
      return holeInOnesDbKeys;
    case 'hole_in_one_claims':
      return holeInOneClaimsDbKeys;
    case 'machinery-breakages':
      return machineryBreakagesDbKeys;
    case 'machinery_breakage_claims':
      return machineryBreakagesClaimsDbKeys;
    case 'cyber-insurances':
      return cyberInsurancesDbKeys;
    case 'cyber_insurance_claims':
      return cyberInsuranceClaimsDbKeys;
    case 'hardware-insurances':
      return hardwareInsurancesDbKeys;
    case 'hardware_insurance_claims':
      return hardwareInsurancesClaimsDbKeys;
    case 'professional-multirisk-insurances':
      return professionalMultiriskInsurancesDbKeys;
    case 'professional_multirisk_insurance_claims':
      return professionalMultiriskInsurancesClaimsDbKeys;
    case 'assembly-testing-insurances':
      return assemblyTestingInsurancesDbKeys;
    case 'assembly_testing_insurance_claims':
      return assemblyTestingInsurancesClaimsDbKeys;
    case 'environment-insurances':
      return environmentInsurancesDbKeys;
    case 'environment_insurance_claims':
      return environmentInsurancesClaimsDbKeys;
    case 'legal-protection-insurances':
      return legalProtectionInsurancesDbKeys;
    case 'legal_protection_insurance_claims':
      return legalProtectionInsurancesClaimsDbKeys;
    case 'individual-houses-builders':
      return individualHousesBuildersDbKeys;
    case 'individual_houses_builder_claims':
      return individualHousesBuildersClaimsDbKeys;
    case 'documents':
      return documentsDbKeys;
    case 'call-for-contributions':
      return callForContributionDbKeys;
    default:
      return [];
  }
};
