import PropTypes from 'prop-types';
import { useState, useEffect, useCallback, useRef } from 'react';
import { Select, Form } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const { Option } = Select;
const { Item } = Form;

/**
 * A custom Select component that fetches entities based on permissions.
 * @param {Object} props - The props object.
 * @param {string} props.dbKey - The key used to store the selected entity in the form.
 * @param {boolean} [props.isDisabled=false] - Flag indicating if the Select should be disabled.
 * @param {string} [props.permKey=''] - The permission key used to fetch entities.
 * @param {function} [props.handleEntityChange=null] - Callback function called when the selected entity changes.
 * @param {function} [props.setEntitiesContext=null] - Function to set the entities context.
 * @returns {JSX.Element} A Select component.
 */

export const SelectEntityV2 = ({
  dbKey,
  isDisabled,
  permKey,
  handleEntityChange,
  setEntitiesContext,
  currentContract
}) => {
  const isMounted = useRef(true);
  const [entities, setEntities] = useState([]);
  const { company, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const getEntities = useCallback(async (contract) => {
    if (!isMounted.current) return;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/entities/company/${company}/permissions/${permKey}?contract=${contract}`
      });
      setEntitiesContext(data);
      if (isMounted.current) {
        setEntities(data);
        setEntitiesContext(data);
      }
    } catch (e) {
      if (isMounted.current) message(e);
    }
  }, []);

  useEffect(() => {
    if (!currentContract) return;
    (async () => {
      await getEntities(currentContract);
    })();
  }, [getEntities, currentContract]);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  return (
    <Item name={[`${dbKey}`]}>
      <Select
        disabled={isDisabled}
        showSearch
        onChange={handleEntityChange || null}
        filterOption={(inputValue, option) =>
          (option?.title ?? '').toLowerCase().includes(inputValue.toLowerCase())
        }
      >
        {(entities || []).map((e) => (
          <Option key={e._id} value={e._id} title={e.name}>
            {e.name}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

SelectEntityV2.propTypes = {
  dbKey: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  permKey: PropTypes.string,
  handleEntityChange: PropTypes.func,
  setEntitiesContext: PropTypes.func,
  currentContract: PropTypes.string
};

SelectEntityV2.defaultProps = {
  isDisabled: false,
  permKey: '',
  handleEntityChange: null,
  setEntitiesContext: null,
  currentContract: null
};
