import { useTranslation } from 'react-i18next';
import { Popover } from 'antd';

/**
 * A custom hook that generates a list of step titles for a subscription process.
 * @param {Boolean} isMandatoryDocumentOk - A boolean indicating whether the mandatory documents are ok.
 * @returns {Object} An object containing an array of step titles.
 */
export const useListItemsStep = (isMandatoryDocumentOk) => {
  const { t } = useTranslation();
  let items = [];
  if (isMandatoryDocumentOk) {
    items = [
      {
        title: t('subscriptions.form.request_received')
      },
      {
        title: t('subscriptions.form.additional_request')
      },
      {
        title: t('subscriptions.form.insurance_exchange')
      },
      {
        title: t('subscriptions.form.rejected')
      },
      {
        title: t('subscriptions.form.accepted')
      }
    ];
  } else {
    items = [
      {
        title: t('subscriptions.form.request_received')
      },
      {
        title: (
          <Popover
            placement="rightBottom"
            title={t('popover.document.required')}
          >
            {t('subscriptions.form.additional_request')}
          </Popover>
        )
      }
    ];
  }
  return { items };
};
