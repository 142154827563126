import { Route, Routes } from 'react-router-dom';
import { ListHardwareInsuranceClaims } from './ListHardwareInsuranceClaims';
import { HardwareInsuranceClaimsCreateUpdate } from './HardwareInsuranceClaimsCreateUpdate';
import { HardwareInsuranceClaimsShow } from './HardwareInsuranceClaimsShow';
import { Exception } from '../../../components';

/**
 * Provides routing for managing hardware insurances, including create, edit, show, and list views.
 *
 * @component
 * @returns {React.ReactNode} Routes for different views related to hardware insurances.
 */
export const HardwareInsuranceClaimsRouter = () => (
  <Routes>
    <Route
      path="create/:id"
      element={<HardwareInsuranceClaimsCreateUpdate purpose="create" />}
    />
    <Route
      path="edit/:id"
      element={<HardwareInsuranceClaimsCreateUpdate purpose="edit" />}
    />
    <Route path="show/:id" element={<HardwareInsuranceClaimsShow />} />
    <Route index element={<ListHardwareInsuranceClaims />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
