import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useFields } from './fieldsLegalProtection';
import { useHistory } from '../../../utils/historyUtils';

/**
 * Component for creating or updating Legal Protection subscriptions.
 * @component
 * @param {Object} props - The component's properties.
 * @param {string} props.purpose - The purpose of the component, either "create" or "edit".
 * @returns {JSX.Element} The JSX element for creating or updating Legal Protection subscriptions.
 */
export const LegalProtectionSubCreateUpdate = ({ purpose }) => {
  const { company } = useAuthContext();
  const program = 'LEGAL_PROTECTION_INSURANCE';
  const { t } = useTranslation();
  const { fieldsInformations } = useFields();
  const { createHistory, updateHistory } = useHistory();
  const draggerFilesKeysOverRide = ['multirisk_company'];

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        desired_effective_date:
          data.desired_effective_date && dayjs(data.desired_effective_date)
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        company_id: company,
        programme_types: program,
        ...createHistory('program')
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        ...updateHistory('program')
      })
    }
  };

  return (
    <CreateUpdateContainer
      title={t(`subscriptions.form.${purpose}`)}
      fields={fieldsInformations}
      purpose={purpose}
      baseUrl="subscriptions"
      resource="subscriptions"
      populate="?populate=documents.file"
      config={config}
      messageOnSuccess
      draggerFilesKeysOverRide={draggerFilesKeysOverRide}
      mandatoryDocuments={[]}
    />
  );
};

LegalProtectionSubCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
