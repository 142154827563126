import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { Button } from 'antd';
import { ImportOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { Mailing } from './Modals/Mailing';
import { useErrorMessage } from '../../../../utils/errorMessage';

export const MailingButtonVehicles = ({
  asset,
  templateName,
  tradKey,
  targetUrl
}) => {
  const [isModalMailingOpen, setIsModalMailingOpen] = useState(false);
  const [templateMail, setTemplateMail] = useState();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const getTemplateMail = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/templates-mails/name/${templateName}?populate=documents,documents.file`
      });
      setTemplateMail(data);
    } catch (e) {
      message(e);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getTemplateMail();
    })();
  }, [getTemplateMail]);
  return (
    <>
      {tradKey && (
        <Button onClick={() => setIsModalMailingOpen(true)}>
          {`${t(`buttons.${tradKey}`)} `}
          <ImportOutlined />
        </Button>
      )}
      <Mailing
        isModalMailingOpen={isModalMailingOpen}
        setIsModalMailingOpen={setIsModalMailingOpen}
        idWithoutParams={asset?._id}
        templateMail={templateMail}
        datas={asset}
        templateName={tradKey}
        targetUrl={targetUrl}
      />
    </>
  );
};

MailingButtonVehicles.propTypes = {
  asset: PropTypes.shape({
    _id: PropTypes.string
  }),
  templateName: PropTypes.string.isRequired,
  tradKey: PropTypes.string.isRequired,
  targetUrl: PropTypes.string
};

MailingButtonVehicles.defaultProps = {
  asset: {},
  targetUrl: null
};
