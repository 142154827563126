import { Link } from 'react-router-dom';
import { Button, Flex, Tooltip } from 'antd';
import { EditOutlined, EyeOutlined, SyncOutlined } from '@ant-design/icons';
import { Flood } from '@carbon/icons-react';
import { MailingButton } from '../../../components/ButtonMailActionColumn';

const iconSize = 18;

/**
 * Generates an array of action columns for a table in a React component for managing machinery breakages.
 *
 * @param {boolean} edit - A boolean indicating whether the "Edit" action is enabled.
 * @param {function} generateAttestation - A function to generate an Attestation for a machinery breakage record.
 * @param {function} t - A function to translate a string.
 * @param {function} isArchivatedPage - A boolean indicating whether the "Sorite" page is enabled.
 * @returns {Object[]} An array of action column objects for rendering actions in a table.
 */
export const useActionColumn = (
  edit,
  generateAttestation,
  t,
  isArchivatedPage
) => [
  {
    key: 'action',
    align: 'right',
    fixed: 'right',
    render: (record) => (
      <Flex gap="small" align="center" justify="end" wrap="nowrap">
        <Tooltip title={t('machinery_breakages.actions.show')}>
          <Link to={`/programmes/machinery-breakages/show/${record?._id}`}>
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
        </Tooltip>
        {edit && (
          <Tooltip title={t('machinery_breakages.actions.edit')}>
            <Link to={`/programmes/machinery-breakages/edit/${record?._id}`}>
              <EditOutlined style={{ fontSize: iconSize }} />
            </Link>
          </Tooltip>
        )}

        {!isArchivatedPage && (
          <>
            <Tooltip title={t('machinery_breakages.actions.create_claim')}>
              <Link to={`/claims/machinery-breakages/create/${record?._id}`}>
                <Flood size={20} />
              </Link>
            </Tooltip>
            <Tooltip title={t('machinery_breakages.actions.specific_demand')}>
              <MailingButton
                assetId={record?._id}
                templateName="Blank"
                tradKey="specific_demand"
                resource="machineryBreakages"
                baseUrl="machinery-breakages"
              />
            </Tooltip>
          </>
        )}
        {record?.contract?.insurance_company === 'AXA' && (
          <Tooltip
            title={t('machinery_breakages.actions.generate_attestation')}
          >
            <Button
              type="link"
              onClick={() => generateAttestation(record?._id)}
            >
              <SyncOutlined style={{ fontSize: iconSize }} />
            </Button>
          </Tooltip>
        )}
      </Flex>
    )
  }
];
