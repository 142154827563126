import React from 'react';
import { Link } from 'react-router-dom';
import { Flex } from 'antd';
import { EditOutlined, EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import { useDownloadDocument } from '../../utils/downloadDoc';
import { ButtonMailActionColumnDocuments } from '../components/ButtonMailActionColumnDocuments';

const iconSize = 18;

/**
 * Generates an array of action columns for a table in a React component for managing documents.
 *
 * @param {boolean} edit - A boolean indicating whether the "Edit" action is enabled.
 * @param {boolean} show - A boolean indicating whether the "Show" action is enabled.
 * @returns {Object[]} An array of action column objects for rendering actions in a table.
 */
export const useActionColumn = (edit, show) => {
  const { downloadDocument, viewDocument } = useDownloadDocument();

  return [
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: 140,
      render: (record) => {
        let content;
        switch (record?.document_type) {
          case 'GUARANTEES':
            content = (
              <Flex gap="small" align="center" justify="end" wrap="nowrap">
                {edit && (
                  <Link
                    to={`/documents/edit-guarantees/${record?._id}`}
                    style={{ lineHeight: 1 }}
                  >
                    <EditOutlined style={{ fontSize: iconSize }} />
                  </Link>
                )}
                {record?.file && (
                  <>
                    <DownloadOutlined
                      style={{ fontSize: iconSize }}
                      onClick={() => downloadDocument(record?.file)}
                    />
                    <ButtonMailActionColumnDocuments
                      assetId={record?._id}
                      tradKey="specific_demand"
                      baseUrl="golfs"
                      file={record?.file}
                    />
                  </>
                )}
              </Flex>
            );
            break;

          case 'ATTESTATION':
            content = (
              <Flex gap="small" align="center" justify="end" wrap="nowrap">
                {edit && (
                  <Link
                    to={`/documents/edit-attestations/${record?._id}`}
                    style={{ lineHeight: 1 }}
                  >
                    <EditOutlined style={{ fontSize: iconSize }} />
                  </Link>
                )}
                {record?.file && (
                  <>
                    <DownloadOutlined
                      style={{ fontSize: iconSize }}
                      onClick={() => {
                        downloadDocument(record?.file);
                      }}
                    />

                    <ButtonMailActionColumnDocuments
                      assetId={record?._id}
                      tradKey="specific_demand"
                      baseUrl="golfs"
                      file={record?.file}
                    />
                  </>
                )}
              </Flex>
            );
            break;

          case 'CONTRACT':
            content = (
              <Flex gap="small" align="center" justify="end" wrap="nowrap">
                {show && (
                  <Link
                    to={`/documents/show/${record?._id}`}
                    style={{ lineHeight: 1 }}
                  >
                    <EyeOutlined style={{ fontSize: iconSize }} />
                  </Link>
                )}
                {edit && (
                  <Link
                    to={`/documents/edit/${record?._id}`}
                    style={{ lineHeight: 1 }}
                  >
                    <EditOutlined style={{ fontSize: iconSize }} />
                  </Link>
                )}
                {record?.file && (
                  <>
                    <DownloadOutlined
                      style={{ fontSize: iconSize }}
                      onClick={() => {
                        downloadDocument(record?.file);
                      }}
                    />

                    <ButtonMailActionColumnDocuments
                      assetId={record?._id}
                      tradKey="specific_demand"
                      baseUrl="golfs"
                      file={record?.file}
                    />
                  </>
                )}
              </Flex>
            );
            break;

          case 'AMENDMENT':
            content = (
              <Flex gap="small" align="center" justify="end" wrap="nowrap">
                {show && (
                  <Link
                    to={`/documents/show/${record?._id}`}
                    style={{ lineHeight: 1 }}
                  >
                    <EyeOutlined style={{ fontSize: iconSize }} />
                  </Link>
                )}
                {edit && (
                  <Link
                    to={`/documents/edit-amendments/${record?._id}`}
                    style={{ lineHeight: 1 }}
                  >
                    <EditOutlined style={{ fontSize: iconSize }} />
                  </Link>
                )}
                {record?.file && (
                  <>
                    <DownloadOutlined
                      style={{ fontSize: iconSize }}
                      onClick={() => downloadDocument(record?.file)}
                    />

                    <ButtonMailActionColumnDocuments
                      assetId={record?._id}
                      tradKey="specific_demand"
                      baseUrl="golfs"
                      file={record?.file}
                    />
                  </>
                )}
              </Flex>
            );
            break;

          case 'CALL_FOR_CONTRIBUTION':
            content = (
              <Flex gap="small" align="center" justify="end" wrap="nowrap">
                {show && (
                  <EyeOutlined
                    style={{ fontSize: iconSize }}
                    onClick={() => viewDocument(record.file)}
                  />
                )}
                {edit && (
                  <Link
                    to={`/contributions/edit/${record?._id}`}
                    style={{ lineHeight: 1 }}
                  >
                    <EditOutlined style={{ fontSize: iconSize }} />
                  </Link>
                )}
                {record?.file && (
                  <>
                    <DownloadOutlined
                      style={{ fontSize: iconSize }}
                      onClick={() => downloadDocument(record?.file)}
                    />
                    <ButtonMailActionColumnDocuments
                      assetId={record?._id}
                      tradKey="specific_demand"
                      baseUrl="golfs"
                      file={record?.file}
                    />
                  </>
                )}
              </Flex>
            );
            break;

          default:
            content = null;
            break;
        }

        return content;
      }
    }
  ];
};
