import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Col, Flex, Skeleton, Row, Steps } from 'antd';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useListContentClaim } from './listContentClaim';
import { useListContent } from './listContent';
import { useListContentCommentaries } from '../../utils/listContentCommentaries';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../../components';
import { Documents } from '../../../components/Documents';
import {
  useListItemsStep,
  useListBeforeValidation
} from '../../utils/listItemsStep';
import { handleStatusUtils } from '../../utils/handleStatusUtils';
import { ModalClosure } from '../../utils/ModalClosure';
import { ShowClaimAccountingOverview } from '../../ClaimAccountingOverview/ShowClaimAccountingOverview';
import { PhotosClaims } from '../../utils/PhotosClaims';
import { useExtraButtons } from '../../extraButtons';
import { History } from '../../../components/History';
import { useProgramDataUpdater } from '../../../../utils/programDataUpdater';
import { MailingButton } from '../../../Mailing/MailingButton';

/**
 * Displays detailed information about a faculty claim, including claim information, faculty information,
 * pictures, and related actions.
 *
 * @component
 * @returns {React.ReactNode} Detailed view of the faculty claim.
 */

export const AdValoremShow = () => {
  const targetUrl = 'transport_claims';
  const { id } = useParams();
  const { dispatchAPI, permissions, verifPermissions } = useAuthContext();
  const { message } = useErrorMessage();
  const {
    current,
    setCurrent,
    onChange,
    toEnclosed,
    setToEnclose,
    changingStatus,
    setChangingStatus
  } = handleStatusUtils(targetUrl);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const { items } = useListItemsStep();
  const { itemsBeforeValidation } = useListBeforeValidation();
  const [adValorem, setAdValorem] = useState();
  const [edit, setEdit] = useState(true);
  const [closure, setClosure] = useState(false);
  const [adValoremData, setAdValoremData] = useState({});

  const {
    ID,
    claim,
    isClosed,
    isCompliant,
    accountingBalanced,
    feesAppealsBalanced
  } = adValoremData;

  const listContentClaim = useListContentClaim(claim);
  const listContent = useListContent(adValorem);
  const listContentCommentaries = useListContentCommentaries(claim);

  const {
    setIsCompliant,
    setAccountingBalanced,
    setFeesAppealsBalanced,
    initialData
  } = useProgramDataUpdater(adValoremData, setAdValoremData);

  const getAdValoremClaim = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/transport_claims/documents/${id}`
      });
      setAdValoremData({
        ...initialData(data)
      });
      setCurrent(data?.step);
      setToEnclose(data?.to_enclose);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, []);

  const getAdValorem = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/transports/${ID}?populate=customer_manager,unit_manager,contract,site,company_Id,entity`
      });
      setAdValorem(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [ID]);

  useEffect(() => {
    if (ID) {
      (async () => {
        await getAdValorem();
      })();
    }
  }, [ID]);

  useEffect(() => {
    (async () => {
      await getAdValoremClaim();
    })();
  }, [current, accountingBalanced, feesAppealsBalanced]);

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setEdit(
        !verifPermissions('/claims/edit', permissions?.notPermittedRoutes)
      );
    }
  }, [permissions]);

  const { extraButtons } = useExtraButtons({
    isCompliant,
    setIsCompliant,
    current,
    edit,
    toEnclosed,
    setClosure,
    onChange,
    reference: claim?.unit_counter,
    resource: 'Transport_claim',
    baseUrl: 'transport_claims',
    productClaim: claim,
    urlPathName: 'ad-valorem',
    accountingBalanced,
    feesAppealsBalanced,
    changingStatus,
    setChangingStatus
  });

  return ID && adValorem && !isLoading ? (
    <>
      <PageHeaderCustom
        title={`${t('claims.form.claim')} ${claim?.unit_counter || 'xxxx'} - ${
          adValorem?.departure_city
        } → ${adValorem?.arrival_city}`}
        extra={extraButtons}
      />
      <MailingButton
        asset={claim}
        templateName={changingStatus}
        tradKey={changingStatus}
        baseUrl={targetUrl}
        changingStatus={changingStatus}
        setChangingStatus={setChangingStatus}
      />
      <ContentCustom>
        <Flex vertical gap="middle">
          {current !== undefined && current !== 6 ? (
            <Steps
              onChange={(step) => {
                onChange(step, id, edit);
              }}
              items={isCompliant ? items : itemsBeforeValidation}
              current={current}
            />
          ) : null}
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={12}>
              <Row gutter={[0, 16]}>
                <Card>
                  <DescriptionList
                    data={listContentClaim}
                    translate
                    layout="vertical"
                    title={t('claims.form.claim_informations')}
                  />
                </Card>
                <Card title={t('claims.form.contract_informations')}>
                  <DescriptionList
                    data={listContent}
                    translate
                    layout="vertical"
                  />
                </Card>
              </Row>
            </Col>
            <Col xs={24} xl={12}>
              <Row gutter={[0, 16]}>
                <PhotosClaims baseUrl="transport_claims" />
                <Documents
                  idWithoutParams={id}
                  baseUrl="transport_claims"
                  resource="Transport_claim"
                  noDelete={current === 6}
                />
                <Row gutter={[0, 16]}>
                  <Card>
                    <DescriptionList
                      data={listContentCommentaries}
                      translate
                      layout="vertical"
                      title={t('claims.form.commentaries')}
                    />
                  </Card>
                </Row>
                <Col span={24}>
                  <Card title={t('subscriptions.form.history')} size="small">
                    <History route="transport_claims" />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={24}>
              {current >= 2 && isCompliant ? (
                <ShowClaimAccountingOverview
                  current={current}
                  buttonBelowTable={
                    current !== 6 && current !== undefined && edit === true
                  }
                  noActionColumnsButton={isClosed}
                  accountingBalanced={accountingBalanced}
                  setAccountingBalanced={setAccountingBalanced}
                  feesAppealsBalanced={feesAppealsBalanced}
                  setFeesAppealsBalanced={setFeesAppealsBalanced}
                  resourceName="transport-claim-accounts"
                  url="transport-claim-accounts"
                  resourceNameClaim="transport_claims"
                  urlClaim="transport_claims"
                  urlAccount="claim-accounts"
                  edit={edit}
                />
              ) : null}
            </Col>
          </Row>
        </Flex>
        <ModalClosure
          open={closure}
          onCancel={() => setClosure(false)}
          onOk={() => setClosure(false)}
          claimReferenceNumber={claim?.unit_counter}
        />
      </ContentCustom>
    </>
  ) : (
    <Skeleton active loading={isLoading} />
  );
};
