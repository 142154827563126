export const useListContentCommentaries = (data = {}) => {
  const { commentaries } = data;

  const labels = [
    {
      label: 'claims.form.commentaries',
      span: 1,
      content: commentaries || ''
    }
  ];

  return labels;
};
