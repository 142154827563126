import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Form, Button } from 'antd';
import PropTypes from 'prop-types';
import useFields from './utils/fieldsAgriculture';
import { CreateUpdateContainerFormListWithInnerFileManager } from './CreateUpdateContainerFormListWithInnerFileManager';
import { ContentCustom } from '../../components/ContentCustom/ContentCustom';
import ULogo from '../../assets/images/logo-outline-green.svg';
import { OutOfNavLayout } from '../interruption-golf/OutOfNavLayout';
import { ReactComponent as LogoQuest } from '../../assets/images/Logo-Questionnaire.svg';
import { useHandleResize } from '../../utils/handleResize';

/**
 * Component for creating or updating agriculture data.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the component (create or update).
 * @returns {JSX.Element} The rendered component.
 */
export const AgricultureCreateUpdate = ({ purpose }) => {
  const translation = 'agriculture.form';
  const resource = 'agriculture';
  const [form] = Form.useForm();
  const [step, setStep] = useState(0);
  const { t } = useTranslation();
  const { width } = useHandleResize();

  const coordenees = (
    <>
      <b>{t(`${translation}.phone_number`)}</b>
      <p>{t('agriculture.phone_number')}</p>
      <b>{t(`${translation}.email`)}</b>
      <p>{t('agriculture.email_contact')}</p>
    </>
  );
  const { fieldsAgriculture } = useFields(form);
  const config = [];

  return (
    <OutOfNavLayout LogoQuest={LogoQuest} coordenees={coordenees}>
      {step === 0 && (
        <CreateUpdateContainerFormListWithInnerFileManager
          fields={fieldsAgriculture}
          purpose={purpose}
          baseUrl="agriculture"
          resource={resource}
          config={config}
          withFilesManager
          form={form}
          setStep={setStep}
        />
      )}
      {step === 1 && (
        <ContentCustom
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '2rem',
            overflow: 'auto',
            paddingLeft: '2rem',
            minHeight: width < 993 ? 'unset' : '100vh',
            maxHeight: width < 993 ? 'unset' : '100vh'
          }}
        >
          <img src={ULogo} alt="Unit" />
          <h2>{t('agriculture.form.unit_remercie')}</h2>
          <p>{t('agriculture.form.questionnaire_envoye')}</p>
          <Button
            type="primary"
            onClick={() => {
              window.location.href = 'https://www.jdg-assurances.fr/';
            }}
          >
            {`${t('agriculture.form.retourne_site')} >`}
          </Button>
        </ContentCustom>
      )}
    </OutOfNavLayout>
  );
};

AgricultureCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
