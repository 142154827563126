import { ContainerOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DatePicker, Flex, Form, Modal } from 'antd';
import PropTypes from 'prop-types';
import { TooltipContainer } from '../components/TooltipContainer';
import FilesManager from '../../components/CreateUpdateContainerV3/FilesManager/FilesManagerWithDisplay';
import { ErrorModal } from '../../components/ErrorModal/ErrorModal';
import { useDeleteProgram } from './VehiclesFleets/utils/deleteProgramUtils';
import { useDraggerProps } from '../../components/CreateUpdateContainerV3/FilesManager/utils/draggerProps';

/**
 * FormDeleteProgrammesWithDocument component.
 * Renders a form for deleting programmes with associated documents.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.resourceName - The name of the resource.
 * @param {boolean} props.visible - Indicates if the form is visible.
 * @param {function} props.setVisible - Function to set the visibility of the form.
 * @param {string} props.resourceId - The ID of the resource.
 * @param {Array} props.draggerFilesKeysOverRide - The array of dragger files keys override.
 * @returns {JSX.Element} The rendered component.
 */
export const FormDeleteProgrammesWithDocument = ({
  resourceName,
  visible,
  setVisible,
  resourceId,
  rowKey,
  idWithoutParams,
  exitDocuments,
  mandatoryExitDocuments
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [filesConfiguration, setFilesConfiguration] = useState([]);
  const [draggerFilesList, setDraggerFilesList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const iconSize = '18px';
  const possibleDocuments = exitDocuments;
  const [mandatoryDocuments, setMandatoryDocuments] = useState(
    mandatoryExitDocuments
  );
  const [isOpen, setIsOpen] = useState(false);
  const disabledDate = (current) => current && current > Date.now();
  const { handleSubmit } = useDeleteProgram(
    draggerFilesList,
    filesConfiguration,
    setIsSubmitting,
    mandatoryDocuments,
    resourceName,
    resourceId || idWithoutParams,
    setVisible,
    setIsOpen
  );

  useDraggerProps(draggerFilesList, setDraggerFilesList);

  useEffect(() => {
    if (filesConfiguration.length > 0) {
      setMandatoryDocuments(
        filesConfiguration
          .filter((file) => file.mandatory)
          .map((file) => file.key)
      );
    } else {
      setMandatoryDocuments(possibleDocuments);
    }
  }, [filesConfiguration]);

  return (
    <TooltipContainer title={t('buttons.remove')}>
      <>
        <Modal
          width={800}
          footer={false}
          open={resourceId ? visible === true : visible === rowKey}
          onCancel={() => {
            setVisible(false);
            form.resetFields();
          }}
        >
          <Form form={form} onFinish={handleSubmit}>
            <Form.Item
              label={t(`${resourceName}.form.deletion_date`)}
              name="deletionDate"
              rules={[
                {
                  required: true,
                  message: t('datatable.column.action.delete.date')
                }
              ]}
            >
              <DatePicker disabledDate={disabledDate} />
            </Form.Item>
            <FilesManager
              filesList={draggerFilesList}
              draggerFilesKeysMandatory={mandatoryDocuments}
              mandatoryDocuments={mandatoryDocuments}
              setFilesList={setDraggerFilesList}
              filesKeys={possibleDocuments}
              filesConfiguration={filesConfiguration}
              setFilesConfiguration={setFilesConfiguration}
              purpose="edit"
            />
            <Form.Item>
              <Flex>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isSubmitting}
                  style={{ marginRight: 10 }}
                >
                  {t('datatable.column.action.delete.ok')}
                </Button>
                <Button
                  type="danger"
                  onClick={() => {
                    setVisible(false);
                    form.resetFields();
                  }}
                  style={{ marginLeft: 10 }}
                >
                  {t('datatable.column.action.delete.cancel')}
                </Button>
              </Flex>
            </Form.Item>
          </Form>
          <ErrorModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title={t(
              'errors.message.missing_mandatory_documents_exit_programme'
            )}
            details={mandatoryDocuments}
            tradKey="files.keys"
          />
        </Modal>
        {!idWithoutParams ? (
          <Button
            onClick={() => {
              setVisible(resourceId ? true : rowKey);
            }}
            type="delete"
          >
            {`${t('buttons.remove')} `}
          </Button>
        ) : (
          <ContainerOutlined
            onClick={() => {
              setVisible(resourceId ? true : rowKey);
            }}
            style={{ color: 'var(--errorColor)', fontSize: iconSize }}
            type="delete"
          />
        )}
      </>
    </TooltipContainer>
  );
};

FormDeleteProgrammesWithDocument.propTypes = {
  resourceName: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  resourceId: PropTypes.string.isRequired,
  rowKey: PropTypes.number,
  idWithoutParams: PropTypes.string,
  exitDocuments: PropTypes.shape([]).isRequired,
  mandatoryExitDocuments: PropTypes.shape([]).isRequired
};

FormDeleteProgrammesWithDocument.defaultProps = {
  rowKey: null,
  idWithoutParams: null
};
