import PropTypes from 'prop-types';
import { useState, useEffect, useCallback, useRef } from 'react';
import { Tooltip, Button } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { MailingBlankDocs } from '../Mailing/Modals/MailingBlankDocs';
import { useErrorMessage } from '../../utils/errorMessage';

const iconSize = 18;

export const ButtonMailActionColumnDocuments = ({
  tradKey,
  resource,
  baseUrl,
  assetId,
  file,
  isButton
}) => {
  const isMounted = useRef(false);
  const [isModalMailingOpen, setIsModalMailingOpen] = useState(false);
  const [templateMail, setTemplateMail] = useState();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();

  const getTemplateMail = useCallback(async () => {
    if (!isMounted.current) return;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/templates-mails/name/Blank?populate=documents,documents.file`
      });
      if (isMounted.current) {
        setTemplateMail(data);
      }
    } catch (e) {
      if (isMounted.current) {
        message(e);
      }
    }
  }, [dispatchAPI, message]);

  const onClick = async () => {
    await getTemplateMail();
    setIsModalMailingOpen(true);
  };

  const closeModal = () => {
    setIsModalMailingOpen(false);
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const renderButton = () =>
    tradKey &&
    isButton && (
      <Button
        onClick={() => {
          onClick();
        }}
      >
        <MailOutlined style={{ fontSize: iconSize }} />
        {`${t('buttons.send')} `}
      </Button>
    );

  const renderIcon = () =>
    tradKey &&
    !isButton && (
      <Tooltip title={t('property.actions.send_doc')}>
        <MailOutlined
          style={{ fontSize: iconSize }}
          onClick={() => {
            onClick();
          }}
        />
      </Tooltip>
    );

  return (
    <>
      {renderButton()}
      {renderIcon()}

      <MailingBlankDocs
        isModalMailingOpen={isModalMailingOpen}
        setIsModalMailingOpen={setIsModalMailingOpen}
        idWithoutParams={assetId}
        templateMail={templateMail}
        resource={resource}
        baseUrl={baseUrl}
        templateName={tradKey}
        isModal
        closeModal={closeModal}
        file={file}
      />
    </>
  );
};

ButtonMailActionColumnDocuments.propTypes = {
  assetId: PropTypes.string.isRequired,
  tradKey: PropTypes.string.isRequired,
  resource: PropTypes.string,
  baseUrl: PropTypes.string.isRequired,
  file: PropTypes.shape({}),
  isButton: PropTypes.bool
};

ButtonMailActionColumnDocuments.defaultProps = {
  isButton: false,
  resource: '',
  file: null
};
