import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Typography } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useHistory } from '../../../../utils/historyUtils';

const { Paragraph, Text } = Typography;
/**
 * A reusable button component to acknowledge compliance with a claim.
 *
 * @function
 * @param {string} claimReferenceNumber - The claim reference number to be displayed.
 * @param {Function} setIsCompliant - Callback function to set the compliance status.
 * @param {string} id - The ID associated with the claim.
 * @param {string} url - The URL to update the compliance status of the claim.
 * @returns {JSX.Element} The JSX element representing the compliance acknowledgment button.
 */

export const AcknowledgeComplianceButton = ({
  claimReferenceNumber,
  setIsCompliant,
  id,
  url,
  setChangingStatus
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { dispatchAPI, history, setHistoryRefresh } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { historyMessage } = useHistory();

  const updateCompliance = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `${url}/${id}`,
        body: JSON.stringify({
          values: JSON.stringify({
            is_compliant: true,
            history: [
              ...history,
              historyMessage('claims.form.claim_declaration_validation')
            ]
          })
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setChangingStatus('compliant_declaration');
      setHistoryRefresh((prev) => !prev);
    } catch (e) {
      message(e);
    }
  };

  const handleCompliance = () => {
    setIsModalOpen(true);
    updateCompliance();
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsCompliant(true);
  };

  return (
    <>
      <Button icon={<CheckOutlined />} onClick={handleCompliance}>
        {`${t('claims.form.compliant_declaration')} `}
      </Button>
      <Modal
        open={isModalOpen}
        footer={[
          <Button onClick={() => closeModal()}>
            {t('claims.form.ok_button')}
          </Button>
        ]}
        title={t('claims.form.claim_declaration_validation')}
      >
        <Typography>
          <Paragraph>
            <Text>{t('claims.form.claim_number')}</Text>
            <Text strong>{`${claimReferenceNumber}`}</Text>
          </Paragraph>
          <Paragraph>
            {t('claims.form.claim_validation_mail_sent', {
              claimReferenceNumber
            })}
          </Paragraph>
        </Typography>
      </Modal>
    </>
  );
};

AcknowledgeComplianceButton.propTypes = {
  claimReferenceNumber: PropTypes.string,
  setIsCompliant: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  url: PropTypes.string,
  setChangingStatus: PropTypes.func.isRequired
};

AcknowledgeComplianceButton.defaultProps = {
  claimReferenceNumber: null,
  url: null
};
