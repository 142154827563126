import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * Renders the signature image for a claim.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.baseUrl - The base URL for the API.
 * @param {string} props.programme_id - The ID of the programme.
 * @returns {JSX.Element} The rendered component.
 */
export const DisplaySignature = ({ baseUrl, programme_id }) => {
  const { id } = useParams();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [photosClaim, setPhotosClaim] = useState([]);

  const getPhotos = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${baseUrl}/${
          programme_id || id
        }?populate=signature&fields=signature`
      });

      setPhotosClaim(data.signature.metadata.type);
      setIsLoading(false);
    } catch (e) {
      message(e);
    }
  }, [baseUrl, id, dispatchAPI, message]);

  useEffect(() => {
    (async () => {
      await getPhotos();
    })();
  }, []);

  return (
    <>
      <Spin spinning={isLoading} size="large" />
      <img
        style={{ backgroundColor: 'white', width: '10rem' }}
        src={photosClaim}
        alt="signature"
      />
    </>
  );
};

DisplaySignature.propTypes = {
  baseUrl: PropTypes.string,
  programme_id: PropTypes.string
};

DisplaySignature.defaultProps = {
  baseUrl: undefined,
  programme_id: undefined
};
