import { Spin } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { ListResource } from '../../../components/ListResource/ListResource';
import { useAuthContext } from '../../../contexts/AuthContext';
import { FilterByMissingDocuments } from '../../components/FilterByMissingDocuments';
import { useColumns } from '../Construction/ConstructionInsurance/columns';

/**
 * Renders a list of individual houses builder claims.
 * @component
 * @returns {JSX.Element} The rendered ListIndividualHousesBuilderClaim component.
 */
export const ListIndividualHousesBuilderClaim = () => {
  const [isMissingDocuments, setIsMissingDocuments] = useState('');
  const [resetFilters, setResetFilters] = useState(false);
  const [extraFilter, setExtraFilter] = useState('');
  const { t } = useTranslation();

  const [isClosedButton, setIsClosedButton] = useState(false);
  const { company } = useAuthContext();
  const resourceName = 'individual_houses_builder_claims';

  const dbExtraQueryFilter = `company=${company}&${
    isClosedButton ? 'claim_status=CLOSED' : 'claim_status!=CLOSED'
  }`;
  const populate = 'entity,site,entry_id,company';

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const currentPage = Number(params.get('p') || 1);
  const pageSize = Number(params.get('pS') || 10);

  let filterParameter = '';

  const [columns, loading] = useColumns(
    resourceName,
    dbExtraQueryFilter,
    populate
  );

  const onFilterMissingDocuments = (missingDocuments) => {
    setIsMissingDocuments(missingDocuments === false ? '' : missingDocuments);
  };

  useEffect(() => {
    if (resetFilters) {
      filterParameter = '';
      setResetFilters(false);
    }
    filterParameter = `missing_documents=${isMissingDocuments}__`;
    setExtraFilter(filterParameter);
    navigate({
      pathname: '/claims/individual-houses-builders',
      search: `?&p=${currentPage}&pS=${pageSize}&f=${filterParameter}`
    });
  }, [navigate, isMissingDocuments, resetFilters]);

  if (loading) return <Spin fullscreen="true" />;

  return (
    <ListResource
      extraQuery={dbExtraQueryFilter}
      resourceName={resourceName}
      exportUrl={`exports/company/${company}/claim/programme-type/INDIVIDUAL_HOUSES_BUILDER`}
      importUrl={`imports-claim-individual-houses-builder/company/${company}`}
      exportTemplateUrl="export-claim-templates/template/individual-houses-builder-claim"
      tradKey="claims"
      columns={[...columns]}
      withExtraFilters={
        <FilterByMissingDocuments
          onChange={onFilterMissingDocuments}
          setResetFilters={setResetFilters}
        />
      }
      populate={populate}
      editAction={false}
      showAction={false}
      deleteAction={false}
      sheetName={t('sheet.hardware_insurance_claims')}
      setIsClosedButton={setIsClosedButton}
      headers={[]}
      noCreateButton
      noArchiveFilter
      extraFilter={extraFilter}
      scroll={{ x: 3800 }}
    />
  );
};
