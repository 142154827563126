import PropTypes from 'prop-types';
import { useReducer, useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFields } from './utils/fieldsAssemblyTestingClaims';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useConfig } from './utils/createUpdateConfig';
import { constants } from './utils/constants';

/**
 * Component for creating or updating individual houses builder insurance subscriptions.
 * @component
 * @param {Object} props - The component's properties.
 * @param {string} props.purpose - The purpose of the component, either "create" or "edit".
 * @returns {JSX.Element} The JSX element for creating or updating individual houses builder insurance subscriptions.
 */
export const AssemblyTestingClaimsCreateUpdate = ({ purpose }) => {
  const isMounted = useRef(false);
  const { claimAPI, productAPI } = constants;
  const { company, dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const [item, setItem] = useState();
  const program = 'ASSEMBLY_TESTING_INSURANCE';
  const { t } = useTranslation();

  const initialState = {
    isOther: false,
    isComplaint: false
  };

  const formStateReducer = (state, action) => {
    switch (action.type) {
      case 'TOGGLE_STATE':
        return { ...state, [action.field]: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(formStateReducer, initialState);

  const mandatoryDocuments = state.isComplaint
    ? ['filing_complaint', 'full_statement']
    : ['full_statement'];

  const getItem = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `${productAPI}/${id}?populate=contract,company`
      });
      setItem(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    (async () => {
      await getItem();
    })();
    return () => {
      isMounted.current = false;
    };
  }, [id]);

  const { fieldsInformations } = useFields(state, dispatch, claimAPI);

  const config = useConfig(dispatch, company, program, item);

  return (
    <CreateUpdateContainer
      title={t(`professional_multirisk_insurances.form.${purpose}`)}
      fields={fieldsInformations}
      purpose={purpose}
      baseUrl={claimAPI}
      resource="claims"
      config={config}
      mandatoryDocuments={mandatoryDocuments}
      populate="?populate=documents.file"
    />
  );
};

AssemblyTestingClaimsCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
