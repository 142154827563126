import { useCallback, useEffect, useRef, useState } from 'react';
import pluralize from 'pluralize';
import { useParams } from 'react-router-dom';
import { Skeleton, Row, Col, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../../components';
import { History } from '../../../components/History';
import { useListContentSubIndividualHousesBuilder } from '../../../subscriptions/utils/listContents/listContentSubIndividualHousesBuilder';
import { useExtraButtons } from '../../utils/useExtraButtons';
import { useSignatureListContent } from './utils/signatureListContent';
import { Documents } from '../../../components/Documents';

/**
 * Represents the `ShowIndividualHousesBuilder` component that displays details of a datas.
 * @component
 *
 * @returns {JSX.Element} The rendered `ShowIndividualHousesBuilder` component which displays the automission's details, documents, and associated drivers.
 */
export const ShowIndividualHousesBuilder = () => {
  const isMounted = useRef(false);
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [dataSet, setDataSet] = useState({
    datas: {},
    programme: '',
    url: undefined
  });
  const { datas, programme } = dataSet;

  const legalProtectionInsuranceDataset = {
    ...datas,
    entity: datas.entity?.map((entityItem) => entityItem?.name)
  };
  const listContent = useListContentSubIndividualHousesBuilder(true, {
    individual_houses_builder: legalProtectionInsuranceDataset
  });
  const listContent2 = useSignatureListContent(false, {
    individual_houses_builder: legalProtectionInsuranceDataset
  });

  const { extraButtons } = useExtraButtons(
    isDeleted,
    dataSet,
    'Individual-houses-builder',
    'Individual-houses-builder'
  );

  const formatUrlPart = (str) =>
    pluralize(str.toLowerCase().replace(/_/g, '-'));

  const getIndividualHousesBuilder = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/individual-houses-builder/${id}?populate=customer_manager,unit_manager,entity,site,contract,company_Id`
      });
      if (isMounted.current) {
        setDataSet((prevState) => ({
          ...prevState,
          datas: {
            ...data
          },
          url: formatUrlPart(data?.programme_types),
          programme: data?.programme_types,
          isLoading: false
        }));
      }
    } catch (e) {
      if (e && isMounted.current) message(e);
    } finally {
      if (isMounted.current) setIsLoading(false);
    }
  }, [id, message]);

  useEffect(() => {
    isMounted.current = true;
    getIndividualHousesBuilder();
    return () => {
      isMounted.current = false;
    };
  }, [getIndividualHousesBuilder]);

  useEffect(() => {
    if (datas) setIsDeleted(datas?.deleted);
  }, [datas]);

  return (
    <>
      <PageHeaderCustom
        title={`${t('number')} ${datas?.unit_counter || 'xxxx'} - ${t(
          `subscriptions.form.${programme}`
        )}`}
        extra={extraButtons}
      />
      <ContentCustom>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={12}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <Row gutter={[0, 16]}>
                <Card title={t('subscriptions.form.informations')}>
                  <DescriptionList
                    data={listContent}
                    translate
                    layout="vertical"
                  />
                </Card>
                <Card title={t('subscriptions.form.informations')}>
                  <DescriptionList
                    data={listContent2}
                    translate
                    layout="vertical"
                    title="Signature du declarant"
                  />
                </Card>
              </Row>
            </Skeleton>
          </Col>
          <Col xs={24} xl={12}>
            <Documents
              idWithoutParams={id}
              baseUrl="individual-houses-builders"
              resource="legal_protection_insurance"
            />
            <Card title={t('subscriptions.form.history')} size="small">
              <History route="individual-houses-builders" />
            </Card>
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};
