import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton, Spin, Popconfirm, Flex } from 'antd';
import {
  EditOutlined,
  WarningOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { useColumns as useColumnsPayments } from './utils/columns/columnsPayments';
import { useColumns as useColumnsExcess } from './utils/columns/columnsExcess';
import { useColumns as useColumnsFees } from './utils/columns/columnsFees';
import { useColumns as useColumnsAppeals } from './utils/columns/columnsAppeals';
import { useColumns as useColumnsProvision } from './utils/columns/columnsProvision';
import { ListResourceWithModal } from '../../../components/ListResource/ListResourceWithModal';
import { CreateUpdateClaimAccount } from './CreateUpdateClaimAccountingOverview';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

export const ListClaimAccountingOverview = ({
  amountType,
  resourceName,
  withCreateButton,
  claimId,
  url,
  fields,
  setReload,
  reload,
  buttonBelowTable,
  noPagination,
  noActionColumnsButton,
  urlAccount,
  resourceNameClaim
}) => {
  const { t } = useTranslation();

  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [purpose, setPurpose] = useState('edit');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [idAmount, setIdAmount] = useState(null);
  const [addButtonText, setAddButtonText] = useState('');
  const [extraQueryFilter, setExtraQueryFilter] = useState('');
  const iconSize = 18;

  const deleteAmount = async (id) => {
    try {
      setIsSubmitting(true);
      await dispatchAPI('DELETE', {
        url: `/${urlAccount}/${id}/${resourceNameClaim}`
      });
      setIsSubmitting(false);
      setIsModalOpen(false);
      setForceRefresh(!forceRefresh);
      setReload(!reload);
    } catch (e) {
      message(e);
      setIsSubmitting(false);
    }
  };

  const getColumns = (type) => {
    switch (type) {
      case 'INVOICES_PAYMENT':
        return useColumnsPayments();
      case 'EXCESS':
        return useColumnsExcess();
      case 'FEES':
        return useColumnsFees();
      case 'APPEAL':
        return useColumnsAppeals();
      case 'ACCOUNTING_PROVISION':
        return useColumnsProvision();
      default:
        return [];
    }
  };

  const setButtonText = () => {
    if (amountType === 'ACCOUNTING_PROVISION') {
      setAddButtonText('claims.form.add_provision');
    } else {
      setAddButtonText('claims.form.add_line');
    }
  };

  const columns = getColumns(amountType);

  useEffect(() => {
    setButtonText();
    setExtraQueryFilter(
      amountType === 'ACCOUNTING_PROVISION' ? '_id' : 'claim_id'
    );
  }, [amountType]);

  const actionColumn = () => [
    {
      key: 'name',
      sorter: false,
      render: (record) => (
        <Flex align="center" wrap="nowrap" justify="end" gap="small">
          {!noActionColumnsButton && (
            <EditOutlined
              style={{ fontSize: iconSize }}
              onClick={() => {
                setIsModalOpen(true);
                setPurpose('edit');
                setIdAmount(record?._id);
              }}
            />
          )}
          {amountType !== 'ACCOUNTING_PROVISION' && !noActionColumnsButton && (
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok_delete')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => deleteAmount(record?._id)}
              icon={<WarningOutlined />}
            >
              <DeleteOutlined
                style={{ color: 'var(--errorColor)', fontSize: iconSize }}
                type="delete"
              />
            </Popconfirm>
          )}
        </Flex>
      )
    }
  ];

  return (
    <Spin spinning={isSubmitting}>
      {amountType && fields && url && extraQueryFilter && (
        <Skeleton
          active
          loading={isSubmitting}
          paragraph={{ rows: 5 }}
          style={{ width: '100%' }}
        >
          <ListResourceWithModal
            resourceName={resourceName}
            extraQuery={`${extraQueryFilter}=${claimId}&amount_type=${amountType}`}
            columns={[...columns, ...actionColumn()]}
            withPageHeader={false}
            withCreateButton={withCreateButton}
            setForceRefresh={setForceRefresh}
            forceRefresh={forceRefresh}
            customActionColumn
            withSearchBar={false}
            openModal
            buttonBelowTable={buttonBelowTable}
            noUpperButton
            noMenu
            canAdd
            canDelete
            amountType={amountType}
            setPurpose={setPurpose}
            setIsModalOpen={setIsModalOpen}
            onDoubleClickAction={false}
            addButtonText={addButtonText}
            noPagination={noPagination}
            urlAccount={urlAccount}
            resourceNameClaim={resourceNameClaim}
            scroll={{ x: 500 }}
          />
          {isModalOpen && (
            <CreateUpdateClaimAccount
              isModalOpen={isModalOpen}
              amountType={amountType}
              fields={fields}
              setIsModalOpen={setIsModalOpen}
              purpose={purpose}
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
              modalTitle={amountType}
              url={url}
              idWithoutParams={idAmount}
              setReload={setReload}
              reload={reload}
            />
          )}
        </Skeleton>
      )}
    </Spin>
  );
};

ListClaimAccountingOverview.propTypes = {
  claimId: PropTypes.string,
  url: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  amountType: PropTypes.string.isRequired,
  withCreateButton: PropTypes.bool,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setReload: PropTypes.func,
  reload: PropTypes.bool,
  buttonBelowTable: PropTypes.bool,
  noPagination: PropTypes.bool,
  noActionColumnsButton: PropTypes.bool,
  urlAccount: PropTypes.string,
  resourceNameClaim: PropTypes.string
};

ListClaimAccountingOverview.defaultProps = {
  claimId: null,
  setReload: () => {},
  reload: false,
  buttonBelowTable: false,
  noPagination: false,
  noActionColumnsButton: false,
  withCreateButton: false,
  urlAccount: '',
  resourceNameClaim: ''
};
