import PropTypes from 'prop-types';
import { Typography, Flex } from 'antd';
import { FloodWarning, TextNewLine } from '@carbon/icons-react';

/**
 * Renders a component to display notification content for a claim.
 * @component
 * @param {Object} props - The props object.
 * @param {string} props.title - The title of the notification.
 * @param {Object} props.item - The item object containing information about the claim.
 * @param {string[]} props.item.infoClaim - An array of claim information.
 * @param {Function} props.t - A translation function.
 * @returns {JSX.Element} JSX element representing the notification content for the claim.
 */

export const CreateNotifContentClaim = ({ title, item, t }) => (
  <Flex justify="space-between" align="center" wrap="wrap" gap="small">
    <Flex align="center" gap="small">
      <FloodWarning />
      <Typography.Text strong style={{ marginRight: 4 }}>
        {title}
      </Typography.Text>
      {item?.infoClaim &&
        item.infoClaim.map((element) => (
          <div key={element}>
            <TextNewLine />
            <Typography.Text strong style={{ marginRight: 4 }}>
              {t(`claims.form.${element}`)}
            </Typography.Text>
          </div>
        ))}
    </Flex>
  </Flex>
);

CreateNotifContentClaim.propTypes = {
  title: PropTypes.string.isRequired,
  item: PropTypes.shape({
    infoClaim: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  t: PropTypes.func.isRequired
};
