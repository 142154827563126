import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Button, Modal, Form, Input, Select } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useCompanyContext } from '../../CompanyContext';
import { useHandleUpdatePermissions } from '../Hooks/useHandleUpdatePermissions';

/**
 * Modal component for creating or updating tree items (sites or entities).
 *
 * @component
 * @param {string} itemType - Type of the item (e.g., 'entities', 'sites').
 * @param {boolean} isModalOpen - Whether the modal is open.
 * @param {Function} setIsModalOpen - Function to control the modal open state.
 * @param {Function} setIsSubmitting - Function to set the submitting state.
 * @returns {JSX.Element} The CreateUpdateTreeModal component.
 */
const CreateUpdateTreeModal = ({
  itemType,
  isModalOpen,
  setIsModalOpen,
  setIsSubmitting
}) => {
  const { t } = useTranslation();
  const { dispatchAPI, company } = useAuthContext();
  const { handleUpdatePermissions } = useHandleUpdatePermissions();
  const { message: errorMessage } = useErrorMessage();
  const [form] = Form.useForm();
  const [entitiesOptions, setEntitiesOptions] = useState([]);
  const [isSavingSite, setIsSavingSite] = useState(false);
  const { forceRefresh, setForceRefresh } = useCompanyContext();

  const getEntities = async () => {
    setIsSubmitting(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/entities/company/${company}`
      });
      setEntitiesOptions(data);
    } catch (e) {
      errorMessage(e);
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      (async () => {
        await getEntities();
      })();
    }
  }, [isModalOpen]);

  const addSite = async (body) => {
    setIsSubmitting(true);
    try {
      const { data } = await dispatchAPI('POST', {
        url: '/sites',
        body: JSON.stringify({ name: body })
      });
      return data._id;
    } catch (e) {
      errorMessage(e);
    } finally {
      setIsSubmitting(false);
    }
    return null;
  };

  const patchEntities = async (siteId, entityId, siteName) => {
    setIsSubmitting(true);
    try {
      await dispatchAPI('PATCH', {
        url: `/entities/sites/${entityId}`,
        body: {
          siteId: String(siteId),
          entityId: String(entityId),
          siteName
        }
      });
    } catch (e) {
      errorMessage(e.response.data);
    }
    setIsSubmitting(false);
  };

  const onFinish = async (values) => {
    setIsSavingSite(true);
    const siteId = await addSite(values.name);
    const entityPromises = values.entities.map(async (entity) => {
      await patchEntities(siteId, entity, values.name);
    });
    await Promise.all(entityPromises);
    await handleUpdatePermissions();
    setIsSavingSite(false);
    setForceRefresh(!forceRefresh);
    setIsModalOpen(false);
  };

  const onCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
    setForceRefresh(!forceRefresh);
  };

  return (
    <Modal
      title={t('companies.form.create_site')}
      open={isModalOpen}
      onCancel={onCancel}
      footer={[]}
    >
      <Form
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        layout="vertical"
      >
        <Form.Item
          name="entities"
          label={t('entities.form.select_entity')}
          rules={[{ required: true }]}
        >
          <Select mode="multiple" allowClear style={{ width: '100%' }}>
            {entitiesOptions.map((entity) => (
              <Select.Option key={entity._id} value={entity._id}>
                {entity.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="name"
          label={
            itemType === 'entities'
              ? t('entities.form.entity_name')
              : t('sites.form.name')
          }
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Row justify="end">
            <Button
              style={{ margin: '0 10px' }}
              type="link"
              danger
              onClick={onCancel}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
            <Button type="primary" htmlType="submit" loading={isSavingSite}>
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

CreateUpdateTreeModal.propTypes = {
  itemType: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  setIsSubmitting: PropTypes.func
};

CreateUpdateTreeModal.defaultProps = {
  setIsSubmitting: false
};

export default CreateUpdateTreeModal;
