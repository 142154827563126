import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Divider, Modal } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';

/**
 * Component for creating or updating a claim amount.
 *
 * @component
 * @param {string} purpose - Purpose of the modal ('create' or 'edit').
 * @param {object} entityData - Data of the claim amount being edited.
 * @param {string} entityId - ID of the claim amount being edited.
 * @param {boolean} forceRefresh - Indicates if a refresh is needed.
 * @param {function} setForceRefresh - Function to set the forceRefresh value.
 * @param {boolean} isModalOpen - Indicates if the modal is open.
 * @param {function} setIsModalOpen - Function to set the modal's open state.
 * @returns {JSX.Element} CreateUpdateClaimAccount component.
 */
export const CreateUpdateClaimAccount = ({
  fields,
  url,
  amountType,
  purpose,
  idWithoutParams,
  forceRefresh,
  setForceRefresh,
  isModalOpen,
  setIsModalOpen,
  modalTitle,
  setReload,
  reload
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { company } = useAuthContext();

  const onCancel = () => {
    setIsModalOpen(false);
    setForceRefresh(!forceRefresh);
  };

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        company,
        ...data,
        claim_id: id,
        amount_type: amountType
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        accounting_started: true,
        amount_type: amountType
      })
    }
  };
  return (
    <Modal
      title={
        purpose === 'create'
          ? t(`claims.form.create_${modalTitle}`)
          : t(`claims.form.modify_${modalTitle}`)
      }
      open={isModalOpen}
      onCancel={onCancel}
      style={{ marginTop: 200 }}
      footer={[]}
    >
      <Divider />
      <CreateUpdateContainer
        purpose={purpose}
        fields={fields}
        baseUrl={url}
        urlFileKeys="claim-accounts"
        idWithoutParams={idWithoutParams}
        resource="claims"
        config={config}
        withFilesManager={false}
        withPageHeaderCustom={false}
        isModal
        customModal={onCancel}
        setReload={setReload}
        reload={reload}
      />
    </Modal>
  );
};

CreateUpdateClaimAccount.propTypes = {
  purpose: PropTypes.string.isRequired,
  forceRefresh: PropTypes.bool.isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  modalTitle: PropTypes.string,
  url: PropTypes.string,
  idWithoutParams: PropTypes.string,
  setReload: PropTypes.func,
  reload: PropTypes.bool,
  amountType: PropTypes.string
};

CreateUpdateClaimAccount.defaultProps = {
  modalTitle: '',
  url: '',
  idWithoutParams: '',
  setReload: () => {},
  reload: false,
  amountType: ''
};
