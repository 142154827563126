import { useEffect } from 'react';
import { useFiltersDataFetch } from '../../../components/ColumnsDynamicFilters/useFiltersDatasFetch';
import { useColumnCollection } from './utils/columnsCollection';

/**
 * Custom React hook that provides a list of column configurations for a table component.
 * Column titles are fetched using the `react-i18next` hook for internationalization.
 * @hook
 * @param {Object} options - Options object.
 * @param {string} options.resourceName - The name of the resource.
 * @param {Object} options.dbExtraQueryFilter - Additional query filters for fetching data.
 * @param {boolean} options.populate - Flag indicating whether to populate data.
 * @param {string} options.targetType - Optional target type.
 * @returns {Array} An array containing the list of column configurations and loading status.
 */
export const useColumns = ({
  resourceName,
  dbExtraQueryFilter,
  populate,
  targetType
}) => {
  const { fetchFilters, loading } = useFiltersDataFetch(
    resourceName,
    dbExtraQueryFilter,
    resourceName,
    populate
  );
  const {
    missing_documents,
    entity,
    site,
    sinister_date,
    claim_type,
    shipper_name,
    departureCity,
    arrivalCity,
    insurance_company_reference,
    client_reference,
    insured_status,
    goods_to_be_appraised,
    goods_type,
    opening_date,
    commentaries,
    claim_status
  } = useColumnCollection({
    resourceName,
    dbExtraQueryFilter,
    populate,
    targetType
  });

  useEffect(() => {
    fetchFilters();
  }, []);

  if (targetType) {
    const columns = [
      missing_documents,
      entity,
      site,
      client_reference,
      claim_status,
      sinister_date,
      claim_type,
      shipper_name,
      departureCity,
      arrivalCity,
      opening_date,
      commentaries
    ];

    return [columns, loading];
  }

  const columns = [
    missing_documents,
    entity,
    client_reference,
    claim_status,
    claim_type,
    insured_status,
    insurance_company_reference,
    sinister_date,
    goods_to_be_appraised,
    goods_type,
    opening_date,
    commentaries
  ];

  return [columns, loading];
};
