import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
  translate,
  translateArray
} from '../../../../utils/formatters/listContent';

/**
 * Custom hook for building the list content for individual houses builders.
 *
 * @param {boolean} isProgram - Indicates if it is a program.
 * @param {Object} data - The data object containing individual houses builder information.
 * @returns {Array} - An array of objects representing the list content.
 */
export const useListContentSubIndividualHousesBuilder = (
  isProgram,
  data = {}
) => {
  const { t } = useTranslation();
  const { individual_houses_builder = {} } = data;
  const {
    subscriber_name,
    subscriber_reg_number,
    contract_number_from_subscription,
    is_1990_law_construction_contract,
    is_delivered_weather_proof,
    is_real_estate_promotion_contract_vefa,
    is_expansion,
    project_owner_name,
    current_adress,
    construction_adress,
    detached_house_height,
    floor_area,
    is_basement,
    is_attic,
    if_attic,
    is_ancillary_construction,
    if_is_ancillary_construction,
    is_project_owner_carry_out_finishing_works,
    is_project_owner_carry_out_construction_works,
    if_is_project_owner_carry_out_work,
    if_other_carried_out_work,
    global_carried_out_work_value,
    is_renewable_energies_installations,
    if_is_geothermal_energy_amount,
    if_is_solar_thermal_energy_amount,
    if_is_aerothermal_energy_amount,
    if_is_earth_air_heat_exchanger_amount,
    if_is_photovoltaic_panels_amount,
    if_photovoltaic_installation,
    construction_contract_signature_date,
    construction_start_date_declaration,
    construction_start_date,
    expected_construction_end_date,
    agreed_price_ancillary_construction_included,
    construction_permit_number,
    delivered_by,
    construction_guarantee_amount_delivered_by,
    agreement_number,
    construction_attestation_number,
    is_location_under_clay_risk_management_plan,
    is_location_under_seismic_regulation,
    is_geotechnical_study,
    if_is_geotechnical_study,
    is_construction_principal_positionning_by_land_surveyor,
    declarant_last_name,
    declarant_first_name,
    declarant_function
  } = individual_houses_builder;
  const tradKey = 'individual_houses_builders.form.enums.';
  const contentList = [
    {
      label: 'individual_houses_builders.form.subscriber_name',
      span: 1,
      content: (subscriber_name && subscriber_name) || '-'
    },
    {
      label: 'individual_houses_builders.form.subscriber_reg_number',
      span: 1,
      content: (subscriber_reg_number && subscriber_reg_number) || '-'
    },
    {
      label:
        'individual_houses_builders.form.contract_number_from_subscription',
      span: 1,
      content:
        (contract_number_from_subscription &&
          contract_number_from_subscription) ||
        '-'
    },
    {
      label:
        'individual_houses_builders.form.is_1990_law_construction_contract',
      span: 1,
      content: translate(t, tradKey, is_1990_law_construction_contract)
    },
    {
      label: 'individual_houses_builders.form.is_delivered_weather_proof',
      span: 1,
      content: translate(t, tradKey, is_delivered_weather_proof)
    },
    {
      label:
        'individual_houses_builders.form.is_real_estate_promotion_contract_vefa',
      span: 1,
      content: translate(t, tradKey, is_real_estate_promotion_contract_vefa)
    },
    {
      label: 'individual_houses_builders.form.is_expansion',
      span: 1,
      content: translate(t, tradKey, is_expansion)
    },
    {
      label: 'individual_houses_builders.form.project_owner_name',
      span: 1,
      content: (project_owner_name && project_owner_name) || '-'
    },
    {
      label: 'individual_houses_builders.form.current_adress',
      span: 1,
      content: (current_adress && current_adress) || '-'
    },
    {
      label: 'individual_houses_builders.form.construction_adress',
      span: 1,
      content: (construction_adress && construction_adress) || '-'
    },
    {
      label: 'individual_houses_builders.form.detached_house_height',
      span: 1,
      content: translate(t, tradKey, detached_house_height)
    },
    {
      label: 'individual_houses_builders.form.floor_area',
      span: 1,
      content: (floor_area && floor_area) || '-'
    },
    {
      label: 'individual_houses_builders.form.is_basement',
      span: 1,
      content: translate(t, tradKey, is_basement)
    },
    {
      label: 'individual_houses_builders.form.is_attic',
      span: 1,
      content: translate(t, tradKey, is_attic)
    },
    {
      label: 'individual_houses_builders.form.if_attic',
      span: 1,
      content: translate(t, tradKey, if_attic) || '-'
    },
    {
      label: 'individual_houses_builders.form.is_ancillary_construction',
      span: 1,
      content: translate(t, tradKey, is_ancillary_construction)
    },
    {
      label: 'individual_houses_builders.form.if_is_ancillary_construction',
      span: 1,
      content: translateArray(t, tradKey, if_is_ancillary_construction) || '-'
    },
    {
      label:
        'individual_houses_builders.form.is_project_owner_carry_out_finishing_works',
      span: 1,
      content: translate(t, tradKey, is_project_owner_carry_out_finishing_works)
    },
    {
      label:
        'individual_houses_builders.form.is_project_owner_carry_out_construction_works',
      span: 1,
      content: translate(
        t,
        tradKey,
        is_project_owner_carry_out_construction_works
      )
    },
    {
      label:
        'individual_houses_builders.form.if_is_project_owner_carry_out_work',
      span: 1,
      content:
        translateArray(t, tradKey, if_is_project_owner_carry_out_work) || '-'
    },
    {
      label: 'individual_houses_builders.form.if_other_carried_out_work',
      span: 1,
      content: (if_other_carried_out_work && if_other_carried_out_work) || '-'
    },
    {
      label: 'individual_houses_builders.form.global_carried_out_work_value',
      span: 1,
      content:
        (global_carried_out_work_value && global_carried_out_work_value) || '-'
    },
    {
      label:
        'individual_houses_builders.form.is_renewable_energies_installations',
      span: 1,
      content: translate(t, tradKey, is_renewable_energies_installations)
    },
    {
      label: 'individual_houses_builders.form.if_is_geothermal_energy_amount',
      span: 1,
      content:
        (if_is_geothermal_energy_amount && if_is_geothermal_energy_amount) ||
        '-'
    },
    {
      label:
        'individual_houses_builders.form.if_is_solar_thermal_energy_amount',
      span: 1,
      content:
        (if_is_solar_thermal_energy_amount &&
          if_is_solar_thermal_energy_amount) ||
        '-'
    },
    {
      label: 'individual_houses_builders.form.if_is_aerothermal_energy_amount',
      span: 1,
      content:
        (if_is_aerothermal_energy_amount && if_is_aerothermal_energy_amount) ||
        '-'
    },
    {
      label:
        'individual_houses_builders.form.if_is_earth_air_heat_exchanger_amount',
      span: 1,
      content:
        (if_is_earth_air_heat_exchanger_amount &&
          if_is_earth_air_heat_exchanger_amount) ||
        '-'
    },
    {
      label: 'individual_houses_builders.form.if_is_photovoltaic_panels_amount',
      span: 1,
      content:
        (if_is_photovoltaic_panels_amount &&
          if_is_photovoltaic_panels_amount) ||
        '-'
    },
    {
      label: 'individual_houses_builders.form.if_photovoltaic_installation',
      span: 1,
      content: translateArray(t, tradKey, if_photovoltaic_installation)
    },
    {
      label:
        'individual_houses_builders.form.construction_contract_signature_date',
      span: 1,
      content:
        (construction_contract_signature_date &&
          dayjs(construction_contract_signature_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label:
        'individual_houses_builders.form.construction_start_date_declaration',
      span: 1,
      content:
        (construction_start_date_declaration &&
          dayjs(construction_start_date_declaration).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'individual_houses_builders.form.construction_start_date',
      span: 1,
      content:
        (construction_start_date &&
          dayjs(construction_start_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'individual_houses_builders.form.expected_construction_end_date',
      span: 1,
      content:
        (expected_construction_end_date &&
          dayjs(expected_construction_end_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label:
        'individual_houses_builders.form.agreed_price_ancillary_construction_included',
      span: 1,
      content:
        (agreed_price_ancillary_construction_included &&
          agreed_price_ancillary_construction_included) ||
        '-'
    },
    {
      label: 'individual_houses_builders.form.construction_permit_number',
      span: 1,
      content: (construction_permit_number && construction_permit_number) || '-'
    },
    {
      label: 'individual_houses_builders.form.delivered_by',
      span: 1,
      content: (delivered_by && delivered_by) || '-'
    },
    {
      label:
        'individual_houses_builders.form.construction_guarantee_amount_delivered_by',
      span: 1,
      content:
        (construction_guarantee_amount_delivered_by &&
          construction_guarantee_amount_delivered_by) ||
        '-'
    },
    {
      label: 'individual_houses_builders.form.agreement_number',
      span: 1,
      content: (agreement_number && agreement_number) || '-'
    },
    {
      label: 'individual_houses_builders.form.construction_attestation_number',
      span: 1,
      content:
        (construction_attestation_number && construction_attestation_number) ||
        '-'
    },
    {
      label:
        'individual_houses_builders.form.is_location_under_clay_risk_management_plan',
      span: 1,
      content: translate(
        t,
        tradKey,
        is_location_under_clay_risk_management_plan
      )
    },
    {
      label:
        'individual_houses_builders.form.is_location_under_seismic_regulation',
      span: 1,
      content: translate(t, tradKey, is_location_under_seismic_regulation)
    },
    {
      label: 'individual_houses_builders.form.is_geotechnical_study',
      span: 1,
      content: translate(t, tradKey, is_geotechnical_study)
    },
    {
      label: 'individual_houses_builders.form.if_is_geotechnical_study',
      span: 1,
      content: translateArray(t, tradKey, if_is_geotechnical_study)
    },
    {
      label:
        'individual_houses_builders.form.is_construction_principal_positionning_by_land_surveyor',
      span: 1,
      content: translate(
        t,
        tradKey,
        is_construction_principal_positionning_by_land_surveyor
      )
    }
  ];

  if (!isProgram) {
    contentList.push(
      {
        label: 'individual_houses_builders.form.declarant_last_name',
        span: 1,
        content: (declarant_last_name && declarant_last_name) || '-'
      },
      {
        label: 'individual_houses_builders.form.declarant_first_name',
        span: 1,
        content: (declarant_first_name && declarant_first_name) || '-'
      },
      {
        label: 'individual_houses_builders.form.declarant_function',
        span: 1,
        content: (declarant_function && declarant_function) || '-'
      }
    );
  }
  return contentList;
};
