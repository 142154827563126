import { useCallback, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Button, Skeleton, Row, Col, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useListContentMainInformations } from './utils/listContentEventCancellation';
import {
  useListContentEventAddress,
  useListContentPostponeDates
} from './utils/listContentEventDateAddress';
import { useListContentEventSpecificGuarantees } from './utils/listContentEventSpecificGuarantees';
import { useListContentEventPreviousTenue } from './utils/listContentEventPreviousTenue';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../components';
import { routes } from '../../../utils/constants/adminRoutes';
import { Documents } from '../../components/Documents';
import { MailingButton } from '../../Mailing/MailingButton';
import { ClaimButton } from '../../components/ClaimButton';
import usePopConfirm from '../utils/popConfirms';
import { History } from '../../components/History';

/**
 * EventCancellationShow component.
 * Renders detailed information about a specific eventCancellations course, including its documents.
 * Provides options to edit or delete the eventCancellations course documents.
 *
 * @component
 * @returns {JSX.Element} EventCancellationShow
 */

export const EventCancellationShow = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [eventCancellations, setEventCancellation] = useState();
  const [visible, setVisible] = useState(false);
  const { exitProgram, unarchiveProgram } = usePopConfirm(
    'event-cancellations'
  );

  const listContentMainInformations =
    useListContentMainInformations(eventCancellations);
  const listContentEventLocation =
    useListContentEventAddress(eventCancellations);
  const listContentPostponeDates =
    useListContentPostponeDates(eventCancellations);
  const listContentEventSpecificGuarantees =
    useListContentEventSpecificGuarantees(eventCancellations);
  const listContentEventPreviousTenue =
    useListContentEventPreviousTenue(eventCancellations);

  const getEventCancellation = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/event-cancellations/${id}?populate=customer_manager,unit_manager,entity,site,contract,company`
      });
      setEventCancellation(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getEventCancellation();
    })();
  }, [getEventCancellation]);

  return (
    <>
      <PageHeaderCustom
        title={`${t('number')} ${
          eventCancellations?.unit_counter || 'xxxx'
        } - ${eventCancellations?.golf_name || ''} ${
          eventCancellations?.city || ''
        }`}
        extra={
          <>
            {eventCancellations && !eventCancellations.deleted && (
              <>
                <Link
                  to={{
                    pathname: `${routes.PROGRAMMES}/event-cancellations/edit/${id}`
                  }}
                >
                  <Button type="primary">
                    {`${t('buttons.edit')} `}
                    <EditOutlined />
                  </Button>
                </Link>
                <ClaimButton
                  id={id}
                  url="event-cancellations"
                  claimType="eventCancellations"
                />
                <MailingButton
                  asset={eventCancellations}
                  templateName="Blank"
                  tradKey="specific_demand"
                  resource="eventCancellations"
                  baseUrl="event-cancellations"
                />
              </>
            )}
            {eventCancellations &&
              !eventCancellations.deleted &&
              exitProgram({
                visible,
                setVisible
              })}
            {eventCancellations &&
              eventCancellations.deleted &&
              unarchiveProgram()}
          </>
        }
      />
      <ContentCustom>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={12}>
            <Skeleton loading={isLoading} title={0} paragraph={0} active />
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <Row gutter={[0, 16]}>
                <Card>
                  <DescriptionList
                    data={listContentMainInformations}
                    translate
                    layout="vertical"
                    title={t('event_cancellations.form.main_informations')}
                  />
                </Card>
                <Card>
                  <DescriptionList
                    data={listContentEventLocation}
                    translate
                    layout="vertical"
                    title={t('event_cancellations.form.event_location')}
                  />
                </Card>
              </Row>
            </Skeleton>
          </Col>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <Col xs={24} xl={12}>
              <Card>
                <DescriptionList
                  data={listContentPostponeDates}
                  translate
                  layout="vertical"
                  title={t(
                    'event_cancellations.form.if_delay_fallback_postponement_possible_date'
                  )}
                />
              </Card>
              <Card>
                <DescriptionList
                  data={listContentEventSpecificGuarantees}
                  translate
                  layout="vertical"
                  title={t(
                    'event_cancellations.form.basic_guarantees_premises_equipments_other_unavailability'
                  )}
                />
              </Card>
              <Card>
                <DescriptionList
                  data={listContentEventPreviousTenue}
                  translate
                  layout="vertical"
                  title={t('event_cancellations.form.if_event_ran_before')}
                />
              </Card>
              <Documents
                idWithoutParams={id}
                baseUrl="event-cancellations"
                resource="Event-cancellation"
              />
              <Card title={t('subscriptions.form.history')} size="small">
                <History route="Event-cancellation" />
              </Card>
            </Col>
          </Skeleton>
        </Row>
      </ContentCustom>
    </>
  );
};
