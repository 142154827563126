import { Checkbox } from 'antd';

/**
 * A custom hook that returns predefined field configurations.
 *
 * This configuration can be used in conjunction with form libraries or frameworks
 * that need a set of field definitions to render and validate form controls.
 *
 * @hook
 * @returns {Array<Object>} returns.golfFields - Field configurations for golfs.
 */

export const useEventCancellationFields = () => {
  const fieldNames = [
    'missing_documents',
    'company_name',
    'contract_number',
    'date_added',
    'entity',
    'site',
    'company_involved_name',
    'company_address',
    'postal_code',
    'city',
    'event_name',
    'event_type',
    'event_start_date',
    'event_end_date',
    'amount_engaged',
    'public_subsidies_to_substract',
    'total_guarantee_amount',
    'estimated_income',
    'deletion_date_effect'
  ];

  const eventCancellationFields = fieldNames.map((name) => ({
    name: [name],
    rules: [{ required: false }],
    valuePropName: 'checked',
    input: <Checkbox />
  }));

  return { eventCancellationFields };
};
