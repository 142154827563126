import { MailingButtonVehicles } from '../../../programmes/VehiclesFleets/components/MailingButtonVehicles';

/**
 * Renders the mailing button for vehicles based on the given conditions.
 * @component
 * @param {boolean} renderCondition - The condition to determine if the button should be rendered.
 * @param {object} vehicle - The vehicle object to be passed as an asset to the MailingButtonVehicles component.
 * @param {string} targetUrl - The target URL for the button.
 * @returns {JSX.Element|null} The rendered MailingButtonVehicles component or null if renderCondition is false.
 */
const renderMailingButtonVehicles = (renderCondition, vehicle, targetUrl) => {
  if (renderCondition)
    return (
      <MailingButtonVehicles
        asset={vehicle}
        templateName="Blank"
        tradKey="specific_demand"
        targetUrl={targetUrl}
      />
    );
  return null;
};
export default renderMailingButtonVehicles;
