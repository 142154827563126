import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Flex,
  Form,
  Modal,
  Select,
  Tooltip,
  message as messageANTD
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * ModalEditFileKey component is responsible for rendering a modal for editing file keys.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.filesKeys - The array of file keys.
 * @param {Object} props.item - The item to be edited.
 * @param {Function} props.setRefresh - The function to refresh the component.
 * @returns {JSX.Element} The JSX element representing the ModalEditFileKey component.
 */
export const ModalEditFileKey = ({
  filesKeys,
  item,
  setRefresh,
  existUnsavedFiles
}) => {
  const [visible, setVisible] = useState(false);
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();

  const updateFileKey = async (values) => {
    try {
      await dispatchAPI('PATCH', {
        url: `documents/filekey/${item?._id}`,
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      messageANTD.success(t('documents.filekey_updated'));
    } catch (e) {
      message(e);
    }
  };

  const onFinish = async (values) => {
    await updateFileKey(values);
    setVisible(false);
    form.resetFields();
    setRefresh((prev) => !prev);
  };

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  return (
    <Tooltip title={existUnsavedFiles && t('documents.save_to_modify')}>
      <Button
        onClick={() => {
          if (!existUnsavedFiles) setVisible(true);
        }}
        type="link"
        icon={
          <EditOutlined style={{ color: existUnsavedFiles && '#adb5bd' }} />
        }
      />
      <Modal
        footer={false}
        open={visible}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
        }}
      >
        <Form form={form} onFinish={onFinish}>
          <p>{item.name}</p>
          <Form.Item name="fileKey" rules={[{ required: true }]}>
            <Select style={{ width: '100%' }} defaultValue={item?.fileKey}>
              {filesKeys.map((key) => (
                <Select.Option key={key} value={key}>
                  {t(`files.keys.${key}`)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Flex>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: 10 }}
              >
                {t('documents.save')}
              </Button>
              <Button
                type="danger"
                onClick={onCancel}
                style={{ marginLeft: 10 }}
              >
                {t('documents.cancel')}
              </Button>
            </Flex>
          </Form.Item>
        </Form>
      </Modal>
    </Tooltip>
  );
};

ModalEditFileKey.propTypes = {
  filesKeys: PropTypes.arrayOf(PropTypes.string),
  item: PropTypes.shape({
    _id: PropTypes,
    fileKey: PropTypes.string,
    name: PropTypes.string
  }),
  setRefresh: PropTypes.func.isRequired,
  existUnsavedFiles: PropTypes.bool
};

ModalEditFileKey.defaultProps = {
  filesKeys: [],
  item: {},
  existUnsavedFiles: false
};
