import { useTranslation } from 'react-i18next';

/**
 * Produces a list content for displaying detailed event cancellation information.
 *
 * This hook extracts specified properties from the provided data, formats them
 * and returns an array of objects suitable for rendering in a description list.
 *
 * @hook
 * @param {Object} data - event cancellation data to process.
 * @returns {Array<Object>} - Formatted list content for rendering.
 *
 */

export const useListContentEventSpecificGuarantees = (data = {}) => {
  const { t } = useTranslation();

  const {
    bad_weather_specific_clauses,
    essential_person_unavailability_specific_clauses,
    if_essential_person_unavailable_details
  } = data;

  const labels = [
    {
      label: 'event_cancellations.form.bad_weather_specific_clauses',
      span: 1,
      content:
        (bad_weather_specific_clauses &&
          t(`event_cancellations.form.${bad_weather_specific_clauses}`)) ||
        '-'
    },
    {
      label:
        'event_cancellations.form.essential_person_unavailability_specific_clauses',
      span: 1,
      content:
        (essential_person_unavailability_specific_clauses &&
          t(
            `event_cancellations.form.${essential_person_unavailability_specific_clauses}`
          )) ||
        '-'
    },
    {
      label: 'event_cancellations.form.if_essential_person_unavailable_details',
      span: 1,
      content:
        (if_essential_person_unavailable_details &&
          if_essential_person_unavailable_details) ||
        '-'
    }
  ];
  return labels;
};
