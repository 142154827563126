import { Route, Routes } from 'react-router-dom';
import { ConversationContextProvider } from '../../contexts/ConversationContext';
import { Messages } from './Messages';
import { Exception } from '../../components';

export const MessagesRouter = () => (
  <ConversationContextProvider>
    <Routes>
      <Route index element={<Messages />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </ConversationContextProvider>
);
