import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Form, Row, Spin } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import Moment from 'moment';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { ContentCustom } from '../../../../components';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../../../utils/constants/formLayout';
import { fieldsCalculator } from './fieldsCalculator';

export const CreateUpdateCalculator = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI, company } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const generateFields = useGenerateFormItem();
  const [form] = Form.useForm();
  const [rate, setRate] = useState();
  const {
    civil_liability_insurance_rmco,
    damages_insurance_construction,
    work_damages_guarantee,
    after_work_damages
  } = fieldsCalculator();

  const updateRate = async (body) => {
    setIsSubmitting(true);
    try {
      await dispatchAPI('PATCH', {
        url: `/rates/company/${company}`,
        body
      });
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
    setIsSubmitting(false);
  };

  const createRate = async (body) => {
    setIsSubmitting(true);
    try {
      await dispatchAPI('POST', {
        url: `/rates`,
        body: {
          ...body,
          company
        }
      });
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
    setIsSubmitting(false);
  };

  const getRate = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/rates/company/${company}`
      });
      form.setFieldsValue(data);
      setRate(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      await getRate();
    })();
  }, [getRate]);

  const handleSubmit = async (values) => {
    if (rate) {
      await updateRate(values);
    } else {
      await createRate(values);
    }
  };

  return (
    <ContentCustom>
      <Spin spinning={isLoading}>
        <p style={{ color: 'var(--gray600)', fontStyle: 'italic' }}>
          {t('rates.form.date')} :{' '}
          {rate && Moment(rate.updated_at).format('DD-MM-YYYY')}
        </p>
        <Form {...formItemLayout} onFinish={handleSubmit} form={form}>
          <Divider orientation="center">
            {t('rates.form.civil_liability_insurance_rmco')}
          </Divider>
          {civil_liability_insurance_rmco.map((field) =>
            generateFields('rates', field)
          )}
          <Divider orientation="center">{t('rates.form.work_damage')}</Divider>
          {damages_insurance_construction.map((field) =>
            generateFields('rates', field)
          )}
          <Divider orientation="center">
            {t('rates.form.construction_insurance')}
          </Divider>
          {work_damages_guarantee.map((field) =>
            generateFields('rates', field)
          )}
          <Divider orientation="center">
            {t('rates.form.damage_guarantee')}
          </Divider>
          {after_work_damages.map((field) => generateFields('rates', field))}
          <Form.Item {...tailFormItemLayout}>
            <Row justify="end">
              <Button style={{ margin: '0 10px' }} type="link" danger>
                {`${t('buttons.cancel')} `}
                <CloseOutlined />
              </Button>
              <Button type="primary" htmlType="submit" loading={isSubmitting}>
                {`${t('buttons.save')} `}
                <CheckOutlined />
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Spin>
    </ContentCustom>
  );
};
