import PropTypes from 'prop-types';
import { Row, Typography } from 'antd';

const { Text } = Typography;

/**
 * Renders a list of entities.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.record - The record object containing the entities.
 * @param {Array} props.record.entity - The array of entities.
 * @param {string} props.record.entity.id - The ID of the entity.
 * @param {string} props.record.entity.name - The name of the entity.
 * @returns {JSX.Element} The rendered component.
 */

export const EntitiesDisplay = ({ record }) => (
  <Row>
    {record?.entity?.map((e, index, array) => (
      <Text key={e._id}>
        {e.name}
        {index < array.length - 1 ? ',\u00A0\u00A0' : ''}
      </Text>
    ))}
  </Row>
);

EntitiesDisplay.propTypes = {
  record: PropTypes.shape({
    entity: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      })
    )
  })
};

EntitiesDisplay.defaultProps = {
  record: {
    entity: []
  }
};
