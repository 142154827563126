import { useTranslation } from 'react-i18next';
import { Card, List, Spin, Badge } from 'antd';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Notification, UserMultiple } from '@carbon/icons-react';
import { handleNotifMenuItemLink } from './handleNotificationMenuLink';
import { useSocketContext } from '../../contexts/SocketContext';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

/**
 * @component
 * @description Alerts
 * @param {Object} props Props
 * @param {Function} props.t Translate function
 * @param {Function} props.dispatchAPI Dispatch API function
 * @param {Function} props.message Message function
 * @param {Object} props.datas Datas
 * @param {Object[]} props.datas.vehicule Vehicule
 * @param {Object[]} props.datas.golves Golves
 * @param {Object[]} props.datas.transports Transports
 * @param {Object[]} props.datas.property Property
 * @param {Object[]} props.datas.machineryBreakage MachineryBreakage
 * @param {Object[]} props.datas.holeInOne HoleInOne
 * @param {Object[]} props.datas.eventCancellation EventCancellation
 * @param {string} props.notifclass Notifclass
 * @returns {React.ReactNode} Alerts
 */

export const Alerts = ({
  datas: {
    vehicule,
    golfs,
    transports,
    property,
    machineryBreakage,
    holeInOne,
    eventCancellation,
    callForContribution
  } = {},
  notifclass
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const [notifications, setNotifications] = useState([]);
  const {
    loadMore,
    loading,
    alertNotifications,
    gestionnaireNotifications,
    alertNotificationsCount,
    gestionnaireNotificationsCount,
    forceRefresh,
    setForceRefresh
  } = useSocketContext();

  const deleteNotification = async (id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `notifications/delete/${id}`
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    if (notifclass === 'alert') {
      setNotifications(
        alertNotifications?.filter(
          (notification) => notification.item.type !== null
        )
      );
    } else if (notifclass === 'gestionnaire') {
      setNotifications(
        gestionnaireNotifications?.filter(
          (notification) => notification.item.type !== null
        )
      );
    }
  }, [alertNotifications, gestionnaireNotifications]);
  return (
    <Card
      title={
        <>
          {notifclass !== 'gestionnaire' ? (
            <Notification size={22} />
          ) : (
            <UserMultiple size={22} />
          )}
          {t(`notifications.supervisions_alerts.${notifclass}`)}
        </>
      }
      className="alert-card"
      extra={
        notifclass === 'alert' ? (
          <Badge count={alertNotificationsCount} overflowCount={999} />
        ) : (
          <Badge count={gestionnaireNotificationsCount} overflowCount={999} />
        )
      }
    >
      <Spin spinning={loading}>
        <List
          dataSource={notifications}
          renderItem={(item) =>
            handleNotifMenuItemLink(
              item._id,
              item.item,
              deleteNotification,
              t,
              item.updated_at,
              item.consulted,
              vehicule,
              golfs,
              transports,
              property,
              machineryBreakage,
              holeInOne,
              eventCancellation,
              callForContribution
            )
          }
        />
        {notifclass === 'gestionnaire' && loadMore && loadMore}
      </Spin>
    </Card>
  );
};

Alerts.propTypes = {
  datas: PropTypes.shape({
    vehicule: PropTypes.arrayOf(PropTypes.shape({})),
    golfs: PropTypes.arrayOf(PropTypes.shape({})),
    transports: PropTypes.arrayOf(PropTypes.shape({})),
    property: PropTypes.arrayOf(PropTypes.shape({})),
    machineryBreakage: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    holeInOne: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    eventCancellation: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    callForContribution: PropTypes.arrayOf(PropTypes.shape({})).isRequired
  }),
  notifclass: PropTypes.string.isRequired
};

Alerts.defaultProps = {
  datas: null
};
