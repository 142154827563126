import { Route, Routes } from 'react-router-dom';
import { ClaimsTilesPage } from './ClaimsTilesPage';
import { AutomissionsRouter } from './VehiclesFleets/Automissions/AutomissionsRouter';
import { LightVehiclesRouter } from './VehiclesFleets/LightVehicles/LightVehiclesRouter';
import { OtherVehiclesRouter } from './VehiclesFleets/OtherVehicles/OtherVehiclesRouter';
import { HeavyVehiclesRouter } from './VehiclesFleets/HeavyVehicles/HeavyVehiclesRouter';
import { PropertyRouter } from './Property/PropertyRouter';
import { GolfRouter } from './Golf/GolfRouter';
import { CivilLiabilityExecutiveRouter } from './CivilLiability/CivilLiabilityExecutive/CivilLiabilityExecutiveRouter';
import { CivilLiabilityProRouter } from './CivilLiability/CivilLiabilityPro/CivilLiabilityProRouter';
import { AdValoremRouter } from './Transport/AdValorem/AdValoremRouter';
import { FreightedGoodsRouter } from './Transport/FreightedGoods/FreightedGoodsRouter';
import { OwnAccountsRouter } from './Transport/OwnAccounts/OwnAccountsRouter';
import { FacultiesRouter } from './Transport/Faculties/FacultiesRouter';
import { ConstructionInsuranceRouter } from './Construction/ConstructionInsurance/ConstructionInsuranceRouter';
import { OthersConstructionRouter } from './Construction/OthersConstruction/OthersConstructionRouter';
import { MachineryBreakageClaimsRouter } from './MachineryBreakageClaim/MachineryBreakageClaimsRouter';
import { HoleInOneClaimsRouter } from './HoleInOneClaim/HoleInOneClaimsRouter';
import { EventCancellationClaimsRouter } from './EventCancellationClaim/EventCancellationClaimsRouter';
import { CyberInsuranceClaimsRouter } from './CyberInsuranceClaim/CyberInsuranceClaimsRouter';
import { HardwareInsuranceClaimsRouter } from './HardwareInsuranceClaim/HardwareInsuranceClaimsRouter';
import { ProfessionalMultiriskInsuranceClaimRouter } from './ProMultiriskInsurance/ProfessionalMultiriskInsuranceClaimRouter';
import { AssemblyTestingClaimsRouter } from './AssemblyTestingInsuranceClaim/AssemblyTestingClaimsRouter';
import { EnvironmentInsuranceRouter } from './EnvironmentInsuranceClaim/EnvironmentInsuranceClaimsRouter';
import { Exception } from '../../components';
import { LegalProtectionInsuranceClaimsRouter } from './LegalProtectionClaim/LegalProtectionInsuranceClaimsRouter';
import { ConversationContextProvider } from '../../contexts/ConversationContext';
import { IndividualHousesBuilderClaimsRouter } from './IndividualHousesBuilderClaim/IndividualHousesBuilderClaimRouter';

/**
 * A router component for handling claims related routes.
 * @component
 * @returns {JSX.Element} The JSX element representing the ClaimsRouter.
 */
export const ClaimsRouter = () => (
  <ConversationContextProvider>
    <Routes>
      <Route
        path="legal-protection-insurances/*"
        element={<LegalProtectionInsuranceClaimsRouter />}
      />
      <Route
        path="environment-insurances/*"
        element={<EnvironmentInsuranceRouter />}
      />
      <Route
        path="assembly-testing-insurances/*"
        element={<AssemblyTestingClaimsRouter />}
      />
      <Route
        path="professional-multirisk-insurances/*"
        element={<ProfessionalMultiriskInsuranceClaimRouter />}
      />
      <Route
        path="hardware-insurances/*"
        element={<HardwareInsuranceClaimsRouter />}
      />
      <Route
        path="cyber-insurances/*"
        element={<CyberInsuranceClaimsRouter />}
      />
      <Route
        path="event-cancellations/*"
        element={<EventCancellationClaimsRouter />}
      />
      <Route path="hole-in-ones/*" element={<HoleInOneClaimsRouter />} />
      <Route
        path="machinery-breakages/*"
        element={<MachineryBreakageClaimsRouter />}
      />
      <Route path="/properties/*" element={<PropertyRouter />} />
      <Route path="/golfs/*" element={<GolfRouter />} />
      <Route path="/light-vehicles/*" element={<LightVehiclesRouter />} />
      <Route path="/heavy-vehicles/*" element={<HeavyVehiclesRouter />} />
      <Route path="/other-vehicles/*" element={<OtherVehiclesRouter />} />
      <Route path="/automissions/*" element={<AutomissionsRouter />} />
      <Route
        path="/civil-liability-pro/*"
        element={<CivilLiabilityProRouter />}
      />
      <Route
        path="/civil-liability-executive/*"
        element={<CivilLiabilityExecutiveRouter />}
      />

      <Route path="/freighted-goods/*" element={<FreightedGoodsRouter />} />
      <Route path="/own-accounts/*" element={<OwnAccountsRouter />} />
      <Route path="/faculties/*" element={<FacultiesRouter />} />
      <Route path="/ad-valorem/*" element={<AdValoremRouter />} />

      <Route
        path="/construction-insurance/*"
        element={<ConstructionInsuranceRouter />}
      />
      <Route
        path="/other-construction/*"
        element={<OthersConstructionRouter />}
      />
      <Route
        path="/individual-houses-builders/*"
        element={<IndividualHousesBuilderClaimsRouter />}
      />
      <Route
        index
        element={<ClaimsTilesPage title="claims" tilesPage="claims" />}
      />
      <Route path="*" element={<Exception />} />
    </Routes>
  </ConversationContextProvider>
);
