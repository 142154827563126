import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { DatePicker, Select, Checkbox, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { MultiSelectEntity } from '../../components/MultiSelectEntity';
import { GenericSelectEnums } from '../../components/GenericSelectEnums';

const { Option } = Select;

/**
 * `useFields` is a custom hook that provides fields for forms and manages their associated states.
 *
 * @returns
 * @property {Array} fields - An array of field configurations for the form.
 * @property {boolean} isFieldsLoading - A flag to determine whether the form fields are being loaded.
 * @hook
 */

export const useFields = (
  selectedType,
  isMandatory,
  setIsMandatory,
  isFieldsLoading,
  setIsFieldsLoading,
  programmeType,
  subProgrammeType,
  currentDates,
  setCurrentDates
) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [enums, setEnums] = useState({});
  const [checkBoxState, setCheckBoxState] = useState();

  const handleDateChange = (dateToDefine) => (date, dateString) => {
    setCurrentDates({
      ...currentDates,
      [dateToDefine]: dateString
    });
  };

  const mirrorCheckBox = (e) => {
    setCheckBoxState(e.target.checked);
  };
  useEffect(() => {
    setIsMandatory(!isMandatory);
  }, [checkBoxState]);

  const fields = [
    {
      label: ['document_type'],
      rules: [{ required: false }],
      input: (
        <Input placeholder={t(`subscriptions.form.${selectedType}`)} disabled />
      )
    },
    {
      name: ['insurance_company'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          filterOption={(input, option) =>
            (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
          }
          placeholder={t('documents.form.select_insurance_company')}
          style={{ width: '100%' }}
        >
          {(enums?.insurance_companies || []).map((i) => (
            <Option key={i} value={i}>
              {i}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: ['is_pending'],
      name: ['is_pending'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox onChange={mirrorCheckBox} />
    },
    {
      label: ['contract_number'],
      name: ['contract_number'],
      rules: [{ required: true }]
    },
    {
      name: ['entity'],
      rules: [{ required: true }],
      input: <MultiSelectEntity dbKey="entity" />
    },
    {
      label: 'contribution_frequency',
      name: ['contribution_frequency'],
      rules: [{ required: true }],
      input: (
        <GenericSelectEnums
          dbKey={['contribution_frequency']}
          enums={enums.contributionFrequency}
          tradKeyEnums="documents.enums"
        />
      )
    },
    {
      name: ['validity_start_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          format="DD/MM/YYYY"
          style={{ width: '100%' }}
          onChange={handleDateChange('validity_start_date')}
        />
      )
    },
    {
      name: ['validity_end_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          format="DD/MM/YYYY"
          style={{ width: '100%' }}
          minDate={dayjs(currentDates.validity_start_date, 'DD/MM/YYYY')}
          disabled={!currentDates.validity_start_date}
        />
      )
    },
    {
      name: ['subscriber_last_name_first_name'],
      rules: [{ required: true }]
    },
    {
      label: 'number',
      name: ['number'],
      rules: [{ required: true }]
    },
    {
      label: 'street',
      name: ['street'],
      rules: [{ required: true }]
    },
    {
      label: 'additional_address',
      name: ['additional_address']
    },
    {
      label: 'postal_code',
      name: ['postal_code'],
      rules: [{ required: true }]
    },
    {
      label: 'city',
      name: ['city'],
      rules: [{ required: true }]
    },
    {
      label: 'programmes_types',
      input: (
        <Input
          placeholder={t(`subscriptions.form.${programmeType}`)}
          disabled
        />
      )
    },
    {
      label: 'civil_liability_sub_programmes',
      rules: [{ required: false }],
      hidden: programmeType !== 'CIVIL_LIABILITY',
      input: (
        <Input
          placeholder={t(`subscriptions.form.${subProgrammeType}`)}
          disabled
        />
      )
    },
    {
      label: 'transport_sub_programmes',
      rules: [{ required: false }],
      hidden: programmeType !== 'TRANSPORT',
      input: (
        <Input
          placeholder={t(`subscriptions.form.${subProgrammeType}`)}
          disabled
        />
      )
    },
    {
      label: 'construction_sub_programmes',
      name: ['construction_sub_programmes'],
      hidden: programmeType !== 'CONSTRUCTION',
      initialvalue: subProgrammeType,
      input: (
        <Input
          placeholder={t(`subscriptions.form.${subProgrammeType}`)}
          disabled
        />
      )
    },
    {
      name: ['contract'],
      rules: [{ required: !isMandatory }],
      hidden: isMandatory,
      input: 'File',
      maxFilesCount: 1,
      multipleFiles: false
    }
  ];
  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/contracts/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};
