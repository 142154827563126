import { Route, Routes } from 'react-router-dom';
import { ListAssemblyTestingClaims } from './ListAssemblyTestingClaims';
import { AssemblyTestingClaimsCreateUpdate } from './AssemblyTestingClaimsCreateUpdate';
import { AssemblyTestingClaimsShow } from './AssemblyTestingClaimsShow';
import { Exception } from '../../../components';

/**
 * Provides routing for managing hardware insurances, including create, edit, show, and list views.
 *
 * @component
 * @returns {React.ReactNode} Routes for different views related to hardware insurances.
 */
export const AssemblyTestingClaimsRouter = () => (
  <Routes>
    <Route
      path="create/:id"
      element={<AssemblyTestingClaimsCreateUpdate purpose="create" />}
    />
    <Route
      path="edit/:id"
      element={<AssemblyTestingClaimsCreateUpdate purpose="edit" />}
    />
    <Route path="show/:id" element={<AssemblyTestingClaimsShow />} />
    <Route index element={<ListAssemblyTestingClaims />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
