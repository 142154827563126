import {
  CivilTile,
  ConstructionTile,
  FreightTile,
  GolfTile,
  RealestateTile,
  VehicleTile,
  MachineryBreakageTile,
  HoleInOneTile,
  EventCancellationTile,
  CyberInsuranceTile,
  HardwareInsuranceTile,
  ProfessionalMultiriskInsuranceTile
} from '../../../utils/constants/customIcons';

export const getClaimIcon = (programmeType) => {
  switch (programmeType) {
    case 'FLEET':
      return <VehicleTile />;
    case 'CIVIL_LIABILITY':
      return <CivilTile />;
    case 'GOLF':
      return <GolfTile />;
    case 'TRANSPORT':
      return <FreightTile />;
    case 'PROPERTY':
      return <RealestateTile />;
    case 'CONSTRUCTION':
      return <ConstructionTile />;
    case 'MACHINERY_BREAKAGE':
      return <MachineryBreakageTile />;
    case 'HOLE_IN_ONE':
      return <HoleInOneTile />;
    case 'EVENT_CANCELLATION':
      return <EventCancellationTile />;
    case 'CYBER_INSURANCE':
      return <CyberInsuranceTile />;
    case 'HARDWARE_INSURANCE':
      return <HardwareInsuranceTile />;
    case 'PROFESSIONAL_MULTIRISK_INSURANCE':
      return <ProfessionalMultiriskInsuranceTile />;
    default:
      return <CivilTile />;
  }
};
