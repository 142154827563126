import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { Tooltip } from 'antd';
import { FormOutlined, MailOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { MailingBlank } from '../Mailing/Modals/MailingBlank';
import { useErrorMessage } from '../../utils/errorMessage';

const iconSize = 18;

export const MailingButton = ({
  assetId,
  templateName,
  tradKey,
  resource,
  baseUrl
}) => {
  const [isModalMailingOpen, setIsModalMailingOpen] = useState(false);
  const [templateMail, setTemplateMail] = useState();
  const [asset, setAsset] = useState();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();

  const getTemplateMail = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/templates-mails/name/${templateName}?populate=documents,documents.file`
      });
      setTemplateMail(data);
    } catch (e) {
      message(e);
    }
  }, []);

  useEffect(() => {
    if (!isModalMailingOpen) return;
    (async () => {
      await getTemplateMail();
    })();
  }, [getTemplateMail, isModalMailingOpen]);

  const getVehicle = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${baseUrl}/${assetId}?populate=customer_manager,unit_manager,entity,site,contract,company_Id`
      });
      setAsset(data);
    } catch (e) {
      message(e);
    }
  }, [assetId]);

  const closeModal = () => {
    setIsModalMailingOpen(false);
  };

  useEffect(() => {
    if (!isModalMailingOpen) return;
    if (assetId) {
      (async () => {
        await getVehicle();
      })();
    }
  }, [assetId, isModalMailingOpen]);

  useEffect(() => {
    if (!isModalMailingOpen) return;
    (async () => {
      await getVehicle();
    })();
  }, [assetId, isModalMailingOpen]);

  return (
    <>
      {tradKey &&
        (templateName === 'InsuranceAttestation' ? (
          <Tooltip title={t('property.actions.send_email')}>
            <MailOutlined
              style={{ fontSize: iconSize }}
              onClick={() => {
                setIsModalMailingOpen(true);
              }}
            />
          </Tooltip>
        ) : (
          <Tooltip title={t('light_vehicle.actions.specific_demand')}>
            <FormOutlined
              style={{ fontSize: iconSize }}
              onClick={() => {
                setIsModalMailingOpen(true);
              }}
            />
          </Tooltip>
        ))}

      <MailingBlank
        isModalMailingOpen={isModalMailingOpen}
        setIsModalMailingOpen={setIsModalMailingOpen}
        idWithoutParams={asset?._id}
        templateMail={templateMail}
        datas={asset}
        resource={resource}
        baseUrl={baseUrl}
        templateName={tradKey}
        isModal
        closeModal={closeModal}
      />
    </>
  );
};

MailingButton.propTypes = {
  assetId: PropTypes.string.isRequired,
  templateName: PropTypes.string.isRequired,
  tradKey: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired
};
