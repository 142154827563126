import { useCallback, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Button, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditOutlined, DownloadOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useListContent } from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { routes } from '../../utils/constants/adminRoutes';
import { useDownloadDocument } from '../../utils/downloadDoc';
import { ButtonMailActionColumnDocuments } from '../components/ButtonMailActionColumnDocuments';

/**
 * Represents the `ShowDocument` component that displays details of a document.
 *
 * Fetches and displays a document's details based on its ID, which is derived from the current route parameters.
 * The component also provides action buttons for editing, regenerating, downloading, and sending the document.
 *
 * @component
 * @example
 * ```jsx
 * <ShowDocument />
 * ```
 */

export const ShowDocument = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { downloadDocument } = useDownloadDocument();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [documentItem, setDocumentItem] = useState();
  const [isAmendment, setIsAmendment] = useState(false);
  const listContent = useListContent(documentItem);

  const getDocument = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/documents/${id}?populate=entity,company_Id,file`
      });
      setIsAmendment(data?.document_type === 'AMENDMENT');
      setDocumentItem(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getDocument();
    })();
  }, [getDocument]);

  return (
    <>
      <PageHeaderCustom
        title={t('documents.show.title')}
        extra={
          <>
            {!isAmendment ? (
              <Link to={{ pathname: `${routes.DOCUMENTS}/edit/${id}` }}>
                <Button>
                  <EditOutlined />
                  {`${t('buttons.edit')} `}
                </Button>
              </Link>
            ) : (
              <Link
                to={{ pathname: `${routes.DOCUMENTS}/edit-amendments/${id}` }}
              >
                <Button>
                  <EditOutlined />
                  {`${t('buttons.edit')} `}
                </Button>
              </Link>
            )}
            <Button
              onClick={() => {
                downloadDocument(documentItem?.file);
              }}
            >
              <DownloadOutlined />
              {`${t('buttons.download')} `}
            </Button>
            <ButtonMailActionColumnDocuments
              assetId={id}
              tradKey="specific_demand"
              baseUrl="documents"
              file={documentItem?.file}
              isButton
            />
          </>
        }
      />
      <ContentCustom>
        <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
          <DescriptionList
            data={listContent}
            translate
            layout="vertical"
            column={4}
          />
        </Skeleton>
      </ContentCustom>
    </>
  );
};
