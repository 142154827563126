import { DatePicker } from 'antd';
import { MultiSelectEntity } from '../../../components/MultiSelectEntity';

/**
 * Generate fields configuration for a form.
 *
 * @function
 * @returns {Object} An object containing the fields configuration.
 */
export const useFields = () => {
  const dateFormat = 'DD-MM-YYYY';

  const fieldsInformations = [
    {
      label: ['entity'],
      name: ['entity'],
      rules: [{ required: true }],
      disabled: true,
      input: <MultiSelectEntity dbKey="entity" />
    },
    {
      label: ['validity_start_date'],
      name: ['validity_start_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['validity_end_date'],
      name: ['validity_end_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['subscriber_last_name_first_name'],
      name: ['subscriber_last_name_first_name'],
      rules: [{ required: true }]
    },
    {
      label: ['number'],
      name: ['number'],
      rules: [{ required: true }]
    },
    {
      label: ['street'],
      name: ['street'],
      rules: [{ required: true }]
    },
    {
      label: ['additional_address'],
      name: ['additional_address']
    },
    {
      label: ['postal_code'],
      name: ['postal_code'],
      rules: [{ required: true }]
    },
    {
      label: ['city'],
      name: ['city'],
      rules: [{ required: true }]
    }
  ];

  return {
    fieldsInformations
  };
};
