import PropTypes from 'prop-types';
import { Typography, Flex } from 'antd';
import { UserSimulation } from '@carbon/icons-react';

/**
 * @component
 * @param {Object} props - The props object.
 * @param {string} props.title - The title of the notification.
 * @returns {JSX.Element} JSX element representing the notification content for the subscribed programme.
 */

export const CreateNotifSubscribedProgramme = ({ title }) => (
  <Flex justify="space-between" align="center" wrap="wrap" gap="small">
    <Flex align="center" gap="small">
      <UserSimulation />
      <Typography.Text strong style={{ marginRight: 4 }}>
        {title}
      </Typography.Text>
    </Flex>
  </Flex>
);

CreateNotifSubscribedProgramme.propTypes = {
  title: PropTypes.string.isRequired
};
