import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Button } from 'antd';
import { EditOutlined, SyncOutlined } from '@ant-design/icons';
import { routes } from '../../../utils/constants/adminRoutes';
import { MailingButton } from '../../Mailing/MailingButton';
import { ClaimButton } from '../../components/ClaimButton';
import { PropertyContext } from './PropertyContext';
import usePopConfirm from '../utils/popConfirms';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useHistory } from '../../../utils/historyUtils';

/**
 * Custom hook that returns extra buttons for a property.
 * @hook
 * @param {boolean} edit - Indicates whether the property is editable.
 * @param {boolean} isDeleted - Indicates whether the property is deleted.
 * @param {object} property - The property object.
 * @returns {object} - An object containing the extra buttons.
 */

export const useExtraButtons = (edit, isDeleted, property, exitDocuments) => {
  const { id } = useParams();
  const APIurl = 'properties';
  const { generateAttestation } = useContext(PropertyContext);
  const { setHistoryRefresh } = useAuthContext();
  const { updateHistoryCustom } = useHistory();
  const { exitProgramWithFile, unarchiveProgram } = usePopConfirm(APIurl);
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const iconSize = 18;

  const handleGenerateAttestation = async (PropertyID) => {
    await generateAttestation(PropertyID);
    updateHistoryCustom('properties', id, t('buttons.attestation_downloaded'));
    setHistoryRefresh((prev) => !prev);
  };

  const extraButtons = (
    <>
      {edit && !isDeleted && (
        <Link
          to={{
            pathname: `${routes.PROGRAMMES}/properties/edit/${id}`
          }}
        >
          <Button type="primary">
            {`${t('buttons.edit')} `}
            <EditOutlined />
          </Button>
        </Link>
      )}
      {property?.contract?.insurance_company === 'AXA' && !isDeleted && (
        <Button onClick={() => handleGenerateAttestation(property?._id)}>
          {`${t('buttons.generate_attestation')} `}
          <SyncOutlined style={{ fontSize: iconSize }} />
        </Button>
      )}
      {!isDeleted && (
        <>
          <MailingButton
            asset={property}
            templateName="InsuranceAttestation"
            tradKey="sending_attestation_property"
            resource="Property"
            baseUrl={APIurl}
          />
          <ClaimButton id={id} url={APIurl} claimType="property" />
          <MailingButton
            asset={property}
            templateName="Blank"
            tradKey="specific_demand"
            resource="Property"
            baseUrl={APIurl}
          />
        </>
      )}
      {property &&
        !property.deleted &&
        exitProgramWithFile({
          visible,
          setVisible,
          exitDocuments
        })}
      {property && property.deleted && unarchiveProgram()}
    </>
  );

  return { extraButtons };
};
