import { Route, Routes } from 'react-router-dom';
import { AssemblyTestingInsuranceShow } from './AssemblyTestingInsuranceShow';
import { ListAssemblyTestingInsurance } from './ListAssemblyTestingInsurance';
import { AssemblyTestingInsuranceCreateUpdate } from './AssemblyTestingInsuranceCreateUpdate';
import { Exception } from '../../../components';

/**
 * A router component for handling hardware insurance related routes.
 *
 * @component
 * @returns {JSX.Element} The JSX element representing the AssemblyTestingInsuranceRouter.
 */

export const AssemblyTestingInsuranceRouter = () => (
  <Routes>
    <Route
      path="edit/:id"
      element={<AssemblyTestingInsuranceCreateUpdate purpose="edit" />}
    />
    <Route path="show/:id" element={<AssemblyTestingInsuranceShow />} />
    <Route index element={<ListAssemblyTestingInsurance />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
