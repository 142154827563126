import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, Spin, Flex, Card, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import { DescriptionList } from '../../../../components';
import TreeStructure from './TreeStructure';
import CreateUpdateTreeModal from './CreateUpdateTreeModal';
import { handleSearch } from '../utils/searchUtils';
import { useAuthContext } from '../../../../contexts/AuthContext';

const { Search } = Input;

/**
 * Component to display company information and associated entities.
 * @component
 * @param {boolean} isLoading - Indicates if data is being loaded.
 * @param {Array} listContent - Array of objects containing label, span, and content properties.
 * @returns {JSX.Element} CompanyAndEntitiesData component.
 */

const Organization = ({ isLoading, listContent }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [create, setCreate] = useState(true);
  const [filters, setFilters] = useState(true);

  const { permissions, verifPermissions } = useAuthContext();
  const { t } = useTranslation();
  const [keySearched, setKeySearched] = useState('');

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setCreate(
        !verifPermissions('/sites/create', permissions?.notPermittedRoutes)
      );
      setFilters(
        !verifPermissions('/sites/filters', permissions?.notPermittedRoutes)
      );
    }
  }, [permissions]);

  return (
    <Spin spinning={isSubmitting}>
      <CreateUpdateTreeModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setIsSubmitting={setIsSubmitting}
      />
      <Flex vertical>
        <Card title={t('companies.form.company_informations')}>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <DescriptionList data={listContent} layout="vertical" translate />
          </Skeleton>
        </Card>
        <Card title={t(`companies.form.organization`)}>
          <Flex justify="space-between" align="center">
            {filters && (
              <Search
                allowClear
                placeholder={t('placeholder.search')}
                onSearch={(value) => handleSearch(value, setKeySearched)}
                style={{ width: 320 }}
              />
            )}
            {create && (
              <Button
                type="primary"
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                {t('companies.form.create_site')}
              </Button>
            )}
          </Flex>
          <TreeStructure keySearched={keySearched} />
        </Card>
      </Flex>
    </Spin>
  );
};

export default Organization;

Organization.propTypes = {
  listContent: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool
};

Organization.defaultProps = {
  isLoading: false
};
