import { useEffect } from 'react';
import { Tag } from 'antd';
import { FileExclamationOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { claimStatus } from '../../../../utils/constants/tagColors';
import { useFiltersDataFetch } from '../../../../components/ColumnsDynamicFilters/useFiltersDatasFetch';

/**
 * `useColumns` is a custom React hook that provides a list of column configurations.
 * The column titles are fetched using the `react-i18next` hook for internationalization.
 * @hook
 * @returns {Array<object>} Returns an array of column configurations to be consumed by a table component.
 */

export const useColumns = (
  resourceName,
  dbExtraQueryFilter,
  targetType,
  populate
) => {
  const { t } = useTranslation();
  const { fetchFilters, filters, loading } = useFiltersDataFetch(
    resourceName,
    dbExtraQueryFilter,
    targetType,
    populate
  );

  useEffect(() => {
    fetchFilters();
  }, []);

  const columns = [
    {
      key: 'documents',
      width: '48px',
      dataIndex: 'missing_documents',
      render: (missing_documents) =>
        missing_documents ? (
          <FileExclamationOutlined
            style={{ fontSize: 18, color: 'var(--warningColor)' }}
          />
        ) : null
    },
    {
      title: t('claims.form.entity'),
      key: 'entity',
      dataIndex: ['entity'],
      filters: filters?.entity?.map((entity) => ({
        text: entity.name,
        value: entity._id
      })),
      sorter: true,
      render: (entity) => entity && entity.map((e) => e.name).join(', ')
    },
    {
      title: t('claims.form.site'),
      key: 'site',
      dataIndex: ['site', 'name'],
      filters: filters?.site?.map((site) => ({
        text: site.name,
        value: site._id
      })),
      sorter: true
    },
    {
      title: t('claims.form.claim_status'),
      key: 'claim_status',
      dataIndex: 'claim_status',
      filters: filters?.claim_status?.map((status) => ({
        text: t(`claims.form.${status}`),
        value: status
      })),
      render: (status) =>
        status ? (
          <Tag color={claimStatus[status]}>
            {t(`claims.form.${status}`, {
              defaultValue: status
            })}
          </Tag>
        ) : (
          <Tag color="orange">{t('claims.form.reception_pending')}</Tag>
        ),
      sorter: true
    },
    {
      title: t('claims.form.claim_type'),
      key: 'claim_type',
      dataIndex: 'claim_type',
      sorter: true,
      filters: filters?.claim_type?.map((type) => ({
        text: t(`claims.form.${type}`),
        value: type
      })),
      render: (type) => t(`claims.form.${type}`)
    },
    {
      title: t('claims.form.sinister_date'),
      key: 'sinister_date',
      dataIndex: ['sinister_date'],
      filters: filters?.sinister_date?.map((date) => ({
        text: moment(date).format('DD/MM/YYYY'),
        value: date
      })),
      render: (occurence_date) =>
        occurence_date ? moment(occurence_date).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('claims.form.insurance_company_reference'),
      key: 'insurance_company_reference',
      dataIndex: 'insurance_company_reference',
      sorter: true,
      filters: filters?.insurance_company_reference?.map((ref) => ({
        text: ref,
        value: ref
      }))
    },
    {
      title: t('claims.form.opening_date'),
      key: 'opening_date',
      dataIndex: ['opening_date'],
      filters: filters?.opening_date?.map((date) => ({
        text: moment(date).format('DD/MM/YYYY'),
        value: date
      })),
      render: (opening_date) =>
        opening_date ? moment(opening_date).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('claims.form.commentaries'),
      key: 'commentaries',
      dataIndex: ['commentaries'],
      filters: filters?.commentaries?.map((commentaries) => ({
        text: commentaries,
        value: commentaries
      })),
      render: (commentaries) => commentaries || '-'
    }
  ];

  return [columns, loading];
};
