import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback, useRef } from 'react';
import { FileExclamationOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useFiltersDataFetch } from '../../../../components/ColumnsDynamicFilters/useFiltersDatasFetch';

/**
 * `useColumns` is a custom React hook that provides a list of column configurations.
 *
 * The column titles are fetched using the `react-i18next` hook for internationalization.
 *
 * @hook
 * @returns {Array<object>} Returns an array of column configurations to be consumed by a table component.
 */

export const useColumns = (resourceName, dbExtraQueryFilter, populate) => {
  const { t } = useTranslation();
  const { dispatchAPI, company: companyId } = useAuthContext();
  const { message } = useErrorMessage();
  const { fetchFilters, filters, loading } = useFiltersDataFetch(
    resourceName,
    dbExtraQueryFilter,
    resourceName,
    populate
  );
  const [config, setConfig] = useState({});
  const isMounted = useRef(true);

  const allColumns = [
    {
      title: t('machinery_breakages.form.missing_documents'),
      key: 'missing_documents',
      dataIndex: ['missing_documents'],
      sorter: true,
      width: 150,
      render: (missing_documents) =>
        missing_documents ? (
          <FileExclamationOutlined
            style={{ fontSize: 18, color: 'var(--warningColor)' }}
          />
        ) : (
          '-'
        )
    },
    {
      title: t('machinery_breakages.form.company_name'),
      key: 'company_name',
      dataIndex: ['company', 'name'],
      sorter: true
    },
    {
      title: t('machinery_breakages.form.contract_number'),
      key: 'contract_number',
      dataIndex: ['contract', 'contract_number'],
      sorter: true
    },
    {
      title: t('machinery_breakages.form.date_added'),
      key: 'date_added',
      dataIndex: ['created_at'],
      render: (created_at) =>
        created_at ? dayjs(created_at).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('machinery_breakages.form.entity'),
      key: 'entity',
      dataIndex: ['entity', 'name'],
      filters: filters?.entity?.map((entity) => ({
        text: entity.name,
        value: entity._id
      })),
      sorter: true
    },
    {
      title: t('machinery_breakages.form.site'),
      key: 'site',
      dataIndex: ['site', 'name'],
      filters: filters?.site?.map((site) => ({
        text: site.name,
        value: site._id
      })),
      sorter: true
    },
    {
      title: t('machinery_breakages.form.machinery_location_address'),
      key: 'machinery_location_address',
      dataIndex: ['machinery_location_address'],
      sorter: true
    },
    {
      title: t('machinery_breakages.form.postal_code'),
      key: 'postal_code',
      dataIndex: ['postal_code'],
      filters: filters?.postal_code?.map((postal_code) => ({
        text: postal_code,
        value: postal_code
      })),
      sorter: true
    },
    {
      title: t('machinery_breakages.form.city'),
      key: 'city',
      dataIndex: ['city'],
      filters: filters?.city?.map((city) => ({
        text: city,
        value: city
      })),
      sorter: true
    },
    {
      title: t('machinery_breakages.form.entry_date'),
      key: 'entry_date',
      dataIndex: ['created_at'],
      filters: filters?.entry_date?.map((entry_date) => ({
        text: dayjs(entry_date).format('DD/MM/YYYY'),
        value: entry_date
      })),
      render: (entry_date) =>
        entry_date ? dayjs(entry_date).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('machinery_breakages.form.removal_date'),
      key: 'removal_date',
      dataIndex: ['created_at'],
      filters: filters?.removal_date?.map((removal_date) => ({
        text: dayjs(removal_date).format('DD/MM/YYYY'),
        value: removal_date
      })),
      render: (removal_date) =>
        removal_date ? dayjs(removal_date).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('machinery_breakages.form.machinery_description'),
      key: 'machinery_description',
      dataIndex: ['machinery_description'],
      filters: filters?.machinery_description?.map((machinery_description) => ({
        text: machinery_description,
        value: machinery_description
      })),
      sorter: true
    },
    {
      title: t('machinery_breakages.form.value_new_tax_excl'),
      key: 'value_new_tax_excl',
      dataIndex: ['value_new_tax_excl'],
      filters: filters?.value_new_tax_excl?.map((value_new_tax_excl) => ({
        text: value_new_tax_excl,
        value: value_new_tax_excl
      })),
      sorter: true
    },
    {
      title: t('machinery_breakages.form.machinery_serial_number'),
      key: 'machinery_serial_number',
      dataIndex: 'machinery_serial_number',
      filters: filters?.machinery_serial_number?.map(
        (machinery_serial_number) => ({
          text: machinery_serial_number,
          value: machinery_serial_number
        })
      ),
      sorter: true
    },
    {
      title: t('machinery_breakages.form.machinery_brand'),
      key: 'machinery_brand',
      dataIndex: ['machinery_brand'],
      filters: filters?.machinery_brand?.map((machinery_brand) => ({
        text: machinery_brand,
        value: machinery_brand
      })),
      sorter: true
    },
    {
      title: t('machinery_breakages.form.machinery_model'),
      key: 'machinery_model',
      dataIndex: 'machinery_model',
      filters: filters?.machinery_model?.map((machinery_model) => ({
        text: machinery_model,
        value: machinery_model
      })),
      sorter: true
    },
    {
      title: t('machinery_breakages.form.initial_commissioning_date'),
      key: 'initial_commissioning_date',
      dataIndex: ['created_at'],
      filters: filters?.initial_commissioning_date?.map(
        (initial_commissioning_date) => ({
          text: dayjs(initial_commissioning_date).format('DD/MM/YYYY'),
          value: initial_commissioning_date
        })
      ),
      render: (initial_commissioning_date) =>
        initial_commissioning_date
          ? dayjs(initial_commissioning_date).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t('machinery_breakages.form.status'),
      key: 'status',
      dataIndex: 'status',
      filters: filters?.status?.map((status) => ({
        text: status,
        value: status
      })),
      sorter: true,
      render: (status) =>
        (status && t(`machinery_breakages.form.${status}`)) || '-'
    },
    {
      title: t('machinery_breakages.form.periodic_revision'),
      key: 'periodic_revision',
      dataIndex: 'periodic_revision',
      filters: filters?.periodic_revision?.map((periodic_revision) => ({
        text: periodic_revision,
        value: periodic_revision
      })),
      sorter: true,
      render: (periodic_revision) =>
        (periodic_revision &&
          t(`machinery_breakages.form.${periodic_revision}`)) ||
        '-'
    },
    {
      title: t('machinery_breakages.form.periodic_revision_frequency'),
      key: 'periodic_revision_frequency',
      dataIndex: 'periodic_revision_frequency',
      filters: filters?.periodic_revision_frequency?.map(
        (periodic_revision_frequency) => ({
          text: periodic_revision_frequency,
          value: periodic_revision_frequency
        })
      ),
      sorter: true,
      render: (periodic_revision_frequency) =>
        (periodic_revision_frequency &&
          t(`machinery_breakages.form.${periodic_revision_frequency}`)) ||
        '-'
    }
  ];

  const getConfig = useCallback(async () => {
    if (!isMounted.current) return;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/programme-configs/${companyId}/machinery-breakage-configs`
      });
      setConfig(data[0]);
    } catch (e) {
      if (isMounted.current) message('missing_parameters');
    }
  }, [dispatchAPI, message]);

  useEffect(() => {
    getConfig();
    fetchFilters();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const filteredColumns = allColumns.filter((column) => config[column.key]);

  return [filteredColumns, loading] || [];
};
