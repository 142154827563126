import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { useFiltersDataFetch } from '../../../../../components/ColumnsDynamicFilters/useFiltersDatasFetch';

/**
 * Custom hook that returns an array of filtered columns and a loading flag.
 * @hook
 * @param {string} resourceName - The name of the resource.
 * @param {object} dbExtraQueryFilter - Additional query filters for the database.
 * @param {boolean} populate - Flag indicating whether to populate the data.
 * @returns {Array} An array containing the filtered columns and a loading flag.
 */
export const useColumns = (resourceName, dbExtraQueryFilter, populate) => {
  const { t } = useTranslation();
  const { dispatchAPI, company: companyId } = useAuthContext();
  const isMounted = useRef(true);
  const { message } = useErrorMessage();
  const [config, setConfig] = useState({});

  const { fetchFilters, filters, loading } = useFiltersDataFetch(
    resourceName,
    dbExtraQueryFilter,
    resourceName,
    populate
  );

  useEffect(() => {
    fetchFilters();
  }, []);

  const allColumns = [
    {
      title: t('individual_houses_builder.columns.company_name'),
      key: 'company_name',
      dataIndex: ['company_Id', 'name'],
      sorter: true
    },
    {
      title: t('individual_houses_builder.columns.contract_number'),
      key: 'contract_number',
      dataIndex: ['contract', 'contract_number'],
      sorter: true
    },
    {
      title: t('individual_houses_builder.columns.date_added'),
      key: 'date_added',
      dataIndex: ['created_at'],
      render: (created_at) =>
        created_at ? dayjs(created_at).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('individual_houses_builder.columns.entity'),
      key: 'entity',
      dataIndex: ['entity'],
      filters: (filters?.entity || []).map((entity) => ({
        text: entity.name,
        value: entity._id
      })),
      sorter: true,
      render: (entity) => (entity ? entity.map((e) => e.name).join(', ') : '-')
    },
    {
      title: t('individual_houses_builder.columns.site'),
      key: 'site',
      dataIndex: ['site'],
      filters: (filters?.site || []).map((site) => ({
        text: site.name,
        value: site._id
      })),
      sorter: true
    },
    {
      title: t('individual_houses_builder.columns.construction_start_date'),
      key: 'construction_start_date',
      dataIndex: ['construction_start_date'],
      render: (construction_start_date) =>
        construction_start_date
          ? dayjs(construction_start_date).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t(
        'individual_houses_builder.columns.expected_construction_end_date'
      ),
      key: 'expected_construction_end_date',
      dataIndex: ['expected_construction_end_date'],
      render: (expected_construction_end_date) =>
        expected_construction_end_date
          ? dayjs(expected_construction_end_date).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t('individual_houses_builder.columns.subscriber_name'),
      key: 'subscriber_name',
      dataIndex: ['subscriber_name'],
      sorter: true
    }
  ];

  const getConfig = useCallback(async () => {
    if (!isMounted.current) return;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/programme-configs/${companyId}/individual-houses-builder-configs`
      });
      setConfig(data[0]);
    } catch (e) {
      if (isMounted.current) message('missing_parameters');
    }
  }, [dispatchAPI, message]);

  useEffect(() => {
    getConfig();
    fetchFilters();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const filteredColumns = allColumns.filter((column) => config[column.key]);

  return [filteredColumns, loading] || [];
};
