import { DatePicker, Input } from 'antd';
import { SelectManager } from '../../../components/SelectManager';
import { MultiSelectEntity } from '../../../components/MultiSelectEntity';
import { SelectSite } from '../../../components/SelectSite';
import { SelectYesNo } from '../../../components/SelectYesNo';

/**
 * Generate fields configuration for a form.
 *
 * @function
 * @returns {Object} An object containing the fields configuration.
 */
export const useFields = () => {
  const dateFormat = 'DD-MM-YYYY';

  const fieldsInformations = [
    {
      label: ['customer_manager'],
      name: ['customer_manager'],
      rules: [{ required: true }],
      input: <SelectManager dbKey="customer_manager" />
    },
    {
      label: ['unit_manager'],
      name: ['unit_manager'],
      input: <SelectManager dbKey="unit_manager" />
    },
    {
      label: ['entity'],
      name: ['entity'],
      rules: [{ required: true }],
      input: <MultiSelectEntity dbKey="entity" />
    },
    {
      label: ['site'],
      name: ['site'],
      input: <SelectSite dbKey="site" />
    },
    {
      label: ['desired_effective_date'],
      name: ['desired_effective_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['address'],
      name: ['address'],
      rules: [{ required: true }]
    },
    {
      label: ['postal_code'],
      name: ['postal_code'],
      rules: [{ required: true }]
    },
    {
      label: ['city'],
      name: ['city'],
      rules: [{ required: true }]
    },
    {
      label: ['global_tax_excl_hardware_value'],
      name: ['global_tax_excl_hardware_value'],
      rules: [{ required: true }],
      input: <Input type="Number" addonAfter="€" />
    },
    {
      label: ['laptops_tax_excl_value'],
      name: ['laptops_tax_excl_value'],
      rules: [{ required: true }],
      input: <Input type="Number" addonAfter="€" />
    },
    {
      label: ['is_claim_last_two_years'],
      name: ['is_claim_last_two_years'],
      rules: [{ required: true }],
      input: <SelectYesNo dbKey={['is_claim_last_two_years']} />
    },
    {
      label: ['is_data_duplication_guarantee'],
      name: ['is_data_duplication_guarantee'],
      rules: [{ required: true }],
      input: <SelectYesNo dbKey={['is_data_duplication_guarantee']} />
    },
    {
      label: ['is_operating_cost_loss_guarantee'],
      name: ['is_operating_cost_loss_guarantee'],
      rules: [{ required: true }],
      input: <SelectYesNo dbKey={['is_operating_cost_loss_guarantee']} />
    }
  ];

  return {
    fieldsInformations
  };
};
