import React, { useState, createContext, useEffect } from 'react';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

export const LightVehiclesContext = createContext();

/**
 * A provider component for theLightVehiclesContext.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {React.Node} props.children - The child components to be wrapped by the context.
 * @returns {React.Element} TheLightVehiclesContextProvider component.
 */

export const LightVehiclesContextProvider = ({ children }) => {
  const [currentContract, setCurrentContract] = useState();
  const [currentEntityContext, setCurrentEntityContext] = useState();
  const [entitiesContext, setEntitiesContext] = useState([]);

  const [contractId, setContractId] = useState('');
  const [forceRefresh, setForceRefresh] = useState(false);
  const [contracts, setContracts] = useState([]);
  const { dispatchAPI, company } = useAuthContext();
  const { message } = useErrorMessage();

  const getcontractId = async () => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: `/contracts/number/companies/${company}`,
        body: {
          programme_types: 'FLEET',
          sub_programme: 'LIGHT_VEHICLE'
        }
      });
      setContractId(data[0]._id);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getcontractId();
    })();
  }, []);

  return (
    <LightVehiclesContext.Provider
      value={{
        forceRefresh,
        setForceRefresh,
        contracts,
        setContracts,
        contractId,
        currentEntityContext,
        setCurrentEntityContext,
        entitiesContext,
        setEntitiesContext,
        currentContract,
        setCurrentContract
      }}
    >
      {children}
    </LightVehiclesContext.Provider>
  );
};
