import { useTranslation } from 'react-i18next';

export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t(`claims.form.amounts`),
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => amount?.toFixed(2) || '-'
    },
    {
      title: t(`claims.form.amount_or_percentage`),
      dataIndex: 'amount_or_percentage',
      key: 'amount_or_percentage',
      render: (amount_or_percentage) =>
        amount_or_percentage ? t(`claims.form.${amount_or_percentage}`) : '-'
    },
    {
      title: t(`claims.form.wording`),
      dataIndex: 'wording',
      key: 'wording'
    }
  ];
};
