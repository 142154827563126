// TODO: awaiting customer feedback before commented code can be removed
import dayjs from 'dayjs';
// import { useTranslation } from 'react-i18next';
import { renderEntitiesShow } from '../../../../utils/formatters/entities';

/**
 * A custom hook that generates a list of content for displaying subscription details.
 * @hook
 * @param {object} data - The subscription data to generate content for.
 * @returns {Array} An array of objects containing label, span, and content for each detail.
 */
export const useListContentProMultiriskInsurance = (data = {}) => {
  // const { t } = useTranslation();

  const {
    entity,
    // site,
    // site_name,
    // site_address,
    // postal_code,
    // city,
    entry_date
    // total_insured_developed_area,
    // legal_status,
    // typology_of_activities,
    // typology_of_activities_if_other,
    // premicies_turnover,
    // content,
    // is_protected_against_theft,
    // if_is_protected_against_theft_details,
    // is_fire_protection,
    // if_is_fire_protection_details
  } = data;

  return [
    {
      label: 'professional_multirisk_insurances.form.entity',
      span: 1,
      content: renderEntitiesShow(entity)
    },
    // {
    //   label: 'professional_multirisk_insurances.form.site',
    //   span: 1,
    //   content: (site && site) || '-'
    // },
    // {
    //   label: 'professional_multirisk_insurances.form.site_name',
    //   span: 1,
    //   content: (site_name && site_name) || '-'
    // },
    // {
    //   label: 'professional_multirisk_insurances.form.site_address',
    //   span: 1,
    //   content: (site_address && site_address) || '-'
    // },
    // {
    //   label: 'professional_multirisk_insurances.form.postal_code',
    //   span: 1,
    //   content: (postal_code && postal_code) || '-'
    // },
    // {
    //   label: 'professional_multirisk_insurances.form.city',
    //   span: 1,
    //   content: (city && city) || '-'
    // },
    {
      label: 'professional_multirisk_insurances.form.entry_date',
      span: 1,
      content: (entry_date && dayjs(entry_date).format('DD/MM/YYYY')) || '-'
    }
    // {
    //   label:
    //     'professional_multirisk_insurances.form.total_insured_developed_area',
    //   span: 1,
    //   content:
    //     (total_insured_developed_area && total_insured_developed_area) || '-'
    // },
    // {
    //   label: 'professional_multirisk_insurances.form.legal_status',
    //   span: 1,
    //   content: legal_status
    //     ? t(`professional_multirisk_insurances.form.enums.${legal_status}`)
    //     : '-'
    // },
    // {
    //   label: 'professional_multirisk_insurances.form.typology_of_activities',
    //   span: 1,
    //   content: typology_of_activities
    //     ? t(
    //         `professional_multirisk_insurances.form.enums.${typology_of_activities}`
    //       )
    //     : '-'
    // },
    // {
    //   label:
    //     'professional_multirisk_insurances.form.typology_of_activities_if_other',
    //   span: 1,
    //   content:
    //     (typology_of_activities_if_other && typology_of_activities_if_other) ||
    //     '-'
    // },
    // {
    //   label: 'professional_multirisk_insurances.form.premicies_turnover',
    //   span: 1,
    //   content: (premicies_turnover && `${premicies_turnover} €`) || '-'
    // },
    // {
    //   label: 'professional_multirisk_insurances.form.content',
    //   span: 1,
    //   content: (content && content) || '-'
    // },
    // {
    //   label:
    //     'professional_multirisk_insurances.form.is_protected_against_theft',
    //   span: 1,
    //   content: is_protected_against_theft
    //     ? t(
    //         `professional_multirisk_insurances.form.enums.${is_protected_against_theft}`
    //       )
    //     : '-'
    // },
    // {
    //   label:
    //     'professional_multirisk_insurances.form.if_is_protected_against_theft_details',
    //   span: 1,
    //   content:
    //     (if_is_protected_against_theft_details &&
    //       if_is_protected_against_theft_details) ||
    //     '-'
    // },
    // {
    //   label: 'professional_multirisk_insurances.form.is_fire_protection',
    //   span: 1,
    //   content: is_fire_protection
    //     ? t(
    //         `professional_multirisk_insurances.form.enums.${is_fire_protection}`
    //       )
    //     : '-'
    // },
    // {
    //   label:
    //     'professional_multirisk_insurances.form.if_is_fire_protection_details',
    //   span: 1,
    //   content:
    //     (if_is_fire_protection_details && if_is_fire_protection_details) || '-'
    // }
  ];
};
