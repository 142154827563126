/**
 * Generates fields configuration for the claims page.
 *
 * @returns {Object} An object containing the fields configuration.
 */
export const useFieldsAccountingProvision = () => {
  const fieldsAccountingProvision = [
    {
      label: ['accounting_provision'],
      name: ['accounting_provision'],
      rules: [{ required: true }]
    },
    {
      label: ['ageing_coefficient'],
      name: ['ageing_coefficient'],
      rules: [{ required: true }]
    }
  ];

  return {
    fieldsAccountingProvision
  };
};
