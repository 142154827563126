// TODO: Uncomment for customer validation
import { Routes, Route } from 'react-router-dom';
import { Exception } from '../../components';
import { ListSubscriptions } from './ListSubscriptions';
import { CyberInsurancesCreateUpdate } from './cyberInsurances/CyberInsurancesCreateUpdate';
import { CivilLiabilitySubscriptionCreateUpdate } from './civilLiability/CivilLiabilitySubscriptionCreateUpdate';
import { TransportSubscriptionCreateUpdate } from './transport/TransportSubscriptionCreateUpdate';
import { ConstructionSubscriptionCreateUpdate } from './construction/ConstructionSubscriptionCreateUpdate';
import { HardwareInsuranceCreateUpdate } from './hardwareInsurances/HardwareInsuranceCreateUpdate';
import { ProMultiriskInsuranceSubCreateUpdate } from './professionalMultiriskInsurance/ProMultiriskInsuranceSubCreateUpdate';
import { AssemblyTestingSubCreateUpdate } from './assemblyTestingInsurance/AssemblyTestingSubCreateUpdate';
import { EnvironmentCreateUpdate } from './environmentInsurance/EnvironmentCreateUpdate';
import { IndividualHousesBuilderCreateUpdate } from './individualHousesBuilder/IndividualHousesBuilderCreateUpdate';
import { SubscriptionShow } from './SubscriptionShow';
import { SubscriptionContextProvider } from './SubscriptionContext';
import { LegalProtectionSubCreateUpdate } from './legalProtection/LegalProtectionSubCreateUpdate';

/**
 * A router component for subscription-related routes.
 * @component
 * @returns {JSX.Element} The JSX element representing the subscription router.
 */
export const SubscriptionRouter = () => (
  <SubscriptionContextProvider>
    <Routes>
      <Route index element={<ListSubscriptions />} />
      <Route
        path="/individual-houses-builder/create"
        element={<IndividualHousesBuilderCreateUpdate purpose="create" />}
      />
      <Route
        path="/individual-houses-builder/edit/:id"
        element={<IndividualHousesBuilderCreateUpdate purpose="edit" />}
      />
      <Route
        path="/legal-protection-insurance/create"
        element={<LegalProtectionSubCreateUpdate purpose="create" />}
      />
      <Route
        path="/legal-protection-insurance/edit/:id"
        element={<LegalProtectionSubCreateUpdate purpose="edit" />}
      />
      <Route
        path="/environment-insurance/create"
        element={<EnvironmentCreateUpdate purpose="create" />}
      />
      <Route
        path="/environment-insurance/edit/:id"
        element={<EnvironmentCreateUpdate purpose="edit" />}
      />
      <Route
        path="/assembly-testing-insurance/create"
        element={<AssemblyTestingSubCreateUpdate purpose="create" />}
      />
      <Route
        path="/assembly-testing-insurance/edit/:id"
        element={<AssemblyTestingSubCreateUpdate purpose="edit" />}
      />
      <Route
        path="/professional-multirisk-insurance/create"
        element={<ProMultiriskInsuranceSubCreateUpdate purpose="create" />}
      />
      <Route
        path="/professional-multirisk-insurance/edit/:id"
        element={<ProMultiriskInsuranceSubCreateUpdate purpose="edit" />}
      />
      <Route
        path="/hardware-insurance/create"
        element={<HardwareInsuranceCreateUpdate purpose="create" />}
      />
      <Route
        path="/hardware-insurance/edit/:id"
        element={<HardwareInsuranceCreateUpdate purpose="edit" />}
      />
      <Route
        path="/cyber-insurance/create"
        element={<CyberInsurancesCreateUpdate purpose="create" />}
      />
      <Route
        path="/cyber-insurance/edit/:id"
        element={<CyberInsurancesCreateUpdate purpose="edit" />}
      />
      <Route
        path="/civil-liability/pro/create"
        element={
          <CivilLiabilitySubscriptionCreateUpdate
            purpose="create"
            subProgramme="CIVIL_LIABILITY_PRO"
          />
        }
      />
      <Route
        path="/civil-liability/executive/create"
        element={
          <CivilLiabilitySubscriptionCreateUpdate
            purpose="create"
            subProgramme="CIVIL_LIABILITY_EXECUTIVE"
          />
        }
      />
      <Route
        path="/civil-liability/edit/:id"
        element={<CivilLiabilitySubscriptionCreateUpdate purpose="edit" />}
      />
      <Route
        path="/transport/faculty/create"
        element={
          <TransportSubscriptionCreateUpdate
            purpose="create"
            subProgramme="FACULTY"
          />
        }
      />
      <Route
        path="/transport/freighted-goods/create"
        element={
          <TransportSubscriptionCreateUpdate
            purpose="create"
            subProgramme="FREIGHTED_GOODS"
          />
        }
      />
      <Route
        path="/transport/own-accounts/create"
        element={
          <TransportSubscriptionCreateUpdate
            purpose="create"
            subProgramme="OWN_ACCOUNT"
          />
        }
      />
      <Route
        path="/transport/edit/:id"
        element={<TransportSubscriptionCreateUpdate purpose="edit" />}
      />
      <Route
        path="/construction/create"
        element={
          <ConstructionSubscriptionCreateUpdate
            purpose="create"
            subProgramme="OTHER"
          />
        }
      />
      <Route
        path="/construction/edit/:id"
        element={<ConstructionSubscriptionCreateUpdate purpose="edit" />}
      />
      <Route path="show/:id" element={<SubscriptionShow />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </SubscriptionContextProvider>
);
