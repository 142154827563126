import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Flex, Tooltip } from 'antd';
import { EyeOutlined, EditOutlined } from '@ant-design/icons';
import { Flood } from '@carbon/icons-react';
import { MailingButton } from './ButtonMailActionColumn';

const iconSize = 18;

/**
 * `useActionColumn` is a hook that returns a configuration array for rendering an action column.
 * The action column consists of several icons used for viewing a document, editing a document,
 * and other associated actions. Each icon has an associated action, such as navigation or
 * triggering a functionality.
 *
 * @hook
 * @returns {Array} An array containing configuration for the action column. The configuration includes
 * how to render the column based on the record provided.
 */

const formatUrlPart = (str) => str.toLowerCase().replace(/_/g, '-');

const extractSubProgrammes = (record) => {
  const { programme_types } = record.contract || {};
  const programmesParts = [...(programme_types || [])];
  return programmesParts
    .filter((part) => typeof part === 'string')
    .map(formatUrlPart)
    .join('/');
};

export const useActionColumn = (edit, isArchivatedPage, resource, baseUrl) => {
  const { t } = useTranslation();

  return [
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => {
        const programmesString = extractSubProgrammes(record);

        return (
          <Flex gap="small" align="center" justify="end" wrap="nowrap">
            <Link to={`/programmes/${programmesString}s/show/${record?._id}`}>
              <EyeOutlined style={{ fontSize: iconSize }} />
            </Link>
            {edit && (
              <Link to={`/programmes/${programmesString}s/edit/${record?._id}`}>
                <EditOutlined style={{ fontSize: iconSize }} />
              </Link>
            )}
            {!isArchivatedPage && (
              <>
                <Tooltip title={t('machinery_breakages.actions.create_claim')}>
                  <Link to={`/claims/${baseUrl}/create/${record?._id}`}>
                    <Flood size={20} />
                  </Link>
                </Tooltip>
                <Tooltip
                  title={t('machinery_breakages.actions.specific_demand')}
                >
                  <MailingButton
                    assetId={record?._id}
                    templateName="Blank"
                    tradKey="specific_demand"
                    resource={resource}
                    baseUrl={baseUrl}
                  />
                </Tooltip>
              </>
            )}
          </Flex>
        );
      }
    }
  ];
};
