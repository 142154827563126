import { Checkbox } from 'antd';

/**
 * A custom hook that returns predefined field configurations.
 *
 * This configuration can be used in conjunction with form libraries or frameworks
 * that need a set of field definitions to render and validate form controls.
 *
 * @hook
 * @returns {Array<Object>} returns.transportsFields - Field configurations for transports.
 */

export const useTransportsFields = () => {
  const fieldNames = [
    'contract',
    'date_added',
    'entity',
    'site',
    'unit_reference_number',
    'shipper_name',
    'departure_city',
    'parcel_quantity',
    'weight',
    'estimated_delivery_date',
    'recipient_name',
    'arrival_city',
    'means_of_transport',
    'nature_of_the_goods',
    'value_of_the_goods',
    'subscriber_last_name_first_name',
    'subscriber_address',
    'subscriber_postal_code',
    'subscriber_city',
    'deletion_date_effect'
  ];

  const transportsFields = fieldNames.map((name) => ({
    name: [name],
    rules: [{ required: false }],
    valuePropName: 'checked',
    input: <Checkbox />
  }));

  return { transportsFields };
};
