import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useRef } from 'react';
import { Select, Form } from 'antd';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';

const { Option } = Select;
const { Item } = Form;

/**
 * `MultipleSelectSubProgrammesAttestation` is a React component that presents a multi-select dropdown selector, allowing users
 * to choose multiple sub_programme types in a contract
 *
 *
 * @component
 * @param {object} props - The properties passed to the component.
 * @param {string} props.dbKey - The database key corresponding to the selected value(s) for this dropdown.
 * @param {string} props.contractId - The contract ID.
 *
 * @returns {JSX.Element} Returns a rendered multi-select dropdown selector for sub_programme types.
 *
 */

export const MultipleSelectSubProgrammesAttestation = ({
  dbKey,
  contractId
}) => {
  const isMounted = useRef(false);
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [subProgrammes, setSubProgrammes] = useState([]);

  const getSubProgrammes = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contracts/sub-programmes/${contractId}`
      });
      if (isMounted.current) {
        setSubProgrammes(data);
      }
    } catch (e) {
      if (e.response && isMounted.current) message(e);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    getSubProgrammes();
    return () => {
      isMounted.current = false;
    };
  }, [contractId]);

  return (
    <Item name={[`${dbKey}`]}>
      <Select
        mode="multiple"
        showSearch
        filterOption={(input, option) =>
          (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
        }
      >
        {subProgrammes?.map((sub_programme_type) => (
          <Option key={sub_programme_type} value={sub_programme_type}>
            {t(`subscriptions.form.${sub_programme_type}`)}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

MultipleSelectSubProgrammesAttestation.propTypes = {
  dbKey: PropTypes.string,
  contractId: PropTypes.string
};

MultipleSelectSubProgrammesAttestation.defaultProps = {
  dbKey: '',
  contractId: ''
};
