import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { RenderEntity } from '../../../documents/utils/RenderEntity';
import { useFiltersDataFetch } from '../../../../components/ColumnsDynamicFilters/useFiltersDatasFetch';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useAuthContext } from '../../../../contexts/AuthContext';

/**
 * `useColumns` is a custom React hook that provides a list of column configurations.
 *
 * The column titles are fetched using the `react-i18next` hook for internationalization.
 *
 * @hook
 * @returns {Array<object>} Returns an array of column configurations to be consumed by a table component.
 */

export const useColumns = (resourceName, dbExtraQueryFilter, populate) => {
  const { dispatchAPI, company: companyId } = useAuthContext();
  const { message } = useErrorMessage();
  const isMounted = useRef(true);
  const { t } = useTranslation();
  const [config, setConfig] = useState({});
  const { fetchFilters, filters, loading } = useFiltersDataFetch(
    resourceName,
    dbExtraQueryFilter,
    resourceName,
    populate
  );

  useEffect(() => {
    fetchFilters();
  }, []);

  const renderAddress = (text, record) => {
    const { number, street, additional_address } = record || {};
    return number || street || additional_address
      ? `${number || ''} ${street || ''} ${additional_address || ''}`
      : '-';
  };

  const allColumns = [
    {
      title: t('assembly_testing_insurances.form.company_name'),
      key: 'company_name',
      dataIndex: ['company', 'name'],
      sorter: true
    },
    {
      title: t('assembly_testing_insurances.form.contract_number'),
      key: 'contract_number',
      dataIndex: ['contract', 'contract_number'],
      sorter: true
    },
    {
      title: t('assembly_testing_insurances.form.entity'),
      key: 'entity',
      dataIndex: ['entity'],
      filters: (filters?.entity || []).map((entity) => ({
        text: entity.name,
        value: entity._id
      })),
      sorter: true,
      render: (entity) => RenderEntity(entity, t)
    },
    {
      title: t('assembly_testing_insurances.form.validity_start_date'),
      key: 'validity_start_date',
      dataIndex: 'validity_start_date',
      filters: (filters?.validity_start_date || []).map((value) => ({
        text: dayjs(value).format('DD/MM/YYYY'),
        value
      })),
      render: (validity_start_date) =>
        dayjs(validity_start_date).format('DD/MM/YYYY'),
      sorter: true
    },
    {
      title: t('assembly_testing_insurances.form.validity_end_date'),
      key: 'validity_end_date',
      dataIndex: 'validity_end_date',
      filters: (filters?.validity_end_date || []).map((value) => ({
        text: dayjs(value).format('DD/MM/YYYY'),
        value
      })),
      render: (validity_end_date) =>
        dayjs(validity_end_date).format('DD/MM/YYYY'),
      sorter: true
    },
    {
      title: t('assembly_testing_insurances.form.address'),
      key: 'address',
      sorter: true,
      render: renderAddress
    },
    {
      title: t('assembly_testing_insurances.form.postal_code'),
      key: 'postal_code',
      dataIndex: ['postal_code'],
      filters: (filters?.postal_code || []).map((postal_code) => ({
        text: postal_code,
        value: postal_code
      })),
      sorter: true
    },
    {
      title: t('assembly_testing_insurances.form.city'),
      key: 'city',
      dataIndex: ['city'],
      filters: (filters?.city || []).map((city) => ({
        text: city,
        value: city
      })),
      sorter: true
    }
  ];

  const getConfig = useCallback(async () => {
    if (!isMounted.current) return;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/programme-configs/${companyId}/assembly-testing-insurance-configs`
      });
      setConfig(data[0]);
    } catch (e) {
      if (isMounted.current) message('missing_parameters');
    }
  }, [dispatchAPI, message]);

  useEffect(() => {
    getConfig();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const filteredColumns = allColumns.filter((column) => config[column.key]);

  return [filteredColumns, loading] || [];
};
