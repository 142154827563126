import { useNavigate } from 'react-router-dom';
import { useErrorMessage } from './errorMessage';
import { useAuthContext } from '../contexts/AuthContext';

/**
 * A hook for deleting a transport resource.
 * @hook
 * @description A hook for deleting a transport resource
 * @returns {Object} An object containing the `deleteTransport` function.
 */
export const useDeleteTransports = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const navigate = useNavigate();

  /**
   * Deletes a transport  .
   * @param {string} id - The ID of the transport resource to delete.
   */

  const deleteTransportsAPI = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `/transports/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return { deleteTransportsAPI };
};
