import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DatePicker,
  Form,
  Modal,
  Flex,
  message as messageANTD
} from 'antd';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { TooltipContainer } from '../components/TooltipContainer';
import { useHistory } from '../../utils/historyUtils';

export const FormDeleteProgrammesButton = ({
  resourceName,
  visible,
  setVisible,
  resourceId
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();
  const { updateHistoryCustom } = useHistory();

  const customDeleteResource = async (deletionDate) => {
    try {
      await dispatchAPI('POST', {
        url: `/${resourceName}/${resourceId}`,
        body: { deletionDate }
      });
      messageANTD.success(t('datatable.column.action.delete.sortie_ok'));
      updateHistoryCustom(resourceName, resourceId, t('actions.exited'));
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (e) {
      message(e);
    }
  };

  const disabledDate = (current) => current && current > Date.now();

  return (
    <TooltipContainer title={t('buttons.remove')}>
      <>
        <Modal
          footer={false}
          open={visible === true}
          onCancel={() => {
            setVisible(false);
            form.resetFields();
          }}
        >
          <Form
            form={form}
            onFinish={(values) => {
              customDeleteResource(values?.deletionDate);
              setVisible(false);
              form.resetFields();
            }}
          >
            <Form.Item
              name="deletionDate"
              rules={[
                {
                  required: true,
                  message: t('datatable.column.action.delete.date')
                }
              ]}
            >
              <DatePicker disabledDate={disabledDate} />
            </Form.Item>
            <Form.Item>
              <Flex>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginRight: 10 }}
                >
                  {t('datatable.column.action.delete.ok')}
                </Button>
                <Button
                  type="danger"
                  onClick={() => {
                    setVisible(false);
                    form.resetFields();
                  }}
                  style={{ marginLeft: 10 }}
                >
                  {t('datatable.column.action.delete.cancel')}
                </Button>
              </Flex>
            </Form.Item>
          </Form>
        </Modal>

        <Button
          onClick={() => {
            setVisible(true);
          }}
          type="delete"
        >
          {`${t('buttons.remove')} `}
        </Button>
      </>
    </TooltipContainer>
  );
};

FormDeleteProgrammesButton.propTypes = {
  resourceName: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  resourceId: PropTypes.string.isRequired
};
