// TODO: Refine hardwareInsurancesClaimsDbKeys after customer feedback
export const cyberInsuranceClaimsDbKeys = [
  'entity',
  'site',
  'sinister_date',
  'claim_type',
  'insurance_company_reference',
  'client_reference',
  'sinister_location',
  'commentaries'
];

export const hardwareInsurancesClaimsDbKeys = [
  'entity',
  'site',
  'sinister_date',
  'claim_type',
  'insurance_company_reference',
  'client_reference',
  'sinister_location',
  'commentaries'
];

export const professionalMultiriskInsurancesClaimsDbKeys = [
  'entity',
  'site',
  'sinister_date',
  'claim_type',
  'insurance_company_reference',
  'client_reference',
  'sinister_location',
  'commentaries'
];

export const assemblyTestingInsurancesClaimsDbKeys = [
  'entity',
  'site',
  'sinister_date',
  'claim_type',
  'insurance_company_reference',
  'client_reference',
  'sinister_location',
  'commentaries'
];
export const environmentInsurancesClaimsDbKeys = [
  'entity',
  'site',
  'sinister_date',
  'claim_type',
  'insurance_company_reference',
  'client_reference',
  'sinister_location',
  'commentaries'
];

export const legalProtectionInsurancesClaimsDbKeys = [
  'entity',
  'sinister_date',
  'claim_type',
  'insurance_company_reference',
  'client_reference',
  'sinister_location',
  'commentaries'
];
export const individualHousesBuildersClaimsDbKeys = [
  'entity',
  'sinister_date',
  'claim_type',
  'insurance_company_reference',
  'client_reference',
  'sinister_location',
  'commentaries'
];
