import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Flex, Tooltip } from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { TrafficIncident } from '@carbon/icons-react';
import { MailingButton } from '../../../components/ButtonMailActionColumnTransport';

const iconSize = 18;

/**
 * `useActionColumn` is a hook for generating an action column configuration in a table.
 *
 * @hook
 * @returns {Array} An array containing the action column configuration.
 * @property {string} key - The unique key for the action column.
 * @property {string} align - The alignment of the column (right).
 * @property {Function} render - A function to render the action column for each record.
 * @param {Object} record - The record object for which the action column is rendered.
 * @returns {JSX.Element} JSX elements representing action links.
 */

export const useActionColumn = (edit, isArchivatedPage) => {
  const { t } = useTranslation();
  return [
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Flex gap="small" align="center" justify="end" wrap="nowrap">
          <Link to={`/programmes/ad-valorem/show/${record?._id}`}>
            <Tooltip title={t('ad_valorem.actions.show')}>
              <EyeOutlined style={{ fontSize: iconSize }} />
            </Tooltip>
          </Link>
          {edit && (
            <Link to={`/programmes/ad-valorem/edit/${record?._id}`}>
              <Tooltip title={t('ad_valorem.actions.edit')}>
                <EditOutlined style={{ fontSize: iconSize }} />
              </Tooltip>
            </Link>
          )}
          {!isArchivatedPage && (
            <>
              <Link to={`/claims/ad-valorem/create/${record?._id}`}>
                <Tooltip title={t('ad_valorem.actions.create_claim')}>
                  <TrafficIncident size={iconSize} />
                </Tooltip>
              </Link>
              <MailingButton
                assetId={record?._id}
                templateName="Blank"
                tradKey="specific_demand"
                resource="Transport"
                baseUrl="transports"
              />
            </>
          )}
        </Flex>
      )
    }
  ];
};
