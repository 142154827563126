import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const useListContentClaim = (data = {}) => {
  const { t } = useTranslation();
  const {
    claim_type,
    sinister_date,
    opening_date,
    customer_manager,
    unit_manager,
    client_reference,
    insurance_company_reference,
    place_of_occurrence,
    unusual_driver_name,
    driver_time_accident,
    personal_injury,
    garage_informations
  } = data;

  const customerManagerName = customer_manager
    ? `${customer_manager.first_name} ${customer_manager.last_name}`
    : '-';
  const unit_managerName = unit_manager
    ? `${unit_manager.first_name} ${unit_manager.last_name}`
    : '-';

  const labels = [
    {
      label: 'claims.form.claim_type',
      span: 1,
      content: claim_type ? t(`claims.form.${claim_type}`) : '-'
    },
    {
      label: 'claims.form.sinister_date',
      span: 1,
      content:
        (sinister_date && moment(sinister_date).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'claims.form.opening_date',
      span: 1,
      content:
        (opening_date && moment(opening_date).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'claims.form.customer_manager',
      span: 1,
      content: (customerManagerName && customerManagerName) || '-'
    },
    {
      label: 'claims.form.unit_manager',
      span: 1,
      content: (unit_managerName && unit_managerName) || '-'
    },
    {
      label: 'claims.form.client_reference',
      span: 1,
      content: client_reference
    },
    {
      label: 'claims.form.insurance_company_reference',
      span: 1,
      content: insurance_company_reference
    }
  ];

  if (data?.fleet_type && data.fleet_type !== 'AUTOMISSION') {
    labels.push({
      label: 'claims.form.driver_time_accident',
      span: 1,
      content:
        (driver_time_accident?.first_name &&
          `${driver_time_accident.first_name} ${driver_time_accident.last_name}`) ||
        (unusual_driver_name && unusual_driver_name) ||
        '-'
    });
  }

  if (data?.fleet_type) {
    labels.push(
      {
        label: 'claims.form.personal_injury',
        span: 1,
        content: (personal_injury && personal_injury) || '-'
      },
      {
        label: 'claims.form.place_of_occurrence',
        span: 1,
        content: (place_of_occurrence && place_of_occurrence) || '-'
      },
      {
        label: 'claims.form.garage_informations',
        span: 1,
        content: garage_informations
      }
    );
  }

  return labels;
};
