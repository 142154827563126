import { useCallback, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Col, Flex, Skeleton, Row, Steps } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useListContentClaim } from './listContentClaim';
import { useListContentCyberInsurance } from '../../subscriptions/utils/listContents/listContentSubCyberInsurance';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../components';
import { Documents } from '../../components/Documents';
import {
  useListItemsStep,
  useListBeforeValidation
} from '../utils/listItemsStep';
import { handleStatusUtils } from '../utils/handleStatusUtils';
import { ModalClosure } from '../utils/ModalClosure';
import { PhotosClaims } from '../utils/PhotosClaims';
import { ShowClaimAccountingOverview } from '../ClaimAccountingOverview/ShowClaimAccountingOverview';
import { useExtraButtons } from '../extraButtons';
import { useProgramDataUpdater } from '../../../utils/programDataUpdater';
import { History } from '../../components/History';
import { MailingButton } from '../../Mailing/MailingButton';

/**
 * Displays detailed information about a cyberInsurance cancellation claim, including claim information, cyberInsurance cancellation information,
 * pictures, and related actions.
 *
 * @component
 * @returns {React.ReactNode} Detailed view of the cyberInsurance claim.
 */
export const CyberInsuranceClaimsShow = () => {
  const isMounted = useRef(false);
  const { id } = useParams();
  const targetUrl = 'cyber_insurance_claims';
  const { dispatchAPI, permissions, verifPermissions } = useAuthContext();
  const { message } = useErrorMessage();
  const {
    current,
    setCurrent,
    onChange,
    toEnclosed,
    setToEnclose,
    changingStatus,
    setChangingStatus
  } = handleStatusUtils(targetUrl);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const { items } = useListItemsStep();
  const { itemsBeforeValidation } = useListBeforeValidation();
  const [cyberInsurance, setCyberInsurance] = useState();
  const [edit, setEdit] = useState(true);
  const [closure, setClosure] = useState(false);

  const [cyberInsuranceData, setCyberInsuranceData] = useState({});

  const {
    ID,
    claim,
    isClosed,
    isCompliant,
    accountingBalanced,
    feesAppealsBalanced
  } = cyberInsuranceData;

  const {
    setIsCompliant,
    setAccountingBalanced,
    setFeesAppealsBalanced,
    initialData
  } = useProgramDataUpdater(cyberInsuranceData, setCyberInsuranceData);

  const listContentClaim = useListContentClaim(claim);
  const listContent = useListContentCyberInsurance(cyberInsurance);

  const getCyberInsuranceClaim = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/cyber_insurance_claims/${id}?populate=entry_id,unit_manager,entity,site,company,customer_manager`
      });
      if (isMounted.current) {
        setCyberInsuranceData({
          ...initialData(data)
        });
        setCurrent(data?.step);
        setToEnclose(data?.to_enclose);
      }
    } catch (e) {
      if (isMounted.current) {
        message(e);
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  }, []);

  const getCyberInsurance = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/cyber-insurances/${ID}?populate=customer_manager,unit_manager,contract,entity,site,company`
      });
      if (isMounted.current) {
        setCyberInsurance({
          ...data,
          entity: data?.entity?.map((entityItem) => entityItem?.name),
          site: data?.site?.name
        });
      }
    } catch (e) {
      if (isMounted.current) message(e);
    } finally {
      if (isMounted.current) setIsLoading(false);
    }
  }, [ID]);

  useEffect(() => {
    if (ID) {
      (async () => {
        await getCyberInsurance();
      })();
    }
  }, [ID]);

  useEffect(() => {
    isMounted.current = true;
    (async () => {
      await getCyberInsuranceClaim();
    })();

    return () => {
      isMounted.current = false;
    };
  }, [
    current,
    cyberInsuranceData?.accountingBalanced,
    cyberInsuranceData?.feesAppealsBalanced
  ]);

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setEdit(
        !verifPermissions('/claims/edit', permissions?.notPermittedRoutes)
      );
    }
  }, [permissions]);

  const { extraButtons } = useExtraButtons({
    isCompliant,
    setIsCompliant,
    current,
    edit,
    toEnclosed,
    setClosure,
    onChange,
    productClaim: cyberInsuranceData?.claim,
    resource: 'Cyber_insurance_claim',
    baseUrl: 'cyber_insurance_claims',
    product: cyberInsurance,
    urlPathName: 'cyber-insurances',
    accountingBalanced: cyberInsuranceData?.accountingBalanced,
    feesAppealsBalanced: cyberInsuranceData?.feesAppealsBalanced,
    changingStatus,
    setChangingStatus
  });

  return ID && cyberInsurance && !isLoading ? (
    <>
      <PageHeaderCustom
        title={`${cyberInsuranceData?.claim?.unit_counter || 'xxxx'}`}
        extra={extraButtons}
      />
      <MailingButton
        asset={claim}
        templateName={changingStatus}
        tradKey={changingStatus}
        baseUrl={targetUrl}
        changingStatus={changingStatus}
        setChangingStatus={setChangingStatus}
      />{' '}
      <ContentCustom>
        <Flex vertical gap="middle">
          {current !== undefined && current !== 6 ? (
            <Steps
              onChange={(step) => {
                onChange(step, id, edit);
              }}
              items={
                cyberInsuranceData?.isCompliant ? items : itemsBeforeValidation
              }
              current={current}
            />
          ) : null}
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={12}>
              <Row gutter={[0, 16]}>
                <Card>
                  <DescriptionList
                    data={listContentClaim}
                    translate
                    layout="vertical"
                    title={t('claims.form.claim_informations')}
                  />
                </Card>
                <PhotosClaims baseUrl="cyber_insurance_claims" />
                <Documents
                  idWithoutParams={id}
                  baseUrl="cyber_insurance_claims"
                  resource="Cyber_insurance_claim"
                  noDelete={current === 6}
                />
                <Col span={24}>
                  <Card title={t('subscriptions.form.history')} size="small">
                    <History route="cyber_insurance_claims" />
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs={24} xl={12}>
              <Row gutter={[0, 16]}>
                <Card title={t('claims.form.main_informations')}>
                  <DescriptionList
                    data={listContent}
                    translate
                    layout="vertical"
                  />
                </Card>
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={24}>
              {current >= 2 && isCompliant ? (
                <ShowClaimAccountingOverview
                  current={current}
                  buttonBelowTable={
                    current !== 6 && current !== undefined && edit === true
                  }
                  noActionColumnsButton={isClosed}
                  accountingBalanced={accountingBalanced}
                  setAccountingBalanced={setAccountingBalanced}
                  feesAppealsBalanced={feesAppealsBalanced}
                  setFeesAppealsBalanced={setFeesAppealsBalanced}
                  resourceName="cyber-insurance-claim-accounts"
                  url="cyber-insurance-claim-accounts"
                  resourceNameClaim="cyber_insurance_claims"
                  urlClaim="cyber_insurance_claims"
                  urlAccount="claim-accounts"
                  edit={edit}
                />
              ) : null}
            </Col>
          </Row>
        </Flex>
        <ModalClosure
          open={closure}
          onCancel={() => setClosure(false)}
          onOk={() => setClosure(false)}
          claimReferenceNumber={claim?.unit_counter}
        />
      </ContentCustom>
    </>
  ) : (
    <Skeleton active loading={isLoading} />
  );
};
