import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useHistory } from '../../../utils/historyUtils';

/**
 * Custom hook for construction utilities.
 * @hook
 * @param {Object} options - The options object.
 * @param {Object} options.construction - The construction object.
 * @param {Object} options.subProgramme - The subProgramme object.
 * @returns {Object} - The construction utilities.
 */
export const useConstructionUtils = ({
  construction,
  subProgramme,
  otherConstruction
}) => {
  const { id } = useParams();
  const { dispatchAPI, company } = useAuthContext();
  const { message } = useErrorMessage();
  const { setInitialHistory, createHistory, updateHistory } = useHistory();

  const draggerFilesKeysOverRide = [
    'lifting_of_reservation_report',
    'letter_formal_notice',
    'reception_report',
    'registered_mail',
    'declaration_to_insurer',
    'full_statement',
    'filing_complaint'
  ];

  const getConstructions = async (setConstruction, setSubProgramme) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `constructions/${id}?populate=entity,site,contract,company_Id,related_contract_entities`
      });
      setConstruction(data);
      setSubProgramme(data?.sub_programme);
    } catch (e) {
      message(e);
    }
  };

  const config = {
    onGetResource: {
      setFields: (data) => {
        if (data?.history) {
          setInitialHistory(data);
        }
        return {
          ...data,
          sinister_date: data.sinister_date && dayjs(data.sinister_date)
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        entry_id: construction?._id,
        company,
        contract: construction?.contract?._id,
        opening_date: dayjs(),
        city: construction?.city,
        entity: construction?.contract?.entity,
        related_contract_entities: otherConstruction
          ? construction?.contract?.related_contract_entities
          : undefined,
        site: construction?.contract?.site,
        construction_sub_programmes: subProgramme,
        ...createHistory('claim', 'constructions', id)
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        ...updateHistory('claim')
      })
    }
  };

  return { draggerFilesKeysOverRide, getConstructions, config };
};

useConstructionUtils.prototype = {
  construction: PropTypes.shape({}).isRequired,
  subProgramme: PropTypes.string.isRequired,
  otherConstruction: PropTypes.bool
};

useConstructionUtils.defaultProps = {
  otherConstruction: false
};
