import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

/**
 * Provides an array of content used to populate property details.
 * This hook maps the properties of the passed data into a structured format suitable for presentation.
 *
 * @hook
 * @param {Object} data - The data object containing property details.
 * @returns {Array} An array of objects containing label, span, and content information for each property detail.
 *
 */

export const useListContent = (data = {}) => {
  const { t } = useTranslation();

  const {
    contract,
    company,
    created_at,
    entity,
    site,
    customer_manager,
    unit_manager,
    desired_effective_date,
    asset_name,
    postal_code,
    city,
    country,
    total_insured_developed_area,
    legal_status,
    typology
  } = data;
  const customerManagerName = customer_manager
    ? `${customer_manager.first_name} ${customer_manager.last_name}`
    : '-';
  const unit_managerName = unit_manager
    ? `${unit_manager.first_name} ${unit_manager.last_name}`
    : '-';

  return [
    {
      label: 'properties.form.contract_number',
      span: 1,
      content: (contract && contract.contract_number) || '-'
    },
    {
      label: 'properties.form.company_name',
      span: 1,
      content: (company?.name && company.name) || '-'
    },
    {
      label: 'properties.form.date_added',
      span: 1,
      content: (created_at && dayjs(created_at).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'properties.form.entity',
      span: 1,
      content: (entity && entity.name) || '-'
    },
    {
      label: 'properties.form.site',
      span: 1,
      content: (site && site.name) || '-'
    },
    {
      label: 'properties.form.customer_manager',
      span: 1,
      content: (customerManagerName && customerManagerName) || '-'
    },
    {
      label: 'properties.form.unit_manager',
      span: 1,
      content: (unit_managerName && unit_managerName) || '-'
    },
    {
      label: 'properties.form.desired_effective_date',
      span: 1,
      content:
        (desired_effective_date &&
          dayjs(desired_effective_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'properties.form.validity_end_date',
      span: 1,
      content:
        (contract?.validity_end_date &&
          dayjs(contract?.validity_end_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'properties.form.asset_name',
      span: 1,
      content: (asset_name && asset_name) || '-'
    },
    {
      label: 'properties.form.postal_code',
      span: 1,
      content: (postal_code && postal_code) || '-'
    },
    {
      label: 'properties.form.city',
      span: 1,
      content: (city && city) || '-'
    },
    {
      label: 'properties.form.country',
      span: 1,
      content: (country && country) || '-'
    },
    {
      label: 'properties.form.total_insured_developed_area',
      span: 1,
      content:
        (total_insured_developed_area &&
          `${total_insured_developed_area} m²`) ||
        '-'
    },
    {
      label: 'properties.form.legal_status',
      span: 1,
      content: (legal_status && t(`properties.form.${legal_status}`)) || '-'
    },
    {
      label: 'properties.form.typology',
      span: 3,
      content: (typology && t(`properties.form.${typology}`)) || '-'
    }
  ];
};

export const useListContentAddresses = (data = {}) => {
  const { postal_code, city, country, number, street, additional } = data;

  const getAddressFromParts = () => {
    const addressParts = [
      number,
      street,
      additional,
      postal_code,
      city,
      country
    ];
    return [addressParts.filter((part) => part).join(' ')];
  };
  return [
    {
      label: `properties.form.address`,
      span: 3,
      content: getAddressFromParts() || '-'
    }
  ];
};
