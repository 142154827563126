import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useHistory } from '../../../utils/historyUtils';
import { useFields } from './utils/fieldsLegalProtection';

/**
 * A component for creating or updating Legal Protection.
 * @component
 * @param {object} props - The component's props.
 * @param {string} props.purpose - The purpose of the component (e.g., 'create' or 'update').
 * @returns {JSX.Element} The JSX element representing the create/update component.
 */
export const LegalProtectionCreateUpdate = ({ purpose }) => {
  const { t } = useTranslation();
  const { setInitialHistory, createHistory, updateHistory } = useHistory();
  const { fieldsInformations } = useFields();

  const config = {
    onGetResource: {
      setFields: (data) => {
        setInitialHistory(data);
        return {
          ...data,
          desired_effective_date:
            data.desired_effective_date && dayjs(data.desired_effective_date)
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        ...createHistory('program')
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        ...updateHistory('program')
      })
    }
  };
  const title = 'legal_protection';
  return (
    <CreateUpdateContainer
      title={t(`${title}.form.edit`)}
      fields={fieldsInformations}
      purpose={purpose}
      baseUrl="legal-protection-insurances"
      resource={title}
      populate="?populate=documents.file"
      config={config}
      messageOnSuccess
      mandatoryDocuments={[]}
    />
  );
};

LegalProtectionCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
