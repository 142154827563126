import { useCallback, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Button, Skeleton, Row, Col, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import {
  useListContent,
  useListContentDepartureLocation,
  useListContentArrivalLocation
} from './listContentAdValorem';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../../components';
import { routes } from '../../../../utils/constants/adminRoutes';
import { MailingButton } from '../../../Mailing/MailingButton';
import { ClaimButton } from '../../../components/ClaimButton';
import usePopConfirm from '../../utils/popConfirms';
import { History } from '../../../components/History';

/**
 * adValoremShow component.
 * Renders detailed information about a specific adValorem course, including its documents.
 * Provides options to edit or delete the adValorem course.
 *
 * @component
 * @returns {JSX.Element} A JSX element representing the component.
 */

export const AdValoremShow = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [adValorem, setAdValorem] = useState();
  const [visible, setVisible] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const listContent = useListContent(adValorem);
  const ListContentDepartureLocation =
    useListContentDepartureLocation(adValorem);
  const ListContentArrivalLocation = useListContentArrivalLocation(adValorem);
  const { exitProgram, unarchiveProgram } = usePopConfirm('transports');

  const getAdValorem = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/transports/${id}?populate=customer_manager,unit_manager,contract,entity,site`
      });
      setAdValorem(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getAdValorem();
    })();
  }, [getAdValorem]);

  useEffect(() => {
    if (adValorem) setIsDeleted(!adValorem?.deleted);
  }, [adValorem]);

  return (
    <>
      <PageHeaderCustom
        title={`${t('number')} ${adValorem?.unit_counter || 'xxxx'} - ${t(
          'transports.form.ad_valorem'
        )} ${adValorem?.departure_city} -> ${adValorem?.arrival_city}`}
        extra={
          <>
            {isDeleted && (
              <Link
                to={{
                  pathname: `${routes.PROGRAMMES}/ad-valorem/edit/${id}`
                }}
              >
                <Button>
                  <EditOutlined />
                  {`${t('buttons.edit')} `}
                </Button>
              </Link>
            )}

            {isDeleted && (
              <>
                <ClaimButton id={id} url="ad-valorem" claimType="transport" />
                <MailingButton
                  asset={adValorem}
                  templateName="Blank"
                  tradKey="specific_demand"
                  resource="Transport"
                  baseUrl="transports"
                />
              </>
            )}
            {adValorem &&
              !adValorem.deleted &&
              exitProgram({
                visible,
                setVisible
              })}
            {adValorem && adValorem.deleted && unarchiveProgram()}
          </>
        }
      />
      <ContentCustom>
        <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={16}>
              <Card>
                <DescriptionList
                  data={listContent}
                  translate
                  layout="vertical"
                  title={t('transports.form.main_informations')}
                />
              </Card>
            </Col>
            <Col xs={24} xl={8}>
              <Row gutter={[0, 16]}>
                <Card>
                  <DescriptionList
                    data={ListContentDepartureLocation}
                    translate
                    title={t('transports.form.departure_location')}
                  />
                </Card>
                <Card>
                  <DescriptionList
                    data={ListContentArrivalLocation}
                    translate
                    title={t('transports.form.arrival_location')}
                  />
                </Card>
                <Col span={24}>
                  <Card title={t('subscriptions.form.history')} size="small">
                    <History route="Transport" />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Skeleton>
      </ContentCustom>
    </>
  );
};
