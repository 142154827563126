import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useEffect } from 'react';
// import { useEffect, useState } from 'react';
import { Checkbox, Tag } from 'antd';
import { useFiltersDataFetch } from '../../../components/ColumnsDynamicFilters/useFiltersDatasFetch';
import { RenderEntity } from '../../documents/utils/RenderEntity';
import { renderSites } from '../../../utils/formatters/entities';

/**
 * `useColumns` is a hook that returns an array of column configurations for rendering a table.
 * Each configuration specifies how a specific column of the table should be displayed.
 * It utilizes the i18next internationalization framework to translate table headers and certain
 * values. Dates in the table are formatted using the `dayjs` library.
 *
 * @hook
 * @returns {Array} An array containing configuration for each column of the table. The configuration includes
 * details such as the title of the column, key, dataIndex to fetch the data, rendering logic, and whether it is sortable.
 */

export const useColumns = (
  resourceName,
  dbExtraQueryFilter,
  populate,
  selectedRowKeysPaidItem,
  setSelectedRowKeysPaidItem
) => {
  const { t } = useTranslation();

  const { fetchFilters, filters, loading } = useFiltersDataFetch(
    resourceName,
    dbExtraQueryFilter,
    resourceName,
    populate
  );
  const handleSelectPaidItem = (record, isSelected) => {
    if (isSelected) {
      if (!selectedRowKeysPaidItem.includes(record._id)) {
        setSelectedRowKeysPaidItem((prevKeys) => [...prevKeys, record._id]);
      }
    } else {
      setSelectedRowKeysPaidItem((prevKeys) =>
        prevKeys.filter((key) => key !== record._id)
      );
    }
  };

  useEffect(() => {
    fetchFilters();
  }, []);

  const columns = [
    {
      title: t('call_for_contributions.form.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) => {
        switch (status) {
          case 'PENDING':
            return (
              <Tag color="orange">
                {t(`call_for_contributions.form.awaiting_payment`)}
              </Tag>
            );
          case 'CREATED':
            return (
              <Tag color="blue">
                {t(`call_for_contributions.form.awaiting_sending`)}
              </Tag>
            );
          default:
            return '-';
        }
      },
      sorter: true
    },
    {
      title: t('call_for_contributions.form.entity'),
      key: 'entity',
      dataIndex: ['entity'],
      filters: filters?.entity?.map((entity) => ({
        text: entity.name,
        value: entity._id
      })),
      sorter: true,
      render: (entity) => RenderEntity(entity, t)
    },
    {
      title: t('call_for_contributions.form.site'),
      key: 'site',
      dataIndex: ['site'],
      filters: filters?.site?.map((site) => ({
        text: site.name,
        value: site._id
      })),
      sorter: true,
      render: (site) => renderSites(site)
    },
    {
      title: t('call_for_contributions.form.document_type'),
      key: 'document_type',
      dataIndex: ['document_type'],
      filters: filters?.document_type?.map((value) => ({
        text: t(`call_for_contributions.enums.${value}`),
        value
      })),
      sorter: true,
      render: (document_type) =>
        t(`call_for_contributions.enums.${document_type}`)
    },
    {
      title: t('call_for_contributions.form.company_Id'),
      key: 'company_Id',
      dataIndex: ['company_Id'],
      filters: filters?.company_Id?.map((value) => ({
        text: value.name,
        value
      })),
      sorter: true,
      render: (company_Id) => company_Id?.name || '-'
    },
    {
      title: t('call_for_contributions.form.contract_number'),
      key: 'contract_number',
      dataIndex: ['contract_number'],
      filters: filters?.contract_number?.map((value) => ({
        text: value,
        value
      })),
      sorter: true
    },
    {
      title: t('call_for_contributions.form.last_sending_date'),
      key: 'last_sending_date',
      dataIndex: 'last_sending_date',
      filters: filters?.last_sending_date?.map((value) => ({
        text: dayjs(value).format('DD/MM/YYYY'),
        value
      })),
      render: (last_sending_date) =>
        (last_sending_date && dayjs(last_sending_date).format('DD/MM/YYYY')) ||
        '-',
      sorter: true
    },
    {
      title: t('call_for_contributions.form.payment_date'),
      key: 'payment_date',
      dataIndex: 'payment_date',
      filters: filters?.payment_date?.map((value) => ({
        text: dayjs(value).format('DD/MM/YYYY'),
        value
      })),
      render: (payment_date) =>
        (payment_date && dayjs(payment_date).format('DD/MM/YYYY')) || '-',
      sorter: true
    },
    {
      title: t('call_for_contributions.form.paid'),
      key: 'is_paid',
      dataIndex: 'is_paid',
      filters: filters?.is_paid?.map((value) => ({
        text: t(`call_for_contributions.form.is_paid`),
        value
      })),
      sorter: true,
      render: (is_paid, record) =>
        is_paid ? (
          <Tag color="green">{t(`call_for_contributions.form.paid`)}</Tag>
        ) : (
          <Checkbox
            checked={selectedRowKeysPaidItem.includes(record._id)}
            onChange={(e) => {
              handleSelectPaidItem(record, e.target.checked);
            }}
          />
        )
    }
  ];

  return [columns, loading];
};
