import React, { useState, createContext } from 'react';

export const SubscriptionContext = createContext();

/**
 * A context provider component for managing subscription-related state.
 * @component
 * @param {object} props - The component's props.
 * @param {React.ReactNode} props.children - The child elements to be wrapped by this context provider.
 * @returns {JSX.Element} The JSX element representing the subscription context provider.
 */
export const SubscriptionContextProvider = ({ children }) => {
  const [forceRefresh, setForceRefresh] = useState(false);

  return (
    <SubscriptionContext.Provider
      value={{
        forceRefresh,
        setForceRefresh
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};
