import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const useListContentClaim = (data = {}) => {
  const { t } = useTranslation();
  const {
    sinister_date,
    opening_date,
    customer_manager,
    unit_manager,
    customer_phone_number,
    claim_type,
    customer_email,
    insurance_company_reference
  } = data;

  const customerManagerName = customer_manager
    ? `${customer_manager?.first_name} ${customer_manager?.last_name}`
    : '-';
  const unit_managerName = unit_manager
    ? `${unit_manager?.first_name} ${unit_manager?.last_name}`
    : '-';

  const phoneNumber = customer_phone_number
    ? `${customer_phone_number?.country_code} ${customer_phone_number?.number}`
    : '-';

  const labels = [
    {
      label: 'claims.form.sinister_date',
      span: 1,
      content:
        (sinister_date && moment(sinister_date).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'claims.form.opening_date',
      span: 2,
      content:
        (opening_date && moment(opening_date).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'claims.form.customer_manager',
      span: 1,
      content: (customerManagerName && customerManagerName) || '-'
    },
    {
      label: 'claims.form.unit_manager',
      span: 2,
      content: (unit_managerName && unit_managerName) || '-'
    },
    {
      label: 'claims.form.insurance_company_reference',
      span: 2,
      content: insurance_company_reference
    },
    {
      label: 'claims.form.claim_type',
      span: 1,
      content: (claim_type && t(`claims.form.${claim_type}`)) || '-'
    },
    {
      label: 'claims.form.customer_email',
      span: 1,
      content: customer_email || '-'
    },
    {
      label: 'claims.form.customer_phone_number',
      span: 3,
      content: phoneNumber || '-'
    }
  ];

  return labels;
};
