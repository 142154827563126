import { createGlobalStyle } from 'styled-components';

const LightTheme = createGlobalStyle`
  body {
    --clientColor: #fff;
    --primaryColor: #1B3837;
    --addColor: #47d267;
    --componentBackground: #fff;
    --secondaryBackground: #F3F1EE;
    --secondaryColor: #314B49;
    --disabledColor: #bfbfbf;
    --bodyBackground: var(--secondaryBackground);
    --primaryHover: var(--primaryColor);
    --secondaryHover: #e6f7ff;
    --textColor: var(--primaryColor);
    --textColorSecondary: var(--gray600);
    --textColorActive: var(--secondaryColor);
    --textColorInvert: #fff;
    --itemActiveBackground: #e6f7ff;
    --itemHoverBackground: #f0f0f0;
    --borderColor: var(--gray500);
    --borderColorSecondary: var(--gray300);
    --contrastBackground: #f5f5f5;
    --errorColor: #e31919;
    --warningColor: #E17A3B;
    --boxShadowColor: var(--borderColor);
    --secondaryHover: var(--componentBackground);
    --subMenuBackground: rgba(49, 75, 73, 0.5);
    --gray100: #F8F9FA;
    --gray200: #E9ECEF;
    --gray300: #DEE2E6;
    --gray400: #CED4DA;
    --gray500: #ADB5BD;
    --gray600: #6C757D;
    --boxShadow: 2px 2px 4px var(--borderColor);
    --borderRadius: 32px;
    --borderRadiusXS: 2px;
    --borderRadiusLG: 8px;
    --borderRadiusXL: 12px;
    --titleFont: 'Hammersmith One', sans-serif;
  }
`;

export default LightTheme;
