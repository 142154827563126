import { RenderTransportList } from '../utils/renderListResource';

/**
 * Displays a list of Other construction claims with relevant columns and additional filters.
 *
 * @component
 * @returns {React.ReactNode} List of Other construction claims.
 */
export const ListFaculties = () =>
  RenderTransportList({
    actionColumnUrl: 'faculties',
    populate: 'entity,site,entry_id,company',
    transport_sub_programmes: 'FACULTY',
    sheetName: 'faculties'
  });
