import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { renderEntities } from '../../../../utils/formatters/entities';

/**
 * `useListContent` is a custom React hook designed to format and prepare data for listing within a component.
 *
 * @hook
 * @param {Object} data - The data object containing details of the civil liability instance.
 * @property {string} data.contract_number - The contract number.
 * @property {Object} data.contract - The contract object.
 * @property {Date} data.created_at - The creation date of the contract.
 * @property {Object} data.customer_manager - The manager responsible for the customer.
 * @property {Object} data.unit_manager - The manager responsible for the unit.
 * @property {Date} data.desired_effective_date - The desired effective date of the contract.
 * @property {Date} data.validity_end_date - The validity end date of the contract.
 * @property {string} data.APE_code - The APE code associated with the contract.
 * @property {number} data.previous_year_revenue - The revenue of the previous year.
 * @property {string} data.eighteen_months_span_statistic_number_of_employees - Number of employees statistic spanning eighteen months.
 * @property {string} data.description_of_the_activity - Description of the related activity.
 *
 * @returns {Array} Returns an array of objects, where each object corresponds to a label and its associated content. This makes it easier to render lists within a component.
 *
 */

export const useListContent = (data = {}) => {
  const {
    contract,
    company_id,
    customer_manager,
    unit_manager,
    desired_effective_date,
    validity_end_date,
    validity_start_date,
    entity,
    APE_code,
    previous_year_revenue,
    eighteen_months_span_statistic_number_of_employees,
    description_of_the_activity,
    deleted,
    deletion_date
  } = data;

  const { t } = useTranslation();

  const customerManagerName = customer_manager
    ? `${customer_manager.first_name} ${customer_manager.last_name}`
    : '-';
  const unit_managerName = unit_manager
    ? `${unit_manager.first_name} ${unit_manager.last_name}`
    : '-';

  const labels = [
    {
      label: 'civil-liability.form.contract',
      span: 1,
      content: (company_id?.name && company_id.name) || '-'
    },
    {
      label: 'civil-liability.form.contract_number',
      span: 1,
      content: (contract?.contract_number && contract?.contract_number) || '-'
    },
    {
      label: 'civil-liability.form.customer_manager',
      span: 1,
      content: customerManagerName
    },
    {
      label: 'civil-liability.form.unit_manager',
      span: 1,
      content: unit_managerName
    },
    {
      label: 'civil-liability.form.desired_effective_date',
      span: 1,
      content:
        (desired_effective_date &&
          dayjs(desired_effective_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'civil-liability.form.validity_start_date',
      span: 1,
      content:
        (contract?.validity_start_date &&
          dayjs(validity_start_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'civil-liability.form.validity_end_date',
      span: 1,
      content:
        (contract?.validity_end_date &&
          dayjs(validity_end_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'civil-liability.form.entity',
      span: 1,
      content: renderEntities(entity)
    },
    {
      label: 'civil-liability.form.APE_code',
      span: 1,
      content: (APE_code && APE_code) || '-'
    },
    {
      label: 'civil-liability.form.previous_year_revenue',
      span: 1,
      content: (previous_year_revenue && previous_year_revenue) || '-'
    },
    {
      label:
        'civil-liability.form.eighteen_months_span_statistic_number_of_employees',
      span: 1,
      content: eighteen_months_span_statistic_number_of_employees
        ? t(
            `civil-liability.form.enums.${eighteen_months_span_statistic_number_of_employees}`
          )
        : '-'
    },
    {
      label: 'civil-liability.form.description_of_the_activity',
      span: 1,
      content:
        (description_of_the_activity && description_of_the_activity) || '-'
    }
  ];

  if (deleted) {
    labels.push({
      label: 'civil-liability.form.deleted',
      span: 1,
      content:
        (deletion_date && dayjs(deletion_date).format('DD/MM/YYYY')) || '-'
    });
  }

  return labels;
};
