import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import { useFields } from './fieldsAssemblyTestingInsurance';
import { useAuthContext } from '../../../contexts/AuthContext';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useConfig } from './createUpdateConfig';
import useNoFields from '../../components/noFieldsForDragger';

/**
 * Component for creating or updating transport subscriptions.
 * @component
 * @param {Object} props - The component's properties.
 * @param {string} props.purpose - The purpose of the component, either "create" or "edit".
 * @returns {JSX.Element} The JSX element for creating or updating transport subscriptions.
 */
export const AssemblyTestingSubCreateUpdate = ({ purpose }) => {
  const { company } = useAuthContext();
  const program = 'ASSEMBLY_TESTING_INSURANCE';
  const { t } = useTranslation();
  const { noFields } = useNoFields();

  // const { fieldsInformations } = useFields();

  const mandatoryDocuments = [
    'company_KBIS_lte_3_months',
    'company_form',
    'sales_contract_copy'
  ];

  const draggerFilesKeysOverRide = [
    'company_KBIS_lte_3_months',
    'company_form',
    'sales_contract_copy',
    'construction_schedule',
    'plans',
    'technical_description',
    'detailed_budget_by_object'
  ];

  const config = useConfig(company, program);

  return (
    <CreateUpdateContainer
      title={`${t(`subscriptions.form.${purpose}`)} - ${t(
        `subscriptions.form.${program}`
      )}`}
      fields={noFields}
      purpose={purpose}
      baseUrl="subscriptions"
      resource="subscriptions"
      config={config}
      draggerFilesKeysOverRide={draggerFilesKeysOverRide}
      mandatoryDocuments={mandatoryDocuments}
      populate="?populate=documents.file"
      messageOnSuccess
    />
  );
};

AssemblyTestingSubCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
