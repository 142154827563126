import { useCallback, useEffect, useState } from 'react';
import { DatePicker, Radio, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { SelectManager } from '../../components/SelectManager';
import { useGetEnums } from '../../../utils/getEnums';

const { TextArea } = Input;
/**
 * A hook for generating form fields configuration based on the provided parameters.
 *
 * @hook
 * @param {boolean} isOther - Indicates whether the "claim_description_if_other" field is visible.
 * @param {Function} setIsOther - A function to set the visibility of the "claim_description_if_other" field.
 * @returns {Object} An object containing an array of form fields configuration.
 */

const { Group } = Radio;

export const useFields = () => {
  const { t } = useTranslation();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [enums, setEnums] = useState({});
  const { getEnums } = useGetEnums();
  const dateFormat = 'DD-MM-YYYY';

  const fieldsInformations = [
    {
      label: ['customer_manager'],
      name: ['customer_manager'],
      rules: [{ required: true }],
      input: <SelectManager dbKey="customer_manager" />
    },
    {
      label: ['unit_manager'],
      name: ['unit_manager'],
      rules: [{ required: false }],
      input: <SelectManager dbKey="unit_manager" />
    },
    {
      label: ['insurance_company_reference'],
      name: ['insurance_company_reference'],
      rules: [{ required: false }]
    },
    {
      label: ['client_reference'],
      name: ['client_reference'],
      rules: [{ required: false }]
    },
    {
      label: ['sinister_date'],
      name: ['sinister_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['commentaries'],
      name: ['commentaries'],
      input: <TextArea style={{ resize: 'none' }} />
    },
    {
      label: ['place_of_occurrence'],
      name: ['place_of_occurrence'],
      rules: [{ required: true }]
    },
    {
      label: ['personal_injury'],
      name: ['personal_injury'],
      rules: [{ required: true }],
      input: (
        <Group>
          {(enums?.personalInjury || []).map((option) => (
            <Radio key={option} value={option}>
              {t(`claims.form.${option}`)}
            </Radio>
          ))}
        </Group>
      )
    }
  ];

  const fetchEnums = async () => {
    const data = await getEnums('civil_liability_claims');
    setEnums(data);
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await fetchEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fieldsInformations,
    isFieldsLoading
  };
};
