import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { getFullName } from '../../../../utils/formatters/listContent';

/**
 * Custom hook for generating a list of claim-related labels and contents from claim data.
 * @hook
 * @param {Object} data - The data containing claim-related information.
 * @param {string} data.claim_type - The type of claim.
 * @param {Date} data.sinister_date - The date of the sinister.
 * @param {Date} data.opening_date - The opening date of the claim.
 * @param {Object} data.customer_manager - The customer manager object.
 * @param {Object} data.unit_manager - The unit manager object.
 * @param {string} data.client_reference - The client reference for the claim.
 * @returns {Array} An array of claim-related labels and contents.
 */
export const useListContentClaim = (data = {}) => {
  const { t } = useTranslation();

  const {
    claim_type,
    sinister_date,
    opening_date,
    customer_manager,
    unit_manager,
    insurance_company_reference,
    client_reference,
    sinister_location,
    personal_injury
  } = data;

  const labels = [
    {
      label: 'claims.form.claim_type',
      span: 1,
      content: (claim_type && t(`claims.form.${claim_type}`)) || '-'
    },
    {
      label: 'claims.form.sinister_date',
      span: 1,
      content:
        (sinister_date && dayjs(sinister_date).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'claims.form.opening_date',
      span: 1,
      content: (opening_date && dayjs(opening_date).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'claims.form.customer_manager',
      span: 1,
      content: (customer_manager && getFullName(customer_manager)) || '-'
    },
    {
      label: 'claims.form.unit_manager',
      span: 1,
      content: (unit_manager && getFullName(unit_manager)) || '-'
    },
    {
      label: 'claims.form.insurance_company_reference',
      span: 1,
      content:
        (insurance_company_reference && insurance_company_reference) || '-'
    },
    {
      label: 'claims.form.client_reference',
      span: 1,
      content: (client_reference && client_reference) || '-'
    },
    {
      label: 'claims.form.sinister_location',
      span: 1,
      content: (sinister_location && sinister_location) || '-'
    },
    {
      label: 'claims.form.personal_injury',
      span: 1,
      content: (personal_injury && t(`claims.form.${personal_injury}`)) || '-'
    }
  ];

  return labels;
};
